export function getStatusText(status) {
  return `${status === 'inactive' ? 'Deactivate' : 'Activate'}`
}

export function getHeading(status) {
  return `${getStatusText(status)} token`
}

export function getBody(status) {
  return `Are you sure you want to ${getStatusText(
    status
  ).toLowerCase()} this token?`
}
