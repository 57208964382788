// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/Content/Content/Launchers/Content/AlignActions/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps_Content_Content_Launchers_Content_AlignActions_Content($person_id: numeric!) {
  vaxiom_contact_method_associations(
    where: {
      person_id: { _eq: $person_id }
      contact_method: { dtype: { _eq: "postal" } }
      postal_address: { zip: { _is_null: false } }
    }
  ) {
    id
  }
}

`