import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataChange } from 'Simple/Data'
import useOnAcceptTreatmentAction from './useOnAcceptTreatmentAction.js'

let STEPS = {
  TREATMENT_PLAN_TEMPLATES: 'treatment_plan_templates',
  SCHEDULE: 'schedule',
  PAYMENT_PLANS: 'payment_plans',
  SIGN_CONTRACT: 'sign_contract',
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let changeSelectedTxPlanning = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.treatment.treatment_plans',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let onAcceptTreatmentAction = useOnAcceptTreatmentAction(props)

  let NEXT_STEP_ACTION = {
    [STEPS.TREATMENT_PLAN_TEMPLATES]: STEPS.SCHEDULE,
    [STEPS.SCHEDULE]: STEPS.PAYMENT_PLANS,
    [STEPS.PAYMENT_PLANS]: STEPS.SIGN_CONTRACT,
    [STEPS.SIGN_CONTRACT]: STEPS.PAYMENT_PLANS,
  }

  let BACK_STEP_ACTION = {
    [STEPS.SCHEDULE]: STEPS.TREATMENT_PLAN_TEMPLATES,
    [STEPS.PAYMENT_PLANS]: STEPS.SCHEDULE,
    [STEPS.SIGN_CONTRACT]: STEPS.PAYMENT_PLANS,
  }
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'continue': {
        change(next => {
          if (next.current_step === STEPS.SIGN_CONTRACT) {
            next.completed = true
          }
          next.current_step =
            NEXT_STEP_ACTION[next.current_step] ?? next.current_step
        })
        break
      }
      case 'back': {
        change(next => {
          if (next.current_step === STEPS.SIGN_CONTRACT) {
            next.completed = false
          }

          next.current_step =
            BACK_STEP_ACTION[next.current_step] ?? next.current_step
        })
        break
      }
      case 'accept_treatment': {
        onAcceptTreatmentAction()
        break
      }
      case 'cancel': {
        // reset tx plans selection
        change(next => {
          next.completed = false
          next.current_step = STEPS.TREATMENT_PLAN_TEMPLATES
        })
        changeSelectedTxPlanning({
          new: true,
          plans: [],
          payment_plan: {
            treatment_fee: 0,
          },
          sign_contract: {},
          completed: false,
        })
        setFlowTo(normalizePath(props.viewPath, '../../../TreatmentCard'))
        break
      }
      case 'done': {
        setFlowTo(normalizePath(props.viewPath, '../../../TreatmentCard'))
        break
      }
      default: {
        break
      }
    }
  }
}
