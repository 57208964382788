// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/mutation_insert.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions(
  $booking_id: numeric!
  $chair_id: numeric!
  $resource_id: numeric!
  $urgent: Boolean!
) {
  insert_vaxiom_provider_calls_one(
    object: {
      booking_id: $booking_id
      chair_id: $chair_id
      resource_id: $resource_id
      urgent: $urgent
    }
  ) {
    id
  }
}

`