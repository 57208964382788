import { useCallback, useState } from 'react'

export default function useIsFocused({ autoSelect = false, onFocus, onBlur }) {
  let [isFocused, setIsFocused] = useState(false)

  let onFocusBind = useCallback(
    (event) => {
      setIsFocused(true)
      onFocus && onFocus(event)

      if (autoSelect) {
        event.target.select()
      }
    },
    [autoSelect, onFocus]
  )
  let onBlurBind = useCallback(
    (event) => {
      setIsFocused(false)
      onBlur && onBlur(event)
    },
    [onBlur]
  )

  return [isFocused, onFocusBind, onBlurBind]
}
