import { useDataValue, useDataSubmit, useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export default function useOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let image_id = useDataValue({
    context: 'patient_image',
    path: 'id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    if (!value?.person_id) return

    let mutationResponse = await executeMutation({
      person_id: value.person_id,
    })

    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't send the image securely`))
    }

    submit({
      type: 'tab/openCommsThread',
      thread_id: mutationResponse.data.thread.id,
      is_secure: value.is_secure,
      auto_uploaded_attachments: [
        {
          resource_id: image_id,
          resource_type: 'image',
        },
      ],
    })

    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
