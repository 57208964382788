import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changeQuestion = useDataChange({
    viewPath: props.viewPath,
    context: 'question',
  })
  return async function onChange(value) {
    changeQuestion(next => {
      next.answer = value?.selected ?? []
      next.alert = value?.alerts ?? false
    })
  }
}
