import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let current_location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let preferredXid = localStorage.getItem('preferredXid')

    if (current_location) {
      return {
        location_id: current_location._id,
        xid: current_location.xid,
      }
    } else if (preferredXid) {
      return {
        location_id: null,
        xid: preferredXid,
      }
    } else {
      return data
    }
  }, [data, current_location])
}
