import { useDataIsSubmitting, useDataValue } from 'Simple/Data'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'payments_edit_autopay',
    viewPath: props.viewPath,
  })
  let method = useDataValue({
    context: 'payments_edit_autopay',
    path: 'method',
    viewPath: props.viewPath,
  })
  let original_autopay_source = useDataValue({
    context: 'edit_autopay_info',
    path: 'payment_plan.autopay_source',
    viewPath: props.viewPath,
  })
  let paymentMethodValid = useDataValue({
    context: 'payment',
    path: 'valid',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    (original_autopay_source === null && method === 'invoice') ||
    (method === 'autopay' && !paymentMethodValid)
  )
}
