import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnClick(props) {
  let estimated_reimbursement_amount = useDataValue({
    context: 'edit_insurance',
    path: 'estimated_reimbursement_amount',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change(next => {
      let insurance = next.insurances[index]
      insurance.estimated_reimbursement_amount = estimated_reimbursement_amount
      insurance.is_overridden = true

      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
