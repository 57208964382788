// this is an autogenerated file from App/Account/ClockIn/Content/mutation-clockinout.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_ClockIn_Content(
  $core_user_id: numeric!
  $date: timestamp!
  $location_id: numeric!
  $status: String!
  $state: String!
  $work_hour_comments: vaxiom_work_hour_comments_arr_rel_insert_input
) {
  insert_vaxiom_work_hours_one(
    object: {
      core_user_id: $core_user_id
      date: $date
      location_id: $location_id
      status: $status
      state: $state
      work_hour_comments: $work_hour_comments
    }
  ) {
    id
  }
}

`