import { useDataValue, useDataChange } from 'Simple/Data.js'
import { parseTimeIntervalInWeeks } from 'Logic/parseTimeIntervalInWeeks.js'

export function useOnChange(props) {
  let interval_to_next = useDataValue({
    context: 'add_appointment',
    path: 'interval_to_next',
    viewPath: props.viewPath,
  })
  let setIntervalToNext = useDataChange({
    context: 'add_appointment',
    path: 'interval_to_next',
    viewPath: props.viewPath,
  })

  return async function onChange(event) {
    let value = parseTimeIntervalInWeeks(event.target.value)
    if (typeof value !== 'number' || interval_to_next === value) return

    setIntervalToNext(value)
  }
}
