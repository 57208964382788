import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'profile_actions',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ tab: 'profile' })
  }
}
