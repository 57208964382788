// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/mutation-no-show.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More($id: uuid!) {
  vaxiom_appointment_booking_no_show(id: $id) {
    id
  }
}

`