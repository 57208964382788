import { useDataChange } from 'Simple/Data'

export function useOnToggle(props) {
  let change = useDataChange({
    context: 'event_action',
    viewPath: props.viewPath,
  })

  return function onToggle(value) {
    change(next => {
      next.data.is_subset = value

      if (!value) {
        next.data.subset_statuses = []
      }
    })
  }
}
