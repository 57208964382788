import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data

    let { tx_card_field_definition_id, values, tracked_values } = data[0]

    return {
      id: tx_card_field_definition_id,
      value: getValue(values, tracked_values),
    }
  }, [data])
}

function getValue(values, tracked_values) {
  if (values.length) {
    return values
      .map(value => value.text_value || value.field_option?.name)
      .filter(Boolean)
      .join(', ')
  } else if (tracked_values.length) {
    return tracked_values
      .map(value => value.text_value || value.field_option?.name)
      .filter(Boolean)
      .map(value => `(${value})`)
      .join(', ')
  } else {
    return null
  }
}
