import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'unseat',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ skip_required_fields: true })
  }
}
