import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSubmit = useDataOnActionSubmit(props)
  let onActionReset = useDataOnActionReset(props)

  return async function onSubmit({ value, args, originalValue, change }) {
    switch (args?.type) {
      case 'submit': {
        return onActionSubmit({ value, args, originalValue, change })
      }
      case 'reset': {
        return onActionReset({ value, args, originalValue, change })
      }
      default: {
        throw new Error(`Unsupported action ${args?.type}`)
      }
    }
  }
}

function useDataOnActionSubmit(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onAction({ value, args, originalValue, change }) {
    let mutationResponse = await executeMutation({
      data: value.data,
      location_id: value.location_id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Treatment card settings updated!'))

    change(mutationResponse.data.insert_treatments_settings_one)
  }
}

function useDataOnActionReset(props) {
  return async function onAction({ value, args, originalValue, change }) {
    change(originalValue)
  }
}
