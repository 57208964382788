// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Notes/Content/NewOrEdit/Content/Actions/add_note_mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Notes_Content_NewOrEdit_Content_Actions(
  $note: String!
  $target_id: numeric!
  $target_type: String!
  $alert: Boolean
  $user_id: numeric!
) {
  insert_vaxiom_notes_one(
    object: {
      alert: $alert
      note: $note
      target_id: $target_id
      target_type: $target_type
      author: $user_id
    }
  ) {
    id
    note
  }
}

`