// this is an autogenerated file from App/Account/Content/Insights/Kpis/Definitions/Content/Menu/Locations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Insights_Kpis_Definitions_Content_Menu_Locations{
  session_user_locations(order_by: { name: asc }) {
    id
    _id
    text: name
  }
}

`