import { useEffect } from 'react'
import Mousetrap from 'mousetrap'

let useMousetrap = ({ keys, onPress, type, disabled }) => {
  useEffect(() => {
    Mousetrap.bind(
      keys.split(' '),
      () => {
        if (disabled !== true) {
          onPress()
        }
        return false
      },
      type
    )

    return () => {
      Mousetrap.unbind(keys.split(' '), type)
    }
  }, [keys, type, onPress, disabled])
}
export default useMousetrap
