// this is an autogenerated file from App/Account/NewPatient/Content/Panes/Form/ReferralsSection/New/Confirm/Content/Referral/Value/Search/Professional/Professionals/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_NewPatient_Content_Panes_Form_ReferralsSection_New_Confirm_Content_Referral_Value_Search_Professional_Professionals($parent_company_id: uuid!, $search_term: String!) {
  vaxiom_other_professional_relationships(
    where: {
      _and: [
        { person: { organization: { _id: { _eq: $parent_company_id } } } }
        { person: { search_name: { _like: $search_term } } }
      ]
    }
  ) {
    id
    external_office {
      id
      name
      postal_address {
        id
        city
        state
      }
    }
    employee_type {
      id
      name
    }
    person {
      id
      first_name
      last_name
      organization_id
    }
  }
}

`