import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let index = useDataValue({
    context: 'comparison_action_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'comparison',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      let temp = next.images[index]
      next.images[index] = next.images[index - 1]
      next.images[index - 1] = temp
    })
  }
}
