// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Medical/Content/ToggleMedicalHistoryV1/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Medical_Content_ToggleMedicalHistoryV1($patient_id: numeric!) {
  vaxiom_medical_history(
    where: { patient_id: { _eq: $patient_id } }
    limit: 1
  ) {
    id
    migrated_to_v2_at
  }
}

`