import React, { useEffect } from 'react'
import { isCoreEmbedMessage } from 'Data/isCoreEmbedMessage.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useClient } from 'Data/Api.js'
import { useDataChange, useDataSubmit } from 'Simple/Data.js'
import toPascalCase from 'to-pascal-case'
import queryPatientInfo from './query-patient-info.graphql.js'
import View from './view.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let sessionExpiredRefreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'session_expired_refresh_embedded_section',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'message',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let client = useClient()

  useEffect(() => {
    let cancel = false
    window.addEventListener('message', listener, false)

    return () => {
      cancel = true
      window.removeEventListener('message', listener)
    }

    async function listener(event) {
      if (!isCoreEmbedMessage(event)) return

      switch (event.data.type) {
        case 'session_expired': {
          sessionExpiredRefreshEmbeddedSection(Date.now())
          return
        }

        case 'files_edit':
        case 'files_download_pdf':
        case 'files_download_combined_pdf':
        case 'files_print_combined_pdf':
        case 'server_connection_lost':
        case 'treatment_request_override':
        case 'payments_create_payment_plan_for_existing_invoice':
        case 'payments_edit_autopay':
        case 'payments_request_payment': {
          change(event.data)
          setFlowTo(
            normalizePath(props.viewPath, toPascalCase(event.data.type))
          )
          return
        }
        case 'payments_post_payment': {
          let { data } = await client
            .query(queryPatientInfo, { id: event.data.params.patient_id })
            .toPromise()
          event.data.params.patient_uuid = data?.vaxiom_patients_by_pk?._id
          change(event.data)
          setFlowTo(
            normalizePath(props.viewPath, toPascalCase(event.data.type))
          )
          return
        }

        case 'navigation': {
          // ignore chairs as it is handled locally
          if (event.data.section === 'chairs') return

          let type = toPascalCase(event.data.section)
          let id = type
          let name = type
          let viewPath = `/App/Account/Content/${type}`
          let patient_id = undefined
          if (type === 'Patients') {
            // missmatch between core and greyfinch, Patients here means the list of patients
            type = 'Patient'
            let { data, error } = await client
              .query(queryPatientInfo, { id: event.data.params.patient_id })
              .toPromise()

            if (cancel || error) return
            let patient = data.vaxiom_patients_by_pk
            id = patient._id
            patient_id = patient._id
            name = `${patient.person.first_name} ${patient.person.last_name}`
          }

          if (['admin', 'hr_admin'].includes(event.data.section)) {
            viewPath = `/App/Account/Content/Settings/AdminContent/SettingsContent/${type}`
            // make sure it's kept within the Settings tab instead of opening a new one
            id = 'Settings'
            type = 'Settings'
            name = 'Settings'
          } else if (['reports'].includes(event.data.section)) {
            viewPath = `/App/Account/Content/Insights/${type}`
          }

          let tab = {
            id,
            patient_id,
            name,
            type,
            tab: event.data.params.tab,
            viewPath,
            coreParams: event.data.params,
          }

          submit({ type: 'tabs/add', tab })
          return
        }
        default:
          return
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
