// this is an autogenerated file from DesignSystem/DataVaxiomSysOrganizationsLocationsSelect/Locations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomSysOrganizationsLocationsSelect_Locations{
  vaxiom_sys_organizations(
    where: { level: { _eq: "location" }, deleted: { _eq: false } }
    order_by: { name: asc }
  ) {
    id
    _id
    text: name
  }
}

`