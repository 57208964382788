import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useListItemDataOnSubmit} */
export default function useListItemDataOnSubmit(props, data) {
  let id = useDataValue({
    context: 'event',
    path: 'appointment._id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit() {
    let mutationResponse = await executeMutation({
      id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem deleting the appointment. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The appointment was deleted successfully.`))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
