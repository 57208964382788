// this is an autogenerated file from DesignSystem/Data/DataVaxiom/DataVaxiomTxCardFieldDefinitions/DataVaxiomTxCardFieldDefinitionsCardWrite/Type/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_Data_DataVaxiom_DataVaxiomTxCardFieldDefinitions_DataVaxiomTxCardFieldDefinitionsCardWrite_Type{
  vaxiom_tx_card_field_types(
    where: { deleted: { _is_null: true } }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`