// this is an autogenerated file from DesignSystem/CommsThread/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__DesignSystem_CommsThread($id: uuid!) {
  thread: comms_threads_by_pk(id: $id) {
    id
    status
    type
    name
    parent_company_id
    allowed_to_send_from {
      id
      text
    }
    resources
    inbound_notification
    users {
      id
      hub_access_status
      vaxiom_person {
        id
        title
        first_name
        last_name
      }
    }
    mobile_numbers_inbound {
      id
      number
    }
    events(
      limit: 1
      where: { _or: [{ type: { _eq: sms } }, { type: { _eq: internal } }] }
      order_by: { created_at: desc }
    ) {
      id
      internal {
        id
      }
      sms {
        id
        mobile_number_outbound {
          id
          number
          name
        }
      }
    }
  }
}

`