import { useEffect } from 'react'

export default function Countdown({
  onChange,
  every = 1000,
  countFrom = 30,
  countTo = 0,
}) {
  useEffect(() => {
    if (typeof onChange !== 'function') return

    let cancel = false
    let value = countFrom

    onChange(value)

    let id = setInterval(() => {
      if (cancel) return

      value--

      if (value < countTo) {
        clearInterval(id)
        return
      }

      onChange(value)
    }, every)

    return () => {
      cancel = true
      clearInterval(id)
    }
  }, [every, countTo, countFrom])

  return null
}
