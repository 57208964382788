import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    context: 'automation',
    path: 'id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'edit_automation', id })
  }
}
