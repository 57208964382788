// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/NewImageSeriesAction/Content/Treatment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_NewImageSeriesAction_Content_Treatment($patient_id: numeric!) {
  vaxiom_txs(where: { tx_card: { patient_id: { _eq: $patient_id } } }) {
    id
    text: name
  }
}

`