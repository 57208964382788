import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutationInApp from './mutationInApp.graphql.js'
import mutationSms from './mutationSms.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let [, executeInAppMutation] = useMutation(mutationInApp)
  let [, executeSmsMutation] = useMutation(mutationSms)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ args, value }) {
    if (value.send_to_person_vaxiom_id === null) return
    if (typeof props.onSubmit !== 'function') {
      notify(notifyError('Missing submit function on SendPatientMessage'))
      setFlowTo(normalizePath(props.viewPath, 'No'))
      return
    }

    let extraArgs = {}

    if (typeof props.onSubmitPre === 'function') {
      try {
        let onSubmitPreResponse = await props.onSubmitPre({
          args,
          value,
          props,
        })
        if (onSubmitPreResponse === false) return

        if (typeof onSubmitPreResponse === 'object') {
          extraArgs = onSubmitPreResponse
        }
      } catch (error) {
        return notify(notifyError(`Can't send message`))
      }
    }

    switch (value.contact_method.type) {
      case 'InApp': {
        let mutationResponse = await executeInAppMutation({
          person_id: value.send_to_person_vaxiom_id,
        })
        if (mutationResponse.error) {
          return notify(notifyError(`Can't send message`))
        }
        props.onSubmit({
          type: 'tab/openCommsThread',
          thread_id: mutationResponse.data.thread.id,
          is_secure: true,
          auto_uploaded_attachments: value.auto_uploaded_attachments,
          message: value.message,
          tags: value.tags,
          ...extraArgs,
        })
        break
      }
      case 'QrCode': {
        props.onSubmit({
          type: 'showQrCode',
        })
        break
      }
      case 'Email': {
        let mutationResponse = await executeInAppMutation({
          person_id: value.send_to_person_vaxiom_id,
        })
        if (mutationResponse.error) {
          return notify(notifyError(`Can't send message`))
        }
        props.onSubmit({
          type: 'tab/openCommsThread',
          thread_id: mutationResponse.data.thread.id,
          is_secure: false,
          auto_uploaded_attachments: value.auto_uploaded_attachments,
          message: value.message,
          tags: value.tags,
          ...extraArgs,
        })
        break
      }
      case 'Phone': {
        let mutationResponse = await executeSmsMutation({
          number: value.contact_method.value,
        })
        if (mutationResponse.error) {
          return notify(notifyError(`Can't send message`))
        }
        props.onSubmit({
          type: 'tab/openCommsThread',
          thread_id: mutationResponse.data.thread.id,
          message: value.message,
          ...extraArgs,
          tags: [],
        })
        break
      }
      default: {
      }
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  }
}
