// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Phone/NewPhone/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Phone_NewPhone_Content($number: String!, $person_id: numeric!) {
  vaxiom_contact_phones(where: { number: { _eq: $number } }) {
    id
  }
  self: vaxiom_contact_phones(
    where: {
      number: { _eq: $number }
      contact_method: { association: { person_id: { _eq: $person_id } } }
    }
  ) {
    id
  }
}

`