export function isEmpty(value) {
  return Array.isArray(value) ? value.length === 0 : !value
}

export function dateObjectOut(value) {
  if (!value) return null

  let temp = value.split('-')
  return new Date(temp[0], temp[1] - 1, temp[2])
}
