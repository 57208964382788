// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Tools/AttachmentAction/InApp/Documents/Content/SystemFiles/FilesTree/ImageSeries/Body/Content/Images/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Tools_AttachmentAction_InApp_Documents_Content_SystemFiles_FilesTree_ImageSeries_Body_Content_Images($image_series_id: numeric!) {
  vaxiom_patient_images(where: { imageseries_id: { _eq: $image_series_id } }) {
    id
    file_key
    type_key
    slot_key
  }
}

`