import { conformToMask } from 'text-mask-core'
import { login, logout } from 'Data/Auth.js'
import camelCase from 'lodash/camelCase'
import {
  APPOINTMENT_TYPE_COLORS,
  MESSAGE_ACTIONS,
  PATIENT_IMAGE_NAMES,
  THREAD_STATUSES,
  TREATMENT_COLORS,
} from './constants.js'
import differenceInYears from 'date-fns/differenceInYears'
import differenceInMonths from 'date-fns/differenceInMonths'
import formatDuration from 'date-fns/formatDuration'
import _formatDate from 'date-fns/format'
import _formatNumber from 'number-format.js'
import get from 'lodash/get'
import intersectionBy from 'lodash/intersectionBy'
import isValidDate from 'date-fns/isValid'
import padStart from 'lodash/padStart'
import parseDate from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import isLeapYear from 'date-fns/isLeapYear'
import subDays from 'date-fns/subDays'
import subWeeks from 'date-fns/subWeeks'
import subMonths from 'date-fns/subMonths'
import subQuarters from 'date-fns/subQuarters'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import getWeek from 'date-fns/getWeek'
import setWeek from 'date-fns/setWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfQuarter from 'date-fns/startOfQuarter'
import endOfQuarter from 'date-fns/endOfQuarter'
import getQuarter from 'date-fns/getQuarter'
import getYear from 'date-fns/getYear'
import isBefore from 'date-fns/isBefore'
import _getUnixTime from 'date-fns/getUnixTime'
import _fromUnixTime from 'date-fns/fromUnixTime'
import { generateLetter as generateVerhoeffLetter } from './verhoeff.js'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import addMinutes from 'date-fns/addMinutes'
import formatRelative from 'date-fns/formatRelative'
import { getValue as getTitleAsText } from 'DesignSystem/Data/DataVaxiom/DataVaxiomPerson/DataVaxiomPersonTitleValue/helpers.js'
import isAfter from 'date-fns/isAfter'
import startOfDay from 'date-fns/startOfDay'
import addDays from 'date-fns/addDays'

export { logout, login }

function formatDate(rvalue, formatIn, formatOut) {
  let value =
    rvalue instanceof Date
      ? rvalue
      : formatIn === 'iso'
      ? parseISO(rvalue)
      : parseDate(rvalue, formatIn, new Date())
  return isValidDate(value) ? _formatDate(value, formatOut) : rvalue
}

function npsValues(value) {
  let total = value.total.aggregate.count || 0

  return {
    promoter: Math.round((value.promoter.aggregate.count / total) * 100) || 0,
    detractor: Math.round((value.detractor.aggregate.count / total) * 100) || 0,
    passive: Math.round((value.passive.aggregate.count / total) * 100) || 0,
  }
}

export function timeISOIn(value) {
  return formatDate(value, 'iso', 'h:mm a')
}

export function npsLineChart(value) {
  return (
    value.length > 0 &&
    value.map(({ completed_date, total, promoter, detractor }) => ({
      x: dateShortInNoYear(completed_date.split('T')[0]),
      y:
        total === 0
          ? 0
          : Math.round((promoter / total) * 100 - (detractor / total) * 100),
    }))
  )
}

export function getIsoTimeStringDisplay(value, military = false) {
  if (!value.includes('T')) return value

  let suffix = ''
  let rTime = value.split('T')[1].split('Z')[0]
  let [hours, mins] = rTime.split(':')

  if (!military) {
    suffix = 'am'
    if (hours >= 12) {
      suffix = 'pm'
    }
    if (hours > 12) {
      hours = hours - 12
    }
  }
  return `${hours}:${mins}${suffix}`
}

export function getIsoDateStringDisplay(value, format = 'MM/dd/yyyy') {
  if (!value.includes('T')) return value

  let rDate = value.split('T')[0]
  let [yyyy, mm, dd] = rDate.split('-')

  return format.replace('yyyy', yyyy).replace('MM', mm).replace('dd', dd)
}

export function dateShortInNoYear(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd')
}

export function formatEstimatedSchedulingDate(value) {
  let estimated_scheduling_date = new Date(value)
  let day = estimated_scheduling_date.getDate()
  let dayInfo = day > 20 ? 'Late' : day > 10 ? 'Mid' : 'Early'
  return `${dayInfo} ${_formatDate(estimated_scheduling_date, 'MMM yyyy')}`
}

export function formatTimegridAppointmentTime(value) {
  return `${formatTimegridEventIsoTime(
    value.start,
    false
  )} - ${formatTimegridEventIsoTime(value.end, true)}`
}

function formatTimegridEventIsoTime(value, displaySuffix) {
  let suffix = ''
  let rTime = value.split('T')[1].split('Z')[0]
  let [hours, mins] = rTime.split(':')

  suffix = ' AM'
  if (hours >= 12) {
    suffix = ' PM'

    if (hours > 12) {
      hours = hours - 12
    }
  }

  return `${hours}:${mins}${displaySuffix ? suffix : ''}`
}

export function npsPieChart(value) {
  return Object.entries(npsValues(value)).map(([key, value]) => ({
    x: `${value}%`,
    y: value,
  }))
}

export function npsPromoter(value) {
  return npsValues(value).promoter
}

export function npsDetractor(value) {
  return npsValues(value).detractor
}

export function npsPassive(value) {
  return npsValues(value).passive
}

export function npsScore(value) {
  let { promoter, detractor } = npsValues(value)

  return `${promoter - detractor}`
}

export function npsBreakdown(value) {
  let { promoter, detractor, passive } = npsValues(value)

  return `Promoter:${promoter}%  Passive:${passive}%  Detractor:${detractor}%`
}

export function timeTextConvert(value) {
  let t = value.split(':')

  return t.length > 1
    ? `${t[0] > 12 ? t[0] - 12 : t[0]}.${t[1]} ${t[0] > 12 ? 'pm' : 'am'}`
    : value
}

export function formatNumber(value, format) {
  return typeof value === 'number' ? _formatNumber(format, value) : value
}

export function formatTime(hours, minutes, seconds = 0) {
  return `${formatNumber(hours, '0#')}:${formatNumber(
    minutes,
    '0#'
  )}:${formatNumber(seconds, '0#')}`
}

export function address(address) {
  return `${toTitleCase(address.street)}, ${toTitleCase(address.city)}, ${
    address.state
  }, ${address.zip}`
}

export function format_address(address) {
  return `${toTitleCase(address.address_line1)}, ${toTitleCase(
    address.city
  )}, ${address.state}, ${address.zip}`
}

export function age(date_of_birth) {
  return differenceInYears(new Date(), parseISO(date_of_birth))
}

export function ageInYearsAndMonths(date_of_birth) {
  let monthsDiff = differenceInMonths(new Date(), parseISO(date_of_birth))

  let years = Math.trunc(monthsDiff / 12)
  let months = monthsDiff % 12

  return formatDuration(
    {
      years,
      months,
    },
    { format: ['years', 'months'] }
  )
}

export function months(date_of_birth) {
  return differenceInMonths(new Date(), parseISO(date_of_birth)) % 12
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function socialSecurityNumber(input) {
  if (input && SOCIAL_SECURITY_NUMBER.test(input)) return input
  let normalizedValue = input.replace(/[^0-9]/g, '')
  if (normalizedValue.length === 9) {
    let tokens = [
      normalizedValue.slice(0, 3),
      normalizedValue.slice(3, 5),
      normalizedValue.slice(5, 9),
    ]
    return tokens.join('-')
  }
  return input
}
let SOCIAL_SECURITY_NUMBER_TO_HIDE = /^\d{3}-\d{2}/
export function socialSecurityNumberHide(value) {
  return value && value.replace(SOCIAL_SECURITY_NUMBER_TO_HIDE, '•••-••')
}

export function memberId(value) {
  return typeof value === 'string' ? padStart(value, 8, '0') : value
}

export function textToNumber(value) {
  let res =
    typeof value === 'string'
      ? parseFloat(value.replace(/[^0-9.]/g, ''))
      : value

  return isNaN(res) ? 0 : res
}

export function toTitleCase(str) {
  return str
    ? str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
      )
    : ''
}

export function numberToText(value) {
  return typeof value === 'number' ? `${value}` : value
}

export function dateShortIn(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd/yyyy')
}
export function dateShortOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'yyyy-MM-dd')
}

export function dateShortInFull(value) {
  return formatDate(value, 'yyyy-MM-dd', 'EEEE, MMMM do yyyy')
}

export function dateyyyyMMddToMMyyyy(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/yyyy')
}

export function dateISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy')
}
export function dateISOOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'iso')
}

export function dateISOToeeeeMMMMdo(value) {
  return formatDate(value, 'iso', 'eeee MMMM do')
}

export function dateISOToDateAndTime(value) {
  return formatDate(value, 'iso', 'M/dd/y h:mm b').toLowerCase()
}

export function dateISOToDateAndTimeShort(value) {
  return formatDate(value, 'iso', 'M/dd/yy h:mma').toLowerCase()
}

export function dateISOToDateAndTimeShortReporting(value) {
  return formatDate(value, 'iso', 'M/dd/yy h:mma z')
}

export function dateISOToTime(value) {
  return formatDate(value, 'iso', 'h:mma').toLowerCase()
}

export function localStartTime(value) {
  return _formatDate(parseISO('1970-01-01T' + value), 'h:mmaaa')
}
export function reportTime(value) {
  return _formatDate(parseISO('1970-01-01T' + value), 'hh:mm a')
}

export function getTimeDisplay(value) {
  return _formatDate(parseISO(value.split('Z')[0]), 'HH:mm aaa')
}

export function timestampISOIn(value) {
  return formatDate(value, 'iso', 'HH:mm MM/dd/yyyy z')
}

/**
 * @param {string} localStartDate
 * @param {string} localStartTime
 * @param {number} duration
 * @returns {string}
 */
export function dateFormatRelativeDisplay(
  localStartDate,
  localStartTime,
  duration
) {
  let startDateTime = parseDate(
    `${localStartDate} ${localStartTime}`,
    'yyyy-MM-dd HH:mm:ss',
    new Date()
  )
  let endDateTime = addMinutes(startDateTime, duration)
  let relativeDate = formatRelative(startDateTime, new Date())

  if (relativeDate.includes(' at')) {
    relativeDate = relativeDate.split(' at')[0]
  }

  return `${relativeDate}, ${_formatDate(
    startDateTime,
    'h:mma'
  )} - ${_formatDate(endDateTime, 'h:mma')}`
}

let PHONE_NUMBER_US_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
let DEFAULT_PHONE_CODE = '+1'
export function phoneNumberUS(rrvalue) {
  let rvalue = addPrefixIfMissing(rrvalue)
  if (!rvalue || !rvalue.startsWith(DEFAULT_PHONE_CODE)) return rvalue

  return conformToMask(
    rvalue.replace(DEFAULT_PHONE_CODE, ''),
    PHONE_NUMBER_US_MASK
  ).conformedValue
}

export function procedureFee(value, data) {
  return formatNumber(
    value,
    data.procedure.is_patient_fee_percentage ? '###.##%' : '$#,##0.00'
  )
}

export function percentage(value) {
  return value === 0 ? '0%' : formatNumber(value || 0, '###.##%')
}

export function prePaid(value, data) {
  return data.procedure.prepaid === 0 ? '-' : data.procedure.prepaid
}

export function booleanToYesNo(value) {
  return value ? 'Yes' : 'No'
}

export function range(start, end, increment = 1) {
  let list = []
  for (let i = start; i <= end; i += increment) {
    list.push(i)
  }
  return list
}

export function getPrice(pricing, people) {
  return pricing[getTier(people)]
}

function memberEmployerContributionValue(value, data) {
  let plan = get(
    data,
    'member.plan.member_group_contributions[0].contribution',
    null
  )
  if (!plan) return null

  let people =
    get(data, 'member.related_members_aggregate.aggregate.count', 0).length + 1

  return getPrice(plan, people)
}

export function memberEmployerContribution(value, data) {
  return formatNumber(memberEmployerContributionValue(value, data), '$#,##0.00')
}

export function memberCost(value, data) {
  let employerContribution = memberEmployerContributionValue(value, data)
  let totalCost = memberTotalCostValue(value, data)

  return formatNumber(totalCost - employerContribution, '$#,##0.00')
}

function getTier(people) {
  switch (people) {
    case 1:
      return 'one_person'

    case 2:
      return 'two_people'

    case 3:
      return 'three_people'

    case 4:
      return 'four_people'

    default:
      return 'five_people_or_more'
  }
}

function memberTotalCostValue(value, data) {
  let pricing = get(
    data,
    'member.plan.member_group_contributions[0].contribution',
    null
  )
  if (!pricing) return null
  let people =
    get(data, 'member.related_members_aggregate.aggregate.count', 0).length + 1
  let tier = getTier(people)

  return getPrice(pricing, tier)
}

export function memberTotalCost(value, data) {
  return formatNumber(memberTotalCostValue(value, data), '$#,##0.00')
}

export function memberCostTier(data, tier) {
  let cost = data.plan.pricing[tier]
  let contribution = data.plan.member_group_contributions[0].contribution[tier]

  return formatNumber(cost - contribution, '$#,##0.00')
}

export function memberCostOnePerson(value, data) {
  return memberCostTier(data, 'one_person')
}
export function memberCostTwoPeople(value, data) {
  return memberCostTier(data, 'two_people')
}
export function memberCostThreePeople(value, data) {
  return memberCostTier(data, 'three_people')
}
export function memberCostFourPeople(value, data) {
  return memberCostTier(data, 'four_people')
}
export function memberCostFivePeopleOrMore(value, data) {
  return memberCostTier(data, 'five_people_or_more')
}

export function memberCoveredMembers(value) {
  return value + 1
}

export function numberThousand(value) {
  return formatNumber(value, '#,##0.00')
}

export function addressStreetZipCityState(value) {
  return value
    ? `${value.street}, ${value.zip}, ${value.city}, ${value.state}`
    : '-'
}

export function locationsProfessional(value) {
  return value.professional_locations
    .map(item => item.provider_location.doing_business_as_and_address)
    .join('; ')
}

export function locationsLastProfessional(value) {
  return value.professional_locations
    .filter(
      item =>
        get(
          item,
          'provider_location.professional_locations_aggregate.aggregate.count'
        ) === 1
    )
    .map(item => item.provider_location.doing_business_as_and_address)
    .join('; ')
}

export function locationsLastPrimaryCareProfessional(value) {
  return (
    value.license &&
    value.license.is_primary_care &&
    value.professional_locations
      .some(
        item =>
          get(
            item,
            'provider_location.primary_care_professional_locations_aggregate.aggregate.count'
          ) === 1
      )
      .map(item => item.provider_location.doing_business_as_and_address)
      .join('; ')
  )
}

export function isLastProfessionalInALocation(value) {
  return value.professional_locations.some(
    item =>
      get(
        item,
        'provider_location.professional_locations_aggregate.aggregate.count'
      ) === 1
  )
}

export function isLastPrimaryCareProfessionalInALocation(value) {
  return (
    value.license &&
    value.license.is_primary_care &&
    value.professional_locations.some(
      item =>
        get(
          item,
          'provider_location.primary_care_professional_locations_aggregate.aggregate.count'
        ) === 1
    )
  )
}

export function isLastSuperAdminInCompany(user) {
  if (
    !Array.isArray(user.company_users) ||
    user.company_users.length === 0 ||
    user.company_users[0].role === 'company-user'
  )
    return false

  return user.company_users.some(
    item => get(item, 'company.company_users_aggregate.aggregate.count') === 1
  )
}

export function relatedMembers(value) {
  return value.map(item => item.profile.first_name).join(', ')
}

export function threadName(thread) {
  return thread.type === 'sms'
    ? thread.mobile_numbers_inbound
        .flatMap(item => item.vaxiom_persons)
        .filter(item => item?.vaxiom_person) //remove any potential empties
        .map(
          person =>
            `${person.vaxiom_person.first_name} ${person.vaxiom_person.last_name}`
        )
        .join(', ') ||
        `${phoneNumberUS(
          thread.mobile_numbers_inbound[0].number
        )} • No patient match`
    : thread.id
}

export function threadNameNoMatch(thread) {
  return thread.type === 'sms'
    ? thread.mobile_numbers_inbound
        .flatMap(item => item.vaxiom_persons)
        .filter(item => item?.vaxiom_person) //remove any potential empties
        .map(
          person =>
            `${person.vaxiom_person.first_name} ${person.vaxiom_person.last_name}`
        )
        .join(', ') || `No patient match`
    : thread.id
}

export function statusActionDescription(compose_message, status) {
  return MESSAGE_ACTIONS[compose_message ? 'withMessage' : 'noMessage'][status]
}

export function statusActionName(status) {
  return MESSAGE_ACTIONS['tags'][status]
}

export function outboundNumber(outbound_number) {
  return `${outbound_number.name} ${phoneNumberUS(outbound_number.number)}`
}

export function selectedOutboundNumber(
  thread_outbound_numbers,
  all_outbound_numbers
) {
  return [
    ...intersectionBy(thread_outbound_numbers, all_outbound_numbers, 'id'),
    ...all_outbound_numbers,
    null,
  ][0]
}

export function threadNamePlaceholder(thread) {
  return `Message ${threadName(thread)}`
}

export function threadOutBoundNumbers(thread) {
  return thread.mobile_numbers_outbound.map(item => ({
    id: item.id,
    number: item.number,
    name: item.name,
  }))
}

export function statusPersonName(person) {
  return person ? `${person.first_name} ${person.last_name}` : 'StatusBot'
}

export function personPhone(phone) {
  return phone.description
    ? `${phoneNumberUS(addPrefixIfMissing(phone.number))} (${
        phone.description
      })`
    : phoneNumberUS(addPrefixIfMissing(phone.number))
}

export function identity(value) {
  return value
}

function isSelectedStatusX(list, x) {
  return list.includes(x)
}
function toggleSelectedStatusX(list, x) {
  // untoggle CLOSED if any other status is selected
  let statuses = list.filter(item => item !== CLOSED)
  return isSelectedStatusX(statuses, x)
    ? statuses.length === 1
      ? statuses
      : statuses.filter(item => item !== x)
    : [...statuses, x]
}

let OPEN = 'open'
export function isSelectedStatusOpen(value) {
  return isSelectedStatusX(value, OPEN)
}
export function toggleSelectedStatusOpen(value, data) {
  return toggleSelectedStatusX(data.selected.statuses, OPEN)
}

let CLOSED = 'closed'
export function isSelectedStatusClosed(value) {
  return isSelectedStatusX(value, CLOSED)
}
export function toggleSelectedStatusClosed(value, data) {
  return isSelectedStatusClosed(data.selected.statuses) ? [OPEN] : [CLOSED]
}

let ONHOLD = 'onhold'
export function isSelectedStatusOnHold(value) {
  return isSelectedStatusX(value, ONHOLD)
}
export function toggleSelectedStatusOnHold(value, data) {
  return toggleSelectedStatusX(data.selected.statuses, ONHOLD)
}

let PENDING = 'pending'
export function isSelectedStatusPending(value) {
  return isSelectedStatusX(value, PENDING)
}
export function toggleSelectedStatusPending(value, data) {
  return toggleSelectedStatusX(data.selected.statuses, PENDING)
}

export function addPrefixIfMissing(number) {
  let result = (number || '').replace(/[^0-9+]/g, '')
  if (!result || result.startsWith('+1')) {
    return result
  } else if (result.startsWith('1') && result.length > 10) {
    return `+${result}`
  } else return `+1${result}`
}

export function unreadThreadsCount(value) {
  return `New (${value})`
}

export function thresholdPlusOne(value) {
  return Number.isFinite(value) && value >= 2 && value <= 8 ? value + 1 : '?'
}

export function personName(value) {
  if (!value) return ''

  let role = ''
  if (value.is_patient) {
    role = '(Patient)'
  } else if (value.is_payer) {
    role = '(Payer)'
  } else if (value.is_employee) {
    role = '(Employee)'
  } else if (value.is_external_professional) {
    role = '(External professional)'
  }
  return `${value.first_name} ${value.last_name} ${role}`.trimEnd()
}

/**
 * @param {{ first_name: string; last_name: string; greeting?: string }} value
 */
export function patientName(value) {
  return [
    value.first_name,
    value.greeting && value.greeting !== value.first_name
      ? `"${value.greeting}"`
      : null,
    value.last_name,
  ]
    .filter(Boolean)
    .join(' ')
}

export function employeeName(value) {
  return [
    value.title ? getTitleAsText(value.title) : null,
    patientName(value).trim(),
  ]
    .filter(Boolean)
    .join(' ')
    .trim()
}

export function initials(value) {
  if (!value) return ''

  return `${value.first_name.slice(0, 1)}${value.last_name.slice(0, 1)}`
}

export function creditCardOrBankAccount(value) {
  return `****${value}`
}

export function creditCardExpiration(value) {
  return value && `${value.substring(0, 2)}/${value.substring(2)}`
}

export function numberMoney(value) {
  return `$${numberThousand(value)}`
}

export function toSentence(value) {
  return Array.isArray(value)
    ? value.length === 0
      ? 'None'
      : value.join(', ')
    : null
}

export function appointmentTemplateName(value) {
  return value.full_name || value.appointment_type.full_name
}

export function patientHistoryAppointmentDate(value) {
  return `${dateShortInFull(
    value.appointment_bookings[0].local_start_date
  )} ${localStartTime(value.appointment_bookings[0].local_start_time)}`
}

export function getMessageStatusView(value) {
  if (!value) return 'No'

  switch (value) {
    case THREAD_STATUSES.new:
      return 'OpenNew'
    case THREAD_STATUSES.open:
      return 'Open'
    case THREAD_STATUSES.onhold:
      return 'Onhold'
    case THREAD_STATUSES.closed:
      return 'Closed'
    case THREAD_STATUSES.pending:
      return 'Pending'
    default:
      return 'No'
  }
}

export function smsMessageStatus(value) {
  let STATUS_MAPPING = {
    accepted: 'Sending',
    queued: 'Sending',
    sending: 'Sending',
    sent: 'Delivered',
    delivered: 'Delivered',
    undelivered: 'Undelivered',
    failed: 'Failed',
  }

  return STATUS_MAPPING[value] || 'Sending'
}

export function diagnosticsAvgtime(value) {
  return `${value.toFixed(3)} ms`
}

export function diagnosticsAvglength(value) {
  return `${(value / 1024).toFixed(3)} kb`
}

export function diagnosticsStatus(value) {
  if (value.avgtime < 200) {
    return 'rgba(0,207,104)'
  } else if (value.avgtime < 1000) {
    return 'rgb(255,131,0)'
  } else {
    let x = value.avgtime / value.avglength
    if (x < 0.05) {
      return 'rgba(0,207,104)'
    } else if (x < 0.6) {
      return 'rgb(255,131,0)'
    } else {
      return 'rgb(237,55,26)'
    }
  }
}

export function isNotEmptyList(list) {
  return Array.isArray(list) && list.length > 0
}

export function listLength(list) {
  return Array.isArray(list) ? list.length : 0
}

export function toCapitalisedCamelCase(value) {
  return _capitalize(camelCase(value))
}

function _capitalize(value) {
  if (!value || typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function isPhoneNumber(rvalue) {
  if (typeof rvalue !== 'string') return false

  let value = addPrefixIfMissing(rvalue.replace(/[^0-9+]/g, ''))

  return /^\+[1-9]\d{10,14}$/.test(value)
}

export function hasManyItems(list) {
  return Array.isArray(list) && list.length > 1
}

export function getOneOrManyView(list) {
  return hasManyItems(list) ? 'Many' : 'One'
}

export function getAddOrEditView(value) {
  return value ? 'Edit' : 'Add'
}

export function getSelectedLocationsView(value) {
  return value.length > 1
}

export function isNotPublicRoleAndTokenValid(value) {
  return (
    value &&
    value.api_role !== 'public' &&
    value.access_token_data &&
    value.access_token_data.exp * 1000 > Date.now()
  )
}

export function isInApp(type) {
  return type === 'in_app'
}

export function isInAppMessage(type) {
  if (type === 'in_app') {
    return 'Secure Message'
  }

  return 'Text Message'
}

export function not(value) {
  return !value
}

let MAX_MESSAGE_LENGTH_SMS = 1600
export function isSmsMaxLength(type) {
  return type === 'sms' ? MAX_MESSAGE_LENGTH_SMS : null
}

function toDate(dateValue) {
  return formatISO(dateValue, { representation: 'date' })
}

export function appointmentTypeColor(color_id) {
  return APPOINTMENT_TYPE_COLORS[color_id] || '#f1f1f1'
}

/*

    Given 24 November 2021

    This -> Month -> 2021 = would be November 2021
    Would display November 2021, October 2021, November 2020 values
    ...

    This -> Day -> 2021 = would be 24 Nov 2021
    Would display 24 November 2021, 23 November 2021, 24 November 2020
    ...

    Last -> Month -> 2021 = would be October 2021
    Would display October 2021, September 2021, October 2020 values
    ...

    Last -> Day -> 2021 = would be 23 November 2021
    Would display 23 November 2021, 22 November 2021, 23 November 2020
    ...

  */

export function dateRange({ period_unit_id, period_type_id, period_year_id }) {
  let dateRanges = []
  let baseDate = new Date()

  if (period_type_id !== 'current') {
    switch (period_unit_id) {
      case 'day':
        baseDate = subDays(baseDate, 1)
        break
      case 'week':
        baseDate = subWeeks(baseDate, 1)
        break
      case 'month':
        baseDate = subMonths(baseDate, 1)
        break
      case 'quarter':
        baseDate = subQuarters(baseDate, 1)
        break
      default:
    }
  }

  if (period_year_id !== baseDate.getFullYear()) {
    baseDate.setFullYear(period_year_id)
  }

  let baseYear = baseDate.getFullYear()
  let baseMonth = baseDate.getMonth()
  let baseDay = baseDate.getDate()

  let lastYear = baseYear - 1

  switch (period_unit_id) {
    case 'day':
      // selected and previous
      dateRanges.push([toDate(baseDate), toDate(subDays(baseDate, 1))])

      // same date last year unless leap year where we pull the 28th instead (29-1)
      if (
        !isLeapYear(new Date(lastYear, baseMonth, baseDay)) &&
        baseMonth === 1 &&
        baseDay === 29
      ) {
        baseDay--
      }
      // selected last year
      dateRanges.push([toDate(new Date(lastYear, baseMonth, baseDay))])
      break

    case 'week':
      dateRanges.push([
        toDate(startOfWeek(subWeeks(baseDate, 1))),
        toDate(endOfWeek(baseDate)),
      ])

      // selected last year
      let weekLastYear = setWeek(
        new Date(lastYear, baseMonth, baseDay),
        getWeek(baseDate)
      )
      dateRanges.push([
        toDate(startOfWeek(weekLastYear)),
        toDate(endOfWeek(weekLastYear)),
      ])
      break

    case 'month':
      // selected and previous
      dateRanges.push([
        toDate(startOfMonth(subMonths(baseDate, 1))),
        toDate(endOfMonth(baseDate)),
      ])

      // selected last year
      dateRanges.push([
        toDate(startOfMonth(new Date(lastYear, baseMonth, 1))),
        toDate(endOfMonth(new Date(lastYear, baseMonth, 1))),
      ])
      break

    case 'quarter':
      // selected and previous

      dateRanges.push([
        toDate(startOfQuarter(subQuarters(baseDate, 1))),
        toDate(endOfQuarter(baseDate)),
      ])

      // selected last year
      dateRanges.push([
        toDate(startOfQuarter(subQuarters(baseDate, 4))),
        toDate(endOfQuarter(subQuarters(baseDate, 4))),
      ])
      break
    default:
  }
  return dateRanges
}

export function getXAxisLabelFromDate(rvalue, unit) {
  let value = parseISO(rvalue)

  switch (unit) {
    case 'quarter':
      return `Q${getQuarter(value)} ${getYear(value)}`
    case 'day':
      return _formatDate(value, 'd MMM y')
    case 'week':
      return `Wk ${_formatDate(value, 'w y')}`
    case 'month':
      return _formatDate(value, 'MMM y')
    default:
      return value.toString()
  }
}

export function formatTableData(columns, data) {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)]
      }

      return [...memo, column]
    }, [])
  }

  let typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column }
  }, {})

  function formatValue(value, { type, format } = {}) {
    if (value === undefined) {
      return value
    }

    if (type === 'boolean') {
      if (typeof value === 'boolean') {
        return value.toString()
      } else if (typeof value === 'number') {
        return Boolean(value).toString()
      }

      return value
    }

    if (type === 'number' && format === 'percent') {
      return [parseFloat(value).toFixed(2), '%'].join('')
    }

    return value.toString()
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])]
      })
    )
  }

  return data.map(format)
}

function maybeDate(value) {
  return value.match(/[-:.]/g) && !value.match(/[ ]/g)
}

export function tableDataPercent(value) {
  return tableData(value, { isPercent: true })
}

export function tableDataCurrency(value) {
  return tableData(value, { isCurrency: true })
}

export function dataValueFormat(value, options) {
  if (!options) return value

  if (!value) return 0

  if (options.isPercent) return `${Number.parseFloat(value).toFixed(2)}%`

  if (options.isCurrency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)
  }

  return new Intl.NumberFormat('en-US').format(value)
}

export function tableData(value, options) {
  let columns = value?.columns
    .map((item, i) => {
      if (item?.dataIndex === 'measures') return false

      return {
        Header: maybeDate(item.shortTitle)
          ? getXAxisLabelFromDate(item.shortTitle, value.unit)
          : item.shortTitle,
        accessor: item.dataIndex.replace(/[-:. ,]/g, ''),
      }
    })
    .filter(Boolean)

  // fill empty accessors cells if they exist
  let filled = {}
  let accessors = columns.map(item => item.accessor)
  accessors.forEach(accessor => (filled[accessor] = '-'))

  let data = value?.data.map((item, i) => {
    return {
      id: i + 1,
      ...filled,
      ...Object.keys(item).reduce((obj, key) => {
        return Object.assign(obj, {
          [key.replace(/[-:. ,]/g, '')]: isFinite(item[key])
            ? dataValueFormat(item[key], options)
            : item[key],
        })
      }, {}),
    }
  })

  return { columns, data }
}

export function decomposeData(data, pivotConfig) {
  return data
    .decompose()
    .map(item => {
      if (item?.loadResponse?.results[0]?.data?.length > 0) {
        return {
          data: item.tablePivot(pivotConfig),
          columns: item.tableColumns(pivotConfig),
        }
      }
      return false
    })
    .filter(Boolean)
}

export function flattenResultSetColumns(decomposedData) {
  let trackColumns = new Set()
  return [
    ...decomposedData
      .flatMap(item => item.columns)
      .map(i => i.children || i)
      .flat()
      .filter(item => {
        if (!trackColumns.has(item.dataIndex)) {
          trackColumns.add(item.dataIndex)
          return true
        }
        return false
      }),
  ]
}

export function flattenResultSetData(decomposedData, sortKey) {
  let finalData = new Map()

  decomposedData.forEach(item => {
    item.data?.forEach(res => {
      if (!res[sortKey]) return

      if (finalData.has(res[sortKey])) {
        let existing = finalData.get(res[sortKey])
        finalData.set(res[sortKey], { ...existing, ...res })
      } else {
        finalData.set(res[sortKey], res)
      }
    })
  })

  return [...finalData.values()]
}

export function isEmptyList(list) {
  return Array.isArray(list) && list.length === 0
}

export function getPatientImageName(value) {
  return PATIENT_IMAGE_NAMES[value] || value || 'Misc'
}

export function isWaiting(value) {
  return value === 'waiting'
}

export function getImageSeriesName(value) {
  if (!value) return
  return value.series_date
    ? `${dateISOIn(value.series_date)} - ${value.name}`
    : value.name
}

export function getImageSeriesAndImageName(value) {
  return `${getImageSeriesName(value.imageseries)} (${getPatientImageName(
    value.slot_key
  )})`
}

export function ifEmpty(value) {
  return value || '-'
}

export function formatDateDisplay(value) {
  return dateShortIn(value) || '-'
}

export function capitalize(value) {
  if (!value || typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export function toPostgresArray(list) {
  return `{${list.map(item => JSON.stringify(item)).join(',')}}`
}

export function fromPostgresArray(input) {
  if (!input || !input.startsWith('{') || !input.endsWith('}')) {
    return null
  }
  return input
    .substring(1, input.length - 1)
    .split(',')
    .filter(Boolean)
    .map(item => JSON.parse(item))
}

export function toLowerCase(value) {
  return value.toLowerCase()
}

export function isResponsibleParty(relationship) {
  return relationship?.role === 'RESPONSIBLE'
}

export function even(value) {
  return value % 2 === 0
}

export function odd(value) {
  return value % 2 !== 0
}

export function today() {
  return formatISO(Date.now(), { representation: 'date' })
}

export function getUnixTime(value) {
  let date = typeof value === 'string' ? parseISO(value) : value
  return _getUnixTime(date)
}

export function dateShortOutFromUnixTime(value) {
  return dateShortOut(_fromUnixTime(value))
}

export function dateShortOutTempFixForMaskedInput(value) {
  let length = value.split('/')?.[2].length
  let formatted = dateShortOut(value)
  return length !== 4 || !isValidDate(new Date(formatted)) ? value : formatted
}

export function getTreatmentColor(color) {
  return APPOINTMENT_TYPE_COLORS[TREATMENT_COLORS[color]]
}

export function humanId(value) {
  return generateVerhoeffLetter(value)
}

export function isAppointmentScheduled(appointment) {
  return (
    appointment.booking &&
    !['NO_SHOW', 'PATIENT_CANCELLED', 'OFFICE_CANCELLED'].includes(
      appointment.booking.state
    )
  )
}

export function isAppointmentBookingLocked(appointment) {
  return (
    isAppointmentScheduled(appointment) &&
    isBefore(
      new Date(`${appointment.booking.start_time}Z`),
      subDays(new Date(), 1)
    )
  )
}

export function isFutureAppointmentBooking(booking) {
  return isAfter(
    new Date(booking.start_time),
    startOfDay(addDays(new Date(), 1))
  )
}

/**
 * @param {string} value
 * @returns {string}
 */
export function preserveLineBreaks(value) {
  return value.replace(/\n/g, '<br />')
}

/**
 * @param {string} value
 */
export function textWithNewLines(value) {
  if (!value.includes('\n')) return value

  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{ __html: `${preserveLineBreaks(value)}` }}
    />
  )
}

/**
 * @param {string} value
 */
export function textWithLinks(value) {
  if (!hasUrls(value)) return textWithNewLines(value)

  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{
        __html: `${preserveLineBreaks(replaceUrlsWithLinks(value))}`,
      }}
    />
  )
}

/**
 * @param {string} value
 */
export function textWithHTML(value) {
  if (!hasHTML(value)) return textWithLinks(value)

  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{ __html: `${preserveLineBreaks(value)}` }}
    />
  )
}

export function stripHtml(text) {
  if (text === null) return ''

  let parser = new DOMParser()
  let doc = parser.parseFromString(text, 'text/html')
  return doc.body.textContent || ''
}

function hasUrls(text) {
  let urlPattern = /(https?:\/\/\S+)/gi
  return urlPattern.test(text)
}

export function hasHTML(text) {
  let urlPattern = /<[a-z][\s\S]*>/gi
  return urlPattern.test(text)
}

export function replaceUrlsWithLinks(text) {
  let urlPattern = /(https?:\/\/\S+)/gi

  function replaceUrl(match) {
    let url = match.trim()
    let sanitizedUrl = url.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    return `<a href="${sanitizedUrl}" target="_blank">${sanitizedUrl}</a>`
  }

  // Create a new DOMParser instance
  let parser = new DOMParser()

  // Parse the text as HTML and retrieve the text content
  let strippedText = parser.parseFromString(text, 'text/html').documentElement
    .textContent

  // Replace the URLs with links in the stripped text
  let replacedText = strippedText.replace(urlPattern, replaceUrl)

  // Return the replaced text
  return replacedText
}

export function listWithUuid(list) {
  return list.map(item => ({
    ...item,
    id: item._id,
  }))
}

export function formatAddress(address) {
  if (!address) {
    return ''
  }
  return [address.city, address.state, address.address_line1, address.zip]
    .filter(Boolean)
    .join(' ')
}

export function twoDigitsTime(time) {
  return padStart(time.toString(), 2, '0')
}

export function timeDifference(t) {
  let minutes = Math.abs(differenceInMinutes(new Date(), t))
  if (minutes >= 60) return `>1 h`
  let seconds = twoDigitsTime(Math.abs(differenceInSeconds(new Date(), t) % 60))
  return `${minutes || '0'}:${seconds}`
}

/**
 * @param {number} minutes
 * @returns {string}
 */
export function minutesToTwoDigitsTime(minutes) {
  // prettier-ignore
  return `${twoDigitsTime(Math.floor(minutes / 60))}:${twoDigitsTime(minutes % 60)}`
}

export function asList(value) {
  return [value]
}

export function convertTxPlanWeeksToMonths(weeks) {
  return Math.round(weeks / 4.34524)
}

let TRANSACTION_TYPE = {
  payment: 'Applied Payment',
  ChargeTransfer: 'Charge Transfer',
  DiscountAdjustment: 'Discount',
  DiscountReverseAdjustment: 'Discount Reverse',
  FixAdjustment: 'Fix Adjustment',
  InstallmentCharge: 'Installment Charge',
  InsuranceLedgerPayment: 'Insurance Ledger',
  LateFeeCharge: 'Late Fee',
  MigrationFixAdjustment: 'Migration Fix',
  MiscFeeCharge: 'MiscFee Charge',
  unapplied_payment: 'Unapplied Payment',
  PaymentPlan: 'Payment Plan',
  PaymentPlanRollback: 'Payment Plan Rollback',
  Refund: 'Refund',
  RetailFeeCharge: 'Retail Fee',
  TransferCharge: 'Transfer Charge',
  TxFeeCharge: 'TxFee Charge',
  WriteOff: 'Write Off',
  TransferPayment: 'Transfer Payment',
  CREDIT: 'Payment',
}

export function transactionType(transaction_type) {
  return TRANSACTION_TYPE[transaction_type] || transaction_type
}
