import { useMutation } from 'Data/Api'
import { useSetFlowTo, normalizePath } from 'Simple/Flow'
import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data'
import {
  notifyError,
  notifyInvalid,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import { DAYS } from 'Data/constants'
import mutation from './add_payment_plan.graphql.js'
import {
  getRealizationDatesForAPaymentPlan,
  getPlanLengthInMonths,
  howManyInstallmentIn,
  getInstallmentDates,
  getInstallmentAmount,
} from './helpers.js'
import { parseISO } from 'date-fns'
import { textToNumber } from 'Simple/Data/format.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let createPaymentPlan = useCreatePaymentPlan(props, data)

  let local_current_date = useDataValue({
    viewPath: props.viewPath,
    context: 'receivable',
    path: 'organization.local_current_date',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    if (args.type !== 'create_payment_plan' && args.value === null) return

    switch (args.type) {
      case 'create_payment_plan': {
        return createPaymentPlan({ value })
      }
      case 'update_invoice_amount': {
        return updateInvoiceAmount({ value, args, change })
      }

      case 'update_installment_interval': {
        return updateInstallmentInterval({
          value,
          args: { ...args, local_current_date },
          change,
        })
      }

      case 'update_installment_date': {
        return updateInstallmentDate({
          value,
          args: { ...args, local_current_date },
          change,
        })
      }

      case 'update_first_installment_date': {
        return updateFirstInstallmentDate({ value, args, change })
      }

      case 'update_plan_length': {
        return updatePlanLength({ value, args, change })
      }

      case 'update_installment_amount': {
        return updateInstallmentAmount({ value, args, change })
      }
      default: {
      }
    }
  }
}

function useCreatePaymentPlan(props, data) {
  let [, executeMutation] = useMutation(mutation)

  let [, notify] = useNotifications()

  let submit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })

  let receivable_id = useDataValue({
    viewPath: props.viewPath,
    context: 'receivable',
    path: 'id',
  })

  let refreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value }) {
    function getFirstInstallmentDate() {
      switch (value.selected_installment_interval) {
        case 'Weekly':
        case 'EverySecondWeek': {
          return (
            DAYS.findIndex(d => d.id === value.selected_installment_date[0]) + 1
          )
        }

        default: {
          return value.selected_installment_date[0]
        }
      }
    }

    function getSecondInstallmentDate() {
      switch (value.selected_installment_interval) {
        case 'TwicePerMonth': {
          return value.selected_installment_date[1]
        }

        default: {
          return 1
        }
      }
    }

    let token = null
    if (value.selected_payment_source !== 'invoice') {
      token = await submit({
        type: 'tokenize',
      })
      // true meaning invalid in this context :/
      if (token === true || !token) return true
    }

    let mutationResponse = await executeMutation({
      receivable_id,
      payment_plan_amount: textToNumber(value.invoice_amount),
      installment_interval: value.selected_installment_interval,
      first_installment_date: getFirstInstallmentDate(),
      second_installment_date: getSecondInstallmentDate(),
      first_due_date:
        value.first_installment_dates[value.selected_first_installment_date]
          .date,
      installment_amount: textToNumber(value.installment_amount),
      notes: value.notes,
      token,
    })

    // refresh embedded core section even when the mutation failed, just in case the payment plan was actually created
    refreshEmbeddedSection(Date.now())

    if (mutationResponse.error) {
      notify(
        notifyError('Unable to create payment plan. Please contact support.')
      )
      return
    }

    if (
      value.selected_payment_source === 'invoice' ||
      value.selected_autopay_source === 'BankAccount' ||
      mutationResponse.data.payments_create_payment_plan_from_receivable
        .verified
    ) {
      notify(notifySuccess('Payment plan creation successful'))
    } else {
      notify(
        notifyInvalid([
          'Autopayment added. verification failed with: ',
          mutationResponse.data.payments_create_payment_plan_from_receivable
            .verification_metadata.auth_message,
        ])
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}

function updateInvoiceAmount({ value, args, change }) {
  change(next => {
    let plan_length = getPlanLengthInMonths({
      due_amount: textToNumber(args.value),
      installment_interval: value.selected_installment_interval,
      due_date:
        value.first_installment_dates[value.selected_first_installment_date]
          .date,
      installment_dates: value.selected_installment_date,
      installment_amount: textToNumber(value.installment_amount),
    })

    next.invoice_amount = args.value
    next.installment_amount =
      textToNumber(value.installment_amount) > textToNumber(args.value)
        ? args.value
        : value.installment_amount
    next.plan_length = plan_length
  })
}

function updateInstallmentInterval({ value, args, change }) {
  change(next => {
    let today = parseISO(`${args.local_current_date}T00:00:00Z`)

    function getSelectedInstallmentDate() {
      switch (args.value) {
        case 'Weekly':
        case 'EverySecondWeek':
          /**
           * We need to know the `id` of the selected day here. The `getDay` function
           * returns 0 for a Sunday, 1 for a Monday and so on. However, the `DAYS`
           * array that  we have starts from Monday, i.e. 0 for a Monday. So, we
           * effectively need to subtract the value that we are getting from `getDay` by 1
           * to arrive at the correct index for `DAYS`
           *
           * However, if `getDay` returns 0, and we subtract 1, we won't be able to get
           * an element for index as -1. Mathematically, if we took a modulo of 7, we
           * should have arrived at 6. However, in JS, -1 % 7 = -1. So, to achieve the
           * same effect, instead of subtracting 1, 6 is being added, as both give the
           * same result modulo 7
           *
           * For example:
           * Value from getDay      Calculated index
           *        0                      6
           *        1                      0
           *        2                      1
           *        3                      2
           *        4                      3
           *        5                      4
           *        6                      5
           */
          return [DAYS[(today.getDay() + 6) % 7].id]

        default:
          return [today.getDate(), 1]
      }
    }

    let installment_dates = getInstallmentDates(args.value)

    let num_of_months = getPlanLengthInMonths({
      due_amount: textToNumber(value.invoice_amount),
      installment_interval: value.selected_installment_interval,
      due_date:
        value.first_installment_dates[value.selected_first_installment_date]
          .date,
      installment_dates: value.selected_installment_date,
      installment_amount: textToNumber(value.installment_amount),
    })
    if (num_of_months < 3 && args.value === 'Quarterly') {
      num_of_months = 3
    } else if (num_of_months < 6 && args.value === 'SemiAnnual') {
      num_of_months = 6
    } else if (num_of_months < 12 && args.value === 'Yearly') {
      num_of_months = 12
    }
    let new_num_of_installments = howManyInstallmentIn({
      installment_interval: args.value,
      num_of_months,
    })

    let new_installment_amount = getInstallmentAmount({
      num_of_installments: new_num_of_installments,
      due_amount: textToNumber(value.invoice_amount),
    })

    // @ts-ignore
    let first_installment_dates = getRealizationDatesForAPaymentPlan({
      installment_interval: args.value,
      today,
      installment_dates: getSelectedInstallmentDate(),
    })

    let plan_length =
      // @ts-ignore
      getPlanLengthInMonths({
        due_amount: textToNumber(value.invoice_amount),
        installment_interval: args.value,
        due_date: first_installment_dates[0].date,
        installment_dates: getSelectedInstallmentDate(),
        installment_amount: textToNumber(new_installment_amount),
      })

    next.selected_installment_interval = args.value
    next.installment_dates = installment_dates
    next.selected_installment_date = getSelectedInstallmentDate()
    next.first_installment_dates = first_installment_dates
    next.selected_first_installment_date = 0
    next.installment_amount = new_installment_amount
    next.plan_length = plan_length
  })
}

function updateInstallmentDate({ value, args, change }) {
  if (
    value.selected_installment_interval === 'Weekly' ||
    value.selected_installment_interval === 'EverySecondWeek'
  ) {
    change(next => {
      let today = parseISO(`${args.local_current_date}T00:00:00Z`)
      let new_selected_installment_date = [args.value.id]

      let first_installment_dates = getRealizationDatesForAPaymentPlan({
        installment_interval: value.selected_installment_interval,
        today,
        installment_dates: new_selected_installment_date,
      })

      next.selected_installment_date = new_selected_installment_date
      next.first_installment_dates = first_installment_dates
      next.selected_first_installment_date = 0
    })
  } else {
    change(next => {
      let today = parseISO(`${args.local_current_date}T00:00:00Z`)
      let new_selected_installment_date
      if (value.selected_installment_interval === 'TwicePerMonth') {
        new_selected_installment_date = [
          args.changeIndex === 0
            ? args.value
            : value.selected_installment_date[0],
          args.changeIndex === 1
            ? args.value
            : value.selected_installment_date[1],
        ]
        if (
          new_selected_installment_date[0] === new_selected_installment_date[1]
        ) {
          // This means the user has selected the same date for both the days
          return
        }
      } else {
        new_selected_installment_date = [args.value]
      }
      let first_installment_dates = getRealizationDatesForAPaymentPlan({
        installment_interval: value.selected_installment_interval,
        today,
        installment_dates: new_selected_installment_date,
      })

      next.selected_installment_date = new_selected_installment_date
      next.first_installment_dates = first_installment_dates
      next.selected_first_installment_date = 0
    })
  }
}

function updateFirstInstallmentDate({ value, args, change }) {
  change(next => {
    let plan_length = getPlanLengthInMonths({
      due_amount: textToNumber(value.invoice_amount),
      installment_interval: value.selected_installment_interval,
      due_date: value.first_installment_dates[args.value].date,
      installment_dates: value.selected_installment_date,
      installment_amount: textToNumber(value.installment_amount),
    })

    next.selected_first_installment_date = args.value
    next.plan_length = plan_length
  })
}

function updatePlanLength({ value, args, change }) {
  change(next => {
    let num_of_installments = howManyInstallmentIn({
      installment_interval: value.selected_installment_interval,
      num_of_months: args.value,
    })
    let new_installment_amount = getInstallmentAmount({
      num_of_installments,
      due_amount: textToNumber(value.invoice_amount),
    })

    next.installment_amount = new_installment_amount
    next.plan_length = args.value
  })
}

function updateInstallmentAmount({ value, args, change }) {
  change(next => {
    let plan_length = getPlanLengthInMonths({
      due_amount: textToNumber(value.invoice_amount),
      installment_interval: value.selected_installment_interval,
      due_date:
        value.first_installment_dates[value.selected_first_installment_date]
          .date,
      installment_dates: value.selected_installment_date,
      installment_amount: textToNumber(args.value),
    })

    next.installment_amount = args.value
    next.plan_length = plan_length
  })
}
