import { useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'images_comparison_sections',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      next.orientation =
        next.orientation === 'horizontal' ? 'vertical' : 'horizontal'
    })
  }
}
