import { useMemo } from 'react'
import {
  getQuestionType,
  getAnswer,
  getOptions,
} from 'Data/treatment-fields.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(({ field_definition, values, tracked_values }) => {
      let type = getQuestionType(
        field_definition.type.value_type,
        field_definition.type.is_free_text,
        field_definition.multi_value
      )
      // tracked field's value is inferred from a previous appointment (no value set explicitly for the current appointment)
      let is_inferred = field_definition.tracked && !values.length
      let options = getOptions(type, field_definition, values, tracked_values)
      return {
        id: field_definition.id,
        name: field_definition.name,
        required: field_definition.mandatory,
        tracked: field_definition.tracked,
        type,
        answer: is_inferred
          ? getAnswer(type, tracked_values)
          : getAnswer(type, values),
        values,
        tracked_values,
        options,
      }
    })
  }, [data])
}
