import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let value = useDataValue({
    context: 'image_serie',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      id: value.id,
      name: value.name,
      treatment_id: value.treatment_id || 0,
      appointment_id: value.appointment_id || 0,
      original_value: {
        name: value.name,
        treatment_id: value.treatment_id || 0,
        appointment_id: value.appointment_id || 0,
      },
    }),
    [value.name, value.treatment_id, value.appointment_id]
  )
}
