import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data'

import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let appointment_booking = useDataValue({
    context: 'event',
    path: 'appointment.booking',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit() {
    let mutationResponse = await executeMutation({
      id: appointment_booking.id,
      state: appointment_booking.state,
    })

    if (
      mutationResponse.error ||
      mutationResponse.data.update_vaxiom_appointment_bookings.affected_rows ===
        0
    ) {
      notify(
        notifyError('Cannot check out selected appointment. Please try again.')
      )
    }
  }
}
