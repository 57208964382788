// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/mutation-resend-virtual-link.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More($id: uuid!) {
  vaxiom_appointment_booking_resend_virtual_link(id: $id) {
    ok
  }
}

`