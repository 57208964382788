import { booleanToYesNo, formatDateDisplay } from 'Data/format'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .filter(
        question =>
          (question.category !== 'habits' && question.category !== 'general') ||
          question.type === 'boolean'
      )
      .map(question => {
        let answer = question.answers[0]
        let questionType = question?.type
        let response = answer?.value

        return {
          id: question.id,
          name: question.name,
          value: getValue(response, questionType, answer?.alert),
          alert: getAlertValue(answer?.alert, response),
        }
      })
      .filter(alertDTO => alertDTO.alert === true)
  }, [data])
}

function getValue(response, questionType, answer_alert) {
  switch (questionType) {
    case 'boolean':
      return response ? 'Yes' : 'No'
    case 'number':
    case 'choice':
    case 'text':
      return response
    case 'date':
      return formatDateDisplay(response)
    case 'multichoice':
      return Array.isArray(response)
        ? response
            .filter(
              item =>
                answer_alert === true ||
                (Array.isArray(answer_alert) && answer_alert?.includes(item))
            )
            .join(', ')
        : response
    default:
      return JSON.stringify(response)
  }
}

function getAlertValue(answer_alert, answer_value) {
  if (
    answer_alert === false ||
    answer_alert === null ||
    answer_alert === undefined
  ) {
    return false
  }
  if (answer_alert === true) {
    if (typeof answer_value === 'boolean') {
      return answer_value
    } else if (typeof answer_value === 'string') {
      return !!answer_value.trim().length
    } else if (Array.isArray(answer_value)) {
      return !!answer_value.length
    }
    return true
  } else if (Array.isArray(answer_alert)) {
    if (Array.isArray(answer_value)) {
      return answer_value.some(item => answer_alert.includes(item))
    }

    return answer_alert.some(alert_value => {
      if (
        typeof answer_value === 'boolean' &&
        typeof alert_value === 'string' &&
        alert_value.toLowerCase() === booleanToYesNo(answer_value).toLowerCase()
      ) {
        return true
      }

      return answer_value === alert_value
    })
  }

  return typeof answer_value === 'boolean' && typeof answer_alert === 'string'
    ? answer_alert.toLowerCase() === booleanToYesNo(answer_value).toLowerCase()
    : answer_alert === answer_value
}
