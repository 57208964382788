import {
  useDataChange,
  useDataIsSubmitting,
  useDataValue,
} from 'Simple/Data.js'
import { normalizePath, useFlow } from 'Simple/Flow.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change(next => {
      next.selected.statements.selected_rows = null
      if (next.selected.statements.merged?.url) {
        try {
          URL.revokeObjectURL(next.selected.statements.merged.url)
        } catch (_) {}
      }
      next.selected.statements.merged = null
      next.selected.statements.query = {
        version: Date.now(),
        location_ids: next.selected.statements.location_ids,
        due_buckets: next.selected.statements.due_buckets,
        min_due_now_amount: next.selected.statements.min_due_now_amount,
      }
    })
  }
}

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let flow = useFlow()
  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.statements',
  })

  return (
    value.due_buckets.length === 0 ||
    value.location_ids.length === 0 ||
    value.credit_cards.length === 0 ||
    isNaN(value.min_due_now_amount) ||
    value.min_due_now_amount < 0.01 ||
    isSubmitting ||
    flow.has(normalizePath(props.viewPath, '../Data/Loading'))
  )
}
