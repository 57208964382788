// this is an autogenerated file from DesignSystem/CommsThread/Content/EventsTimeline/mutation_send_last_event.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content_EventsTimeline(
  $event_id: uuid!
  $thread_id: uuid!
  $parent_company_id: uuid!
) {
  mutation_send_last_event: insert_comms_thread_user_last_seen_internal_events_one(
    object: {
      event_id: $event_id
      thread_id: $thread_id
      parent_company_id: $parent_company_id
    }
    on_conflict: {
      constraint: thread_user_last_seen_internal_events_pkey
      update_columns: [event_id]
    }
  ) {
    event_id
  }
}

`