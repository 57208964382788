import { useDataValue, useDataIsSubmitting } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let treatment_plan_templates = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.treatment.treatment_plans.plans',
  })
  let is_submitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'treatment_plan_groups',
  })

  return !treatment_plan_templates.length || is_submitting
}
