import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'delete': {
        let mutationResponse = await executeMutation({
          token_id: args.token_id,
        })
        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please, try again.'))
          return
        }
        notify(notifySuccess('Payment method removed!'))
        setFlowTo(
          normalizePath(
            props.viewPath,
            'Content/ReusableMethods/Method/Delete/No'
          )
        )
        return
      }
      default: {
        return
      }
    }
  }
}
