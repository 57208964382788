import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let patient = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
  })
  return {
    variables: {
      patient_id: patient?.id,
      patient_uuid: patient?._id,
    },
    pause: !patient?.id || !patient?._id,
  }
}
