import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let ruleset = useDataValue({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      organization_ids: [
        ruleset.parent_id,
        ruleset.location_id,
        ruleset.division_id,
      ].filter(Boolean),
    },
  }
}
