// this is an autogenerated file from App/Account/Content/Insights/Reports/mutation-delete-preset.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Insights_Reports($id: uuid!) {
  delete_reports_filter_presets_by_pk(id: $id) {
    id
    name
    text: name
    data
  }
}

`