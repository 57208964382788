import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation_insert from './mutation_insert.graphql.js'
import mutation_update from './mutation_update.graphql.js'
import mutation_delete from './mutation_delete.graphql.js'

export function useOnClick(props) {
  let appointment_booking = useDataValue({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })
  let submitUnseat = useDataSubmit({
    context: 'unseat',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeInsertMutation] = useMutation(mutation_insert)
  let [, executeUpdateMutation] = useMutation(mutation_update)
  let [, executeDeleteMutation] = useMutation(mutation_delete)

  return async function onClick(value) {
    switch (value) {
      case 'SHOW_TREATMENT':
        return submit({
          type: 'showPatient',
          tab: 'treatment_card',
        })
      case 'SHOW_IMAGES':
        return submit({
          type: 'showPatient',
          tab: 'images',
        })
      case 'CHANGE_CHAIR':
        return changeChair()
      case 'CALL_DOCTOR':
        return await callDoctor(false)
      case 'URGENT':
        return await callDoctor(true)
      case 'MARK_AS_URGENT':
        return await markAsUrgent()
      case 'DOCTOR_ARRIVED':
        return await doctorArrived()
      case 'DOCTOR_LEFT':
        return await doctorLeft()
      case 'CANCEL_CALL':
        return await cancelDoctorCall()
      case 'UNSEAT':
        return unseat()
      default:
        return
    }
  }

  function changeChair() {
    setFlowTo(normalizePath(props.viewPath, 'ChangeChair/Content'))
  }

  async function callDoctor(urgent) {
    let mutationResponse = await executeInsertMutation({
      booking_id: appointment_booking.id,
      chair_id: appointment_booking.seated_chair_id,
      resource_id: appointment_booking.provider_id,
      urgent,
    })

    if (mutationResponse.error) {
      notify(notifyError(`Cannot call doctor. Please try again.`))
      return
    }

    submit({ type: 'reFetch' })
  }

  async function markAsUrgent() {
    let mutationResponse = await executeUpdateMutation({
      id: appointment_booking.provider_call.id,
      object: { urgent: true },
    })

    if (mutationResponse.error) {
      notify(notifyError(`Cannot mark call as urgent. Please try again.`))
      return
    }

    submit({ type: 'reFetch' })
  }

  async function doctorArrived() {
    let mutationResponse = await executeUpdateMutation({
      id: appointment_booking.provider_call.id,
      object: { arrived_at: new Date() },
    })

    if (mutationResponse.error) {
      notify(notifyError(`Cannot update doctor call. Please try again.`))
      return
    }

    submit({ type: 'reFetch' })
  }

  async function doctorLeft() {
    let mutationResponse = await executeUpdateMutation({
      id: appointment_booking.provider_call.id,
      object: { left_at: new Date() },
    })

    if (mutationResponse.error) {
      notify(notifyError(`Cannot update doctor call. Please try again.`))
      return
    }

    submit({ type: 'reFetch' })
  }

  async function cancelDoctorCall() {
    let mutationResponse = await executeDeleteMutation({
      id: appointment_booking.provider_call.id,
    })

    if (mutationResponse.error) {
      notify(notifyError(`Cannot cancel doctor call. Please try again.`))
      return
    }

    submit({ type: 'reFetch' })
  }

  function unseat() {
    submitUnseat()
  }
}

export function getActions(provider_call, disabled) {
  if (!provider_call) {
    return [
      { id: 'CALL_DOCTOR', text: 'Call doctor' },
      { id: 'URGENT', text: 'Urgent' },
      { id: 'SHOW_TREATMENT', text: 'Show treatment' },
      { id: 'SHOW_IMAGES', text: 'Show images' },
      { id: 'CHANGE_CHAIR', text: 'Change chair' },
      { id: 'UNSEAT', text: 'Unseat' },
    ]
  }

  if (provider_call.left_at) {
    return [
      { id: 'CALL_DOCTOR', text: 'Call doctor again' },
      { id: 'SHOW_TREATMENT', text: 'Show treatment' },
      { id: 'CHANGE_CHAIR', text: 'Change chair' },
      { id: 'UNSEAT', text: 'Unseat' },
    ]
  }
  if (provider_call.arrived_at) {
    return [
      { id: 'DOCTOR_LEFT', text: 'Doctor has left' },
      { id: 'SHOW_TREATMENT', text: 'Show treatment' },
      { id: 'SHOW_IMAGES', text: 'Show images' },
      { id: 'CHANGE_CHAIR', text: 'Change chair' },
      { id: 'UNSEAT', text: 'Unseat' },
    ]
  }
  if (provider_call.calling_since) {
    return [
      { id: 'DOCTOR_ARRIVED', text: 'Doctor has arrived' },
      provider_call.urgent
        ? null
        : { id: 'MARK_AS_URGENT', text: 'Mark as urgent' },
      { id: 'CANCEL_CALL', text: 'Cancel call' },
      { id: 'SHOW_TREATMENT', text: 'Show treatment' },
      { id: 'SHOW_IMAGES', text: 'Show images' },
      { id: 'CHANGE_CHAIR', text: 'Change chair' },
      { id: 'UNSEAT', text: 'Unseat' },
    ].filter(Boolean)
  }
}
