import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data'
import View from './view'

export default function Logic(props) {
  let emails = useDataValue({
    context: 'emails',
    viewPath: props.viewPath,
  })
  let email = useDataValue({
    context: 'message_compose',
    path: 'email',
    viewPath: props.viewPath,
  })
  let setEmail = useDataChange({
    context: 'message_compose',
    path: 'email',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!emails?.length || email) return
    setEmail(emails[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, emails])

  return <View {...props} />
}
