import React from 'react'
import './style.css'

export default function RawHtml(props) {
  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  )
}
