import { useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected_note',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change({
      note: '',
      target_type: 'com.axpm.patients.core.internal.domain.Patient',
    })
    setFlowTo(normalizePath(props.viewPath, '../NewOrEdit/Content'))
  }
}
