// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/ChangeStatus/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_ChangeStatus_Content(
  $tx_id: numeric!
  $status: String!
  $old_status: String!
) {
  update_vaxiom_txs_by_pk(
    pk_columns: { id: $tx_id }
    _set: { status: $status, did_not_start: false, end_date: null }
  ) {
    id
  }
  insert_vaxiom_txs_state_changes_one(
    object: { new_state: $status, old_state: $old_status, treatment_id: $tx_id }
  ) {
    id
  }
}

`