import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import {
  TREATMENT_FLOW_PAYMENT_METHOD_FLAG,
  SUPPORTED_PAYMENT_METHODS,
} from 'Data/common-constants'

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ value }) {
    try {
      if (value.value !== value.originalValue) {
        let payment_plan = SUPPORTED_PAYMENT_METHODS[value.value]?.payment_plan
        let variables = {
          organization_id: value.location.id, // ok to be id and not _id
          upsert_feature_flag: {
            organization_id: value.location.id, // ok to be id and not _id
            message_key: TREATMENT_FLOW_PAYMENT_METHOD_FLAG,
            message_value: value.value,
          },
          payment_plan: {},
          with_payment_plan: !!payment_plan,
        }

        if (variables.with_payment_plan) {
          variables.payment_plan = {
            ...payment_plan,
            organization_id: value.location._id,
          }
        }

        let mutationResponse = await executeMutation(variables)

        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please, try again.'))
          return
        }

        notify(notifySuccess('Changes saved!'))
      }
    } catch (error) {
      notify(notifyError('Something went wrong. Please, contact support.'))
    }
  }
}
