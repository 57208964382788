// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/RefreshButton/query_ledger_status.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_RefreshButton($patient_id: uuid!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    where: { due_now: { _gt: 0 } }
  ) {
    id: payment_account_id
    due_now
    payment_account {
      id
      account_type
      person_payment_account {
        id
        payer_person {
          id
          first_name
          last_name
        }
      }
      insurance_payment_accounts {
        id
        insurance_company {
          id
          carrier_name
        }
      }
      collection_labels(where: { patient: { _id: { _eq: $patient_id } } }) {
        id: patient_id
        collection_label_template {
          id
          name
        }
      }
    }
  }
}

`