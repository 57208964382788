// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-seat.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions(
  $id: uuid!
  $provider_id: numeric!
  $assistant_id: numeric!
  $chair_id: numeric!
) {
  vaxiom_appointment_booking_seat(
    id: $id
    provider_id: $provider_id
    assistant_id: $assistant_id
    chair_id: $chair_id
  ) {
    id
  }
}

`