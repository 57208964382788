import { useDataChange } from 'Simple/Data.js'

export function useOnSelectPreTriggerType(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.trigger_type = 'pre'
      next.actions = [{ type: null, data: null }]
    })
  }
}

export function useOnSelectPostTriggerType(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.trigger_type = 'post'
      next.actions = [{ type: null, data: null }]
    })
  }
}
