import { useDataChange, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changeSelected = useDataChange({
    context: 'selected',
    path: 'doctor_id',
    viewPath: props.viewPath,
  })

  let doctor_id = useDataValue({
    context: 'selected',
    path: 'doctor_id',
    viewPath: props.viewPath,
  })

  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })

  return async function onChange(value, change) {
    // set a default selection to the current user if it's a DM doctor
    // or the first if there's only one
    if (value?.length > 0 && !doctor_id) {
      let selectedDoctor = value.find(item => item.user.id === user_id)
      if (selectedDoctor) {
        changeSelected(selectedDoctor.id)
      } else if (!selectedDoctor && value.length === 1) {
        changeSelected(value[0].id)
      }
    }
  }
}

// We may want to get the assigned doctor at some point:
// https://greyfinch.slack.com/archives/C02HMB3JLG3/p1681310542998489
// query external_professionals($patient_id: uuid!) {
//   vaxiom_professional_relationships(
//     where: {
//       dtype: { _eq: "otherProfessionalRelationship" }
//       patient: { _id: { _eq: $patient_id } }
//     }
//   ) {
//     id
//     other_professional_relationship {
//       id
//       person {
//         id
//         first_name
//         last_name
//       }
//     }
//   }
// }
// Or this variation...
// query assigned_providers($patient_id: uuid!) {
//   vaxiom_professional_relationships(
//     where: {
//       dtype: { _eq: "emploProfessionalRelationship" }
//       patient: { _id: { _eq: $patient_id } }
//       employee_professional_relationship: {
//         provider: { resource_type: { _or: [{ is_provider: { _eq: true } }] } }
//       }
//     }
//   ) {
//     id
//     employee_professional_relationship {
//       id
//       provider {
//         id
//         employee_resources {
//           id
//           employment_contract {
//             id
//             person {
//               id
//               first_name
//               last_name
//             }
//           }
//         }
//       }
//     }
//   }
// }
