import React, { useEffect, useMemo, useState } from 'react'
import { useClient } from 'Data/Api'
import TysiaText from 'DesignSystem/TysiaText/view.js'
import query from './txStatusQuery.graphql.js'

import PatientCard from './PatientCard/index.js'
import PatientNotFoundCard from './PatientNotFoundCard/index.js'
import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let patients = useDataValue({
    context: 'patients',
    viewPath: props.viewPath,
    path: 'patients',
  })
  let filters = useDataValue({
    context: 'filters',
    viewPath: props.viewPath,
  })

  let client = useClient()

  let [filteredPatients, setFilteredPatients] = useState(patients)
  useEffect(() => {
    let setup = async () => {
      let fp = patients.filter(
        p => filters.type === 'all' || filters.type === p.external.type
      )
      if (filters.status.length) {
        let { data, error } = await client.query(query, {
          patient_ids: patients
            .filter(p => p.greyfinch?.patient)
            .map(p => p.greyfinch.patient.id),
          statuses: filters.status,
        })
        if (error) {
          return
        }
        let filteredPatientIds = data.vaxiom_patients.map(p => p._id)
        fp = patients.filter(
          p =>
            p.greyfinch.patient &&
            filteredPatientIds.includes(p.greyfinch.patient.id)
        )
      }
      setFilteredPatients(fp)
    }
    setup()
  }, [filters, patients])

  let [allEnabled, setAllEnabled] = useState(true)
  useEffect(() => {
    if (
      patients.some(
        p =>
          typeof p.greyfinch === 'object' &&
          p.greyfinch.type !== 'noMatch' &&
          !p.selected
      )
    ) {
      setAllEnabled(false)
    }
  }, [patients])

  let change = useDataChange({
    context: 'patients',
    viewPath: props.viewPath,
  })

  function selectAll() {
    change(next => {
      next.patients.forEach(p => {
        if (typeof p.greyfinch !== 'object') return
        if (p.greyfinch.type === 'noMatch') return
        p.selected = !allEnabled
      })
    })
  }

  function onPatientSelect(id) {
    change(next => {
      let patient = next.patients.find(p => p?.greyfinch?.patient?.id === id)
      patient.selected = !patient.selected
    })
  }

  function getDifferences(row) {
    let patient = row.greyfinch.patient
    let externalPatient = row.external.patient
    let diff = []
    for (let k in externalPatient) {
      if (patient.hasOwnProperty(k) && patient[k] !== externalPatient[k]) {
        diff.push(k)
      }
    }
    return diff
  }

  let value = useMemo(() => {
    let columns = [
      {
        Header: () => (
          <TysiaText weight="medium" viewPath={props.viewPath} text="Align" />
        ),
        accessor: 'external',
        disableSortBy: true,
        size: 50,
        maxSize: 50,
        width: 50,
        Cell: row =>
          typeof row.value === 'string' ? (
            <TysiaText
              text={row.value}
              viewPath={props.viewPath}
              paddingLeft={16}
            />
          ) : (
            <PatientCard
              viewPath={`${props.viewPath}/PatientCard(${row.row.original.id})`}
              {...row.value}
              selected={row.row.original.selected}
              patient={row.value.patient}
              field_differences={
                row.value.type === 'partial'
                  ? getDifferences(row.row.original)
                  : []
              }
            />
          ),
      },
      {
        Header: () => (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {/* <TysiaCheckbox
              onChange={() => {
                selectAll()
              }}
              viewPath={props.viewPath}
            >
              <TysiaCheckboxInput
                viewPath={props.viewPath}
                value={allEnabled}
              />
            </TysiaCheckbox> */}
            <TysiaText
              weight="medium"
              viewPath={props.viewPath}
              text="Greyfinch"
            />
          </div>
        ),
        accessor: 'greyfinch',
        disableSortBy: true,
        size: 50,
        width: 50,
        Cell: row =>
          typeof row.value === 'string' ? (
            <TysiaText
              text={row.value}
              size="tiny"
              marginTop={0}
              marginBottom={0}
              viewPath={props.viewPath}
              paddingLeft={16}
            />
          ) : row.value.patient ? (
            <PatientCard
              viewPath={`${props.viewPath}/PatientCard(${row.row.original.id})`}
              {...row.value}
              onSelect={() => {
                onPatientSelect(row.value.patient.id)
              }}
              selected={row.row.original.selected}
              greyfinch={true}
              patient={row.value.patient}
              external_id={row.row.original.external.patient.id}
              field_differences={
                row.value.type === 'partial'
                  ? getDifferences(row.row.original)
                  : []
              }
            />
          ) : (
            <PatientNotFoundCard
              viewPath={`${props.viewPath}/PatientNotFoundCard(${row.row.original.id})`}
              external_id={row.row.original.external.patient.id}
            />
          ),
      },
    ].filter(Boolean)

    if (!filteredPatients) return { columns, data: [] }

    return {
      columns,
      data: filteredPatients,
    }
  }, [filteredPatients])

  return <View value={value} viewPath={props.viewPath} />
}
