import { useMemo } from 'react'

let PM_MINUTE_THRESHOLD = 12 * 60

let getTimePostfix = minute => (minute < PM_MINUTE_THRESHOLD ? 'AM' : 'PM')
let formatTime = time => time.replace(/:\d{2}$/, '')

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(slot => {
      let id = `${slot.chair_id}_${slot.date}_${slot.start_min}_${slot.end_min}`

      return {
        ...slot,
        id,
        chair_name: slot.chair.full_name,
        // prettier-ignore
        start_time_formatted: `${formatTime(slot.start_time)}${getTimePostfix(slot.start_min)}`,
        // prettier-ignore
        end_time_formatted: `${formatTime(slot.end_time)}${getTimePostfix(slot.end_min)}`,
      }
    })
  }, [data])
}
