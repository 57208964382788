import { getParentView } from 'Simple/Flow.js'
import { useDataChange } from 'Simple/Data.js'
import debounce from 'lodash/debounce.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    context: 'global',
    viewPath: props.viewPath,
  })
  let [, tab_id] = getParentView(props.viewPath)

  return debounce(async function onChange(next, ...args) {
    if (!next) return

    change(inext => {
      if (tab_id !== inext.tab_id) return

      inext.tabs = inext.tabs.map(item => (item.id === tab_id ? next : item))
    })
  }, 250)
}
