import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  let changeLocation = useDataChange({
    context: 'payment_method_selection',
    path: 'location',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (!value) {
      return
    }
    let location = locations.find(item => item.id === value) ?? locations[0] // ok to be id and not _id

    changeLocation(location)
  }
}
