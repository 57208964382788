// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/Steps/Rulesets/Content/Actions/Content/SaveAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_Steps_Rulesets_Content_Actions_Content_SaveAction(
  $ruleset_ids_to_delete: [uuid!]
  $rulesets_to_update: [treatments_allowed_request_steps_updates!]!
) {
  delete_treatments_allowed_request_steps(
    where: { id: { _in: $ruleset_ids_to_delete } }
  ) {
    affected_rows
    returning {
      id
    }
  }
  update_treatments_allowed_request_steps_many(updates: $rulesets_to_update) {
    affected_rows
    returning {
      id
    }
  }
}

`