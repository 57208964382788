import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    if (data.subset) {
      return data.subset
    } else {
      return data.all
    }
  }, [data])
}
