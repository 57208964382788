import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { isCoreEmbedMessage } from 'Data/isCoreEmbedMessage.js'

import View from './view.js'

export default function Logic(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    window.addEventListener('message', listener, false)

    return () => {
      window.removeEventListener('message', listener)
    }

    async function listener(event) {
      // listen for navigation events from core within the same tab only
      // navigation to other sections of the app are handled globally in ../EmbeddableCoreMessageListener/logic.js
      if (
        !isCoreEmbedMessage(event) ||
        event.data.type !== 'navigation' ||
        event.data.section !== 'patients' ||
        event.data.params.patient_id !== patient_id
      ) {
        return
      }

      change(next => {
        if (event.data.params.tab === 'images') {
          next.tab = 'images'
          next.coreParams = event.data.params
          next.selected.images.image_id = event.data.params.image_id
          next.selected.images.image_series_id =
            event.data.params.image_series_id
        } else {
          next.tab = event.data.params.tab
          next.coreParams = event.data.params
        }
      })
    }
  }, [patient_id, change])

  return <View {...props} />
}
