import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'professional_search',
    path: 'term',
  })
  let search_results = useDataValue({
    viewPath: props.viewPath,
    context: 'org_professionals',
  })

  let selected_id = useDataValue({
    viewPath: props.viewPath,
    context: 'org_professional',
    path: 'id',
  })
  let change_new_referral = useDataChange({
    context: 'new_referral',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick(value) {
    let selected = search_results.find(item => item.id == selected_id)
    change(selected.value)
    change_new_referral(next => {
      next.person_id = selected_id
      next.person_type = 'Professional'
      next.value = selected.value
    })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
