import { numberMoney } from 'Data/format.js'

export function getValue(payment_plan) {
  if (payment_plan.insurances.length === 0) return null

  let amount = payment_plan.insurances.reduce(
    (acc, charge) => acc + charge.estimated_reimbursement_amount,
    0
  )
  return `(${payment_plan.insurances.length}) - ${numberMoney(amount)}`
}
