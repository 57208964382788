import { useMemo } from 'react'
import formatISO from 'date-fns/formatISO'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      date: formatISO(new Date(), { representation: 'date' }),
    }
  }, [data])
}
