// this is an autogenerated file from DesignSystem/PaymentProvider/PaymentProviderLocation/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PaymentProvider_PaymentProviderLocation_Content($xid: String!) {
  payments_provider_location_accounts(
    where: {
      is_default: { _eq: true }
      # TODO: review data, we shouldn't need this filter
      # but staging returns the PC
      location: { level: { _eq: "location" } }
      status: { _eq: active }
      xid: { _eq: $xid }
    }
    order_by: { location: { name: asc } }
  ) {
    id
    location {
      id
      _id
      name
    }
  }
}

`