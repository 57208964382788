import { useDataValueOnce } from 'Logic/FlowShortcuts.js'
import { useDataValue } from 'Simple/Data'
import { uuid } from 'Data/validate.js'

export default function useGetDefaultTab(props, data) {
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company.id',
    viewPath: props.viewPath,
  })

  let report_id_raw = useDataValueOnce({
    context: 'flow_shortcuts',
    path: 'reportId',
    viewPath: props.viewPath,
  })

  let report_execution_id_raw = useDataValueOnce({
    context: 'flow_shortcuts',
    path: 'reportExecutionId',
    viewPath: props.viewPath,
  })

  let is_new_reporting_default_enabled = useDataValue({
    context: 'global',
    path: 'feature_flags.is_new_reporting_default_enabled',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id) {
    if (maybeTab?.selected) return maybeTab

    let report_id = report_id_raw
    if (!uuid(report_id)) {
      report_id = null
    }
    let report_execution_id = report_execution_id_raw
    if (!uuid(report_execution_id)) {
      report_execution_id = null
    }

    return {
      ...data,
      viewPath: props.viewPath,
      selected: {
        ...data.selected,
        new: is_new_reporting_default_enabled,
        parent_company_id,
        report_id,
        report_execution_id,
      },
    }
  }
}
