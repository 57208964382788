import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let directory_id = useDataValue({
    context: 'patient_documents_directory',
    path: 'id',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'patient_documents_directory_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let setDirectories = useDataChange({
    context: 'patient_documents',
    path: 'directories',
    viewPath: props.viewPath,
  })
  let setParentId = useDataChange({
    context: 'patient_documents',
    path: 'parent_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setParentId(directory_id)
    setDirectories(value => {
      value.directories = value.directories.slice(0, index + 1)
    })
  }
}
