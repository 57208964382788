import { useDataChange } from 'Simple/Data.js'

export function useOnChangeSelectedRows(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.statements',
  })

  return function onChange(event) {
    change(next => {
      if (next.selected.statements.merged !== null) {
        if (!next.selected.statements.merged.downloaded) {
          if (
            !window.confirm(
              `You have a generated statement that was printed but not downloaded. Reselecting rows will clear it and you'll start over. Are you sure you want to do this?`
            )
          ) {
            return
          }
        } else if (!next.selected.statements.merged.printed) {
          if (
            !window.confirm(
              `You have a generated statement that was downloaded but not printed. Reselecting rows will clear it and you'll start over. Are you sure you want to do this?`
            )
          ) {
            return
          }
        }
      }

      if (next.selected.statements.merged?.url) {
        try {
          URL.revokeObjectURL(next.selected.statements.merged.url)
        } catch (_) {}
      }
      next.selected.statements.merged = null
      next.selected.statements.selected_rows = event.api
        .getSelectedNodes()
        .map(item => item.data)
    })
  }
}
