import useFocusedManual from './useFocusedManual.js'

export default function useCapture(props) {
  let focusedManual = useFocusedManual()

  function onKeyUp(event) {
    if (props.onKeyUp) {
      props.onKeyUp(event)
    } else if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
      props.onSubmit()
    }
  }

  function onChange(event, value) {
    props.onChange(
      // Check that the value is a number
      !isNaN(value) && !isNaN(parseFloat(value)) && props.type === 'number'
        ? props.isInteger
          ? parseInt(value, 10)
          : parseFloat(value, 10)
        : value
    )
  }

  return { ...focusedManual, onKeyUp, onChange }
}
