import React, { useRef } from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export default function Logic(props) {
  let contentRef = useRef()

  let setFlowTo = useSetFlowTo(props.viewPath)

  useOnClickOutside(contentRef, event => {
    setFlowTo(normalizePath(props.viewPath, '../No'))
  })

  return <View {...props} innerRef={contentRef} />
}
