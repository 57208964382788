import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let image_series = useDataValue({
    context: 'image_series',
    viewPath: props.viewPath,
  })
  let image_serie_id = useDataValue({
    context: 'images_comparison_action',
    path: 'image_serie_id',
    viewPath: props.viewPath,
  })
  let setImageSerieId = useDataChange({
    context: 'images_comparison_action',
    path: 'image_serie_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!image_series?.length || image_serie_id) return
    setImageSerieId(image_series[0].id)
  }, [image_serie_id, image_series, setImageSerieId])

  return <View {...props} />
}
