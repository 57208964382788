// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Medical/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Medical_Content($answers: [medical_histories_answers_insert_input!]!) {
  insert_medical_histories_answers(objects: $answers) {
    returning {
      id
    }
  }
}

`