import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payers',
    viewPath: props.viewPath,
    path: 'activeTab.downpayment_metadata.type',
  })
  return function onClick() {
    change('card')
  }
}
