// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/SendDocument/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_SendDocument_Content($document_id: numeric!) {
  vaxiom_transform_html_document_to_pdf(document_id: $document_id) {
    ok
  }
}

`