/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ args, value, change }) {
    switch (args.type) {
      case 'create':
        return props.onCreate()
      case 'update':
        return props.onUpdate()
      case 'cancel':
        return props.onCancel()
      case 'addAction': {
        change(next => {
          next.actions.push({ type: null, data: null })
        })
        break
      }
      case 'removeAction': {
        change(next => {
          next.actions = value.actions.filter((_, idx) => idx !== args.index)
        })
        break
      }
      default:
        return
    }
  }
}
