import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let selectedLocations = useDataValue({
    context: 'tab',
    path: 'selected.location_ids',
    viewPath: props.viewPath,
  })
  let setSelectedLocations = useDataChange({
    context: 'tab',
    path: 'selected.location_ids',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (selectedLocations?.length || !locations?.length) {
      return
    }
    setSelectedLocations(locations.map(item => item.id))
  }, [locations]) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
