import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'send_to_person_id',
  })

  return {
    variables: {
      person_id,
    },
    pause: !person_id,
  }
}
