// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Procedures/Content/mutation-delete.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Procedures_Content(
  $appointment_id: uuid!
  $procedure_ids: [uuid!]!
) {
  delete_vaxiom_appointment_procedures(
    where: {
      appointment: { _id: { _eq: $appointment_id } }
      procedure: { _id: { _in: $procedure_ids } }
    }
  ) {
    affected_rows
  }
}

`