import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutationInsert from './mutation-insert.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnActionSubmit(props) {
  let [, notify] = useNotifications()
  let organization_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let [, executeMutationInsert] = useMutation(mutationInsert)

  return async function onAction({ value, args, originalValue, change }) {
    // TODO: ajv.validate
    if (!value.name || !value.type_id) return true

    let mutationResponse = await executeMutationInsert({
      object: {
        ...value,
        organization_id,
      },
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Tx card field definitions added!'))
    props.onClose()
  }
}
