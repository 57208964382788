import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'question',
  })

  return function onClick() {
    change(next => {
      next.alert = !next.alert
    })
  }
}
