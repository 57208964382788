// this is an autogenerated file from DesignSystem/ImageViewGrid/ImageGrid/ImageSlot/LogoImage/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageViewGrid_ImageGrid_ImageSlot_LogoImage_Content($resource_id: numeric!) {
  resource_object_meta: vaxiom_resource_object_meta(
    resource_id: $resource_id
    resource_type: logo
  ) {
    name
    content_type
    size
    url
    preview_url
  }
}

`