import { patientName, age, dateShortIn } from 'Data/format.js'

export function getText(patient) {
  let { person } = patient
  let { birth_date } = person
  let name = patientName(person)

  return [
    name,
    person.gender[0],
    ...(birth_date && name.length <= 20
      ? [age(birth_date), dateShortIn(birth_date)]
      : []),
  ]
    .filter(Boolean)
    .join(', ')
    .trim()
}
