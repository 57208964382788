import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.organization.id',
  })

  return useMemo(
    () => ({
      ...data,
      location_id,
    }),
    [data, location_id]
  )
}
