import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(employee_resource => ({
      id: employee_resource.id,
      text: `${employee_resource.employment_contract.person.first_name} ${employee_resource.employment_contract.person.last_name}`,
    }))
  }, [data])
}
