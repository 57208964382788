// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsDevelopers/CreateToken/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsDevelopers_CreateToken($expires_at: numeric!, $description: String!) {
  auth_api_user_register(description: $description, expires_at: $expires_at) {
    key
    secret
  }
}

`