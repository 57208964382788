import { useDataSubmit } from 'Simple/Data'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    submit({ type: 'update_first_installment_date', value })
  }
}
