import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { useSaveEditorContent } from '../../useSaveEditorContent.js'
import mutation from './mutation.graphql.js'

export function useOnSubmitPre(props) {
  let document_id = useDataValue({
    context: 'preview_document',
    path: 'document_id',
    viewPath: props.viewPath,
  })
  let url = useDataValue({
    context: 'document',
    path: 'url',
    viewPath: props.viewPath,
  })
  let editor = useDataValue({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })

  let save = useSaveEditorContent(props)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmitPre() {
    change(next => {
      next.saving = true
    })

    if (editor.isDirty) {
      await save({ url, content: editor.api.getContent() })
    }

    let mutationResponse = await executeMutation({ document_id })

    editor.api.setDirty(false)
    change(next => {
      next.saving = false
      next.isDirty = false
    })

    if (mutationResponse.error) {
      throw mutationResponse.error
    }
  }
}
