import { useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    submit({ type: 'update_installment_amount', value })
  }
}
