// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/SendTo/InApp/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_SendTo_InApp($id: uuid!) {
  users_by_pk(id: $id) {
    id
    person: vaxiom_person {
      id
      contact_method_associations(
        where: { contact_method: { dtype: { _eq: "email" } } }
      ) {
        id
        contact_method {
          id
          email {
            id
            address
          }
        }
      }
    }
  }
}

`