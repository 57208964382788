import { dateISOIn } from 'Data/format.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(item => {
      let series_date = item.series_date
        ? `[${dateISOIn(new Date(item.series_date))}] `
        : ''
      let name = `${item.name} ${series_date}- ${
        item.treatment?.name || 'No treatment'
      }`
      return {
        ...item,
        text: item.deleted ? `${name} (deleted)` : name,
      }
    })
  }, [data])
}
