// this is an autogenerated file from App/ChooseLocation/query-chairs.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_ChooseLocation($current_location_id: uuid!) {
  vaxiom_chairs(
    where: {
      resource: {
        organization: { _id: { _eq: $current_location_id } }
        deleted: { _eq: false }
      }
    }
    order_by: { pos: asc }
  ) {
    id
    _id
  }
}

`