import { useMutation } from 'Data/Api'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'
import { useDataChange } from 'Simple/Data.js'

export function getChairs(chairs) {
  return chairs.map(chair => ({ id: chair._id, text: chair.code }))
}

export function useOnClick(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let change = useDataChange({
    context: 'selected_chair_ids',
    viewPath: props.viewPath,
  })

  return async function onClick(value) {
    let chair_ids = Array.isArray(value) ? value : [value]

    let mutationResponse = await executeMutation({
      chair_ids,
    })
    change(chair_ids)

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
    }
  }
}
