import React from 'react'
import { useDataValue } from 'Simple/Data.js'
import { appointmentTypeColor } from 'Data/format.js'
import View from './view.js'
import { useDesignTokenValue } from 'Simple/DesignTokens.js'

export default function Logic(props) {
  let isUnplanned = useDataValue({
    context: 'event',
    path: 'appointment.unplanned',
    viewPath: props.viewPath,
  })
  let borderColor = useDataValue({
    context: 'event',
    path: 'appointment.type.color_id',
    viewPath: props.viewPath,
  })
  let unplannedColor = useDesignTokenValue({
    path: 'colors.neutral.700',
    viewPath: props.viewPath,
  })

  return (
    <View
      {...props}
      borderColor={
        isUnplanned ? unplannedColor : appointmentTypeColor(borderColor)
      }
    />
  )
}
