import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { formatDate } from 'Simple/Data/format.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

function getPatientId(person) {
  return `${person.first_name?.charAt(0) ?? ''}${
    person.last_name?.charAt(0) ?? ''
  }`
}

export function useOnClick(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let analysis_data = useDataValue({
    context: 'ceph_analysis',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useDataValue({ context: 'selected', viewPath: props.viewPath })
  let patient = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'selected',
    path: 'ceph_order_id',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    let analysisType = analysis_data.analysis_types.find(
      analysisType => analysisType.id === value.analysis_type_id
    )
    if (!analysisType) {
      notify(notifyError(`Couldn't perform the analysis`))
    }

    let mutationResponse = await executeMutation({
      token: analysis_data.token,
      analysis_type_id: analysisType.id,
      analysis_type_name: analysisType.text,
      birthdate: formatDate(analysis_data.birth_date, 'iso', 'yyyy/MM/dd'),
      calibration: value.calibration_size,
      image_date: formatDate(new Date().toISOString(), 'iso', 'yyyy/MM/dd'),
      patient_id: getPatientId(patient?.person),
      image_id: value.ceph_image_id,
      request_id: value.ceph_request_id,
      sex: analysis_data.gender,
    })

    if (mutationResponse.error || !mutationResponse.data) {
      notify(notifyError(`Couldn't perform the analysis`))
      setFlowTo(normalizePath(props.viewPath, '../../../No'))
      return
    }

    change(mutationResponse.data?.ceph_create_order?.id)

    notify(
      notifySuccess(
        'The analysis has been started. A new notification will be displayed on Tasks sections when it is done.'
      )
    )

    setFlowTo(normalizePath(props.viewPath, '../../../../No'))
  }
}

export function isDisabled({
  analysis_type_id,
  calibration_size,
  ceph_image_id,
  ceph_request_id,
  disable_actions,
}) {
  return (
    !analysis_type_id ||
    !calibration_size ||
    !ceph_image_id ||
    !ceph_request_id ||
    disable_actions
  )
}
