import { truncate } from 'Data/aggregate.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let file = useDataValue({
    context: 'request_file',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick() {
    change(next => {
      next.file_id = file.id
      next.content_type = file.type
      next.url = file.url
      next.file_name = file.name
    })

    setFlowTo(normalizePath(props.viewPath, '../../../../../../../Preview'))
  }
}

export function formatFilename(value) {
  if (!value || value.length <= 60) {
    return value
  }
  let [filename, extension] = value.split('.')

  filename = truncate(filename, 60)

  return `${filename}.${extension}`
}
