import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let refresh_tooth_chart_snapshot = useDataValue({
    context: 'refresh',
    path: 'tooth_chart_snapshot',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tooth_chart_snapshot',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refresh_tooth_chart_snapshot) return

    submit({ type: 'reFetch' })
  }, [refresh_tooth_chart_snapshot]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitProgress

  return <View {...props} />
}
