import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { textToNumber, numberMoney } from 'Simple/Data/format.js'

import mutationPayRequest from './mutation_pay_request.graphql.js'
import queryPatientDueNow from './query_patient_due_now.graphql.js'
import { validate } from './helpers.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionFormSubmit = useDataOnActionFormSubmit(props)
  let onDueAmountSubmit = useDataOnDueAmountSubmit(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      // temp form blocking while querying due amount
      case 'due_amount_submit': {
        return onDueAmountSubmit({ value, args, change })
      }
      case 'form_submit': {
        return onActionFormSubmit({ value, change })
      }
      default: {
      }
    }
  }
}

function useDataOnDueAmountSubmit(props) {
  let client = useClient()

  let patient = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, change }) {
    let mutationResponse = await client
      .query(queryPatientDueNow, {
        patient_uuid: patient._id,
        payment_account_id: args.person_payment_account_id,
      })
      .toPromise()

    if (mutationResponse.error) {
      change(next => {
        next.amount = numberMoney(0)
      })
      return
    }

    change(next => {
      next.amount = numberMoney(
        mutationResponse.data.financial_patient_due_amount?.[0]?.due_now ?? 0
      )
    })
  }
}

function useDataOnActionFormSubmit(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let current_location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let patient = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
  })
  let [, executeMutationPayRequest] = useMutation(mutationPayRequest)

  return async function onAction({ value, change }) {
    let validation = validate(value)

    if (validation.isInvalid) {
      notify(notifyError(validation.errors[0]))
      return
    }

    try {
      let mutationResponse = await executeMutationPayRequest({
        amount: textToNumber(value.amount),
        message: value.message,
        location_uuid: current_location._id,
        xid: current_location.xid,
        person_payment_account_uuid:
          value.person_payment_account.data.person_payment_account._id,
        patient_person_uuid: patient.person._id,
        tx_uuid: value.tx_uuid,
        allowed_birth_dates: {
          PAYER: value.person_payment_account.data.payor.birth_date,
          PATIENT: patient.person.birth_date,
        },
        contact_methods: [value.selected_phone, value.selected_email]
          .filter(Boolean)
          .map(cm => ({ contact_method_id: cm })),
      })

      if (mutationResponse.error) {
        notify(
          notifyError(mutationResponse.error?.message ?? 'Something went wrong')
        )
        return
      }

      notify(notifySuccess('Pay request created'))
      setFlowTo(normalizePath(props.viewPath, '../../No'))
    } catch (error) {
      notify(notifyError(error?.message ?? 'Something went wrong'))
      return
    }
  }
}
