// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/NoteTemplates/Templates/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_NoteTemplates_Templates_Content($organization_id: uuid!) {
  notes_templates(where: { organization_id: { _eq: $organization_id } }) {
    id
    name
    tags
    organization_id
    updated_at
    updated_by_user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`