// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Apps/CustomResources/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Apps_CustomResources($patient_id: uuid!) {
  apps_custom_resources(
    where: { patient_id: { _eq: $patient_id } }
    order_by: { updated_at: desc_nulls_last }
    limit: 1
  ) {
    id
    type
    data
  }
}

`