import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { parseTimeIntervalInWeeks } from 'Logic/parseTimeIntervalInWeeks.js'
import { useDataValue } from 'Simple/Data.js'
import { isAppointmentScheduled } from 'Data/format.js'

import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick(value) {
    let mutationResponse = await executeMutation({
      appointment_id,
      interval_to_next: value,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the appointment. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`Saved!`), { extendExistingNotification: true })
  }
}

export function useOnSave(props) {
  let interval_to_next = useDataValue({
    context: 'event',
    path: 'appointment.interval_to_next',
    viewPath: props.viewPath,
  })
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSave(event) {
    let value = parseTimeIntervalInWeeks(event.target.value)
    if (typeof value !== 'number' || interval_to_next === value) return

    let mutationResponse = await executeMutation({
      appointment_id,
      interval_to_next: value,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the appointment. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`Saved!`), { extendExistingNotification: true })
  }
}

export function isDisabled(appointment, events) {
  return (
    isAppointmentScheduled(appointment) &&
    isBefore(
      new Date(`${appointment.booking.start_time}Z`),
      startOfDay(new Date())
    ) &&
    !isLastPastAppointment(appointment, events)
  )
}

function isLastPastAppointment(appointment, events) {
  let past_appointments = events.filter(
    event =>
      event.type === 'appointment' &&
      isAppointmentScheduled(event.appointment) &&
      isBefore(
        new Date(`${event.appointment.booking.start_time}Z`),
        startOfDay(new Date())
      )
  )
  return (
    past_appointments.length > 0 &&
    appointment.id ===
      past_appointments[past_appointments.length - 1].appointment.id
  )
}
