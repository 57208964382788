import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'requirement',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.is_skippable = value
      next.create_task = false
    })
  }
}
