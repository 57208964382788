// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/Primary/Content/CheckIn/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_Primary_Content_CheckIn($id: uuid!) {
  vaxiom_appointment_booking_check_in(id: $id) {
    id
  }
}

`