// this is an autogenerated file from App/Account/Content/PatientsList/Filters/WidgetTypeChooser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_PatientsList_Filters_WidgetTypeChooser{
  leads_configurations(distinct_on: [name], order_by: [{ name: asc }]) {
    id
    text: name
  }
}

`