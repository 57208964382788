import { dateISOIn } from 'Data/format.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let image_series = useDataValue({
    context: 'image_series',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'tab',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (!image_series.length) return
    // prevent updating selected treatment if treatment cards displayed for the previous patient
    if (patient_id !== image_series[0].patient._id) return

    change(next => {
      next.image_series_id = value
      next.image_id = null
    })
  }
}

export function asList(list) {
  return list.map(item => ({
    id: item.id,
    text: item.series_date
      ? `${dateISOIn(item.series_date)} - ${item.name}`
      : item.name,
  }))
}
