import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    return data?.map(entry => ({
      id: entry.id,
      action: entry.action,
      created_at: entry.audit_log.sys_created,
      created_by: entry.audit_log.created_by?.person,
    }))
  }, [data])
}
