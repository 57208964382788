import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let tab = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let submitGlobal = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let submitPatient = useDataSubmit({
    context: 'patient',
    viewPath: props.viewPath,
  })
  let patient = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    submitGlobal({
      type: 'patientNotifications/open',
      patient_id: patient._id,
    })

    return () => {
      submitGlobal({ type: 'patientNotifications/close' })
    }
  }, [patient._id])

  useEffect(() => {
    // an empty tab name means that the patient came from the URL
    // this ensures the tab gets the correct name once we have the data
    if (tab.name !== 'Patient') return

    // requestIdleCallback is not supported in Safari 14.1
    // eslint-disable-next-line compat/compat
    let id = window.requestIdleCallback(
      () => {
        submitPatient({ type: 'showPatient', tab: tab.tab })
      },
      { timeout: 1_000 }
    )

    return () => window.cancelIdleCallback(id)
  }, [tab]) // eslint-disable-line
  // ignore submit

  return <View {...props} />
}
