import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let connection_id = useDataValue({
    context: 'connection',
    path: 'id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      id: connection_id,
      display_name: value.display_name,
    })

    if (mutationResponse.error) {
      return notify(
        notifyError('An error has occurred renaming the connection')
      )
    }

    notify(notifySuccess('Connection name updated'))

    setFlowTo(normalizePath(props.viewPath, '../No'))

    return
  }
}
