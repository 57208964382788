import { useMemo } from 'react'
import { PATIENT_IMAGE_LAYOUT_DEFINITIONS } from 'Data/constants.js'

let DEFAULT_SETTINGS = {
  intraoral_mobile_capture_examples_enabled: false,
}
PATIENT_IMAGE_LAYOUT_DEFINITIONS.standard_8
  .flat()
  .filter(image => image.slot_key !== 'logo')
  .forEach(image => {
    DEFAULT_SETTINGS[image.slot_key] = { flip_x: false, flip_y: false }
  })

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return DEFAULT_SETTINGS

    let {
      images_settings: [image_settings],
      vaxiom_application_properties,
    } = data
    let settings = { ...DEFAULT_SETTINGS }
    if (image_settings) {
      settings = { ...settings, ...image_settings.data }
    }
    vaxiom_application_properties.forEach(item => {
      let slot_key = item.message_key.replace('image.auto-flipping.', '')
      settings[slot_key] = {
        flip_x: item.message_value === 'Horizontal',
        flip_y: item.message_value === 'Vertical',
      }
    })
    return settings
  }, [data])
}
