import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api'
import { useUppy } from 'DesignSystem/FileUploader/react.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let image_id = useDataValue({
    context: 'patient_image',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let uppy = useUppy(props)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick(file) {
    try {
      setFlowTo(normalizePath(props.viewPath, '../Actions/Saving/Content'))

      uppy.cancelAll()

      uppy.addFile({
        name: file.name,
        type: file.type,
        size: file.size,
        data: file,
      })

      await uppy.upload()

      let [image] = uppy.getFiles()

      await executeMutation({
        id: image_id,
        file_key: image.meta.location.substring(
          image.meta.location.lastIndexOf('/') + 1
        ),
      })

      notify(notifySuccess(`The image was saved successfully`))
    } catch (err) {
      notify(notifyError(`Couldn't save the image. Please try again.`))
    } finally {
      setFlowTo(normalizePath(props.viewPath, '../Actions/Saving/No'))
    }
  }
}
