// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Locations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Locations($patient_id: uuid!) {
  vaxiom_tx_cards(
    where: { deleted: { _eq: false }, patient: { _id: { _eq: $patient_id } } }
    order_by: { latest_appointment_booking_start_time: desc_nulls_last }
  ) {
    id
    txs(where: { deleted: { _eq: false } }) {
      id
      organization {
        id
        name
      }
    }
  }
}

`