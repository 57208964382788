// this is an autogenerated file from App/Account/Content/PatientsList/Filters/LocationChooser/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_PatientsList_Filters_LocationChooser_Content{
  session_user_locations(order_by: { name: asc }) {
    id: _id
    text: name
  }
}

`