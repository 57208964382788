import { useDataValue } from 'Simple/Data.js'

import { useMemo } from 'react'
import useEnsureDefaultTab from './useEnsureDefaultTab.js'
import useEnsureTab from 'Logic/use-ensure-tab.js'

export default function useDataTransform(props, data) {
  let tabs = useDataValue({
    context: 'global',
    path: 'tabs',
    viewPath: props.viewPath,
  })

  let { id: tab_id } = data
  let ensureDefaultTab = useEnsureDefaultTab(props, data)

  let tab = useMemo(
    () =>
      ensureDefaultTab(
        tabs.find(item => item.id === tab_id),
        tab_id
      ),
    [tab_id] // eslint-disable-line
  ) // only care about first render

  useEnsureTab({
    viewPath: props.viewPath,
    tab,
  })

  return tab
}
