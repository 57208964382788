// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/AlignItero/Content/Content/launch-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_AlignItero_Content_Content($id: uuid!, $connection_id: uuid!) {
  apps_align_scan_request_launcher(id: $id, connection_id: $connection_id) {
    url
  }
}

`