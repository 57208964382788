// this is an autogenerated file from App/Account/NewPatient/Content/similarPersonsQuery.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_NewPatient_Content(
  $first_name: String!
  $last_name: String!
  $birth_date: date!
) {
  vaxiom_persons_aggregate(
    where: {
      first_name: { _eq: $first_name }
      last_name: { _eq: $last_name }
      birth_date: { _eq: $birth_date }
    }
  ) {
    aggregate {
      count
    }
  }
}

`