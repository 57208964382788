import isInIframe from './is-in-iframe.js'

export let localStorage = {
  getItem,
  setItem,
  removeItem,
}

let _storageWhenInIframe = {}

function getItem(key) {
  if (isInIframe()) {
    return _storageWhenInIframe[key]
  } else {
    return window.localStorage.getItem(key)
  }
}

function setItem(key, value) {
  if (isInIframe()) {
    _storageWhenInIframe[key] = value
  } else {
    window.localStorage.setItem(key, value)
  }
}

function removeItem(key) {
  if (isInIframe()) {
    delete _storageWhenInIframe[key]
  } else {
    window.localStorage.removeItem(key)
  }
}

export function windowAddEventListenerStorage(listener) {
  if (isInIframe()) return

  window.addEventListener('storage', listener)
}

export function windowRemoveEventListenerStorage(listener) {
  if (isInIframe()) return

  window.removeEventListener('storage', listener)
}
