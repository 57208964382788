import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let id = useDataValue({
    context: 'event',
    path: 'appointment.booking._id',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onClick() {
    // check settings (patient is late or has billing overdue)
    let mutationResponse = await executeMutation({
      id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Cannot check in selected appointment. Please try again.')
      )
    }
  }
}
