import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })
  let changeLayout = useDataChange({
    context: 'tab',
    path: 'selected.images.layout',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, change, originalValue }) {
    let v_result = validate(value)
    if (!v_result.is_valid) {
      notify(notifyError(`Invalid data: ${v_result.errors}`))
      change(originalValue)
      return
    }

    changeLayout('itero')
    let mutationResponse = await executeMutation({
      image_series_id: value.image_series_id,
      location_id,
      app_id: value.app_id,
      connection_name: value.connection_name,
      account_id: value.account_id,
      set_preferred: value.set_preferred,
      doctor_id: value.doctor_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(`Scan generation from iTero failed. Please try again.`)
      )
    } else {
      notify(notifySuccess(`iTero scan generated successfully.`))
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function validate(value) {
  let errors = [
    !value.account_id && 'missing account id',
    !value.doctor_id && 'missing doctor',
  ]
    .filter(Boolean)
    .join(', ')

  return {
    is_valid: !errors.length,
    errors,
  }
}
