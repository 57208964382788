import { personName } from 'Data/format.js'

/**
 *
 * @param {string} selected
 * @param {{id: string, person: object, role: 'Provider' | 'Assistant'}[]} providers_and_assistants
 * @returns
 */
export function joinWithComma(selected, providers_and_assistants) {
  let result = providers_and_assistants
    .filter(
      pa => pa.role === (selected === 'providers' ? 'Provider' : 'Assistant')
    )
    .map(pa => personName(pa.person))
    .join(',')

  return result.endsWith(',') ? result.slice(0, -1) : result
}
