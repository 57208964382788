/**
 * This file is automatically generated by Simple and will be overwritten
 * when the morpher runs. If you want to contribute to how it's generated, eg,
 * improving the algorithms inside, etc, see this:
 * https://github.com/use-simple/morpher/blob/master/ensure-flow.js
 */

/**
 * @typedef {import('./types.js').ViewPath} ViewPath
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'

import Flows from './Flow.json'
import { useToolsFlow } from './Tools'
import makeDebug from './debug.js'

/** @type {import('./types.js').FlowsJson} */
let { definitions: flowDefinition, roots } = Flows
export { flowDefinition }

if (process.env.NODE_ENV === 'development') {
  if (import.meta.hot) {
    import.meta.hot.accept('./Flow.json', (nextFlow) => {
      flowDefinition = nextFlow.definitions
      roots = nextFlow.roots
    })
  }
}

let debug = makeDebug('simple/flow')

let FLOW_KEY_WITH_ARGUMENTS = /\(.+?\)/g
/** @type {typeof import('./types.js').isFlowKeyWithArguments} */
export function isFlowKeyWithArguments(item) {
  return FLOW_KEY_WITH_ARGUMENTS.test(item)
}
/** @type {typeof import('./types.js').getFlowDefinitionKey} */
export function getFlowDefinitionKey(key) {
  return key.replace(FLOW_KEY_WITH_ARGUMENTS, '')
}
/** @type {typeof import('./types.js').getFlowDefinition} */
export function getFlowDefinition(key) {
  return flowDefinition[getFlowDefinitionKey(key)]
}
/** @type {typeof import('./types.js').getParentView} */
export function getParentView(key) {
  let parentBits = key.split('/')
  let view = parentBits.pop()
  let parent = parentBits.join('/')
  return [parent, view]
}

function findSeparateParentAndView(key) {
  if (!key || key === '/') return [null, null]

  let [parent, view] = getParentView(key)
  let parentFlowDefinitionKey = getFlowDefinitionKey(parent)
  return parentFlowDefinitionKey in flowDefinition
    ? [parent, view]
    : findSeparateParentAndView(parent)
}

function makeRemovedRegexAlternatives(keys) {
  return keys
    .map((rkey) => {
      let key = rkey.replace(/\(/g, '\\(').replace(/\)/g, '\\)')
      return `${key}$|${key}/|${key}\\(`
    })
    .join('|')
}

function getNextFlowWithoutKeys(removed, flow) {
  if (removed.length === 0) return flow

  let removedRegex = new RegExp(`^(${makeRemovedRegexAlternatives(removed)})`)

  return Object.fromEntries(
    Object.entries(flow).filter(([key]) => !removedRegex.test(key))
  )
}

/** @type {typeof import('./types.js').getNextFlow} */
export function getNextFlow(rkeys, rflow) {
  let keys = Array.isArray(rkeys) ? rkeys : [rkeys]
  if (keys.length === 0) return rflow

  let flow = { ...rflow }
  let added = false
  let removed = []
  keys.forEach((key) => {
    let [parent, view] = findSeparateParentAndView(key)
    while (parent && view) {
      let viewCurrent = flow[parent]
      if (viewCurrent !== view) {
        flow[parent] = view
        added = true

        if (viewCurrent) {
          removed.push(`${parent}/${viewCurrent}`)
        }
      }

      ;[parent, view] = findSeparateParentAndView(parent)
    }
  })

  if (!added && removed.length === 0) return rflow

  return getNextFlowWithoutKeys(removed, flow)
}

let MAX_ACTIONS = 10000
// TODO: type
function getNextActions(state, action) {
  return [action, ...state.actions].slice(0, MAX_ACTIONS)
}

let Context = React.createContext(null)

/** @type {typeof import('./types.js').useFlowContext} */
function useFlowContext() {
  return useContext(Context)
}

export function useFlowState() {
  return useFlowContext()[0]
}

// TODO: type
function isKeyInFlow(key, flow, checkParent = false) {
  // active view in flow
  let [parent, view] = getParentView(key)
  let value = flow[parent]
  if (value === view) return true
  if (typeof value === 'string') return false

  if (checkParent) {
    if (roots.includes(key)) return true
    if (parent) return isKeyInFlow(parent, flow, checkParent)
  }

  // TODO: FIXME HACK: check for a definition key instead of the arguments
  // version of it because Tools doesn't understand list items on the
  // flow just yet and sets the flow to the definition key instead
  if (isFlowKeyWithArguments(key)) {
    let definitionKey = getFlowDefinitionKey(key)
    let [parent, view] = getParentView(definitionKey)
    let value = flow[parent]
    if (value === view) return true
    if (typeof value === 'string') return false
  }

  // first view defined on the flow
  let parentFlowDefinition = getFlowDefinition(parent)
  return Array.isArray(parentFlowDefinition) && parentFlowDefinition[0] === view
}

/** @type {typeof import('./types.js').useFlow} */
export function useFlow() {
  let [state] = useFlowContext()
  let cache = useRef(new Set())
  useEffect(() => {
    cache.current = new Set()
  }, [state.flow])

  return { has, flow: state.flow }
  /** @param {ViewPath} rkey */
  function has(rkey) {
    if (!rkey) return false

    let key = patchViewPath({
      viewPath: rkey,
      flowMountedViewPath: state.viewPath,
      flowDefinitionViewPathSource: state.flowDefinitionViewPathSource,
    })
    if (!cache.current.has(key)) {
      if (isKeyInFlow(key, state.flow)) {
        cache.current.add(key)
      }
    }
    return cache.current.has(key)
  }
}

/** @type {typeof import('./types.js').useFlowValue} */
export function useFlowValue(rviewPath) {
  let [state] = useFlowContext()
  let viewPath = patchViewPath({
    viewPath: rviewPath,
    flowMountedViewPath: state.viewPath,
    flowDefinitionViewPathSource: state.flowDefinitionViewPathSource,
  })

  let flowValue = state.flow[viewPath]
  if (flowValue) return flowValue

  // TODO: FIXME HACK: remove once Tools supports arguments in viewPaths
  if (isFlowKeyWithArguments(viewPath)) {
    let definitionKey = getFlowDefinitionKey(viewPath)
    return state.flow[definitionKey]
  }
}

/** @type {typeof import('./types.js').useSetFlowTo} */
export function useSetFlowTo(source) {
  let [, dispatch] = useFlowContext()

  return useCallback(
    function setFlowTo(target, data = null) {
      dispatch(makeSetFlowToAction({ source, target, data }))
    },
    [dispatch, source]
  )
}

/** @type {typeof import('./types.js').makeSetFlowToAction} */
export function makeSetFlowToAction({ target, source, data = null }) {
  let ts = Date.now()
  debug({ type: 'set', target, source, data, ts })
  return { target, source, data, ts }
}

// TODO: type
function isCurrentActionSameAsLast(current, lastAction) {
  if (!lastAction) return false

  let currentIsArray = Array.isArray(current.target)
  let lastIsArray = Array.isArray(lastAction.target)

  if (currentIsArray && lastIsArray) {
    return current.target.some((item) => lastAction.target.includes(item))
  } else if (currentIsArray && !lastIsArray) {
    return current.target.includes(lastAction.target)
  } else if (!currentIsArray && lastIsArray) {
    return lastAction.target.includes(current.target)
  } else {
    return lastAction.target === current.target
  }
}

/** @type {typeof import('./types.js').flowReducer} */
function reducer(state, action) {
  let target = patchViewPath({
    viewPath: action.target,
    flowMountedViewPath: state.viewPath,
    flowDefinitionViewPathSource: state.flowDefinitionViewPathSource,
  })
  let source = patchViewPath({
    viewPath: action.source,
    flowMountedViewPath: state.viewPath,
    flowDefinitionViewPathSource: state.flowDefinitionViewPathSource,
  })

  if (process.env.NODE_ENV === 'development') {
    let targets = Array.isArray(target) ? target : [target]
    let hasInvalidTarget = targets.some((target) => {
      let [definitionKey, definitionView] = getParentView(
        getFlowDefinitionKey(target)
      )

      if (
        !flowDefinition[definitionKey] ||
        !flowDefinition[definitionKey].includes(definitionView)
      ) {
        debug({
          type: 'invalid-view',
          invalid: target,
          target,
          source,
          definitionKey,
          definitionView,
          flowDefinition,
        })
        return true
      }
      return false
    })

    if (hasInvalidTarget) return state
  }

  if (source && source !== '/Tools' && !isKeyInFlow(source, state.flow, true)) {
    return state
  }

  if (isCurrentActionSameAsLast(action, state.actions[0])) {
    debug({
      type: 'already-set-as-last-action-ignoring',
      target,
      source,
      actions: state.actions,
    })
    return state
  }

  let flow = getNextFlow(target, state.flow)
  if (flow === state.flow) {
    debug({
      type: 'same-flow',
      target,
      source,
    })
    return state
  }

  return {
    flow,
    actions: getNextActions(state, {
      target,
      source,
      data: action.data,
      ts: action.ts,
    }),
    version: state.version + 1,
    viewPath: state.viewPath,
    flowDefinitionViewPathSource: state.flowDefinitionViewPathSource,
  }
}

// TODO
// /**
//  * @param {{
//  *  viewPath: ViewPath | ViewPath[],
//  *  flowMountedViewPath: ViewPath,
//  *  flowDefinitionViewPathSource: ViewPath
//  * }} params
//  * @returns {ViewPath | ViewPath[]}
//  */
function patchViewPath({
  viewPath,
  flowMountedViewPath,
  flowDefinitionViewPathSource,
}) {
  if (flowMountedViewPath === flowDefinitionViewPathSource) return viewPath

  if (typeof viewPath === 'string') {
    return viewPath.replace(flowMountedViewPath, flowDefinitionViewPathSource)
  } else if (Array.isArray(viewPath)) {
    return viewPath.map((viewPath) =>
      patchViewPath({
        viewPath,
        flowMountedViewPath,
        flowDefinitionViewPathSource,
      })
    )
  } else {
    return viewPath
  }
}

/** @type {typeof import('./types.js').Flow} */
export function Flow({
  initialState = {},
  onChange,
  children,
  viewPath,
  flowDefinitionViewPathSource = viewPath,
}) {
  let context = useReducer(reducer, {
    actions: [],
    flow: initialState,
    version: 0,
    viewPath,
    flowDefinitionViewPathSource,
  })
  let [state] = context

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state, onChange])

  useToolsFlow({ context, viewPath })

  return <Context.Provider value={context}>{children}</Context.Provider>
}

/** @type {typeof import('./types.js').normalizePath} */
export function normalizePath(viewPath, relativePath) {
  let url = new URL(`file://${viewPath}/${relativePath}`)
  return url.pathname
}

export function When(props) {
  return props.is ? props.children : null
}
