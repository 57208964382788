import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let changeAccountView = useDataChange({
    context: 'account_settings',
    path: 'view',
    viewPath: props.viewPath,
  })

  return function onClick() {
    changeAccountView('accounts')
  }
}
