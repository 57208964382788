import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(event) {
    event.stopPropagation()

    setFlowTo(normalizePath(props.viewPath, 'Confirmation/Content'))
  }
}
