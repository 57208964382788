// this is an autogenerated file from App/Account/Content/Comms/Filter/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Comms_Filter($user_id: uuid!) {
  comms_mobile_numbers_outbound_allowed_to_send_from {
    id
    mobile_numbers_outbound_permissions {
      location_id
    }
  }
  comms_user_preferences: comms_user_preferences_by_pk(user_id: $user_id) {
    user_id
    location_ids: data(path: "location_ids")
  }
  session_user_locations(order_by: { name: asc }) {
    id
    _id
    name
  }
}

`