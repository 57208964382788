// this is an autogenerated file from DesignSystem/EditAutomation/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_Location($organization_id: uuid!) {
  vaxiom_sys_organizations(where: { _id: { _eq: $organization_id } }) {
    id
    name
  }
}

`