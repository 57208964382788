// this is an autogenerated file from App/ChooseLocation/mutation-login-xid.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_ChooseLocation($xid: String!) {
  vaxiom_users_login_xid(xid: $xid) {
    status
    access_token: jwt_token
    refresh_token
    inactivity_timeout
  }
}

`