// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/Cancel/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More_Cancel_Content($id: uuid!) {
  vaxiom_appointment_booking_cancel(id: $id) {
    id
  }
}

`