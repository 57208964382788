import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import {
  TREATMENT_PAYMENT_PLAN_LENGTH_TYPES,
  TREATMENT_PAYMENT_PLAN_VALUE_TYPES,
} from 'Data/constants.js'
import { numberZeroOrPositive } from 'Data/validate.js'
import { toSentence } from 'Data/format.js'

export function useOnSubmit(props) {
  let changes = useDataValue({
    context: 'ruleset_changes',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit() {
    let validation = validate(changes.update_changes)
    if (validation.isInvalid) {
      notify(notifyError(`Invalid data - ${validation.errors.join(', ')}`))
      return false
    }

    let mutationResponse = await executeMutation({
      rulesets_to_delete: changes.delete_changes,
      rulesets_to_update: changes.update_changes.map(ruleset => ({
        where: { id: { _eq: ruleset.id } },
        _set: {
          tx_plan_group_id: ruleset.tx_plan_group_id,
          min_downpayment: ruleset.min_downpayment,
          min_downpayment_type: ruleset.min_downpayment_type,
          max_length: ruleset.max_length,
          max_length_type: ruleset.max_length_type,
        },
      })),
    })

    if (mutationResponse.error) {
      return notify(notifyError('Something went wrong. Please, try again.'))
    }

    return notify(notifySuccess(`Rulesets updated successfully!`))
  }
}

function validate(rulesets) {
  let errors = rulesets
    .map(item => {
      let max_length = parseFloat(item.max_length)
      let errors = [
        !item.tx_plan_group_id && 'missing treatment plan group',
        !TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(
          item.min_downpayment_type
        ) && 'invalid minimum downpayment type',
        !numberZeroOrPositive(item.min_downpayment) &&
          'invalid minimum downpayment',
        item.min_downpayment_type === 'percentage' &&
          item.min_downpayment > 100 &&
          'invalid percentage for minimum downpayment (must be a number between 0 and 100)',
        !TREATMENT_PAYMENT_PLAN_LENGTH_TYPES.includes(item.max_length_type) &&
          'invalid maximum length type',
        !Number.isInteger(max_length) &&
          'invalid maximum length (must be an integer)',
        item.max_length_type === 'total' &&
          max_length < 0 &&
          'invalid total maximum length (must be a non-negative integer)',
        item.min_downpayment === 100 &&
          item.min_downpayment_type === 'percentage' &&
          (max_length !== 0 || item.max_length_type !== 'total') &&
          'a minimum downpayment of 100% on the payer fee requires a total maximum length of 0 months',
        max_length === 0 &&
          item.max_length_type === 'total' &&
          (item.min_downpayment !== 100 ||
            item.min_downpayment_type !== 'percentage') &&
          'a total maximum length of 0 months requires a minimum downpayment of 100%',
      ].filter(Boolean)

      if (errors.length === 0) return null
      return `card ${item.index + 1}: ${toSentence(errors)}`
    })
    .filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}
