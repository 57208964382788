export function getStatusText(status) {
  switch (status) {
    case 'active':
      return 'Active'
    case 'pending':
      return 'Awaiting verification'
    case 'invalidated':
      return 'Invalidated'
    default:
      return ''
  }
}
