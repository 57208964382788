// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/NotesTab/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_NotesTab($id: uuid!) {
  vaxiom_notes_aggregate(
    where: {
      patient: { _id: { _eq: $id } }
      _or: [
        { target_type: { _eq: "Patient.CheckIn" } }
        {
          target_type: { _eq: "com.axpm.patients.core.internal.domain.Patient" }
        }
      ]
    }
  ) {
    aggregate {
      count
    }
  }
}

`