// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/TreatmentFields/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_TreatmentFields($appointment_id: uuid!) {
  appointments_treatment_fields(args: { _appointment_id: $appointment_id }) {
    tx_card_field_definition_id
    appointment_id
    tracked_appointment_id
    field_definition {
      id
      name
      multi_value
      hide_for_future
      mandatory
      tracked
      number
      type {
        id
        value_type
        is_free_text
        options: tx_card_field_options(
          where: { deleted: { _eq: false } }
          order_by: { pos: asc_nulls_last }
        ) {
          id
          name: value
        }
      }
    }
    # value set explicitly for the current appointment
    values {
      appointment_id
      field_definition_id
      field_option_id
      field_option {
        id
        name: value
      }
      text_value
    }
    # value inferred from previous appointments (only relevant for tracked fields)
    tracked_values {
      appointment_id
      field_definition_id
      field_option_id
      field_option {
        id
        name: value
      }
      text_value
    }
  }
}

`