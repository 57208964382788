import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getNumberOfInstallmentsPerMonth,
  getPayorShareAfterDiscounts,
  LOCK,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      if (value === payor.payment_plan_length) return

      payor.payment_plan_length = value

      let base = getPayorShareAfterDiscounts(payor, next)

      if (value === 0) {
        payor.installment_amount = 0
        payor.downpayment_amount = base
      } else if (!payor.lock || payor.lock === LOCK.DOWNPAYMENT_AMOUNT) {
        let total_installments_amount = base - payor.downpayment_amount
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let total_number_of_installments =
          value * number_of_installments_per_month
        let installment_amount =
          total_installments_amount / total_number_of_installments
        payor.installment_amount = parseFloat(installment_amount.toFixed(2))
      } else if (payor.lock === LOCK.INSTALLMENT_AMOUNT) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let total_installments_amount = Math.min(
          payor.installment_amount * value * number_of_installments_per_month,
          base
        )
        payor.downpayment_amount = base - total_installments_amount
        let monthly_installment_amount =
          payor.installment_amount * number_of_installments_per_month
        // reset the installment length to not allow to have negative installment amounts
        payor.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
      }
    })
  }
}

export function formatPaymentPlanLength(value) {
  return `${value} months`
}

export function isDisabled(lock, installment_amount) {
  return lock === LOCK.PAYMENT_PLAN_LENGTH || installment_amount === 0
}
