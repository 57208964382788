import { useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change(next => {
      next.insurances = []

      // all the discounts and charges should be updated to not be included in insurance claim
      next.discounts.forEach(discount => {
        discount.is_included_in_insurance_claim = false
      })
      next.charges.forEach(charge => {
        charge.is_included_in_insurance_claim = false
      })

      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
