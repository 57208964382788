import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    if (!data.length) return null

    return [
      {
        id: '{{connection.access_token}}',
        description: 'Access token to integrate with Align IDS system',
        showFor: 'ids',
      },
      {
        id: '{{parent_company.external_mappings.id}}',
        description: 'Align IDS doctor ID',
        showFor: 'ids',
      },
      {
        id: '{{patient.external_mappings.ids_id}}',
        description: 'Align IDS patient ID',
        showFor: 'ids',
      },
      {
        id: '{{patient.ids_gender}}',
        description: "Patient's gender formatted for Align IDS system",
        showFor: 'ids',
      },
      {
        id: '{{connection.access_token}}',
        description: 'Access token to integrate with Align iTero system',
        showFor: 'itero',
      },
      {
        id: '{{patient.itero_gender}}',
        description: "Patient's gender formatted for Align iTero system",
        showFor: 'itero',
      },
    ].filter(item => data.some(element => element.name === item.showFor))
  }, [data])
}
