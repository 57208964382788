import { useMemo } from 'react'

let UTM_NAMES = {
  deviceCategory: 'category',
  newVsReturning: 'newVsReturning',
  sessionManualCampaignId: 'campaign_id',
  sessionManualCampaignName: 'campaign_name',
  sessionManualSource: 'source',
  sessionManualTerm: 'term',
  sessionManualMedium: 'medium',
  sessionManualAdContent: 'content',
}

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let total_paying = 0

    let { total, total_conversions, total_incompletes, total_created, leads } =
      data

    leads = leads
      .map(lead => {
        // only need table data where we have contact details
        if (!lead.metadata.person?.firstName && !lead.metadata.person?.lastName)
          return false

        let analytics

        // get the metadata from analytics
        let latest_analytics = lead.analytics?.[0]?.metadata
        if (latest_analytics?.rows?.length > 0) {
          analytics = latest_analytics.dimensionHeaders
            .map((dimension, i) => {
              return {
                name: UTM_NAMES[dimension.name] || dimension.name,
                value: latest_analytics.rows.at(-1).dimensionValues[i].value,
              }
            })
            .reduce((acc, { name, value }) => {
              acc[name] = value
              return acc
            }, {})
        }

        if (!!lead?.patient?.person?.tx_payers[0]?.tx?.tx_plan?.fee) {
          total_paying++
        }

        return {
          first_name: formatEmpty(lead.metadata.person?.firstName),
          last_name: formatEmpty(lead.metadata.person?.lastName),
          email: formatEmpty(lead.metadata.person?.email),
          phone: formatEmpty(lead.metadata.person?.phoneDetails?.number),
          fee: formatEmpty(
            lead?.patient?.person?.tx_payers[0]?.tx?.tx_plan?.fee
          ),
          paying: !!lead?.patient?.person?.tx_payers[0]?.tx?.tx_plan?.fee,
          conversion: !!lead?.patient,
          scheduled:
            !!lead?.patient?.appointments?.[0]?.appointment_bookings?.[0],
          questions: lead.configuration.metadata.questions.reduce(
            (acc, currentItem) => {
              acc[currentItem.id] = currentItem
              return acc
            },
            {}
          ),
          ...lead,
          ...analytics,
        }
      })
      .filter(Boolean)

    return {
      leadsById: leads.reduce((acc, current) => {
        acc[current.id] = current
        return acc
      }, {}),
      leads,
      total,
      total_conversions,
      total_incompletes,
      total_created,
      total_paying,
    }
  }, [data])
}

function formatEmpty(value) {
  return value || '-'
}
