import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let setImageSeries = useDataChange({
    context: 'patient_documents',
    path: 'selected_image_series',
    viewPath: props.viewPath,
  })
  return function onClick() {
    setImageSeries(null)
  }
}
