import { useDataValue, useDataChange } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let row = useDataValue({
    context: 'row',
    viewPath: props.viewPath,
  })

  let order = useDataValue({
    context: 'selected',
    path: 'ordered_rows',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'current_row',
    path: 'item',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change({ index: order.indexOf(row.id), id: row.id })
    setFlowTo(normalizePath(props.viewPath, '../../Detail/Content'))
  }
}
