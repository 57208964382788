import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let tx_statuses = useDataValue({
    context: 'tx_statuses',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.event.data.value_to = value

      next.event.data.tx_status_name = Array.isArray(tx_statuses)
        ? tx_statuses
            .filter(status => value.includes(status.id))
            .map(status => status.text)
        : []
    })
  }
}
