// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/DentalMonitoring/Content/Started/Pause/Details/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_DentalMonitoring_Content_Started_Pause_Details($id: uuid!, $resume_date: numeric!) {
  dental_monitoring_patient_monitoring_pause(
    id: $id
    resume_date: $resume_date
  ) {
    id
    patient {
      monitoring {
        status
        resume_date
        config {
          protocol_title
        }
      }
    }
  }
}

`