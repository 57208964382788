// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/Form/Content/FindSlot/Content/SlotSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_Form_Content_FindSlot_Content_SlotSelect(
  $location_id: uuid!
  $appointment_type_id: uuid!
  $start_date: date!
  $end_date: date!
) {
  calendar_scheduling_get_candidate_slots(
    args: {
      _appointment_type_id: $appointment_type_id
      _location_id: $location_id
      _start_date: $start_date
      _end_date: $end_date
    }
    order_by: { date: asc, start_min: asc }
  ) {
    # keep synced with apps/admin/src/Data/ApiCacheExchangeKeys.js (urql cache id)
    xid
    chair_id
    date
    start_time
    end_time
    start_min
    end_min
    chair {
      _id
      full_name
    }
  }
}

`