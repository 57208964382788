// this is an autogenerated file from App/Account/Content/Marketing/ReportTable/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Marketing_ReportTable_Content(
  $where: leads_leads_bool_exp!
  $where_total: leads_leads_bool_exp!
  $where_total_incompletes: leads_leads_bool_exp!
  $where_total_created: leads_leads_bool_exp!
  $where_total_patient_created: leads_leads_bool_exp!
) {
  leads: leads_leads(where: $where) {
    id
    metadata
    created_at
    updated_at
    location_id
    patient_id
    configuration {
      id
      metadata
    }
    location {
      id
      name
    }
    patient {
      id
      _id
      heard_from_other
      heard_from_website
      patient_template_referrals {
        id
        text_value
        referral_template {
          id
          type
          free_type
        }
      }
      person {
        id
        _id
        first_name
        last_name
        tx_payers {
          id
          tx {
            id
            tx_plan {
              id
              fee
            }
          }
        }
      }
      appointments(
        limit: 1
        where: {
          appointment_bookings: { state: { _in: ["CHECKED_OUT", "SCHEDULED"] } }
        }
      ) {
        id
        appointment_bookings(limit: 1) {
          id
        }
      }
    }
    analytics(order_by: { created_at: desc }, limit: 1) {
      id
      metadata
    }
  }

  total: leads_leads_aggregate(where: $where_total) {
    aggregate {
      count
    }
  }
  total_incompletes: leads_leads_aggregate(where: $where_total_incompletes) {
    aggregate {
      count
    }
  }
  total_created: leads_leads_aggregate(where: $where_total_created) {
    aggregate {
      count
    }
  }
  total_conversions: leads_leads_aggregate(
    where: $where_total_patient_created
  ) {
    aggregate {
      count
    }
  }
}

`