import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { startOfMonth, subMonths, addMonths, isAfter } from 'date-fns'

/**
 * @param {'left' | 'right'} direction
 */
function useOnClick(props, direction) {
  let start_date = useDataValue({
    context: 'date_select',
    path: 'start_date',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  return function onChange() {
    submit({
      type: 'updateMonth',
      date:
        direction === 'left'
          ? startOfMonth(subMonths(start_date, 1))
          : startOfMonth(addMonths(start_date, 1)),
    })
  }
}

export function useOnClickArrowLeft(props) {
  return useOnClick(props, 'left')
}

export function useOnClickArrowRight(props) {
  return useOnClick(props, 'right')
}

/**
 * @param {Date} location_current_date
 * @param {Date} start_date
 * @returns {boolean}
 */
export function isLeftArrowDisabled(location_current_date, start_date) {
  return isAfter(
    startOfMonth(location_current_date),
    startOfMonth(subMonths(start_date, 1))
  )
}
