import { patientName } from 'Data/format.js'
import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'reFetch': {
        reExecuteQuery({ requestPolicy: 'cache-and-network' })
        return
      }

      case 'showPatient': {
        submit({
          type: 'tabs/add',
          tab: {
            id: value.patient_id,
            name: patientName(value.patient_person),
            type: 'Patient',
            tab: args.tab,
            viewPath: '/App/Account/Content/Patients',
            patient_id: value.patient_id,
          },
        })
        return
      }

      default: {
      }
    }
  }
}
