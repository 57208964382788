import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'

export default function useOnSubmit(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (!value?.id) return

    let mutationResponse = await executeMutation({
      ...value,
      treatment_id: value.treatment_id || null,
      appointment_id: value.appointment_id || null,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't update the image series`))
    }

    notify(notifySuccess('Image series updated successfully'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
