import { useDataValue } from 'Simple/Data'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let { url, body_type, ...options } = useDataValue({
    context: 'api',
    path: 'req',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      url,
      options: {
        ...options,
        headers: Array.isArray(options.headers)
          ? options.headers.reduce((acc, { key, value }) => {
              if (!key) {
                acc[key] = value
              }

              return acc
            }, {})
          : null,
      },
    },
    pause: false,
  }
}
