// this is an autogenerated file from App/Account/WaitingRoom/Content/Content/Checkout/Appointments/Content/Appointment/StateOrAction/CheckOut/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_WaitingRoom_Content_Content_Checkout_Appointments_Content_Appointment_StateOrAction_CheckOut($id: numeric!, $state: String!) {
  update_vaxiom_appointment_bookings(
    where: { id: { _eq: $id }, state: { _eq: $state } }
    _set: { state: "CHECKED_OUT" }
  ) {
    affected_rows
  }
  insert_vaxiom_appointment_booking_state_changes_one(
    object: {
      booking_id: $id
      old_state: $state
      new_state: "CHECKED_OUT"
      change_time: "now()"
      sys_version: 0
    }
  ) {
    id
  }
}

`