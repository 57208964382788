import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import query from './query.graphql.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useClient, useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import mutationCreatePatient from './mutation-create.graphql.js'
import mutationForgetPatient from './mutation-forget.graphql.js'
import {
  validatePatientGenderAlignIDS,
  validatePatientNameAlignIDS,
} from 'Data/validate.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let client = useClient()
  let patient = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
  })
  let connection_id = useDataValue({
    context: 'connection',
    path: 'id',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'connection',
    path: 'app_id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'connection',
    path: 'name',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  let [, executeMutationCreate] = useMutation(mutationCreatePatient)
  let [, executeMutationForget] = useMutation(mutationForgetPatient)

  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)

  function validatePatientData(person, address) {
    return (
      validatePatientNameAlignIDS(person.first_name) &&
      validatePatientNameAlignIDS(person.last_name) &&
      validatePatientGenderAlignIDS(person.gender) &&
      person.birth_date &&
      address
    )
  }

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (value.action) {
      case 'create': {
        if (!patient?._id) {
          return notify(
            notifyError('Cannot sync patient. Something went wrong')
          )
        }

        let { data: patientAddressData, error: patientAddressError } =
          await client
            .query(
              query,
              { person_id: patient.person.id },
              { requestPolicy: 'network-only' }
            )
            .toPromise()

        if (patientAddressError) {
          notify(notifyError('Cannot sync patient. Something went wrong'))
          return
        }

        let isValid = validatePatientData(
          patient.person,
          patientAddressData.vaxiom_contact_method_associations[0]?.id
        )

        if (!isValid) {
          setFlowTo(
            normalizePath(props.viewPath, '../../../MissingInfoModal/Content')
          )
          return
        }

        let mutationResponse = await executeMutationCreate({
          app_id,
          connection_name,
          location_id,
          patient_id: patient._id,
        })

        if (
          !mutationResponse ||
          mutationResponse.error ||
          !mutationResponse.data.apps_align_ids_patient_create.id
        ) {
          return notify(
            notifyError(
              'Patient could not be created in Align. Please ensure the patient profile in Greyfinch has a recorded address with zip code, gender (female or male), date of birth, and that the first and last names are less than 25 characters (only letters and blank spaces are valid)'
            ),
            {
              hideAfter: 30000,
            }
          )
        }

        if (
          mutationResponse.data.apps_align_ids_patient_create.status ===
          'invalid_grant'
        ) {
          return notify(
            notifyError(
              mutationResponse.data.apps_align_ids_patient_create.error
            ),
            {
              hideAfter: 30000,
            }
          )
        }

        notify(notifySuccess('Patient successfully created in Align'))
        break
      }
      case 'forget': {
        let mutationResponse = await executeMutationForget({
          connection_id,
          patient_id: patient._id,
        })
        if (mutationResponse.error) {
          return notify(
            notifyError('Something went wrong. Please try again later.'),
            {
              hideAfter: 30000,
            }
          )
        }
        notify(notifySuccess('Patient forgotten successfully'))
        break
      }
      default: {
        return null
      }
    }
  }
}
