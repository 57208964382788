// this is an autogenerated file from DesignSystem/MoveToAnotherTreatment/Events/Content/Event/Appointment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_MoveToAnotherTreatment_Events_Content_Event_Appointment($id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $id } }) {
    id
    _id
    unplanned
    is_diagnosis_required
    is_imaging_required
    colors
    interval_to_next
    sys_created
    booking {
      id
      _id
      state
      start_time
      local_start_date
      local_start_time
      video_link
      chair_id
      provider_id
      assistant_id
      seated_chair {
        id
        _id
        code
        resource {
          id
          organization {
            id
            _id
          }
        }
      }
      chair {
        id
        resource {
          id
          organization {
            id
            name
          }
        }
      }
      provider {
        id
        employment_contract {
          id
          person {
            id
            first_name
            last_name
          }
        }
      }
      assistant {
        id
        employment_contract {
          id
          person {
            id
            first_name
            last_name
          }
        }
      }
    }
    type {
      id
      full_name
      display_name
      display_color_id
      color_id
      is_treatment_starter
      is_virtual
    }
  }
}

`