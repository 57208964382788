import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let treatment_card_id = useDataValue({
    context: 'tab',
    path: 'treatment_card_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit() {
    let mutationResponse = await executeMutation({
      treatment_card_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem deleting the treatment card. Please try again.`
        )
      )
      return
    }

    change(next => {
      next.treatment_card_id = null
      next.treatment_id = null
      next.image_series_id = null
      next.image_id = null
    })
    notify(notifySuccess(`The treatment card was deleted successfully.`))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
