import {
  useDataSubmit,
  useDataIsSubmitting,
  useDataValue,
} from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'payers',
    viewPath: props.viewPath,
  })

  return async function (value) {
    submit({
      type: 'downpayment',
    })
  }
}

export function useIsDisabled(props) {
  let paymentMethodValid = useDataValue({
    context: 'payment',
    path: 'valid',
    viewPath: props.viewPath,
  })

  let isSubmitting = useDataIsSubmitting({
    context: 'payers',
    viewPath: props.viewPath,
  })

  let paymentMethod = useDataValue({
    context: 'payers',
    viewPath: props.viewPath,
    path: 'activeTab.downpayment_metadata.type',
  })

  return paymentMethod === 'card' && (isSubmitting || !paymentMethodValid)
}
