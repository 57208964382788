export function getLocationsLengthMinus1(locations) {
  return locations.length - 1
}

export function getOtherLocations(locations) {
  return locations
    .slice(1)
    .map(item => item.name)
    .join(', ')
}
