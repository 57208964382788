import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import mutation from './mutation.graphql.js'

export function useOnClick(props, data) {
  let id = useDataValue({
    context: 'dental_monitoring',
    path: 'id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    if (!window.confirm('Are you sure?')) return

    let response = await executeMutation({ id })

    if (response.error) {
      notify(notifyError(`Can't stop DM treatment`))
    } else {
      notify(notifySuccess(`Stopped DM treatment`))
    }
  }
}
