import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .filter(
        // filter out providers that don't have an employment contract with the current location
        obj => !!obj.employment_contracts?.[0].employee_resources.length
      )
      .map(obj => ({
        ...obj,
        text: `${obj.first_name} ${obj.last_name}`,
        id: obj.employment_contracts[0].employee_resources[0].id,
      }))
  }, [data])
}
