import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let mobile_numbers_outbound = useDataValue({
    context: 'filter',
    path: 'comms_mobile_numbers_outbound_allowed_to_send_from',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'global',
    viewPath: props.viewPath,
  })

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return <View onClick={onClick} viewPath={props.viewPath} />

  async function onClick(value) {
    let location_ids = Array.isArray(value) ? value : [value]
    let mobile_numbers_outbound_ids =
      location_ids.length === 0
        ? []
        : mobile_numbers_outbound
            .filter(item =>
              item.mobile_numbers_outbound_permissions.find(item =>
                location_ids.includes(item.location_id)
              )
            )
            .map(item => item.id)

    let mutationResponse = await executeMutation({
      data: {
        mobile_numbers_outbound_ids,
        location_ids,
      },
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the locations filter. You may not have the permissions for your role. Contact your administrator.`
        )
      )
    } else {
      change(next => {
        next.user.comms_preferences.location_ids =
          mutationResponse.data.insert_comms_user_preferences_one.location_ids
        next.user.comms_preferences.mobile_numbers_outbound_ids =
          mutationResponse.data.insert_comms_user_preferences_one.mobile_numbers_outbound_ids
      })
    }
  }
}
