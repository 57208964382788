import { useMemo } from 'react'
import { useDataFormat } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let events = useDataFormat({
    context: 'events',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!Array.isArray(events) || !events.length) {
      return []
    }

    let future_separator_idx = events.findIndex(
      event => event.type === 'future_separator'
    )

    let lastPreviousAppointment =
      future_separator_idx > 0
        ? events
            .slice(0, future_separator_idx)
            .findLast(
              event =>
                event.type === 'appointment' && !event.appointment.unplanned
            )
        : null

    let futureAppointments = (
      future_separator_idx > 0 ? events.slice(future_separator_idx) : events
    ).filter(
      event => event.type === 'appointment' && !event.appointment.unplanned
    )

    return [lastPreviousAppointment, ...futureAppointments]
      .filter(Boolean)
      .map(event => ({
        id: event.appointment._id,
        text: event.appointment.type.display_name,
      }))
  }, [events])
}
