import isPast from 'date-fns/isPast'
import isToday from 'date-fns/isToday'

export function hasImages(appointment) {
  return (
    (isPast(new Date(`${appointment.booking.start_time}Z`)) ||
      isToday(new Date(`${appointment.booking.start_time}Z`))) &&
    appointment.is_imaging_required
  )
}
