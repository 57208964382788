import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'connection',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({ type: 'disable_connection' })
  }
}
