import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let provider_id = useDataValue({
    context: 'event',
    path: 'appointment.booking.provider_id',
    viewPath: props.viewPath,
  })
  let assistant_id = useDataValue({
    context: 'event',
    path: 'appointment.booking.assistant_id',
    viewPath: props.viewPath,
  })
  let chair_id = useDataValue({
    context: 'event',
    path: 'appointment.booking.chair_id',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      provider_id,
      assistant_id,
      chair_id,
    }),
    [assistant_id, chair_id, provider_id]
  )
}
