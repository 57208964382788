import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'reset_password_confirm',
    viewPath: props.viewPath,
  })
  return function onSubmit() {
    submit({ type: 'reset_password' })
  }
}
