import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let images = useDataValue({
    context: 'patient_images',
    viewPath: props.viewPath,
  })
  let image_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_id',
  })
  let setImageId = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_id',
  })

  useEffect(() => {
    let onKeyDown = event => {
      if (!images.length || !image_id) {
        return
      }

      let index = images.map(image => image.id).indexOf(image_id)
      if (event.key === 'ArrowLeft') {
        setImageId(images[Math.max(index - 1, 0)].id)
      } else if (event.key === 'ArrowRight') {
        setImageId(images[Math.min(index + 1, images.length - 1)].id)
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [image_id, images]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setImageId

  return <View {...props} />
}
