import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    return data.map(d => ({
      id: d.organization_id,
      vaxiom_id: d.vaxiom_organization_id,
      text: d.text,
    }))
  }, [data])
}
