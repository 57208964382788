// this is an autogenerated file from App/Account/PatientNotifications/Content/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientNotifications_Content_Content($patient_id: numeric!, $patient_uuid: uuid!) {
  medical_histories_questions(
    where: {
      status: { _eq: active }
      answers: { patient_id: { _eq: $patient_uuid } }
    }
  ) {
    id
    name
    alert_type
    alert_conditions
    category
    type
    prompt
    answers(
      where: { patient_id: { _eq: $patient_uuid } }
      order_by: { created_at: desc_nulls_last }
      limit: 1
    ) {
      id
      alert
      value
      created_at
      patient {
        id
        person {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
  }
  vaxiom_notes(
    where: {
      target_id: { _eq: $patient_id }
      target_type: {
        _in: [
          "com.axpm.patients.core.internal.domain.Patient"
          "Patient.CheckIn"
        ]
      }
      alert: { _eq: true }
    }
    order_by: { sys_created: desc_nulls_last }
  ) {
    id
    alert
    note
    sys_created
    author_user {
      id
      person {
        id
        first_name
        middle_name
        last_name
      }
    }
  }
}

`