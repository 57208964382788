import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let setOpenImagesTab = useDataChange({
    context: 'refresh',
    path: 'open_images_tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.image_series_id = appointment.patient_imageseries[0].id
      next.image_id = null
    })
    setOpenImagesTab(Date.now())
  }
}
