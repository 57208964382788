import { useDataChange } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let change = useDataChange({
    context: 'comms',
    viewPath: props.viewPath,
  })

  return () => {
    change(next => {
      next.auto_uploaded_attachments = []
      next.message = ''
      next.tags = []
    })
  }
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'comms',
    viewPath: props.viewPath,
  })

  return value => {
    change(next => {
      next.message_composes[value.thread_id] = value
    })
  }
}

export function useOnThreadIdsHackChange(props) {
  let change = useDataChange({
    context: 'comms',
    path: 'thread_ids_hack',
    viewPath: props.viewPath,
  })

  return function onThreadIdsHackChange(value) {
    change(value)
  }
}
