import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSaveEditorContent } from '../useSaveEditorContent.js'

export function useOnClick(props) {
  let url = useDataValue({
    context: 'document',
    path: 'url',
    viewPath: props.viewPath,
  })
  let editor = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let save = useSaveEditorContent(props)

  return async function onClick() {
    try {
      change(next => {
        next.saving = true
      })

      await save({
        url,
        content: editor.getContent(),
      })

      notify(notifySuccess(`Document saved.`))
    } catch (err) {
      return notify(
        notifyError(`Couldn't save the document. Please try again.`)
      )
    } finally {
      editor.setDirty(false)
      change(next => {
        next.isDirty = false
        next.saving = false
      })
    }
  }
}

export function disabled(isDirty, saving) {
  if (isDirty) return false
  if (saving) return true
  return true
}
