import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs._id',
  })
  return {
    variables: {
      treatment_id,
    },
    pause: !treatment_id,
  }
}
