import { capitalize } from 'Data/format.js'

/**
 *
 * @param {string} appointment_type_name
 * @returns
 */
export function format(appointment_type_name) {
  return appointment_type_name.split(' ').map(capitalize).join(' ')
}
