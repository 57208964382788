import { useMemo } from 'react'
import { PATIENT_IMAGE_LAYOUT_DEFINITIONS } from 'Data/constants.js'
import { getCellSize } from './helpers.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    let layout_definition = PATIENT_IMAGE_LAYOUT_DEFINITIONS[props.layout]
    if (!layout_definition) return data

    let { rows, columns } = getRowsAndColumns({ layout_definition })
    let gridRatio = rows / columns
    let height = getDefaultHeight({ layout: props.layout })
    let width = getDefaultWidth({ height, ratio: gridRatio })

    return {
      ...data,
      rows,
      columns,
      cell: getCellSize({ height, width, rows, columns }),
      images: getImages({ layout_definition, images: props.patientImages }),
      image_series_id: props.imageSeriesId,
    }
  }, [data, props.layout, props.patientImages, props.imageSeriesId])
}

function getDefaultHeight({ layout }) {
  switch (layout) {
    case 'x_ray_cephalometric':
    case 'x_ray_panoramic': {
      return 300
    }
    default: {
      return 650
    }
  }
}

function getDefaultWidth({ height, ratio }) {
  return Math.floor(height / ratio)
}

function getRowsAndColumns({ layout_definition }) {
  let rows = layout_definition
    .map(value => Math.min(...value.map(item => item.rows)))
    .reduce((sum, value) => sum + value, 0)
  let columns = Math.max(
    ...layout_definition.map(row =>
      row.reduce((sum, value) => sum + value.columns, 0)
    )
  )

  return { rows, columns }
}

function getImages({ layout_definition, images }) {
  return layout_definition.flat().map(layout_image => {
    let patient_image = images?.find(
      patient_image =>
        patient_image.type_key === layout_image.type_key &&
        patient_image.slot_key === layout_image.slot_key &&
        !patient_image.mime_type?.startsWith('video/')
    )

    return {
      id: layout_image.slot_key,
      definition: layout_image,
      patient_image,
    }
  })
}
