import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let account_id = useDataValue({
    context: 'account_settings',
    path: 'preferred_account.id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.account_id = account_id
      next.set_preferred = false
      next.step = 'doctor'
    })
  }
}
