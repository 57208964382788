import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })

  return {
    variables: { id },
    // pause: false,
  }
}
