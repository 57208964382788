import { useDataValue } from 'Simple/Data'
import { format, toDate } from 'date-fns-tz'
import { useMemo } from 'react'
import { groupBy, map, sortBy } from 'lodash'

let PM_MINUTE_THRESHOLD = 12 * 60

export default function useDataTransform(props, data) {
  let timeZoneId = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return sortBy(
      map(groupBy(data, 'date'), group => {
        let date = group[0].date
        let amSlotsCount = 0
        let pmSlotsCount = 0

        group.forEach(slot => {
          if (slot.start_min < PM_MINUTE_THRESHOLD) {
            amSlotsCount += 1
          } else {
            pmSlotsCount += 1
          }
        })

        return {
          id: date, // for list
          date,
          line1: format(
            toDate(date, { timeZone: timeZoneId }),
            'EEEE, dd MMMM yyyy'
          ),
          line2: `Open slots - ${amSlotsCount} in AM, ${pmSlotsCount} in PM`,
        }
      }),
      'date'
    )
  }, [data, timeZoneId])
}
