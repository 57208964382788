import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    if (!data.launchers.length) return null

    return data
  }, [data])
}
