import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return null
    let appointment = data[0]

    return { ...appointment, booking: appointment.booking?.[0] }
  }, [data])
}
