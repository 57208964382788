import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation, useClient } from 'Data/Api.js'
import debounce from 'lodash/debounce.js'
import throttle from 'lodash/throttle.js'
import View from './view.js'
import mutation from './mutation.graphql.js'
import query from './query_profile.graphql.js'

export default function Logic(props) {
  let ref = useRef()
  let client = useClient()
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let is_saving = useDataValue({
    context: 'image_card',
    path: 'is_saving',
    viewPath: props.viewPath,
  })
  let setSaving = useDataChange({
    context: 'image_card',
    path: 'is_saving',
    viewPath: props.viewPath,
  })
  let imageCard = useDataValue({
    context: 'image_card',
    viewPath: props.viewPath,
  })

  let [{ isHovering }, drop] = useDrop({
    accept: 'temporary-image',
    canDrop: throttle(() => !is_saving, 100),
    drop: debounce(onDrop),
    collect: monitor => ({
      isHovering: monitor.isOver({ shallow: true }),
    }),
  })

  drop(ref)

  return (
    <View
      innerRef={ref}
      isHovering={isHovering}
      zIndex={props.itemIndexReverse}
      zIndexHovering={props.imagesLength}
      {...props}
    />
  )

  async function onDrop(item) {
    try {
      setSaving(true)

      let mutationResponse = await executeMutation({
        temporary_image_id: item.id,
        image_series_id: imageCard.image_series_id,
        type_key: imageCard.image_definition.type_key,
        slot_key: imageCard.image_definition.slot_key,
        image_id: imageCard.patient_image?.id || null,
      })

      if (imageCard.image_definition.slot_key === 'facialFrontalSmiling') {
        try {
          await client
            .query(
              query,
              {
                patient_id,
              },
              {
                requestPolicy: 'network-only',
              }
            )
            .toPromise()
        } catch (e) {}
      }

      if (mutationResponse.error) {
        return notify(notifyError(`Couldn't save the image. Please try again.`))
      }

      notify(notifySuccess(`The image was saved successfully`))
    } finally {
      setSaving(false)
    }
  }
}
