import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    setFlowTo(
      normalizePath(
        props.viewPath,
        '../../../../../../Content/Images/Content/Images/Standard/Gallery/Content'
      )
    )
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
