import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let chairs = useDataValue({
    viewPath: props.viewPath,
    context: 'chairs',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'seat',
  })

  return function onChange(value) {
    let chair = chairs.find(chair => chair.id === value)
    change(next => {
      next.chair_id = value
      if (chair.default_provider_id) {
        next.provider_id = chair.default_provider_id
      }
      if (chair.default_assistant_id) {
        next.assistant_id = chair.default_assistant_id
      }
    })
  }
}
