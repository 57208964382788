// this is an autogenerated file from App/Account/WaitingRoom/Content/Content/Waiting/Appointments/Content/Appointment/Action/Seat/Confirmation/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_WaitingRoom_Content_Content_Waiting_Appointments_Content_Appointment_Action_Seat_Confirmation_Content(
  $id: uuid!
  $provider_id: numeric!
  $assistant_id: numeric!
  $chair_id: numeric!
) {
  vaxiom_appointment_booking_seat(
    id: $id
    provider_id: $provider_id
    assistant_id: $assistant_id
    chair_id: $chair_id
  ) {
    id
  }
}

`