import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })

  let tx_statuses = useDataValue({
    context: 'tx_statuses',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.tx_status = value

      let tx_status_names = Array.isArray(tx_statuses)
        ? tx_statuses
            .filter(status => value.includes(status.id))
            .map(status => status.text)
        : null

      if (tx_status_names.length) {
        next.tx_status_name = tx_status_names
      }
    })
  }
}

export function getTreatmentStatuses(treatment_statuses) {
  return treatment_statuses.map(status => ({
    id: status.type,
    text: status.name,
  }))
}

export function useOnSelectPreTriggerType(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.trigger_type = 'pre'
      next.actions = [{ type: null, data: null }]
    })
  }
}

export function useOnSelectPostTriggerType(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.trigger_type = 'post'
      next.actions = [{ type: null, data: null }]
    })
  }
}
