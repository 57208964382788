import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import debounce from 'lodash/debounce.js'
import throttle from 'lodash/throttle.js'
import View from './view.js'
import mutation from './mutation.graphql.js'

export default function Logic(props) {
  let ref = useRef()

  let is_saving = useDataValue({
    context: 'image_placeholder',
    path: 'is_saving',
    viewPath: props.viewPath,
  })
  let setSaving = useDataChange({
    context: 'image_placeholder',
    path: 'is_saving',
    viewPath: props.viewPath,
  })
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let [{ isHovering }, drop] = useDrop({
    accept: 'temporary-image',
    canDrop: throttle(() => !is_saving, 100),
    drop: debounce(onDrop),
    collect: monitor => ({
      isHovering: monitor.isOver({ shallow: true }),
    }),
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  drop(ref)

  return <View innerRef={ref} isHovering={isHovering} {...props} />

  async function onDrop(item) {
    try {
      setSaving(true)

      let mutationResponse = await executeMutation({
        temporary_image_id: item.id,
        image_series_id,
      })

      if (mutationResponse.error) {
        return notify(notifyError(`Couldn't save the image. Please try again.`))
      }

      notify(notifySuccess(`The image was saved successfully`))
    } finally {
      setSaving(false)
    }
  }
}
