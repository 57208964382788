// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/EditLocation/Content/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_EditLocation_Content_Location($parent_company_id: uuid!) {
  session_user_locations(
    where: { parent: { parent: { _id: { _eq: $parent_company_id } } } }
    order_by: { name: asc }
  ) {
    id
    _id
    text: name
  }
}

`