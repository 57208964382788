import React, { useRef } from 'react'

export default function FileUploadInput({
  accept,
  multiple = false,
  capture = false,
  disabled = false,
  onChange,
  children,
  viewPath,
}) {
  let ref = useRef()
  return (
    <div onClick={handleOnClick}>
      <input
        ref={ref}
        hidden
        type="file"
        capture={capture}
        accept={accept}
        multiple={multiple}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {typeof children === 'function' ? children({ viewPath }) : children}
    </div>
  )

  function handleOnClick() {
    ref.current?.click()
  }

  function handleOnChange(event) {
    if (!event.target?.files) return

    onChange && onChange(Array.from(event.target.files))
    event.target.value = ''
  }
}
