// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Procedures/Content/mutation-insert.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Procedures_Content($appointment_id: uuid!, $procedure_id: uuid!) {
  vaxiom_appointment_procedure_create(
    appointment_id: $appointment_id
    procedure_id: $procedure_id
  ) {
    procedure_id
    appointment_id
  }
}

`