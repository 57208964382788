import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.sidebar.is_floating = false
      next.sidebar.is_floating_and_visible = false
    })
  }
}

export function useOnMouseEnter(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onMouseEnter() {
    change(next => {
      next.sidebar.is_floating_and_visible = true
    })
  }
}

export function useOnMouseLeave(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  return function onMouseLeave() {
    change(next => {
      next.sidebar.is_floating_and_visible = false
    })
  }
}
