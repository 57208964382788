import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let tracking_url = useDataValue({
    context: 'order',
    path: 'tracking_url',
    viewPath: props.viewPath,
  })

  return function onClick() {
    window.open(tracking_url, '_blank')
  }
}
