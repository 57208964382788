// this is an autogenerated file from DesignSystem/CommsThread/Content/mutation_send_internal.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content(
  $thread_id: uuid!
  $message: String!
  $is_secure: Boolean!
  $status: comms_events_statuses_types_enum_action!
  $attachments: [comms_events_internal_and_status_attachment!]
  $tags: jsonb
) {
  comms_events_internal_send_and_events_status_create(
    thread_id: $thread_id
    is_secure: $is_secure
    message: $message
    status: $status
    attachments: $attachments
    tags: $tags
  ) {
    event_internal_id
  }
}

`