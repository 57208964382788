import React, { useCallback, useMemo } from 'react'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import { useDataValue } from 'Simple/Data.js'
import { isAppointmentScheduled } from 'Data/format.js'

import View from './view.js'
// TODO: find a better way to render the columns, preferrably directly from view.blocks files
import AppointmentCell from './AppointmentCell/index.js'
import NotesCell from './NotesCell/index.js'
import TreatmentNoteRow from './TreatmentNoteRow/index.js'

export default function Logic(props) {
  let events = useDataValue({
    context: 'events',
    viewPath: props.viewPath,
  })
  let past_appointments = useMemo(
    () =>
      events.filter(
        event =>
          (event.type === 'appointment' &&
            isAppointmentScheduled(event.appointment) &&
            isBefore(
              new Date(`${event.appointment.booking.start_time}Z`),
              startOfDay(new Date())
            )) ||
          (event.type === 'note' &&
            isBefore(new Date(`${event.created_at}Z`), startOfDay(new Date())))
      ),
    [events]
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'appointment_summary',
        headerName: 'Appointment',
        wrapText: true,
        autoHeight: true,
        initialWidth: 300,
        cellRenderer: params => (
          <AppointmentCell
            {...params}
            key={params.data.id}
            viewPath={`${props.viewPath}/AppointmentCell(${params.data.id})`}
          />
        ),
      },
      {
        field: 'notes',
        autoHeight: true,
        minWidth: 200,
        flex: 1,
        cellRenderer: params => (
          <NotesCell
            {...params}
            key={params.data.id}
            viewPath={`${props.viewPath}/NotesCell(${params.data.id})`}
          />
        ),
      },
    ],
    [props.viewPath]
  )
  let isFullWidthRow = useCallback(
    params => params.rowNode.data.type === 'note',
    []
  )
  let fullWidthCellRenderer = useCallback(
    params => (
      <TreatmentNoteRow
        {...params}
        key={params.data.id}
        viewPath={`${props.viewPath}/TreatmentNoteRow(${params.data.id})`}
      />
    ),
    [props.viewPath]
  )
  return (
    <View
      {...props}
      columnDefs={columnDefs}
      rowData={past_appointments}
      isFullWidthRow={isFullWidthRow}
      fullWidthCellRenderer={fullWidthCellRenderer}
    />
  )
}
