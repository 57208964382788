import { useEffect, useRef } from 'react'
import { useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import View from './view.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let medical_history = useDataValue({
    context: 'medical_history',
    viewPath: props.viewPath,
  })
  let vaxiom_medical_history_migrated_to_v2_at = useDataValue({
    context: 'vaxiom_medical_history',
    path: 'migrated_to_v2_at',
    viewPath: props.viewPath,
  })

  let initialRedirectDone = useRef(false)

  useEffect(() => {
    if (initialRedirectDone.current) return

    // If the patient doesn't have answers for MFv2 but has answers for MFv1,
    // MFv1 will be displayed until MFv2 data is generated
    if (
      medical_history.every(item =>
        item.questions.every(
          question =>
            question.answer === null ||
            question.answer === undefined ||
            question.answer.length === 0
        )
      ) &&
      !vaxiom_medical_history_migrated_to_v2_at
    ) {
      initialRedirectDone.current = true
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    }
  }, [medical_history, vaxiom_medical_history_migrated_to_v2_at]) // eslint-disable-line
  // ignore setFlowTo and props.viewPath

  return <View {...props} />
}
