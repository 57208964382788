import React, { useEffect, useState } from 'react'
import { useDataValue } from 'Simple/Data.js'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import max from 'date-fns/max'

import View from './view.js'

export default function Logic(props) {
  let check_in_time = useDataValue({
    context: 'appointment_booking',
    path: 'check_in_time',
    viewPath: props.viewPath,
  })
  let start_time = useDataValue({
    context: 'appointment_booking',
    path: 'start_time',
    viewPath: props.viewPath,
  })
  let [state, setState] = useState(getState)

  useEffect(() => {
    let interval = setInterval(() => setState(getState()), 30000) // recalculate waiting time every minute

    return () => {
      clearInterval(interval)
    }
  }, [check_in_time, start_time]) // eslint-disable-line
  // ignore getState

  return (
    <View
      {...props}
      waitingTime={state.waitingTime}
      isOver0Min={state.isOver0Min}
      isOver10Min={state.isOver10Min}
      isOver20Min={state.isOver20Min}
    />
  )

  function getState() {
    let waitingTime = getWaitingTime(check_in_time)
    // if patient is late then the reference is check in time instead of scheduled start time
    let reference = check_in_time
      ? max([new Date(`${start_time}Z`), new Date(`${check_in_time}Z`)])
      : new Date(`${start_time}Z`)
    let diff = differenceInMinutes(reference, new Date())
    return {
      waitingTime,
      isOver0Min: diff < 0,
      isOver10Min: diff <= -10,
      isOver20Min: diff <= -20,
    }
  }
}

function getWaitingTime(check_in_time) {
  let waitingTime = check_in_time
    ? differenceInMinutes(new Date(), new Date(`${check_in_time}Z`))
    : 0
  if (waitingTime <= 1) return '1 min.'
  if (waitingTime < 60) return `${waitingTime} min.`
  return '> 1 h'
}
