import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(({ text, appointment_type }) => ({
      id: appointment_type._id,
      text,
    }))
  }, [data])
}
