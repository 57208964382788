import { dateISOToeeeeMMMMdo, dateISOIn } from 'Data/format.js'
import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'
import formatDate from 'date-fns/format'

export default function useDataTransform(props, data) {
  let thread_id = useDataValue({
    viewPath: props.viewPath,
    context: 'thread',
    path: 'id',
  })

  return useMemo(() => {
    if (!data) return null
    if (data.length === 0) return data
    if (!data.some(item => item.thread_id === thread_id)) return null

    let result = data.flatMap((item, i, items) => {
      let toDate = dateISOToeeeeMMMMdo(item.created_at)
      return i === 0 || toDate !== dateISOToeeeeMMMMdo(items[i - 1].created_at)
        ? [
            {
              id: `${Date.now()}-${i}`,
              type: 'timeline',
              created_at: item.created_at,
              timeline_date:
                dateISOIn(item.created_at) ===
                formatDate(new Date(), 'MM/dd/yyyy')
                  ? 'Today'
                  : toDate,
            },
            item,
          ]
        : item
    })

    return reverseTimelineOrder(result)
  }, [data, thread_id])
}

function reverseTimelineOrder(data) {
  // Group items by timeline_date
  let groupedItems = data.reduce((groups, item) => {
    let toDate = dateISOToeeeeMMMMdo(item.created_at)
    let isToday =
      dateISOIn(item.created_at) === formatDate(new Date(), 'MM/dd/yyyy')
    let timelineDate = isToday ? 'Today' : toDate

    if (!groups[timelineDate]) {
      groups[timelineDate] = []
    }

    groups[timelineDate].push(item)
    return groups
  }, {})

  // Reverse the order of groups and sort items within each group in reverse time order
  let reversedAndSorted = Object.keys(groupedItems)
    .reverse()
    .reduce((result, timelineDate) => {
      let items = groupedItems[timelineDate]

      // Sort items in reverse time order so that timeline entries come first
      items.sort((a, b) => {
        let timeA = new Date(a.created_at).getTime()
        let timeB = new Date(b.created_at).getTime()
        if (a.type === 'timeline' && b.type !== 'timeline') {
          return -1
        } else if (a.type !== 'timeline' && b.type === 'timeline') {
          return 1
        } else {
          return timeA - timeB
        }
      })

      result.push(...items)
      return result
    }, [])

  return reversedAndSorted
}
