// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Details/Person/Profession/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Details_Person_Profession{
  vaxiom_resource_types(
    where: { dtype: { _eq: "employeeType" } }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`