import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnDelete(props) {
  let index = useDataValue({
    context: 'charge_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onDelete() {
    change(next => {
      next.charges.splice(index, 1)

      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
