// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/UnappliedPayments/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_UnappliedPayments($patient_id: uuid!) {
  vaxiom_unapplied_payments(
    where: {
      applied_payment: { payment: { patient: { _id: { _eq: $patient_id } } } }
    }
    order_by: { transaction: { sys_created: desc } }
  ) {
    id
    applied_payment {
      id
      payment_id
    }
    transaction {
      id
      dtype
      effective_date
      notes
      amount
      receivable_id
    }
  }
}

`