import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let layout = useDataValue({
    context: 'layout_option',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    path: 'selected.images.layout',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(layout)
  }
}
