// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Unscheduled/More/Content/ScheduleViaSmsOrEmail/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Unscheduled_More_Content_ScheduleViaSmsOrEmail_Content(
  $appointment_id: uuid!
  $phone: String
  $email: String
  $custom_instructions: String
) {
  appointments_scheduling_link_send(
    appointment_id: $appointment_id
    phone: $phone
    email: $email
    custom_instructions: $custom_instructions
  ) {
    ok
  }
}

`