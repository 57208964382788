import React from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useFlowValue, useSetFlowTo } from 'Simple/Flow.js'
import { useFloating, offset } from '@floating-ui/react-dom'

export default function Logic(props) {
  let flow = useFlowValue(props.viewPath)

  let setFlowTo = useSetFlowTo(props.viewPath)

  let { refs, x, y } = useFloating({
    open: flow === 'ContactMethods',
    middleware: [offset({ mainAxis: 10 })],
  })

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === refs.reference.current ||
      refs.reference.current?.contains(event.target)
    ) {
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View
      {...props}
      setOuterRef={refs.setReference}
      setInnerRef={refs.setFloating}
      left={x}
      top={y}
    />
  )
}
