import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { getImageSeriesAndImageName } from 'Data/format.js'

export default function useDataTransform(props, data) {
  let patient_image = useDataValue({
    context: 'patient_image',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      id: patient_image.id,
      name: getImageSeriesAndImageName(patient_image),
    }
  }, [data, patient_image])
}
