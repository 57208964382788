// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/query_patient_due_now.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content($patient_id: uuid!, $payment_account_id: numeric!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    where: { payment_account_id: { _eq: $payment_account_id } }
  ) {
    due_now
  }
}

`