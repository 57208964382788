// this is an autogenerated file from DesignSystem/ImageComparison/Content/Actions/Choose/ChooseImageAction/Content/Content/Images/Content/PatientImage/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageComparison_Content_Actions_Choose_ChooseImageAction_Content_Content_Images_Content_PatientImage($resource_id: numeric!) {
  vaxiom_resource_object_meta(resource_id: $resource_id, resource_type: image) {
    name
    content_type
    size
    url
    preview_url
  }
}

`