// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/MedicalTab/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_MedicalTab_Content($patient_id: uuid!) {
  medical_histories_questions(
    where: {
      status: { _eq: active }
      answers: { patient_id: { _eq: $patient_id } }
    }
  ) {
    id
    name
    alert_type
    alert_conditions
    category
    type
    prompt
    answers(
      where: { patient_id: { _eq: $patient_id } }
      order_by: { created_at: desc_nulls_last }
      limit: 1
    ) {
      id
      alert
      value
      created_at
      patient {
        id
        person {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
  }
}

`