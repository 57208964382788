import React, { useEffect } from 'react'
import { isCoreEmbedMessage } from 'Data/isCoreEmbedMessage.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataChange } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    window.addEventListener('message', listener, false)

    return () => {
      window.removeEventListener('message', listener)
    }

    async function listener(event) {
      if (!isCoreEmbedMessage(event)) return
      if (event.data.type !== 'payments_treatment_plan') return

      change(next => {
        next.selected.payments_treatment_plan = event.data.params
      })
      setFlowTo(normalizePath(props.viewPath, 'PaymentsTreatmentPlan/Content'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
