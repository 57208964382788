import { useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let requests = useDataValue({
    context: 'requests',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    let [request] = requests
    change(next => {
      if (
        !!request.patient_image_id &&
        next.selected.images.ceph_image_id !== request.patient_image_id
      ) {
        next.selected.images.ceph_image_id = request.patient_image_id
      }
    })

    setFlowTo(normalizePath(props.viewPath, '../../../../../../PerformTrace'))
  }
}
