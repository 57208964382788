import {
  useDataSubmit,
  useDataIsSubmitting,
  useDataValue,
} from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'payers',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    submit({
      type: 'installment',
    })
  }
}

export function useIsDisabled(props) {
  let paymentMethodValid = useDataValue({
    context: 'payment',
    path: 'valid',
    viewPath: props.viewPath,
  })

  let isSubmitting = useDataIsSubmitting({
    context: 'payers',
    viewPath: props.viewPath,
  })

  return isSubmitting || !paymentMethodValid
}
