// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Medical/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Medical($organization_id: uuid!, $patient_id: uuid!) {
  medical_histories_question_categories {
    id: value
    category: value
    questions(
      where: {
        organization_id: { _eq: $organization_id }
        status: { _eq: active }
      }
      order_by: { order: asc }
    ) {
      id
      name
      type
      alert_type
      alert_conditions
      options
      order
      answers(
        where: { patient_id: { _eq: $patient_id } }
        order_by: { created_at: desc_nulls_last }
        limit: 10
      ) {
        id
        value
        alert
        created_at
        user {
          id
          vaxiom_person {
            id
            first_name
            last_name
          }
        }
      }
    }
  }
}

`