import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(
    () => ({
      ...data,
      ...props.event,
    }),
    [data, props.event]
  )
}
