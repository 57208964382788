import useIsHovered from 'Simple/hooks/useIsHovered.js'
import useIsFocused from 'Simple/hooks/useIsFocused.js'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })
  let [focused, onFocusBind, onBlurBind] = useIsFocused({})

  return (
    <View
      {...props}
      focused={focused}
      hovered={hovered}
      onFocus={onFocusBind}
      onBlur={onBlurBind}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      onChange={onChange}
    />
  )

  function onChange(event) {
    event.stopPropagation()

    if (typeof props.onChange !== 'function') return

    props.onChange(!props.value)
  }
}
