import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let ruleset = useDataValue({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    // remove locations that are already in other rulesets
    return data.filter(
      location =>
        location._id === ruleset._id ||
        !rulesets.some(item => item._id === location._id)
    )
  }, [data, rulesets, ruleset])
}
