import useDataOnActionInApp from './useDataOnActionInApp.js'
import useDataOnActionSms from './useDataOnActionSms.js'
import useDataOnActionDiscussion from './useDataOnActionDiscussion.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionInApp = useDataOnActionInApp(props)
  let onActionSms = useDataOnActionSms(props)
  let onActionDiscussion = useDataOnActionDiscussion(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'inApp': {
        return await onActionInApp({ value, originalValue, args, change })
      }
      case 'sms': {
        return await onActionSms({ value, originalValue, args, change })
      }
      case 'discussion': {
        return await onActionDiscussion({ value, originalValue, args, change })
      }
      default: {
        throw new Error(`Unknown action type ${args.type}`)
      }
    }
  }
}
