import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let imageseries_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data || !imageseries_id) return data

    return {
      url: null,
      content_type: null,
      file_id: null,
      file_name: null,
      id: null,
    }
  }, [data, imageseries_id])
}
