import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    window.postMessage({
      fakeDevelopmentCoreEmbedMessage: true,
      type: 'payments_request_payment',
      params: {
        patient_id,
      },
    })
  }
}
