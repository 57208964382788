// this is an autogenerated file from DesignSystem/EditAutomation/TriggerType/CustomResourceInsert/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_TriggerType_CustomResourceInsert{
  apps_custom_resource_types {
    id
    custom_resource_types
  }
}

`