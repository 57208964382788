import { useMutation } from 'Data/Api.js'
import { useDataChange } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setRefreshEvents = useDataChange({
    context: 'refresh',
    path: 'events',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'close': {
        return props.onClose()
      }
      default: {
        if (!value.target_treatment_card_id) return true

        let mutationResponse = await executeMutation({
          appointment_ids: value.appointment_ids,
          note_ids: value.note_ids,
          source_treatment_card_id: value.source_treatment_card_id,
          target_treatment_card_id: value.target_treatment_card_id,
        })

        if (mutationResponse.error) {
          return notify(
            notifyError(
              `Cannot move appointments and notes to the selected treatment. Please try again.`
            )
          )
        }

        notify(
          notifySuccess(
            `Appointments and notes moves successfully to the selected treatment.`
          )
        )
        setRefreshEvents(Date.now())
        props.onClose()
      }
    }
  }
}
