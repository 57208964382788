import React, { useEffect } from 'react'
import { useDataChange } from 'Simple/Data.js'
import { isCoreEmbedMessage } from 'Data/isCoreEmbedMessage.js'
import View from './view.js'

export default function Logic(props) {
  let changeTabCoreParams = useDataChange({
    context: 'tab',
    path: 'coreParams',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    window.addEventListener('message', listener, false)

    return () => {
      window.removeEventListener('message', listener)
    }

    async function listener(event) {
      if (
        !isCoreEmbedMessage(event) ||
        event.data.type !== 'navigation' ||
        event.data.section !== 'tasks'
      ) {
        return
      }

      changeTabCoreParams(event.data.params)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
