import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let data = useDataValue({
    context: 'template_select',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  return function onChange(value) {
    let selected = value ? data.find(item => item.id === value) : null
    submit({
      type: 'updateTemplate',
      template_id: selected?.template_id || null,
      appointment_type_id: selected?.id ?? null,
    })
  }
}
