// this is an autogenerated file from App/Account/Content/Marketing/CampaignChooser/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Marketing_CampaignChooser_Content($location_ids: [uuid!]) {
  leads: leads_leads(
    where: { location_id: { _in: $location_ids }, analytics: {} }
  ) {
    id
    analytics(
      where: { campaign_id: { _is_null: false } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      campaign {
        id
        external_id
        name
      }
    }
  }
}

`