import { useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnBack(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'editor',
    path: 'note',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    change('')
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
