import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'payment',
    path: 'reusable',
  })
  return function onChange(value) {
    change(value)
  }
}
