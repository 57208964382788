import { useDataSubmit, useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSubmit = useOnActionSubmit(props)
  let onActionReset = useOnActionReset(props)
  let onActionUpdateTemplate = useOnActionUpdateTemplate(props)
  let onActionUpdateMonth = useOnActionUpdateMonth(props)
  let onActionUpdatePatientOption = useOnActionUpdatePatientOption(props)
  let onActionUnsetPatientOption = useOnActionUnsetPatient(props)
  let onActionNewAppointment = useOnActionNewAppointment(props)
  let onActionExistingAppointment = useOnActionExistingAppointment(props)
  let onActionToggleDockedSide = useOnActionToggleDockedSide(props)

  return async function onSubmit(params) {
    let { args } = params

    switch (args?.type) {
      case 'submit': {
        return onActionSubmit(params)
      }
      case 'reset': {
        return onActionReset(params)
      }
      case 'updateTemplate': {
        return onActionUpdateTemplate(params)
      }
      case 'updateMonth': {
        return onActionUpdateMonth(params)
      }
      case 'updatePatientOption': {
        return onActionUpdatePatientOption(params)
      }
      case 'unsetPatient': {
        return onActionUnsetPatientOption(params)
      }
      case 'newAppointment': {
        return onActionNewAppointment(params)
      }
      case 'existingAppointment': {
        return onActionExistingAppointment(params)
      }
      case 'toggleDockedSide': {
        return onActionToggleDockedSide(params)
      }
      default: {
        throw new Error(`Unsupported action ${args?.type}`)
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionToggleDockedSide(props) {
  return async function onAction({ value, args, originalValue, change }) {
    let { docked_to_left } = value

    change(next => {
      next.docked_to_left = !docked_to_left
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionExistingAppointment(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    let { patient_option, patient_id, appointment_id } = value

    if (appointment_id === args.id) return

    submitTab({
      type: 'scheduling/reset',
    })
    change({
      ...originalValue,
      patient_option,
      patient_id,
      appointment_id: args.id,
      appointment_type_id: args.type_id,
      ...(args.note
        ? {
            notes: {
              enabled: true,
              value: args.note,
            },
          }
        : {}),
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionNewAppointment(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    let { patient_option, patient_id } = value
    submitTab({
      type: 'scheduling/reset',
    })
    change({
      ...originalValue,
      patient_option,
      patient_id,
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionUnsetPatient(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    change({
      ...originalValue,
      patient_option: 'existing',
    })
    submitTab({
      type: 'scheduling/reset',
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionUpdatePatientOption(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    change({
      ...originalValue,
      patient_option: args.patient_option,
    })
    submitTab({
      type: 'scheduling/reset',
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionUpdateMonth(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let changeTab = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return async function onAction({ value, args, originalValue, change }) {
    changeTab(next => {
      next.selected.date = args.date
    })
    submitTab({
      type: 'scheduling/reset',
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionUpdateTemplate(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    change(next => {
      next.template_id = args.template_id
      next.appointment_type_id = args.appointment_type_id
    })
    submitTab({
      type: 'scheduling/reset',
    })
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionSubmit(props) {
  return async function onAction({ value, args, originalValue, change }) {
    //
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnActionReset(props) {
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, originalValue, change }) {
    let { patient_option, patient_id } = value
    change({
      ...originalValue,
      ...(args.preservePatientId ? { patient_option, patient_id } : {}),
    })
    submitTab({
      type: 'scheduling/reset',
    })
  }
}
