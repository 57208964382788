import Copy from 'design-system/Copy/index.js'

let tableData = {
  columns: [
    {
      Header: 'Method',
      accessor: 'payment_method',
    },
    {
      Header: 'Test data',
      accessor: 'test_data',
    },
    {
      Header: 'CVV',
      accessor: 'cvv',
    },
  ],
  data: [
    { payment_method: 'Visa', test_data: '4012000098765439', cvv: '999' },
    { payment_method: 'MasterCard', test_data: '5146315000000055', cvv: '998' },
    { payment_method: 'Discover', test_data: '6011000993026909', cvv: '996' },
    {
      payment_method: 'AMEX',
      test_data: '374101000000608',
      cvv: '9997',
    },
    { payment_method: 'ACH account', test_data: '123123123', cvv: 'N/A' },
    {
      payment_method: 'ACH routing',
      test_data: 'Any eg 102103407',
      cvv: 'N/A',
    },
  ],
}

export function getTableData() {
  return tableData
}
