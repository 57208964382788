// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/ChangeAppointmentType/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More_ChangeAppointmentType_Content(
  $appointment_id: numeric!
  $appointment_type_id: numeric!
) {
  update_vaxiom_appointments_by_pk(
    pk_columns: { id: $appointment_id }
    _set: { type_id: $appointment_type_id }
  ) {
    id
    type {
      id
      full_name
      display_name
      display_color_id
      color_id
      is_treatment_starter
      is_virtual
    }
  }
  insert_vaxiom_audit_logs_one(
    object: {
      class_type: "com.axpm.treatments.core.internal.model.AppointmentAuditLog"
      appointment_audit_log: {
        data: {
          action: "AppointmentTypeChanged"
          appointment_id: $appointment_id
        }
      }
    }
  ) {
    id
  }
}

`