import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.discounts.push({
        id: crypto.randomUUID(),
        type_id: null,
        name: '',
        type: 'amount',
        amount: 0,
        percentage: 0,
        is_included_in_insurance_claim: false,
        is_applied_to_payor: false,
        payor_person_id: null,
      })
    })
  }
}
