import { dateISOIn } from 'Data/format.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let tooth_chart_snapshots = useDataValue({
    context: 'tooth_chart_snapshots',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'tab',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    path: 'tooth_chart_snapshot_id',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    // prevent updating selected tooth chart if tooth charts displayed for the previous patient
    if (
      !tooth_chart_snapshots.length ||
      patient_id !== tooth_chart_snapshots[0].appointment.patient._id
    ) {
      return
    }

    change(value)
  }
}

export function asList(list) {
  return list.map(item => ({
    id: item._id,
    text: `${dateISOIn(item.appointment.booking[0].local_start_date)} - ${
      item.appointment.type.display_name
    }`,
  }))
}
