// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/PdfFile/Actions/Signature/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content_Content_PdfFile_Actions_Signature($id: numeric!) {
  documents_signature_requests(
    where: {
      status: { _eq: signed }
      resource_metadata: {
        _contains: { id: $id, table: "document_tree_nodes", schema: "vaxiom" }
      }
    }
  ) {
    id
    signed_at
    signatory_person {
      id
      first_name
      last_name
    }
  }
}

`