import { TREATMENT_AUTODRAFT_METHODS } from 'Data/constants'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'payment_methods',
    viewPath: props.viewPath,
  })
  let rulesetPaymentMethods = useDataValue({
    context: 'ruleset',
    path: 'payment_methods',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change(
      value
        ? TREATMENT_AUTODRAFT_METHODS.filter(
            paymentMethod =>
              rulesetPaymentMethods.includes(paymentMethod) ||
              paymentMethod === 'credit_card'
          )
        : TREATMENT_AUTODRAFT_METHODS.filter(
            paymentMethod =>
              rulesetPaymentMethods.includes(paymentMethod) &&
              paymentMethod !== 'credit_card'
          )
    )
  }
}
