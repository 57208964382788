import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      max_discount: data.max_discount || 0,
      custom_payment_plan_ruleset: data.settings?.custom_payment_plan_ruleset ||
        data.tx_plan.tx_plan_template.tx_plan_group
          .custom_payment_plan_ruleset || {
          min_downpayment: 0,
          min_downpayment_type: 'amount',
          max_length: 0,
          max_length_type: 'total',
        },
    }
  }, [data])
}
