// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/ChangeChair/Content/Chair/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions_ChangeChair_Content_Chair($current_location_id: uuid!) {
  vaxiom_chairs(
    where: {
      resource: {
        organization: { _id: { _eq: $current_location_id } }
        deleted: { _eq: false }
      }
    }
    order_by: { pos: asc }
  ) {
    id
    _id
    text: full_name
  }
}

`