import { useClient } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data'
import { notifyError, useNotifications } from 'Logic/Notifications'
import query_print_image_series from './query_print_image_series.graphql.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let layout = useDataValue({
    context: 'tab',
    path: 'selected.images.layout',
    viewPath: props.viewPath,
  })

  let client = useClient()
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value }) {
    if (!image_series_id || !layout) return

    try {
      setFlowTo(normalizePath(props.viewPath, 'Loading/Content'))

      let response = await client
        .query(query_print_image_series, {
          image_series_id,
          layout,
        })
        .toPromise()
      if (response.error) {
        return notify(notifyError(`Couldn't print the selected image`))
      }
      window.open(
        response.data.vaxiom_patient_images_print_preview.url,
        '_blank'
      )
    } finally {
      setFlowTo(normalizePath(props.viewPath, 'Loading/No'))
      setFlowTo(normalizePath(props.viewPath, '../../../No'))
    }
  }
}
