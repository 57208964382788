import { useMemo } from 'react'

import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.create_treatment_plan.treatment_id',
  })
  return useMemo(() => {
    return {
      patient_id,
      treatment_id,
      tab: 'treatment_planning',
    }
  }, [patient_id, treatment_id])
}
