// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/Delete/Content/Main/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_Delete_Content_Main($treatment_id: uuid!) {
  vaxiom_appointments_aggregate(
    where: { tx: { _id: { _eq: $treatment_id } } }
  ) {
    aggregate {
      count
    }
  }
  vaxiom_txs(where: { _id: { _eq: $treatment_id } }) {
    id
    _id
    is_contract_applied
  }
}

`