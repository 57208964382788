// this is an autogenerated file from DesignSystem/PatientOverview/Content/HubAccess/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_HubAccess($person_id: uuid!, $patient_id: numeric!) {
  persons: vaxiom_persons(where: { _id: { _eq: $person_id } }) {
    id
    public_user {
      id
      hub_access_status
    }
  }
  emails: patient_contact_method_associations(
    args: { _patient_id: $patient_id, _type: "email" }
    order_by: { preference: asc_nulls_last }
  ) {
    id
    preference
    email {
      id
      address
    }
  }
  phones: patient_contact_method_associations(
    args: { _patient_id: $patient_id, _type: "phone" }
  ) {
    id
  }
}

`