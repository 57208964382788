import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let treatment_id = useDataValue({
    context: 'treatment_card',
    path: 'txs._id',
    viewPath: props.viewPath,
  })
  let previous_appointment_id = useDataValue({
    context: 'event',
    path: 'appointment._id',
    viewPath: props.viewPath,
  })

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (!value.appointment_template_id) return true

    let mutationResponse = await executeMutation({
      treatment_id,
      appointment_template_id: value.appointment_template_id,
      location_id,
      previous_appointment_id,
      interval_to_next: value.interval_to_next,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem adding the appointment. Please try again.`
        )
      )
      return
    }
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
