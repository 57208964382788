import { useDataChange } from 'Simple/Data.js'

let SIGNATURE_REGEX = /{.+[-_]signature([-_]required)?}/i

export function useOnChange(props) {
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })

  return function onChange(content) {
    change(next => {
      if (next.saving) return

      let hasSignature = SIGNATURE_REGEX.test(content)
      if (next.hasSignature === hasSignature) return

      next.hasSignature = hasSignature
    })
  }
}
