import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .filter(item => !!item.person)
      .map(item => {
        let phones = item.person.phones
          .map(item => {
            let number = item.contact_method.phone?.number

            if (!number) return false

            return {
              id: item.id,
              number,
              description: item.description,
            }
          })
          .filter(Boolean)

        let is_patient = !!item.person.patient
        let is_payer = item.person.from_person_relationships?.length > 0
        let is_employee = item.person.employment_contracts?.length > 0

        let external_professional =
          item.person.other_professional_relationships?.[0]
        let is_external_professional = Boolean(external_professional)
        let external_professional_id = external_professional?.id
        let external_professional_info = [
          external_professional?.employee_type?.name,
          external_professional?.external_office?.name,
          external_professional?.external_office?.postal_address?.state,
        ]
          .filter(Boolean)
          .join(', ')

        return {
          ...item.person,
          is_patient,
          is_payer,
          is_employee,
          is_external_professional,
          phones,
          external_professional_id,
          external_professional_info: external_professional_info || null,
        }
      })
      .filter(Boolean)
  }, [data]) // eslint-disable-line
  // ignore transform
}
