// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-mark-as-no-show.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($id: uuid!) {
  vaxiom_appointment_booking_no_show(id: $id) {
    id
  }
}

`