import React, { useState } from 'react'

import View from './view.js'

export default function Logic(props) {
  let [isOpen, setOpen] = useState(true)
  // use passed props to determine the state if the component is controlled from parent
  let isControlled = typeof props.isOpen === 'boolean'

  return (
    <View
      {...props}
      isOpen={isControlled ? props.isOpen : isOpen}
      onToggle={isControlled ? props.onToggle : onToggle}
    />
  )

  function onToggle() {
    setOpen(previous => !previous)
  }
}
