// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/App/Connection/Status/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps_App_Connection_Status(
  $parent_company_id: uuid!
  $location_id: uuid!
  $app_id: uuid!
  $connection_name: String!
) {
  apps_connection_resources(
    where: {
      # depending on the app's configuration, either parent company level or location level connections will be created
      _or: [
        { resource_id: { _eq: $parent_company_id }, type: { _eq: company } }
        { resource_id: { _eq: $location_id }, type: { _eq: location } }
      ]
      connection: { app_id: { _eq: $app_id }, name: { _eq: $connection_name } }
    }
  ) {
    id
    status
    connection_id
    resource_id
    type
    xid
    connection {
      id
      name
      status
      app_id
    }
  }
}

`