import React from 'react'

export default function Video({ viewPath: _, innerRef, src, ...props }) {
  let {
    objectFit = 'contain',
    width = 'auto',
    height = 'auto',
    maxHeight = 'auto',
    maxWidth = 'auto',
    minHeight = 'auto',
    minWidth = 'auto',
    flexGrow,
    flexShrink,
    flexBasis,
    ...restProps
  } = props

  return (
    <video
      {...restProps}
      ref={innerRef}
      src={!Array.isArray(src) ? src : null}
      style={{
        objectFit,
        width,
        height,
        maxWidth,
        maxHeight,
        minHeight,
        minWidth,
        flexGrow,
        flexShrink,
        flexBasis,
      }}
    >
      {Array.isArray(src)
        ? src.map((source, i) => (
            <source key={i} src={source.file} type={source.type} />
          ))
        : null}
    </video>
  )
}
