import { useDataChange, useDataValue, useDataSubmit } from 'Simple/Data.js'

import { PAYOR_FILTERS } from '../constants.js'

export function filterPaymentAccounts(accounts, payorFilter) {
  switch (payorFilter) {
    case PAYOR_FILTERS.EXISTING:
      return accounts.filter(i => i.data.is_new === false)
    case PAYOR_FILTERS.NEW:
      return accounts.filter(i => i.data.is_new === true)
    default:
      return accounts
  }
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })
  let payment_accounts = useDataValue({
    context: 'payment_accounts',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'payments_post_payment',
  })

  return function onChange(next_id) {
    let payment_account = payment_accounts?.find(i => i.id === next_id)
    if (!payment_account) return

    change(next => {
      next.payment_account.selected_id = next_id
      next.payment_account.data = payment_account.data
    })

    // trigger query for due amount on payment account
    submit({
      type: 'due_amount_submit',
      payment_account_id: payment_account.data.payment_account_id,
    })
  }
}
