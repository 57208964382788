import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let parent_company_id = useDataValue({
    context: 'images_request',
    path: 'parent_company_uuid',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'images_request',
    path: 'location_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { parent_company_id, location_id },
  }
}
