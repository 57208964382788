import useDataOnActionSubmit from './useDataOnActionSubmit.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSubmit = useDataOnActionSubmit(props)

  return async function onSubmit({ value, args, originalValue, change }) {
    switch (args?.type) {
      case 'submit': {
        return onActionSubmit({ value, args, originalValue, change })
      }
      case 'reset': {
        return props.onClose()
      }
      default: {
        throw new Error(`Unsupported action ${args?.type}`)
      }
    }
  }
}
