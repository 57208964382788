import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_actions',
    path: 'appointment_booking.location.id',
  })

  return {
    variables: {
      current_location_id,
    },
    pause: !current_location_id,
  }
}
