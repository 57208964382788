// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Comms/queryDiscussion.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Comms($resources: jsonb) {
  comms_threads(
    where: { resources: { _contains: $resources }, type: { _eq: internal } }
  ) {
    id
  }
}

`