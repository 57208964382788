// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps_Content($type: String!, $resource_id: uuid!) {
  apps_launchers(type: $type, resource_id: $resource_id) {
    launchers
  }
}

`