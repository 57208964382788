// this is an autogenerated file from DesignSystem/MoveToAnotherTreatment/TargetTreatment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_MoveToAnotherTreatment_TargetTreatment($patient_id: uuid!, $treatment_card_id: uuid!) {
  vaxiom_tx_cards(
    where: {
      _id: { _neq: $treatment_card_id }
      patient: { _id: { _eq: $patient_id } }
      deleted: { _eq: false }
    }
    order_by: { latest_appointment_booking_start_time: desc_nulls_last }
  ) {
    id
    _id
    name
  }
}

`