import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })
  let contact_methods = useDataValue({
    viewPath: props.viewPath,
    context: 'contact_methods',
  })

  return function onChange(id) {
    let found = contact_methods.find(i => i.id === id)

    if (found) {
      change(next => {
        next.contact_method = {
          id: found.id,
          type: found.type,
          value: found.value,
        }
      })
    }
  }
}
