import { useDataSubmit } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { addPrefixIfMissing } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import View from './view.js'
import React, { useEffect, useRef } from 'react'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let cancel = useRef(false)
  useEffect(() => {
    return () => {
      cancel.current = true
    }
  }, []) // eslint-disable-line

  return <View onClick={onClick} viewPath={props.viewPath} />

  async function onClick() {
    let mutationResponse = await executeMutation({
      number: addPrefixIfMissing(props.item.number),
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem starting this thread.`))
    }

    submit({
      type: 'tabs/add',
      tab: {
        id: 'Comms',
        name: 'Comms',
        type: 'Comms',
        viewPath: '/App/Account/Content/Comms',
        sync: {
          threadId: mutationResponse.data.thread.id,
        },
      },
    })

    setFlowTo(normalizePath(props.viewPath, '../../../../../../No'))
  }
}
