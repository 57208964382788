// this is an autogenerated file from App/Account/Content/Workflows/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Workflows{
  vaxiom_sys_organizations(
    where: { level: { _eq: "parent" }, deleted: { _eq: false } }
    order_by: { name: asc }
  ) {
    id: _id
    name
  }
}

`