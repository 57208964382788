export function getView({ data, error, fetching }) {
  if (fetching) return 'Loading'

  if (error) {
    return 'Error'
  }

  if (data) {
    return 'Response'
  }

  return 'No'
}
