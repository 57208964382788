import React, { useEffect } from 'react'

import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let treatment_card_id = useDataValue({
    context: 'tab',
    path: 'treatment_card_id',
    viewPath: props.viewPath,
  })
  let tx_plan_notes = useDataValue({
    context: 'tx_plan_notes',
    viewPath: props.viewPath,
  })
  let tx_plan_note = useDataValue({
    context: 'tx_plan_note',
    viewPath: props.viewPath,
  })
  let setTxPlanNote = useDataChange({
    context: 'tx_plan_note',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (
      tx_plan_note.treatment_card_id === treatment_card_id ||
      tx_plan_notes.some(item => item.tx_card._id !== treatment_card_id)
    ) {
      return
    }
    // reset once the selected treatment card changed
    setTxPlanNote(
      tx_plan_notes?.length
        ? {
            ...tx_plan_notes[0],
            treatment_card_id: tx_plan_notes[0].tx_card._id,
          }
        : { id: null, note: '', treatment_card_id }
    )
  }, [treatment_card_id, tx_plan_note, tx_plan_notes])

  return <View {...props} />
}
