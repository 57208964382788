// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/Receivables/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_Receivables($patient_id: uuid!) {
  vaxiom_receivables(
    where: { patient: { _id: { _eq: $patient_id } } }
    order_by: { sys_created_at: desc }
  ) {
    id
    rtype
    human_readable_id
    due_amount
    balance_amount
    due_date
    realization_date
    autopay_date
    status
  }
}

`