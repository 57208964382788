import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let location_id = useDataValue({
    context: 'selected',
    path: 'location_id',
    viewPath: props.viewPath,
  })
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let changeSelection = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === location_id) return

    let location = Array.isArray(locations)
      ? locations.find(location => location._id === value)
      : null

    changeSelection(next => {
      next.location_id = location?._id
      next.category = location ? 'all' : null
      next.question_id = null
    })
  }
}
