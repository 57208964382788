import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointments',
    path: 'tx_uuid',
  })

  return async function onChange(value) {
    let active = value?.find(i => i.status === 'ACTIVE')

    if (active) {
      change(active._id)
      return
    }

    let first = value?.[0]
    if (first) change(first._id)
  }
}
