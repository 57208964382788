import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let tx_id = useDataValue({
    context: 'treatment_card',
    path: 'txs.id',
    viewPath: props.viewPath,
  })
  let old_status = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.status',
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    if (old_status === value.status) return true

    let mutationResponse = await executeMutation({
      tx_id,
      status: value.status,
      old_status,
    })

    if (mutationResponse.error) {
      return notify(
        notifyError(`Cannot update treatment's status. Please try again.`)
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
