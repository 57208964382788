import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'tx_plan_groups',
    viewPath: props.viewPath,
  })

  let tx_plan_groups = useDataValue({
    context: 'tx_plan_groups',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    change(next.map(item => tx_plan_groups.find(({ _id }) => _id === item)))
  }
}
