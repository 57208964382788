import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      let discount = next.discounts[index]
      discount.is_included_in_insurance_claim = value

      if (value) {
        discount.is_applied_to_payor = false
        discount.payor_person_id = null
      }

      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
