import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataChange, useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let patient_uuid = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let value = useDataValue({
    context: 'global',
    path: 'acknowledged_notifications_by_patient',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'global',
    path: 'acknowledged_notifications_by_patient',
    viewPath: props.viewPath,
  })
  let submitGlobal = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onClick() {
    if (!value.includes(patient_uuid)) {
      let acknowledge_array = [...value, patient_uuid]
      change(acknowledge_array)
    }

    setFlowTo(normalizePath(props.viewPath, '../../../No'))
    submitGlobal({ type: 'patientNotifications/close' })
  }
}
