/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return function onSubmit({ value }) {
    //eslint-disable-next-line
    if (!!document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      let elem = value.container_id
        ? document.getElementById(value.container_id)
        : document.documentElement

      elem.requestFullscreen()
    }
  }
}
