import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'patient_new.provider_id',
    viewPath: props.viewPath,
  })

  return async function onChange(next) {
    if (next?.length !== 1) return
    change(next[0].id)
  }
}
