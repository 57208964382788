import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let launchers = useDataValue({
    viewPath: props.viewPath,
    context: 'app',
    path: 'launchers',
  })

  return useMemo(() => {
    let action = launchers?.find(
      ({ id, connected }) => id === 'align-ids-patient' && connected
    )

    if (!action) return data

    if (!action.active) {
      return {
        id: action.id,
        action: 'create',
        app_id: action.app_id,
        icon: action.icon,
        name: 'Create patient in Align',
      }
    } else {
      return {
        id: action.id,
        action: 'forget',
        app_id: action.app_id,
        icon: action.icon,
        name: 'Forget this patient in Align',
      }
    }
  }, [data, launchers])
}
