import { textInputOrNumber, validateCurrency } from 'Data/validate.js'

export function validate(value) {
  let errors = []

  let {
    person_payment_account,
    amount,
    tx_uuid,
    selected_phone,
    selected_email,
  } = value

  if (!textInputOrNumber(person_payment_account.selected_id)) {
    errors.push('Please select payor account')
  }

  if (!validateCurrency(amount)) {
    errors.push('Please provide amount larger than 0')
  }

  if (!textInputOrNumber(tx_uuid)) {
    errors.push('Please select treatment')
  }

  if (selected_email === null && selected_phone === null) {
    errors.push('Please select one contact method to send the request to')
  }

  return {
    isInvalid: errors.length > 0,
    errors,
  }
}
