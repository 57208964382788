import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let search_term = useStableValue(
    useDataValue({
      viewPath: props.viewPath,
      context: 'search',
      path: 'term',
    })
  )

  let organizations = useDataValue({
    viewPath: props.viewPath,
    context: 'organizations',
  })

  return {
    variables: {
      search_term: `%${search_term}%`,
      organizations,
    },
    pause: search_term.length < 3,
    requestPolicy: 'cache-and-network',
  }
}
