// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/SyncPatients/Content/SyncPatients/Content/PatientNotFoundCard/ReplacePatient/ReplaceDialog/SearchResult/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_SyncPatients_Content_SyncPatients_Content_PatientNotFoundCard_ReplacePatient_ReplaceDialog_SearchResult($search_term: String!, $parent_company_id: uuid!) {
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: false
  ) {
    person: vaxiom_person {
      id
      first_name
      middle_name
      last_name
      gender
      birth_date
      profile_pic_url
      patient {
        id
        _id
      }
      contact_method_associations {
        id
        email {
          id
          address
        }
        phone {
          id
          number
        }
        postal_address {
          id
          zip
        }
      }
    }
  }
}

`