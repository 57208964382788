import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payments_request_payment',
    viewPath: props.viewPath,
    path: 'selected_phone',
  })

  return function onChange(next_id) {
    change(next_id)
  }
}

export function isDisabled(isSubmitting, phones) {
  return isSubmitting || phones.length === 0
}
