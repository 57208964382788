import { useDataChange, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'selected',
    path: 'status',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('skipped')
    // run on the next cycle so the first change takes effect
    setTimeout(submit, 100)
  }
}
