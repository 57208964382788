import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let params = useDataValue({
    context: 'message',
    path: 'params',
    viewPath: props.viewPath,
  })

  return {
    requestPolicy: 'network-only',
    variables: {
      patient_id: params.patient_id,
      patient_uuid: params.patient_uuid,
      location_id: location.id,
      receivable_id: params.receivable_id || 0,
      include_receivable: typeof params.receivable_id === 'number',
      payment_account_id: params.payer_account_id || 0,
      include_patient_due_amount:
        typeof params.receivable_id !== 'number' &&
        typeof params.payer_account_id === 'number',
    },
  }
}
