import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      ...data,
      images: props.images,
      image_series: props.images?.[0]?.imageseries,
      image_id: props.imageId,
    }
  }, [data, props.images, props.imageId])
}
