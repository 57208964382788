import { useMemo } from 'react'
import { useGetSignedUrl } from 'Data/s3'

export default function useDataTransform(props, data) {
  let {
    url = '',
    file_id = '',
    content_type = '',
    file_name = '',
  } = props.resource
  let previewUrl = useGetSignedUrl({
    url,
    filename: file_id,
    content_type,
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data || (!previewUrl && !file_id && !content_type)) return data

    return {
      ...data,
      id: file_id,
      preview_url: previewUrl,
      content_type: content_type?.toString(),
      file_name: file_name,
      captures: [],
    }
  }, [data, previewUrl, file_id, content_type, file_name])
}
