// this is an autogenerated file from App/Account/Search/Content/Profiles/Content/Profile/Phones/Phone/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Search_Content_Profiles_Content_Profile_Phones_Phone($number: PhoneNumber!) {
  thread: comms_threads_sms_create(number: $number) {
    id
  }
}

`