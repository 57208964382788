// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/PaymentPlans/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_PaymentPlans($patient_id: uuid!) {
  vaxiom_payment_plans(
    where: {
      transaction: { receivable: { patient: { _id: { _eq: $patient_id } } } }
    }
    order_by: { transaction: { sys_created: desc } }
  ) {
    id
    autopay_source
    token_id
  }
}

`