// this is an autogenerated file from App/ChooseLocation/ParentCompanies/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_ChooseLocation_ParentCompanies{
  app_admin_users_access(
    where: { organization_level: { _eq: "parent" } }
    order_by: { organization_name: asc }
  ) {
    id
    xid
    text: organization_name
  }
}

`