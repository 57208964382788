import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useQuery } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import isToday from 'date-fns/isToday'

import query from './query.graphql.js'

export function useOnClick(props) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [{ data, error }] = useQuery({
    query,
    variables: {
      image_series_id,
    },
    requestPolicy: 'network-only',
  })

  return function onClick() {
    if (error) {
      notify(notifyError(`Cannot import images. Please try again.`))
      return
    }
    let image_series = data.vaxiom_patient_imageseries_by_pk
    if (
      !image_series.series_date ||
      !isToday(new Date(image_series.series_date)) ||
      image_series.patient_images.length
    ) {
      notify(
        notifyError(
          `Your image series must be empty and dated today to import from Align.`
        )
      )
      return
    }

    setFlowTo(
      normalizePath(
        props.viewPath,
        '../../../../ImportFromInvisalignPracticeApp/Content'
      )
    )
  }
}
