import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let appointment = useDataValue({
    context: 'appointment',
    viewPath: props.viewPath,
  })

  return () => {
    submit({
      type: 'existingAppointment',
      id: appointment.id,
      type_id: appointment.type.id,
      note: appointment.note,
    })
    props.closePopover?.()
  }
}
