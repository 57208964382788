// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/mutation_person_payment_account.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content(
  $payer_person_id: numeric!
  $parent_organization_id: numeric!
) {
  insert_vaxiom_person_payment_accounts_one(
    object: {
      payer_person_id: $payer_person_id
      organization_id: $parent_organization_id
      payment_account: {
        data: { account_type: PersonPaymentAccount, temp: false }
      }
    }
  ) {
    id
    payment_account {
      id
    }
  }
}

`