import { DataProvider, useDataValue } from 'Simple/Data'
import tokens from 'Simple/DesignTokens.json'
import makeDebug from 'Simple/debug.js'
import get from 'lodash/get'
import React from 'react'

let debug = makeDebug('simple/design-tokens')

let defaultTheme = Object.keys(tokens)[0]

export function DesignTokens({ theme = defaultTheme, children, viewPath }) {
  return (
    <DataProvider
      context="design_tokens_theme"
      value={theme}
      viewPath={viewPath}
    >
      {children}
    </DataProvider>
  )
}

export function useDesignTokenValue({ path, viewPath }) {
  let theme = useDataValue({
    context: 'design_tokens_theme',
    viewPath,
  })
  let token = get(tokens, `${theme}.${path}`, null)
  if (token === null) {
    debug({
      type: 'missing-design-token',
      viewPath,
      message: `You're missing the design token "${path}" for the theme "${theme}". Please add it to your project's design-tokens.json file.`,
    })
  }
  return token
}
