import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getNumberOfInstallmentsPerMonth,
  getPayorShareAfterDiscounts,
  LOCK,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      if (value === payor.installment_amount) return

      payor.installment_amount = value

      let base = getPayorShareAfterDiscounts(payor, next)

      if (payor.payment_plan_length === 0) {
        payor.payment_plan_length = 1
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let monthly_installment_amount =
          value * number_of_installments_per_month
        payor.downpayment_amount = base - monthly_installment_amount
        payor.lock = null
      } else if (value === 0) {
        // pay in full
        payor.downpayment_amount = base
        payor.payment_plan_length = 0
        payor.lock = null
      } else if (!payor.lock || payor.lock === LOCK.DOWNPAYMENT_AMOUNT) {
        let total_installments_amount = base - payor.downpayment_amount
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let monthly_installment_amount =
          value * number_of_installments_per_month
        payor.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
        let maximum_installment_amount =
          total_installments_amount / number_of_installments_per_month
        payor.installment_amount = Math.min(
          parseFloat(maximum_installment_amount.toFixed(2)),
          value
        )
      } else if (payor.lock === LOCK.PAYMENT_PLAN_LENGTH) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let total_installments_amount =
          value * payor.payment_plan_length * number_of_installments_per_month
        payor.downpayment_amount = base - total_installments_amount
      }
    })
  }
}
