import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_id,
    },
  }
}
