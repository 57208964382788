// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/linkOfficeMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content($id: numeric!, $office_id: numeric) {
  update_vaxiom_other_professional_relationships_by_pk(
    pk_columns: { id: $id }
    _set: { external_office_id: $office_id }
  ) {
    id
  }
}

`