import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let id = useDataValue({
    context: 'signature',
    path: 'id',
    viewPath: props.viewPath,
  })
  let editor = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let changeDisabled = useDataChange({
    context: 'editor',
    path: 'disabled',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    let mutationResponse = await executeMutation({ id })

    if (mutationResponse.error) {
      notify(notifyError(`Couldn't cancel signature request`))
    } else {
      notify(notifySuccess(`Cancelled signature request`))
    }

    editor.setEditableRoot(true)
    changeDisabled(false)
  }
}
