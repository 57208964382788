// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/NewImageSeriesAction/Content/Appointment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_NewImageSeriesAction_Content_Appointment($treatment_id: numeric!) {
  vaxiom_appointments(
    where: {
      tx_id: { _eq: $treatment_id }
      appointment_bookings: {
        local_start_date: { _lte: "NOW()" }
        state: { _nin: ["NO_SHOW", "PATIENT_CANCELLED", "OFFICE_CANCELLED"] }
      }
      _not: { patient_imageseries: { deleted: { _eq: false } } }
    }
    order_by: {
      appointment_bookings_aggregate: {
        max: { local_start_date: desc_nulls_last }
      }
    }
    limit: 10
  ) {
    id
    type {
      id
      full_name
      display_name
    }
    appointment_bookings(
      where: {
        local_start_date: { _lte: "NOW()" }
        state: { _nin: ["NO_SHOW", "PATIENT_CANCELLED", "OFFICE_CANCELLED"] }
      }
    ) {
      id
      local_start_date
    }
  }
}

`