import { useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    submit({ type: 'update_plan_length', value })
  }
}

export function getStep(value) {
  switch (value) {
    case 'Quarterly':
      return 3

    case 'SemiAnnual':
      return 6

    case 'Yearly':
      return 12

    default:
      return 1
  }
}
