import { useDataChange } from 'Simple/Data'

export function useOnUpdate(props) {
  let change = useDataChange({
    context: 'image',
    path: 'editor_updated',
    viewPath: props.viewPath,
  })

  return function onUpdate() {
    change(true)
  }
}

export function useOnInit(props) {
  let change = useDataChange({
    context: 'image',
    path: 'editor',
    viewPath: props.viewPath,
  })

  return function onInit(editor) {
    change(editor)
  }
}
