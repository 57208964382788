import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let automationType = useDataValue({
    context: 'automation',
    path: 'automation_type',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return data.map(item => ({
      ...item,
      selected: item.id === automationType,
    }))
  }, [data, automationType])
}
