import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      ...data,
      images: [
        {
          id: 'slot-1',
          show_deleted_image_series: false,
          image_series_id: null,
          image_id: null,
        },
        {
          id: 'slot-2',
          show_deleted_image_series: false,
          image_series_id: props.imageSeriesId,
          image_id: props.imageId,
        },
      ],
    }
  }, [data, props.imageSeriesId, props.imageId])
}
