import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props, data) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let setSelectedLocations = useDataChange({
    context: 'tab',
    path: 'selected.locations',
    viewPath: props.viewPath,
  })

  return async function onClick(value) {
    let selected_locations = Array.isArray(value) ? value : [value]

    setSelectedLocations(
      selected_locations.map(
        item => locations.find(({ _id }) => _id === item)._id
      )
    )
  }
}
