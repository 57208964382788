// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Upload/Content/Documents/Content/Patients/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Upload_Content_Documents_Content_Patients($id: numeric!) {
  vaxiom_patients_by_pk(id: $id) {
    id
    person {
      id
      first_name
      last_name
      from_person_relationships(
        where: { permitted_to_see_info: { _eq: true } }
      ) {
        id
        to_person_record {
          id
          first_name
          last_name
          patient {
            id
          }
        }
      }
    }
  }
}

`