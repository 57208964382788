// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Empty/NewTreatmentModal/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Empty_NewTreatmentModal_Content($patient_id: uuid!, $location_id: uuid!) {
  vaxiom_treatment_card_create(
    patient_id: $patient_id
    location_id: $location_id
  ) {
    id
  }
}

`