import { useClient } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import query_assigned_assistants from './query_assigned_assistants.graphql.js'
import query_assigned_providers from './query_assigned_providers.graphql.js'
import query_external_professionals from './query_external_professionals.graphql.js'
import query_other_relatives from './query_other_relatives.graphql.js'
import query_responsible_parties from './query_responsible_parties.graphql.js'
import query_medical_history_status from './query_medical_history_status.graphql.js'
import query_ledger_status from './query_ledger_status.graphql.js'
import query_tx_cards from './query_tx_cards.graphql.js'
import query_image_series from './query_image_series.graphql.js'

// All profile related queries
import query_profile_patient from './query_profile_patient.graphql.js'
import query_profile_professionals from './query_profile_professionals.graphql.js'

export function useOnClick(props) {
  let client = useClient()
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'patient_id',
  })
  let refreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person._id',
  })

  return async function onClick() {
    await Promise.all(
      [query_other_relatives, query_responsible_parties].map(query =>
        client
          .query(
            query,
            {
              person_id,
            },
            {
              requestPolicy: 'network-only',
            }
          )
          .toPromise()
      )
    )

    await Promise.all(
      [
        query_assigned_assistants,
        query_assigned_providers,
        query_external_professionals,
        query_medical_history_status,
        query_ledger_status,
        query_tx_cards,
        query_profile_patient,
      ].map(query =>
        client
          .query(
            query,
            {
              patient_id,
            },
            {
              requestPolicy: 'network-only',
            }
          )
          .toPromise()
      )
    )

    await Promise.all(
      [
        {
          dtype: { _eq: 'emploProfessionalRelationship' },
          past: false,
        },
        {
          dtype: { _eq: 'otherProfessionalRelationship' },
          past: false,
        },
        {
          dtype: {
            _in: [
              'otherProfessionalRelationship',
              'emploProfessionalRelationship',
            ],
          },
          past: true,
        },
      ].map(variables =>
        client
          .query(
            query_profile_professionals,
            { ...variables, patient_id },
            { requestPolicy: 'network-only' }
          )
          .toPromise()
      )
    )

    await client
      .query(
        query_image_series,
        {
          patient_id,
          deleted: {},
        },
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise()

    // triggering a refresh of the embedded section
    refreshEmbeddedSection(Date.now())
  }
}
