import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let person_payment_accounts = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
    path: 'person_payment_accounts',
  })

  let selected_id = useDataValue({
    context: 'payments_request_payment',
    viewPath: props.viewPath,
    path: 'person_payment_account.selected_id',
  })

  return useMemo(() => {
    let person_payment_account = person_payment_accounts.find(
      ppa => ppa.id === selected_id
    )
    if (!person_payment_account)
      return {
        phones: [],
        emails: [],
      }
    return {
      phones: person_payment_account.data.payor.contact_method_associations
        .filter(cma => cma.contact_method.dtype === 'phone')
        .map(cma => {
          return {
            ...cma,
            id: cma.contact_method.id,
            text: cma.contact_method.phone.number,
          }
        }),
      emails: person_payment_account.data.payor.contact_method_associations
        .filter(cma => cma.contact_method.dtype === 'email')
        .map(cma => {
          return {
            ...cma,
            id: cma.contact_method.id,
            text: cma.contact_method.email.address,
          }
        }),
    }
  }, [person_payment_accounts, selected_id])
}
