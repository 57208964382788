// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/Audit/Content/Main/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More_Audit_Content_Main($appointment_id: uuid!) {
  vaxiom_appointment_audit_log(
    where: { appointment: { _id: { _eq: $appointment_id } } }
    order_by: { audit_log: { sys_created: desc } }
  ) {
    id
    action
    audit_log {
      id
      sys_created
      created_by {
        id
        person {
          id
          _id
          first_name
          last_name
        }
      }
    }
  }
}

`