import React, { useEffect, useRef } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data'
import View from './view.js'
import { getCellSize } from './helpers.js'

export default function Logic(props) {
  let ref = useRef()
  let grid = useDataValue({
    context: 'grid',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'grid',
    path: 'cell',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    function handleResize() {
      if (!ref.current) return
      resizeGrid({ ref, grid, change })
    }
    window.addEventListener('resize', handleResize)
    // needed when we change view to force redraw
    forceResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [grid]) //eslint-disable-line

  useEffect(() => {
    if (!ref.current) return
    resizeGrid({ ref, grid, change })
  }, []) //eslint-disable-line

  return <View {...props} contentRef={ref} />
}

function resizeGrid({ ref, grid, change }) {
  let height = ref.current.getBoundingClientRect().height
  let width = ref.current.getBoundingClientRect().width
  let cell = getCellSize({
    height,
    width,
    rows: grid.rows,
    columns: grid.columns,
  })
  change(cell)
}

function forceResize() {
  let event = new Event('resize')
  window.dispatchEvent(event)
}
