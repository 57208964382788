import React, { useEffect } from 'react'
import View from './view.js'
import { useDataChange } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let setRefetch = useDataChange({
    context: 'tab',
    path: 'selected.images.refetch',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    setRefetch(Date.now())
    setFlowTo(
      normalizePath(props.viewPath, '../../../../IntraoralMobileCapture/No')
    )
  }, []) // eslint-disable-line

  return <View {...props} />
}
