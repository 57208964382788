// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/Content/Content/Launchers/Content/AlignActions/Content/mutation-forget.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps_Content_Content_Launchers_Content_AlignActions_Content($connection_id: uuid!, $patient_id: uuid!) {
  apps_forget_patient(connection_id: $connection_id, patient_id: $patient_id) {
    ok
  }
}

`