// this is an autogenerated file from DesignSystem/TimelineEvent/Note/Content/Edit/Content/mutation_update.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Note_Content_Edit_Content($note_id: numeric!, $new_note: String!) {
  update_vaxiom_notes_by_pk(
    pk_columns: { id: $note_id }
    _set: { note: $new_note }
  ) {
    id
    note
  }
}

`