// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/AutomationsList/LocationChooser/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations_AutomationsList_LocationChooser_Location($parent_company_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      parent_company: { _id: { _eq: $parent_company_id } }
    }
    order_by: { name: asc }
  ) {
    id
    _id
    text: name
  }
}

`