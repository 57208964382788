export async function downloadUrl({ url, file_name, uFEFF }) {
  let blob = await fetch(url).then(res => res.blob())
  return downloadBlob({ blob, file_name, uFEFF })
}

export async function downloadBlob({ blob, file_name, uFEFF }) {
  let text = await blob.text()
  let dataURI = `data:${blob.type};charset=utf-8,${
    uFEFF ? '\uFEFF' : ''
  }${text}`

  let URL = window.URL || window.webkitURL

  let content =
    typeof URL.createObjectURL === 'undefined'
      ? dataURI
      : URL.createObjectURL(blob)
  let downloadLink = document.createElement('a')
  downloadLink.href = content
  downloadLink.download = file_name

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(content)
}
