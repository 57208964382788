import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patientsData = useDataValue({
    context: 'patients',
    viewPath: props.viewPath,
    path: 'patients',
  })
  let patients = patientsData
    .filter(p => p.greyfinch.patient)
    .map(p => p.greyfinch.patient.id)
  return useMemo(() => {
    if (!data) return data
    // filter out patients that are already in the list
    return data
      .map(item => item.person)
      .filter(p => !!p.patient)
      .filter(p => !patients.includes(p.patient._id))
  }, [data])
}
