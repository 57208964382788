import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'change_appointment_type',
    viewPath: props.viewPath,
  })
  let current_appointment_type_id = useDataValue({
    context: 'event',
    path: 'appointment.type.id',
    viewPath: props.viewPath,
  })
  let next_appointment_type_id = useDataValue({
    context: 'change_appointment_type',
    path: 'appointment_type_id',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting || current_appointment_type_id === next_appointment_type_id
  )
}
