/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let onUpdate = useOnUpdate(props)
  let onClose = useOnClose(props)

  return async function onSubmit({ args, ...rest }) {
    switch (args.type) {
      case 'update': {
        return onUpdate({ args, ...rest })
      }
      case 'close':
        return onClose({ args, ...rest })
      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnUpdate(props) {
  return function onUpdate({ value }) {
    if (typeof value.update === 'function') {
      value.update()
      return true
    }
    return false
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnClose(props) {
  return function onClose({ change }) {
    change(next => {
      next.selected.showInfoDialog = false
      next.selected.showActionsDialog = false
    })

    return true
  }
}
