import { useDataValueOnce } from 'Logic/FlowShortcuts'
export default function useGetDefaultTab(props, data) {
  let date = useDataValueOnce({
    context: 'flow_shortcuts',
    path: 'date',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id) {
    if (maybeTab?.coreParams) return maybeTab

    return {
      ...data,
      coreParams: { date },
      viewPath: props.viewPath,
    }
  }
}
