import { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function useEnsureTab({ tab, viewPath }) {
  let tabId = useDataValue({
    context: 'global',
    path: 'tab_id',
    viewPath,
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath,
  })

  useEffect(() => {
    if (tabId) return

    let id = window.requestIdleCallback(
      () => {
        submit({ type: 'tabs/add', tab })
      },
      { timeout: 1_000 }
    )

    return () => window.cancelIdleCallback(id)
  }, [tabId]) // eslint-disable-line
  // ignore change and tab
}
