import React from 'react'
// See: https://formidable.com/open-source/victory/docs
import { VictoryPie } from 'victory'

export default function PieChart(props) {
  if (!Array.isArray(props.value)) return null
  return (
    <VictoryPie
      animate={{
        duration: 2000,
      }}
      padding={30}
      labelRadius={({ innerRadius }) => innerRadius + 50}
      style={{
        labels: {
          fill: '#606060',
          fontSize: 10,
          fontFamily: 'Inter',
        },
      }}
      innerRadius={30}
      height={props.height}
      width={props.width}
      data={props.value}
      colorScale={['#00BC70', 'rgba(247,130,112,0.7)', '#A6CDF5']}
    />
  )
}
