import React from 'react'
import View from './view.js'

export default function Logic(props) {
  return (
    <View
      {...props}
      // used to identify child of nested floating portal
      data-view-path={props.viewPath}
    />
  )
}
