import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnDelete(props) {
  let token_id = useDataValue({
    context: 'reusable_method',
    path: 'id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'reusable_methods',
  })

  return async function onClick() {
    submit({
      type: 'delete',
      token_id,
    })
  }
}
