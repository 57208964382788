import { useDataChange } from 'Simple/Data.js'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    change(inext => {
      inext.actions[props.index] = next
    })
  }
}
