import isAfter from 'date-fns/isAfter'
import isValidDate from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import { formatInTimeZone } from 'date-fns-tz'

export function getCheckInTimeInTZ(value, timezone_id = 'US/Central') {
  let date = parseISO(`${value}Z`)
  return isValidDate(date)
    ? formatInTimeZone(date, timezone_id, 'h:mm a')
    : value
}

export function isAfterStartTime(check_in_time, start_time) {
  return isAfter(new Date(check_in_time), new Date(start_time))
}
