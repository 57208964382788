import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let image_serie_id = useDataValue({
    context: 'tab',
    path: 'image_serie_id',
    viewPath: props.viewPath,
  })
  let image_id = useDataValue({
    context: 'tab',
    path: 'image_id',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data) return

    return {
      ...data,
      images: [
        {
          id: 'slot-1',
          show_deleted_image_series: false,
          image_serie_id: null,
          image_id: null,
        },
        {
          id: 'slot-2',
          show_deleted_image_series: false,
          image_serie_id,
          image_id,
        },
      ],
    }
  }, [data, image_id, image_serie_id])
}
