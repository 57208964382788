// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Notes/Content/NewOrEdit/Content/Actions/delete_mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Notes_Content_NewOrEdit_Content_Actions($note_id: numeric!) {
  delete_vaxiom_notes_by_pk(id: $note_id) {
    id
    note
  }
}

`