import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let resource_id = useDataValue({
    context: 'location_logo',
    path: 'id',
    viewPath: props.viewPath,
  })

  return {
    variables: { resource_id },
    pause: !resource_id,
  }
}
