import { TREATMENT_FLOW_STEPS } from 'Data/constants'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'steps',
    viewPath: props.viewPath,
  })
  let rulesetSteps = useDataValue({
    context: 'ruleset',
    path: 'steps',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change(
      value
        ? TREATMENT_FLOW_STEPS.filter(
            step => rulesetSteps.includes(step) || step === 'down_payment'
          )
        : TREATMENT_FLOW_STEPS.filter(
            step => rulesetSteps.includes(step) && step !== 'down_payment'
          )
    )
  }
}
