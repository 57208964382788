import React, { useMemo } from 'react'
import { DataProvider, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let apps = useDataValue({
    context: 'apps',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'tab',
    path: 'selected.app_id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'tab',
    path: 'selected.connection_name',
    viewPath: props.viewPath,
  })
  let value = useMemo(() => {
    let app = apps.find(item => item.id === app_id)
    let connection = app.metadata.connections.find(
      connection => connection.id === connection_name
    )

    return {
      ...connection,
      app_id: app.id,
      xid: app.xid,
      sync_available: !!app.metadata?.sync?.find(
        item => item.connection_name === connection_name
      ),
    }
  }, [app_id, apps, connection_name])

  return (
    <DataProvider
      context="connection_definition"
      value={value}
      viewPath={props.viewPath}
    >
      <View {...props} />
    </DataProvider>
  )
}
