import { useMemo } from 'react'
import produce from 'immer'
import { useDataValue } from 'Simple/Data.js'
import { numberMoney } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let receivable = useDataValue({
    context: 'post_payment_info',
    path: 'receivable',
    viewPath: props.viewPath,
  })
  let patient_due_amount = useDataValue({
    context: 'post_payment_info',
    path: 'patient_due_amount',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return produce(data, next => {
      // receivable balance has priority over payment account balance
      if (receivable) {
        next.amount = numberMoney(receivable.balance_amount || 0)
        next.receivable_id = receivable.id
        next.auto_apply_to_unpaid_receivables = false
      } else {
        next.amount = numberMoney(patient_due_amount || 0)
      }
    })
  }, [data, receivable, patient_due_amount])
}
