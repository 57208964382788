import { useClient, useMutation } from 'Data/Api'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { isAccessTokenValid, useRefreshToken } from 'Data/Auth.js'
import { today } from 'Data/format.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useHistory } from 'Logic/FlowShortcuts.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import React, { useEffect } from 'react'
import View from './view.js'
import querySession from './query-session.graphql.js'
import queryUser from './query-user.graphql.js'
import mutationSetCurrentLocation from './mutation-set-current-location.graphql.js'

export default function Logic(props) {
  let auth = useDataValue({
    context: 'auth',
    viewPath: props.viewPath,
  })
  let refreshToken = useRefreshToken({ viewPath: props.viewPath })
  let submitSession = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutationSetCurrentLocation)
  let [, notify] = useNotifications()

  let client = useClient()

  let redirect = useDataValue({
    context: 'flow_shortcuts',
    path: 'redirect',
    viewPath: props.viewPath,
  })
  let history = useHistory()

  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    let cancel = false

    ;(async () => {
      try {
        if (isAccessTokenValid(auth)) {
          return await success(auth)
        } else if (auth.refresh_token) {
          let nextAuth = await refreshToken()
          if (cancel) return

          if (nextAuth.access_token) {
            return await success(nextAuth)
          }
        }
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.debug({
            type: props.viewPath,
            error,
            auth,
          })
        }
      }
      setFlowTo(normalizePath(props.viewPath, '../SignIn'))

      async function success(auth) {
        // If the token was refreshed during the Check process, we need to
        // pass the credentials over to urql, otherwise Api didn't have time
        // pick up the new credentials from Auth and it kicks the user out.
        // To make things simpler always run queries in this method enforcing
        // a specific context.
        // In fairness, what would make sense here is that the logic to pull
        // session data is elsewhere (like Account) but this was the easiest
        // when refactoring all that stuff at the time.
        let context = {
          fetchOptions: {
            headers: {
              'x-hasura-role': auth.api_role,
              Authorization: `Bearer ${auth.access_token}`,
            },
          },
        }
        let { user_id } = auth.access_token_data

        let user = null
        if (!auth.api_role.endsWith('-xid')) {
          let queryUserResponse = await client
            .query(queryUser, { user_id }, context)
            .toPromise()
          user = queryUserResponse.data
        }

        // avoid asking for location on action, even if new pms is enabled
        let isAction =
          history.location.pathname.startsWith('/action') ||
          (history.location.pathname.startsWith('/signin-token') &&
            history.location.search.startsWith('?redirectUri=/action'))

        let choseLocationAfterLogin = localStorage.getItem(
          'choseLocationAfterLogin'
        )

        let location_id = user?.preferences?.current_location_id

        if (
          user === null ||
          (user.locations.length > 1 &&
            choseLocationAfterLogin !== today() &&
            !isAction)
        ) {
          setFlowTo('/App/ChooseLocation')
        } else if (user.locations.length === 0) {
          // shouldn't happen but just in case...
          setFlowTo('/App/Auth/SignOut')
        } else {
          if (!location_id) {
            // using the first location as the current location
            location_id = user.locations[0]._id
            let mutationResponse = await executeMutation(
              {
                current_location_id: location_id,
              },
              context
            )
            if (mutationResponse.error) {
              notify(
                notifyError(
                  'Something went wrong. Please, try again or contact support if the problem persists.'
                )
              )
              setFlowTo('/App/Auth/SignOut')
              return
            }
          }

          let { data: session } = await client
            .query(querySession, { user_id, location_id }, context)
            .toPromise()
          let {
            locations: [current_location],
          } = session

          let user_details = {
            first_name: session.user_details.vaxiom_user.person.first_name,
            last_name: session.user_details.vaxiom_user.person.last_name,
            email: session.user_details.account.email,
            title: session.user_details.vaxiom_user.person.title || '',
            job_title: session.user_details.vaxiom_user.person.job_title || '',
            role: session.user_details.vaxiom_user.role || '',
          }

          submitSession({
            type: 'session/set',
            current_location: {
              ...current_location,
              parent_company: current_location.parent_company[0],
              permissions: current_location.permissions[0],
            },
            feature_flags: session.feature_flags,
            user: {
              id: user_id,
              ...user_details,
              preferences: {
                chair_ids: session.user_preferences.chair_ids,
              },
              comms_preferences: session.comms_user_preferences || {
                location_ids: null,
                mobile_numbers_outbound_ids: null,
              },
            },
          })

          if (redirect) {
            redirect()
          } else {
            setFlowTo('/App/Account/Content/RedirectToDefaultTab')
          }
        }
      }
    })()

    return () => {
      cancel = true
    }
  }, []) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
