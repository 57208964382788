// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/Frequencies/Rulesets/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_Frequencies_Rulesets($parent_company_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      payment_plan_frequencies: { parent_id: { _eq: $parent_company_id } }
    }
  ) {
    _id
    payment_plan_frequencies {
      id
      organization_id
      parent_id
      division_id
      location_id
      type
      label
      allowed_dates
      document_template_id
    }
  }
}

`