import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      patient: {
        id: props.patient_id ?? data.patient.id,
        person: props.person ?? data.person,
      },
    }
  }, [data.patient.id, data.person, props.patient_id, props.person])
}
