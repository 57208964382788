import { useDataValue } from 'Simple/Data.js'
import { useUppy } from 'design-system/FileUploader/react.js'

export function useOnClick(props) {
  let uppy = useUppy(props)
  let selectedFile = useDataValue({
    context: 'patient_documents',
    path: 'preview_file',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    let tagFile = {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size,
      isRemote: true,
      remote: {
        body: {
          resource_id: selectedFile.resource_id,
          resource_type: selectedFile.resource_type,
        },
      },
      data: {
        size: selectedFile.size,
      },
    }
    try {
      uppy.addFile(tagFile)
    } catch (err) {
      if (!err.isRestriction) {
        uppy.log(err)
        uppy.info(
          'There was an error when trying to add your files. Please try again.',
          'error',
          3000
        )
      }
    }
  }
}
