// this is an autogenerated file from DesignSystem/CaptureImageSeries/Content/Active/Content/Images/mutation_create_patient_image.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CaptureImageSeries_Content_Active_Content_Images(
  $delete_patient_image: Boolean!
  $patient_image_id: numeric
  $patient_id: numeric!
  $person_id: numeric!
  $image_series_id: numeric!
  $type_key: String!
  $slot_key: String
  $original_file_key: String!
  $file_key: String!
  $is_profile_picture: Boolean!
  $profile_pic_url: String
) {
  old_patient_image: delete_vaxiom_patient_images(
    where: { id: { _eq: $patient_image_id } }
  ) @include(if: $delete_patient_image) {
    returning {
      id
    }
  }
  new_patient_image: insert_vaxiom_patient_images_one(
    object: {
      patient_id: $patient_id
      imageseries_id: $image_series_id
      type_key: $type_key
      slot_key: $slot_key
      file_key: $file_key
      original_file_key: $original_file_key
    }
  ) {
    id
    file_key
    original_file_key
    type_key
    slot_key
    mime_type
  }
  profile_picture: update_vaxiom_persons_by_pk(
    pk_columns: { id: $person_id }
    _set: { profile_pic_url: $profile_pic_url }
  ) @include(if: $is_profile_picture) {
    id
  }
}

`