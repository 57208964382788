// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Images/Videos/VideosTree/Content/Files/File/DeleteAction/Confirmation/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Images_Videos_VideosTree_Content_Files_File_DeleteAction_Confirmation_Content($id: numeric!) {
  delete_vaxiom_patient_images_by_pk(id: $id) {
    id
  }
}

`