import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.selected.images.uploaded_images_docked_left =
        !next.selected.images.uploaded_images_docked_left
    })
  }
}
