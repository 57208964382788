import { useEffect } from 'react'
import Plugin from './plugin.js'

export default function useFileUploaderPluginEditor({ uppy, editor }) {
  useEffect(() => {
    // this one is tricky as Uppy overrides the "this" reference and as a result it will point to an outdated version of those dependencies
    // here we have to re-register the plugin in order for the changes to take effect
    let plugin = uppy.getPlugin(Plugin.pluginName)
    if (plugin) {
      uppy.removePlugin(plugin)
    }

    uppy.use(Plugin, {
      id: Plugin.pluginName,
      editor,
    })
  }, [uppy, editor])
}
