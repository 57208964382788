// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Comms/mutationSms.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Comms($number: PhoneNumber!) {
  thread: comms_threads_sms_create(number: $number) {
    id
  }
}

`