import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { useDataValue } from 'Simple/Data.js'
import isEqual from 'lodash/isEqual.js'

export function useOnChangeTablePreset(props) {
  let [, executeMutation] = useMutation(mutation)
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.organization._id',
  })
  let table_preset = useDataValue({
    viewPath: props.viewPath,
    context: 'user_location_preferences',
    path: 'compact_table_presets',
  })

  return async function onChangeTablePreset(data) {
    if (isEqual(table_preset?.data, data)) return

    await executeMutation({ location_id, data })
  }
}
