// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CaptureImageRequest/Content/Content/Request/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CaptureImageRequest_Content_Content_Request(
  $id: uuid!
  $status: images_request_status_types_enum!
) {
  update_images_requests_by_pk(
    pk_columns: { id: $id }
    _set: { status: $status }
  ) {
    id
    status
  }
}

`