import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'
import { endOfMonth, startOfMonth } from 'date-fns'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      start_date: startOfMonth(date),
      end_date: endOfMonth(date),
    }
  }, [data, date])
}
