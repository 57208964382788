// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Images/Misc/Empty/ImagePlaceholder/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Images_Misc_Empty_ImagePlaceholder(
  $temporary_image_id: numeric!
  $image_series_id: numeric!
) {
  vaxiom_patient_images_create(
    temporary_image_id: $temporary_image_id
    image_series_id: $image_series_id
    type_key: "misc"
  ) {
    id
  }
}

`