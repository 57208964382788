// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/mutation_delete.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions($id: uuid!) {
  delete_vaxiom_provider_calls_by_pk(id: $id) {
    id
  }
}

`