// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/NewImageSeriesAction/Content/SeriesName/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_NewImageSeriesAction_Content_SeriesName($parent_company_id: numeric!) {
  vaxiom_image_series(where: { organization_id: { _eq: $parent_company_id } }) {
    id
    type {
      id
      name
    }
  }
}

`