// https://github.com/LiosK/cdigit/blob/main/lib/algo/verhoeff.js
/**
 * cdigit
 *
 * @copyright 2018-2023 LiosK
 * @license (MIT OR Apache-2.0)
 */

let d = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
  [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
  [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
  [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
  [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
  [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
  [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
  [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
]
/** The Verhoeff permutation table. */
let p = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
  [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
  [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
  [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
  [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
  [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
  [7, 0, 4, 6, 9, 1, 3, 2, 5, 8],
]
/** The Verhoeff inverse table. */
let inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9]
function computeFromNumVals(ns) {
  if (ns.length === 0) {
    throw new SyntaxError('string to be protected is empty')
  } else if (ns.some(e => e < 0 || e > 9 || !Number.isInteger(e))) {
    throw new SyntaxError('invalid numerical value detected')
  }
  // as if: `ns.push(0); let c = 0;` and finished first loop where i == 0
  let c = d[0][p[0][0]]
  for (let i = 1, len = ns.length; i <= len; i += 1) {
    c = d[c][p[i & 7][ns[len - i]]]
  }
  return [inv[c]]
}
function compute(s) {
  let ds = String(s).replace(/[^0-9]/g, '')
  let ns = [...ds].map(Number)
  return String(computeFromNumVals(ns)[0])
}
function parse(s) {
  let m = String(s).match(/^(.*)([0-9])$/s)
  if (m != null) {
    return [m[1], m[2]]
  } else {
    throw new SyntaxError('could not find check character(s)')
  }
}
export function generate(s) {
  return `${s}${compute(s)}`
}
export function validate(s) {
  let [bare, cc] = parse(s)
  return compute(bare) === cc
}
/**
 * The Verhoeff algorithm implementation.
 *
 * Note: There is not a firm consensus on the direction (left to right or right
 * to left) in which a Verhoeff calculator scans numeric text to letruct an
 * input digit sequence. This implementation is hard coded to read a string from
 * right to left and append the check digit at the rightmost position, which is
 * a consistent behavior with other popular implementations. Reverse the input
 * string before calling this class' methods if you need to interpret a string
 * from left to right.
 */

export function generateLetter(s) {
  return `${s}${computeLetter(s)}`
}

function computeLetter(s) {
  switch (compute(s)) {
    case '0':
      return 'A'
    case '1':
      return 'B'
    case '2':
      return 'C'
    case '3':
      return 'D'
    case '4':
      return 'E'
    case '5':
      return 'F'
    case '6':
      return 'H'
    case '7':
      return 'J'
    case '8':
      return 'K'
    case '9':
      return 'L'
    default:
      return ''
  }
}
