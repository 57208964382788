import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let estimated_reimbursement_amount = useDataValue({
    context: 'insurance',
    path: 'estimated_reimbursement_amount',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      estimated_reimbursement_amount,
    }),
    [estimated_reimbursement_amount]
  )
}
