import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import React, { useMemo } from 'react'
import View from './view.js'
import TysiaText from 'DesignSystem/TysiaText/index.js'
import StatusToggle from './StatusToggle/index.js'
import { format, parseISO } from 'date-fns'

export default function Logic(props) {
  let tokens = useDataValue({
    context: 'tokens',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useMemo(() => {
    return {
      columns: [
        {
          Header: 'Description',
          accessor: 'description',
        },
        {
          Header: 'Key',
          accessor: 'key',
        },
        {
          Header: 'Masked Secret',
          accessor: 'masked_secret',
        },
        {
          Header: 'Expires At',
          accessor: 'expires_at',
          Cell: row => (
            <TysiaText
              text={format(parseISO(row.value), 'd MMM, yyyy')}
              viewPath={props.viewPath}
            />
          ),
        },
        {
          Header: 'Active',
          accessor: 'status',
          Cell: ({ row }) => (
            <StatusToggle
              isActive={row.original.status === 'active'}
              viewPath={props.viewPath}
              onChange={event => {
                change(next => {
                  next.selected.developers = {
                    id: row.original.id,
                    status:
                      row.original.status === 'active' ? 'inactive' : 'active',
                  }
                })
                setFlowTo(
                  normalizePath(props.viewPath, './ChangeStatus/Content')
                )
              }}
            />
          ),
        },
      ],
      data: tokens || [],
    }
  }, [tokens])

  return <View value={value} viewPath={props.viewPath} />
}
