import { MONTHS } from 'Data/constants.js'
import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  /** @type {Date | undefined} */
  let sidebar_date = useDataValue({
    context: 'sidebar',
    viewPath: props.viewPath,
    path: 'date',
  })

  return useMemo(() => {
    let selectedMonthIndex = sidebar_date?.getMonth()

    return {
      months: MONTHS.map(month => ({
        ...month,
        selected: month.id === selectedMonthIndex,
      })),
      date: sidebar_date,
    }
  }, [sidebar_date])
}
