// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/NoteTemplates/copy-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_NoteTemplates(
  $note_template_ids: [uuid!]!
  $target_location_ids: [uuid!]!
) {
  notes_templates_copy(
    note_template_ids: $note_template_ids
    target_location_ids: $target_location_ids
  ) {
    ok
  }
}

`