// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsEditAutopay/Content/mutation_remove.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsEditAutopay_Content($payment_plan_id: numeric!) {
  payments_remove_payment_plan_autopay(payment_plan_id: $payment_plan_id) {
    payment_plan_id
  }
}

`