// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/DefaultPaymentPlans/Rulesets/Content/Ruleset/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_DefaultPaymentPlans_Rulesets_Content_Ruleset_Location($parent_company_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      _or: [
        { parent: { _id: { _eq: $parent_company_id } } }
        { parent: { parent: { _id: { _eq: $parent_company_id } } } }
      ]
    }
    order_by: { name: asc }
  ) {
    _id
    name
    parent {
      _id
    }
  }
}

`