// This file is automatically generated by Simple and will be overwritten
// when the morpher runs. If you want to contribute to how it's generated, eg,
// improving the algorithms inside, etc, see this:
// https://github.com/use-simple/morpher/blob/master/ensure-is-hovered.js
import { useEffect, useMemo, useRef, useState } from 'react'

// TODO replace with something more performant like:
// https://github.com/therealparmesh/use-hovering/blob/master/src/index.js
// or useTooltip from Reach UI
// https://github.com/reach/reach-ui/blob/master/packages/tooltip/src/index.tsx
export default function useIsHovered({
  isDisabled,
  isSelected,
  onMouseEnter,
  onMouseEnterDelay = null,
  onMouseLeave,
  onMouseLeaveDelay = onMouseEnterDelay,
}) {
  let [isHovered, setIsHovered] = useState(false)
  let onMouseEnterTimeout = useRef(null)
  let onMouseLeaveTimeout = useRef(null)
  // let cancel = useRef(false)

  let isHoveredBind = useMemo(
    () => ({
      onMouseEnter: event => {
        if (onMouseEnterDelay === null) {
          run()
        } else {
          clearTimeout(onMouseEnterTimeout.current)
          onMouseEnterTimeout.current = setTimeout(run, onMouseEnterDelay)
        }

        function run() {
          // if (cancel.current) {
          //   cancel.current = false
          //   return
          // }
          setIsHovered(true)

          if (typeof onMouseEnter === 'function') {
            onMouseEnter(event)
          }
        }
      },
      onMouseLeave: event => {
        if (onMouseEnterDelay === null) {
          run()
        } else {
          clearTimeout(onMouseLeaveTimeout.current)
          onMouseLeaveTimeout.current = setTimeout(run, onMouseLeaveDelay)
        }

        function run() {
          // if (cancel.current) {
          //   cancel.current = false
          //   return
          // }
          setIsHovered(false)
          // cancel.current = true

          if (typeof onMouseLeave === 'function') {
            onMouseLeave(event)
          }
        }
      },
    }),
    [onMouseEnter, onMouseEnterDelay, onMouseLeave, onMouseLeaveDelay]
  )

  useEffect(() => {
    if (!isDisabled) return

    setIsHovered(false)
  }, [isDisabled])

  return [isHovered, isHovered && isSelected, isHoveredBind]
}
