import { useRef } from 'react'
import { useDataValue } from 'Simple/Data'

function getAttachmentsMessageDefault(threadName) {
  return threadName
    ? `Please find the attachments included for ${threadName}`
    : 'Please find the attachments included'
}

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let prevMessage = useRef(null)
  let threadName = useDataValue({
    context: 'thread',
    path: 'name',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    let attachments_message =
      props.attachments_message || getAttachmentsMessageDefault(threadName)

    if (
      value.attachments?.length > 0 &&
      value.message === '' &&
      prevMessage.current !== attachments_message
    ) {
      change(next => {
        next.message = attachments_message
      })
    } else if (
      value.attachments?.length === 0 &&
      value.message === attachments_message
    ) {
      change(next => {
        next.message = ''
      })
    } else {
      if (typeof props.onChange === 'function') {
        props.onChange(value)
      }
    }

    prevMessage.current = value.message
  }
}
