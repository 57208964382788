import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let editor = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let changeDisabled = useDataChange({
    context: 'editor',
    path: 'disabled',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!editor) return

    editor.setEditableRoot(false)
    changeDisabled(true)

    return () => {
      try {
        editor.setEditableRoot(true)
        changeDisabled(false)
      } catch (_) {}
    }
  }, [editor]) // eslint-disable-line
  // ignore change

  return <View viewPath={props.viewPath} />
}
