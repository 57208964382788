import { PutObjectCommand } from '@aws-sdk/client-s3'
import { getAmazonS3URI, useAwsS3Client } from 'Data/s3'

export function useSaveEditorContent(props) {
  let s3 = useAwsS3Client(props)

  return async function save({ url, content }) {
    let { bucket, key } = getAmazonS3URI(url)
    await (
      await s3()
    ).send(
      new PutObjectCommand({
        Bucket: bucket,
        Key: key,
        Body: content,
      })
    )
  }
}
