import React from 'react'

export default function Iframe(props) {
  return (
    <iframe
      src={props.src}
      height="100%"
      width="100%"
      style={{
        border: 0,
      }}
    />
  )
}
