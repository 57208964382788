// this is an autogenerated file from DesignSystem/AppointmentActions/refresh-query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_AppointmentActions($id: uuid!) {
  vaxiom_appointment_bookings(where: { _id: { _eq: $id } }) {
    id
    _id
    state
    confirmation_status
  }
}

`