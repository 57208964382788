import React from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'
import { useFloating, offset, shift } from '@floating-ui/react-dom'

export default function Logic(props) {
  let flow = useFlowValue(props.viewPath)
  let setFlowTo = useSetFlowTo(props.viewPath)

  let { refs, x, y } = useFloating({
    open: flow === 'Content',
    middleware: [offset({ mainAxis: 5 }), shift()],
  })

  refs.setReference(props.triggerRef.current)

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === refs.reference.current ||
      refs.reference.current?.contains(event.target)
    ) {
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return <View {...props} setInnerRef={refs.setFloating} left={x} top={y} />
}
