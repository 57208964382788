// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Procedures/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Procedures($appointment_id: uuid!, $location_id: uuid!) {
  all: vaxiom_procedures(
    where: { organization: { _id: { _eq: $location_id } } }
    order_by: { name: asc }
  ) {
    id
    _id
    description
    insurance_code {
      id
      code
    }
  }
  appointment: vaxiom_appointment_procedures(
    where: {
      deleted: { _eq: false }
      appointment: { _id: { _eq: $appointment_id } }
    }
  ) {
    procedure {
      id
      _id
      description
      insurance_code {
        id
        code
      }
    }
  }
}

`