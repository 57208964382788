import { utcToZonedTime } from 'date-fns-tz'
import differenceInMinutes from 'date-fns/differenceInMinutes'

export function getDifferenceInMinutes(start, time_zone_id) {
  let diff = differenceInMinutes(
    utcToZonedTime(new Date(), time_zone_id),
    new Date(start)
  )
  return `# Patient is ${diff} minutes late`
}
