import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'image_series_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { image_series_id },
    pause: !image_series_id,
    requestPolicy: 'cache-and-network',
  }
}
