import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let treatment = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs',
  })
  let parent_company_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person.organization_id',
  })
  return {
    variables: {
      treatment_id: treatment?.id,
      treatment_status: treatment?.status,
      parent_company_id,
    },
    pause: !treatment && !parent_company_id,
  }
}
