import { useDataChange, useDataValue } from 'Simple/Data'

export default function useDataOnChange(props) {
  let selected = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (!value) return
    if (value.length === 0) return
    if (selected.xid && value.some(item => item.xid === selected.xid)) {
      return
    }

    change({
      xid: value[0].xid,
      location_id: null,
    })
  }
}
