import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let action = useDataValue({
    viewPath: props.viewPath,
    context: 'action',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onClick() {
    change(next => {
      if (action.id === 'overview') {
        next.selected.tab = 'Overview'
        next.selected.kpi = null
      } else {
        next.selected.tab = 'Kpi'
        next.selected.kpi = action.kpi
      }
    })
  }
}

export function isSelected(id, selected) {
  switch (selected.tab) {
    case 'Overview': {
      return id === 'overview'
    }

    case 'Kpi': {
      return id === selected.kpi?.id
    }

    default: {
      return false
    }
  }
}
