// this is an autogenerated file from DesignSystem/FeatureFlag/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_FeatureFlag($organization_ids: [numeric!]!, $name: String!) {
  vaxiom_application_properties(
    where: {
      organization_id: { _in: $organization_ids }
      message_key: { _eq: $name }
    }
    order_by: { organization_id: desc }
  ) {
    value: message_value
  }
}

`