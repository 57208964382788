import { dateShortOutFromUnixTime } from 'Data/format.js'
import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let resume_date = useDataValue({
    context: 'dental_monitoring',
    path: 'patient.monitoring.resume_date',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data
    if (!resume_date) return data

    return {
      ...data,
      resume_date: dateShortOutFromUnixTime(resume_date),
    }
  }, [data, resume_date])
}
