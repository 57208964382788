import React from 'react'
import { ApiCube } from 'Data/ApiCube.js'

import View from './view.js'

export default function Logic(props) {
  return (
    <ApiCube viewPath={props.viewPath}>
      <View {...props} />
    </ApiCube>
  )
}
