import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSaveEditorContent } from '../useSaveEditorContent.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let document_id = useDataValue({
    context: 'preview_document',
    path: 'document_id',
    viewPath: props.viewPath,
  })
  let url = useDataValue({
    context: 'document',
    path: 'url',
    viewPath: props.viewPath,
  })
  let editor = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let save = useSaveEditorContent(props)

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    change(next => {
      next.saving = true
    })

    await save({
      url,
      content: editor.getContent(),
    })
    let mutationResponse = await executeMutation({
      document_id,
    })

    editor.setDirty(false)
    change(next => {
      next.isDirty = false
      next.saving = false
    })

    if (mutationResponse.error) {
      notify(notifyError(`Couldn't save the document as PDF`))
    } else {
      notify(notifySuccess(`The document was saved successfully as PDF`))
    }
  }
}
