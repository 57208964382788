import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'connections' })
  }
}

export function getText(connections) {
  return connections.length === 1
    ? '1 connection'
    : `${connections.length} connections`
}
