import { generateSignedUrl, useAwsS3Client } from 'Data/s3.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import View from './view.js'
import React, { useEffect } from 'react'

export default function Logic(props) {
  let params = useDataValue({
    context: 'message',
    path: 'params',
    viewPath: props.viewPath,
  })
  let s3 = useAwsS3Client({ viewPath: props.viewPath })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    let cancel = false

    async function run() {
      let pdfMergerJs = import('pdf-merger-js/browser')

      // if we do it within the promise we get them out of order, however,
      // we can fetch the files in parallel no problem and the array will
      // be fine
      let files = await Promise.all(
        params.urls.map(async url => {
          let file = await fetch(await generateSignedUrl(s3, url))
          return await file.blob()
        })
      )
      if (cancel) return

      let merger = new (await pdfMergerJs).default()
      if (cancel) return
      for (let file of files) {
        await merger.add(file)
      }
      if (cancel) return

      await merger.save(params.filename.replace('.pdf', ''))
      if (cancel) return

      setFlowTo(normalizePath(props.viewPath, '../No'))
    }
    run()

    return () => {
      cancel = true
    }
  }, [])

  return <View {...props} />
}
