import useIsHovered from 'Simple/hooks/useIsHovered.js'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      // make sure it uses the height passed as props if provided
      height={
        typeof props.height === 'number' || typeof props.height === 'string'
          ? props.height
          : getHeight(props.size)
      }
    />
  )
}

function getHeight(size) {
  switch (size) {
    case 'normal':
      return 30
    case 'small':
      return 24
    default:
      return 30
  }
}
