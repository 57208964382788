import React from 'react'
import { isDevelopmentOrStaging } from './helpers.js'
import View from './view.js'

export default function Logic(props) {
  return (
    <View
      {...props}
      shouldDisplay={isDevelopmentOrStaging() || !!props.force}
    ></View>
  )
}
