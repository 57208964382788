// this is an autogenerated file from App/Account/Content/Insights/Reports/mutation-rename-preset.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Insights_Reports($id: uuid!, $name: String!) {
  update_reports_filter_presets_by_pk(
    pk_columns: { id: $id }
    _set: { name: $name }
  ) {
    id
    name
    text: name
    data
  }
}

`