import {
  isBankAccountMetadataValid,
  isCreditCardMetadataValid,
} from './helpers.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  return function onChange(value, change) {
    if (!value.add_new) return

    if (value.provider === 'bluefin') {
      let nextValid =
        value.method === 'card'
          ? isCreditCardMetadataValid(value.credit_card)
          : isBankAccountMetadataValid(value.bank_account)

      if (nextValid !== value.valid) {
        change(next => {
          next.valid = nextValid
        })
      }
    }
  }
}
