// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/ConfigurationTable/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_ConfigurationTable($organization_id: numeric!, $value: String!) {
  insert_vaxiom_application_properties_one(
    object: {
      organization_id: $organization_id
      message_key: "new.pay_app.enabled"
      message_value: $value
    }
    on_conflict: {
      constraint: idx_364469_primary
      update_columns: [message_value]
      where: {
        organization_id: { _eq: $organization_id }
        message_key: { _eq: "new.pay_app.enabled" }
      }
    }
  ) {
    organization_id
    message_key
    message_value
  }
}

`