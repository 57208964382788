// @view
import React from 'react'

export default React.forwardRef(function Canvas(props, ref) {
  let { isShown, width, height, zIndex, position } = props

  return (
    <canvas
      style={{
        display: isShown ? 'block' : 'none',
        zIndex: zIndex || 'auto',
        position: position || 'unset',
      }}
      width={width}
      height={height}
      ref={ref}
    />
  )
})
