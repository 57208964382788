// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past($location_id: uuid!) {
  vaxiom_tx_card_field_definitions(
    where: {
      organization: { _id: { _eq: $location_id } }
      type: {
        _or: [{ deleted: { _is_null: true } }, { deleted: { _gte: "now()" } }]
      }
      _or: [{ deleted: { _is_null: true } }, { deleted: { _gte: "now()" } }]
    }
    order_by: { number: asc }
  ) {
    id
    short_name
    name
  }
}

`