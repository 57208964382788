import View from './view.js'
import { useDataSubmit, useDataChange, useDataValue } from 'Simple/Data'
import { useMemo } from 'react'
import { dateISOToDateAndTime } from 'Data/format'
import ChangeStatusAction from './ChangeStatusAction/index.js'
import TysiaCheckbox from 'DesignSystem/TysiaCheckbox/index.js'
import TysiaCheckboxInput from 'DesignSystem/TysiaCheckbox/TysiaCheckboxInput/index.js'

let EVENT_TYPES = {
  appointment_bookings_state_update: () => 'Appointment status',
  txs_status_update: () => 'Treatment status',
  custom_resource_insert: () => 'Custom resource created',
}

let VALUES = {
  CHECKED_IN: 'Checked In',
  CHECKED_OUT: 'Checked Out',
  CONFIRMED: 'Confirmed',
  NO_SHOW: 'No Show',
  OFFICE_CANCELLED: 'Office Cancelled',
  PATIENT_CANCELLED: 'Patient Cancelled',
  SCHEDULED: 'Scheduled',
  UNSEATED: 'Unseated',
}

let EVENT_STATUS = {
  appointment_bookings_state_update: ({ value_to, appointment_type_names }) =>
    `${VALUES[value_to] ?? ''}${
      appointment_type_names?.length
        ? appointment_type_names.length === 1
          ? ` / Appointment type - ${appointment_type_names.join(',')}`
          : ' / Multiple appointment types'
        : ''
    }`,
  txs_status_update: ({ status_name }) =>
    status_name?.length
      ? status_name.length === 1
        ? status_name[0]
        : ' - Multiple tx statuses'
      : '',
  custom_resource_insert: ({ custom_resource_type }) => custom_resource_type,
}

export default function Logic(props) {
  let events = useDataValue({
    context: 'events',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let tableData = useMemo(
    () => ({
      columns: [
        {
          Header: '',
          accessor: 'id',
          Cell: ({ row }) => (
            <TysiaCheckbox
              onChange={value => {
                change(next => {
                  if (value) {
                    next.selected.automations.push(row.original.id)
                  } else {
                    next.selected.automations =
                      next.selected.automations.filter(
                        item => item !== row.original.id
                      )
                  }
                })
              }}
              value={row.original.selected}
              viewPath={props.viewPath}
            >
              {childProps => <TysiaCheckboxInput {...childProps} />}
            </TysiaCheckbox>
          ),
        },
        {
          Header: 'Name',
          accessor: 'name',
          Cell: ({ value }) => value ?? '-',
        },
        {
          Header: 'Based On',
          accessor: row =>
            `${EVENT_TYPES[row.type](row.data)} ${EVENT_STATUS[row.type](
              row.data
            )}`,
        },
        {
          Header: 'Last Modified at',
          accessor: 'updated_at',
          Cell: ({ value }) => dateISOToDateAndTime(value),
        },
        {
          Header: 'Running',
          id: 'status',
          accessor: 'status',
          Cell: ({ row }) => (
            <ChangeStatusAction
              viewPath={`${props.viewPath}/TableX/TableActions`}
              isActive={row.original?.status === 'active'}
              disabledChangeStatus={row.original?.is_integration}
              onChange={event => {
                submit({
                  type: 'toggleStatus',
                  status:
                    row.original?.status === 'active' ? 'disabled' : 'active',
                  event_id: row.original?.id,
                  event_type: row.original?.type,
                  data: row.original?.data,
                  organization_id: row.original?.organization_id,
                  automation_type: row.original?.automation_type,
                })
              }}
            />
          ),
        },
      ],
      data: events ?? [],
    }),
    [events]
  )

  return <View viewPath={props.viewPath} tableData={tableData} />
}
