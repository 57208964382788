// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/Content/ActiveStep/Automation/AppointmentBookings/AppointmentType/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content_Content_ActiveStep_Automation_AppointmentBookings_AppointmentType($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      deleted: { _eq: false }
      organization: { _id: { _eq: $location_id } }
    }
    order_by: { full_name_computed: asc }
  ) {
    id
    appointment_type_id
    text: full_name_computed
    appointment_type {
      id
      _id
      name
    }
  }
}

`