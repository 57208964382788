import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let tx_card_id = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'treatment_card_id',
  })
  return {
    variables: { tx_card_id },
    pause: !tx_card_id,
  }
}
