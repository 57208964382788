import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_method_selection',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.value = next.originalValue
    })
  }
}
