import { useRef } from 'react'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'

import mutationApiAuthorize from './mutation-api-authorize.graphql.js'
import mutationDeleteConnection from './mutation-delete-connection.graphql.js'

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, executeApiAuthorizeMutation] = useMutation(mutationApiAuthorize)
  let [, executeDeleteConnectionMutation] = useMutation(
    mutationDeleteConnection
  )

  let [, notify] = useNotifications()

  let app_id = useDataValue({
    viewPath: props.viewPath,
    context: 'app',
    path: 'id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let connection_name = useDataValue({
    context: 'connection_definition',
    path: 'id',
    viewPath: props.viewPath,
  })
  let title = useDataValue({
    context: 'connection_definition',
    path: 'title',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let setIsConnecting = useDataChange({
    context: 'selected',
    path: 'is_connecting',
    viewPath: props.viewPath,
  })

  let authWindow = useRef(null)

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'connect': {
        setIsConnecting(true)

        let mutationResponse = await executeApiAuthorizeMutation({
          app_id,
          connection_id: null,
          connection_name,
          location_id,
          // TO DO: take this from dynamically generated connection configuration inputs
          // see: https://www.notion.so/getgreyfinch/App-Connections-Refactor-Proposal-96b3ef598e3e4db9b6880b42da3aae09?pvs=4
          connection_properties: {},
        })
        if (
          mutationResponse.error ||
          !mutationResponse.data.apps_external_api_authorize.id
        ) {
          setIsConnecting(false)
          return notify(
            notifyError(
              'An error occurred trying to connect the app. Please refresh the page and try again.'
            )
          )
        }

        if (mutationResponse.data.apps_external_api_authorize.url) {
          authWindow.current = window.open(
            mutationResponse.data.apps_external_api_authorize.url,
            'popup',
            `height=768,width=1024,top=${(window.innerHeight - 768) / 2},left=${
              (window.innerWidth - 1024) / 2
            }`
          )
        }

        return
      }

      case 'cancel': {
        try {
          authWindow.current.close()

          // delete newly created connection as not used anyway
          await executeDeleteConnectionMutation({
            connection_id: value[0].id,
          })
        } catch (_) {
        } finally {
          setTimeout(() => setIsConnecting(false), 100)
        }

        return
      }
      case 'active': {
        reExecuteQuery({ requestPolicy: 'network-only' })
        setIsConnecting(false)
        submit({ type: 'manage', app_id, connection_name, title })

        return notify(
          notifySuccess('The application was connected successfully')
        )
      }

      default: {
        return notify(notifyError('Invalid action'))
      }
    }
  }
}
