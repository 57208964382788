import { useDataValue } from 'Simple/Data.js'

export default function useGetDefaultTab(props, data) {
  return function ensureDefaultTab(maybeTab, id) {
    if (maybeTab) return maybeTab

    return {
      ...data,
      viewPath: props.viewPath,
    }
  }
}
