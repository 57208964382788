import { useDataValue } from 'Simple/Data.js'
import formatISO from 'date-fns/formatISO/index.js'
import parseISO from 'date-fns/parseISO/index.js'
import subDays from 'date-fns/subDays/index.js'
import startOfDay from 'date-fns/startOfDay/index.js'
import endOfDay from 'date-fns/endOfDay/index.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let locations = useDataValue({
    context: 'tab',
    path: 'selected.locations',
    viewPath: props.viewPath,
  })

  let selectedCampaigns = useDataValue({
    context: 'tab',
    path: 'selected.campaigns',
    viewPath: props.viewPath,
  })

  let selectedCampaignDateRange = useDataValue({
    context: 'tab',
    path: 'selected.range',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    // default start range
    let date_start = formatISO(startOfDay(subDays(new Date(), 30)))
    let date_end = formatISO(new Date())

    if (
      selectedCampaignDateRange.date_start &&
      selectedCampaignDateRange.date_end
    ) {
      date_start = formatISO(
        startOfDay(parseISO(selectedCampaignDateRange.date_start))
      )
      date_end = formatISO(
        endOfDay(parseISO(selectedCampaignDateRange.date_end))
      )
    }

    return {
      variables: {
        locations: `{${locations.join(',')}}`,
        campaign_ids: selectedCampaigns || [],
        date_start,
        date_end,
      },
      pause: !locations,
    }
  }, [
    locations,
    selectedCampaignDateRange.date_end,
    selectedCampaignDateRange.date_start,
    selectedCampaigns,
  ])
}
