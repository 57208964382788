import { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useDataValueOnce } from 'Logic/FlowShortcuts.js'
import View from './view.js'

export default function Logic(props) {
  let sync_event_id = useDataValueOnce({
    context: 'tab',
    path: 'sync.event_id',
    viewPath: props.viewPath,
  })
  let event_id = useDataValue({
    context: 'tab',
    path: 'selected.event_id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (sync_event_id && sync_event_id !== event_id) {
      submit({
        type: 'edit',
        event_id: sync_event_id,
      })
    }
  }, [event_id, sync_event_id])

  return <View viewPath={props.viewPath} {...props} />
}
