import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let colors = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.colors',
  })

  return useMemo(
    () => ({
      ...data,
      colors: colors ? colors.split(',') : [],
    }),
    [data, colors]
  )
}
