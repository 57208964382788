// this is an autogenerated file from DesignSystem/PaymentProvider/query-provider-location-account.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PaymentProvider($location_id: uuid!) {
  payments_provider_location_accounts_default_for_location(
    location_id: $location_id
  ) {
    id
    account_id
    provider
    status
  }
}

`