// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/TxCardFields/query-parent-company.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding_TxCardFields($id: uuid!) {
  vaxiom_sys_organizations(where: { _id: { _eq: $id } }) {
    _id
    xid
    sys_organizations {
      sys_organizations {
        id
        _id
        name
        level
      }
    }
  }
}

`