import { useDataChange, useDataValue } from 'Simple/Data.js'

export function isDisabled(length, index) {
  return !(index + 1 !== length)
}

export function useOnClick(props) {
  let current = useDataValue({
    context: 'current_row',
    path: 'item',
    viewPath: props.viewPath,
  })

  let order = useDataValue({
    context: 'selected',
    path: 'ordered_rows',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'current_row',
    path: 'item',
    viewPath: props.viewPath,
  })

  return function onClick() {
    let nextPos = order.indexOf(current.id) + 1
    change({ index: nextPos, id: order[nextPos] })
  }
}
