import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return Array(31)
      .fill()
      .map((n, index) => ({
        id: index + 1,
        label: `${index + 1}`,
      }))
  }, [])
}
