import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let submit = useDataSubmit({
    context: 'appointment_actions',
    viewPath: props.viewPath,
  })

  return async function onSubmit() {
    await submit({ type: 'deleteAppointment' })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
