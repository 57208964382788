import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let location_id = useDataValue({
    context: 'ruleset',
    path: '_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_id,
    },
    pause: !location_id,
  }
}
