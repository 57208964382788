// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/EditPreferences/Content/Main/Content/Account/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_EditPreferences_Content_Main_Content_Account($connection_id: uuid!, $location_id: uuid!) {
  apps_align_itero_accounts(
    connection_id: $connection_id
    location_id: $location_id
  ) {
    accounts {
      id
      name
    }
  }
}

`