import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let appointment_id = useDataValue({
    context: 'appointment_booking',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onClick(event) {
    event.stopPropagation()

    submit({
      type: 'tabs/add',
      tab: {
        id: 'Calendar',
        name: 'Calendar',
        type: 'Calendar',
        viewPath: '/App/Account/Content/Calendar',
        coreParams: {
          appointment_id,
          section: 'calendar',
        },
      },
    })
  }
}
