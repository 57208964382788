// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Email/NewEmail/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Email_NewEmail_Content($address: String!, $person_id: numeric!) {
  vaxiom_contact_emails(where: { address: { _eq: $address } }) {
    id
  }

  self: vaxiom_contact_emails(
    where: {
      address: { _eq: $address }
      contact_method: { association: { person_id: { _eq: $person_id } } }
    }
  ) {
    id
  }
}

`