import { APP_ENV } from './constants.js'

export function isCoreEmbedMessage(event) {
  if (APP_ENV === 'development') {
    if (event.data.fakeDevelopmentCoreEmbedMessage) {
      return true
    }
  }

  return event.origin?.endsWith(
    process.env.REACT_APP_CORE_EMBED_APP_URL.replace(
      'https://embed-app',
      'users'
    )
  )
}
