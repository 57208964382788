import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnDelete(props) {
  let change = useDataChange({
    context: 'resource',
    viewPath: props.viewPath,
  })
  let temporary_image_index = useDataValue({
    context: 'temporary_image_item',
    path: 'index',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onDelete() {
    change(next => {
      next.captures = next.captures.filter(
        (_, index) => index !== temporary_image_index
      )
    })

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
