// this is an autogenerated file from DesignSystem/EditAutomation/AppointmentType/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_AppointmentType_Content($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      deleted: { _eq: false }
      organization: { _id: { _eq: $location_id } }
    }
    order_by: { full_name_computed: asc }
  ) {
    id
    appointment_type_id
    text: full_name_computed
    appointment_type {
      id
      _id
      name
    }
  }
}

`