// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/Frequencies/Rulesets/Content/Actions/Content/SaveAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_Frequencies_Rulesets_Content_Actions_Content_SaveAction(
  $rulesets_to_delete: [uuid!]!
  $rulesets_to_insert: [treatments_payment_plan_frequencies_insert_input!]!
  $rulesets_to_update: [treatments_payment_plan_frequencies_updates!]!
) {
  delete_treatments_payment_plan_frequencies(
    where: { id: { _in: $rulesets_to_delete } }
  ) {
    affected_rows
    returning {
      id
    }
  }
  insert_treatments_payment_plan_frequencies(objects: $rulesets_to_insert) {
    affected_rows
    returning {
      id
      organization_id
      parent_id
      division_id
      location_id
      type
      label
      allowed_dates
      document_template_id
    }
  }
  update_treatments_payment_plan_frequencies_many(
    updates: $rulesets_to_update
  ) {
    affected_rows
    returning {
      id
      organization_id
      parent_id
      division_id
      location_id
      type
      label
      allowed_dates
      document_template_id
    }
  }
}

`