let STATES_BY_TAB = {
  all: ['UNSEATED', 'CHECKED_OUT'],
  unseated: ['UNSEATED'],
  checked_out: ['CHECKED_OUT'],
}

export function filterByActiveTab(tab, appointment_bookings) {
  let states = STATES_BY_TAB[tab]
  return appointment_bookings.filter(booking => states.includes(booking.state))
}
