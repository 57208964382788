import React from 'react'

let DEFAULT_FEATURE = 'simple-tools-enabled'
export async function isEnabled(feature = DEFAULT_FEATURE) {
  if (
    process.env.REACT_APP_ENV === 'development' &&
    feature === DEFAULT_FEATURE
  ) {
    return true
  }

  return _isEnabled(feature)
}

function _isEnabled(feature) {
  try {
    return window.localStorage.getItem(feature) === 'true'
  } catch (_) {
    // To avoid "SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.""
    return false
  }
}

export function getInstanceId() {
  let id = window.localStorage.getItem('simple-tools-instance-id')
  if (!id) {
    let random = `${Math.random()}}`.substring(2, 5)
    id = `${window.location.hostname}-${random}`
    window.localStorage.setItem('simple-tools-instance-id', id)
  }
  let tabId = window.sessionStorage.getItem('simple-tools-instance-tab-id')
  if (!tabId) {
    tabId = `${Math.random()}}`.substring(2, 5)
    window.sessionStorage.setItem('simple-tools-instance-tab-id', tabId)
  }
  return `${id}-${tabId}`
}

export function Button(props) {
  return (
    <button
      style={{
        position: 'fixed',
        top: 4,
        left: 4,
        zIndex: 1111,
        backgroundColor: '#e67c73',
        color: '#ffffff',
        paddingTop: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        cursor: 'pointer',
      }}
      className="views-block"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  )
}

export let EventTarget = window.EventTarget
export let Event = window.Event
export let CustomEvent = window.CustomEvent
