import parseISO from 'date-fns/parseISO'
import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export default function useOnSubmit(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ value }) {
    if (!value || !value.date || !value.name) return true

    let series_date
    try {
      series_date = new Date(value.date)
    } catch (err) {
      // invalid date
      return true
    }

    let mutationResponse = await executeMutation({
      patient_id,
      name: value.name,
      series_date: series_date || new Date(),
      treatment_id: value.treatment_id || null,
      appointment_id: value.appointment_id || null,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't save the image series`))
    }
    notify(notifySuccess(`Image series successfully created`))
    change(mutationResponse.data.insert_vaxiom_patient_imageseries_one.id)
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
