export function getActions(value) {
  return [
    { id: 'overview', title: 'Overview' },
    ...value
      .filter(item => !item.overview)
      .map(item => ({
        id: item.id,
        title: item.title,
        kpi: item,
      })),
  ]
}
