import React from 'react'
import View from './view.js'
import useIsHovered from 'Logic/useIsHovered.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
    onMouseEnterDelay: 500,
  })

  return (
    <View
      hovered={hovered}
      onMouseEnterManual={hoverBind.onMouseEnter}
      onMouseLeaveManual={hoverBind.onMouseLeave}
      {...props}
      viewPath={props?.dsViewPath || props.viewPath}
    />
  )
}
