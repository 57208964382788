import { useDataValue, useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let manual_actions = useDataValue({
    context: 'manual_action_executions',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'manual_action_executions',
    viewPath: props.viewPath,
  })
  return async function onSubmit({ value, originalValue, args, change }) {
    if (value.index < manual_actions.length - 1) {
      return change(manual_actions[value.index + 1])
    } else {
      return submit()
    }
  }
}
