import { useDataChange, useDataValue } from 'Simple/Data.js'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let setSelectedLocations = useDataChange({
    context: 'selected',
    path: 'locations',
    viewPath: props.viewPath,
  })

  return <View onClick={onChange} viewPath={props.viewPath} />

  function onChange(next) {
    setSelectedLocations(
      next.map(item => locations.find(({ id }) => id === item))
    )
  }
}
