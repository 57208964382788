import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'comms',
    path: 'thread_id',
    viewPath: props.viewPath,
  })

  return () => {
    change(null)
  }
}
