import React, { Suspense } from 'react'
import View from './view.js'

export default function SuspenseBoundary(props) {
  return (
    <Suspense fallback={<View viewPath={props.viewPath} />}>
      {typeof props.children === 'function' ? props.children() : props.children}
    </Suspense>
  )
}
