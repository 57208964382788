import { useMemo } from 'react'
import { dateISOToDateAndTime } from 'Data/format.js'
import { useDataValue } from 'Simple/Data.js'
import {
  getType,
  getAlertValue,
  getPersonFullName,
  getTimestamp,
  getValue,
} from './helpers.js'

export default function useDataTransform(props, data) {
  let patient = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
  })
  return useMemo(() => {
    if (!data) return data

    let medical_alerts = data.medical_histories_questions
      .filter(
        question =>
          (question.category !== 'habits' && question.category !== 'general') ||
          question.type === 'boolean'
      )
      .flatMap(question => {
        let answer = question.answers[0]
        let response = answer?.value ?? ''

        return {
          id: question.id,
          name: question.name,
          type: getType(question),
          createdByPerson: getPersonFullName(answer?.patient?.person),
          createdDate: dateISOToDateAndTime(answer?.created_at),
          lastModified: dateISOToDateAndTime(answer?.created_at),
          timestamp: getTimestamp(answer?.created_at),
          createdFrom: 0,
          alert: getAlertValue(answer?.alert, response),
          value: getValue(response, question.type, answer?.alert),
        }
      })
      .filter(alertDTO => alertDTO.alert === true)

    let notes = data.vaxiom_notes
      .flatMap(item => {
        let { note = '' } = item ?? {}

        return {
          id: item.id,
          name: note,
          type: getType(item),
          createdByPerson: getPersonFullName(item?.author_user?.person),
          createdDate: dateISOToDateAndTime(item?.sys_created),
          lastModified: dateISOToDateAndTime(item?.sys_created),
          timestamp: getTimestamp(item?.sys_created),
          createdFrom: 0,
          alert: item.alert,
        }
      })
      .filter(noteDTO => noteDTO.alert === true)

    return [...medical_alerts, ...notes].sort(
      (a, b) => b.timestamp - a.timestamp
    )
  }, [data, patient])
}
