// this is an autogenerated file from App/Auth/Token/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_Token($token: String!, $xid: String) {
  vaxiom_users_auth_with_token(token: $token, xid: $xid) {
    status
    access_token: jwt_token
    refresh_token
  }
}

`