import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      connection_id,
      location_id,
    },
    pause: !connection_id || !location_id,
  }
}
