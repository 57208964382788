import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person._id',
  })

  return {
    variables: {
      person_id,
    },
    pause: !person_id,
  }
}
