import { useClient, useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import { equals } from 'Data/aggregate.js'
import { useDataSubmit } from 'Simple/Data.js'

export default function useDataOnSubmit(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let submit = useDataSubmit({
    context: 'medical_history',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, change, args }) {
    switch (args?.type) {
      case 'cancel': {
        change(originalValue)
        submit({ type: 'cancel' })
        return
      }

      default: {
        if (
          !Array.isArray(value?.changes) ||
          !value.changes?.length ||
          equals(value?.changes, originalValue?.changes)
        )
          return

        let answers = getAnswersToBeStored(value.changes)

        let mutationResponse = await executeMutation({
          answers,
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        submit()

        change(next => {
          next.changes = []
          next.has_changes = false
        })

        notify(notifySuccess(`The answers were updated successfully`))
      }
    }
  }
}

/**
 * {
 *     "id": "79e88243-8430-4a30-8a6b-e025308dd0931",
 *     "name": "AIDS or HIV infection",
 *     "type": "boolean",
 *     "alert_type": "any",
 *     "alert_conditions": null,
 *     "options": [
 *         "ADD or ADHD",
 *         "AIDS or HIV infection",
 *         "Anemia",
 *         "Arthritis",
 *         "Artificial bones/joints/valves",
 *         "Asthma",
 *         "Autism or sensory",
 *         "Blood disorders",
 *         "Brain injury",
 *         "Cancer",
 *         "Cardiac conditions",
 *         "Congenital heart defect",
 *         "Diabetes",
 *         "Dizziness or fainting",
 *         "Drug or alcohol abuse",
 *         "Emphysema",
 *         "Epilepsy or convulsions",
 *         "Herpes",
 *         "Frequently tired",
 *         "Glaucoma",
 *         "Handicaps or disabilities",
 *         "Hearing impairment",
 *         "Heart attack",
 *         "Heart murmur",
 *         "Heart surgery or pacemaker",
 *         "Hemophilia or abnormal bleeding",
 *         "Hepatitis or jaundice",
 *         "High blood pressure",
 *         "Kidney diseases",
 *         "Liver disease",
 *         "Low blood pressure",
 *         "Mitral valve prolapse",
 *         "Psychiatric problem",
 *         "Radiation therapy",
 *         "Respiratory problems",
 *         "Rheumatic scarlet fever",
 *         "Seizures",
 *         "Shingles",
 *         "Sickle cell disease",
 *         "Stomach troubles or ulcers",
 *         "Stroke",
 *         "Thyroid problem",
 *         "Tuberculosis"
 *     ],
 *     "order": 24,
 *     "__typename": "medical_histories_questions",
 *     "parent_id": "79e88243-8430-4a30-8a6b-e025308dd093",
 *     "parent_type": "multichoice",
 *     "parent_name": "Medical Conditions",
 *     "parent_answer": [
 *         "Arthritis",
 *         "Artificial bones/joints/valves"
 *     ],
 *     "answer": true,
 *     "alert": true,
 *     "question_id": "79e88243-8430-4a30-8a6b-e025308dd0931",
 *     "parent_question_id": "79e88243-8430-4a30-8a6b-e025308dd093",
 *     "patient_id": "99f925df-5754-4e9c-8792-2c983b3b54f2",
 *     "value": {
 *         "response": true
 *     }
 * }
 * */
export function getAnswersToBeStored(changes) {
  let answersDict = changes?.reduce(
    (
      answers,
      {
        answer,
        name,
        parent_id,
        patient_id,
        alert,
        options,
        id,
        parent_answer,
        parent_alert,
      }
    ) => {
      // Habit or Medical Condition
      if (parent_id) {
        if (!answers[parent_id]) {
          answers[parent_id] = {
            question_id: parent_id,
            patient_id: patient_id,
            value: getResponse({ answer, name, parent_answer }),
            alert: getAlert({ parent_alert, name, alert, options }),
          }
        } else {
          answers[parent_id] = {
            ...answers[parent_id],
            value: getResponse({
              parent_answer: answers[parent_id].value,
              answer: answer,
              name: name,
            }),
            alert: getAlert({
              parent_alert: answers[parent_id].alert,
              name: name,
              alert: alert,
              options: options,
            }),
          }
        }
      } else {
        answers[id] = {
          question_id: id,
          patient_id: patient_id,
          value: answer,
          alert: alert,
        }
      }

      return answers
    },
    {}
  )

  return Object.values(answersDict)
}

function getAlert({ parent_alert, alert, options, name }) {
  if (parent_alert === true && alert === false) {
    return options.filter(item => item !== name)
  } else if (parent_alert === false && alert === true) {
    return [name]
  } else if (Array.isArray(parent_alert)) {
    if (alert === true) {
      if (!parent_alert.includes(name)) return [...parent_alert, name]

      return parent_alert
    } else {
      return parent_alert.filter(item => item !== name)
    }
  }

  return alert
}
function getResponse({ parent_answer, answer, name }) {
  if (Array.isArray(parent_answer) && answer === true) {
    if (!parent_answer.includes(name)) {
      return [...parent_answer, name]
    }

    return parent_answer
  } else if (Array.isArray(parent_answer) && answer === false) {
    if (!parent_answer.includes(name)) {
      return parent_answer
    }

    return parent_answer.filter(item => item !== name)
  }

  return [name]
}
