import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let xid = useDataValue({
    context: 'payment',
    path: 'xid',
    viewPath: props.viewPath,
  })

  return {
    variables: { xid },
  }
}
