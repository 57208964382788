// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Images/Misc/Content/PatientImage/Content/Content/SendSecurely/Content/Recipients/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Images_Misc_Content_PatientImage_Content_Content_SendSecurely_Content_Recipients_Content($person_id: numeric!) {
  thread: comms_threads_in_app_create(person_id: $person_id) {
    id
  }
}

`