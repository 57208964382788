import { useEffect, useMemo, useState, useRef } from 'react'
import escapeStringRegexp from 'escape-string-regexp'
import useOnClickOutside from 'use-onclickoutside'

export default function useDropdown(props) {
  let [isShowing, setIsShowing] = useState(false)
  let [valueFilter, onChangeFilter] = useState('')
  let dropdownRef = useRef()
  let isMultiChoice = Array.isArray(props.selected)

  function getId(item) {
    return props.id ? item[props.id] : item?.id ?? item?._id
  }

  let selected =
    isMultiChoice &&
    typeof props.selected[0] === 'object' &&
    props.selected[0] !== null
      ? props.selected.map(item => getId(item))
      : props.selected

  let isSelected = isMultiChoice
    ? item => selected.includes(getId(item))
    : item => selected === getId(item)
  let field = props.field || 'text'

  let { isEmpty, isFilterable, hasMany, hasSelectedAll, value } =
    useMemo(() => {
      let isEmpty = true
      let isFilterable = false
      let hasMany = false
      let hasSelectedAll = false
      let value = null

      if (props.from) {
        isEmpty = props.from.length === 0
        hasMany = props.hasMany || props.from.length > 1

        value = props.from
          .filter(isSelected)
          .map(item => item[field])
          .join(', ')

        if (!value) {
          if (isEmpty) {
            value = '-'
          } else if (hasMany) {
            value = 'None selected'
          } else {
            let item = props.from[0]
            value = item[field]
          }
        }

        isFilterable = props.from.length > 10

        if (isMultiChoice) {
          hasSelectedAll = props.from.length === props.selected.length
        }
      }

      return {
        isEmpty,
        isFilterable,
        hasMany,
        hasSelectedAll,
        value,
      }
    }, [props.from, selected]) // eslint-disable-line
  // ignore field and isSelected

  useEffect(() => {
    if (!props.from || props.from.length !== 1) return

    let valueSelected = props.from
      .filter(isSelected)
      .map(item => item[field])
      .join(', ')

    if (valueSelected) return

    let item = props.from[0]
    if (
      value === item[field] &&
      !props.skipCallingOnClickWhenMoreThanOneItemAndNoneSelected
    ) {
      props.onClick(getId(item))
    }
  }, [props.from, selected]) // eslint-disable-line

  let fromMaybeFiltered = useMemo(() => {
    if (valueFilter === '') return props.from

    let valueFilterRegex = new RegExp(escapeStringRegexp(valueFilter), 'i')

    return props.from.filter(item => valueFilterRegex.test(item[field]))
  }, [props.from, valueFilter]) // eslint-disable-line
  // ignore field

  function onClickHide(event) {
    setIsShowing(false)
    onChangeFilter('')
  }
  function onClickShow() {
    setIsShowing(true)
  }

  useOnClickOutside(dropdownRef, isShowing && onClickHide)

  return {
    from: fromMaybeFiltered,
    isMultiChoice,
    isFilterable,
    isSelected,
    isShowing,
    hasSelectedAll,
    toggleIsShowing: e => {
      e.stopPropagation()
      setIsShowing(v => !v)
    },
    onClickShow,
    onClickHide,
    dropdownRef,
    isEmpty,
    hasMany,
    onBlur: event => {
      if (props.onBlur) {
        props.onBlur(event)
      }

      if (!isMultiChoice) {
        onClickHide()
      }
    },
    onClick: id => {
      let next = id
      if (isMultiChoice) {
        next = selected.includes(id)
          ? selected.filter(sid => sid !== id)
          : [...selected, id]
      }
      props.onClick(next)

      if (!isMultiChoice) {
        onClickHide()
      }
    },
    onClickToggleSelectAll: () => {
      props.onClick(
        hasSelectedAll ? [selected[0]] : props.from.map(item => getId(item))
      )
    },
    value: isShowing && isFilterable ? valueFilter : value,
    onChangeFilter,
  }
}
