import { useDataValue, useDataChange } from 'Simple/Data.js'

export function getName(person) {
  return `${person.first_name} ${person.last_name}, ${person.gender}, ${person.birth_date}`
}

export function getEmail(contact_method_associations) {
  return contact_method_associations.find(cm => cm.email)?.email?.address || '-'
}

export function getPhone(contact_method_associations) {
  return contact_method_associations.find(cm => cm.phone)?.phone?.number || '-'
}

export function useOnClick(props) {
  let value = useDataValue({
    context: 'person',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'replace',
    viewPath: props.viewPath,
    path: 'selected_patient',
  })
  return function onClick() {
    let phone = '-',
      email = '-',
      zip = '-'

    value.contact_method_associations.forEach(cm => {
      if (cm.email) {
        email = cm.email.address
      }
      if (cm.phone) {
        phone = cm.phone.number
      }
      if (cm.postal_address) {
        zip = cm.postal_address.zip
      }
    })

    let formattedPatient = {
      first_name: value.first_name,
      last_name: value.last_name,
      gender: value.gender,
      birth_date: value.birth_date,
      profile_pic_url: value.profile_pic_url,
      id: value.patient._id,
      phone,
      email,
      zip,
    }

    change(formattedPatient)
  }
}
