import { useMemo } from 'react'
import { personPhone } from 'Data/format.js'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'send_to_person_id',
  })

  return useMemo(() => {
    if (!person_id) return []

    if (!data) return data

    return [
      { id: 'InApp', text: 'Send securely', type: 'InApp', value: null },
      { id: 'Email', text: 'Send email', type: 'Email', value: null },
      { id: 'QrCode', text: 'Show QR code', type: 'QrCode', value: null },
      ...data
        .filter(item => item.contact_method.mobile_phone?.number)
        .map(item => ({
          id: item.id,
          type: 'Phone',
          value: item.contact_method.mobile_phone.number,
          text: [
            `Send SMS to ${personPhone(item.contact_method.mobile_phone)}`,
            item.description ? `(${item.description})` : null,
          ]
            .filter(Boolean)
            .join(' '),
        })),
    ]
  }, [data, person_id])
}
