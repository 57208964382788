import { useDataValue, useDataSubmit } from 'Simple/Data'
import { patientName } from 'Data/format.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let lead = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'lead',
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(value) {
    setFlowTo(normalizePath(props.viewPath, '../../../No'))

    if (!lead.patient_id) return

    submit({
      type: 'tabs/add',
      tab: {
        id: lead.patient._id,
        type: 'Patient',
        name: patientName(lead.patient.person),
        tab: 'profile',
        viewPath: '/App/Account/Content/Patients',
        patient_id: lead.patient._id,
      },
    })
  }
}
