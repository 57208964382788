// this is an autogenerated file from App/Account/Topbar/Tasks/PendingCounter/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Topbar_Tasks_PendingCounter($location_id: uuid!) {
  tasks_user_location_pending_tasks(
    where: { location_id: { _eq: $location_id } }
  ) {
    id
    has_pending_tasks
  }
}

`