import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let name = useDataValue({
    context: 'charge',
    path: 'name',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data?.length || data.some(item => item.name === name)) return data
    return [{ id: name, text: name, name }, ...data]
  }, [data])
}
