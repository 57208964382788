import { useDataValue } from 'Simple/Data'
import { useQuery } from 'Data/Api'
import useDataConfiguration from './useDataConfiguration'
import query from './query.graphql.js'

export default function FeatureFlag(props) {
  let isSupport = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.is_support',
  })
  let configuration = useDataConfiguration(props)
  let [{ data }] = useQuery({ query, ...configuration })

  return (isSupport && props.support) ||
    data?.vaxiom_application_properties?.[0]?.value === 'true'
    ? typeof props.children === 'function'
      ? props.children(props)
      : props.children
    : null
}
