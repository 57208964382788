// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Phone/Phones/Content/Phone/Delete/Content/Actions/DeleteAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Phone_Phones_Content_Phone_Delete_Content_Actions_DeleteAction($association_id: numeric!) {
  delete_vaxiom_contact_method_associations_by_pk(id: $association_id) {
    id
  }
}

`