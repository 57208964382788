// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/PaymentsTreatmentPlan/Content/TreatmentPayments/mutation_edit_payment_plan.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_PaymentsTreatmentPlan_Content_TreatmentPayments($payment_plan_id: numeric!, $token: jsonb!) {
  payments_add_payment_plan_autopay(
    payment_plan_id: $payment_plan_id
    token: $token
  ) {
    payment_plan_id
    verified
    verification_metadata
  }
}

`