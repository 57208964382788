// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/mutation_unseat.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions($id: uuid!, $skip_required_fields: Boolean!) {
  vaxiom_appointment_booking_unseat(
    id: $id
    skip_required_fields: $skip_required_fields
  ) {
    status
  }
}

`