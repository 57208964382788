let VIEW_NAME = {
  boolean: 'BooleanInput',
  choice: 'SelectorInput',
  choiceCustom: 'SelectorCustomInput',
  date: 'DateInput',
  group: 'SelectorInput',
  list: 'SelectorInput',
  multichoice: 'ListInput',
  multiChoiceCustom: 'MultiChoiceCustomInput',
  number: 'NumberInput',
  text: 'TextInput',
  textMultiLine: 'TextMultiLineInput',
}

export function getView(value) {
  return VIEW_NAME[value] ?? 'No'
}
