// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/AppliedPayments/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_AppliedPayments($patient_id: uuid!) {
  vaxiom_applied_payments(
    where: { payment: { patient: { _id: { _eq: $patient_id } } } }
    order_by: { transaction: { sys_created: desc } }
  ) {
    id
    payment_id
    applied_type
    transaction {
      id
      dtype
      effective_date
      notes
      amount
      receivable_id
      # payment_correction_detail {
      #   id
      #   note
      #   correction_type_id
      # }
    }
  }
}

`