// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/mutation-create-automations.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content(
  $connection_name: String!
  $connection_level: connection_levels!
  $location_id: uuid!
  $app_id: uuid!
  $events: [jsonb!]!
) {
  events_events_integration_automation_create(
    connection_name: $connection_name
    connection_level: $connection_level
    location_id: $location_id
    events: $events
    app_id: $app_id
  ) {
    ids
  }
}

`