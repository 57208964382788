import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changePayment = useDataChange({
    context: 'payment',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    changePayment(next => {
      next.method = props.method
      next.add_new = false
    })
  }
}
