import { useClient } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data'
import { notifyError, useNotifications } from 'Logic/Notifications'
import { toJpeg } from 'html-to-image'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import query_print_image_series from './query_print_image_series.graphql.js'
import { downloadUrl } from 'Logic/downloadUrl.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let layout = useDataValue({
    context: 'tab',
    path: 'selected.images.layout',
    viewPath: props.viewPath,
  })

  let client = useClient()
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value }) {
    if (!image_series_id || !layout) return

    setFlowTo(normalizePath(props.viewPath, 'Loading/Content'))
    let response = await client
      .query(query_print_image_series, {
        image_series_id,
        layout,
      })
      .toPromise()
    if (response.error) {
      return notify(notifyError(`Couldn't download the selected layout`))
    }
    let element = document.createElement('div')
    try {
      let content = await fetch(
        response.data.vaxiom_patient_image_series_print.url
      ).then(res => res.text())
      element.innerHTML = content
      document.body.appendChild(element)
      let width = element.getElementsByTagName('table')[0].clientWidth
      element.style.width = `${width}px`
      element.style.fontFamily = 'Inter'
      let dataUrl = await toJpeg(element, {
        backgroundColor: '#ffffff',
        width,
      })
      downloadUrl({
        url: dataUrl,
        file_name: `${layout}.jpeg`,
      })
    } catch (err) {
      notify(notifyError(`Couldn't download the selected layout`))
    } finally {
      document.body.removeChild(element)
      setFlowTo(normalizePath(props.viewPath, 'Loading/No'))
      setFlowTo(normalizePath(props.viewPath, '../../../No'))
    }
  }
}
