import { useDataValue, useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let tab = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'tabs/select',
      tab,
    })
  }
}

export function useOnRemove(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let tab = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'tabs/remove',
      tab,
    })
  }
}
