import React, { useEffect, useRef } from 'react'
import { useDataSubmit } from 'Simple/Data.js'
import View from './view.js'

let SESSION_EXPIRED_RETRY_STATUSES = {
  FIRST_RENDER: 'FIRST_RENDER',
  CAN_RETRY: 'CAN_RETRY',
  ALREADY_RETRIED: 'ALREADY_RETRIED',
}

export default function Logic(props) {
  let changeError = useDataSubmit({
    context: 'core_embedded_section',
    path: 'error',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'core_embedded_section',
    viewPath: props.viewPath,
  })
  let sessionExpiredRetryStatus = useRef(
    SESSION_EXPIRED_RETRY_STATUSES.FIRST_RENDER
  )

  useEffect(() => {
    // submit({ type: 'login' })
    return () => {
      submit({ type: 'logout' })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submit

  useEffect(() => {
    submit({ type: 'refresh' })
  }, [props.refresh]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submit

  // Auto-retry once if refresh was sent here
  useEffect(() => {
    switch (sessionExpiredRetryStatus.current) {
      case SESSION_EXPIRED_RETRY_STATUSES.FIRST_RENDER: {
        sessionExpiredRetryStatus.current =
          SESSION_EXPIRED_RETRY_STATUSES.CAN_RETRY
        break
      }

      case SESSION_EXPIRED_RETRY_STATUSES.CAN_RETRY: {
        sessionExpiredRetryStatus.current =
          SESSION_EXPIRED_RETRY_STATUSES.ALREADY_RETRIED
        // TODO: logout
        submit({ type: 'refresh' })
        break
      }

      case SESSION_EXPIRED_RETRY_STATUSES.ALREADY_RETRIED: {
        changeError(true)
        break
      }

      default: {
        break
      }
    }
  }, [props.sessionExpiredRefresh, props.viewPath]) // eslint-disable-line
  // ignore changeError and submit

  return <View {...props} onClickRetry={onClickRetry} />

  async function onClickRetry() {
    sessionExpiredRetryStatus.current = SESSION_EXPIRED_RETRY_STATUSES.CAN_RETRY
    submit({ type: 'get_url' })
  }
}
