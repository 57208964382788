// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/PatientSelect/Content/Content/Existing/AppointmentSelect/Content/Content/Selected/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_PatientSelect_Content_Content_Existing_AppointmentSelect_Content_Content_Selected_Content($appointment_id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $appointment_id } }) {
    id
    type {
      id
      full_name
      color_id
    }
    tx {
      id
      tx_card {
        id
        name
      }
    }
    booking {
      id
      local_start_date
      local_start_time
      duration
      state
    }
  }
}

`