import View from './view.js'
import React from 'react'
import { useFlow } from 'Simple/Flow.js'

export default function Logic(props) {
  let flow = useFlow()
  return (
    <View
      {...props}
      isSelected={props.isSelected || flow.has(props.onClickId)}
    />
  )
}
