import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })
  let payment_methods = useDataValue({
    context: 'post_payment_info',
    path: 'payment_methods',
    viewPath: props.viewPath,
  })

  return function onChange(next_id) {
    let payment_method = payment_methods?.find(i => i.id === next_id)

    if (!payment_method) return

    change(next => {
      next.payment_method.selected_id = next_id
      next.payment_method.data = payment_method.data
    })
  }
}
