import React, { useEffect } from 'react'
import View from './view.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { useDataValue } from 'Simple/Data'
import { notifyError, useNotifications } from 'Logic/Notifications'

export default function Logic(props) {
  let requestId = useDataValue({
    context: 'selected',
    path: 'request_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  async function updateRequestStatus() {
    try {
      let res = await executeMutation({
        id: requestId,
      })

      if (res.error) {
        notify(notifyError(`There was an error performing the re analysis`))
      }
    } catch (error) {
      notify(notifyError(`There was an error performing the re analysis`))
    }
  }

  useEffect(() => {
    function calibrationToolListener(event) {
      let { id = '' } = event?.data
      // Ignore all events where the origin is not wedoceph.com
      if (
        ['wedoceph.com', 'greyfinch.com', 'localhost'].every(
          origin => !event.origin.includes(origin)
        ) ||
        !['editAnalysisMessageSaveOK', 'editAnalysisMessageCancel'].includes(id)
      ) {
        return
      }

      if (id === 'editAnalysisMessageSaveOK') {
        // Update the request status to processing
        updateRequestStatus()
      }

      setFlowTo(normalizePath(props.viewPath, '../No'))
    }
    window.addEventListener('message', calibrationToolListener)

    return function unsubscribe() {
      window.removeEventListener('message', calibrationToolListener)
    }
  }, [])

  return <View {...props} />
}
