import React from 'react'
import { DataProvider, useDataValue, useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { useDwt } from 'DesignSystem/DWT/useDwt'
import { useUppy } from 'DesignSystem/FileUploader/react.js'

import View from './view.js'

export default function Logic(props) {
  let uppy = useUppy(props)
  let {
    sources,
    currentSource,
    isLoading,
    isAcquiring,
    onSourceChange,
    onImagesAcquire,
  } = useDwt({
    containerId: 'dwtControlContainerId',
    convertMultiPageToPdf: true,
    onImagesAcquired,
  })
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let parent_id = useDataValue({
    context: 'patient_documents',
    path: 'parent_id',
    viewPath: props.viewPath,
  })
  let setUploading = useDataChange({
    context: 'patient_documents',
    path: 'isUploading',
    viewPath: props.viewPath,
  })
  let setRefetch = useDataChange({
    context: 'patient_documents',
    path: 'refetch',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeCreateDocumentMutation] = useMutation(mutation)

  let value = {
    sources: sources.map(source => ({ id: source, name: source })),
    currentSource,
    isLoading,
    isAcquiring,
  }

  return (
    <DataProvider context="dwt" value={value} viewPath={props.viewPath}>
      <View
        {...props}
        onSourceChange={onSourceChange}
        onImagesAcquire={onImagesAcquire}
      />
    </DataProvider>
  )

  async function onImagesAcquired(images) {
    setUploading(true)

    for (let image of images) {
      try {
        uppy.addFile(image)
      } catch (err) {
        if (!err.isRestriction) {
          uppy.log(err)
          uppy.info(
            'There was an error when trying to add your files. Please try again.',
            'error',
            3000
          )
        }
      }
    }

    await uppy.upload()

    let attachments = uppy.getFiles()

    let results = await Promise.allSettled(
      attachments.map(async attachment => {
        let mutationCreateDocumentResponse =
          await executeCreateDocumentMutation({
            patient_id,
            parent_id,
            name: attachment.name,
            file_uid: attachment.meta.location.substring(
              attachment.meta.location.lastIndexOf('/') + 1
            ),
          })
        if (mutationCreateDocumentResponse.error) {
          throw mutationCreateDocumentResponse.error
        }
      })
    )

    setUploading(false)

    let hasFailedAttachment = results.some(
      result => result?.status !== 'fulfilled'
    )
    if (hasFailedAttachment) {
      return notify(
        notifyError(
          'There was a problem storing the selected patient documents. Please try again.'
        )
      )
    }

    setRefetch(Date.now())
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
