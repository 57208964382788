import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(person => {
      let { id, first_name, last_name, contact_method_associations } = person
      let contact_method_association = contact_method_associations?.[0]
      let city = contact_method_association?.contact_method?.city
      let state = contact_method_association?.contact_method?.state
      return {
        id: id,
        value: `${first_name} ${last_name} ${[city, state]
          .filter(Boolean)
          .join(', ')}`,
      }
    })
  }, [data])
}
