import { useDataChange, useDataValue } from 'Simple/Data'
import { useClient } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications'
import { generateSignedUrl } from 'Data/s3'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useAwsS3Client } from 'Data/s3'
import query_print_image from './query_print_image.graphql.js'
import query_print_image_series from './query_print_image_series.graphql.js'

export function useOnClick(props) {
  let image = useDataValue({
    context: 'resource_object_meta',
    viewPath: props.viewPath,
  })
  let image_id = useDataValue({
    context: 'patient_image',
    path: 'id',
    viewPath: props.viewPath,
  })
  let image_uuid = useDataValue({
    context: 'patient_image',
    path: '_id',
    viewPath: props.viewPath,
  })
  let slot_key = useDataValue({
    context: 'patient_image',
    path: 'slot_key',
    viewPath: props.viewPath,
  })
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'comparison',
    viewPath: props.viewPath,
  })
  let changeSelected = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let client = useClient()
  let [, notify] = useNotifications()
  let s3 = useAwsS3Client(props)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick(value) {
    switch (value) {
      case 'SEND_SECURELY':
        return sendSecurely()
      case 'EDIT':
        return editImage()
      case 'PRINT':
        return print()
      case 'COMPARE':
        return compareImages()
      case 'DOWNLOAD':
        return download()
      case 'DELETE':
        return deleteImage()
      case 'ANALYZE':
        return analyzeCeph()
      default:
        return
    }
  }

  function deleteImage() {
    setFlowTo(normalizePath(props.viewPath, 'DeleteAction/Content'))
  }

  function editImage() {
    setFlowTo(normalizePath(props.viewPath, 'Edit/Content'))
  }

  function sendSecurely() {
    setFlowTo(normalizePath(props.viewPath, 'SendSecurely/Content'))
  }

  function compareImages() {
    change(next => {
      next.image_serie_id = image_series_id
      next.image_id = image_id
    })
    setFlowTo(
      normalizePath(props.viewPath, '../../../../../Comparison/Content')
    )
  }

  function analyzeCeph() {
    changeSelected(next => {
      next.selected.images.ceph_image_id = image_uuid
    })
    setFlowTo(
      normalizePath(
        props.viewPath,
        '../../../../../../../../../CephTracing/PerformTrace'
      )
    )
  }

  async function print() {
    if (['xRayCephalometric', 'xRayPanoramic'].includes(slot_key)) {
      let response = await client
        .query(query_print_image_series, {
          image_series_id,
          layout:
            slot_key === 'xRayCephalometric'
              ? 'x_ray_cephalometric'
              : 'x_ray_panoramic',
        })
        .toPromise()
      if (response.error) {
        return notify(notifyError(`Couldn't print the selected image`))
      }
      window.open(response.data.vaxiom_patient_image_series_print.url, '_blank')
    } else {
      let response = await client
        .query(query_print_image, {
          image_id,
        })
        .toPromise()
      if (response.error) {
        return notify(notifyError(`Couldn't print the selected image`))
      }
      window.open(response.data.vaxiom_patient_image_print.url, '_blank')
    }
  }

  async function download() {
    let url = await generateSignedUrl(s3, image.url, {
      filename: image.name,
      download: true,
      content_type: image.content_type,
    })
    window.open(url, '_blank')
  }
}

export function useOnOpenGallery(props) {
  let image_id = useDataValue({
    context: 'patient_image',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setSelectedImageId = useDataChange({
    context: 'gallery',
    path: 'image_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onOpenGallery() {
    setSelectedImageId(image_id)
    setFlowTo(normalizePath(props.viewPath, '../../../../../Gallery/Content'))
  }
}

export function getThreeDotsMenu(items, layout, isEnabled) {
  return items.filter(item => {
    return (
      item.id !== 'ANALYZE' ||
      (item.id === 'ANALYZE' && layout === 'xRayCephalometric' && isEnabled)
    )
  })
}
