export function parseTimeIntervalInWeeks(value) {
  let regex = /(\d+)\s*([a-zA-Z]*)/ // The * allows for zero or more alphabetical characters.
  let match
  try {
    match = value.match(regex)
  } catch (_) {
    return // invalid input provided, ignore it
  }

  if (!match) {
    return // Return undefined for invalid or non-matching inputs
  }

  let intervalValue = parseInt(match[1], 10)
  let textAfterMatch = (match[2] || 'w').toLowerCase() // Default to 'w' if no unit is provided

  let unitMap = {
    d: 1 / 7, // Converting days to weeks
    w: 1, // Weeks remain unchanged
    m: 4, // Assuming 4 weeks in a month
    y: 52, // Assuming 52 weeks in a year
  }

  let unitMultiplier = unitMap[textAfterMatch[0]]

  if (unitMultiplier === undefined) return

  return intervalValue * unitMultiplier
}
