// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/mutation_bank_account.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content(
  $amount: numeric!
  $reference_number: String
  $notes: String
  $location_id: uuid!
  $payment_account_id: numeric!
  $patient_id: uuid!
  $insured_id: numeric
  $token: jsonb!
  $payment_type_id: numeric!
  $auto_apply_to_unpaid_receivables: Boolean!
  $receivable_id: numeric
) {
  payments_post_payment_bank_account(
    token: $token
    amount: $amount
    insured_id: $insured_id
    location_id: $location_id
    notes: $notes
    patient_id: $patient_id
    payment_account_id: $payment_account_id
    payment_type_id: $payment_type_id
    reference_number: $reference_number
    auto_apply_to_unpaid_receivables: $auto_apply_to_unpaid_receivables
    receivable_id: $receivable_id
  ) {
    payment_id
  }
}

`