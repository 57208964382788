import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let params = useDataValue({
    context: 'message',
    path: 'params',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      payment_plan_id: params.payment_plan_id,
      receivable_id: params.receivable_id,
      location_id: params.location_id,
      patient_id: params.patient_id,
    },
  }
}
