// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Signature/Empty/RequestAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Signature_Empty_RequestAction_Content(
  $template_file_key: String!
  $initial_document_data: jsonb!
  $allowed_birth_dates: jsonb!
  $resource_metadata: jsonb!
  $signatory_person_id: uuid!
) {
  insert_documents_signature_requests_one(
    object: {
      template_file_key: $template_file_key
      initial_document_data: $initial_document_data
      allowed_birth_dates: $allowed_birth_dates
      resource_metadata: $resource_metadata
      signatory_person_id: $signatory_person_id
      status: pending
    }
    on_conflict: {
      constraint: signature_requests_pkey
      update_columns: [initial_document_data, allowed_birth_dates, status]
    }
  ) {
    id
  }
}

`