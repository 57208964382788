// this is an autogenerated file from DesignSystem/SendPatientMessage/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_SendPatientMessage($person_id: numeric!) {
  thread: comms_threads_in_app_create(person_id: $person_id) {
    id
  }
}

`