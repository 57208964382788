// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Automation/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Automation(
  $update_event: Boolean!
  $event_id: uuid!
  $event: events_events_set_input
  $insert_actions: Boolean!
  $action_inserts: [events_actions_insert_input!]!
  $update_actions: Boolean!
  $action_updates: [events_actions_updates!]!
  $delete_actions: Boolean!
  $action_deletes: [uuid!]!
) {
  event_updated: update_events_events_by_pk(
    pk_columns: { id: $event_id }
    _set: $event
  ) @include(if: $update_event) {
    id
  }
  actions_inserted: insert_events_actions(objects: $action_inserts)
    @include(if: $insert_actions) {
    affected_rows
    returning {
      id
    }
  }
  actions_updated: update_events_actions_many(updates: $action_updates)
    @include(if: $update_actions) {
    affected_rows
    returning {
      id
    }
  }
  action_executions_deleted: delete_events_action_executions(
    where: { action_id: { _in: $action_deletes } }
  ) @include(if: $delete_actions) {
    affected_rows
    returning {
      id
    }
  }
  actions_deleted: delete_events_actions(
    where: { id: { _in: $action_deletes } }
  ) @include(if: $delete_actions) {
    affected_rows
    returning {
      id
    }
  }
}

`