import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let thread_id = useDataValue({
    viewPath: props.viewPath,
    context: 'thread',
    path: 'id',
  })

  let limit = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'limit',
  })

  let thread_ids_hack = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'thread_ids_hack',
  })

  return {
    variables: {
      limit,
      ids:
        thread_ids_hack && thread_ids_hack.includes(thread_id)
          ? thread_ids_hack
          : [thread_id],
    },
  }
}
