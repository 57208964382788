// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/NoteTemplates/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding_NoteTemplates($objects: [notes_templates_insert_input!]!) {
  insert_notes_templates(objects: $objects) {
    returning {
      id
      name
    }
  }
}

`