import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payments_edit_autopay',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.method = 'autopay'
    })
  }
}
