// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/Events/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_Events($location_id: uuid!) {
  treatments_settings(where: { location_id: { _eq: $location_id } }) {
    id
    location_id
    data
  }
}

`