import { truncate } from 'Data/aggregate.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let video = useDataValue({
    context: 'video',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    change(video)
  }
}

export function formatFilename(value) {
  if (!value || value.length <= 60) {
    return value
  }
  let [filename, extension] = value.split('.')

  filename = truncate(filename, 60)

  return `${filename}.${extension}`
}
