import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(d => {
      let description =
        d.provider === 'payabli' && d.description
          ? JSON.parse(d.description)
          : {}
      if ('token' in description) {
        description['token'] = atob(description['token'])
      }
      return {
        ...d,
        description,
      }
    })
  }, [data])
}
