import React, { useEffect } from 'react'

import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let treatment_card_id = useDataValue({
    context: 'tab',
    path: 'treatment_card_id',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'tab',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let tx_cards = useDataValue({
    context: 'tx_cards',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  useEffect(() => {
    if (treatment_card_id || !tx_cards.length) return

    let tx_card = tx_cards[0]

    // prevent updating selected treatment if treatment cards displayed for the previous patient
    if (patient_id !== tx_card.patient._id) return

    change(next => {
      next.treatment_card_id = tx_card._id
      next.treatment_id = tx_card.txs._id
      next.image_series_id = null
      next.image_id = null
    })
  }, [treatment_card_id, tx_cards]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore change

  return <View {...props} />
}
