// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Email/Emails/Content/Email/Related/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Email_Emails_Content_Email_Related($contact_method_id: numeric!, $person_id: numeric) {
  vaxiom_contact_method_associations(
    where: {
      contact_method_id: { _eq: $contact_method_id }
      person_id: { _neq: $person_id }
    }
  ) {
    id
    error_location_id
    person {
      id
      first_name
      last_name
    }
  }
}

`