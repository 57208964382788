import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value) {
      change(next => {
        let location = locations.find(item => item._id === value)
        let timeZoneId = location?.time_zone_id || DEFAULT_TIMEZONE
        let currentUtcDate = zonedTimeToUtc(
          next.selected.date,
          next.selected.time_zone_id
        )

        next.selected.location_id = value
        next.selected.vaxiom_location_id = location.vaxiom_id
        next.selected.date = utcToZonedTime(currentUtcDate, timeZoneId)
        next.selected.time_zone_id = timeZoneId
      })
    }
  }
}
