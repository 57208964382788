// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Upload/Content/Documents/Content/SystemFiles/FilesTree/ImageSeries/Content/Images/Content/PatientImage/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Upload_Content_Documents_Content_SystemFiles_FilesTree_ImageSeries_Content_Images_Content_PatientImage(
  $resource_id: numeric!
  $resource_type: vaxiom_resource_object_meta_resource_type!
) {
  resource_object_meta: vaxiom_resource_object_meta(
    resource_id: $resource_id
    resource_type: $resource_type
  ) {
    name
    content_type
    size
    preview_url
  }
}

`