import mutation from './mutation.graphql.js'
import update_status_mutation from './update_status_mutation.graphql.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })

  let vaxiom_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'vaxiom_id',
  })

  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'user.id',
  })

  let [, executeMutation] = useMutation(mutation)
  let [, executeUpdateStatusMutation] = useMutation(update_status_mutation)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'change_default': {
        if (!args.account_id) {
          return
        }

        let mutationResponse = await executeMutation({
          location_id,
          provider_location_account_id: args.account_id,
          vaxiom_location_id,
          should_insert: args.provider === 'payabli',
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        notify(notifySuccess('Default provider set!'))
        return
      }

      case 'verify_paypoint':
      case 'invalidate_paypoint': {
        if (!args.provider_location_account_id || !user_id) {
          return
        }

        let mutationResponse = await executeUpdateStatusMutation({
          id: args.provider_location_account_id,
          user_id,
          status: args.type === 'verify_paypoint' ? 'active' : 'invalidated',
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        notify(
          args.type === 'verify_paypoint'
            ? notifySuccess('Verified paypoint!')
            : notifyError('Paypoint invalidated!')
        )
        return
      }

      default: {
        return
      }
    }
  }
}
