import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let question = useDataValue({
    viewPath: props.viewPath,
    context: 'question',
  })

  return useMemo(() => {
    if (!data) return data

    let options = question.options.map(item => ({
      id: item?.id ?? item,
      text: item?.name ?? item,
    }))

    return {
      ...data,
      options,
      selected: question.answer ?? [],
      alerts: question.alert,
    }
  }, [data, question])
}
