// this is an autogenerated file from DesignSystem/TimelineEvent/CustomResource/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_CustomResource_Content($id: uuid!) {
  apps_custom_resources_by_pk(id: $id) {
    id
    connection {
      id
      app_id
    }
    type
    data
    created_at
    updated_at
  }
}

`