// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/Internal/Tools/VirtualMeetingAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content_MessageTools_Internal_Tools_VirtualMeetingAction($thread_id: uuid!) {
  virtual_room_insert_for_comms_thread(thread_id: $thread_id) {
    url
  }
}

`