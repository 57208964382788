function calcValues(value) {
  let total = value.total.aggregate.count || 0

  return {
    conversions:
      Math.round(
        ((value.total_conversions.aggregate.count - value.total_paying) /
          total) *
          100
      ) || 0,
    incomplete:
      Math.round((value.total_incompletes.aggregate.count / total) * 100) || 0,
    created:
      Math.round((value.total_created.aggregate.count / total) * 100) || 0,
    paying: Math.round((value.total_paying / total) * 100) || 0,
  }
}

export function pieChart(value) {
  return Object.entries(calcValues(value)).map(([key, value]) => ({
    x: `${value}%`,
    y: value,
  }))
}
