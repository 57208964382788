import { useDataSubmit } from 'Simple/Data.js'

export function useOnClickComplete(props) {
  let submit = useDataSubmit({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onClickComplete() {
    submit({ type: 'close' })
  }
}
