import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let tx_uuid = useDataValue({
    viewPath: props.viewPath,
    context: 'appointments',
    path: 'tx_uuid',
  })
  let treatment_plans = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_plans',
  })

  return useMemo(() => {
    if (!tx_uuid || !treatment_plans) return null
    return treatment_plans.find(i => i._id === tx_uuid)
  }, [tx_uuid, treatment_plans])
}
