import { useDataValue } from 'Simple/Data.js'
export default function useDataConfiguration(props) {
  let thread_id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })
  return {
    variables: {
      id: thread_id,
    },
    pause: !thread_id,
  }
}
