// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/Content/Content/Launchers/Content/AlignActions/Content/mutation-create.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps_Content_Content_Launchers_Content_AlignActions_Content(
  $app_id: uuid!
  $connection_name: String!
  $location_id: uuid!
  $patient_id: uuid!
) {
  apps_align_ids_patient_create(
    app_id: $app_id
    connection_name: $connection_name
    location_id: $location_id
    patient_id: $patient_id
  ) {
    id
    status
    error
  }
}

`