import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let doctor_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'doctor_id',
  })

  return {
    variables: {
      doctor_id,
    },
    pause: !doctor_id,
  }
}
