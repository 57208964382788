import { useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let resetEditedNote = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'reset_edited_note',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    resetEditedNote(Date.now())
    setFlowTo(
      normalizePath(props.viewPath, '../Expanded/Content/Notes/Add/Content')
    )
  }
}
