// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/RefreshButton/query_image_series.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_RefreshButton($patient_id: uuid!, $deleted: Boolean_comparison_exp!) {
  image_series: vaxiom_patient_imageseries(
    where: { patient: { _id: { _eq: $patient_id } }, deleted: $deleted }
    order_by: { series_date: desc }
  ) {
    id
    series_date
    name
    deleted
    treatment {
      id
      _id
      name
    }
  }
}

`