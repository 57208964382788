import { email } from 'Data/validate.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.persons?.length) return null

    let hub_access_status = data.persons[0].public_user?.hub_access_status
    let phones = data.phones ?? []
    let emails = data.emails ?? []
    let uniqueEmails = Object.keys(
      emails.reduce((acc, current) => {
        if (
          !acc[current.email.address] ||
          (acc[current.email.address] && current.preference)
        ) {
          acc[current.email.address] = current
        }

        return acc
      }, {})
    )

    return {
      status: hub_access_status
        ? hub_access_status
        : phones.length === 0 && emails.length === 0
        ? 'missing_mobile_phone_and_email'
        : phones.length === 0
        ? 'missing_mobile_phone'
        : emails.length === 0
        ? 'missing_email'
        : 'ok_account_missing',
      emails: uniqueEmails,
    }
  }, [data])
}
