// this is an autogenerated file from DesignSystem/ImageCard/query_profile.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageCard($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    person {
      id
      _id
      profile_pic_url
    }
  }
}

`