import { PATIENT_IMAGE_ASPECT_RATIO } from 'Data/constants'
import {
  processImage,
  createDefaultImageReader,
  createDefaultImageWriter,
} from '@pqina/pintura'

export function getAspectRatio(value) {
  let [width, height] = (PATIENT_IMAGE_ASPECT_RATIO[value] || '1:1')
    .split(':')
    .map(Number)
  return width / height
}

export function getWillRenderCanvas(value) {
  return (shapes, state) => {
    let { utilVisibility, selectionRect } = state

    // Exit if crop utils is not visible
    if (utilVisibility.crop <= 0) return shapes

    // Get variable shortcuts to the crop selection rect
    let { x, y, width, height } = selectionRect

    switch (value) {
      case 'facialFrontal':
      case 'facialFrontalSmiling':
      case 'facialLateral':
        return {
          // Copy all props from current shapes
          ...shapes,

          // Now we add an inverted ellipse shape to the interface shapes array
          interfaceShapes: [
            {
              x1: x,
              y1: y + height * 0.03,
              x2: x + width,
              y2: y + height * 0.03,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            {
              x1: x,
              y1: y + height * 0.45,
              x2: x + width,
              y2: y + height * 0.45,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            {
              x1: x,
              y1: y + height * 0.94,
              x2: x + width,
              y2: y + height * 0.94,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            {
              x1: x + width * 0.5,
              y1: y,
              x2: x + width * 0.5,
              y2: y + height,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            // Spread all existing interface shapes onto the array
            ...shapes.interfaceShapes,
          ],
        }
      case 'occlusalUpper':
      case 'occlusalLower':
        return {
          // Copy all props from current shapes
          ...shapes,

          // Now we add an inverted ellipse shape to the interface shapes array
          interfaceShapes: [
            {
              x1: x + width * 0.5,
              y1: y,
              x2: x + width * 0.5,
              y2: y + height,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            // Spread all existing interface shapes onto the array
            ...shapes.interfaceShapes,
          ],
        }
      case 'buccalRight':
      case 'buccalCenter':
      case 'buccalLeft':
        return {
          // Copy all props from current shapes
          ...shapes,

          // Now we add an inverted ellipse shape to the interface shapes array
          interfaceShapes: [
            {
              x1: x,
              y1: y + height * 0.5,
              x2: x + width,
              y2: y + height * 0.5,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            // Spread all existing interface shapes onto the array
            ...shapes.interfaceShapes,
          ],
        }
      default:
        return {
          // Copy all props from current shapes
          ...shapes,

          // Now we add an inverted ellipse shape to the interface shapes array
          interfaceShapes: [
            {
              x1: x,
              y1: y + height * 0.33,
              x2: x + width,
              y2: y + height * 0.33,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            {
              x1: x,
              y1: y + height * 0.66,
              x2: x + width,
              y2: y + height * 0.66,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            {
              x1: x + width * 0.33,
              y1: y,
              x2: x + width * 0.33,
              y2: y + height,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            {
              x1: x + width * 0.66,
              y1: y,
              x2: x + width * 0.66,
              y2: y + height,
              opacity: 0.25,
              strokeWidth: 1,
              strokeColor: [255, 255, 255],
            },
            // Spread all existing interface shapes onto the array
            ...shapes.interfaceShapes,
          ],
        }
    }
  }
}

export async function processCapturedImage({ src, settings }) {
  try {
    let { dest } = await processImage(src, {
      imageReader: createDefaultImageReader(),
      imageWriter: createDefaultImageWriter({ quality: 1 }),
      imageFlipX: settings.flip_x,
      imageFlipY: settings.flip_y,
    })

    return {
      dest,
    }
  } catch (error) {
    return {
      error,
    }
  }
}
