// this is an autogenerated file from DesignSystem/TimelineEvent/Note/Content/Display/Delete/Content/Actions/DeleteAction/mutation_delete.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Note_Content_Display_Delete_Content_Actions_DeleteAction($note_id: numeric!) {
  delete_vaxiom_notes_by_pk(id: $note_id) {
    id
    note
  }
}

`