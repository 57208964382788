// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/FilesEdit/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_FilesEdit($id: numeric!) {
  vaxiom_patients_by_pk(id: $id) {
    id
    person {
      id
      first_name
      greeting
      last_name
      birth_date
    }
  }
}

`