import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let tx_id = useDataValue({
    context: 'treatment_card',
    path: 'txs._id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      tx_id,
      colors: value.colors.join(',') || null,
    })

    if (mutationResponse.error) {
      return notify(
        notifyError(`Cannot update treatment's colors. Please try again.`)
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
