// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsDevelopers/Keys/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsDevelopers_Keys($id: uuid!, $status: apps_key_status_enum!) {
  update_apps_keys_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
    id
  }
}

`