import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'core_embedded_section',
  })
  return function onClick() {
    submit({ type: 'refresh' })
  }
}
