import View from './view.js';
import React from 'react';

let ChoiceToggleLogic = props => (
  <View
    {...props}
    isSelected={props.isSelected || (props.id && props.id === props.selected)}
    onClick={() => props.onClick(props.id)}
  />
);

export default ChoiceToggleLogic;
