// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Address/NewAddress/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Address_NewAddress_Content($person_id: numeric!, $contact_methods: json!) {
  add_person_contact_methods(
    args: { _person_id: $person_id, _contact_methods: $contact_methods }
  ) {
    id
  }
}

`