// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Notes/update_tx_notes.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Notes($tx_id: uuid!, $notes: String) {
  update_vaxiom_txs(where: { _id: { _eq: $tx_id } }, _set: { notes: $notes }) {
    affected_rows
    returning {
      _id
      notes
    }
  }
}

`