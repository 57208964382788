// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/NextControl/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_NextControl(
  $appointment_id: numeric!
  $interval_to_next: Int!
) {
  update_vaxiom_appointments_by_pk(
    pk_columns: { id: $appointment_id }
    _set: { interval_to_next: $interval_to_next }
  ) {
    id
    interval_to_next
  }
}

`