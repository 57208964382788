import mutation from './mutation.graphql.js'
import queryParentCompany from './query-parent-company.graphql.js'
import { useMutation, useClient } from 'Data/Api.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications.js'
import { parseCsv } from 'Logic/csv.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let client = useClient()
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let submitOnboarding = useDataSubmit({
    context: 'onboarding',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    if (!window.confirm(`Are you sure?`)) return

    try {
      let [
        { data: txCardFieldTypesRaw },
        { data: txCardFieldOptionsRaw },
        { data: txCardFieldDefinitionsRaw },
        {
          data: {
            vaxiom_sys_organizations: [parent_company],
          },
        },
      ] = await Promise.all([
        parseCsv({
          file: value.tx_card_field_types_csv.file,
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        }),
        parseCsv({
          file: value.tx_card_field_options_csv.file,
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        }),
        parseCsv({
          file: value.tx_card_field_definitions_csv.file,
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        }),
        client.query(queryParentCompany, { id: parent_company_id }).toPromise(),
      ])
      if (!parent_company) {
        notify(notifyError(`No parent company with id="${parent_company_id}"`))
        return
      }

      let locationsMap = Object.fromEntries(
        parent_company.sys_organizations
          .map(item => item.sys_organizations.map(item => [item.name, item.id]))
          .flat()
      )

      let errors = new Set()
      let objects = txCardFieldTypesRaw.map(item => ({
        name: item.name,
        value_type: item.value_type,
        is_broken_brackets: item.is_broken_brackets,
        is_hygiene: item.is_hygiene,
        is_free_text: item.is_free_text,
        tx_card_field_options: {
          data: txCardFieldOptionsRaw
            .filter(oitem => oitem.field_type_name === item.name)
            .map(oitem => ({
              value: `${oitem.value}`,
              pos: oitem.pos,
            })),
        },
        tx_card_field_definitions: {
          data: txCardFieldDefinitionsRaw
            .filter(oitem => oitem.field_type_name === item.name)
            .map(oitem => {
              let organization_id = locationsMap[oitem.location_name]
              if (!organization_id) {
                errors.add(`Missing location "${oitem.location_name}"`)
              }

              return {
                name: oitem.name,
                number: oitem.number,
                tracked: oitem.tracked,
                multi_value: oitem.multi_value,
                mandatory: oitem.mandatory,
                alert: oitem.alert,
                hide_for_future: oitem.hide_for_future,
                organization_id,
              }
            }),
        },
      }))

      if (errors.size > 0) {
        ;[...errors].forEach(message => notify(notifyError(message)))
        return
      }

      let mutationResponse = await executeMutation({ objects })

      if (mutationResponse.error) {
        notify(
          notifyError(
            `Tx card fields setup failed: ${mutationResponse.error.message}`
          )
        )
      } else {
        await submitOnboarding({
          type: 'TX_CARD_FIELDS',
          data: {
            source: {
              types: txCardFieldTypesRaw,
              options: txCardFieldOptionsRaw,
              definitions: txCardFieldDefinitionsRaw,
            },
            created: {
              types:
                mutationResponse.data.insert_vaxiom_tx_card_field_types.returning.map(
                  item => item.id
                ),
            },
          },
        })
        notify(notifySuccess(`Tx card fields setup done`))
      }
    } catch (error) {
      notify(notifyError(`Tx card fields setup failed: ${error.message}`))
    }
  }
}
