import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      action_executions: value,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.events_manual_event_execution_submit
    ) {
      return notify(notifyError('An unexpected error has occurred'))
    }

    let { status } = mutationResponse.data.events_manual_event_execution_submit
    if (status === 'ok') {
      setFlowTo(normalizePath(props.viewPath, 'No'))
      return notify(notifySuccess('The actions were performed successfully.'))
    } else {
      return notify(
        notifyError(
          'At least one action failed. Please complete the ones that remain pending. If the problem persists, contact Support'
        )
      )
    }
  }
}
