import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let display_name = useDataValue({
    context: 'connection',
    path: 'display_name',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      display_name,
    }),
    [display_name]
  )
}
