import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(
    () => ({
      ...data,
      state: props.state,
      confirmation_status: props.confirmation_status,
      show_icon: props.show_icon ?? data.show_icon,
      show_text: props.show_text ?? data.show_text,
    }),
    [
      props.state,
      props.confirmation_status,
      props.show_icon,
      props.show_text,
      data,
    ]
  )
}
