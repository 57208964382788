import { useDataValue } from 'Simple/Data.js'
import { TREATMENT_FLOW_PAYMENT_METHOD_FLAG } from 'Data/common-constants'

export default function useDataConfiguration(props) {
  let selectedCompanyId = useDataValue({
    context: 'payment_method_selection',
    path: 'location.id', // ok to be id and not _id
    viewPath: props.viewPath,
  })

  return {
    variables: {
      organization_id: selectedCompanyId,
      feature_flag: TREATMENT_FLOW_PAYMENT_METHOD_FLAG,
    },
    pause: !selectedCompanyId,
  }
}
