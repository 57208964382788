// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsCreatePaymentPlanForExistingInvoice/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsCreatePaymentPlanForExistingInvoice($receivable_id: numeric!) {
  receivable: vaxiom_receivables_by_pk(id: $receivable_id) {
    id
    human_readable_id
    balance_amount
    treatment {
      id
      name
    }
    patient {
      id
      person {
        id
        _id
      }
    }
    organization {
      id
      _id
      local_current_date
    }
    payment_account {
      id
      account_type
      person_payment_account {
        id
        payer_person {
          id
          _id
          first_name
          last_name
        }
      }
    }
    insured {
      id
      insurance_subscription {
        id
        insurance_plan {
          id
          number
          insurance_company {
            id
            carrier_name
          }
        }
      }
    }
  }
}

`