import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let resource_id = useDataValue({
    context: 'tab',
    path: 'selected.images.ceph_image_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { resource_id },
    pause: !resource_id,
  }
}
