import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'

import { email as validate_email } from 'Data/validate.js'
import mutation_new_contact_method_association from './newContactMethodAssociationMutation.graphql.js'
import mutation_add_person_contact_methods from './addPersonContactMethodsMutation.graphql.js'
import query from './query.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionNewEmailForProfessional =
    useDataOnActionNewEmailForProfessional(props)
  let onActionEmailForProfessional = useDataOnActionEmailForProfessional(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'email_for_patient': {
        return onActionEmailForProfessional({ value, args })
      }
      default: {
        return onActionNewEmailForProfessional({ value, args })
      }
    }
  }
}

function useDataOnActionNewEmailForProfessional(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let client = useClient()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })
  return async function onAction({ value: email }) {
    if (!validate_email(email.email.address)) {
      return true
    }
    // if the email doesn't exist,
    // and we don't want to add a relation,
    // we just save the new email and add
    // a new association
    let { data } = await client
      .query(query, {
        address: email.email.address,
        person_id,
      })
      .toPromise()

    if (data?.self.length > 0) {
      notify(
        notifyError(
          'This email address is already associated with this person.'
        )
      )
      return
    }

    if (data?.vaxiom_contact_emails.length === 0) {
      let mutationAddPersonContactMethods =
        await executeMutationAddPersonContactMethods({
          person_id,
          contact_methods: [
            {
              dtype: 'email',
              description: email.description,
              data: {
                address: email.email.address,
              },
            },
          ],
        })

      if (mutationAddPersonContactMethods.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
      }
      notify(notifySuccess('Email saved!'))
      setFlowTo(normalizePath(props.viewPath, '../No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, './SearchForEmail/Content'))
    }
  }
}

function useDataOnActionEmailForProfessional(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationNewContactMethodAssociation] = useMutation(
    mutation_new_contact_method_association
  )
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })
  return async function onAction({ args }) {
    // if we found that the email already exists,
    // but we don't want to add a realtionship,
    // we just link the patient to the existing contact_method
    let mutationNewContactMethodAssociationRespone =
      await executeMutationNewContactMethodAssociation({
        contact_method_id: args.contact_method_id,
        person_id,
      })

    if (mutationNewContactMethodAssociationRespone.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Email saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
