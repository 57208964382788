import { snakeCaseToHumanReadable } from 'App/Account/Content/PatientsList/helpers.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !data.length) return data

    let array = data.map(item => ({
      id: item.type,
      text: snakeCaseToHumanReadable(item.name),
    }))

    return array
  }, [data])
}
