import { useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tokens',
  })
  return function onClick() {
    submit({ type: 'change_status' })
  }
}

export function getStatusText(status) {
  return `${status === 'inactive' ? 'Deactivate' : 'Activate'}`
}
