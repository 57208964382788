import { useDataValue } from 'Simple/Data'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'email',
  })
  let code = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'code',
  })

  return useMemo(
    () => ({
      ...data,
      email,
      code,
    }),
    [data, email, code]
  )
}
