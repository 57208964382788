import React, { useEffect, useRef } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

export default function ScrollIntoViewIfNeeded({
  behavior = 'smooth',
  scrollMode = 'always',
  block = 'start',
  inline = 'start',
  delay = 0,
}) {
  let ref = useRef(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        if (ref.current) {
          scrollIntoView(ref.current, {
            behavior,
            scrollMode,
            block,
            inline,
          })
        }
      }, delay)
    })
  }, [behavior, scrollMode, block, inline, delay])
  return <span ref={ref} />
}
