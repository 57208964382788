import React, { useEffect, useRef } from 'react'
import View from './view.js'
import { useDataSubmit } from 'Simple/Data.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'images_request',
  })
  let called = useRef(false)

  useEffect(() => {
    if (called.current) return

    called.current = true
    submit({ type: 'activate' })
  }, []) // eslint-disable-line

  return <View {...props} />
}
