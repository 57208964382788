// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPlanTemplates/TxPlanTemplates/TxPlanTemplates/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPlanTemplates_TxPlanTemplates_TxPlanTemplates_Content(
  $location_ids: [numeric!]
  $deleted_values: [Boolean!]
) {
  vaxiom_tx_plan_templates(
    where: {
      organization_id: { _in: $location_ids }
      deleted: { _in: $deleted_values }
    }
    order_by: { organization: { name: asc }, name: asc }
  ) {
    id
    name
    fee
    length_in_weeks
    deleted
    tx_category {
      id
      name
    }
    insurance_code {
      id
      code
    }
    tx_plan_group {
      id
      name
    }
    organization {
      id
      name
    }
  }
}

`