import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.payments_treatment_plan.treatment_id',
  })
  return {
    variables: {
      treatment_id,
    },
    pause: !treatment_id,
  }
}
