import useDataConfiguration from './useDataConfiguration.js'
import useFetch from 'Data/useFetch'
import { useDataChange } from 'Simple/Data'
import { useEffect } from 'react'

let HTTP_STATUS_CODES = {
  200: 'OK',
  201: 'Created',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  408: 'Request Timeout',
  413: 'Payload Too Large',
  500: 'Internal Server Error',
}

export default function useResponse(props) {
  let configuration = useDataConfiguration(props)

  let { data, error, fetching, status, headers, statusText } = useFetch(
    configuration.variables.url,
    configuration.variables.options
  )
  let change = useDataChange({
    context: 'response',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    change({
      url: configuration.variables.url,
      data,
      error:
        error !== undefined && error !== null && typeof error === 'object'
          ? error.message
          : error,
      status,
      headers,
      statusText: HTTP_STATUS_CODES[status] ?? statusText,
      fetching,
    })
  }, [data, error, fetching, status, headers, statusText])

  return null
}
