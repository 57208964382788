import { useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'event_action',
    path: 'data.req',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let { headers, body_type, body, ...rest } = value.req

    headers = headers.reduce(
      (acc, { key, value }) => ({ ...acc, [key]: value }),
      {}
    )

    // ApiCaller is returning the body as string in some cases and we need to store the body as json in our db
    try {
      if (typeof body === 'string' && body.trim().length) {
        body = JSON.parse(body)
      }
    } catch (e) {}

    change({
      ...rest,
      headers,
      body,
    })
  }
}
