import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let treatment_card_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'treatment_card_id',
  })

  return {
    variables: { treatment_card_id },
    pause: !treatment_card_id,
    requestPolicy: 'cache-and-network',
  }
}
