import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let requirement = useDataValue({
    context: 'requirement',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'event_action',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (next.data.requirements.some(item => item.id === requirement.id)) {
        next.data.requirements = next.data.requirements.filter(
          item => item.id !== requirement.id
        )
      } else {
        next.data.requirements.push({
          ...requirement,
          is_skippable: false,
          create_task: false,
        })
      }
    })
  }
}

export function isEnabled(action_requirements, requirement) {
  return action_requirements.some(item => item.id === requirement)
}
