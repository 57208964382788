import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !data?.length) return data

    return data.map(document => ({
      id: document._id,
      text: `${document.name} | ${document.organization.name} ( ${document.organization.level} )`,
    }))
  }, [data])
}
