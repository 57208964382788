// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/FeatureAccess/Rulesets/Content/Ruleset/Division/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_FeatureAccess_Rulesets_Content_Ruleset_Division{
  vaxiom_sys_organizations(
    where: { level: { _eq: "div" }, deleted: { _eq: false } }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`