// this is an autogenerated file from DesignSystem/TimelineEvent/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_CustomResource{
  apps_ui_mappings(type: treatment_timeline) {
    id
    display_definitions
  }
}

`