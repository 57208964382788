import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(({ id, text, chair_allocations }) => {
      let providerAllocation = chair_allocations.find(
        item => item.resource.resource_type.is_provider
      )
      let default_provider_id =
        providerAllocation?.resource?.employee_resources?.[0]?.id || null

      let assistantAllocation = chair_allocations.find(
        item => item.resource.resource_type.is_assistant
      )
      let default_assistant_id =
        assistantAllocation?.resource?.employee_resources?.[0]?.id || null
      return {
        id,
        text,
        default_provider_id,
        default_assistant_id,
      }
    })
  }, [data])
}
