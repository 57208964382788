// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsCreatePaymentPlanForExistingInvoice/Content/Content/add_payment_plan.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsCreatePaymentPlanForExistingInvoice_Content_Content(
  $receivable_id: numeric!
  $payment_plan_amount: numeric!
  $installment_interval: payments_installment_interval_types_enum
  $first_installment_date: Int!
  $second_installment_date: Int!
  $first_due_date: date!
  $installment_amount: numeric!
  $notes: String = ""
  $token: jsonb
) {
  payments_create_payment_plan_from_receivable(
    receivable_id: $receivable_id
    payment_plan_amount: $payment_plan_amount
    installment_interval: $installment_interval
    first_installment_date: $first_installment_date
    second_installment_date: $second_installment_date
    first_due_date: $first_due_date
    installment_amount: $installment_amount
    notes: $notes
    token: $token
  ) {
    payment_plan_id
    verified
    verification_metadata
  }
}

`