import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let organizations = data
      .flatMap(tx_card => tx_card.txs)
      .map(tx => tx.organization)
      .filter(Boolean)

    return [
      ...new Set(
        data
          .flatMap(tx_card => tx_card.txs)
          .map(tx => tx.organization)
          .filter(Boolean)
          .map(organization => organization.id)
      ),
    ].map(id => organizations.find(org => org.id === id))
  }, [data])
}
