import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let ui_mappings = useDataValue({
    context: 'ui_mappings',
    path: 'display_definitions',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    // find the data mapping configuration to be used for the custom resource
    let mapping = ui_mappings.find(
      m => m.app_id === data.connection.app_id && m.resource_type === data.type
    )?.mapping
    if (!mapping) return null

    return {
      id: data.id,
      created_at: data.created_at,
      updated_at: data.updated_at,
      ...getValues(data.data, mapping),
    }
  }, [data, ui_mappings])
}

function getValues(data, mapping) {
  let result = {}
  for (let key of Object.keys(mapping)) {
    if (typeof mapping[key] === 'object') {
      result[key] = getValues(data, mapping[key])
    } else if (key in mapping) {
      result[key] = getValue(data, mapping[key])
    }
  }
  return result
}

function getValue(data, placeholder) {
  if (placeholder.startsWith('{{')) {
    return data[placeholder.replace('{{', '').replace('}}', '')]
  } else {
    return placeholder
  }
}
