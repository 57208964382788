import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let appointment_booking = useDataValue({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })
  let changeDisabled = useDataChange({
    context: 'appointment_booking',
    path: 'disabled',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onClick() {
    let mutationResponse = await executeMutation({
      id: appointment_booking.id,
      state: appointment_booking.state,
    })

    if (
      mutationResponse.error ||
      mutationResponse.data.update_vaxiom_appointment_bookings.affected_rows ===
        0
    ) {
      notify(
        notifyError('Cannot check in selected appointment. Please try again.')
      )
      return
    }

    changeDisabled(true)
  }
}
