import { useDataChange, useDataValue } from 'Simple/Data.js'
export function useOnChange(props) {
  let referral_templates = useDataValue({
    context: 'referral_templates',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'new_referral',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    let new_referral = referral_templates.find(v => v.id === value)

    change(new_referral)
  }
}
