import { useDataChange } from 'Simple/Data.js'
import { useEffect, useMemo, useRef } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let change = useDataChange({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })
  let changeRef = useRef(change)

  let email =
    data?.[0]?.person?.contact_method_associations?.[0]?.email?.address

  useEffect(() => {
    changeRef.current(next => {
      next.receipt.email = email
    })
  }, [email])

  return useMemo(() => {
    if (!data) return data

    return data
  }, [data])
}
