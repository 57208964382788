export function getView(value) {
  switch (value) {
    case 'card':
      return 'CreditCard'

    case 'ach':
      return 'BankAccount'

    default:
      return 'No'
  }
}
