import { useDataSubmit } from 'Simple/Data'
import { useUppy } from 'DesignSystem/FileUploaderProvider/react.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'message_compose',
  })
  let uppy = useUppy({ viewPath: props.viewPath })

  return function onClick(rstatus) {
    submit({ type: 'sendInternal', uppy, rstatus })
  }
}
