import { format } from 'date-fns'

export function formatTimestamp(value) {
  return format(Date.parse(`${value}Z`), 'MM/d/yy h:mm aa')
}

export function formatCreator(creator) {
  return creator?.person
    ? `by ${creator.person.first_name} ${creator.person.last_name}`
    : ''
}

export function formatEditor(note) {
  let { creator, editor } = note
  return !editor || creator?.person?.id === editor?.person?.id
    ? ''
    : `Last edit by ${editor.person?.first_name} ${editor.person?.last_name}`
}
