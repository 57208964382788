import React, { useEffect } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { appointmentTypeColor, isAppointmentScheduled } from 'Data/format.js'
import { useDesignTokenValue } from 'Simple/DesignTokens.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import isBefore from 'date-fns/isBefore'
import isToday from 'date-fns/isToday'
import View from './view.js'
import useIsHovered from 'Simple/hooks/useIsHovered.js'

export default function Logic(props) {
  let [isHoveredManual, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: props.isSelected,
  })
  let id = useDataValue({
    context: 'event',
    path: 'id',
    viewPath: props.viewPath,
  })
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  let state = useDataValue({
    context: 'event',
    path: 'appointment.booking.state',
    viewPath: props.viewPath,
  })
  let isUnplanned = useDataValue({
    context: 'event',
    path: 'appointment.unplanned',
    viewPath: props.viewPath,
  })
  let borderColor = useDataValue({
    context: 'event',
    path: 'appointment.type.color_id',
    viewPath: props.viewPath,
  })
  let unplannedColor = useDesignTokenValue({
    path: 'colors.neutral.700',
    viewPath: props.viewPath,
  })
  let seatedColor = useDesignTokenValue({
    path: 'colors.blue.600',
    viewPath: props.viewPath,
  })

  let latest_event_id = useDataValue({
    context: 'latest_events',
    path: 'last_event_id',
    viewPath: props.viewPath,
  })
  let previous_event_ids = useDataValue({
    context: 'latest_events',
    path: 'previous_event_ids',
    viewPath: props.viewPath,
  })

  let pastBackgroundColor = useDesignTokenValue({
    path: 'colors.neutral.100',
    viewPath: props.viewPath,
  })
  let futureBackgroundColor = useDesignTokenValue({
    path: 'colors.white',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    if (latest_event_id === id || previous_event_ids.includes(id)) {
      setFlowTo(normalizePath(props.viewPath, 'Expanded/Content'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View
      isHoveredManual={isHoveredManual}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      {...props}
      backgroundColor={
        isAppointmentScheduled(appointment) &&
        (isBefore(
          Date.parse(`${appointment.booking.start_time}Z`),
          new Date()
        ) ||
          isToday(Date.parse(`${appointment.booking.start_time}Z`)))
          ? pastBackgroundColor
          : futureBackgroundColor
      }
      borderColor={
        state === 'SEATED'
          ? seatedColor
          : isUnplanned
          ? unplannedColor
          : appointmentTypeColor(borderColor)
      }
    />
  )
}
