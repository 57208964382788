import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useListItemDataOnSubmit} */
export default function useListItemDataOnSubmit(props, data) {
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment._id',
    viewPath: props.viewPath,
  })
  let contact_methods = useDataValue({
    context: 'contact_methods',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (!value.contact_method_id) return true

    let contact_method = contact_methods.find(
      item => item.id === value.contact_method_id
    )
    let mutationResponse = await executeMutation({
      appointment_id,
      phone:
        contact_method.dtype === 'phone' ? contact_method.phone.number : null,
      email:
        contact_method.dtype === 'email' ? contact_method.email.address : null,
      custom_instructions:
        value.template_type === 'custom' ? value.custom_instructions : null,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem sending the scheduling link. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The scheduling link was sent successfully.`))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
