// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/Delete/Content/Main/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_Delete_Content_Main_Content($treatment_card_id: uuid!) {
  update_vaxiom_txs(
    where: { tx_card: { _id: { _eq: $treatment_card_id } } }
    _set: { deleted: true }
  ) {
    affected_rows
  }
  update_vaxiom_tx_cards(
    where: { _id: { _eq: $treatment_card_id } }
    _set: { deleted: true }
  ) {
    affected_rows
  }
}

`