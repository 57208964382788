// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/VaxiomPayments/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_VaxiomPayments($patient_id: uuid!) {
  vaxiom_payments(
    where: { patient: { _id: { _eq: $patient_id } } }
    order_by: { sys_created_at: desc }
  ) {
    id
    amount
    balance
    payment_type
    payments_transaction_id
    sys_created_at
    has_correction
    notes
    # payment_account {
    #   person_payment_account {
    #     payer_person {
    #       first_name
    #       last_name
    #     }
    #   }
    # }
    # transactions {
    #   id
    #   dtype
    #   amount
    #   notes
    # }
  }
}

`