import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let preview_url = useDataValue({
    context: 'document',
    path: 'preview_url',
    viewPath: props.viewPath,
  })

  return function onClick() {
    window.open(preview_url, '_blank')
  }
}
