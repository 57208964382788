// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/Content/Content/ReceiptEmailInput/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content_Content_Content_ReceiptEmailInput_Content($patient_id: numeric!) {
  vaxiom_patients(where: { id: { _eq: $patient_id } }) {
    id
    person {
      id
      contact_method_associations(
        where: { email: { address: { _is_null: false } } }
        limit: 1
      ) {
        id
        email {
          id
          address
        }
      }
    }
  }
}

`