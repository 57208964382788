import { useDataValue } from 'Simple/Data.js'
import { useUppy } from 'design-system/FileUploader/react.js'

export function useOnClick(props) {
  let uppy = useUppy(props)
  let selectedFile = useDataValue({
    context: 'patient_documents',
    path: 'preview_file',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    // eslint-disable-next-line compat/compat
    let data = await fetch(selectedFile.preview_url).then(res => res.blob())
    try {
      uppy.addFile({
        name: selectedFile.name,
        type: selectedFile.type,
        size: selectedFile.size,
        data,
      })
    } catch (err) {
      if (!err.isRestriction) {
        uppy.log(err)
        uppy.info(
          'There was an error when trying to add your files. Please try again.',
          'error',
          3000
        )
      }
    }
  }
}
