import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let is_secure = useDataValue({
    context: 'message_compose',
    path: 'is_secure',
    viewPath: props.viewPath,
  })
  let setSecure = useDataChange({
    context: 'message_compose',
    path: 'is_secure',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setSecure(!is_secure)
  }
}
