import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'data',
    viewPath: props.viewPath,
  })
  let location_ids = useDataValue({
    context: 'data',
    path: 'location_ids',
    viewPath: props.viewPath,
  })

  return isSubmitting || location_ids.length === 0
}
