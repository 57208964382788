import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ args, value }) {
    if (value.send_to_person_vaxiom_id === null) return
    if (typeof props.onSubmit !== 'function') {
      notify(notifyError('Missing submit function on SendPatientMessage'))
      setFlowTo(normalizePath(props.viewPath, 'No'))
      return
    }

    let extraArgs = {}
    if (typeof props.onSubmitPre === 'function') {
      try {
        let onSubmitPreResponse = await props.onSubmitPre({
          args,
          value,
          props,
        })
        if (onSubmitPreResponse === false) return

        if (typeof onSubmitPreResponse === 'object') {
          extraArgs = onSubmitPreResponse
        }
      } catch (error) {
        return notify(notifyError(`Couldn't send the message`))
      }
    }

    let mutationResponse = await executeMutation({
      person_id: value.send_to_person_vaxiom_id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't send the message`))
    }

    props.onSubmit({
      type: 'tab/openCommsThread',
      thread_id: mutationResponse.data.thread.id,
      is_secure: args.is_secure,
      auto_uploaded_attachments: value.auto_uploaded_attachments,
      message: value.message,
      tags: value.tags,
      ...extraArgs,
    })

    setFlowTo(normalizePath(props.viewPath, 'No'))

    // TODO: we might no need this anymore?
    if (typeof props.onSubmitPost === 'function') {
      props.onSubmitPost()
    }
  }
}
