import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransfrom(props, data) {
  let value = useDataValue({
    context: 'procedures',
    path: 'appointment',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      procedure_ids: value,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
