import { useDataSubmit } from 'Simple/Data.js'
import { patientName } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onSubmit({ value, args }) {
    if (!args.tab) return

    submit({
      type: 'tabs/add',
      tab: {
        id: value.patient.id,
        type: 'Patient',
        name: patientName(value.patient.person),
        tab: args.tab,
        viewPath: '/App/Account/Content/Patients',
        patient_id: value.patient.id,
      },
    })

    if (typeof props.onSubmit === 'function') {
      props.onSubmit()
    }

    return true
  }
}
