// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/FeatureAccess/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_FeatureAccess_AddRuleset(
  $organization_id: numeric!
  $message_key: String!
  $message_value: String!
) {
  insert_vaxiom_application_properties_one(
    object: {
      organization_id: $organization_id
      message_key: $message_key
      message_value: $message_value
    }
  ) {
    organization_id
  }
}

`