// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/AddAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_AddAction_Content(
  $access_key_encoded: String!
  $account_id: String!
  $is_default: Boolean!
  $provider: payments_providers_enum!
  $location_id: uuid!
  $vaxiom_location_id: numeric!
  $description: String!
) {
  payments_add_provider_location_account(
    access_key_encoded: $access_key_encoded
    account_id: $account_id
    is_default: $is_default
    provider: $provider
    location_id: $location_id
    vaxiom_location_id: $vaxiom_location_id
    description: $description
  ) {
    provider_location_account_id
  }
}

`