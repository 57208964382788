import View from './view.js'
import React from 'react'

export default function Logic(props) {
  return <View {...props} text={props.item.text} onClick={onClick} />

  function onClick() {
    if (typeof props.onClick === 'function') {
      props.onClick(props.item.id)
    }
  }
}
