import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let image_series = useDataValue({
    context: 'image_serie',
    viewPath: props.viewPath,
  })
  let setSelectedImageSeries = useDataChange({
    context: 'patient_documents',
    path: 'selected_image_series',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setSelectedImageSeries(image_series)
  }
}
