import { useDataSubmit } from 'Simple/Data.js'

export function useOnCreate(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onCreate() {
    submit({ type: 'save' })
  }
}

export function useOnUpdate(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onUpdate() {
    submit({ type: 'update' })
  }
}

export function useOnCancel(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onCancel() {
    submit({ type: 'cancel' })
  }
}
