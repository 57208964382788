// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Upload/Content/Documents/Content/SystemFiles/FilesTree/ImageSeries/Content/Images/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Upload_Content_Documents_Content_SystemFiles_FilesTree_ImageSeries_Content_Images($image_series_id: numeric!) {
  vaxiom_patient_images(where: { imageseries_id: { _eq: $image_series_id } }) {
    id
    file_key
    type_key
    slot_key
  }
}

`