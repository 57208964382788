// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/AssistantAndProvider/Content/Change/Content/Chair/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_AssistantAndProvider_Content_Change_Content_Chair($current_location_id: uuid!) {
  vaxiom_chairs(
    where: {
      resource: {
        organization: { _id: { _eq: $current_location_id } }
        deleted: { _eq: false }
      }
    }
    order_by: { pos: asc }
  ) {
    id
    _id
    text: full_name
  }
}

`