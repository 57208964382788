import { useMutation, useClient } from 'Data/Api.js'
import { useAwsS3Client, generateSignedUrl } from 'Data/s3'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation_delete_image from './mutation_delete_image.graphql.js'
import query_print_image_series from './query_print_image_series.graphql.js'
import query_print_image from './query_print_image.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionDelete = useDataOnActionDelete(props)
  let onActionPrint = useDataOnActionPrint(props)
  let onActionDownload = useDataOnActionDownload(props)
  let onActionAnalyze = useDataOnActionAnalyze(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'delete':
        return onActionDelete({ value })
      case 'print':
        return onActionPrint({ value })
      case 'download':
        return onActionDownload({ value })
      case 'analyze':
        return onActionAnalyze({ value })
      default:
        console.error(`The action type ${args?.type} does not exist`)
    }
  }
}

function useDataOnActionDelete(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation_delete_image)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value }) {
    let mutationResponse = await executeMutation({
      id: value.id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't delete the image`))
    }
    setFlowTo(normalizePath(props.viewPath, 'Actions/Delete/No'))
  }
}

function useDataOnActionPrint(props) {
  let client = useClient()
  let [, notify] = useNotifications()

  return async function onAction({ value }) {
    let url

    if (['xRayCephalometric', 'xRayPanoramic'].includes(value.slot_key)) {
      let response = await client
        .query(query_print_image_series, {
          image_series_id: value.imageseries.id,
          layout:
            value.slot_key === 'xRayCephalometric'
              ? 'x_ray_cephalometric'
              : 'x_ray_panoramic',
        })
        .toPromise()
      if (response.error) {
        return notify(notifyError(`Couldn't print the selected image`))
      }
      url = response.data.vaxiom_patient_images_print_preview.url
    } else {
      let response = await client
        .query(query_print_image, {
          image_id: value.id,
        })
        .toPromise()
      if (response.error) {
        return notify(notifyError(`Couldn't print the selected image`))
      }
      url = response.data.vaxiom_patient_image_print.url
    }

    window.open(url, '_blank')
  }
}

function useDataOnActionDownload(props) {
  let s3 = useAwsS3Client(props)
  let resource = useDataValue({
    context: 'image_card',
    path: 'resource',
    viewPath: props.viewPath,
  })

  return async function onAction({ value }) {
    let url = await generateSignedUrl(s3, resource.url, {
      filename: resource.name,
      download: true,
      content_type: resource.content_type,
    })
    window.open(url, '_blank')
  }
}

function useDataOnActionAnalyze(props) {
  return async function onAction({ value }) {
    return (
      typeof props.onImageAnalyze === 'function' &&
      props.onImageAnalyze(value._id)
    )
  }
}
