import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let paypoint = useDataValue({
    context: 'add_provider_payabli_paypoint',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'add_provider',
    viewPath: props.viewPath,
  })

  return function onClick() {
    if (typeof props.onPaypointClick === 'function')
      props.onPaypointClick(paypoint.id)
    change(next => {
      next.payabli.selected_paypoint_details = {
        name: paypoint.name,
        parentOrgName: paypoint.parentOrgName,
      }
    })
  }
}

export function getUsedByText(usedBy) {
  if (usedBy > 0)
    return `Used by ${usedBy} location${usedBy > 1 ? 's' : ''} in this PC`
  else return 'Not used'
}
