import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let accounts = useDataValue({
    context: 'itero_accounts',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    let preferred_account = accounts.find(item => item.is_preferred)

    return preferred_account
      ? {
          view: 'preferred',
          preferred_account,
        }
      : data
  }, [data, accounts])
}
