import React from 'react'
import { hasHTML, replaceUrlsWithLinks } from 'Data/format.js'
import RawHtml from '../RawHtml/react.js'
import './style.css'

let FONT_SIZE = 13
let LINE_HEIGHT = 18

export default function NoteHtml(props) {
  return (
    <div
      style={{
        '--font-size': `${props.fontSize ?? FONT_SIZE}px`,
        '--line-height': `${props.lineHeight ?? LINE_HEIGHT}px`,
      }}
      className="note-html"
    >
      <RawHtml
        html={
          hasHTML(props.html) ? props.html : replaceUrlsWithLinks(props.html)
        }
      ></RawHtml>
    </div>
  )
}
