import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'api',
    viewPath: props.viewPath,
  })

  let index = useDataValue({
    context: 'header_item',
    path: 'index',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'removeHeader',
      index,
    })
  }
}
