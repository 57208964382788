import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(patient_id)
  }
}
