import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'steps',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'changeStatus',
      value: 'skipped',
    })
  }
}
