// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-unseat.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($id: uuid!, $skip_required_fields: Boolean!) {
  vaxiom_appointment_booking_unseat(
    id: $id
    skip_required_fields: $skip_required_fields
  ) {
    status
  }
}

`