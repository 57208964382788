import _Dynamsoft from 'dwt/dist/dynamsoft.webtwain.min.js'
import { useEffect, useState } from 'react'
import formatDate from 'date-fns/format'
import { v4 as uuid } from 'uuid'
import { captureError } from 'Logic/ErrorBoundary.js'

let width = '100%'
let height = '400'
let ProductKey = process.env.REACT_APP_DYNAMSOFT_API_KEY
// Importing Dynamsoft like this because of how Vite bundles it
// and for compatibility with CRA. The default import 'dwt' tries to load
// and ESM module that fails because process.version isn't set. When trying
// to set it, eg by using define: { 'process.version': '"17.0.0"'} in vite.config.js
// it fails in another line because import.meta.url isn't a string either. My guess
// is that the module isn't ready to work with ESM despite the build.
let Dynamsoft = 'DWT' in _Dynamsoft ? _Dynamsoft : window.Dynamsoft
let ResourcesPath = `${
  window.location.origin
}/dwt-resources/${Dynamsoft.DWT.JSVersion.replace(/,/g, '-')}`

// https://www.dynamsoft.com/web-twain/docs/info/api/appendix.html
let USER_ERROR_CODES = [-1032]

export function useDwt(props) {
  let [state, setState] = useState({
    sources: [],
    currentSource: null,
    DWObject: null,
    isLoading: false,
    isAcquiring: false,
  })

  useEffect(() => {
    setState(prev => ({ ...prev, isLoading: true }))

    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
      console.debug({
        type: 'twain/event/OnWebTwainReady',
      })
      let dwObject = Dynamsoft.DWT.GetWebTwain(props.containerId)
      if (dwObject) {
        let vCount = dwObject.SourceCount
        if (vCount === 0 && Dynamsoft.Lib.env.bMac) {
          dwObject.CloseSourceManager()
          dwObject.ImageCaptureDriverType = 0
          dwObject.OpenSourceManager()
          vCount = dwObject.SourceCount
        }

        let sourceNames = []
        for (let i = 0; i < vCount; i++) {
          sourceNames.push(dwObject.GetSourceNameItems(i))
        }
        dwObject.LogLevel = 0

        setState(prev => ({
          ...prev,
          DWObject: dwObject,
          sources: sourceNames,
          currentSource: sourceNames.length
            ? sourceNames[0]
            : state.currentSource,
          isLoading: false,
        }))
      }
    })

    loadDWT()
    return () => {
      Dynamsoft.DWT.Unload()
    }
  }, []) // eslint-disable-line

  return {
    ...state,
    onSourceChange,
    onImagesAcquire,
  }

  function onSourceChange(value) {
    setState(prev => ({ ...prev, currentSource: value }))
  }

  function onImagesAcquire() {
    if (!state.currentSource) return

    state.DWObject.RemoveAllImages()
    state.DWObject.IfDisableSourceAfterAcquire = true
    state.DWObject.CloseSource()
    state.DWObject.CloseSourceManager()

    for (let i = 0; i < state.DWObject.SourceCount; i++) {
      if (state.DWObject.GetSourceNameItems(i) === state.currentSource) {
        state.DWObject.SelectSourceByIndex(i)
        break
      }
    }

    state.DWObject.OpenSource()
    state.DWObject.IfShowUI = true
    state.DWObject.AcquireImage(onAcquireImageSuccess, onAcquireImageFailure)

    function onAcquireImageSuccess() {
      setState(prev => ({ ...prev, isAcquiring: true }))

      if (state.DWObject.HowManyImagesInBuffer < 1) {
        console.error({
          type: 'twain/upload/no-images',
          ErrorString: state.DWObject.ErrorString,
        })
        setState(prev => ({ ...prev, isAcquiring: false }))
        return
      }

      console.debug({
        type: 'twain/upload/images',
        number: state.DWObject.HowManyImagesInBuffer,
      })

      let images_number = state.DWObject.HowManyImagesInBuffer
      let indices = []
      for (let i = 0; i < images_number; i++) {
        if (state.DWObject.GetImageBitDepth(i) === 1) {
          state.DWObject.ConvertToGrayScale(i)
          console.debug({
            type: 'twain/upload/image/ConvertToGrayScale',
            i,
          })
        }

        indices.push(i)
      }

      let convertImagePromise
      if (images_number === 1 || !props.convertMultiPageToPdf) {
        convertImagePromise = Promise.all(
          indices.map(
            i =>
              new Promise((resolve, reject) => {
                let name = `${formatDate(new Date(), 'yyyyMMdd')}-${
                  state.currentSource
                }-${uuid().substring(0, 7)}.jpg`
                let type = 'image/jpeg'
                state.DWObject.ConvertToBlob(
                  [i],
                  Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG,
                  function (result, indices, resultType) {
                    console.debug({
                      type: 'twain/upload/image/converted',
                      i,
                      result,
                      indices,
                      resultType,
                    })

                    resolve({ name, type, size: result.size, data: result })
                  },
                  function (errorCode, errorString) {
                    console.error({
                      type: 'twain/upload/image/not-converted',
                      i,
                      errorCode,
                      errorString,
                    })
                    reject({ i, errorCode, errorString })
                  }
                )
              })
          )
        )
      } else {
        convertImagePromise = new Promise((resolve, reject) => {
          let name = `${formatDate(new Date(), 'yyyyMMdd')}-${
            state.currentSource
          }-${uuid().substring(0, 7)}.pdf`
          let type = 'application/pdf'
          state.DWObject.ConvertToBlob(
            indices,
            Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
            function (result, indices, resultType) {
              console.debug({
                type: 'twain/upload/pdf/converted',
                result,
                indices,
                resultType,
              })

              resolve([{ name, type, size: result.size, data: result }])
            },
            function (errorCode, errorString) {
              console.error({
                type: 'twain/upload/pdf/not-converted',
                errorCode,
                errorString,
              })
              reject({ errorCode, errorString })
            }
          )
        })
      }

      // leaving it to return a Promise because changing it to async/await results in an error being thrown from Dynamsoft
      return convertImagePromise
        .then(imageSource => {
          state.DWObject.CloseSource()

          return props.onImagesAcquired && props.onImagesAcquired(imageSource)
        })
        .then(() => {
          setTimeout(
            () => setState(prev => ({ ...prev, isAcquiring: false })),
            5000
          )
        })
        .catch(error => {
          captureError(error, { type: 'twain/upload/error' })
        })
    }

    function onAcquireImageFailure(errorCode, errorString) {
      state.DWObject.CloseSource()

      // do not report errors caused by user's action such as cancelling the scanning
      if (USER_ERROR_CODES.includes(errorCode)) return

      captureError(errorCode, {
        type: 'twain/acquireImage/failure',
        ErrorString: errorString,
      })
    }
  }

  function loadDWT() {
    Dynamsoft.DWT.ResourcesPath = ResourcesPath
    Dynamsoft.DWT.ProductKey = ProductKey
    Dynamsoft.DWT.Containers = [
      { ContainerId: props.containerId, Width: width, Height: height },
    ]
    Dynamsoft.DWT.IfAddMD5InUploadHeader = false
    Dynamsoft.DWT.IfConfineMaskWithinTheViewer = false
    modulizeInstallJS()
    Dynamsoft.DWT.Load()
  }

  function modulizeInstallJS() {
    let _DWT_Reconnect = Dynamsoft.DWT_Reconnect
    Dynamsoft.DWT_Reconnect = (...args) =>
      _DWT_Reconnect.call({ Dynamsoft: Dynamsoft }, ...args)
    let __show_install_dialog = Dynamsoft._show_install_dialog
    Dynamsoft._show_install_dialog = (...args) =>
      __show_install_dialog.call({ Dynamsoft: Dynamsoft }, ...args)
    let _OnWebTwainOldPluginNotAllowedCallback =
      Dynamsoft.OnWebTwainOldPluginNotAllowedCallback
    Dynamsoft.OnWebTwainOldPluginNotAllowedCallback = (...args) =>
      _OnWebTwainOldPluginNotAllowedCallback.call(
        { Dynamsoft: Dynamsoft },
        ...args
      )
    let _OnWebTwainNeedUpgradeCallback = Dynamsoft.OnWebTwainNeedUpgradeCallback
    Dynamsoft.OnWebTwainNeedUpgradeCallback = (...args) =>
      _OnWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args)
    let _OnWebTwainPreExecuteCallback = Dynamsoft.OnWebTwainPreExecuteCallback
    Dynamsoft.OnWebTwainPreExecuteCallback = (...args) =>
      _OnWebTwainPreExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args)
    let _OnWebTwainPostExecuteCallback = Dynamsoft.OnWebTwainPostExecuteCallback
    Dynamsoft.OnWebTwainPostExecuteCallback = (...args) =>
      _OnWebTwainPostExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args)
    let _OnRemoteWebTwainNotFoundCallback =
      Dynamsoft.OnRemoteWebTwainNotFoundCallback
    Dynamsoft.OnRemoteWebTwainNotFoundCallback = (...args) =>
      _OnRemoteWebTwainNotFoundCallback.call({ Dynamsoft: Dynamsoft }, ...args)
    let _OnRemoteWebTwainNeedUpgradeCallback =
      Dynamsoft.OnRemoteWebTwainNeedUpgradeCallback
    Dynamsoft.OnRemoteWebTwainNeedUpgradeCallback = (...args) =>
      _OnRemoteWebTwainNeedUpgradeCallback.call(
        { Dynamsoft: Dynamsoft },
        ...args
      )
    let _OnWebTWAINDllDownloadFailure = Dynamsoft.OnWebTWAINDllDownloadFailure
    Dynamsoft.OnWebTWAINDllDownloadFailure = (...args) =>
      _OnWebTWAINDllDownloadFailure.call({ Dynamsoft: Dynamsoft }, ...args)
  }
}
