import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  notifyInvalid,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import mutation from './mutation.graphql.js'
import { text, uuid } from 'Data/validate.js'

export default function useDataOnSubmit(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()

  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (isInvalid(value, notify)) return true

    let response = await executeMutation({
      patient_id,
      doctor_id: value.doctor_id,
      practice_id: value.practice_id,
    })
    if (response.error) {
      notify(notifyError(`Can't send patient to DM`))
    } else {
      notify(notifySuccess(`Sent patient to DM`))
    }
  }

  function isInvalid(value, notify) {
    let list = [
      !uuid(value.doctor_id) && 'assigned doctor',
      !text(value.practice_id) && 'assigned practice',
    ].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
