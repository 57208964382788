import { useDataValue } from 'Simple/Data'

export function prettify(text) {
  try {
    return JSON.stringify(JSON.parse(text), null, 2)
  } catch (e) {
    return text
  }
}

export function useOnCopyClick(props) {
  let data = useDataValue({
    context: 'response',
    path: 'data',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    try {
      // eslint-disable-next-line compat/compat
      await navigator.clipboard.writeText(data)
    } catch (error) {}
  }
}
