import React from 'react'
import View from './view.js'

let MAX_FILE_SIZE_IN_APP = 1024 * 1024 * 50 // 50MB
let ALLOWED_FILE_TYPES_IN_APP = [
  'image/*',
  'text/*',
  'application/*',
  '.heic',
  '.heif',
  '.inv',
  '.stl',
]
let restrictions = {
  maxFileSize: MAX_FILE_SIZE_IN_APP,
  allowedFileTypes: ALLOWED_FILE_TYPES_IN_APP,
}

export default function Logic(props) {
  return <View viewPath={props.viewPath} restrictions={restrictions} />
}
