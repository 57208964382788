import { useDataChange } from 'Simple/Data.js'

export function useOnClickYes(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'min_downpayment_type',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('percentage')
  }
}

export function useOnClickNo(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'min_downpayment_type',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('amount')
  }
}
