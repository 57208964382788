import { useEffect } from 'react'

export default function AppTitle(props) {
  useEffect(() => {
    let prev = document.title

    document.title = props.append ? `${props.text} • ${prev}` : props.text

    return () => {
      document.title = prev
    }
  }, [props.text])

  return null
}
AppTitle.defaultProps = {
  append: true,
}
