import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'search',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === '') {
      change(next => {
        next.term = ''
        next.items = locations
      })
    }

    let valueRegex = new RegExp(value, 'i')
    change(next => {
      next.term = value
      next.items = locations.filter(item => valueRegex.test(item.name))
    })
  }
}
