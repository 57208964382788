import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import mutation_send_sms from './mutation_send_sms.graphql.js'
import mutation_send_in_app from './mutation_send_in_app.graphql.js'
import mutation_set_status from './mutation_set_status.graphql.js'
import mutation_send_internal from './mutation_send_internal.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { v4 as uuid } from 'uuid'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSendInternal = useDataOnActionSendInternal(props, data)
  let onActionThreadIdsHack = useDataOnActionThreadIdsHack(props, data)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'sendInternal': {
        return await onActionSendInternal({
          value,
          originalValue,
          args,
          change,
        })
      }
      case 'threadIdsHack': {
        return await onActionThreadIdsHack({
          value,
          originalValue,
          args,
          change,
        })
      }
      default: {
        throw new Error(`Unknown action ${args.type}`)
      }
    }
  }
}

function useDataOnActionThreadIdsHack(props, data) {
  return async function onAction({ args }) {
    if (
      Array.isArray(args.thread_ids) &&
      typeof props.onThreadIdsHackChange === 'function'
    ) {
      props.onThreadIdsHackChange(args.thread_ids)
    }
  }
}

function useDataOnActionSendInternal(props, data) {
  let dataThread = useDataValue({
    context: 'thread',
    viewPath: props.viewPath,
  })

  let [, executeSendSmsMutation] = useMutation(mutation_send_sms)
  let [, executeSendInAppMutation] = useMutation(mutation_send_in_app)
  let [, executeSendInternalMutation] = useMutation(mutation_send_internal)
  let [, executeSetStatusMutation] = useMutation(mutation_set_status)

  let [, notify] = useNotifications()

  return async function onAction({
    value,
    args = { rstatus: null, uppy: null },
    change,
  }) {
    let status = typeof args.rstatus === 'string' ? args.rstatus : value.status
    let { message, attachments } = value

    try {
      if (attachments.some(attachment => !attachment.progress.uploadComplete)) {
        await args.uppy.upload()
        attachments = args.uppy.getFiles()
      }

      let mutationResponse = {}
      let resetUuid = null
      if (message.length) {
        switch (dataThread.type) {
          case 'sms': {
            mutationResponse = await executeSendSmsMutation({
              from_number_id: value.send_from_id,
              to_number: value.to_number,
              message,
              status,
              attachments: attachments.map(attachment => ({
                name: attachment.name,
                type: attachment.type,
                url: attachment.meta.location,
              })),
              tags: value.tags,
            })
            break
          }

          case 'in_app': {
            mutationResponse = await executeSendInAppMutation({
              thread_id: dataThread.id,
              location_id: value.send_from_id,
              is_secure: value.is_secure,
              message,
              status,
              email: value.email,
              attachments: attachments.map(attachment => ({
                name: attachment.name,
                type: attachment.type,
                url: attachment.meta.location,
              })),
              tags: value.tags,
            })
            break
          }

          case 'internal': {
            mutationResponse = await executeSendInternalMutation({
              thread_id: dataThread.id,
              is_secure: true,
              message,
              status: 'open',
              attachments: attachments.map(attachment => ({
                name: attachment.name,
                type: attachment.type,
                url: attachment.meta.location,
              })),
              tags: value.tags,
            })
            break
          }

          default: {
            return notify(
              notifyError(`Unknown thread type: ${dataThread.type}`)
            )
          }
        }

        resetUuid = uuid()
      } else {
        if (status === dataThread.status) {
          return notify(notifySuccess(`Status is already ${status}`))
        }

        mutationResponse = await executeSetStatusMutation({
          thread_id: dataThread.id,
          name: status,
        })
      }

      if (mutationResponse.error) {
        throw mutationResponse.error
      }

      if (typeof props.onSubmit === 'function') {
        props.onSubmit({ status, thread_id: dataThread.id })
      }

      change(next => {
        next.message = ''
        next.status = status
        // clear attachments
        next.attachments = []
        next.auto_uploaded_attachments = []
        next.tags = []
        next.pinToBottom = true
        if (resetUuid) {
          // clear the attachments once the message was sent
          next.reset = resetUuid
        }
      })

      return notify(
        notifySuccess(
          message.length ? `Message sent` : `Status set to ${status}`
        )
      )
    } catch (err) {
      return notify(
        notifyError(
          message.length
            ? `We couldn't send your message.`
            : `We couldn't change the status.`
        )
      )
    }
  }
}
