import React from 'react'

import View from './view.js'
import { useIsDisabled } from './helpers.js'

export default function Logic(props) {
  let disabled = useIsDisabled(props)

  return <View {...props} disabled={disabled} />
}
