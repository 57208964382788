import ApiSchema from './ApiCacheExchangeUpdatesSchema.json'
import customMutationFieldNameMappings from './ApiCacheExchangeUpdatesCustom.json'
import makeDebug from 'Simple/debug.js'
import isEqual from 'lodash/isEqual'

let debug = makeDebug('simple/ApiCacheExchangeUpdates')

let Mutation = Object.fromEntries(
  ApiSchema.mutationFieldNames.map(item => {
    let typename = item
      .replace(/_(by_pk|one)$/, '')
      .replace(/^(insert|update|delete)_/, '')

    let isInsert = item.startsWith('insert_')
    let isDelete = item.startsWith('delete_')

    let fieldNames = new Set(
      [
        typename,
        (isInsert || isDelete) && `${typename}_by_pk`,
        (isInsert || isDelete) && `${typename}_one`,
        ...(customMutationFieldNameMappings[typename] ?? []),
        // for more control, allow refetching by defining a specific mutation instead of applying the rule for all of them
        ...(customMutationFieldNameMappings[item] ?? []),
      ].filter(Boolean)
    )

    if (customMutationFieldNameMappings[typename]) {
      debug({
        type: 'simple/api/cache-exchange-updates/custom-mutation-map',
        fieldNames,
      })
    }

    return [
      item,
      (result, args, cache, info) => {
        let allQueries = cache.inspectFields(ApiSchema.queryType)
        let queries = allQueries.filter(x => fieldNames.has(x.fieldName))
        debug({
          type: 'simple/api/cache-exchange-updates/hit/mutation',
          typename,
          name: item,
          queries,
          allQueries,
          result,
          args,
          cache,
          info,
        })

        // insert could add the elements from a query without arguments
        // delete could remove the elements from any list it finds itself on
        // what happens with queries that aren't on the top level though?
        // I guess that as long as we use the thing.id form instead of
        // thing_id, urql would be dealing with it for us since the type would
        // be removed to begin with? Need to double check that assumption
        // I think it doesn't and we might need to invalidate relations too
        // https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#inspecting-a-sub-field
        // how do we do that though if we don't have the id?
        queries.forEach(({ fieldName, arguments: variables }) => {
          cache.invalidate(ApiSchema.queryType, fieldName, variables)
        })
      },
    ]
  })
)

let Subscription = Object.fromEntries(
  ApiSchema.subscriptionFieldNames.map(item => {
    let typename = item.replace(/_(by_pk|aggregate|stream)$/, '')

    let fieldNames = new Set(
      [
        typename,
        `${typename}_by_pk`,
        `${typename}_one`,
        ...(customMutationFieldNameMappings[typename] ?? []),
        // for more control, allow refetching by defining a specific mutation instead of applying the rule for all of them
        ...(customMutationFieldNameMappings[item] ?? []),
      ].filter(Boolean)
    )

    if (customMutationFieldNameMappings[typename]) {
      debug({
        type: 'simple/api/cache-exchange-updates/custom-mutation-map',
        fieldNames,
      })
    }

    return [
      item,
      (result, args, cache, info) => {
        let allQueries = cache.inspectFields(ApiSchema.queryType)
        let queries = allQueries.filter(
          x => fieldNames.has(x.fieldName) && isEqual(args, x.arguments)
        )
        debug({
          type: 'simple/api/cache-exchange-updates/hit/subscription',
          typename,
          name: item,
          queries,
          allQueries,
          result,
          args,
          cache,
          info,
        })

        // insert could add the elements from a query without arguments
        // delete could remove the elements from any list it finds itself on
        // what happens with queries that aren't on the top level though?
        // I guess that as long as we use the thing.id form instead of
        // thing_id, urql would be dealing with it for us since the type would
        // be removed to begin with? Need to double check that assumption
        // I think it doesn't and we might need to invalidate relations too
        // https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#inspecting-a-sub-field
        // how do we do that though if we don't have the id?
        queries.forEach(({ fieldKey, fieldName, arguments: variables }) => {
          // https://github.com/urql-graphql/urql/discussions/2257#discussioncomment-2568780
          cache.link(
            ApiSchema.queryType,
            fieldName,
            args,
            Object.values(result)[0]
          )
        })
      },
    ]
  })
)

// do we want to do anything with optimistic updates too?
// https://formidable.com/open-source/urql/docs/graphcache/custom-updates/
// it could:
// - take the _set key in updates and merge the values in
// - add an item to a list without a filter
// - delete an item right away
//
// the cache.invalidate call here will deal with getting the right data at some
// point

let Updates = {
  Mutation,
  Subscription,
}

export default Updates
