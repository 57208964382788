import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let actions = useDataValue({
    context: 'manual_action_executions',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!actions?.length) return null
    return actions[0]
  }, [actions])
}
