import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataChange, useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { URL_HUB_SIGN } from 'Data/constants.js'
import mutation from './mutation.graphql.js'
import { useSaveEditorContent } from '../../../../useSaveEditorContent.js'

let MESSAGE = {
  INAPP:
    'Your signature is requested on a document for patient {Patient_Name}: {Link}',
  PHONE: 'You have a signature request for {Patient_Name}: {Link}',
}

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'preview_document',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return action => {
    if (action.type === 'showQrCode') {
      setFlowTo(normalizePath(props.viewPath, '../../../Content/Content'))
    } else {
      submit(action)
    }
  }
}

export function useOnSubmitPre(props) {
  let document_id = useDataValue({
    context: 'preview_document',
    path: 'document_id',
    viewPath: props.viewPath,
  })
  let url = useDataValue({
    context: 'document',
    path: 'url',
    viewPath: props.viewPath,
  })
  let editor = useDataValue({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let source_key = useDataValue({
    context: 'document',
    path: 'source_key',
    viewPath: props.viewPath,
  })
  let patientPerson = useDataValue({
    context: 'patient',
    path: 'person',
    viewPath: props.viewPath,
  })
  let save = useSaveEditorContent(props)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmitPre({ args, props, value }) {
    // auto save editor
    if (editor.isDirty) {
      change(next => {
        next.saving = true
      })
      await save({ url, content: editor.api.getContent() })

      editor.api.setDirty(false)
      change(next => {
        next.saving = false
        next.isDirty = false
      })
    }

    let mutationResponse = await executeMutation({
      // document_template_id: document_id,
      template_file_key: source_key,
      initial_document_data: {},
      signatory_person_id: value.send_to_person_id,
      allowed_birth_dates: {
        // PAYER: treatment_request.person.birth_date,
        PATIENT: patientPerson.birth_date,
      },
      resource_metadata: {
        id: document_id,
        table: 'document_tree_nodes',
        schema: 'vaxiom',
      },
    })

    if (mutationResponse.error) {
      notify(notifyError(`Couldn't send the document for signature`))
      return false
    }

    let { id } = mutationResponse.data.insert_documents_signature_requests_one

    let message =
      value.contact_method.type === 'Phone' ? MESSAGE.PHONE : MESSAGE.INAPP

    return {
      message: message
        .replace(
          '{Patient_Name}',
          `${patientPerson.first_name} ${patientPerson.last_name}`
        )
        .replace('{Link}', `${URL_HUB_SIGN}/${id}`),
      tags: ['documents.signature_requests', 'request', id],
    }
  }
}
