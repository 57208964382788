// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Unscheduled/More/Content/AddAppointment/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Unscheduled_More_Content_AddAppointment_Content(
  $treatment_id: uuid!
  $appointment_template_id: uuid!
  $location_id: uuid!
  $interval_to_next: numeric
  $previous_appointment_id: uuid
) {
  vaxiom_appointment_create(
    treatment_id: $treatment_id
    appointment_template_id: $appointment_template_id
    location_id: $location_id
    interval_to_next: $interval_to_next
    previous_appointment_id: $previous_appointment_id
  ) {
    id
  }
}

`