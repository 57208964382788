import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let payment_account = useDataValue({
    context: 'receivable',
    viewPath: props.viewPath,
    path: 'payment_account',
  })

  return useMemo(() => {
    /**
     * The filtering that is being below ensures that if an InsurancePaymentAccount is linked
     * with the receivable that we are in the process of creating a payment plan for, then
     * we do not display the option to select "Autopay" as a payment source. The only option they
     * would have is of "Invoice". On the other hand, if the linked payment account is a
     * PersonPaymentAccount, both the options of "Invoice" and "Autopay" are shown
     */
    return data.filter(
      source =>
        source.id !== 'autopay' ||
        (source.id === 'autopay' &&
          payment_account?.account_type === 'PersonPaymentAccount')
    )
  }, [payment_account, data])
}
