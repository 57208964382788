import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!props.url) return data

    return {
      ...data,
      url: props.url,
    }
  }, [data, props.url])
}
