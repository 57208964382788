import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { useUppy } from 'design-system/FileUploader/react.js'
import mutation from './mutation.graphql.js'

export function useOnClose(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let parent_id = useDataValue({
    context: 'patient_documents',
    path: 'parent_id',
    viewPath: props.viewPath,
  })
  let setSelectedDocumentId = useDataChange({
    context: 'patient_documents',
    path: 'selected_document_id',
    viewPath: props.viewPath,
  })

  let uppy = useUppy(props)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeCreateDocumentMutation] = useMutation(mutation)

  return async function onClose() {
    let attachments = uppy.getFiles()

    if (attachments.some(attachment => !attachment.progress.uploadComplete)) {
      return notify(
        notifyError(
          'Please upload the pending documents or remove them from the list before closing the modal.'
        )
      )
    }

    let results = await Promise.allSettled(
      attachments.map(async attachment => {
        let mutationCreateDocumentResponse =
          await executeCreateDocumentMutation({
            patient_id,
            parent_id,
            name: attachment.name,
            file_uid: attachment.meta.location.substring(
              attachment.meta.location.lastIndexOf('/') + 1
            ),
          })
        if (mutationCreateDocumentResponse.error) {
          throw mutationCreateDocumentResponse.error
        }

        setSelectedDocumentId(
          mutationCreateDocumentResponse.data
            .insert_vaxiom_document_tree_nodes_one.id
        )
      })
    )

    let hasFailedAttachment = results.some(
      result => result?.status !== 'fulfilled'
    )
    if (hasFailedAttachment) {
      return notify(
        notifyError(
          'There was a problem storing the selected patient documents. Please try again.'
        )
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
