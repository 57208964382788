// this is an autogenerated file from App/Account/Search/Content/Profiles/Empty/NewThreadAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Search_Content_Profiles_Empty_NewThreadAction_Content($number: PhoneNumber!) {
  thread: comms_threads_sms_create(number: $number) {
    id
  }
}

`