import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let organization_id = useDataValue({
    context: 'tab',
    path: 'selected.location_numeric_id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    let mutationResponse = await executeMutation({
      organization_id,
    })

    if (mutationResponse.error) {
      return notify(notifyError('Something went wrong. Please try again.'))
    }

    notify(
      notifySuccess(
        'Automation hub enabled! Automation hub has been enabled for this location.'
      )
    )
  }
}
