import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      image_series_id,
    },
    requestPolicy: 'network-only',
    pause: !image_series_id,
  }
}
