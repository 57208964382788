import View from './view.js'
import { appointmentTypeColor } from 'Data/format.js'

export default function Logic(props) {
  let color_id_computed = props.selected?.[0]?.color_id_computed

  if (!color_id_computed) return <></>

  return <View {...props} color={appointmentTypeColor(color_id_computed)} />
}
