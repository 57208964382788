// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Comparison/Content/Content/Actions/Choose/ImageSelect/Content/Content/ImageSeries/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Comparison_Content_Content_Actions_Choose_ImageSelect_Content_Content_ImageSeries($condition: vaxiom_patient_imageseries_bool_exp!) {
  image_series: vaxiom_patient_imageseries(
    where: $condition
    order_by: { series_date: desc }
  ) {
    id
    series_date
    name
    deleted
  }
}

`