import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import View from './view.js'
import React, { useEffect, useRef } from 'react'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutationCreateThread] = useMutation(mutation)

  let cancel = useRef(false)
  useEffect(() => {
    return () => {
      cancel.current = true
    }
  }, []) // eslint-disable-line

  return <View onClick={onClick} viewPath={props.viewPath} />

  async function onClick() {
    try {
      let mutationResponseCreateThread = await executeMutationCreateThread({
        person_id: person.id,
      })

      if (mutationResponseCreateThread.error) {
        console.error(`😱 ${mutationResponseCreateThread.error.message}`)
        throw new Error(`There was a problem opening this record.`)
      }

      let thread = mutationResponseCreateThread.data.thread

      submit({
        type: 'tabs/add',
        tab: {
          id: 'Comms',
          name: 'Comms',
          type: 'Comms',
          viewPath: '/App/Account/Content/Comms',
          sync: {
            threadId: thread.id,
          },
        },
      })

      setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
    } catch (e) {
      setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
      notify(notifyError(e.error))
    }
  }
}
