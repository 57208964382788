import { DataProvider } from 'Simple/Data.js'
import React from 'react'
import View from './view.js'

let value = { current: 30 }

export default function Logic(props) {
  return (
    <DataProvider context="countdown" value={value} viewPath={props.viewPath}>
      <View viewPath={props.viewPath} />
    </DataProvider>
  )
}
