import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'image',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.original_image = null
      next.editor_src = null
    })
  }
}
