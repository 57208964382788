// this is an autogenerated file from DesignSystem/CommsThread/Content/mutation_set_status.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content(
  $thread_id: uuid!
  $name: comms_events_statuses_types_enum!
) {
  insert_comms_events_statuses_one(
    object: { thread_id: $thread_id, name: $name }
  ) {
    id
  }
}

`