// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/update-event-status-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations(
  $event_id: uuid!
  $status: events_event_statuses_enum!
  $event_type: String!
  $data: jsonb!
  $organization_id: uuid!
  $automation_type: String!
  $update_other_statuses: Boolean!
) {
  event_updated: update_events_events_by_pk(
    pk_columns: { id: $event_id }
    _set: { status: $status }
  ) {
    id
  }
  update_statuses: events_events_update_statuses(
    status: $status
    event_type: $event_type
    data: $data
    organization_id: $organization_id
    automation_type: $automation_type
  ) @include(if: $update_other_statuses) {
    status
  }
}

`