// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/DownPayment/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_DownPayment_AddRuleset(
  $organization_id: uuid!
  $payment_methods: jsonb!
) {
  insert_treatments_downpayment_payment_methods_one(
    object: {
      organization_id: $organization_id
      payment_methods: $payment_methods
    }
  ) {
    id
  }
}

`