import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let questionnaire_id = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })
  return {
    variables: { questionnaire_id },
    pause: !questionnaire_id,
  }
}
