// this is an autogenerated file from App/Auth/Callback/Loading/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_Callback_Loading($code: String!, $id: uuid!) {
  apps_external_api_token_swap(code: $code, id: $id) {
    status
  }
}

`