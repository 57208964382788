// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsRequestPayment/Content/mutation_pay_request.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsRequestPayment_Content(
  $patient_person_uuid: uuid!
  $message: String
  $amount: numeric!
  $person_payment_account_uuid: uuid!
  $xid: String!
  $allowed_birth_dates: jsonb!
  $location_uuid: uuid!
  $tx_uuid: uuid!
  $contact_methods: [payments_pay_request_contact_methods_insert_input!]!
) {
  insert_payments_pay_requests_one(
    object: {
      location_id: $location_uuid
      patient_person_id: $patient_person_uuid
      person_payment_account_id: $person_payment_account_uuid
      amount: $amount
      message: $message
      status: created
      xid: $xid
      allowed_birth_dates: $allowed_birth_dates
      treatment_id: $tx_uuid
      request_type: REQUEST_PAYMENT
      contact_methods: { data: $contact_methods }
    }
  ) {
    id
  }
}

`