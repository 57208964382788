import { useDataSubmit } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'patient',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    submit({
      type: 'showPatient',
      tab: 'profile',
    })

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
