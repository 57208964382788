import { PutObjectCommand } from '@aws-sdk/client-s3'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import { getS3ObjectUrl, useAwsCredentials, useAwsS3Client } from 'Data/s3.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { v4 as uuid } from 'uuid'

export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let submitOnboarding = useDataSubmit({
    context: 'onboarding',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let getAwsCredentials = useAwsCredentials(props)
  let getS3Client = useAwsS3Client(props)

  return async function onSubmit({ value }) {
    if (!window.confirm(`Are you sure?`)) return

    try {
      let awsCredentials = await getAwsCredentials()
      let s3 = await getS3Client()

      let objects = await Promise.all(
        value.location_ids.flatMap(organization_id =>
          value.note_templates.map(async item => {
            let key = `companies/${parent_company_id}/note-templates/${uuid()}`
            let bucket = awsCredentials.storage_bucket_name

            await s3.send(
              new PutObjectCommand({
                Key: key,
                Bucket: bucket,
                Body: item.content,
                ContentType: 'text/html',
              })
            )

            return {
              name: item.name,
              tags: item.tags,
              url: getS3ObjectUrl({
                bucket,
                key,
                region: awsCredentials.region,
              }),
              organization_id,
            }
          })
        )
      )

      let mutationResponse = await executeMutation({ objects })

      if (mutationResponse.error) {
        notify(
          notifyError(
            `Note templates setup failed: ${mutationResponse.error.message}`
          )
        )
      } else {
        await submitOnboarding({
          type: 'NOTE_TEMPLATES',
          data: {
            source: {
              note_templates: objects,
            },
            created: {
              note_templates:
                mutationResponse.data.insert_notes_templates.returning.map(
                  item => item.id
                ),
            },
          },
        })
        notify(notifySuccess(`Note templates setup done`))
      }
    } catch (error) {
      notify(notifyError(`Note templates setup failed: ${error.message}`))
    }
  }
}
