import isBefore from 'date-fns/isBefore'
import isToday from 'date-fns/isToday'

export function getView(booking) {
  return booking &&
    (isBefore(Date.parse(`${booking.start_time}Z`), new Date()) ||
      isToday(Date.parse(`${booking.start_time}Z`)))
    ? 'Past'
    : 'Future'
}
