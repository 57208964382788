import { useDataValue, useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import delete_note from './mutation_delete.graphql.js'

export function useOnDelete(props) {
  let changeEvents = useDataChange({
    viewPath: props.viewPath,
    context: 'events',
  })
  let events = useDataValue({
    viewPath: props.viewPath,
    context: 'events',
  })

  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'note',
  })

  let [, notify] = useNotifications()
  let [, execDeleteMutation] = useMutation(delete_note)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick() {
    let mutationResponse = await execDeleteMutation({
      note_id: note.id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Note deleted!'))
    changeEvents(events.filter(e => e?.note?.id !== note.id))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
