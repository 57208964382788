import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let screen_width = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'screen_width',
  })
  let is_full_width = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'is_full_width',
  })
  let selected_appointment_templates = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
    path: 'selected_appointment_templates',
  })

  return useMemo(() => {
    return {
      is_full_width:
        is_full_width &&
        (screen_width >= 1500 || selected_appointment_templates.length === 0),
    }
  }, [screen_width, is_full_width, selected_appointment_templates])
}
