import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return {
      ...data,
      items: locations,
    }
  }, [data, locations])
}
