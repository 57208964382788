import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let preference = parseInt(
      localStorage.getItem('number_of_tabs_to_show'),
      10
    )
    return {
      ...data,
      number_of_tabs_to_show: isNaN(preference)
        ? data.number_of_tabs_to_show
        : preference,
    }
  }, [data])
}
