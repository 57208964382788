import { useMutation } from 'Data/Api.js'
import updateTxNotes from './update_tx_notes.graphql.js'
import { notifyError, useNotifications } from 'Logic/Notifications'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let tx_id = useDataValue({
    context: 'tab',
    path: 'treatment_id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(updateTxNotes)

  return async function onSubmit({ value, originalValue, args, change }) {
    if (value === originalValue) return

    let mutationResponse = await executeMutation({
      tx_id,
      notes: value,
    })

    if (mutationResponse.error) {
      return notify(notifyError(`Cannot update the notes.`))
    }
  }
}
