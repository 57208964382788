import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import View from './view.js'
import { preserveLineBreaks } from 'Data/format.js'

export default function Logic(props) {
  let content = useDataValue({
    context: 'content',
    viewPath: props.viewPath,
  })
  let editorApi = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    path: 'note_template_url',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    editorApi.insertContent(preserveLineBreaks(content))
    change(null)
  }, [])

  return <View {...props} />
}
