// this is an autogenerated file from DesignSystem/PreviewDocument/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content($resource_id: numeric!) {
  resource_object_meta: vaxiom_resource_object_meta(
    resource_id: $resource_id
    resource_type: document
  ) {
    name
    source_key
    content_type
    size
    preview_url
    url
  }
}

`