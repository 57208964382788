import {
  useDataChange,
  useDataOriginalValue,
  useDataValue,
  useOnChangeLastValueGuard,
} from 'Simple/Data.js'

export default function useListItemDataOnChange(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'answers',
    viewPath: props.viewPath,
  })

  let category = useDataOriginalValue({
    viewPath: props.viewPath,
    context: 'category',
  })

  return useOnChangeLastValueGuard(function onChange(next) {
    change(nextAnswers => {
      if (!Array.isArray(nextAnswers.changes)) {
        nextAnswers.changes = []
      }

      let originalQuestion = category.questions.find(
        question => question.id === next.id
      )

      if (nextAnswers.changes.length) {
        nextAnswers.changes = nextAnswers.changes.filter(
          item => next.id !== item.question_id
        )
      }

      if (
        originalQuestion?.answer !== next.answer ||
        originalQuestion?.alert !== next.alert
      ) {
        nextAnswers.changes.push({
          ...next,
          question_id: next.id,
          patient_id,
        })
      }
      nextAnswers.has_changes = !!nextAnswers.changes.length
    })
  })
}
