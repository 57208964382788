// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Images/AllImages/Content/PatientImage/Content/SendSecurely/Content/Recipients/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Images_AllImages_Content_PatientImage_Content_SendSecurely_Content_Recipients($id: numeric!) {
  vaxiom_patients_by_pk(id: $id) {
    id
    _id
    person {
      id
      first_name
      last_name

      to_person_relationships(where: { permitted_to_see_info: { _eq: true } }) {
        id
        type
        from_person_record {
          id
          first_name
          last_name
        }
      }
    }

    professional_relationships {
      id
      dtype
      employee {
        id
        provider {
          id
          employee_resources(where: { invalid: { _eq: false } }) {
            id
            employment_contract {
              id
              person {
                id
                first_name
                last_name
              }
            }
          }
        }
      }

      other {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`