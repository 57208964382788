// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/mutation_credit_card_manual.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content(
  $amount: numeric!
  $reference_number: String
  $notes: String
  $location_id: uuid!
  $payment_account_id: numeric!
  $patient_id: uuid!
  $insured_id: numeric
  $payment_type_id: numeric!
  $transaction_id: String!
  $effective_date: date!
  $auto_apply_to_unpaid_receivables: Boolean!
  $receivable_id: numeric
) {
  payments_post_payment_credit_card_manual(
    amount: $amount
    insured_id: $insured_id
    location_id: $location_id
    notes: $notes
    patient_id: $patient_id
    payment_account_id: $payment_account_id
    payment_type_id: $payment_type_id
    reference_number: $reference_number
    transaction_id: $transaction_id
    effective_date: $effective_date
    auto_apply_to_unpaid_receivables: $auto_apply_to_unpaid_receivables
    receivable_id: $receivable_id
  ) {
    payment_id
  }
}

`