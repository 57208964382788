// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/FeatureAccess/AddRuleset/Location/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_FeatureAccess_AddRuleset_Location_Content($division_id: numeric!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      parent_id: { _eq: $division_id }
    }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`