import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let charge_types = useDataValue({
    context: 'charge_types',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'charge_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!charge_types) return

    change(next => {
      let charge = next.charges[index]
      if (!charge.type_id && charge.name) {
        let charge_type = charge_types.find(
          charge_type => charge_type.name === charge.name
        )
        charge.type_id = charge_type ? charge_type.id : next.name
      }
    })
  }, [charge_types])

  return <View {...props} />
}
