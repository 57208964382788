import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let chair_ids = useDataValue({
    viewPath: props.viewPath,
    context: 'selected_chair_ids',
  })

  return useMemo(() => {
    if (!data) return data

    return data.filter(item => chair_ids.includes(item.seated_chair_uuid))
  }, [data, chair_ids])
}
