import React, { useMemo } from 'react'
import VanillaQR from './vanillaqr.js'

export default function QrCode({
  value,
  size = 280,
  colorLight = '#ffffff',
  colorDark = '#212529',
  toTable = false,
  eccLevel = 1,
  noBorder = true,
  borderSize = 4,
  marginTop = 8,
  marginLeft = 8,
  marginRight = 8,
  marginBottom = 8,
}) {
  let src = useMemo(() => {
    let qr = new VanillaQR({
      url: value,
      size,
      colorLight,
      colorDark,
      //output to table or canvas
      toTable,
      //Ecc correction level 1-4
      ecclevel: eccLevel,
      //Use a border or not
      noBorder,
      //Border size to output at
      borderSize,
    })
    return qr.toImageSrc('png')
  }, [
    value,
    size,
    colorLight,
    colorDark,
    toTable,
    eccLevel,
    noBorder,
    borderSize,
  ])

  return (
    <img
      alt={value}
      src={src}
      width={size}
      height={size}
      style={{ marginTop, marginLeft, marginRight, marginBottom }}
    />
  )
}
