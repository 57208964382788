import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    setTimeout(() => {
      submit({ type: 'patientOverlay/close' })
    })
  }
}
