import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company.id',
    viewPath: props.viewPath,
  })

  let tx_plan_group_id = useDataValue({
    context: 'ruleset',
    path: 'tx_plan_group_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      parent_company_id,
      tx_plan_group_id,
    },
    pause: !parent_company_id,
  }
}
