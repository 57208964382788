// this is an autogenerated file from App/Account/Content/Financial/Content/Statements/Data/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Financial_Content_Statements_Data($where: financial_due_statements_bool_exp!) {
  financial_due_statements(where: $where, limit: 10000) {
    id
    due_now_amount
    due_buckets
    due_receivables
    account_balance
    created_at
    payer_person {
      id
      first_name
      last_name
      contact_method_associations(
        where: { contact_method: { dtype: { _eq: "postal" } } }
        limit: 1
      ) {
        id
        postal_address {
          id
          address_line1
          address_line2
          city
          state
          zip
        }
      }
    }
    patient {
      id
      _id
      human_readable_id
      person {
        id
        first_name
        last_name
      }
    }
    location {
      id
      _id
      name
    }
  }
}

`