import React, { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import View from './view.js'

// Twilio supports up to 10 attachments, as long as the total size of
// the message body text and all attachments is less than 5 MB.
// To send files that are not jpeg, png or gif images, it is
// recommended using attachments no larger than 600KB as we don't
// know the MMS-carrier of the receiver

let MAX_FILE_SIZE_NOT_IMAGE_MMS = 1024 * 600 // 600KB
let MAX_FILE_SIZE_NOT_IMAGE_MMS_ERROR = `Non-image files must be no larger than ${
  MAX_FILE_SIZE_NOT_IMAGE_MMS / 1024
} KB`
let MAX_NUMBER_OF_FILES_MMS = 10
let MAX_MMS_SIZE = 1024 * 1024 * 5 // 5MB
let ALLOWED_FILE_TYPES_MMS = [
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/png',
  'video/*',
  '.3gp',
]

export default function Logic(props) {
  let message = useDataValue({
    context: 'message_compose',
    path: 'message',
    viewPath: props.viewPath,
  })

  let fileUploaderData = useMemo(
    () => ({
      restrictions: {
        maxNumberOfFiles: MAX_NUMBER_OF_FILES_MMS,
        maxTotalFileSize: MAX_MMS_SIZE - (message?.length || 0),
        allowedFileTypes: ALLOWED_FILE_TYPES_MMS,
      },
      onBeforeFileAdded: (currentFile, files) => {
        if (
          !/image/i.test(currentFile.type) &&
          currentFile.size > MAX_FILE_SIZE_NOT_IMAGE_MMS
        ) {
          return {
            status: false,
            notification: {
              message: MAX_FILE_SIZE_NOT_IMAGE_MMS_ERROR,
              type: 'error',
            },
          }
        }

        return {
          status: true,
        }
      },
    }),
    [message]
  )

  return (
    <View
      viewPath={props.viewPath}
      restrictions={fileUploaderData.restrictions}
      onBeforeFileAdded={fileUploaderData.onBeforeFileAdded}
    />
  )
}
