export function getView(treatment) {
  return (
    [
      'NEW_PATIENT',
      'OBSERVATION',
      'PENDING_CONSULTATION',
      'PENDING_TREATMENT',
      'LONG_TERM_PENDING',
      'RETENTION',
      'ACTIVE',
    ].includes(treatment.status) || treatment.is_active
  )
}
