import differenceInHours from 'date-fns/differenceInHours'
import {
  isAppointmentScheduled,
  isFutureAppointmentBooking,
} from 'Data/format.js'

export function isEditable(note, appointment) {
  return (
    differenceInHours(new Date(), Date.parse(note.sys_created)) <= 24 ||
    !isAppointmentScheduled(appointment) ||
    isFutureAppointmentBooking(appointment.booking)
  )
}
