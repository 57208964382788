import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
    path: 'appointment.id',
  })

  return {
    variables: { appointment_id },
    pause: !appointment_id,
  }
}
