import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let item = useDataValue({
    context: 'item',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'list_dialog',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (value) {
        next.selected.push(item.id)
      } else {
        next.selected = next.selected.filter(id => id !== item.id)
      }

      props.onChange && props.onChange(next.selected)
    })
  }
}
