import { DataProvider } from 'Simple/Data.js'
import React from 'react'
import View from './view.js'

let value = {
  note: '',
}

export default function Logic(props) {
  return (
    <DataProvider
      context="selected_note"
      value={value}
      viewPath={props.viewPath}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )
}
