import React from 'react'
import View from './view.js'
import {
  useFloating,
  flip,
  offset,
  shift,
  autoUpdate,
} from '@floating-ui/react-dom'

export default function Logic(props) {
  let { x, y, refs } = useFloating({
    open: props.showDialog,
    elements: {
      reference: {
        getBoundingClientRect: () => props.position,
      },
    },
    middleware: [
      offset({ mainAxis: 8, alignmentAxis: 72 }),
      flip({ fallbackPlacements: ['bottom-end', 'top-start', 'top-end'] }),
      shift({ padding: 5 }),
    ],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  })

  return (
    <View
      {...props}
      contentRef={refs.setFloating}
      isOpen={props.showDialog}
      top={y}
      left={x}
    />
  )
}
