import React from 'react'
import { useDesignTokenValue } from 'Simple/DesignTokens.js'

import View from './view.js'
import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let percentage = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_progress',
    path: 'percentage',
  })
  let green = useDesignTokenValue({
    viewPath: props.viewPath,
    path: 'colors.green.700',
  })
  let orange = useDesignTokenValue({
    viewPath: props.viewPath,
    path: 'colors.orange.700',
  })
  let red = useDesignTokenValue({
    viewPath: props.viewPath,
    path: 'colors.red.700',
  })
  return (
    <View
      backgroundColor={
        percentage < 75 ? green : percentage > 100 ? red : orange
      }
      {...props}
    />
  )
}
