import { useClient } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataChange } from 'Simple/Data.js'
import query from './query_list_paypoints.graphql.js'

export function validate(value) {
  return /^o\./.test(value) && value.length > 100
}

export function useOnChange(props) {
  let [, notify] = useNotifications()
  let client = useClient()

  let change = useDataChange({
    context: 'add_provider',
    viewPath: props.viewPath,
  })

  return async function onChange(token) {
    change(next => {
      next.payabli.token = token
    })
    // 1. Check if entered token is valid
    if (!validate(token)) return

    // 2. Call the action to get the paypoints
    let response = await client.query(query, { token_id: token }).toPromise()

    if (response.data) {
      change(next => {
        next.payabli.paypoints = response.data.payments_list_paypoints
      })
    } else if (response.error) {
      notify(
        notifyError(
          'There was an error fetching the paypoints using the provided token id'
        )
      )
    }
  }
}
