import React from 'react'
import View from './view.js'

export default function Logic(props) {
  // when disabled and has a single option selected there is no need to show the tags
  // as the input already shows the option and it saves up some space
  return !props.disabled || props.selected.length > 1 ? (
    <View {...props} onClick={onClick} />
  ) : null

  function onClick(id) {
    if (typeof props.onChange !== 'function') return

    props.onChange(props.value.filter(item => item !== id))
  }
}
