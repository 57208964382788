import { useDataChange } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnImageAnalyze(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let change = useDataChange({
    context: 'tab',
    path: 'selected.images.ceph_image_id',
    viewPath: props.viewPath,
  })

  return async function onImageAnalyze(id) {
    change(id)
    setFlowTo(
      normalizePath(
        props.viewPath,
        '../../../../../../../CephTracing/PerformTrace'
      )
    )
  }
}
