// this is an autogenerated file from DesignSystem/CommsThread/Content/EventsTimeline/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__DesignSystem_CommsThread_Content_EventsTimeline($ids: [uuid!]!, $limit: Int!) {
  comms_events(
    where: { thread_id: { _in: $ids } }
    limit: $limit
    order_by: { created_at: desc }
  ) {
    id
    thread_id
    type
    created_at
    updated_at
  }
}

`