import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'

export function useOnDelete(props) {
  let temporary_image_id = useDataValue({
    context: 'temporary_image',
    path: 'id',
    viewPath: props.viewPath,
  })

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onDelete() {
    let mutationResponse = await executeMutation({
      id: temporary_image_id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't delete the temporary image`))
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
