import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import addMonths from 'date-fns/addMonths'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let tx_id = useDataValue({
    context: 'treatment_card',
    path: 'txs._id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    if (!value.start_date || !isTreatmentLengthValid(value.length_in_months)) {
      return true
    }

    let mutationResponse = await executeMutation({
      tx_id,
      start_date: new Date(value.start_date),
      end_date: addMonths(new Date(value.start_date), value.length_in_months),
    })

    if (mutationResponse.error) {
      return notify(
        notifyError(`Cannot update treatment's length. Please try again.`)
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function isTreatmentLengthValid(length_in_months) {
  return !!length_in_months && length_in_months > 0 && length_in_months <= 1200
}
