import { useMemo } from 'react'
import { PAYMENT_TYPE_INT_TO_PAYMENTS_PAYMENT_TYPE } from 'Data/common-constants.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      location_accounts: data.location_accounts,
      patient_due_amount: data.patient_due_amount?.[0]?.due_now ?? null,
      patient: data.patient,
      person: data.patient.person,
      receivable: data.receivable,
      payment_methods: data.payment_methods.map(payment_type => ({
        id: payment_type.id,
        text: payment_type.name,
        data: {
          payment_type_id: payment_type.id,
          payments_payment_type:
            PAYMENT_TYPE_INT_TO_PAYMENTS_PAYMENT_TYPE[payment_type.type],
        },
      })),
    }
  }, [data])
}
