import { useDataChange } from 'Simple/Data'

export function getView(is_toolbar_hidden) {
  return is_toolbar_hidden ? 'Show' : 'Hide'
}

export function useOnToggleToolbar(props) {
  let setToolbarHidden = useDataChange({
    context: 'gallery',
    path: 'is_toolbar_hidden',
    viewPath: props.viewPath,
  })

  return function toggleToolbar() {
    setToolbarHidden(next => {
      next.is_toolbar_hidden = !next.is_toolbar_hidden
    })
  }
}
