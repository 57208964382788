import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    if (data && data.length === 0) return null

    return data[0]
  }, [data])
}
