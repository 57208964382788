import { useDataSubmit, useDataIsSubmitting, useDataValue } from 'Simple/Data'

import { validate } from '../helpers.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'payments_request_payment',
  })

  return function onClick() {
    submit({
      type: 'form_submit',
    })
  }
}

export function useIsDisabled(props) {
  let value = useDataValue({
    context: 'payments_request_payment',
    viewPath: props.viewPath,
  })
  let isSubmitting = useDataIsSubmitting({
    context: 'payments_request_payment',
    viewPath: props.viewPath,
  })

  return isSubmitting || validate(value).isInvalid
}
