import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    path: 'patient_notifications_patient_id',
    context: 'global',
  })

  return {
    variables: {
      patient_id,
    },
    pause: !patient_id,
  }
}
