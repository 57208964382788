// this is an autogenerated file from DesignSystem/ImageCard/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_ImageCard(
  $temporary_image_id: numeric!
  $image_series_id: numeric!
  $image_id: numeric
  $type_key: String!
  $slot_key: String!
) {
  vaxiom_patient_images_create(
    temporary_image_id: $temporary_image_id
    image_series_id: $image_series_id
    image_id: $image_id
    type_key: $type_key
    slot_key: $slot_key
  ) {
    id
  }
}

`