import differenceInMinutes from 'date-fns/differenceInMinutes'

export function filterByActiveTab(tab, appointment_bookings) {
  if (tab === 'all') {
    return appointment_bookings.filter(
      booking => booking.state === 'CHECKED_IN'
    )
  }

  return appointment_bookings
    .filter(booking => booking.state === 'CHECKED_IN')
    .filter(booking => {
      let waitingTime = differenceInMinutes(
        new Date(),
        // stored at UTC in DB, but as timestamp so doesn't include timezone offset
        new Date(`${booking.check_in_time}Z`)
      )
      if (tab === 'greater_than_20_mins' && waitingTime >= 20) {
        return true
      } else if (
        tab === 'greater_than_10_mins' &&
        waitingTime >= 10 &&
        waitingTime < 20
      ) {
        return true
      } else {
        return false
      }
    })
}
