import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let changeIndex = useDataValue({
    viewPath: props.viewPath,
    context: 'new_payment_plan_installment_date_item',
    path: 'index',
  })

  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === null) return
    submit({
      type: 'update_installment_date',
      value: value + 1,
      changeIndex,
    })
  }
}

export function getSelectedValue(arr, index) {
  return arr[index] - 1
}
