import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !data?.length) return data

    return data.map(organization => ({
      id: organization._id,
      parent_id: organization.payment_plan_frequencies[0].parent_id,
      division_id: organization.payment_plan_frequencies[0].division_id,
      location_id: organization.payment_plan_frequencies[0].location_id,
      frequencies: getFrequencies(organization.payment_plan_frequencies),
    }))
  }, [data])
}

function getFrequencies(values) {
  let frequencies = [
    {
      id: null,
      type: 'Monthly',
      label: 'Monthly',
      allowed_dates: [],
      allowed_date_type: 'MonthDays',
      enabled: false,
      document_template_id: null,
    },
    {
      id: null,
      type: 'TwicePerMonth',
      label: 'Twice Per Month',
      allowed_dates: [],
      allowed_date_type: 'MonthDays',
      enabled: false,
      document_template_id: null,
    },
    {
      id: null,
      type: 'Weekly',
      label: 'Weekly',
      allowed_dates: [],
      allowed_date_type: 'WeekDays',
      enabled: false,
      document_template_id: null,
    },
    {
      id: null,
      type: 'EverySecondWeek',
      label: 'Bi-Weekly',
      allowed_dates: [],
      allowed_date_type: 'WeekDays',
      enabled: false,
      document_template_id: null,
    },
    {
      id: null,
      type: 'None',
      label: 'None',
      enabled: true,
      allowed_dates: [],
      allowed_date_type: 'No',
      document_template_id: null,
    },
  ]

  return frequencies.map(f => {
    let value = values.find(v => v.type === f.type)
    if (!value) return f

    return {
      ...f,
      id: value.id,
      label: value.label,
      allowed_dates: value.allowed_dates,
      enabled: true,
      document_template_id: value.document_template_id,
    }
  })
}
