import { useDataValue } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'

export function useOnClick(props) {
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    await executeMutation({
      transaction_id: prompt('payments.transactions.id'),
      amount: parseFloat(prompt('Amount to refund')),
    })
  }
}
