// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/updateProfessionalMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content(
  $person_id: numeric!
  $first_name: String!
  $middle_name: String
  $last_name: String!
  $greeting: String
  $title: String
  $other_professional_relationship_id: numeric!
  $employee_type_id: numeric!
  $note_id: numeric
  $note: String
  $create_note: Boolean!
  $update_note: Boolean!
  $location_id: numeric!
) {
  update_vaxiom_persons_by_pk(
    pk_columns: { id: $person_id }
    _set: {
      first_name: $first_name
      middle_name: $middle_name
      last_name: $last_name
      greeting: $greeting
      title: $title
    }
  ) {
    id
  }
  update_vaxiom_other_professional_relationships_by_pk(
    pk_columns: { id: $other_professional_relationship_id }
    _set: { employee_type_id: $employee_type_id }
  ) {
    id
  }

  update_vaxiom_notes(where: { id: { _eq: $note_id } }, _set: { note: $note })
    @include(if: $update_note) {
    returning {
      id
    }
  }

  insert_vaxiom_notes_one(
    object: {
      note: $note
      target_type: "com.axpm.base.person.domain.Person"
      target_id: $person_id
      org_id: $location_id
      alert: false
    }
  ) @include(if: $create_note) {
    id
  }
}

`