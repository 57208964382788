import './style.css'
import React, { useEffect, useRef } from 'react'
import View from './view.js'
import { useDataSubmit } from 'Simple/Data.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })
  let submitRef = useRef(submit)

  useEffect(() => {
    submitRef.current = submit
  }, [submit])

  useEffect(() => {
    let cancel = false
    // we need a timeout because the initialise's submit
    // action sometimes takes a bit longer and blocks
    // this action from running
    setTimeout(() => {
      if (cancel) return
      submitRef.current({ type: 'render' })
    }, 100)

    return () => {
      cancel = true
    }
  }, [])

  return <View {...props} />
}
