import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

let INTERVALS = [0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 24, 36, 48, 72, 96].map(
  interval => ({ id: interval, text: `${interval} w` })
)

export default function useDataTransform(props, data) {
  let interval_to_next = useDataValue({
    context: 'event',
    path: 'appointment.interval_to_next',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (
      typeof interval_to_next !== 'number' ||
      INTERVALS.some(option => option.id === interval_to_next)
    ) {
      return INTERVALS
    }
    let list = [...INTERVALS]
    let new_option = { id: interval_to_next, text: `${interval_to_next} w` }
    let index = list.findIndex(option => new_option.id <= option.id)

    // If the new item is greater than all items in the list, append it to the end.
    if (index === -1) {
      list.push(new_option)
    } else {
      list.splice(index, 0, new_option)
    }

    return list
  }, [interval_to_next])
}
