import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

export default function useDataConfiguration(props) {
  let search_term = useDataValue({
    context: 'search_payor',
    path: 'search_term',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person.id',
  })
  let selected_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'payor',
    path: 'person_id',
  })

  let search_term_stable = useStableValue(`%${search_term}%`)

  return {
    variables: {
      person_id,
      selected_person_id: selected_person_id || person_id,
      parent_company_id,
      search_term: search_term_stable,
      with_search: search_term_stable.length >= 5,
      with_selected_payor: !!selected_person_id,
    },
  }
}
