// this is an autogenerated file from DesignSystem/TimelineEvent/Note/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Note($id: uuid!) {
  vaxiom_notes(where: { _id: { _eq: $id } }) {
    id
    note
    date: sys_created
    creator {
      id
      person {
        id
        first_name
        last_name
      }
    }
    editor {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`