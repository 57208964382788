import { useDataChange, useDataValue } from 'Simple/Data.js'
import { dateShortOutTempFixForMaskedInput } from 'Data/format.js'
import { isWeekly } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      let first_due_date = dateShortOutTempFixForMaskedInput(value)
      payor.first_due_date = first_due_date

      if (isWeekly(payor.installment_interval)) {
        let day_of_the_week = new Date(first_due_date).getDay()
        // expecting to be 1-7, while JS returns 0-6 (starting with Sunday)
        payor.first_installment_date =
          day_of_the_week === 0 ? 7 : day_of_the_week
      } else {
        payor.first_installment_date = new Date(first_due_date).getDate()
      }
    })
  }
}
