import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let current_location = useDataValue({
    context: 'location',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    submit({ current_location })
  }
}
