import { useEffect } from 'react'
import { useClient, gql } from 'Data/Api.js'
import Plugin from './plugin.js'
import { v4 as uuid } from 'uuid'
import { captureError } from 'Logic/ErrorBoundary.js'

let FILE_PART_SIZE = 1024 * 1024 * 5 // 5MB

export default function useFileUploaderPluginS3({
  uppy,
  Bucket,
  prefix,
  excludeKeyFileExtension,
}) {
  let client = useClient()

  useEffect(() => {
    if (!Bucket) return

    /** @param {[{ type: string, payload: object }]} list */
    async function sendCommands(list) {
      let { data } = await client.mutation(MUTATION, { list }).toPromise()
      return data?.aws_s3_send_commands
    }
    /** @param {{ type: string, payload: object }} command */
    async function sendCommand(command) {
      let commands = await sendCommands([command])
      return commands?.[0]?.response
    }

    // this one is tricky as Uppy overrides the "this" reference and as a result it will point to an outdated version of those dependencies
    // here we have to re-register the plugin in order for the changes to take effect
    let plugin = uppy.getPlugin(Plugin.pluginName)
    if (plugin) {
      uppy.removePlugin(plugin)
    }

    uppy.use(Plugin, {
      id: Plugin.pluginName,
      getChunkSize(file) {
        return Math.ceil(file.size / FILE_PART_SIZE)
      },
      async createMultipartUpload(file) {
        try {
          let Key = file.meta?.useRawName
            ? file.name
            : excludeKeyFileExtension
            ? uuid()
            : `${uuid()}.${file.extension}`

          let response = await sendCommand({
            type: 'CreateMultipartUpload',
            payload: {
              Bucket,
              // TODO review if we want to use a specific name, maybe use a
              // metadata field in the file or let it be autogenerated
              Key: `${prefix || file.meta?.prefix}/${Key}`,
              ContentType: getContentType(file),
            },
          })

          return {
            uploadId: response.UploadId,
            key: response.Key,
          }
        } catch (error) {
          captureError(error, {
            type: 's3-uploader-plugin/create-multipart-upload',
          })
          throw error
        }
      },
      listParts() {
        return []
      },
      async signPart(_, { body, key, uploadId, partNumber }) {
        try {
          let payload = {
            Bucket,
            Key: key,
            // Body: body,
            UploadId: uploadId,
            PartNumber: partNumber,
          }

          let response = await sendCommand({
            type: 'UploadPartSigned',
            payload,
          })

          return { url: response, headers: {} }
        } catch (error) {
          captureError(error, {
            type: 's3-uploader-plugin/sign-part',
          })
          throw error
        }
      },
      async abortMultipartUpload(file, { uploadId, key }) {
        try {
          return await sendCommand({
            type: 'AbortMultipartUpload',
            payload: {
              Bucket,
              Key: key,
              UploadId: uploadId,
            },
          })
        } catch (error) {
          captureError(error, {
            type: 's3-uploader-plugin/abort-multipart-upload',
          })
          throw error
        }
      },
      async completeMultipartUpload(file, { uploadId, key, parts }) {
        try {
          let response = await sendCommand({
            type: 'CompleteMultipartUpload',
            payload: {
              Bucket,
              Key: key,
              UploadId: uploadId,
              MultipartUpload: { Parts: parts },
            },
          })
          uppy.setFileMeta(file.id, {
            location: decodeURIComponent(response.Location),
          })
          return { location: null }
        } catch (error) {
          captureError(error, {
            type: 's3-uploader-plugin/complete-multipart-upload',
          })
          throw error
        }
      },
    })
  }, [uppy, prefix, client, Bucket, excludeKeyFileExtension])
}

function getContentType(file) {
  if (file.type === 'application/octet-stream') {
    switch (file.extension) {
      case '3gp':
        return 'video/3gpp'
      default:
        return file.type
    }
  }

  return file.type
}

let MUTATION = gql`
  mutation s3_files($list: [aws_s3_command_input!]!) {
    aws_s3_send_commands(list: $list) {
      type
      response
    }
  }
`
