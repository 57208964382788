import View from './view.js'
import React, { useMemo } from 'react'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'id',
      },
      {
        field: 'amount',
        headerName: 'amount',
      },
      {
        field: 'status',
        headerName: 'status',
      },
      {
        field: 'reference_id',
        headerName: 'reference_id',
      },
      // {
      //   field: 'verified_at',
      //   headerName: 'verified_at',
      // },
      {
        field: 'token',
        headerName: 'last_4 & exp',
        valueGetter: params => {
          return `${params.data.token.last_4} ${params.data.token.exp}`
        },
      },
    ],
    []
  )
  return <View {...props} columnDefs={columnDefs} />
}
