import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'showPatient',
      tab: 'treatment_card',
    })
  }
}

export function getInitialWithDot(rvalue) {
  return `${rvalue[0]}.`
}

export function isUrgent(provider_call) {
  if (!provider_call) return false
  return (
    provider_call.calling_since &&
    !provider_call.arrived_at &&
    !provider_call.left_at &&
    provider_call.urgent
  )
}

export function isDoctorCalled(provider_call) {
  if (!provider_call) return false
  return (
    provider_call.calling_since &&
    !provider_call.arrived_at &&
    !provider_call.left_at &&
    !provider_call.urgent
  )
}
