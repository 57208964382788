// this is an autogenerated file from App/Auth/Check/query-user.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Auth_Check($user_id: uuid!) {
  preferences: user_preferences_by_pk(user_id: $user_id) {
    id: user_id
    user_id
    chair_ids
    current_location_id
  }
  locations: session_user_locations(order_by: { name: asc }) {
    id
    _id
  }
}

`