// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-delete-appointment.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($id: uuid!) {
  vaxiom_appointment_delete(id: $id) {
    id
  }
}

`