import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick(value) {
    switch (value) {
      case 'RENAME':
        return rename()
      case 'REORDER_ENTRIES':
        return reorderEntries()
      case 'START_CONTRACT':
        return startContract()
      case 'PRINT_PDF':
        return printPDF()
      case 'EDIT_TREATMENT_LENGTH':
        return editLength()
      case 'EDIT_TREATMENT_LOCATION':
        return editLocation()
      case 'MOVE_TO_ANOTHER_TREATMENT':
        return moveToAnotherTreatment()
      case 'ASSIGN_COLORS':
        return assignColors()
      case 'COMPLETE_TREATMENT':
        return completeTreatment()
      case 'CHANGE_TREATMENT_STATUS':
        return changeStatus()
      case 'QUESTIONNAIRES':
        return openQuestionnaires()
      case 'DELETE':
        return deleteTreatment()
      default:
        return
    }
  }

  function rename() {
    setFlowTo(normalizePath(props.viewPath, 'Rename/Content'))
  }

  function reorderEntries() {
    setFlowTo(normalizePath(props.viewPath, 'ReorderEntries/Content'))
  }

  function startContract() {
    setFlowTo(normalizePath(props.viewPath, 'StartContract/Content'))
  }

  function printPDF() {
    setFlowTo(normalizePath(props.viewPath, 'PrintPDF/Content'))
  }

  function editLength() {
    setFlowTo(normalizePath(props.viewPath, 'EditLength/Content'))
  }

  function editLocation() {
    setFlowTo(normalizePath(props.viewPath, 'EditLocation/Content'))
  }

  function moveToAnotherTreatment() {
    setFlowTo(normalizePath(props.viewPath, 'MoveToAnotherTreatment/Content'))
  }

  function assignColors() {
    setFlowTo(normalizePath(props.viewPath, 'AssignColors/Content'))
  }

  function completeTreatment() {
    setFlowTo(normalizePath(props.viewPath, 'CompleteTreatment/Content'))
  }

  function changeStatus() {
    setFlowTo(normalizePath(props.viewPath, 'ChangeStatus/Content'))
  }

  function openQuestionnaires() {
    setFlowTo(normalizePath(props.viewPath, 'Questionnaires/Content'))
  }

  function deleteTreatment() {
    setFlowTo(normalizePath(props.viewPath, 'Delete/Content'))
  }
}
