import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'new_patient',
    viewPath: props.viewPath,
  })
  return async function onClick(value) {
    submit({
      check_similar: true,
    })
  }
}
