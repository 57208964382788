import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payers',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      let nextPayer = next.payers.find(p => !p.completed)
      next.activeTab = nextPayer
      next.activeTab.view = nextPayer.data.downpayment
        ? 'DownpaymentSetup'
        : 'AutopaymentSetup'
    })
  }
}
