import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let currentLocation = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let time_zone_id = currentLocation.time_zone_id || DEFAULT_TIMEZONE
    return {
      ...data,
      selected: {
        ...data.selected,
        parent_company_id,
        location_id: currentLocation._id,
        vaxiom_location_id: currentLocation.id,
        time_zone_id,
        date: startOfDay(utcToZonedTime(new Date(), time_zone_id)),
        // static value, do not update this value from outside
        location_current_date: startOfDay(
          utcToZonedTime(new Date(), time_zone_id)
        ),
      },
      sidebar: {
        ...data.sidebar,
        date: startOfDay(utcToZonedTime(new Date(), time_zone_id)),
      },
    }
  }, [
    data,
    currentLocation._id,
    currentLocation.id,
    currentLocation.time_zone_id,
    parent_company_id,
  ])
}
