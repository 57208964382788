import { useDataSubmit } from 'Simple/Data.js'

export function useOnUpdate(props) {
  let submit = useDataSubmit({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onUpdate() {
    submit({ type: 'update' })
  }
}

export function useOnCancel(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onCancel() {
    submit({ type: 'back' })
  }
}
