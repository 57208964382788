import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  return async function onSubmit({ value, args }) {
    if (!value.address_line1 || !value.city || !value.state || !value.zip) {
      return true
    }

    let { id, address_line1, city, state, zip } = value

    let mutationResponse = await executeMutation({
      id,
      address_line1,
      city,
      state,
      zip,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
