import {
  localStorage,
  windowAddEventListenerStorage,
  windowRemoveEventListenerStorage,
} from 'Logic/localStorage'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useEffect } from 'react'
import { useRefreshToken } from 'Data/Auth.js'

let INACTIVITY_TRACKER_KEEP_SESSION_OPEN_KEY =
  'inactivity_tracker_keep_session_open'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let refreshToken = useRefreshToken(props)

  useEffect(() => {
    function listener(event) {
      if (event.key === INACTIVITY_TRACKER_KEEP_SESSION_OPEN_KEY) {
        console.debug({
          type: 'inactivity/KeepSessionOpenAction',
          event,
        })
        setFlowTo(normalizePath(props.viewPath, '../../No'))
      }
    }

    windowAddEventListenerStorage(listener)

    return () => {
      windowRemoveEventListenerStorage(listener)
      localStorage.removeItem(INACTIVITY_TRACKER_KEEP_SESSION_OPEN_KEY)
    }
  }, [props.viewPath, setFlowTo])

  return async function onClick() {
    try {
      await refreshToken()
      localStorage.setItem(INACTIVITY_TRACKER_KEEP_SESSION_OPEN_KEY, Date.now())
      setFlowTo(normalizePath(props.viewPath, '../../No'))
    } catch (error) {
      setFlowTo('/App/Auth/SignOut')
    }
  }
}
