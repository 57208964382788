// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/TxCardFields/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding_TxCardFields(
  $objects: [vaxiom_tx_card_field_types_insert_input!]!
) {
  insert_vaxiom_tx_card_field_types(objects: $objects) {
    returning {
      id
    }
  }
}

`