import * as Sentry from '@sentry/react'
import SentryErrorBoundaryFallback from './SentryErrorBoundaryFallback/index.js'

export default function SentryErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary
      fallback={context => (
        <SentryErrorBoundaryFallback
          viewPath={props.viewPath}
          context={context}
          onClickSignOut={
            props.onClickSignOut
              ? () => {
                  context.resetError()
                  props.onClickSignOut()
                }
              : null
          }
        />
      )}
      beforeCapture={scope => {
        scope.setTag('viewPath', props.viewPath)
      }}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
