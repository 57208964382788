import { useDataValue } from 'Simple/Data'

export default function useDefaultTab(props, data) {
  let current_location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id, event_id) {
    if (maybeTab?.selected) {
      return {
        ...maybeTab,
        sync: {
          event_id,
        },
      }
    }

    return {
      ...data,
      id,
      type: id,
      name: id,
      viewPath: props.viewPath,
      selected: {
        ...data.selected,
        parent_company_id: current_location.parent_company._id,
        location_id: current_location._id,
        location_numeric_id: current_location.id,
      },
      sync: {
        event_id,
      },
    }
  }
}
