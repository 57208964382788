import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return [
      { type: 'InApp', id: 'InApp' },
      ...data
        .filter(item => item.contact_method.mobile_phone?.number)
        .map(item => ({
          id: item.id,
          type: 'Phone',
          number: item.contact_method.mobile_phone?.number,
          description: item.description,
        })),
      { type: 'Discussion', id: 'Discussion' },
    ]
  }, [data])
}
