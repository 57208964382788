// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PhoneNumbers/AddPhoneNumber/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PhoneNumbers_AddPhoneNumber_Content(
  $name: String!
  $parent_company_id: uuid!
  $number: PhoneNumber!
  $call_forwarding_number: PhoneNumber
) {
  comms_mobile_numbers_outbound_setup(
    name: $name
    parent_company_id: $parent_company_id
    number: $number
    call_forwarding_number: $call_forwarding_number
  ) {
    mobile_number_outbound {
      id
      metadata
      call_forwarding_number
      number
      name
      parent_company_id
    }
  }
}

`