import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let organization_id = useDataValue({
    context: 'selected',
    path: 'event_execution_data.parent_company_id',
    viewPath: props.viewPath,
  })

  let types = useDataValue({
    context: 'selected',
    path: 'data.statuses',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      organization_id,
      types: types ?? [],
      has_subset: !!types?.length,
    },
    pause: !organization_id,
  }
}
