import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnToggle(props) {
  let insurances = useDataValue({
    context: 'payment_plan',
    path: 'insurances',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'collapsible',
    viewPath: props.viewPath,
  })

  return function onChange() {
    // don't allow to expand when there is no insurance
    if (insurances.length === 0) return

    change(next => {
      next.is_collapsed = !next.is_collapsed
    })
  }
}
