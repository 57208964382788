import React from 'react'
import { getS3ObjectUrl, useGetSignedUrl, withAwsCredentials } from 'Data/s3.js'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default withAwsCredentials(function Logic(props) {
  let bucket = useDataValue({
    context: 'aws_credentials',
    path: 'storage_bucket_name',
    viewPath: props.viewPath,
  })
  let region = useDataValue({
    context: 'aws_credentials',
    path: 'region',
    viewPath: props.viewPath,
  })
  let organization_id = useDataValue({
    context: 'image_example',
    path: 'organization_id',
    viewPath: props.viewPath,
  })
  let slot_key = useDataValue({
    context: 'image_example',
    path: 'slot_key',
    viewPath: props.viewPath,
  })
  let imageUrl = useGetSignedUrl({
    url: getS3ObjectUrl({
      bucket,
      key: `companies/${organization_id}/sample-slot-images/${slot_key}.jpeg`,
      region,
    }),
    filename: `${slot_key}.jpeg`,
    viewPath: props.viewPath,
  })

  return <View {...props} url={imageUrl} />
})
