import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data

    return [
      {
        id: null,
        text: 'No treatment',
      },
      ...data,
    ]
  }, [data])
}
