// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/CompleteTreatment/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_CompleteTreatment_Content($tx_id: uuid!, $status: String!) {
  update_vaxiom_txs(
    where: { _id: { _eq: $tx_id } }
    _set: { status: $status, did_not_start: false, end_date: "now()" }
  ) {
    affected_rows
  }
}

`