import { BasePlugin } from '@uppy/core'

export default class VaxiomFilesUploader extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts)
    this.id = this.opts.id
    this.title = this.opts.title
    this.type = 'uploader'

    let defaultOptions = {}

    this.opts = { ...defaultOptions, ...opts }

    this.i18nInit()

    this.upload = this.upload.bind(this)
  }

  async upload(fileIds) {
    let files = fileIds
      .map(id => this.uppy.getFile(id))
      .filter(file => file.isRemote)
    if (files.length === 0) {
      this.uppy.log(`[${this.id}] No files to upload!`)
      return
    }

    this.uppy.log(`[${this.id}] Uploading...`)

    await Promise.all(
      files.map(async file => {
        this.uppy.emit('upload-started', file)

        try {
          this.uppy.emit('upload-progress', file, {
            uploader: this,
            bytesUploaded: 0,
            bytesTotal: file.size,
          })

          let result = await this.opts.uploadFile.call(this, file)

          this.uppy.emit('upload-progress', file, {
            uploader: this,
            bytesUploaded: file.size,
            bytesTotal: file.size,
          })

          this.uppy.emit('upload-success', file, result)
        } catch (error) {
          this.uppy.emit('upload-error', file, error)
        }

        return file
      })
    )

    return null
  }

  install() {
    this.uppy.addUploader(this.upload)
  }

  uninstall() {
    this.uppy.removeUploader(this.upload)
  }
}
VaxiomFilesUploader.pluginName = 'VaxiomFilesUploader'
