import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let patient_id = useDataValue({
    context: 'patient_documents',
    path: 'patient_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      patient_id,
    },
    pause: !patient_id,
  }
}
