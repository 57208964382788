// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCard/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCard_Content($location_id: uuid!) {
  treatments_settings(where: { location_id: { _eq: $location_id } }) {
    id
    location_id
    data
  }
}

`