import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'urql'
import downloadMutation from './download-mutation.graphql.js'
import launchMutation from './launch-mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionDownload = useDataOnActionDownload()
  let onActionLaunch = useDataOnActionLaunch(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'download':
        return onActionDownload({ value })
      case 'launch':
        return onActionLaunch({ value })
      default:
        return
    }
  }
}

function useDataOnActionDownload() {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(downloadMutation)

  return async function onAction({ value }) {
    notify(
      notifySuccess(`We are downloading the images. Please, wait a moment.`)
    )
    let mutationResponse = await executeMutation({
      scan_id: value.scan_id,
    })

    if (
      mutationResponse.error ||
      mutationResponse.data?.apps_align_scan_request_update?.status === 'failed'
    ) {
      return notify(
        notifyError(`There was a problem downloading the scan results.`)
      )
    }
  }
}

function useDataOnActionLaunch(props) {
  let connection_id = useDataValue({
    context: 'connection',
    path: 'id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(launchMutation)

  return async function onAction({ value }) {
    let mutationResponse = await executeMutation({
      id: value.id,
      connection_id,
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem getting the scan url.`))
    }

    window.open(mutationResponse.data.apps_align_scan_request_launcher.url)
  }
}
