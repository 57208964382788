// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding(
  $type: onboarding_input_types_enum!
  $data_url: String!
) {
  insert_onboarding_inputs_one(object: { type: $type, data_url: $data_url }) {
    id
  }
}

`