// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Unscheduled/More/Content/AppointmentHistory/Content/Main/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Actions_Unscheduled_More_Content_AppointmentHistory_Content_Main($id: uuid!) {
  vaxiom_appointments(where: { _id: { _eq: $id } }) {
    id
    _id
    sys_created
    created_by {
      id
      person {
        id
        _id
        first_name
        last_name
      }
    }
    appointment_bookings(order_by: { sys_created: asc }) {
      id
      _id
      local_start_date
      local_start_time
      state
      created_by {
        id
        person {
          id
          _id
          first_name
          last_name
        }
      }
      modified_by {
        id
        person {
          id
          _id
          first_name
          last_name
        }
      }
      created_by_person {
        id
        _id
        first_name
        last_name
        patient {
          id
          _id
        }
      }
      modified_by_person {
        id
        _id
        first_name
        last_name
        patient {
          id
          _id
        }
      }
      state_changes: appointment_booking_state_changes(
        order_by: { change_time: asc }
      ) {
        id
        _id
        new_state
        old_state
        change_time
      }
    }
  }
}

`