import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataConfiguration(props) {
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'user.id',
  })

  let location_ids = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'user.comms_preferences.location_ids',
  })

  let mobile_numbers_outbound_ids = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'user.comms_preferences.mobile_numbers_outbound_ids',
  })

  return useMemo(() => {
    return {
      variables: {
        resources_ids: [user_id, location_ids, mobile_numbers_outbound_ids]
          .flat()
          .filter(Boolean),
      },
    }
  }, [mobile_numbers_outbound_ids, location_ids])
}
