// this is an autogenerated file from DesignSystem/CaptureImageSeries/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CaptureImageSeries(
  $id: uuid!
  $status: images_request_status_types_enum!
) {
  update_images_requests_by_pk(
    pk_columns: { id: $id }
    _set: { status: $status }
  ) {
    id
    status
  }
}

`