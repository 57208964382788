import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

export default function useOnDataOnSubmit(props) {
  let id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let show_deleted_image_series = useDataValue({
    context: 'tab',
    path: 'selected.images.show_deleted_image_series',
    viewPath: props.viewPath,
  })
  let setRefetch = useDataChange({
    context: 'tab',
    path: 'selected.images.refetch',
    viewPath: props.viewPath,
  })
  let setSelectedImageSerieId = useDataChange({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit() {
    let mutationResponse = await executeMutation({
      id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't delete the image series`))
    }

    setRefetch(Date.now())
    // adding a short timeout so that it won't end up selecting the deleted image series
    setTimeout(() => {
      if (!show_deleted_image_series) {
        setSelectedImageSerieId(null)
      }
      setFlowTo(normalizePath(props.viewPath, '../No'))
    }, 200)
  }
}
