import React, { useEffect } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { useUppy } from 'design-system/FileUploader/react.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useClient } from 'Data/Api.js'
import query from './query.graphql.js'
import View from './view.js'

export default function Logic(props) {
  let thread_id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })
  let attachments = useDataValue({
    context: 'message_compose',
    path: 'auto_uploaded_attachments',
    viewPath: props.viewPath,
  })
  let uppy = useUppy(props)
  let client = useClient()
  let [, notify] = useNotifications()

  useEffect(() => {
    if (!thread_id || !attachments || attachments.length === 0) {
      return
    }

    async function run() {
      uppy.cancelAll()

      // eslint-disable-next-line compat/compat
      let results = await Promise.allSettled(
        attachments.map(async attachment => {
          let res = await client
            .query(query, {
              resource_id: attachment.resource_id,
              resource_type: attachment.resource_type,
            })
            .toPromise()

          if (res.error) {
            console.error(`😱 ${res.error.message}`)
            throw new Error(
              `There was a problem attaching the selected patient ${attachment.resource_type}.`
            )
          }

          let tagFile = {
            name: res.data.resource_object_meta.name,
            type: res.data.resource_object_meta.content_type,
            size: res.data.resource_object_meta.size,
            isRemote: true,
            remote: {
              body: {
                resource_id: attachment.resource_id,
                resource_type: attachment.resource_type,
              },
            },
            data: {
              size: res.data.resource_object_meta.size,
            },
          }
          try {
            uppy.addFile(tagFile)
          } catch (err) {
            if (!err.isRestriction) {
              uppy.log(err)
              uppy.info(
                'There was an error when trying to add your files. Please try again.',
                'error',
                3000
              )
            }
          }
        })
      )
      // changeAttachments([])

      let errors = results.some(result => result.status !== 'fulfilled')
      if (errors) {
        return notify(
          notifyError(
            `There was a problem attaching the selected patient documents or images.`
          )
        )
      }

      // // upload attachments so that everything is prepared when the users sends the message
      // await uppy.upload()
    }
    run()
  }, [attachments]) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
