import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(
    () =>
      data?.map(connection => ({
        ...connection,
        connection_id: connection.id,
        id: `${connection.id}-${connection.app.id}`,
        text: `${connection.app.name} - ${connection.name.toUpperCase()}`,
      })),
    [data]
  )
}
