import { PATIENT_IMAGE_LAYOUT_DEFINITIONS } from 'Data/constants.js'
import { useDataValue } from 'Simple/Data.js'
import { useEffect, useState } from 'react'
import { getS3ObjectUrl, useAwsCredentials } from 'Data/s3.js'

export let NEW_MISC_SLOT = {
  id: 'misc',
  slot_key: null,
  type_key: 'misc',
  patient_image: null,
  original_image: null,
  editor_src: null,
  status: 'done',
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let patient_images = useDataValue({
    context: 'images_request',
    path: 'patient_image_series.patient_images',
    viewPath: props.viewPath,
  })
  let getAwsCredentials = useAwsCredentials(props)
  let [res, setRes] = useState([])

  useEffect(() => {
    async function run() {
      let awsCredentials = await getAwsCredentials()

      // Take standard 8 images and assign those the patient already has
      let layout_images = PATIENT_IMAGE_LAYOUT_DEFINITIONS.standard_8
        .flat()
        .filter(image => image.slot_key !== 'logo')

      let images = layout_images.map(layout_image => {
        let patient_image = patient_images.find(
          patient_image =>
            patient_image.type_key === layout_image.type_key &&
            patient_image.slot_key === layout_image.slot_key &&
            !patient_image.mime_type?.startsWith('video/')
        )

        return {
          id: layout_image.slot_key,
          slot_key: layout_image.slot_key,
          type_key: layout_image.type_key,
          patient_image,
          original_image: null,
          editor_src: null,
          url: patient_image
            ? getS3ObjectUrl({
                key: `patientimg/${patient_image.file_key}`,
                bucket: awsCredentials.legacy_storage_bucket_name,
                region: awsCredentials.region,
              })
            : null,
          status: 'done',
        }
      })

      // Take misc images the patient already has and add a non-image
      // element so they can capture new ones
      let misc_images = patient_images
        .filter(item => item.type_key === 'misc')
        .map(item => ({
          id: item.id,
          slot_key: item.slot_key,
          type_key: item.type_key,
          patient_image: item,
          original_image: null,
          editor_src: null,
          url: getS3ObjectUrl({
            key: `patientimg/${item.file_key}`,
            bucket: awsCredentials.legacy_storage_bucket_name,
            region: awsCredentials.region,
          }),
          status: 'done',
        }))

      setRes([...images, ...misc_images, NEW_MISC_SLOT])
    }
    run()
  }, []) // eslint-disable-line
  // ignore patient_images changes

  return res
}
