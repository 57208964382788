import React, { useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'
import {
  useFloating,
  offset,
  autoUpdate,
  autoPlacement,
} from '@floating-ui/react-dom'

import View from './view.js'

export default function Logic(props) {
  let flow = useFlowValue(props.viewPath)
  let triggerRef = useRef()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let { refs, x, y, elements } = useFloating({
    open: flow === 'Content',
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: 5 }),
      autoPlacement({ allowedPlacements: ['bottom', 'top'] }),
    ],
    whileElementsMounted: autoUpdate,
  })

  refs.setReference(triggerRef.current)

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === refs.reference.current ||
      refs.reference.current?.contains(event.target)
    ) {
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View
      {...props}
      triggerRef={triggerRef}
      contentRef={refs.setFloating}
      top={y}
      left={x}
      width={elements.reference?.getBoundingClientRect()?.width}
    />
  )
}
