// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/query_print_image_series.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageCard_Content_Content(
  $image_series_id: numeric!
  $layout: vaxiom_patient_images_layout_type!
) {
  vaxiom_patient_images_print_preview(id: $image_series_id, layout: $layout) {
    url
  }
}

`