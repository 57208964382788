import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let appointment_type_id = useDataValue({
    context: 'event',
    path: 'appointment.type.id',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    return { ...data, appointment_type_id }
  }, [data, appointment_type_id])
}
