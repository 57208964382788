// this is an autogenerated file from App/Account/Content/Marketing/ReportTable/Content/Content/Content/LineChart/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Marketing_ReportTable_Content_Content_Content_LineChart(
  $locations: _uuid!
  $campaigns: _uuid
  $date_start: timestamptz
  $date_end: timestamptz
) {
  leads_get_history(
    args: {
      locations: $locations
      campaign_ids: $campaigns
      date_start: $date_start
      date_end: $date_end
    }
  ) {
    completed_date
    total
    incomplete
    created
    patient_created
  }
}

`