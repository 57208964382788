import { useMemo } from 'react'

import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    return {
      patient_id,
      appointment_id,
      tab: 'appointment_generate_letters',
    }
  }, [appointment_id, patient_id])
}
