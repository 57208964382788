// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content($location_id: uuid!, $parent_company_id: uuid!) {
  vaxiom_application_properties(
    where: {
      organization: { _id: { _in: [$location_id, $parent_company_id] } }
      _or: [
        { message_key: { _eq: "audax.ceph.enabled" } }
        { message_key: { _eq: "admin.patient.videos.enabled" } }
      ]
    }
    order_by: { organization_id: desc }
  ) {
    id: message_key
    value: message_value
  }
  itero_connections: apps_connection_resources(
    where: {
      # TODO: it should also check app_id
      connection: { name: { _eq: "itero" }, status: { _eq: active } }
      status: { _eq: enabled }
      _or: [
        { resource_id: { _eq: $location_id }, type: { _eq: location } }
        { resource_id: { _eq: $parent_company_id }, type: { _eq: company } }
      ]
    }
  ) {
    id
  }
}

`