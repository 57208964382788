// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/SyncPatients/Content/SyncPatients/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_SyncPatients_Content_SyncPatients(
  $sync_id: uuid!
  $patients: [apps_complete_sync_patients!]!
) {
  apps_complete_sync(sync_id: $sync_id, patients: $patients) {
    patients_synced
    sync_id
  }
}

`