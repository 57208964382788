import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { patientName } from 'Data/format.js'

export function useOnClick(props) {
  let person = useDataValue({
    context: 'dependentPerson',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    submit({
      type: 'tabs/add',
      tab: {
        id: person.to_person_record.patient._id,
        type: 'Patient',
        name: patientName(person.to_person_record),
        tab: 'profile',
        viewPath: '/App/Account/Content/Patients',
        patient_id: person.to_person_record.patient._id,
      },
    })

    setFlowTo('/App/Account/Search/No')
  }
}
