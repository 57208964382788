import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import query from './query.graphql.js'

export function useOnClick(props) {
  let file = useDataValue({
    context: 'document',
    viewPath: props.viewPath,
  })
  let setSelectedFile = useDataChange({
    context: 'patient_documents',
    path: 'preview_file',
    viewPath: props.viewPath,
  })
  let client = useClient()
  let [, notify] = useNotifications()

  return async function onClick() {
    let res = await client
      .query(query, {
        resource_id: file.id,
        resource_type: 'document',
      })
      .toPromise()

    if (res.error) {
      return notify(
        notifyError(`Couldn't attach the selected patient document`)
      )
    }

    setSelectedFile({
      resource_id: file.id,
      resource_type: 'document',
      name: res.data.resource_object_meta.name,
      type: res.data.resource_object_meta.content_type,
      size: res.data.resource_object_meta.size,
      preview_url: res.data.resource_object_meta.preview_url,
    })
  }
}
