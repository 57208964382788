import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import { v4 as uuid } from 'uuid'
import { useAwsCredentials, useAwsS3Client } from 'Data/s3'
import { CopyObjectCommand } from '@aws-sdk/client-s3'

let PATIENT_IMAGE_PREFIX = 'patientimg'
let PATIENT_ORIGINAL_IMAGE_PREFIX = 'orig_patientimg'

export function useOnClick(props) {
  let image = useDataValue({
    context: 'patient_image',
    viewPath: props.viewPath,
  })
  let getCredentials = useAwsCredentials(props)
  let s3 = useAwsS3Client(props)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    try {
      let file_key = uuid()
      let destination_key = `${PATIENT_IMAGE_PREFIX}/${file_key}`

      let credentials = await getCredentials()
      await copyObject({
        Bucket: credentials.legacy_storage_bucket_name,
        Key: destination_key,
        CopySource: `${credentials.legacy_storage_bucket_name}/${PATIENT_ORIGINAL_IMAGE_PREFIX}/${image.original_file_key}`,
      })
      await executeMutation({
        id: image.id,
        file_key,
      })

      notify(
        notifySuccess(`The image was successfully reset to the original image`)
      )
    } catch (err) {
      notify(notifyError(`Couldn't save the image. Please try again.`))
    } finally {
      setFlowTo(normalizePath(props.viewPath, '../No'))
    }
  }

  async function copyObject(params) {
    await (await s3()).send(new CopyObjectCommand(params))
  }
}
