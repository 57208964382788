// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-mark-as-confirmed.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($appointment_booking_id: uuid!) {
  update_vaxiom_appointment_bookings(
    where: { _id: { _eq: $appointment_booking_id } }
    _set: { confirmation_status: "CONFIRMED" }
  ) {
    affected_rows
    returning {
      _id
      confirmation_status
    }
  }
}

`