// this is an autogenerated file from DesignSystem/TimelineEvent/FutureSeparator/Actions/AddAppointment/Add/Content/AppointmentTypes/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_FutureSeparator_Actions_AddAppointment_Add_Content_AppointmentTypes($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      organization: { _id: { _eq: $location_id } }
      deleted: { _eq: false }
    }
    order_by: { full_name_computed: asc }
  ) {
    id: _id
    text: full_name_computed
  }
}

`