import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let setTxPlanGroups = useDataChange({
    context: 'ruleset',
    path: 'tx_plan_groups',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    setTxPlanGroups(value?.length === 1 ? value : [])
  }
}
