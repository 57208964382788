// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/mutation_update.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions($id: uuid!, $object: vaxiom_provider_calls_set_input!) {
  update_vaxiom_provider_calls_by_pk(pk_columns: { id: $id }, _set: $object) {
    id
  }
}

`