import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let patient_id = useDataValue({
    context: 'patient_documents',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let changeSelection = useDataChange({
    context: 'patient_documents',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === patient_id) return

    changeSelection(next => {
      next.selected_image_series = null
      next.parent_id = null
      next.directories = [{ id: null, name: 'All documents' }]
      next.preview_file = null
      next.patient_id = value
    })
  }
}
