import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !props.value) return data

    let req = {
      ...data.req,
      ...props.value,
      headers: props.value.headers
        ? Object.keys(props.value.headers).map((key, id) => ({
            id,
            key,
            value: props.value.headers[key],
          }))
        : [],
      body:
        !!props.value.body && typeof props.value.body === 'object'
          ? JSON.stringify(props.value.body, null, 2)
          : props.value.body,
    }

    return {
      ...data,
      req,
    }
  }, [data, props.value])
}
