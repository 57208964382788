// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/mutation-requirements-check.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions($id: uuid!, $params: jsonb) {
  create_pre_appointment_bookings_state_update_automations(
    appointment_booking_id: $id
    state: "UNSEATED"
    params: $params
  ) {
    event_execution_ids
    is_pending
  }
}

`