import { useDataChange } from 'Simple/Data.js'

export function useOnOpen(props) {
  let setIsUploadOpen = useDataChange({
    context: 'message_compose',
    path: 'is_upload_open',
    viewPath: props.viewPath,
  })

  return function toggleModal() {
    setIsUploadOpen(true)
  }
}

export function useOnClose(props) {
  let setIsUploadOpen = useDataChange({
    context: 'message_compose',
    path: 'is_upload_open',
    viewPath: props.viewPath,
  })

  return function toggleModal() {
    setIsUploadOpen(false)
  }
}
