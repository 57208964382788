// this is an autogenerated file from App/Account/Chairs/Content/Appointments/Content/Appointment/Actions/MissingRequiredTreatmentFields/Content/TreatmentFields/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Chairs_Content_Appointments_Content_Appointment_Actions_MissingRequiredTreatmentFields_Content_TreatmentFields($appointment_id: uuid!) {
  appointments_missing_required_treatment_fields(
    args: { _appointment_id: $appointment_id }
    order_by: { number: asc }
  ) {
    id
    name
  }
}

`