import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'patient',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'showCalendar',
      coreParams: {
        patient_id,
        appointment_id,
        is_rescheduling: true,
      },
    })
  }
}
