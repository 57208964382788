import { useDataIsSubmitting, useDataValue } from 'Simple/Data'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'form',
    viewPath: props.viewPath,
  })
  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'form',
  })

  return (
    isSubmitting ||
    !value.name?.trim().length ||
    (value.name?.trim() === value.original_value.name?.trim() &&
      value.treatment_id === value.original_value.treatment_id &&
      value.appointment_id === value.original_value.appointment_id)
  )
}
