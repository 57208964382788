import React from 'react'
import { useDataValue } from 'Simple/Data.js'
import { appointmentTypeColor, isAppointmentScheduled } from 'Data/format.js'
import { useDesignTokenValue } from 'Simple/DesignTokens.js'
import isAfter from 'date-fns/isAfter'
import View from './view.js'
import useIsHovered from 'Simple/hooks/useIsHovered.js'

export default function Logic(props) {
  let [isHoveredManual, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: props.isSelected,
  })

  let appointment = useDataValue({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  let state = useDataValue({
    context: 'appointment',
    path: 'booking.state',
    viewPath: props.viewPath,
  })
  let isUnplanned = useDataValue({
    context: 'appointment',
    path: 'unplanned',
    viewPath: props.viewPath,
  })
  let borderColor = useDataValue({
    context: 'appointment',
    path: 'type.color_id',
    viewPath: props.viewPath,
  })
  let unplannedColor = useDesignTokenValue({
    path: 'colors.neutral.700',
    viewPath: props.viewPath,
  })
  let seatedColor = useDesignTokenValue({
    path: 'colors.blue.600',
    viewPath: props.viewPath,
  })

  let pastBackgroundColor = useDesignTokenValue({
    path: 'colors.neutral.100',
    viewPath: props.viewPath,
  })
  let futureBackgroundColor = useDesignTokenValue({
    path: 'colors.white',
    viewPath: props.viewPath,
  })

  return (
    <View
      isHoveredManual={isHoveredManual}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      {...props}
      backgroundColor={
        isAppointmentScheduled(appointment) &&
        isAfter(Date.parse(`${appointment.booking.start_time}Z`), new Date())
          ? futureBackgroundColor
          : pastBackgroundColor
      }
      borderColor={
        state === 'SEATED'
          ? seatedColor
          : isUnplanned
          ? unplannedColor
          : appointmentTypeColor(borderColor)
      }
    />
  )
}
