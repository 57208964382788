import { useDataValue } from 'Simple/Data'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import mutation from './mutation-clockinout.graphql.js'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export default function useDataOnActionClockInOut(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })

  return async function onActionClockInOut({
    value,
    originalValue,
    args,
    change,
  }) {
    let date = new Date(
      `${value.date}T${
        value.state === 'NORMAL'
          ? value.time.slice(0, -2) + format(new Date(), 'ss')
          : value.time
      }`
    ).toISOString()

    let variables = {
      core_user_id: value.selected_vaxiom_user_id,
      date,
      location_id,
      status: value.is_user_clocked_in ? 'CLOCKED_OUT' : 'CLOCKED_IN',
      state: value.state,
    }
    if (value.comment !== '') {
      variables.work_hour_comments = {
        data: [
          {
            comment: value.comment,
            core_user_id: value.selected_vaxiom_user_id,
            date,
          },
        ],
      }
    }

    if (
      value.previous_actions_time
        .map(i => format(parseISO(i), 'HH:mm:ss'))
        .includes(format(parseISO(variables.date.slice(0, -1)), 'HH:mm:ss'))
    ) {
      notify(
        notifyError(
          'At least one minute has to pass between clocking in and clocking out.'
        )
      )

      return
    }

    let mutationResponse = await executeMutation(
      variables,
      value.access_token
        ? {
            fetchOptions: {
              headers: {
                Authorization: `Bearer ${value.access_token}`,
                'x-hasura-role': value.role,
              },
            },
          }
        : undefined
    )

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )

      return
    }
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
