import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let app_connections = useDataValue({
    context: 'app_connections',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'event_action',
    path: 'data.app_id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'event_action',
    path: 'data.connection_name',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!app_id || !connection_name) return data

    let connection = app_connections.find(
      connection =>
        connection.name === connection_name && connection.app.id === app_id
    )
    if (!connection) return data

    return connection.id
  }, [app_connections, app_id, connection_name, data])
}
