import { textAtLeastOneChar } from 'Simple/Data/validate.js'
import { validate as validatePayabliToken } from '../Content/Payabli/Token/helpers.js'
import { useDataValue } from 'Simple/Data.js'

let PROVIDERS = ['bluefin', 'payabli']

export function useIsDisabled(props) {
  let value = useDataValue({
    context: 'add_provider',
    viewPath: props.viewPath,
  })

  let { isInvalid: isDisabled } = isInvalid(value)

  return isDisabled
}

function isInvalid(value) {
  let list = [
    !PROVIDERS.includes(value.provider) && 'provider',
    !textAtLeastOneChar(
      value.provider === 'payabli'
        ? value['payabli'].selected_paypoint
        : value['bluefin'].account_id
    ) && (value.provider === 'payabli' ? 'paypoint ID' : 'account ID'),
    (value.provider === 'payabli'
      ? !validatePayabliToken(value['payabli'].token)
      : !textAtLeastOneChar(value['bluefin'].access_key)) &&
      (value.provider === 'payabli' ? 'token' : 'access key'),
  ].filter(Boolean)

  if (list.length > 0) {
    return {
      isInvalid: true,
      errors: list,
    }
  }
  return {
    isInvalid: false,
    errors: [],
  }
}
