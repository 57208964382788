import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let deleted = useDataValue({
    context: 'images_comparison_action',
    path: 'show_deleted_image_series',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      variables: {
        condition: {
          patient_id: { _eq: patient_id },
          deleted: deleted ? undefined : { _eq: false },
        },
      },
      requestPolicy: 'network-only',
    }),
    [patient_id, deleted]
  )
}
