import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let treatment_card_id = useDataValue({
    context: 'tab',
    path: 'treatment_card_id',
    viewPath: props.viewPath,
  })
  let tx_plan_notes = useDataValue({
    context: 'tx_plan_notes',
    viewPath: props.viewPath,
  })

  return useMemo(
    () =>
      tx_plan_notes?.length
        ? {
            ...tx_plan_notes[0],
            treatment_card_id: tx_plan_notes[0].tx_card._id,
          }
        : { id: null, note: '', treatment_card_id },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
