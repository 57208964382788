import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { login, logout } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useHistory, useDataValueOnce } from 'Logic/FlowShortcuts.js'
import mutation from './mutation.graphql.js'
import React, { useEffect } from 'react'
import View from './view.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { maybeMakeSessionTemporary } from 'Data/Auth.js'

export default function Logic(props) {
  let [, notify] = useNotifications()
  let changeAuth = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let dataToken = useDataValueOnce({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'authToken',
  })
  let xid = useDataValueOnce({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'xid',
  })
  let dataRedirectUri = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'redirectUri',
  })
  let temporarySession = useDataValue({
    viewPath: props.viewPath,
    context: 'flow_shortcuts',
    path: 'temporarySession',
  })
  let [, executeMutation] = useMutation(mutation)
  let history = useHistory()

  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    if (!dataToken) return

    let cancel = false

    ;(async () => {
      maybeMakeSessionTemporary(temporarySession)
      localStorage.removeItem('choseLocationAfterLogin')

      let variables = { token: dataToken }
      if (xid) {
        variables.xid = xid
      }

      let mutationResponse = await executeMutation(variables, {
        fetchOptions: { headers: { 'x-hasura-role': 'public' } },
      })
      if (cancel) return

      if (
        mutationResponse.error ||
        mutationResponse.data.vaxiom_users_auth_with_token.status !== 'ok'
      ) {
        notify(notifyError('The token provided is invalid or has expired.'))
        history.push({
          pathname: `/signin?redirectUri=${dataRedirectUri}` || '/',
          search: '',
        })
        changeAuth(logout())
      } else {
        changeAuth(login(mutationResponse.data.vaxiom_users_auth_with_token))
      }

      setFlowTo(normalizePath(props.viewPath, '../Check'))
    })()

    return () => {
      cancel = true
    }
  }, [dataToken]) // eslint-disable-line
  // run once

  return <View viewPath={props.viewPath} />
}
