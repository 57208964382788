export function getView(value) {
  switch (value.event_type) {
    case 'appointment_bookings_state_update':
      return value.appointment_status && value.appointment_type_ids?.length

    case 'txs_status_update':
      return value.tx_status

    case 'custom_resource_insert':
      return value.custom_resource_type

    default:
      return false
  }
}
