import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export default function useDataOnChange(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onChange(value, change) {
    if (value) {
      setFlowTo(normalizePath(props.viewPath, 'QuestionsAnswersModal/Content'))
    }
  }
}
