import { useEffect, useState, useRef } from 'react'
import load from 'load-script2'

let queue = {}

window._googleMapsCallback = () => {}

async function loadScript(url) {
  if (!queue[url]) {
    queue[url] = load(url)
  }

  return queue[url]
}

export default function useGoogleMapsApi({ key, libraries = '' }) {
  let api = useRef(window?.google?.maps)
  let [state, setState] = useState(api.current ? 'ready' : 'loading')

  useEffect(() => {
    if (api.current) return

    let cancel = false

    ;(async () => {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=${libraries}&callback=_googleMapsCallback`
        )
        if (cancel) return

        api.current = window.google.maps
        setState('ready')
      } catch (error) {
        if (cancel) return
        setState('error')
      }
    })()

    return () => {
      cancel = true
    }
  }, []) // eslint-disable-line

  return {
    api: api.current,
    isError: state === 'error',
    isLoading: state === 'loading',
    isReady: state === 'ready',
  }
}
