import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let id = useDataValue({
    context: 'note_templates_tab',
    path: 'note_template.id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    return submit({ type: 'delete', id })
  }
}
