// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/PatientSelect/Content/Content/New/AssignedDoctor/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_PatientSelect_Content_Content_New_AssignedDoctor($location_id: uuid!) {
  vaxiom_persons(
    where: {
      employment_contracts: {
        employment_end_date: { _is_null: true }
        organization_id: { _is_null: false }
        employee_resources: {
          invalid: { _eq: false }
          resource: {
            organization: { _id: { _eq: $location_id } }
            resource_type: { is_provider: { _eq: true } }
          }
        }
      }
    }
  ) {
    id
    first_name
    last_name
    employment_contracts {
      id
      # Make sure that we fetch only one employee_resource for each employment_contracts
      employee_resources(
        where: {
          invalid: { _eq: false }
          resource: {
            organization: { _id: { _eq: $location_id } }
            resource_type: { is_provider: { _eq: true } }
          }
        }
      ) {
        id
      }
    }
  }
}

`