// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Phone/Phones/Content/Phone/Edit/Content/updatePhoneMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Phone_Phones_Content_Phone_Edit_Content(
  $id: numeric!
  $number: String!
  $type: String!
  $description: String
  $association_id: numeric!
) {
  update_vaxiom_contact_phones_by_pk(
    pk_columns: { id: $id }
    _set: { number: $number, type: $type }
  ) {
    id
  }

  update_vaxiom_contact_method_associations_by_pk(
    pk_columns: { id: $association_id }
    _set: { description: $description }
  ) {
    id
  }
}

`