import { useMemo } from 'react'

let DEFAULT_COLOR = '#DEE2E6'

let ORAL_HYGIENE_COLOR_MAP = {
  e: '#0c8',
  g: '#ba0',
  f: '#c70',
  p: '#a00',
}

function calculateBrokenBrackets(broken_brackets) {
  return Array.isArray(broken_brackets)
    ? broken_brackets.reduce((sum, item) => {
        try {
          sum += parseInt(item.value, 10)
        } catch (error) {
          sum += 0
        }
        return sum
      }, 0)
    : 0
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data

    return {
      appointment_no_shows: data[0].appointments_no_shows.length || 0,
      cancelled_appointments: data[0].cancelled_appointments.length || 0,
      broken_brackets: calculateBrokenBrackets(data[0].broken_brackets),
      oral_hygiene_color:
        ORAL_HYGIENE_COLOR_MAP[
          data[0].oral_hygiene?.[0]?.value?.toLowerCase()
        ] || DEFAULT_COLOR,
    }
  }, [data])
}
