import { useMutation } from 'Data/Api.js'
import { useUppy } from 'DesignSystem/FileUploader/react.js'
import { getS3ObjectUrl, useAwsCredentials, useAwsS3Client } from 'Data/s3'
import { CopyObjectCommand } from '@aws-sdk/client-s3'
import { captureError } from 'Logic/ErrorBoundary'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import mutation_update_patient_image from './mutation_update_patient_image.graphql.js'
import mutation_create_patient_image from './mutation_create_patient_image.graphql.js'
import { v4 as uuid } from 'uuid'
import { getPatientImageName } from 'Data/format.js'
import {
  PATIENT_IMAGE_PREFIX,
  PATIENT_ORIGINAL_IMAGE_PREFIX,
} from 'Data/common-constants.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { NEW_MISC_SLOT } from '../useDataTransform.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useListItemDataOnSubmit(props, data) {
  let onActionReset = useDataOnActionReset(props)
  let onActionUpdate = useDataOnActionUpdate(props)
  let onActionCreate = useDataOnActionCreate(props)

  return async function onSubmit({ value, args, change }) {
    switch (args?.type) {
      case 'create':
        return onActionCreate({ value, args, change })
      case 'update':
        return onActionUpdate({ value, args, change })
      case 'reset':
        return onActionReset({ value, change })
      default:
        console.error(`The action type ${args?.type} does not exist`)
    }
  }
}

function useDataOnActionReset(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation_update_patient_image)
  let s3 = useAwsS3Client(props)
  let getCredentials = useAwsCredentials(props)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value, change }) {
    async function background() {
      let slotName = getPatientImageName(value.slot_key)

      try {
        let file_key = uuid()
        let destination_key = `${PATIENT_IMAGE_PREFIX}/${file_key}`
        let credentials = await getCredentials()

        await copyObject({
          Bucket: credentials.legacy_storage_bucket_name,
          Key: destination_key,
          CopySource: `${credentials.legacy_storage_bucket_name}/${PATIENT_ORIGINAL_IMAGE_PREFIX}/${value.patient_image.original_file_key}`,
        })

        let mutationResponse = await executeMutation({
          id: value.patient_image.id,
          file_key,
        })
        if (mutationResponse.error) {
          throw new Error(mutationResponse.error)
        }

        change(next => {
          next.original_image = null
          next.editor_src = null
          next.patient_image =
            mutationResponse.data.vaxiom_patient_images_update.patient_image
          next.url = getS3ObjectUrl({
            key: destination_key,
            bucket: credentials.legacy_storage_bucket_name,
            region: credentials.region,
          })
        })

        notify(
          notifySuccess(
            `The ${slotName} image was successfully reset to the original`
          )
        )
      } catch (err) {
        notify(
          notifyError(`Couldn't reset the ${slotName} image. Please try again.`)
        )
      }
    }

    background()
    setFlowTo(normalizePath(props.viewPath, 'ImageSot/Content/Edit/No'))
  }

  async function copyObject(params) {
    await (await s3()).send(new CopyObjectCommand(params))
  }
}

function useDataOnActionUpdate(props) {
  let uppy = useUppy({ viewPath: props.viewPath })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation_update_patient_image)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value, args, change }) {
    async function background() {
      try {
        // Upload image to s3
        let slotName = getPatientImageName(value.slot_key)
        let uploadResult = await uploadFile({
          uppy,
          prefix: PATIENT_IMAGE_PREFIX,
          file: args.file,
          name: args.file.name,
        })
        if (uploadResult.error) {
          throw new Error(`Couldn't upload the ${slotName} image`)
        }
        let image = uploadResult.file

        // Update patient image instance
        let mutationResponse = await executeMutation({
          id: value.patient_image.id,
          file_key: image.meta.location.substring(
            image.meta.location.lastIndexOf('/') + 1
          ),
        })
        if (mutationResponse.error) {
          throw new Error(`Couldn't update ${slotName} image`)
        }
        notify(notifySuccess(`Updated ${slotName} image`))
        change(next => {
          next.status = 'done'
        })
      } catch (error) {
        change(next => {
          next.status = 'error'
        })
        return notify(notifyError(error.message))
      }
    }
    change(next => {
      next.status = 'uploading'
      next.editor_src = null
      next.url = URL.createObjectURL(args.file)
    })
    background()
    setFlowTo(normalizePath(props.viewPath, 'ImageSot/Content/Edit/No'))
  }
}

function useDataOnActionCreate(props) {
  let uppy = useUppy({ viewPath: props.viewPath })
  let getCredentials = useAwsCredentials(props)
  let [, notify] = useNotifications()
  let [, executeCreateImageMutation] = useMutation(
    mutation_create_patient_image
  )
  let setFlowTo = useSetFlowTo(props.viewPath)

  let image_series_id = useDataValue({
    context: 'images_request',
    path: 'patient_image_series.id',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    context: 'patient',
    path: 'person.id',
    viewPath: props.viewPath,
  })
  let changeImages = useDataChange({
    context: 'images',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, change }) {
    async function background() {
      let slotName = getPatientImageName(value.slot_key)
      let name = `${slotName}.jpeg`

      // Upload original image to s3
      let uploadOriginalResult = await uploadFile({
        uppy,
        prefix: PATIENT_ORIGINAL_IMAGE_PREFIX,
        file: value.original_image,
        name,
      })
      if (uploadOriginalResult.error) {
        throw new Error(`Couldn't upload the original ${slotName} image`)
      }
      let original_image = uploadOriginalResult.file

      // Upload patient image to s3
      let uploadImageResult = await uploadFile({
        uppy,
        prefix: PATIENT_IMAGE_PREFIX,
        file: args.file,
        name,
      })
      if (uploadImageResult.error) {
        throw new Error(`Couldn't upload the ${slotName} image`)
      }
      let patient_image = uploadImageResult.file

      // Create patient image instance
      try {
        let file_key = patient_image.meta.location.substring(
          patient_image.meta.location.lastIndexOf('/') + 1
        )
        let is_profile_picture = value.slot_key === 'facialFrontalSmiling'
        let profile_pic_url = await getProfilePictureUrl(
          is_profile_picture,
          file_key
        )

        let imageMutationResponse = await executeCreateImageMutation({
          delete_patient_image: Boolean(value.patient_image?.id),
          patient_image_id: value.patient_image?.id || null,
          patient_id,
          person_id,
          image_series_id,
          type_key: value.type_key,
          slot_key: value.slot_key,
          original_file_key: original_image.meta.location.substring(
            original_image.meta.location.lastIndexOf('/') + 1
          ),
          file_key,
          is_profile_picture,
          profile_pic_url,
        })
        if (imageMutationResponse.error) {
          throw new Error(`Couldn't save ${slotName} image`)
        }

        notify(notifySuccess(`Saved ${slotName} image`))

        change(next => {
          next.status = 'done'
          next.patient_image = imageMutationResponse.data.new_patient_image
        })
      } catch (error) {
        change(next => {
          next.status = 'error'
        })
        return notify(notifyError(error.message))
      }
    }
    change(next => {
      next.status = 'uploading'
      next.original_image = null
      next.editor_src = null
      next.patient_image = true
      next.url = URL.createObjectURL(args.file)
    })

    if (value.type_key === 'misc') {
      changeImages(next => {
        next.push({ ...NEW_MISC_SLOT, id: `misc-${Date.now()}` })
      })
    }

    background()
    setFlowTo(normalizePath(props.viewPath, 'ImageSot/Content/Edit/No'))

    if (value.id === 'misc') {
      change(next => {
        next.original_image = null
        next.editor_src = null
      })
    }
  }

  async function getProfilePictureUrl(is_profile_picture, file_key) {
    if (!is_profile_picture) return null

    let credentials = await getCredentials()

    return getS3ObjectUrl({
      bucket: credentials.legacy_storage_bucket_name,
      key: `${PATIENT_IMAGE_PREFIX}/${file_key}`,
      region: credentials.region,
    })
  }
}

async function uploadFile({ uppy, prefix, file, name }) {
  let tries = 0

  return await new Promise(resolve => {
    let fileId = uppy.addFile({
      name,
      type: 'image/jpeg',
      size: file.size,
      data: file,
      meta: {
        prefix,
      },
    })
    uppy.on('upload-success', uploadSuccess)
    uppy.on('upload-error', uploadError)

    function uploadSuccess(file) {
      if (file.id !== fileId) return

      uppy.off('upload-success', uploadSuccess)
      uppy.off('upload-error', uploadError)

      resolve({ error: false, file })
    }

    function uploadError(file, error) {
      if (file.id !== fileId) return

      if (tries < 1) {
        uppy.retryUpload(fileId)
        tries++
        return
      }

      captureError(new Error('intraoral-mobile-capture/file-upload-failed'), {
        error,
        prefix,
        size: file.size,
        fileId,
        name,
      })

      uppy.off('upload-success', uploadSuccess)
      uppy.off('upload-error', uploadError)

      resolve({ error: true, file })
    }
  })
}
