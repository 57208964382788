import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  let tx_plan_groups = useDataValue({
    context: 'tx_plan_groups',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.tx_plan_groups =
        tx_plan_groups.length === next.tx_plan_groups.length
          ? []
          : tx_plan_groups
    })
  }
}
