// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/Notes/Content/Note/Read/Delete/Content/Actions/DeleteAction/deleteNoteAuditMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes_Notes_Content_Note_Read_Delete_Content_Actions_DeleteAction($input: vaxiom_audit_logs_insert_input!) {
  insert_vaxiom_audit_logs_one(object: $input) {
    id
  }
}

`