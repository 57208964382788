import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSaveEditorContent } from '../../../useSaveEditorContent.js'

let SIGNATURE = `{Signatory_Signature_Required}<br />{Document_Signed_Date}`

export function useOnClick(props) {
  let editor = useDataValue({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let url = useDataValue({
    context: 'document',
    path: 'url',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let save = useSaveEditorContent(props)

  return async function onClick() {
    change(next => {
      next.saving = true
    })

    let currentNode = editor.api.selection.getNode()
    let currentBlock =
      editor.api.dom.getParent(currentNode, 'p,div,li') || currentNode

    let currentContent = currentBlock.textContent.trim()

    if (currentContent !== '') {
      let newBlock = editor.api.dom.create('p', {}, '<br>')
      if (currentBlock.nodeName === 'BODY') {
        editor.api.dom.add(currentBlock, newBlock)
      } else {
        editor.api.dom.insertAfter(newBlock, currentBlock)
      }
      editor.api.selection.setCursorLocation(newBlock, 0)
    }
    editor.api.execCommand('mceInsertContent', false, SIGNATURE)

    try {
      await save({ url, content: editor.api.getContent() })
      editor.api.setDirty(false)
      change(next => {
        next.hasSignature = true
        next.saving = false
        next.isDirty = false
      })
    } catch (error) {
      change(next => {
        next.hasSignature = true
        next.saving = false
      })
    }
  }
}
