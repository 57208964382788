import { useDataSubmit } from 'Simple/Data.js'

function useChange(props, patient_option) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  return () => {
    submit({
      type: 'updatePatientOption',
      patient_option,
    })
  }
}

export function useOnNew(props) {
  return useChange(props, 'new')
}

export function useOnExisting(props) {
  return useChange(props, 'existing')
}
