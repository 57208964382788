import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payments_request_payment',
    viewPath: props.viewPath,
    path: 'selected_email',
  })

  return function onChange(next_id) {
    change(next_id)
  }
}

export function isDisabled(isSubmitting, emails) {
  return isSubmitting || emails.length === 0
}
