// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsEditAutopay/Content/mutation_add.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsEditAutopay_Content($payment_plan_id: numeric!, $token: jsonb!) {
  payments_add_payment_plan_autopay(
    payment_plan_id: $payment_plan_id
    token: $token
  ) {
    payment_plan_id
    verified
    verification_metadata
  }
}

`