// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/RefundCardPaymentAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_RefundCardPaymentAction($transaction_id: uuid!, $amount: numeric!) {
  payments_apply_refund(transaction_id: $transaction_id, amount: $amount) {
    ok
    transaction_id
  }
}

`