import View from './view.js'
import React, { useCallback } from 'react'
import useCapture from '../useCapture.js'

export default React.forwardRef(function Logic(props, ref) {
  let { onKeyUp, onChange, onFocus } = useCapture(props)

  let handleOnFocus = useCallback(
    event => {
      if (props.autoSelect) {
        event.target.select()
      }

      props.onFocus?.(event)
      onFocus?.(event)
    },
    [props.autoSelect] // eslint-disable-line
    // ignore onFocus
  )

  return (
    <View
      {...props}
      onKeyUp={onKeyUp}
      onChange={onChange}
      onFocus={handleOnFocus}
      innerRef={ref}
      isShowingInvalidMessage={
        props.isInvalid &&
        typeof props.invalidMessage === 'string' &&
        props.invalidMessage !== ''
      }
      value={typeof props.value === 'number' ? props.value : props.value || ''}
    />
  )
})
