import { useDataValue, useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'note',
  })

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected_note',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick(value) {
    change(note)
    setFlowTo(normalizePath(props.viewPath, '../NewOrEdit/Content'))
  }
}
