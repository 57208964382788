import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import differenceInMonths from 'date-fns/differenceInMonths'

export default function useDataTransform(props, data) {
  let tx = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs',
  })

  return useMemo(() => {
    let start_date = tx.start_date || tx.estimated_start_date
    let end_date = tx.end_date || tx.estimated_end_date
    return {
      ...data,
      start_date,
      length_in_months: end_date
        ? differenceInMonths(new Date(end_date), new Date(start_date))
        : 0,
    }
  }, [data, tx])
}
