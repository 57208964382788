import React from 'react'
import {
  DataProvider,
  useSetFlowToBasedOnData,
  useDataValue,
} from 'Simple/Data.js'
import useFetch from 'Data/useFetch.js'
import View from './view.js'

export default function Logic(props) {
  let preview_url = useDataValue({
    context: 'document',
    path: 'preview_url',
    viewPath: props.viewPath,
  })
  let { data, fetching, error } = useFetch(preview_url)

  useSetFlowToBasedOnData({
    data,
    fetching,
    error,
    viewPath: props.viewPath,
    pause: !preview_url,
  })

  return (
    <DataProvider context="content" value={data} viewPath={props.viewPath}>
      <View {...props} />
    </DataProvider>
  )
}
