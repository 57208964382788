import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data'
import View from './view.js'

export default function Logic(props) {
  let images = useDataValue({
    context: 'patient_images',
    viewPath: props.viewPath,
  })
  let image_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_id',
  })
  let image_series_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_series_id',
  })
  let setImageId = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_id',
  })

  useEffect(() => {
    if (
      !images.length ||
      image_id ||
      !images.some(image => image.imageseries.id === image_series_id)
    ) {
      return
    }

    let image =
      images.find(image => image.slot_key === 'xRayPanoramic') || images[0]
    setImageId(image.id)
  }, [image_id, images, image_series_id]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setImageId

  return <View {...props} />
}
