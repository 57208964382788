import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let recipients = useDataValue({
    context: 'recipients',
    viewPath: props.viewPath,
  })

  return function onChange(send_to_person_vaxiom_id) {
    change(next => {
      next.send_to_person_vaxiom_id = send_to_person_vaxiom_id
      next.send_to_person_id = recipients.find(
        item => item.id === send_to_person_vaxiom_id
      )?._id
    })
  }
}
