// this is an autogenerated file from App/Account/Content/Profile/Content/TwoFactorAuth/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Profile_Content_TwoFactorAuth($user_id: uuid!) {
  auth_accounts(where: { users: { id: { _eq: $user_id } } }) {
    id
    active_mfa_type
    phone_number
    email
  }
}

`