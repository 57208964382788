import React from 'react'
import View from './view.js'

let MAX_FILE_SIZE = 1024 * 1024 * 50 // 50MB
let ALLOWED_FILE_TYPES = ['image/*']
let restrictions = {
  maxFileSize: MAX_FILE_SIZE,
  allowedFileTypes: ALLOWED_FILE_TYPES,
}

export default function Logic(props) {
  return <View {...props} restrictions={restrictions} />
}
