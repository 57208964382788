import { useDataSubmit } from 'Simple/Data.js'

export function getView(created) {
  return created ? 'Created' : 'Create'
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'create_token',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'close' })
  }
}
