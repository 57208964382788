import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return () => {
    change('assistants')
  }
}
