import { useEffect } from 'react'

export default function AppFavIcon(props) {
  useEffect(() => {
    let icon = document.querySelector('link[rel*=icon]')
    let prev = null

    if (icon) {
      prev = icon.href
      icon.href = props.url
    }

    return () => {
      if (!prev) return

      icon.href = prev
    }
  }, [props.url])

  return null
}
