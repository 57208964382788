// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/TreatmentFields/Content/Field/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_TreatmentFields_Content_Field(
  $appointment_id: numeric!
  $field_definition_id: numeric!
  $values: [vaxiom_appointment_field_values_insert_input!]!
) {
  delete_vaxiom_appointment_field_values(
    where: {
      appointment_id: { _eq: $appointment_id }
      field_definition_id: { _eq: $field_definition_id }
    }
  ) {
    affected_rows
  }
  insert_vaxiom_appointment_field_values(objects: $values) {
    affected_rows
  }
}

`