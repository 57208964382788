import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointments',
    path: 'tx_uuid',
  })
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_plan',
    path: '_id',
  })

  return function onClick() {
    change(id)
    props.closePopover?.()
  }
}
