// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-check-in-patient.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($id: uuid!) {
  vaxiom_appointment_booking_check_in(id: $id) {
    id
  }
}

`