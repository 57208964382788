import { useMemo } from 'react'
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let uniqueIds = new Set()

    return data
      .map(detail => {
        if (
          !detail.analytics.length ||
          uniqueIds.has(detail.analytics[0].campaign.id)
        ) {
          return false
        }

        uniqueIds.add(detail.analytics[0].campaign.id)

        return {
          id: detail.analytics[0].campaign.id,
          text: getText(detail.analytics[0].campaign),
        }
      })
      .filter(Boolean)
  }, [data])
}

function getText(item) {
  return `${item.name}${
    item.external_id && item.name
      ? ' / ' + item.external_id
      : item.external_id && !item.name
      ? item.external_id
      : ''
  }`
}
