// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/SyncPatients/Content/SyncPatients/Content/txStatusQuery.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_SyncPatients_Content_SyncPatients_Content($patient_ids: [uuid!]!, $statuses: [String!]!) {
  vaxiom_patients(
    where: {
      _id: { _in: $patient_ids }
      tx_cards: { txs: { status: { _in: $statuses } } }
    }
  ) {
    id
    _id
  }
}

`