// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/ActionsCell/DiagnosisAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_ActionsCell_DiagnosisAction_Content($appointment_id: uuid!, $parent_company_id: uuid!) {
  vaxiom_diagnosis_create(
    appointment_id: $appointment_id
    parent_company_id: $parent_company_id
  ) {
    id
    _id
  }
}

`