import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payments_request_payment',
    viewPath: props.viewPath,
  })
  let person_payment_accounts = useDataValue({
    context: 'patient',
    path: 'person_payment_accounts',
    viewPath: props.viewPath,
  })

  return function onChange(next_id) {
    let found = person_payment_accounts?.find(i => i.id === next_id)
    if (!found) return

    change(next => {
      next.person_payment_account.selected_id = next_id
      next.person_payment_account.data = found.data
    })
  }
}
