// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/ImportAction/Content/ImportImagesFromAlignAction/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_ImportAction_Content_ImportImagesFromAlignAction($parent_company_id: uuid!) {
  apps_connections(
    where: {
      name: { _eq: "ids" }
      app: {
        companies: {
          parent_company_id: { _eq: $parent_company_id }
          status: { _eq: granted }
        }
      }
    }
  ) {
    id
  }
}

`