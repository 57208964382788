// this is an autogenerated file from App/Account/Content/Patients/Content/Content/TopBar/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_TopBar_CustomResource{
  apps_ui_mappings(type: patient_header) {
    id
    display_definitions
  }
}

`