import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClickMinus(props) {
  let duration = useDataValue({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })

  return () => {
    let newDuration = duration - 30
    if (newDuration >= 30) {
      change(newDuration)
    }
  }
}

export function useOnClickPlus(props) {
  let duration = useDataValue({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })

  return () => {
    change(duration + 30)
  }
}
