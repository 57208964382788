import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import React, { useEffect } from 'react'

import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import View from './view.js'

export default function Logic(props) {
  let id = useDataValue({
    context: 'flow_shortcuts',
    path: 'state',
    viewPath: props.viewPath,
  })

  let code = useDataValue({
    context: 'flow_shortcuts',
    path: 'code',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutation] = useMutation(mutation)

  useEffect(() => {
    if (!id || !code) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      setFlowTo(normalizePath(props.viewPath, '../Error'))
      return
    }

    let cancel = false

    ;(async () => {
      let mutationResponse = await executeMutation({
        id,
        code,
      })

      if (cancel) return

      if (
        !mutationResponse ||
        mutationResponse.error ||
        mutationResponse.data.apps_external_api_token_swap.status !== 'ok'
      ) {
        setFlowTo(normalizePath(props.viewPath, '../Error'))
      } else {
        window.close()
      }
    })()

    return () => {
      cancel = true
    }
  }, []) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
