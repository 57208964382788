// this is an autogenerated file from DesignSystem/PatientOverview/Content/HubAccess/Content/OkAccountMissing/Emails/Email/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientOverview_Content_HubAccess_Content_OkAccountMissing_Emails_Email($email: citext!, $location_id: numeric!) {
  auth_patient_invite(email: $email, location_id: $location_id) {
    ok
  }
}

`