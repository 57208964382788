// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/ImportFromDevices/Content/ModalBody/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_ImportFromDevices_Content_ModalBody(
  $objects: [vaxiom_temporary_images_insert_input!]!
) {
  insert_vaxiom_temporary_images(objects: $objects) {
    affected_rows
    returning {
      id
      patient_id
      organization_node_id
      file_key
      original_name
      mime_type
    }
  }
}

`