import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return [
      { type: 'InApp', id: 'InApp' },
      ...data.vaxiom_contact_method_associations
        .filter(item => item.contact_method.mobile_phone?.number)
        .map(item => ({
          id: item.id,
          type: 'Phone',
          number: item.contact_method.mobile_phone?.number,
          description: item.description,
        })),
      ...data.vaxiom_relationships.reduce(
        (result, current) => [
          ...result,
          ...current.from_person_record.contact_method_associations
            .filter(item => item.contact_method.mobile_phone?.number)
            .map(item => ({
              id: item.id,
              type: 'Phone',
              number: item.contact_method.mobile_phone.number,
              description: current.type.toLowerCase(),
            })),
        ],
        []
      ),
      { type: 'Discussion', id: 'Discussion' },
    ]
  }, [data])
}
