import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let selected_image_series = useDataValue({
    context: 'patient_documents',
    path: 'selected_image_series',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      image_series_id: selected_image_series?.id,
    },
    pause: !selected_image_series?.id,
  }
}
