import { DataProvider } from 'Simple/Data'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  return (
    <DataProvider
      context="patient"
      value={{
        ...props.patient,
        field_differences: props.field_differences,
      }}
      viewPath={props.viewPath}
    >
      <View {...props} />
    </DataProvider>
  )
}
