import { useDataChange, useDataOriginalValue } from 'Simple/Data.js'

import { PAYOR_FILTERS } from '../../constants.js'

function usePayorFilterChange(props, filter) {
  let originalValue = useDataOriginalValue({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })

  return () => {
    change({
      ...originalValue,
      payor_filter: filter,
    })
  }
}

export function useOnClickExisting(props) {
  return usePayorFilterChange(props, PAYOR_FILTERS.EXISTING)
}

export function useOnClickNew(props) {
  return usePayorFilterChange(props, PAYOR_FILTERS.NEW)
}
