// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/LocationSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_LocationSelect($current_location_xid: String!) {
  app_admin_users_access(
    where: {
      organization_level: { _eq: "location" }
      xid: { _eq: $current_location_xid }
    }
    order_by: { organization_name: asc }
  ) {
    id
    organization_id
    vaxiom_organization_id
    text: organization_name
  }
}

`