import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let option = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'list',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (value && !next.selected.includes(option.text)) {
        next.selected.push(option.text)
      } else if (!value && next.selected.includes(option.text)) {
        next.selected = next.selected.filter(item => item !== option.text)
      }
    })
  }
}
