import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let current_location = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location',
  })

  return useMemo(
    () => ({
      ...data,
      id: current_location._id,
      vaxiom_id: current_location.id,
      text: current_location.name,
    }),
    [data, current_location]
  )
}
