import { booleanToYesNo, formatDateDisplay } from 'Data/format.js'

export let TYPE = {
  BOOLEAN: 0,
  TEXT: 1,
  FAMILY_MEMBER: 2,
  MEDICATION: 3,
  SENSITIVITY: 4,
  NOTIFICATION: 5,
}

let FAMILY_MEMBERS = [
  'MOTHER',
  'FATHER',
  'SISTER',
  'BROTHER',
  'GRANDMOTHER',
  'GRANDFATHER',
  'PARTNER',
  'SPOUSE',
  'STEPMOTHER',
  'STEPFATHER',
  'GUARDIAN',
  'AUNT',
  'UNCLE',
  'CHILD',
  'NEPHEW',
  'NIECE',
  'GRANDSON',
  'GRANDDAUGHTER',
  'STEPCHILD',
  'WARD',
]

export function getType(item) {
  if (item?.note) {
    return TYPE.NOTIFICATION
  }
  let answer = item.answers?.[0]?.value
  switch (item?.category) {
    case 'medications':
      return TYPE.MEDICATION
    case 'drugs_allergies_and_sensitivities':
      return TYPE.SENSITIVITY
    default: {
      if (isFamilyMemberAnswer(answer)) {
        return TYPE.FAMILY_MEMBER
      }

      return TYPE.TEXT
    }
  }
}

export function isFamilyMemberAnswer(answer) {
  if (answer != null) {
    if (Array.isArray(answer)) {
      return answer.every(
        item =>
          typeof item === 'string' &&
          FAMILY_MEMBERS.includes(item.toUpperCase())
      )
    } else if (typeof answer === 'string') {
      return FAMILY_MEMBERS.includes(answer.toUpperCase())
    }
  }

  return false
}

export function getValue(response, questionType, answer_alert) {
  switch (questionType) {
    case 'boolean':
      return response ? 'Yes' : 'No'
    case 'number':
    case 'choice':
    case 'text':
      return response
    case 'date':
      return formatDateDisplay(response)
    case 'multichoice':
      return Array.isArray(response)
        ? response
            .filter(
              item =>
                answer_alert === true ||
                (Array.isArray(answer_alert) && answer_alert?.includes(item))
            )
            .join(', ')
        : response
    default:
      return JSON.stringify(response)
  }
}

export function getAlertValue(answer_alert, answer_value) {
  if (
    answer_alert === false ||
    answer_alert === null ||
    answer_alert === undefined
  ) {
    return false
  }
  if (answer_alert === true) {
    if (typeof answer_value === 'boolean') {
      return answer_value
    } else if (typeof answer_value === 'string') {
      return !!answer_value.trim().length
    } else if (Array.isArray(answer_value)) {
      return !!answer_value.length
    }
    return true
  } else if (Array.isArray(answer_alert)) {
    if (Array.isArray(answer_value)) {
      return answer_value.some(item => answer_alert.includes(item))
    }

    return answer_alert.some(alert_value => {
      if (
        typeof answer_value === 'boolean' &&
        typeof alert_value === 'string' &&
        alert_value.toLowerCase() === booleanToYesNo(answer_value).toLowerCase()
      ) {
        return true
      }

      return answer_value === alert_value
    })
  }

  return typeof answer_value === 'boolean' && typeof answer_alert === 'string'
    ? answer_alert.toLowerCase() === booleanToYesNo(answer_value).toLowerCase()
    : answer_alert === answer_value
}

export function getPersonFullName(person) {
  return person
    ? [person.first_name, person.middle_name, person.last_name]
        .filter(Boolean)
        .join(' ')
    : ''
}

export function getTimestamp(date) {
  return date ? new Date(date).getTime() : 0
}
