import { useDataSubmit, useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let sync_id = useDataValue({
    context: 'sync',
    viewPath: props.viewPath,
    path: 'id',
  })
  return function onClick() {
    submit({
      type: 'open_sync',
      sync_id,
    })
  }
}
