// this is an autogenerated file from App/Account/Content/Insights/Reports/mutation-save-preset.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Insights_Reports($report_id: uuid!, $name: String!, $data: jsonb!) {
  insert_reports_filter_presets_one(
    object: { report_id: $report_id, name: $name, data: $data }
  ) {
    id
    name
    text: name
    data
  }
}

`