import React from 'react'
// See: https://formidable.com/open-source/victory/docs
import {
  VictoryAxis,
  VictoryLine,
  VictoryLegend,
  VictoryScatter,
  VictoryChart,
} from 'victory'

let COLORS = ['red', 'blue']

export default function LineChart(props) {
  if (!Array.isArray(props.value)) return null

  return (
    <VictoryChart
      height={props.height}
      width={props.width}
      padding={{ left: 32, right: 32, top: 4, bottom: 4 }}
      domainPadding={{ x: [20, 10], y: [10, 2] }}
    >
      {props.multiline && (
        <VictoryLegend
          x={props.width / 2 - 30}
          y={0}
          orientation="horizontal"
          style={{ title: { fontSize: 8 }, labels: { fontSize: 6 } }}
          colorScale={COLORS}
          data={props.legend}
        />
      )}

      {props.multiline &&
        props.value.map((data, i) => (
          <VictoryLine
            key={`victoryLine-${i}`}
            style={{
              data: { strokeWidth: 0.5, stroke: props.stroke || COLORS[i] },
              labels: { fontSize: 10 },
            }}
            data={data}
            interpolation={'natural'}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
        ))}

      {props.multiline &&
        props.value.map((data, i) => (
          <VictoryScatter
            key={`victoryScatter-${i}`}
            data={data}
            size={2}
            style={{ data: { fill: COLORS[i] } }}
          />
        ))}

      {!props.multiline && (
        <VictoryLine
          style={{
            data: { stroke: props.stroke || '#00BC70' },
            labels: { fontSize: 10 },
          }}
          data={props.value}
          interpolation={'natural'}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
        />
      )}
      {!props.multiline && (
        <VictoryScatter
          data={props.value}
          size={2}
          style={{ data: { fill: '#009659' } }}
        />
      )}
      <VictoryAxis
        label={props.xLabel || ''}
        fixLabelOverlap={true}
        offsetY={props.offsetY ?? 40}
        style={{
          axis: { stroke: 'rgba(0,0,0,0.3)' },
          tickLabels: {
            fontSize: 5,
            padding: 4,
            fontFamily: 'Inter',
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        label={props.yLabel || ''}
        style={{
          grid: { stroke: '#e0e0e0', strokeWidth: 0.5 },
          axis: { stroke: 'rgba(0,0,0,0.3)' },
          tickLabels: {
            fontSize: 5,
            fontFamily: 'Inter',
          },
        }}
      />
    </VictoryChart>
  )
}
