import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    submit({ type: 'statements/print' })
  }
}
