import { AwsCredentials } from 'Data/s3'
import View from './view.js'
import React from 'react'

export default function Logic(props) {
  return (
    <AwsCredentials viewPath={props.viewPath}>
      <View viewPath={props.viewPath} />
    </AwsCredentials>
  )
}
