import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let events = useDataValue({
    viewPath: props.viewPath,
    context: 'events',
  })

  let oldCount = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'oldCount',
  })

  let oldLimit = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'oldLimit',
  })

  let limit = useDataValue({
    viewPath: props.viewPath,
    context: 'message_compose',
    path: 'limit',
  })

  if (
    events.length === oldCount ||
    events.length < limit ||
    limit === oldLimit
  ) {
    return {
      status: 'End',
    }
  } else {
    return {
      status: 'LoadMore',
    }
  }
}
