import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let search_term = useStableValue(
    useDataValue({
      viewPath: props.viewPath,
      context: 'search',
      path: 'term',
    })
  )
  let whole_parent_company = useDataValue({
    viewPath: props.viewPath,
    context: 'search',
    path: 'whole_parent_company',
  })

  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      search_term,
      parent_company_id,
      whole_parent_company,
    },
    pause: search_term.length < 3 || !parent_company_id,
    requestPolicy: 'cache-and-network',
  }
}
