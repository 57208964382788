import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import load from 'load-script2'
import { useAuthRef } from 'Data/Auth.js'

let queue = {}
async function loadScript(url) {
  if (!queue[url]) {
    queue[url] = load(url)
  }
  return queue[url]
}

export default function AppComponent({
  value,
  onClose = () => {},
  viewPath,
  innerRef = null,
}) {
  let element = useRef(innerRef)
  let authRef = useAuthRef({
    viewPath,
  })
  let isRunning = useRef(false)

  useEffect(() => {
    if (isRunning.current) return

    async function run() {
      try {
        isRunning.current = true

        switch (value.type) {
          // other types to handle, module, iframe
          case 'module': {
            // Dynamically import the ES module
            let module = await import(
              /* webpackIgnore: true */ /* @vite-ignore */ value.url
            )
            executeFunction(getFunction(module))
            break
          }

          case 'script': {
            await loadScript(value.url, { async: false })
            executeFunction(getFunction(window))
            break
          }

          default: {
            break
          }
        }
      } catch (error) {
        console.error(`Failed to load module: ${value.url}`, error)
      }

      isRunning.current = false
    }
    run()

    function executeFunction(fn) {
      // Execute the function
      if (typeof fn === 'function') {
        let args = getArgs()
        console.log(`Executing function: ${value.entryPoint.path}`, {
          compiledArgs: args,
          sourceArgs: value.entryPoint.args,
        })
        fn(...args)
      } else {
        console.error(`Entry point is not a function: ${value.entryPoint.path}`)
      }
    }

    function getFunction(root) {
      // Access the function using the path
      let entryPointPath = value.entryPoint.path.split('.')
      let fn = root

      for (let i = 0; i < entryPointPath.length; i++) {
        fn = fn[entryPointPath[i]]
        if (!fn) {
          console.error(`Function not found: ${value.entryPoint.path}`)
          return
        }
      }

      return fn
    }

    function getArgs() {
      return value.entryPoint.args.map(ritem =>
        // TODO: switch (item.type) { case 'object': ... not a now thing
        // eslint-disable-next-line compat/compat
        Object.fromEntries(
          ritem.values.map(item => {
            let value = item.value
            switch (item.type) {
              case 'function': {
                switch (item.value) {
                  case '{{component.onClose}}': {
                    value = onClose
                    break
                  }
                  case '{{component.getPmsAccessToken}}': {
                    value = () => authRef.current.access_token
                    break
                  }
                  default: {
                    break
                  }
                }
                break
              }

              case 'dom': {
                switch (item.value) {
                  case '{{component.rootElement}}': {
                    value = element.current
                    break
                  }
                  default: {
                    break
                  }
                }
                break
              }

              default: {
                break
              }
            }

            return [item.name, value]
          })
        )
      )
    }
  }, [value])

  return createPortal(<div ref={element}></div>, document.body)
}
