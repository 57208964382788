import { useMemo } from 'react'

let DEFAULT_APPOINTMENT_REQUIREMENTS = [
  {
    id: 'REQUIRED_TREATMENT_FIELDS',
    name: 'Required treatment fields',
    task_title: 'Missing required treatment fields',
    type: 'internal',
  },
]

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    let external_requirements = data.requirements.map(requirement => ({
      ...requirement,
      type: 'external',
    }))

    return [...DEFAULT_APPOINTMENT_REQUIREMENTS, ...external_requirements]
  }, [data])
}
