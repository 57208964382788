import useIsHovered from 'Simple/hooks/useIsHovered.js'
import useIsFocused from 'Simple/hooks/useIsFocused.js'
import { useDataValue } from 'Simple/Data'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [hovered, selected, hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: props.selected,
  })
  let [focused, onFocusBind, onBlurBind] = useIsFocused({})

  let isHandHeld = useDataValue({
    viewPath: props.viewPath,
    context: 'media',
    path: 'device.handheld',
  })

  return (
    <View
      {...props}
      focused={focused}
      hovered={hovered}
      selected={selected}
      onFocus={onFocusBind}
      onBlur={onBlurBind}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      onChange={onChange}
      onMouseDown={(isHandHeld && props.onChange) || null}
      onClick={(!isHandHeld && props.onChange) || null}
    />
  )

  function onChange(event) {
    event.stopPropagation()

    if (typeof props.onChange !== 'function') return

    props.onChange(!props.value)
  }
}
