import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'notes.enabled',
  })
  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'notes.enabled',
  })

  return () => {
    change(!value)
  }
}
