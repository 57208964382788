// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/Internal/Tools/AddParticipantAction/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_Internal_Tools_AddParticipantAction_Content($current_users: [uuid!], $organization_id: uuid!) {
  users: vaxiom_sys_users(
    where: {
      disabled: { _eq: false }
      locked: { _eq: false }
      person: {
        public_user: { id: { _nin: $current_users } }
        employment_contracts: {
          _and: [
            { id: { _is_null: false } }
            { deleted: { _eq: false } }
            { employment_end_date: { _is_null: true } }
            {
              employee_resources: {
                resource: {
                  organization: {
                    parent_company: { _id: { _eq: $organization_id } }
                  }
                }
              }
            }
          ]
        }
      }
    }
  ) {
    id
    person {
      _id
      title
      first_name
      last_name
      public_user {
        id
      }
      employment_contracts {
        id
        employee_resources(limit: 1) {
          id
          resource {
            id
            resource_type {
              id
              name
            }
          }
        }
      }
    }
  }
}

`