import { useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'patients',
    viewPath: props.viewPath,
  })
  return function onClick(value) {
    switch (value) {
      case 'remove': {
        submit({
          type: 'remove',
          external_id: props.external_id,
        })
      }
    }
  }
}
