import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(user => ({
      id: user.id,
      text: `${user.person.search_name}`,
    }))
  }, [data])
}
