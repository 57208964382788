import { useMemo } from 'react'

let KEYS_MAP = {
  'audax.ceph.enabled': 'audax_ceph_enabled',
  'admin.patient.videos.enabled': 'admin_patient_videos_enabled',
}

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let app_properties = Object.fromEntries(
      Object.entries(KEYS_MAP).map(([message_key, app_prop_key]) => [
        app_prop_key,
        data.vaxiom_application_properties.some(
          item => item.id === message_key && item.value === 'true'
        ),
      ])
    )

    return {
      ...app_properties,
      itero_enabled: !!data.itero_connections?.length,
    }
  }, [data])
}
