import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

export function useOnChange(props, data) {
  let thread_id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })
  let users = useDataValue({
    context: 'users',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()

  let [, executeMutation] = useMutation(mutation)

  return async function onChange(value, change) {
    if (!value) return

    let mutationResponse = await executeMutation({
      user_id: value,
      thread_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Unable to add user to the discussion'
        )
      )
    } else {
      notify(
        notifySuccess(
          `${
            users.filter(user => user.id === value)[0].text
          } added to the discussion.`
        )
      )
    }
  }
}
