import View from './view.js'
import React from 'react'

export default function Logic(props) {
  return (
    <View
      {...props}
      onKeyUp={(event) => {
        if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
          props.onSubmit()
        }
      }}
      onChange={(event) => props.onChange(event.target.value)}
    />
  )
}
