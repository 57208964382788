import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let app_id = useDataValue({
    context: 'app',
    path: 'id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'connection_definition',
    path: 'id',
    viewPath: props.viewPath,
  })
  let title = useDataValue({
    context: 'connection_definition',
    path: 'title',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'manage', app_id, connection_name, title })
  }
}
