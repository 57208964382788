import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import {
  TREATMENT_PAYMENT_PLAN_LENGTH_TYPES,
  TREATMENT_PAYMENT_PLAN_VALUE_TYPES,
} from 'Data/constants.js'
import { numberZeroOrPositive } from 'Data/validate.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, change }) {
    let validation = validate(value)
    if (validation.isInvalid) {
      return notify(
        notifyError(`Invalid data: ${validation.errors.join(', ')}`)
      )
    }

    let res = await executeMutation({
      rulesets: value.tx_plan_groups.map(tpg => ({
        tx_plan_group_id: tpg._id,
        min_downpayment: value.min_downpayment,
        min_downpayment_type: value.min_downpayment_type,
        max_length: value.max_length,
        max_length_type: value.max_length_type,
      })),
    })

    if (res.error) {
      return notify(notifyError(`There was a problem adding the ruleset.`))
    }

    notify(notifySuccess(`Ruleset added!`))
    change(originalValue)
  }
}

function validate(ruleset) {
  let errors = []
  let {
    tx_plan_groups,
    min_downpayment,
    min_downpayment_type,
    max_length,
    max_length_type,
  } = ruleset

  if (!tx_plan_groups?.length) {
    errors.push('select at least one treatment plan group')
  }

  if (!TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(min_downpayment_type)) {
    errors.push('minimum downpayment type is required')
  } else if (!numberZeroOrPositive(min_downpayment)) {
    errors.push('minimum downpayment must be a non-negative number')
  } else if (min_downpayment_type === 'percentage' && min_downpayment > 100) {
    errors.push(
      'minimum downpayment percentage must be a number between 0 and 100'
    )
  }

  max_length = parseFloat(max_length)

  if (!TREATMENT_PAYMENT_PLAN_LENGTH_TYPES.includes(max_length_type)) {
    errors.push('maximum length type is required')
  } else if (!Number.isInteger(max_length)) {
    errors.push('maximum length must be an integer')
  } else if (max_length_type === 'total' && max_length < 0) {
    errors.push('total maximum length must be a non-negative integer')
  }

  if (
    min_downpayment === 100 &&
    min_downpayment_type === 'percentage' &&
    (max_length !== 0 || max_length_type !== 'total')
  ) {
    errors.push(
      'a minimum downpayment of 100% on the payer fee requires a total maximum length of 0 months'
    )
  } else if (
    max_length === 0 &&
    max_length_type === 'total' &&
    (min_downpayment !== 100 || min_downpayment_type !== 'percentage')
  ) {
    errors.push(
      'a total maximum length of 0 months requires a minimum downpayment of 100%'
    )
  }

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}
