import { phoneNumberUS } from 'Data/format.js'

export function getContacteMethods(contact_methods) {
  return contact_methods
    .filter(item => ['phone', 'email'].includes(item.dtype))
    .map(item => ({
      id: item.id,
      text:
        item.dtype === 'phone'
          ? phoneNumberUS(item.phone.number)
          : item.email.address,
    }))
}
