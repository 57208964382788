import React from 'react'
import { DndProvider as ReactDndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export default function DndProvider(props) {
  return (
    <ReactDndProvider backend={HTML5Backend} context={window}>
      {typeof props.children === 'function' ? props.children() : props.children}
    </ReactDndProvider>
  )
}
