import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import {
  TREATMENT_PAYMENT_PLAN_FREQUENCY_TYPES,
  TREATMENT_PAYMENT_PLAN_LENGTH_TYPES,
  TREATMENT_PAYMENT_PLAN_VALUE_TYPES,
} from 'Data/constants.js'
import { emptyList, numberZeroOrPositive } from 'Data/validate.js'
import { toSentence } from 'Data/format.js'

export function useOnSubmit(props) {
  let changes = useDataValue({
    context: 'ruleset_changes',
    viewPath: props.viewPath,
  })
  let rulesets = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit() {
    let validation = validate(rulesets)
    if (validation.isInvalid) {
      notify(notifyError(`Invalid data: ${validation.errors.join(', ')}`))
      return false
    }

    let mutationResponse = await executeMutation({
      rulesets_to_delete: changes.delete_changes,
      rulesets_to_insert: getPlansToInsert(changes.add_changes),
      rulesets_to_update: getPlansToUpdate(changes.update_changes),
      discounts_to_insert: getDiscountsToInsert(changes.update_changes),
      discounts_to_update: getDiscountsToUpdate(changes.update_changes),
      discounts_to_delete: getDiscountsToDelete(changes.update_changes),
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return false
    }

    notify(notifySuccess(`Rulesets updated successfully!`))
  }
}

function validate(values) {
  let errors = values
    .map((item, index) => {
      let errors = [
        !item._id && 'location is required',
        ...validateDefaultPaymentPlans(item.default_payment_plans),
      ].filter(Boolean)

      if (errors.length === 0) return null
      return `Card ${index + 1} >> ${toSentence(errors)}`
    })
    .filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}

function validateDefaultPaymentPlans(values) {
  return values
    .map((item, index) => {
      let length = parseFloat(item.length)
      let errors = [
        !item.name && 'name is required',
        !TREATMENT_PAYMENT_PLAN_FREQUENCY_TYPES.includes(item.frequency) &&
          'frequency is not valid',
        !TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(item.downpayment_type) &&
          'downpayment type is not valid',
        !numberZeroOrPositive(item.downpayment) && 'downpayment not valid',
        item.downpayment_type === 'percentage' &&
          item.downpayment > 100 &&
          'downpayment of type percentage must be a number between 0 and 100',
        !TREATMENT_PAYMENT_PLAN_LENGTH_TYPES.includes(item.length_type) &&
          'length type is not valid',
        !Number.isInteger(length) && 'length must be an integer',
        item.length_type === 'total' &&
          length < 0 &&
          'length of type total must be a non-negative integer',
        item.downpayment === 100 &&
          item.downpayment_type === 'percentage' &&
          (length !== 0 ||
            item.length_type !== 'total' ||
            item.frequency !== 'None') &&
          'a downpayment of 100% on the payer fee requires a total length of 0 months and frequency None',
        length === 0 &&
          item.length_type === 'total' &&
          (item.downpayment !== 100 ||
            item.downpayment_type !== 'percentage' ||
            item.frequency !== 'None') &&
          'a total length of 0 months requires a downpayment of 100% and frequency None',
        item.frequency === 'None' &&
          (item.downpayment !== 100 ||
            item.downpayment_type !== 'percentage' ||
            length !== 0 ||
            item.length_type !== 'total') &&
          'a frequency None requires a total length of 0 months and a downpayment of 100%',
        ...validateDiscounts(item.discounts),
      ].filter(Boolean)

      if (errors.length === 0) return null
      return `[plan ${index + 1}] ${toSentence(errors)}`
    })
    .filter(Boolean)
}

function validateDiscounts(values) {
  if (emptyList(values)) return []
  if (values.length > 1) return ['only one discount is allowed']

  return values
    .map((item, index) => {
      let errors = [
        !item.discount_id && 'discount name is required',
        !TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(item.type) &&
          'discount type is not valid',
        !numberZeroOrPositive(item.value) && 'discount value not valid',
        item.type === 'percentage' &&
          item.value > 100 &&
          'discount value of type percentage must be a number between 0 and 100',
      ].filter(Boolean)

      if (errors.length === 0) return false
      return `[discount ${index + 1}] ${toSentence(errors)}`
    })
    .filter(Boolean)
}

function getPlansToInsert(values) {
  return values.map(item => ({
    name: item.name,
    description: item.description,
    downpayment: item.downpayment,
    downpayment_type: item.downpayment_type,
    length: item.length,
    length_type: item.length_type,
    frequency: item.frequency,
    order: item.order,
    organization_id: item.organization_id,
    discounts: {
      data: item.discounts,
    },
  }))
}

function getPlansToUpdate(values) {
  return values.map(item => ({
    where: { id: { _eq: item.id } },
    _set: {
      name: item.name,
      description: item.description,
      downpayment: item.downpayment,
      downpayment_type: item.downpayment_type,
      length: item.length,
      length_type: item.length_type,
      frequency: item.frequency,
      order: item.order,
      organization_id: item.organization_id,
    },
  }))
}

function getDiscountsToInsert(values) {
  return values
    .map(item =>
      item.discount_changes.add_changes.map(discount => ({
        discount_id: discount.discount_id,
        default_payment_plan_id: item.id,
        type: discount.type,
        value: discount.value,
        stack_with_other_discounts: discount.stack_with_other_discounts,
      }))
    )
    .flat()
}

function getDiscountsToUpdate(values) {
  return values
    .map(item =>
      item.discount_changes.update_changes.map(discount => ({
        where: { id: { _eq: discount.id } },
        _set: {
          discount_id: discount.discount_id,
          type: discount.type,
          value: discount.value,
          stack_with_other_discounts: discount.stack_with_other_discounts,
        },
      }))
    )
    .flat()
}

function getDiscountsToDelete(values) {
  return values.map(item => item.discount_changes.delete_changes).flat()
}
