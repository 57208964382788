import { useMemo } from 'react'
import isBefore from 'date-fns/isBefore'
import endOfDay from 'date-fns/endOfDay'

import { useDataValue } from 'Simple/Data.js'
import { isAppointmentScheduled } from 'Data/format.js'

export default function useDataTransform(props, data) {
  let events = useDataValue({
    context: 'events',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let scheduled_appointments = events.filter(
      e =>
        e.type === 'appointment' &&
        isAppointmentScheduled(e.appointment) &&
        isBefore(
          Date.parse(`${e.appointment.booking.start_time}Z`),
          endOfDay(new Date())
        )
    )
    let event = scheduled_appointments.length
      ? scheduled_appointments[scheduled_appointments.length - 1]
      : null
    let index = events.indexOf(event)

    return {
      last_event_id: event?.id,
      previous_event_ids:
        index > 0
          ? events.slice(Math.max(index - 2, 0), index).map(event => event.id)
          : [],
    }
  }, [data, events])
}
