import { LOCAL_DEV_IP } from 'Data/constants'
import { PutObjectCommand } from '@aws-sdk/client-s3'
import { useDataValue } from 'Simple/Data.js'
import { text } from 'Data/validate.js'
import { useClient } from 'Data/Api.js'
import { useAwsS3Client } from 'Data/s3'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import query from './query.graphql.js'
import { v4 as uuid } from 'uuid'

let TEMPORARY_PREFIX = 'temp'

export function useOnClick(props) {
  let document_id = useDataValue({
    context: 'preview_document',
    path: 'document_id',
    viewPath: props.viewPath,
  })
  let document_url = useDataValue({
    context: 'document',
    path: 'url',
    viewPath: props.viewPath,
  })

  let is_dirty = useDataValue({
    context: 'editor',
    path: 'isDirty',
    viewPath: props.viewPath,
  })

  let editor = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let client = useClient()
  let s3 = useAwsS3Client(props)
  let [, notify] = useNotifications()

  return async function onClick() {
    try {
      let { data: parentCompanyData, error: parentCompanyError } = await client
        .query(query, { document_id })
        .toPromise()

      if (parentCompanyError) {
        throw new Error(`Couldn't generate the sample preview`)
      }

      let parent_company_uuid =
        parentCompanyData?.vaxiom_document_tree_nodes_by_pk?.patient
          ?.organization?.parent_company[0]?.id

      if (!parent_company_uuid) {
        throw new Error(`Couldn't generate the sample preview`)
      }

      let mutation_variables = {
        type: 'url',
        content: document_url,
        parent_company_id: parent_company_uuid,
      }

      if (is_dirty) {
        let content = editor.getContent()
        let file_key = uuid()
        let destination_key = `${TEMPORARY_PREFIX}/${file_key}`

        await (
          await s3()
        ).send(
          new PutObjectCommand({
            Bucket: process.env.REACT_APP_S3_BUCKET_FILES,
            Key: destination_key,
            Body: content,
            ContentType: 'text/html',
          })
        )

        let preview_url =
          process.env.REACT_APP_ENV === 'development'
            ? `http://${LOCAL_DEV_IP}:3003/${process.env.REACT_APP_S3_BUCKET_FILES}/${destination_key}`
            : `https://${process.env.REACT_APP_S3_BUCKET_FILES}.s3.amazonaws.com/${destination_key}`

        mutation_variables.content = preview_url
      }

      let { data, error } = await client
        .mutation(mutation, mutation_variables)
        .toPromise()

      if (error) {
        throw new Error(`Couldn't generate the sample preview`)
      }

      let { url } = data?.vaxiom_export_html_document_to_pdf
      if (!url) {
        throw new Error(`Couldn't generate the sample preview`)
      }

      window.open(url, '_blank')
    } catch (error) {
      let errorMessage = text(error)
        ? error
        : error.message
        ? error.message
        : `Couldn't generate the sample preview`

      return notify(notifyError(errorMessage))
    }
  }
}
