import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let name = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'name',
  })

  return useMemo(
    () => ({
      ...data,
      name,
    }),
    [data, name]
  )
}
