import { useMemo } from 'react'

import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  let booking_id = useDataValue({
    context: 'event',
    path: 'appointment.booking.id',
    viewPath: props.viewPath,
  })
  let diagnosis_id = useDataValue({
    context: 'event',
    path: 'appointment.diagnosis.id',
    viewPath: props.viewPath,
  })
  return useMemo(
    () => ({
      patient_id,
      appointment_id,
      booking_id,
      diagnosis_id,
      tab: 'appointment_diagnosis',
    }),
    [appointment_id, booking_id, diagnosis_id, patient_id]
  )
}
