import React, { useState } from 'react'
import View from './view.js'
import formatDate from 'date-fns/format'
import isValid from 'date-fns/isValid'
import DayPicker from 'react-day-picker/DayPicker'
import 'react-day-picker/lib/style.css'
import './day-picker.css'

function YearMonthForm({ date, localeUtils, onChange, yearPastFrom, yearTo }) {
  let months = localeUtils.getMonths()
  let currentYear = new Date().getFullYear()
  let fromMonth = new Date(currentYear - yearPastFrom, 0)
  let toMonth = new Date(currentYear + yearTo, 11)

  let years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  let handleChange = function handleChange(e) {
    let { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  )
}

export default function Logic({
  viewPath,
  onChange,
  value = Date.now(),
  disableFuture = false,
  disablePast = false,
  datePast = new Date(),
  dateFuture = new Date(),
  format = 'MM/dd/yyyy',
  innerRef,
  yearPicker = false,
  yearPastFrom = 100,
  yearTo = 10,
  top,
  left,
}) {
  let date = new Date(value)
  let dateIsValid = isValid(date)
  let [month, setMonth] = useState(dateIsValid ? date : new Date())
  let yearPickerProps = {}
  if (yearPicker) {
    yearPickerProps = {
      month,
      captionElement: function ({ date, localeUtils }) {
        return (
          <YearMonthForm
            date={date}
            yearPastFrom={yearPastFrom}
            yearTo={yearTo}
            localeUtils={localeUtils}
            onChange={setMonth}
          />
        )
      },
    }
  }

  return (
    <View viewPath={viewPath} innerRef={innerRef} top={top} left={left}>
      <DayPicker
        className={yearPicker ? 'has-year-picker' : ''}
        initialMonth={dateIsValid ? date : new Date()}
        selectedDays={dateIsValid ? date : null}
        onDayClick={date => {
          if (!date || typeof onChange !== 'function') return

          onChange(null, formatDate(date, format))
        }}
        disabledDays={{
          after: disableFuture && dateFuture, // disable future dates
          before: disablePast && datePast, // disable past dates
        }}
        {...yearPickerProps}
      />
    </View>
  )
}
