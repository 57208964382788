// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/MaxDiscount/Rulesets/Content/Actions/Content/SaveAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_MaxDiscount_Rulesets_Content_Actions_Content_SaveAction(
  $rulesets_to_delete: [uuid!]
  $rulesets_to_update: [treatments_max_discounts_updates!]!
) {
  delete_treatments_max_discounts(where: { id: { _in: $rulesets_to_delete } }) {
    affected_rows
    returning {
      id
    }
  }
  update_treatments_max_discounts_many(updates: $rulesets_to_update) {
    affected_rows
    returning {
      id
    }
  }
}

`