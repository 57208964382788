import { useDataSubmit, useDataIsSubmitting, useDataValue } from 'Simple/Data'
import { PAYMENTS_PAYMENT_TYPES } from 'Data/common-constants.js'

import { validate } from '../helpers.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'payments_post_payment',
  })

  return function onClick() {
    submit({
      type: 'form_submit',
    })
  }
}

export function useIsDisabled(props) {
  let value = useDataValue({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })
  let isSubmitting = useDataIsSubmitting({
    context: 'payments_post_payment',
    viewPath: props.viewPath,
  })
  let paymentMethodValid = useDataValue({
    context: 'payment',
    path: 'valid',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    validate(value).isInvalid ||
    (!paymentMethodValid &&
      [
        PAYMENTS_PAYMENT_TYPES.CreditCardPayment,
        PAYMENTS_PAYMENT_TYPES.BankAccountPayment,
      ].includes(value.payment_method.data.payments_payment_type))
  )
}
