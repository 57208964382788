import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let external_professional_id = useDataValue({
    context: 'person',
    path: 'external_professional_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      id: external_professional_id,
    },
  }
}
