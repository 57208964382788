// this is an autogenerated file from App/Account/Topbar/Tabs/QuickAccess/Tab/Icon/Comms/Status/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Topbar_Tabs_QuickAccess_Tab_Icon_Comms_Status($resources_ids: [String!]!) {
  comms_threads_new_aggregate(
    where: { resources_ids: { _has_keys_any: $resources_ids } }
  ) {
    aggregate {
      count
    }
  }
}

`