import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let date = useDataValue({
    context: 'calendar_day',
    viewPath: props.viewPath,
    path: 'date',
  })

  return function onClick(e) {
    e.preventDefault()
    if (e.type === 'click') {
      change(next => {
        date.setHours(next.selected.date.getHours())
        date.setMinutes(next.selected.date.getMinutes())
        date.setSeconds(next.selected.date.getSeconds())

        next.selected.date = date
      })

      if (typeof props.onLeftClick === 'function') props.onLeftClick(e)
    }
  }
}
