import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let refreshEvents = useDataChange({
    context: 'refresh',
    path: 'events',
    viewPath: props.viewPath,
  })

  return function onClick() {
    refreshEvents(Date.now())
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
