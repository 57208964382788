import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let current_location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data) return data
    if (!current_location_id) return data

    return {
      ...data,
      location_ids: [current_location_id],
    }
  }, [data, current_location_id])
}
