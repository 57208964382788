import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_id = useDataValue({
    context: 'add_connection',
    path: 'connection_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { connection_id },
  }
}
