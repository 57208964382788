import { gql } from 'Data/Api.js'

export default gql`
  mutation vaxiom_resource_object_copy(
    $resource_id: numeric!
    $resource_type: vaxiom_resource_object_copy_resource_type!
    $destination_key: String!
  ) {
    vaxiom_resource_object_copy(
      resource_id: $resource_id
      resource_type: $resource_type
      destination_key: $destination_key
    ) {
      url
    }
  }
`
