// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/query_payment_receipt_download.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content($payment_id: numeric!, $person_id: numeric!) {
  vaxiom_payment_receipt_download(
    payment_id: $payment_id
    person_id: $person_id
  ) {
    url
  }
}

`