function keyValues(obj, separator = ', ') {
  // if no displayValues defined give back the values joined
  let keys = obj.displayValues || Object.keys(obj)
  return keys.map(key => obj[key]).join(separator)
}

export function questionsAndAnswers(answers, questions) {
  return answers
    ?.map(item => {
      if (!item.answer || item.isInformationOnly || !questions[item.id].prompt)
        return false
      return {
        question: questions[item.id].prompt,
        answer: Array.isArray(item?.answer)
          ? item?.answer.map(keyValues).join(', ')
          : typeof item?.answer === 'object'
          ? keyValues(item?.answer)
          : item?.answer,
      }
    })
    .filter(Boolean)
}
