import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })

  let location_accounts = useDataValue({
    viewPath: props.viewPath,
    context: 'location_accounts',
  })

  return function onChange(value) {
    change(location_accounts.find(account => account.id === value))
  }
}
