import { useDataValue, useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.document_templates.location_id',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === location_id) return

    change(next => {
      next.selected.document_templates.location_id = value
      next.selected.document_templates.document_template = null
    })
  }
}
