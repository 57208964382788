// this is an autogenerated file from App/Account/TimeOutOfSyncNotice/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_TimeOutOfSyncNotice{
  time {
    now
  }
}

`