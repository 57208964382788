import { logout } from 'Data/format.js'
import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifySuccess, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import React, { useEffect } from 'react'
import View from './view.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export default function Logic(props) {
  let refresh_token = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'refresh_token',
  })
  let [, executeMutation] = useMutation(mutation)
  let change = useDataChange({
    context: 'auth',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    // This always have to be executed, no need to cancel
    ;(async function () {
      await executeMutation({
        refresh_token,
      })

      submit({ type: 'reset' })
      setFlowTo(normalizePath(props.viewPath, '../SignIn'))
      change(logout())
      notify(notifySuccess(`You've signed out`))
    })()
  }, []) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
