import React, { useEffect, useRef } from 'react'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let slot_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'scheduling.slot_id',
  })
  let initial_slot_id = useRef(slot_id)

  useEffect(() => {
    if (initial_slot_id.current !== slot_id) {
      props.closePopover?.()
    }
  }, [slot_id])

  return <View {...props} />
}
