export function getView(value) {
  switch (value) {
    case 'Weekly':
    case 'EverySecondWeek':
      return 'Days'

    case 'TwicePerMonth':
    case 'Monthly':
    case 'Quarterly':
    case 'SemiAnnual':
    case 'Yearly':
      return 'Dates'

    default:
      return 'No'
  }
}
