import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let trigger_type = useDataValue({
    context: 'automation',
    path: 'trigger_type',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data) return []

    return trigger_type === 'pre'
      ? data.filter(item => item.id === 'UNSEATED')
      : data
  }, [data, trigger_type])
}
