import { useDataValue, useDataChange } from 'Simple/Data.js'
import { toDate } from 'date-fns-tz'
import { format } from 'date-fns'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let date = useDataValue({
    context: 'date',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let timeZoneId = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    // close popup
    setFlowTo(normalizePath(props.viewPath, '../../../../No'))
    change(next => {
      next.selected.date = toDate(date.date, { timeZone: timeZoneId })
    })
  }
}

/**
 * @param {string} date_date
 * @param {Date} selected_date
 * @returns {boolean}
 */
export function isSelected(date_date, selected_date) {
  return date_date === format(selected_date, 'yyyy-MM-dd')
}
