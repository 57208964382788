import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return function onClick() {
    change({
      url: null,
      content_type: null,
      file_id: null,
      file_name: null,
      id: null,
    })
  }
}
