import React from 'react'
import { DataProvider } from 'Simple/Data.js'
import { getParentView } from 'Simple/Flow'

let value = {
  api: null,
  isInitialized: false,
  isDirty: false,
  // disableActions: false,
  hasSignature: null,
  disabled: false,
  saving: false,
}

export default function Editor(props) {
  // this is needed because the DataProvider context needs to be attached at the app
  // level flow-wise to be consumed properly by the app's children
  let [viewPath] = getParentView(props.viewPath)
  return (
    <DataProvider context="editor" value={value} viewPath={viewPath}>
      {typeof props.children === 'function' ? props.children() : props.children}
    </DataProvider>
  )
}
