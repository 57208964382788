import {
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let insurances = []
    for (let insurance of value.insurances) {
      let primary_insurance = value.insurances.find(item => item.is_primary)
      let { downpayment_amount, installment_amount } = insurance.is_primary
        ? getPrimaryInsuranceEstimatedReimbursement(
            insurance,
            value,
            treatment_plan
          )
        : getSecondaryInsuranceEstimatedReimbursement(
            insurance,
            primary_insurance,
            value,
            treatment_plan
          )
      insurances.push({
        insured_id: insurance.insured_id,
        estimated_reimbursement_amount:
          insurance.estimated_reimbursement_amount,
        is_primary: insurance.is_primary,
        // TODO: ideally these fields should be re-clalculated on the backend as all depend on the insured (subscription)
        // passing it from UI for now since it's too much work and duplicated logic in SQL
        downpayment_amount,
        installment_amount,
      })
    }
    let mutationResponse = await executeMutation({
      current_location_id,
      payment_plan: {
        ...value,
        insurances,
      },
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem saving the payment plan. Please try again.`
        )
      )
      return
    } else {
      notify(notifySuccess(`Payment plan saved successfully.`))
    }
  }
}
