import 'Logic/polyfillObjectEntries.js'
import '@reach/dialog/styles.css'
import './index.css'
import './version.js'
import 'Logic/platform.js'
import { Api } from 'Data/Api.js'
import { Auth } from 'Data/Auth.js'
import { FlowShortcuts } from 'Logic/FlowShortcuts.js'
import { Notifications } from 'Logic/Notifications.js'
import { DesignTokens } from 'Simple/DesignTokens'
import Media from 'Simple/Media'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App/index.js'
import SentryErrorBoundary from 'DesignSystem/SentryErrorBoundary/react.js'
import SuspenseBoundary from 'DesignSystem/SuspenseBoundary/index'

// not ideal but...
let error = window.console.error
window.console.error = (...args) => {
  if (
    /(cannot appear as a descendant of|must have either an)/.test(
      args.join(' ')
    )
  )
    return

  error(...args)
}

let root = createRoot(document.getElementById('root'))

root.render(
  <SentryErrorBoundary viewPath="/App">
    <App>
      {({ children, viewPath }) => (
        <Media viewPath={viewPath}>
          <DesignTokens viewPath={viewPath}>
            <FlowShortcuts viewPath={viewPath}>
              <Notifications viewPath={viewPath}>
                <Auth viewPath={viewPath}>
                  <Api viewPath={viewPath}>
                    <SuspenseBoundary viewPath={viewPath}>
                      {children}
                    </SuspenseBoundary>
                  </Api>
                </Auth>
              </Notifications>
            </FlowShortcuts>
          </DesignTokens>
        </Media>
      )}
    </App>
  </SentryErrorBoundary>
)
