// @view
import React from 'react'
import dateFnsParse from 'date-fns/parse'
import dateFnsformat from 'date-fns/format'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './day-picker.css'

function formatDate(date, format, locale) {
  return dateFnsformat(date, format, { locale })
}

function parseDate(str, format, locale) {
  let parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

export default function DatePicker({
  onChange,
  value,
  isInvalid,
  disableFuture = false,
  disablePast = false,
  isDisabled = false,
  datePast = new Date(),
  dateFuture = new Date(),
}) {
  let FORMAT = 'MM/dd/yyyy'

  return (
    <>
      <DayPickerInput
        value={value}
        inputProps={{
          type: 'text',
          pattern: '[0-9\\/]',
          disabled: isDisabled,
        }}
        parseDate={parseDate}
        formatDate={formatDate}
        format={FORMAT}
        onDayChange={d => {
          d && onChange(formatDate(d, FORMAT))
        }}
        placeholder={`${FORMAT.toLowerCase()}`}
        dayPickerProps={{
          disabledDays: {
            after: disableFuture && dateFuture, // disable future dates
            before: disablePast && datePast, // disable past dates
          },
        }}
      />
    </>
  )
}
