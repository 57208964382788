import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changePayment = useDataChange({
    viewPath: props.viewPath,
    context: 'payment',
  })

  return async function onChange(value) {
    changePayment(nextPayment => {
      nextPayment.allow_reuse =
        typeof value.payment_account.data.insured_id !== 'number'
    })
  }
}
