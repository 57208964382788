import useIsHovered from 'Simple/hooks/useIsHovered.js'
import useIsFocused from 'Simple/hooks/useIsFocused.js'
import { useDataValue } from 'Simple/Data'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [hovered, hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: props.selected,
  })
  let [focused, onFocusBind, onBlurBind] = useIsFocused({})

  let isHandHeld = useDataValue({
    viewPath: props.viewPath,
    context: 'media',
    path: 'device.handheld',
  })

  return (
    <View
      {...props}
      focused={focused}
      hovered={hovered}
      onFocus={onFocusBind}
      onBlur={onBlurBind}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      onMouseDown={(isHandHeld && props.onClick) || null}
    />
  )
}
