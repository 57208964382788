import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let option = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'list',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      if (Array.isArray(next.alerts)) {
        let isActive = next.alerts.includes(option.text)

        if (isActive) {
          next.alerts = next.alerts.filter(item => item !== option.text)
        } else {
          next.alerts.push(option.text)
        }
      } else if (next.alerts === true) {
        next.alerts = next.options
          .filter(item => item.text !== option.text)
          .map(item => item.text)
      } else if (next.alerts === false) {
        next.alerts = [option.text]
      }
    })
  }
}

export function isAlertActive(alerts, name) {
  if (Array.isArray(alerts)) {
    return alerts.includes(name)
  }

  if ([true, false].includes(alerts)) {
    return alerts
  }

  return false
}
