import React, { useEffect } from 'react'

import View from './view.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let refresh_progress = useDataValue({
    context: 'refresh',
    path: 'progress',
    viewPath: props.viewPath,
  })
  let submitProgress = useDataSubmit({
    context: 'treatment_progress',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refresh_progress) return

    submitProgress({ type: 'reFetch' })
  }, [refresh_progress]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitProgress

  return <View {...props} />
}
