import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { useAwsS3Client } from 'Data/s3'
import { generateSignedUrl } from 'Data/s3'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSaveEditorContent } from '../useSaveEditorContent.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let document_id = useDataValue({
    context: 'preview_document',
    path: 'document_id',
    viewPath: props.viewPath,
  })
  let document = useDataValue({
    context: 'document',
    viewPath: props.viewPath,
  })
  let editor = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    viewPath: props.viewPath,
  })
  let save = useSaveEditorContent(props)

  let s3 = useAwsS3Client(props)

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    change(next => {
      next.saving = true
    })

    await save({
      url: document.url,
      content: editor.getContent(),
    })

    let mutationResponse = await executeMutation({
      document_id,
    })

    editor.setDirty(false)
    change(next => {
      next.isDirty = false
      next.saving = false
    })
    if (mutationResponse.error) {
      notify(notifyError(`Couldn't save the document as PDF`))
    } else {
      notify(notifySuccess(`The document was saved successfully as PDF`))
      let url = await generateSignedUrl(s3, document.url, {
        download: false,
        filename: document.name.replace('.html', '.pdf'),
        content_type: 'application/pdf',
      })
      window.open(url, '_blank')
    }
  }
}
