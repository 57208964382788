import React from 'react'
import useIsHovered from 'Simple/hooks/useIsHovered.js'

import View from './view.js'

export default function Logic(props) {
  let [isHoveredManual, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: props.isSelected,
  })

  return (
    <View
      isHoveredManual={isHoveredManual}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      {...props}
    />
  )
}
