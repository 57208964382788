import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })
  let user_id = useDataValue({
    context: 'auth',
    path: 'access_token_data.user_id',
    viewPath: props.viewPath,
  })

  let thread_id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    let mutationResponse = await executeMutation({
      user_id,
      thread_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Something went wrong. Unable to leave the discussion')
      )
    } else {
      notify(notifySuccess(`You've left the discussion.`))

      submit({
        type: 'tabs/add',
        tab: {
          id: 'Comms',
          name: 'Comms',
          type: 'Comms',
          viewPath: '/App/Account/Content/Comms',
          sync: {},
        },
      })
    }
  }
}
