import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      ...data,
      layout: props.layout,
      image_definition: props.imageDefinition,
      image_series_id: props.imageSeriesId,
      patient_image: props.patientImage,
      resource: props.resource,
    }
  }, [
    data,
    props.layout,
    props.imageDefinition,
    props.imageSeriesId,
    props.patientImage,
    props.resource,
  ])
}
