import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'empty_slot',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.type = 'blockTime'
      next.view = 'content'
    })
  }
}
