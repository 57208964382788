import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(
      ({ action, is_required, event_execution, id, status, data }, index) => ({
        index,
        id,
        status,
        is_required,
        data: getActionExecutionData(action),
        action_execution_data: data,
        user_id: action?.event?.user_id,
        action_id: action.id,
        action_type: action?.type,
        event_type: action?.event?.type,
        trigger_type: action?.event?.trigger_type,
        event_value: action?.event?.data?.value_to,
        event_execution_id: event_execution.id,
        event_execution_data: event_execution.data,
      })
    )
  }, [data])
}

function getActionExecutionData({ type, data }) {
  switch (type) {
    case 'status_update':
      return {
        new_status: data.default_status ?? '',
        statuses: data.subset_statuses ?? [],
      }
    case 'task_insert':
      return {
        task: {
          title: data.title ?? '',
          description: data.description ?? '',
          document_template_id: data.document_template_id ?? null,
          generate_letter: false,
          assignee_id: null,
          task_basket_id: data.task_basket_id ?? null,
          recipient_id: null,
        },
      }
    case 'notification':
      return {
        notification: {
          recipient_id: null,
          email_templates: data.email_template.subset ?? [],
          sms_templates: data.sms_template.subset ?? [],
          email_template_id: data.email_template.default_value ?? null,
          sms_template_id: data.sms_template.default_value ?? null,
        },
      }
    case 'check_requirements':
      return data
    default:
      return {}
  }
}
