import { useMemo } from 'react'
import { useDataOriginalValue, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets_original = useDataOriginalValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let rulesets_current = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!rulesets_original?.length || !rulesets_current) {
      return data
    }

    let delete_changes = deleteChanges(rulesets_original, rulesets_current)
    let update_changes = updateChanges(rulesets_original, rulesets_current)

    return {
      has_changes: Boolean(delete_changes.length || update_changes.length),
      delete_changes,
      update_changes,
    }
  }, [data, rulesets_original, rulesets_current])
}

function deleteChanges(original, current) {
  return original
    .filter(or => !current.map(cr => cr.id).includes(or.id))
    .map(or => or.id)
}

function updateChanges(original, current) {
  return current
    .map((item, index) => ({ ...item, index }))
    .filter(item => {
      let or_item = original.find(ruleset => ruleset.id === item.id)

      return !or_item
        ? false
        : or_item.tx_plan_group_id !== item.tx_plan_group_id ||
            or_item.min_downpayment !== item.min_downpayment ||
            or_item.min_downpayment_type !== item.min_downpayment_type ||
            or_item.max_length !== item.max_length ||
            or_item.max_length_type !== item.max_length_type
    })
}
