// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/update-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes($id: numeric!, $note: String!, $user_id: numeric!) {
  update_vaxiom_notes_by_pk(
    pk_columns: { id: $id }
    _set: { note: $note, author: $user_id }
  ) {
    id
    note
    author
  }
}

`