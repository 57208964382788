import { useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { addPrefixIfMissing } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutationSms.graphql.js'

export default function useDataOnActionSms(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onActionSms({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      number: addPrefixIfMissing(args.number),
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem starting this thread.`))
    }

    submit({
      type: 'tab/openCommsThread',
      thread_id: mutationResponse.data.thread.id,
    })

    // close dropdown
    setFlowTo(normalizePath(props.viewPath, 'Content/No'))
  }
}
