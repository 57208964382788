import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { toSentence } from 'Data/format.js'

export function useOnSubmit(props) {
  let changes = useDataValue({
    context: 'ruleset_changes',
    viewPath: props.viewPath,
  })
  let rulesets = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit() {
    let validation = validate(rulesets)
    if (validation.isInvalid) {
      notify(notifyError(`Invalid data: ${validation.errors.join(', ')}`))
      return false
    }

    let mutationResponse = await executeMutation({
      rulesets_to_delete: changes.delete_changes,
      rulesets_to_insert: changes.add_changes.map(ruleset => ({
        organization_id: ruleset.organization_id,
        type: ruleset.type,
        label: ruleset.label,
        document_template_id: ruleset.document_template_id,
        allowed_dates: ruleset.allowed_dates,
      })),
      rulesets_to_update: changes.update_changes.map(ruleset => ({
        where: { id: { _eq: ruleset.id } },
        _set: {
          organization_id: ruleset.organization_id,
          label: ruleset.label,
          allowed_dates: ruleset.allowed_dates,
          document_template_id: ruleset.document_template_id,
        },
      })),
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return false
    }

    notify(notifySuccess(`Rulesets updated successfully!`))
  }
}

function validate(rulesets) {
  let errors = rulesets
    .map((item, index) => {
      let errors = [
        !item.frequencies.some(f => f.type === 'None' && f.enabled) &&
          'missing None frequency',
        ...validateFrequencies(item.frequencies.filter(f => f.enabled)),
      ].filter(Boolean)

      if (errors.length === 0) return false
      return `[card ${index + 1}] ${toSentence(errors)}`
    })
    .filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}

function validateFrequencies(frequencies) {
  return frequencies
    .map(item => {
      let errors = [
        item.type !== 'None' &&
          !item.allowed_dates?.length &&
          'missing allowed dates',
        !item.document_template_id && 'missing document template',
      ].filter(Boolean)

      if (errors.length === 0) return false
      return `[${item.type}] ${toSentence(errors)}`
    })
    .filter(Boolean)
}
