// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/RefreshButton/query_external_professionals.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_RefreshButton($patient_id: uuid!) {
  vaxiom_professional_relationships(
    where: {
      dtype: { _eq: "otherProfessionalRelationship" }
      patient: { _id: { _eq: $patient_id } }
    }
  ) {
    id
    other {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`