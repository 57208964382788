let NEW = 'new'
let OPEN = 'open'
let PENDING = 'pending'
let ONHOLD = 'onhold'
let CLOSED = 'closed'

export let MESSAGE_ACTIONS = {
  withMessage: {
    [NEW]: 'Send and Open',
    [OPEN]: 'Send and Open',
    [PENDING]: 'Send and Set To Pending',
    [ONHOLD]: 'Send and Put On Hold',
    [CLOSED]: 'Send and Close',
  },
  noMessage: {
    [NEW]: 'Open',
    [OPEN]: 'Open',
    [PENDING]: 'Set To Pending',
    [ONHOLD]: 'Put On Hold',
    [CLOSED]: 'Close',
  },
  tags: {
    [NEW]: 'New',
    [OPEN]: 'Open',
    [PENDING]: 'Pending',
    [ONHOLD]: 'On Hold',
    [CLOSED]: 'Close',
  },
}

export let THREAD_STATUS_ORDERED_WITH_INDEX = {
  [NEW]: 0,
  [OPEN]: 1,
  [PENDING]: 2,
  [ONHOLD]: 3,
  [CLOSED]: 4,
}

export let THREAD_STATUSES = {
  [NEW]: NEW,
  [OPEN]: OPEN,
  [PENDING]: PENDING,
  [ONHOLD]: ONHOLD,
  [CLOSED]: CLOSED,
}

export let THREAD_STATUS_ORDERED = [NEW, OPEN, PENDING, ONHOLD, CLOSED]

export let THREAD_STATUS_ORDERED_WITHOUT_NEW = [OPEN, PENDING, ONHOLD, CLOSED]

export let FEATURE_FLAGS = [
  {
    key: 'patient.login.enabled',
    label: 'Patient Hub login',
    description: 'Patient is able to login onto the Patient Hub app',
  },
  {
    key: 'patient.appointments.cancel',
    label: 'Appointment cancellation',
    description: 'Patient is able to cancel appointments',
  },
  {
    key: 'patient.appointments.reschedule',
    label: 'Appointment rescheduling',
    description: 'Patient is able to reschedule appointments',
  },
  {
    key: 'patient.appointments.schedule',
    label: 'Appointment scheduling',
    description: 'Patient is able to schedule appointments',
  },
  {
    key: 'patient.comms.enabled',
    label: 'Communication features',
    description: 'Patient is able to use the message functionality',
  },
]

export let AUDAX_CEPH_TRACING_FEATURE_FLAGS = [
  {
    key: 'audax.ceph.enabled',
    label: 'Enable Audax Ceph Integration',
    description: 'The audax ceph integration is enabled for the location',
  },
]

export let MEDICAL_FORM_NEW_APP_FEATURE_FLAGS = [
  {
    key: 'medical-form.v2.disable_insurance_questions',
    label: 'Disable Insurance Questions for the New Medical Form',
    description:
      'The insurance questions for the new medical form app will be disabled',
  },
  {
    key: 'medical.form.v2.appointment.enabled',
    label:
      'Enable ability to schedule appointment directly after completion of New Medical Form',
    description:
      'The user can schedule an appointment as part of the medical form process',
  },
]

export let PATIENT_IMAGE_LAYOUT_DEFINITIONS = {
  standard_8: [
    [
      {
        type_key: 'ortho',
        slot_key: 'facialFrontal',
        rows: 3,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'facialFrontalSmiling',
        rows: 3,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'facialLateral',
        rows: 3,
        columns: 3,
      },
    ],
    [
      {
        type_key: 'ortho',
        slot_key: 'occlusalUpper',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'logo',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'occlusalLower',
        rows: 2,
        columns: 3,
      },
    ],
    [
      {
        type_key: 'ortho',
        slot_key: 'buccalRight',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'buccalCenter',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'buccalLeft',
        rows: 2,
        columns: 3,
      },
    ],
  ],
  standard_10: [
    [
      {
        type_key: 'ortho',
        slot_key: 'facialFrontal',
        rows: 3,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'facialFrontalSmiling',
        rows: 3,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'facialLateral',
        rows: 3,
        columns: 3,
      },
      {
        type_key: 'xRayCephalometric',
        slot_key: 'xRayCephalometric',
        rows: 5,
        columns: 5,
      },
    ],
    [
      {
        type_key: 'ortho',
        slot_key: 'occlusalUpper',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'logo',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'occlusalLower',
        rows: 2,
        columns: 3,
      },
    ],
    [
      {
        type_key: 'ortho',
        slot_key: 'buccalRight',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'buccalCenter',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'ortho',
        slot_key: 'buccalLeft',
        rows: 2,
        columns: 3,
      },
      {
        type_key: 'xRayPanoramic',
        slot_key: 'xRayPanoramic',
        rows: 2,
        columns: 5,
      },
    ],
  ],
  x_ray_cephalometric: [
    [
      {
        type_key: 'xRayCephalometric',
        slot_key: 'xRayCephalometric',
        rows: 331,
        columns: 299,
      },
    ],
  ],
  x_ray_panoramic: [
    [
      {
        type_key: 'xRayPanoramic',
        slot_key: 'xRayPanoramic',
        rows: 150,
        columns: 300,
      },
    ],
  ],
}

export let PATIENT_IMAGE_NAMES = {
  buccalCenter: 'Buccal center',
  buccalLeft: 'Buccal left',
  buccalRight: 'Buccal right',
  facialFrontal: 'Facial frontal',
  facialFrontalSmiling: 'Facial frontal smiling',
  facialLateral: 'Facial lateral',
  occlusalLower: 'Occlusal lower',
  occlusalUpper: 'Occlusal upper',
  xRayCephalometric: 'X-Ray cephalometric',
  xRayMolarLeft: 'X-Ray molar left',
  xRayMolarPreLeft: 'X-Ray molar pre left',
  xRayMolarPreRight: 'X-Ray molar pre right',
  xRayMolarRight: 'X-Ray molar right',
  xRayPanoramic: 'X-Ray panoramic',
}

export let PATIENT_IMAGE_ASPECT_RATIO = {
  buccalCenter: '3:2',
  buccalLeft: '3:2',
  buccalRight: '3:2',
  facialFrontal: '1:1',
  facialFrontalSmiling: '1:1',
  facialLateral: '1:1',
  occlusalLower: '3:2',
  occlusalUpper: '3:2',
  xRayCephalometric: '299:331',
  xRayMolarLeft: '1:1',
  xRayMolarPreLeft: '1:1',
  xRayMolarPreRight: '1:2',
  xRayMolarRight: '1:1',
  xRayPanoramic: '299:132',
}

export let APPOINTMENT_TYPE_COLORS = {
  'color-A3': '#ff5c33', // Red
  'color-E3': '#9dd600', // Green
  'color-I3': '#00a4f0', // Blue
  'color-G3': '#00d69a', // Blue/Green
  'color-B2': '#ffa552', // Orange
  'color-B1': '#ffc28a', // Light Orange
  'color-B3': '#ff881a', // Dark Orange
  'color-M3': '#ff4caf', // Pink/Purple
  'color-A0': '#ffefeb', // Light Pink
  'color-L3': '#c44cff', // Dark Purple
  'color-K2': '#c3adff', // Light Purple
  'color-A5': '#ffb6a3', // Salmon
  'color-brown': '#964b00', // Brown
  'color-light-brown': '#c4a484', // Light Brown
  'color-F3': '#00d600', // Dark Green
  'color-E0': '#d2ff58', // Light Green
  'color-J3': '#668fff', // Dark Blue
  'color-light-grey': '#d3d3d3', // Light Grey
  'color-dark-grey': '#606060', // Dark Grey
  'color-D2': '#fbff02', // Yellow
  'color-J0': '#FFFFFF', // White
  'color-black': '#000000', // Black,
  'color-C3': '#dea708', // Dark Yellow
  'color-I1': '#45bbff', // Light Blue
  'color-K3': '#7b48fe', // Purple
}

export let TREATMENT_COLORS = {
  RED: 'color-A3',
  GREEN: 'color-E3',
  BLUE: 'color-I3',
  BLUE_GREEN: 'color-G3',
  ORANGE: 'color-B2',
  LIGHT_ORANGE: 'color-B1',
  DARK_ORANGE: 'color-B3',
  PINK_PURPLE: 'color-M3',
  LIGHT_PINK: 'color-A0',
  DARK_PURPLE: 'color-L3',
  LIGHT_PURPLE: 'color-K2',
  SALMON: 'color-A5',
  BROWN: 'color-brown',
  LIGHT_BROWN: 'color-light-brown',
  DARK_GREEN: 'color-F3',
  LIGHT_GREEN: 'color-E0',
  DARK_BLUE: 'color-J3',
  LIGHT_BLUE: 'color-I1',
  LIGHT_GREY: 'color-light-grey',
  DARK_GREY: 'color-dark-grey',
  YELLOW: 'color-D2',
  WHITE: 'color-J0',
  BLACK: 'color-black',
}

export let TREATMENT_FLOW_STEPS = [
  'plan',
  'sign',
  'down_payment',
  'auto_pay',
  'schedule',
]
export let TREATMENT_AUTODRAFT_METHODS = ['bank_account', 'credit_card']
export let TREATMENT_DOWNPAYMENT_METHODS = [
  'in_office',
  'bank_account',
  'credit_card',
]
export let TREATMENT_FLOW_ACCESS_FLAG = 'treatment.flow.enabled'
export let TREATMENT_FLOW_PAYMENT_METHOD_FLAG = 'treatment.flow.payment_method'
export let TREATMENT_PAYMENT_PLAN_VALUE_TYPES = ['amount', 'percentage']
export let TREATMENT_PAYMENT_PLAN_LENGTH_TYPES = ['total', 'relative']
export let TREATMENT_PAYMENT_PLAN_FREQUENCY_TYPES = [
  'None',
  'Monthly',
  'TwicePerMonth',
  'Weekly',
  'EverySecondWeek',
]

export let MEDICAL_HISTORY_CATEGORY_NAME = {
  all: 'All',
  general: 'General',
  medical_conditions: 'Medical conditions',
  habits: 'Habits',
  medications: 'Medications',
  drugs_allergies_and_sensitivities: 'Drug allergies & sensitivities',
}

// Medical History fixed questions

export let MANUAL_EVENT_TYPES = {
  appointment_marked_unseated: 'appointment_marked_unseated',
  appointment_marked_checked_out: 'appointment_marked_checked_out',
  treatment_status_changed: 'treatment_status_changed',
}

export let RELATIONSHIPS = [
  {
    id: 'MOTHER',
    text: 'Mother',
    FEMALE: 'CHILD',
    MALE: 'CHILD',
    UNSPECIFIED: 'CHILD',
  },
  {
    id: 'FATHER',
    text: 'Father',
    FEMALE: 'CHILD',
    MALE: 'CHILD',
    UNSPECIFIED: 'CHILD',
  },
  {
    id: 'BROTHER',
    text: 'Brother',
    FEMALE: 'SISTER',
    MALE: 'BROTHER',
    UNSPECIFIED: 'SIBLING',
  },
  {
    id: 'SISTER',
    text: 'Sister',
    FEMALE: 'SISTER',
    MALE: 'BROTHER',
    UNSPECIFIED: 'SIBLING',
  },
  {
    id: 'SIBLING',
    text: 'Sibling',
    FEMALE: 'SIBLING',
    MALE: 'SIBLING',
    UNSPECIFIED: 'SIBLING',
  },
  {
    id: 'GRANDMOTHER',
    text: 'Grandmother',
    FEMALE: 'GRANDDAUGHTER',
    MALE: 'GRANDSON',
    UNSPECIFIED: 'GRANDCHILD',
  },
  {
    id: 'GRANDFATHER',
    text: 'Grandfather',
    FEMALE: 'GRANDDAUGHTER',
    MALE: 'GRANDSON',
    UNSPECIFIED: 'GRANDCHILD',
  },
  {
    id: 'PARTNER',
    text: 'Partner',
    FEMALE: 'PARTNER',
    MALE: 'PARTNER',
    UNSPECIFIED: 'PARTNER',
  },
  {
    id: 'SPOUSE',
    text: 'Spouse',
    FEMALE: 'SPOUSE',
    MALE: 'SPOUSE',
    UNSPECIFIED: 'SPOUSE',
  },
  {
    id: 'STEPMOTHER',
    text: 'Stepmother',
    FEMALE: 'STEPCHILD',
    MALE: 'STEPCHILD',
    UNSPECIFIED: 'STEPCHILD',
  },
  {
    id: 'STEPFATHER',
    text: 'Stepfather',
    FEMALE: 'STEPCHILD',
    MALE: 'STEPCHILD',
    UNSPECIFIED: 'STEPCHILD',
  },
  {
    id: 'GUARDIAN',
    text: 'Guardian',
    FEMALE: 'WARD',
    MALE: 'WARD',
    UNSPECIFIED: 'WARD',
  },
  {
    id: 'WARD',
    text: 'Ward',
    FEMALE: 'GUARDIAN',
    MALE: 'GUARDIAN',
    UNSPECIFIED: 'GUARDIAN',
  },
  {
    id: 'AUNT',
    text: 'Aunt',
    FEMALE: 'NIECE',
    MALE: 'NEPHEW',
    UNSPECIFIED: 'NEPHEW_OR_NIECE',
  },
  {
    id: 'UNCLE',
    text: 'Uncle',
    FEMALE: 'NIECE',
    MALE: 'NEPHEW',
    UNSPECIFIED: 'NEPHEW_OR_NIECE',
  },
  {
    id: 'CHILD',
    text: 'Child',
    FEMALE: 'MOTHER',
    MALE: 'FATHER',
    UNSPECIFIED: 'PARENT',
  },
  {
    id: 'NEPHEW',
    text: 'Nephew',
    FEMALE: 'AUNT',
    MALE: 'UNCLE',
    UNSPECIFIED: 'UNCLE_OR_AUNT',
  },
  {
    id: 'NIECE',
    text: 'Niece',
    FEMALE: 'AUNT',
    MALE: 'UNCLE',
    UNSPECIFIED: 'UNCLE_OR_AUNT',
  },
  {
    id: 'GRANDSON',
    text: 'Grandson',
    FEMALE: 'GRANDMOTHER',
    MALE: 'GRANDFATHER',
    UNSPECIFIED: 'GRANDPARENT',
  },
  {
    id: 'GRANDDAUGHTER',
    text: 'Granddaughter',
    FEMALE: 'GRANDMOTHER',
    MALE: 'GRANDFATHER',
    UNSPECIFIED: 'GRANDPARENT',
  },
  {
    id: 'STEPCHILD',
    text: 'Stepchild',
    FEMALE: 'STEPMOTHER',
    MALE: 'STEPFATHER',
    UNSPECIFIED: 'STEPPARENT',
  },
  {
    id: 'OTHER',
    text: 'Other',
    FEMALE: 'OTHER',
    MALE: 'OTHER',
    UNSPECIFIED: 'OTHER',
  },
]

export let TEMPORARY_IMAGE_PREFIX = 'tmpimg'
export let PATIENT_IMAGE_PREFIX = 'patientimg'
export let PATIENT_ORIGINAL_IMAGE_PREFIX = 'orig_patientimg'

export let DAYS = [
  { id: 'monday', text: 'Monday' },
  { id: 'tuesday', text: 'Tuesday' },
  { id: 'wednesday', text: 'Wednesday' },
  { id: 'thursday', text: 'Thursday' },
  { id: 'friday', text: 'Friday' },
  { id: 'saturday', text: 'Saturday' },
  { id: 'sunday', text: 'Sunday' },
]

// copied from functions/helpers/payments.js
export let PAYMENTS_PAYMENT_TYPES = /** @type {const} */ ({
  AutoPayment: 'AutoPayment',
  BankAccountPayment: 'BankAccountPayment',
  CashPayment: 'CashPayment',
  CheckPayment: 'CheckPayment',
  CreditCardPayment: 'CreditCardPayment',
  /**
   * @description this one is just used to control execution flow, it will be inserted as CreditCardPayment
   */
  CreditCardManualPayment: 'CreditCardManualPayment',
  InsurancePayment: 'InsurancePayment',
  OtherPayment: 'OtherPayment',
  TransferPayment: 'TransferPayment',
  // Payabli/UI only
  PaymentPagePayment: 'PaymentPagePayment',
})

// copied from functions/helpers/payments.js
export let PAYMENT_PLAN_TYPES = /** @type {const} */ ({
  Person: 'Person',
  Insurance: 'Insurance',
})

// based on sources/core-embed/core-embed-application/src/main/java/com/axpm/modules/admin/businessportal/ui/paymenttypes/PaymentTypeType.java
export let PAYMENT_TYPE_INT_TO_PAYMENTS_PAYMENT_TYPE = /** @type {const} */ {
  1: PAYMENTS_PAYMENT_TYPES.CashPayment,
  2: PAYMENTS_PAYMENT_TYPES.CheckPayment,
  3: PAYMENTS_PAYMENT_TYPES.CreditCardPayment,
  4: PAYMENTS_PAYMENT_TYPES.CreditCardManualPayment,
  5: PAYMENTS_PAYMENT_TYPES.OtherPayment,
  6: PAYMENTS_PAYMENT_TYPES.BankAccountPayment,
}

// copied from functions/helpers/payments.js
export let PAYMENT_PLAN_AUTOPAY_SOURCES = /** @type {const} */ ({
  BankAccount: 'BankAccount',
  CreditCard: 'CreditCard',
})

export let NOTE_TEMPLATE_EDITOR_VARIANTS = /** @type {const} */ ({
  richtext: 'richtext',
  plaintext: 'plaintext',
})

/**
 * @type {Record<string, { name: string; editorVariant: typeof NOTE_TEMPLATE_EDITOR_VARIANTS[keyof typeof NOTE_TEMPLATE_EDITOR_VARIANTS] }>}
 */
export let NOTE_TEMPLATE_TAGS = {
  appointment: {
    name: 'Appointment card notes',
    editorVariant: 'richtext',
  },
  comms: {
    name: 'Comms',
    editorVariant: 'plaintext',
  },
}

/** @readonly */
export let MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month, index) => ({ id: index, text: month }))

export let DEFAULT_TIMEZONE = 'America/Chicago'

export let GENDERS = [
  {
    id: 'MALE',
    text: 'Male',
  },
  {
    id: 'FEMALE',
    text: 'Female',
  },
  {
    id: 'UNSPECIFIED',
    text: 'Unspecified',
  },
]

export let SUPPORTED_PAYMENT_METHODS = /** @type {const} */ ({
  wisetack: {
    id: 'wisetack',
    payment_plan: {
      name: 'Pay over time with Wisetack',
      description: 'Pay in installments',
      downpayment: 100,
      downpayment_type: 'percentage',
      length: 0,
      length_type: 'total',
      frequency: 'None',
      order: 0,
      discounts: {
        data: [],
      },
    },
  },
  hfd: {
    id: 'hfd',
    payment_plan: {
      name: 'Apply to pay over time with HFD Financing',
      description:
        'Available offers are subject to approval and credit qualification',
      downpayment: 100,
      downpayment_type: 'percentage',
      length: 0,
      length_type: 'total',
      frequency: 'None',
      order: 0,
      discounts: {
        data: [],
      },
    },
  },
})

export let BOOKING_STATES = /** @type {const} */ ({
  NO_SHOW: 'NO_SHOW',
  PATIENT_CANCELLED: 'PATIENT_CANCELLED',
  OFFICE_CANCELLED: 'OFFICE_CANCELLED',
})

export let UNSCHEDULED_BOOKING_STATES = /** @type {const} */ [
  BOOKING_STATES.NO_SHOW,
  BOOKING_STATES.OFFICE_CANCELLED,
  BOOKING_STATES.PATIENT_CANCELLED,
]
