import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let thread_id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    let mutationResponse = await executeMutation({
      thread_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Something went wrong. Unable to open virtual meeting.')
      )
    } else {
      notify(notifySuccess(`Launching virtual meeting in new window`))
      window.open(
        mutationResponse.data.virtual_room_insert_for_comms_thread.url,
        '_blank'
      )
    }
  }
}
