import { useMemo } from 'react'

let DEFAULT_SETTINGS = {
  slot_interval: 5,
  hipaa: false,
  selected_appointment_templates: [],
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let settings = data?.[0]

    if (!settings) {
      return DEFAULT_SETTINGS
    }

    return settings
  }, [data])
}
