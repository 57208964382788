import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'
import launcherComponentMutation from './apps-launcher-component-mutation.graphql.js'

export default function useListItemDataOnSubmit(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    path: 'patient_id',
    context: 'tab',
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)
  let [, executeLauncherComponentMutation] = useMutation(
    launcherComponentMutation
  )
  let [, notify] = useNotifications()

  let changeComponentSelected = useDataChange({
    viewPath: props.viewPath,
    context: 'app',
    path: 'component.selected',
  })

  return async function onSubmit({ args, value, change }) {
    switch (value.resolver.type) {
      case 'component': {
        let mutationResponse = await executeLauncherComponentMutation({
          id: value.resolver.id,
          launcher_id: value.id,
          resource_id: patient_id,
          resource_type: 'patient',
        })

        if (mutationResponse.error) {
          notify(notifyError('Problem opening element'))
          return
        }

        changeComponentSelected(
          mutationResponse.data.apps_launcher_component_mount_details
            .configuration
        )

        setFlowTo(normalizePath(props.viewPath, '../../../AlignRx'))

        break
      }

      case 'url': {
        changeComponentSelected(null)
        window.open(value.resolver.data)
        break
      }

      case 'http': {
        changeComponentSelected(null)
        let mutationResponse = await executeMutation({
          id: value.id,
          resource_id: patient_id,
          location_id,
        })

        if (mutationResponse.error) {
          notify(notifyError(getError(value)))
          return
        }

        window.open(mutationResponse.data.apps_launcher_generate_url.url)

        break
      }

      default: {
        throw new Error(`Unknown resolver type "${value.type}"`)
      }
    }
  }
}

function getError(value) {
  switch (value.id) {
    case 'align-itero-patient': {
      return `No iTero scans exist for this patient yet.`
    }

    case 'align-ids-clincheck-patient': {
      return `There is no ClinCheck to be launched for this patient.`
    }

    default: {
      return `There was a problem launching the app. Please try again.`
    }
  }
}
