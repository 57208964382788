import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let custom_resource = data.apps_custom_resources_by_pk

    // find the data mapping configuration to be used for the custom resource
    let mapping = data.apps_ui_mappings.display_definitions.find(
      m =>
        m.app_id === custom_resource.connection.app_id &&
        m.resource_type === custom_resource.type
    ).mapping

    return {
      id: custom_resource.id,
      created_at: custom_resource.created_at,
      updated_at: custom_resource.updated_at,
      ...getValues(custom_resource.data, mapping),
    }
  }, [data])
}

function getValues(data, mapping) {
  let result = {}
  for (let key of Object.keys(mapping)) {
    if (typeof mapping[key] === 'object') {
      result[key] = getValues(data, mapping[key])
    } else if (key in mapping) {
      result[key] = getValue(data, mapping[key])
    }
  }
  return result
}

function getValue(data, placeholder) {
  if (placeholder.startsWith('{{')) {
    return data[placeholder.replace('{{', '').replace('}}', '')]
  } else {
    return placeholder
  }
}
