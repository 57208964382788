import React, { useEffect } from 'react'
import { useDataChange } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let setIsHovered = useDataChange({
    context: 'selected',
    path: 'is_hovered',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    setIsHovered(props.is_hovered)
  }, [props.is_hovered]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setIsHovered

  return <View {...props} />
}
