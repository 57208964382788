import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let chair_ids = useDataValue({
    context: 'global',
    path: 'user.preferences.chair_ids',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => (Array.isArray(chair_ids) ? chair_ids : data),
    [data, chair_ids]
  )
}
