// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/PrintPDF/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_PrintPDF_Content(
  $patient_id: uuid!
  $location_id: uuid!
  $include_notes: Boolean!
) {
  vaxiom_patient_treatment_cards_export(
    patient_id: $patient_id
    location_id: $location_id
    include_notes: $include_notes
  ) {
    url
  }
}

`