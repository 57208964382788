// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/Accounts/update_status_mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_Accounts(
  $id: uuid!
  $user_id: uuid!
  $status: payments_provider_statuses_enum!
) {
  update_payments_provider_location_accounts_by_pk(
    _set: {
      status: $status
      status_changed_at: "now()"
      status_changed_by_id: $user_id
    }
    pk_columns: { id: $id }
  ) {
    id
  }
}

`