import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      if (next.add_new) return

      next.add_new = true
      next.state = 'loading'
      next.token_id = null
      next.valid = true
      next.containerId = `${next.containerId}-${Date.now()}`
    })
  }
}
