import React, { useEffect, useState } from 'react'
import { useDataChange } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import View from './view.js'

export default function Logic(props) {
  let [remaining, setRemaining] = useState(30)

  let refreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    let interval = setInterval(
      () =>
        setRemaining(previous => {
          let value = Math.max(previous - 1, 0)
          if (value === 0) {
            onClick()
          }
          return value
        }),
      1000
    )
    return () => {
      clearInterval(interval)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View
      {...props}
      text={`The section will refresh automatically in ${remaining} seconds...`}
      onClick={onClick}
    />
  )

  function onClick() {
    refreshEmbeddedSection(Date.now())
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
