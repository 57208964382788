/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  return async function onChange(value) {
    if (
      props.imageId !== value.image_id &&
      typeof props.onSelectImage === 'function'
    ) {
      props.onSelectImage(value.image_id)
    }
  }
}
