import { createElement } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import { UIPlugin } from '@uppy/core'

function PreactProxyRef(props) {
  let ref = useRef()

  useEffect(() => {
    props.onMount(ref.current)
    return () => {
      props.onMount(null)
    }
  }, []) // eslint-disable-line

  return createElement('div', {
    ref,
    style: {
      width: '100%',
      height: '100%',
    },
  })
}

export default class UppyProxyPreactToReact extends UIPlugin {
  constructor(uppy, opts) {
    super(uppy, opts)
    this.id = this.opts.id
    this.title = this.opts.title
    this.type = 'acquirer'
    this.icon = () =>
      createElement(PreactProxyRef, {
        onMount: opts.renderIcon,
      })
    this.render = () =>
      createElement(PreactProxyRef, {
        onMount: opts.renderContent,
      })

    let defaultOptions = {}

    this.opts = { ...defaultOptions, ...opts }

    this.i18nInit()
  }

  install() {
    let { target } = this.opts
    if (target) {
      this.mount(target, this)
    }
  }

  uninstall() {
    this.unmount()
  }
}
