// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Unscheduled/More/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Actions_Unscheduled_More($patient_id: uuid!) {
  vaxiom_contact_methods(
    where: {
      association: {
        communication_preferences: {
          patient: { _id: { _eq: $patient_id } }
          type: { _eq: "APPOINTMENT_MESSAGES" }
        }
      }
      dtype: { _in: ["email", "phone"] }
    }
  ) {
    id
    dtype
    phone {
      id
      number
    }
    email {
      id
      address
    }
  }
}

`