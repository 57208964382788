import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tx_card_field_definition',
  })

  return function onClick() {
    submit({ type: 'reset' })
  }
}
