// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/CustomResourceRow/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_CustomResourceRow($id: uuid!) {
  apps_custom_resources_by_pk(id: $id) {
    id
    connection {
      id
      app_id
    }
    type
    data
    created_at
    updated_at
  }
  apps_ui_mappings(type: treatment_timeline) {
    id
    display_definitions
  }
}

`