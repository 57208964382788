// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/TreatmentPlan/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_TreatmentPlan($treatment_id: uuid!) {
  vaxiom_txs(where: { _id: { _eq: $treatment_id } }) {
    id
    _id
    is_contract_applied
    is_treatment_plan_applied
  }
}

`