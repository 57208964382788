// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/NoteTemplates/Template/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_NoteTemplates_Template_Location($organization_id: uuid!) {
  vaxiom_sys_organizations(where: { _id: { _eq: $organization_id } }) {
    id
    _id
    name
  }
}

`