import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let medical_histories_questions = useDataValue({
    viewPath: props.viewPath,
    context: 'medical_histories_questions',
  })
  let changeSelected = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.medical_histories_questions',
  })

  return function onChange(value) {
    changeSelected(
      medical_histories_questions.filter(item => value.includes(item.id))
    )
  }
}

export function getIds(value) {
  return value.map(item => item.id)
}
