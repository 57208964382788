import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.person) return data

    let dependants = (data.person.from_person_relationships || [])
      .filter(Boolean)
      .filter(relationship => relationship?.to_person_record?.patient)
      .map(relationship => relationship.to_person_record)
      .map(person => ({
        id: person.patient.id,
        person_id: person.id,
        name: `${person.first_name} ${person.last_name}`,
      }))

    // add the owner of the thread if they are a patient too
    let patient = {
      id: data.id,
      person_id: data.person.id,
      name: `${data.person.first_name} ${data.person.last_name}`,
    }
    return [patient, ...dependants]
  }, [data])
}
