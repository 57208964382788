import { useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'patients',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({ type: 'complete-sync' })
  }
}
