// this is an autogenerated file from DesignSystem/Data/DataVaxiom/DataVaxiomTxCardFieldDefinitions/DataVaxiomTxCardFieldDefinitionsCardCreate/mutation-insert.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_Data_DataVaxiom_DataVaxiomTxCardFieldDefinitions_DataVaxiomTxCardFieldDefinitionsCardCreate(
  $object: vaxiom_tx_card_field_definitions_insert_input!
) {
  insert_vaxiom_tx_card_field_definitions_one(object: $object) {
    id
    alert
    hide_for_future
    name
    short_name
    multi_value
    number
    tracked
    type_id
    mandatory
  }
}

`