// this is an autogenerated file from DesignSystem/TysiaCopyToLocationsDialog/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TysiaCopyToLocationsDialog($location_id: uuid!) {
  session_user_locations(
    where: { _id: { _neq: $location_id } }
    order_by: { name: asc }
  ) {
    id: _id
    name
  }
}

`