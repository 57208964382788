import { useDataSubmit } from 'Simple/Data.js'

export function useOnClickCancel(props) {
  let submit = useDataSubmit({
    context: 'answers',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'cancel' })
  }
}
