import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let email = useDataValue({
    context: 'email',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })

  return async function onClick() {
    props.closePopover()

    let mutationResponse = await executeMutation({
      email,
      location_id,
    })

    if (
      mutationResponse.error ||
      !mutationResponse.data.auth_patient_invite.ok
    ) {
      notify(
        notifyError(
          'Make sure that the patient is at least 18 years old or that they have a responsible person.'
        )
      )
      return
    }

    notify(notifySuccess('Hub Invite Sent!'))
  }
}
