// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CreateIteroScanRequest/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CreateIteroScanRequest_Content(
  $app_id: uuid!
  $connection_name: String!
  $image_series_id: numeric!
  $location_id: uuid!
  $account_id: numeric!
  $set_preferred: Boolean!
  $doctor_id: numeric!
) {
  apps_align_scan_request_create(
    app_id: $app_id
    connection_name: $connection_name
    image_series_id: $image_series_id
    location_id: $location_id
    account_id: $account_id
    set_preferred: $set_preferred
    doctor_id: $doctor_id
  ) {
    id
  }
}

`