import useDataOnActionProcessStatements from './useDataOnActionProcessStatements.js'
import useDataOnActionViewStatementPdf from './useDataOnActionViewStatementPdf.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionProcessStatements = useDataOnActionProcessStatements(props, data)
  let onActionViewStatementPdf = useDataOnActionViewStatementPdf(props, data)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'statements/print':
      case 'statements/download': {
        return onActionProcessStatements({ value, originalValue, args, change })
      }

      case 'statements/view': {
        return onActionViewStatementPdf({ value, originalValue, args, change })
      }

      default: {
        throw new Error(`Unkown action "${args.type}"`)
      }
    }
  }
}
