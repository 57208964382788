// this is an autogenerated file from App/Auth/SignOut/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignOut($refresh_token: String) {
  auth_logout: vaxiom_users_logout(refresh_token: $refresh_token) {
    ok
  }
}

`