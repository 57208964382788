// this is an autogenerated file from DesignSystem/EditAutomation/EventActions/Content/EventAction/AppointmentRequirements/Content/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_EventActions_Content_EventAction_AppointmentRequirements_Content_Content{
  apps_appointment_requirements {
    id
    requirements
  }
}

`