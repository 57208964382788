import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let term = useDataValue({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })
  let city = useDataValue({
    context: 'edit_professional',
    viewPath: props.viewPath,
    path: 'other.external_office.postal_address.city',
  })
  let search_term = useStableValue(`%${term}%`)

  return {
    variables: {
      search_term,
    },
    pause: search_term.length < 5 || term === city,
  }
}
