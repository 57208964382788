import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import queryEventByPK from './query-event-by-pk.graphql.js'
import insertEventMutation from './insert-event-mutation.graphql.js'
import updateEventMutation from './update-event-mutation.graphql.js'
import updateEventStatusMutation from './update-event-status-mutation.graphql.js'
import copyEventsMutation from './copy-events-mutation.graphql.js'
import { useClient, useMutation } from 'Data/Api.js'
import { equals } from 'Data/aggregate.js'
import { useDataChange } from 'Simple/Data.js'

let INITIAL_SELECTED_STATE = {
  event_id: null,
  event_name: null,
  event_type: null,
  event_status: null,
  automation_type: null,
  trigger_type: 'post',
  tx_status: [],
  appointment_status: null,
  appointment_type_ids: [],
  appointment_type_names: [],
  actions: [{ type: null, data: null }],
  source: null,
  is_integration: false,
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeInsertEventMutation] = useMutation(insertEventMutation)
  let [, executeUpdateEventMutation] = useMutation(updateEventMutation)
  let [, executeCopyEventsMutation] = useMutation(copyEventsMutation)
  let [, executeUpdateEventStatusMutation] = useMutation(
    updateEventStatusMutation
  )
  let client = useClient()

  let setFlowTo = useSetFlowTo(props.viewPath)

  let setEventId = useDataChange({
    context: 'flow_shortcuts',
    path: 'eventId',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  function resetData(change) {
    change(next => {
      next.selected = {
        ...next.selected,
        ...INITIAL_SELECTED_STATE,
      }
      next.original_value = INITIAL_SELECTED_STATE
    })
    setEventId(null)
  }

  async function openEditAutomationScreen(event_id, change) {
    let response = await client
      .query(queryEventByPK, { id: event_id })
      .toPromise()

    if (response.error || !response.data?.events_events_by_pk) {
      notify(notifyError('An error has occurred trying to get the automation'))
      return
    }

    let data = response.data.events_events_by_pk
    let original_value = {
      event_id,
      event_name: data.name,
      event_type: data.type,
      event_status: data.status,
      location_id: data.organization_id,
      automation_type: data.automation_type,
      trigger_type: data.trigger_type,
      tx_status: data.type === 'txs_status_update' ? data.data.value_to : null,
      appointment_status:
        data.type === 'appointment_bookings_state_update'
          ? data.data.value_to
          : null,
      appointment_type_ids:
        data.type === 'appointment_bookings_state_update'
          ? data.data.appointment_type_ids
          : null,
      actions: data.actions,
      tx_status_name:
        data.type === 'txs_status_update' ? data.data.status_name : null,
      appointment_type_names:
        data.type === 'appointment_bookings_state_update'
          ? data.data.appointment_type_names
          : null,
      custom_resource_type:
        data.type === 'custom_resource_insert'
          ? data.data.custom_resource_type
          : null,
      app_id: data.type === 'custom_resource_insert' ? data.data.app_id : null,
      source: data.source,
      is_integration: !!data.source && data.source !== 'user',
    }

    change(next => {
      next.selected = {
        ...next.selected,
        ...original_value,
      }
      next.original_value = original_value
    })

    setEventId(event_id)
    setFlowTo(normalizePath(props.viewPath, 'Automation'))
  }

  function openNewAutomationScreen(change) {
    resetData(change)
    setFlowTo(normalizePath(props.viewPath, 'Automation'))
  }

  async function saveNewAutomation(automation_data, change) {
    let { id, ...object } = processEventData(automation_data)
    let response = await executeInsertEventMutation({
      object,
    })

    if (response.error) {
      notify(notifyError('An error has occurred when creating automation'))
      return
    }
    resetData(change)
    notify(
      notifySuccess(
        'Automation added! Automation has been created for this location.'
      )
    )
    setFlowTo(normalizePath(props.viewPath, 'AutomationsList'))
  }

  async function updateAutomation(value, change) {
    let { actions: original_actions, ...original_event } = value.original_value
    let { actions: current_actions, ...current_event } = value.selected
    let eventsData = getEventDifferences(original_event, current_event)
    let actions = getActionsDifferences(
      original_actions,
      current_actions,
      current_event.event_id
    )

    let response = await executeUpdateEventMutation({
      ...eventsData,
      insert_actions: !!actions.action_inserts.length,
      action_inserts: actions.action_inserts,
      update_actions: !!actions.action_updates.length,
      action_updates: actions.action_updates,
      delete_actions: !!actions.action_deletes.length,
      action_deletes: actions.action_deletes,
    })

    if (response.error) {
      return notify(
        notifyError('An error has occurred when updating automation')
      )
    }

    notify(notifySuccess('Automation updated!'))
    resetData(change)
    setFlowTo(normalizePath(props.viewPath, 'AutomationsList'))
  }

  async function toggleAutomationStatus({
    event_id,
    status,
    event_type,
    data,
    organization_id,
    automation_type,
    automation_hub_enabled,
  }) {
    if (!automation_hub_enabled) return

    let response = await executeUpdateEventStatusMutation({
      event_id,
      update_other_statuses: status === 'active',
      status,
      event_type,
      data,
      organization_id,
      automation_type,
    })
    if (response.error) {
      notify(
        notifyError('An error has occurred changing the automation status')
      )
      return
    }
    notify(
      notifySuccess(
        `${
          status === 'active'
            ? 'Now Running! Automation has been set to active for this location.'
            : 'Stopped! Automation has been paused for this location.'
        }`
      )
    )
  }

  async function copyAutomations(value, change) {
    let { copy_to_locations, automations } = value.selected
    if (!automations.length || !copy_to_locations?.length) return

    let response = await executeCopyEventsMutation({
      event_ids: automations,
      target_location_ids: copy_to_locations,
    })

    if (response.error) {
      return notify(
        notifyError('An error has occurred when copying automations')
      )
    }

    let length = copy_to_locations.length
    notify(
      notifySuccess(
        `Automations copied! Automations were copied to ${length} ${
          length === 1 ? 'location' : 'locations'
        }.`
      )
    )

    change(next => {
      next.selected.copy_to_locations = []
      next.selected.automations = []
    })
  }

  return async function onSubmit({ value, originalValue, args, change }) {
    try {
      switch (args.type) {
        case 'edit': {
          await openEditAutomationScreen(args.event_id, change)
          break
        }
        case 'new': {
          openNewAutomationScreen(change)
          break
        }
        case 'save': {
          await saveNewAutomation(value.selected, change)
          break
        }
        case 'update': {
          await updateAutomation(value, change)
          break
        }
        case 'toggleStatus': {
          await toggleAutomationStatus({
            ...args,
            automation_hub_enabled: value.selected.automation_hub_enabled,
          })
          break
        }
        case 'copy': {
          await copyAutomations(value, change)
          break
        }
        case 'cancel':
        case 'back': {
          resetData(change)
          setEventId(null)
          setFlowTo(normalizePath(props.viewPath, 'AutomationsList'))
          break
        }
        default:
          break
      }
    } catch (error) {
      notify(notifyError('An error has occurred'))
    }
  }
}

function generateData(event) {
  switch (event.event_type) {
    case 'appointment_bookings_state_update':
      return {
        value_to: event.appointment_status,
        appointment_type_ids: event.appointment_type_ids,
        appointment_type_names: event.appointment_type_names,
      }
    case 'txs_status_update':
      return {
        value_to: event.tx_status,
        status_name: event.tx_status_name,
      }
    case 'custom_resource_insert':
      return {
        custom_resource_type: event.custom_resource_type,
        app_id: event.app_id,
      }
    default:
      return {}
  }
}

function generateActions(actions) {
  return Array.isArray(actions)
    ? { data: actions.map(({ id, type, data }) => ({ id, type, data })) }
    : {}
}

function getActionsDifferences(original_array, current_array, event_id) {
  let actions_data = current_array.reduce(
    (acc, current_element) => {
      if (!current_element.id) {
        // New Element
        acc.action_inserts.push({ ...current_element, event_id })
        acc.insert_actions = true
      } else {
        let original_element_index = acc.action_deletes.findIndex(
          element => element.id === current_element.id
        )

        if (original_element_index !== -1) {
          let original_element = acc.action_deletes[original_element_index]
          if (!equals(original_element, current_element)) {
            // Updated element
            let { id, action_executions, ...action } = current_element
            acc.action_updates.push({
              where: { id: { _eq: id } },
              _set: { ...action },
            })
            acc.update_actions = true

            // The element was updated, so don't need to be deleted
            acc.action_deletes.splice(original_element_index, 1)
          } else {
            // The element exists but it doesn't need to be deleted
            acc.action_deletes.splice(original_element_index, 1)
          }
        }
      }

      return acc
    },
    {
      insert_actions: false,
      update_actions: false,
      delete_actions: true,
      action_inserts: [],
      action_updates: [],
      action_deletes: [...original_array],
    }
  )

  actions_data.action_deletes = actions_data.action_deletes.map(({ id }) => id)

  actions_data.delete_actions = !!actions_data.action_deletes.length

  return actions_data
}

function processEventData(eventData) {
  return {
    id: eventData.event_id,
    name: eventData.event_name,
    organization_id: eventData.location_id,
    type: eventData.event_type,
    automation_type: eventData.automation_type,
    trigger_type: eventData.trigger_type,
    data: generateData(eventData),
    actions: generateActions(eventData.actions),
  }
}

function getEventDifferences(original_event, current_event) {
  let original_object = processEventData(original_event)
  let current_object = processEventData(current_event)
  return Object.keys(original_object)
    .filter(key => key !== 'organization_id')
    .reduce(
      (acc, key) => {
        let original_value = original_object[key]
        let current_value = current_object[key]

        if (!equals(original_value, current_value)) {
          acc.event[key] = current_value
          acc.update_event = true
        }

        return acc
      },
      {
        update_event: false,
        event_id: original_event.event_id,
        event: {},
      }
    )
}
