export function isBankAccountMetadataValid(value) {
  return value.holder_first_name && value.holder_last_name
}

export function isCreditCardMetadataValid(value) {
  return (
    value.holder_first_name &&
    value.holder_last_name &&
    value.zip_code &&
    value.zip_code.length === 5
  )
}
