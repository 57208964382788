export function getView(booking) {
  switch (booking.state) {
    case 'SCHEDULED':
      return 'CheckIn'
    case 'CHECKED_IN':
      return 'Seat'
    case 'SEATED':
      return 'Unseat'
    case 'UNSEATED':
      return 'Check out'
    default:
      return 'No'
  }
}
