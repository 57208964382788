// this is an autogenerated file from App/Account/Content/Financial/mutation-s3-send-commands.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Financial($list: [aws_s3_command_input!]!) {
  aws_s3_send_commands(list: $list) {
    type
    response
  }
}

`