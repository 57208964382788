import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let selected = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (
      selected.send_to_person_vaxiom_id !== null &&
      selected.send_to_person_id === null &&
      Array.isArray(value)
    ) {
      change(next => {
        let item = value.find(
          item => item.id === selected.send_to_person_vaxiom_id
        )
        if (item) {
          next.send_to_person_id = item._id
        } else {
          next.send_to_person_id = null
          next.send_to_person_vaxiom_id = null
        }
      })
    }
  }
}
