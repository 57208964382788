import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let changeSelected = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let action = useDataValue({
    context: 'action_execution',
    viewPath: props.viewPath,
  })

  return function onClick() {
    changeSelected(action)
  }
}

// Used in two places, maybe extract it to format.js
let specialCases = ['cbs']

export function getTabName(action_type) {
  return action_type === 'check_requirements'
    ? 'The following tasks are required before unseating the patient'
    : snakeCaseToHumanReadable(action_type)
}

export function snakeCaseToHumanReadable(str = '') {
  // Replace underscores with spaces
  str = str.replace(/_/g, ' ').toLowerCase()

  // Capitalize the first letter of the string
  str = str.replace(/^\w/, match => match.toUpperCase())

  // Convert special cases to upper case
  return str
    .split(' ')
    .map(item =>
      specialCases.includes(item.toLowerCase()) ? item.toUpperCase() : item
    )
    .join(' ')
}

export function addOne(index) {
  return index + 1
}

export function isCompletedOrSkipped(status) {
  return ['completed', 'skipped'].includes(status)
}
