import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      all: [
        ...data.all,
        // some procedures could come from a different location
        // adding them so that selected procedures are correctly displayed
        ...data.appointment
          .filter(
            item =>
              !data.all.some(procedure => procedure._id === item.procedure._id)
          )
          .map(item => item.procedure),
      ],
      appointment: data.appointment.map(item => item.procedure._id),
    }
  }, [data])
}
