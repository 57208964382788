export function getView(
  connection_resource_status,
  connection_status,
  is_admin
) {
  return (
    is_admin &&
    ['created', 'setup_automations', 'disabled'].includes(
      connection_resource_status
    ) &&
    connection_status === 'active'
  )
}
