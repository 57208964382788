// this is an autogenerated file from DesignSystem/NoteTextArea/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_NoteTextArea(
  $organization_id: uuid!
  $pattern: String!
  $tags: jsonb!
) {
  notes_templates(
    where: {
      organization_id: { _eq: $organization_id }
      name: { _ilike: $pattern }
      tags: { _contains: $tags }
    }
    order_by: { name: asc }
  ) {
    id
    value: url
    text: name
  }
}

`