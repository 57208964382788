// this is an autogenerated file from App/Account/Content/Insights/Reports/mutation-update-preset.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Insights_Reports($id: uuid!, $data: jsonb!) {
  update_reports_filter_presets_by_pk(
    pk_columns: { id: $id }
    _set: { data: $data }
  ) {
    id
    name
    text: name
    data
  }
}

`