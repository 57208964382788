// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Notes/Content/NewCheckinNoteAction/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Notes_Content_NewCheckinNoteAction($location_id: uuid!) {
  vaxiom_location_access_keys(
    where: { organization: { _id: { _eq: $location_id } } }
  ) {
    id
    access_name
  }
}

`