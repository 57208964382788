import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)

  let location_id = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.location_id',
  })

  return async function onSubmit({ args }) {
    await executeMutation({
      object: {
        location_id,
        [args.key]: args.value,
      },
      columns_to_update: [args.key],
    })
  }
}
