import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let contact_methods = useDataValue({
    viewPath: props.viewPath,
    context: 'contact_methods',
  })

  return useMemo(() => {
    let value = [
      { id: 'DELETE', text: 'Delete' },
      { id: 'APPOINTMENT_HISTORY', text: 'Appointment history' },
      { id: 'APPOINTMENT_ADD', text: 'Add appointment after ...' },
      { id: 'GENERATE_LETTERS', text: 'Generate letters' },
      {
        id: 'MOVE_TO_ANOTHER_TREATMENT',
        text: 'Move to another treatment',
      },
    ]

    if (
      contact_methods?.some(item => ['phone', 'email'].includes(item.dtype))
    ) {
      value.push({
        id: 'SCHEDULE_VIA_SMS_OR_EMAIL',
        text: 'Schedule via SMS / email',
      })
    }

    return value
  }, [contact_methods])
}
