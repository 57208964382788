import { useDataChange, useOnChangeLastValueGuard } from 'Simple/Data.js'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return useOnChangeLastValueGuard(function onChange(next) {
    change(inext => {
      console.log({ next })
      inext.default_payment_plans[props.index] = next
    })
  })
}
