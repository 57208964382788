import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  let setDiagnosis = useDataChange({
    context: 'event',
    path: 'appointment.diagnosis',
    viewPath: props.viewPath,
  })
  let globalParentCompanyId = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit() {
    if (!appointment.diagnosis) {
      let mutationResponse = await executeMutation({
        appointment_id: appointment._id,
        parent_company_id: globalParentCompanyId,
      })

      if (mutationResponse.error) {
        return notify(
          notifyError(
            `Cannot create the diagnosis. Please check if at least one diagnosis template is available for the appointment type or the parent company and try again.`
          )
        )
      }
      setDiagnosis(mutationResponse.data.vaxiom_diagnosis_create)
    }

    setFlowTo(normalizePath(props.viewPath, 'Diagnosis/Content'))
  }
}
