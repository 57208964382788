import React, { useEffect, useState } from 'react'
import { useDataValue } from 'Simple/Data.js'
import useIsHovered from 'Logic/useIsHovered.js'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import max from 'date-fns/max'

import View from './view.js'

export default function Logic(props) {
  let [isHoveredManual, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
  })
  let [hoveredName, , hoverNameBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
    onMouseEnterDelay: 500,
  })
  let check_in_time = useDataValue({
    context: 'appointment_booking',
    path: 'check_in_time',
    viewPath: props.viewPath,
  })
  let start_time = useDataValue({
    context: 'appointment_booking',
    path: 'start_time',
    viewPath: props.viewPath,
  })
  let [isOver10Min, setOver10Min] = useState(getState)

  useEffect(() => {
    let interval = setInterval(() => setOver10Min(getState()), 60000) // recalculate waiting time every minute

    return () => {
      clearInterval(interval)
    }
  }, [check_in_time, start_time]) // eslint-disable-line
  // ignore getState

  function getState() {
    // if patient is late then the reference is check in time instead of scheduled start time
    let reference = max([
      new Date(`${start_time}Z`),
      new Date(`${check_in_time}Z`),
    ])
    let diff = differenceInMinutes(reference, new Date())
    return diff <= -10
  }

  return (
    <View
      isHoveredManual={isHoveredManual}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      hoveredName={hoveredName}
      onMouseEnterName={hoverNameBind.onMouseEnter}
      onMouseLeaveName={hoverNameBind.onMouseLeave}
      isOver10Min={isOver10Min}
      {...props}
    />
  )
}
