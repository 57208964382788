import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let tx = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs',
  })

  return useMemo(
    () =>
      data.filter(
        option =>
          option !== 'COMPLETE_TREATMENT' ||
          tx.status === 'RETENTION' ||
          tx.is_active
      ),
    [data, tx]
  )
}
