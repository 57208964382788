import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.accounts?.length) return []

    return data.accounts.map(item => ({
      id: item.id,
      name: `${item.name} (${item.id})`,
      is_preferred: item.is_preferred,
    }))
  }, [data])
}
