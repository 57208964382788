import { useDataChange, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changeSelected = useDataChange({
    context: 'selected',
    path: 'practice_id',
    viewPath: props.viewPath,
  })

  let practice_id = useDataValue({
    context: 'selected',
    path: 'practice_id',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    // set a default selection to the first if there's only one
    // or the one they chose doesn't exist anymore
    if (value?.length === 1 && !practice_id) {
      changeSelected(value[0].id)
    }
  }
}
