import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import query from './query.graphql.js'

export function useOnAutocompleteAction(props) {
  let change = useDataChange({
    context: 'editor',
    path: 'note_template_url',
    viewPath: props.viewPath,
  })

  return function onAutocompleteAction(value) {
    change(value)
  }
}

export function useOnAutocompleteFetch(props) {
  let client = useClient()
  let organization_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onAutocompleteFetch(pattern) {
    let response = await client
      .query(query, { organization_id, pattern: `%${pattern}%` })
      .toPromise()

    return response?.data?.notes_templates || []
  }
}

export function getContainerId(value) {
  return `new-note-${value}`
}
