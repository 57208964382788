let TABS = {
  Calendar: {
    permission_check: 'has_office_calendar',
    isSupportOnly: false,
  },
  Comms: {
    permission_check: null,
    isSupportOnly: false,
  },
  Kpis: {
    permission_check: 'has_reports',
    isSupportOnly: false,
  },
  Reports: {
    permission_check: 'has_reports',
    isSupportOnly: false,
  },
  Financial: {
    permission_check: 'has_financial',
    isSupportOnly: false,
  },
  Notes: {
    permission_check: 'has_notes',
    isSupportOnly: false,
  },
  Workflows: {
    permission_check: 'has_admin',
    isSupportOnly: false,
  },
  WorkHours: {
    permission_check: 'has_work_hours',
    isSupportOnly: false,
  },
  Settings: {
    permission_check: 'has_admin',
    isSupportOnly: false,
  },
  Patient: {
    permission_check: null,
    isSupportOnly: false,
    sync: ['tab', 'coreParams'],
  },
  Patients: {
    permission_check: 'has_patients',
    isSupportOnly: false,
  },
  EmployeeWorkHours: {
    permission_check: 'has_employee_work_hours',
    isSupportOnly: false,
  },
}
export function filterAllowedTabsOnly(next) {
  next.tabs = next.tabs.filter(tab =>
    isAllowedToAccessTab({
      tab,
      current_location: next.current_location,
      feature_flags: next.feature_flags,
    })
  )
}

export function isAllowedToAccessTab({ tab, current_location, feature_flags }) {
  let item = TABS[tab.type]
  if (!item) return true

  return (
    (!item.permission_check ||
      current_location?.permissions[item.permission_check]) &&
    (!item.isSupportOnly || feature_flags?.is_support)
  )
}

// this is a bit convoluted, there's likely a better way to handle it
// let useEnsureCurrentTab handle setting the active tab
export function ensureDefaultTabId(next) {
  // try to default to the calendar
  next.next_tab_id = next.tabs.some(item => item.id === 'Calendar')
    ? 'Calendar'
    : // otherwise get the first tab
    next.tabs.length > 0
    ? next.tabs[0].id
    : null
}

export function ensureCommsAndCalendarTabs(next) {
  // ensure Comms
  if (!next.tabs.some(item => item.id === 'Comms')) {
    next.tabs.splice(0, 0, {
      id: 'Comms',
      name: 'Comms',
      type: 'Comms',
      viewPath: '/App/Account/Content/Comms',
    })
  }
  // ensure Calendar
  if (!next.tabs.some(item => item.id === 'Calendar')) {
    // the calendar goes second so that when a user closes all other tabs they naturally
    // land on it
    next.tabs.splice(1, 0, {
      id: 'Calendar',
      name: 'Calendar',
      type: 'Calendar',
      viewPath: '/App/Account/Content/Calendar',
    })
  }
}

export function getSyncParams(tab) {
  if (tab.sync) return tab.sync

  let item = TABS[tab.type]?.sync
  if (!item) return null

  return Object.fromEntries(item.map(key => [key, tab[key]]))
}
