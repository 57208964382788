import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let patients = JSON.parse(data)
    let matchedPatients = []
    let partialPatients = []
    let noMatch = []

    patients.forEach(p => {
      switch (p.external.type) {
        case 'noMatch':
          noMatch.push({ ...p, id: p.external.patient.id, selected: false })
          break
        case 'match':
          matchedPatients.push({
            ...p,
            id: p.external.patient.id,
            selected: false,
          })
          break
        case 'partial':
          partialPatients.push({
            ...p,
            id: p.external.patient.id,
            selected: false,
          })
          break
      }
    })

    return {
      patients: [
        { external: 'MATCH', greyfinch: '' },
        ...matchedPatients,
        { external: 'PARTIAL MATCH', greyfinch: '' },
        ...partialPatients,
        { external: 'NO MATCH', greyfinch: '' },
        ...noMatch,
      ],
    }
  }, [data])
}
