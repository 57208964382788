import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })

  let appointment_types = useDataValue({
    context: 'appointment_types',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.appointment_type_ids = value

      next.appointment_type_names = Array.isArray(appointment_types)
        ? appointment_types
            .filter(appointment_type => value.includes(appointment_type.id))
            .map(appointment_type => appointment_type.text)
        : []
    })
  }
}
