import { useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import delete_note from './deleteNoteMutation.graphql.js'
import delete_note_audit from './deleteNoteAuditMutation.graphql.js'

export function useOnDelete(props) {
  let appointment_note = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_note',
  })

  let event = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
  })
  let [, notify] = useNotifications()
  let [, execDeleteMutation] = useMutation(delete_note)

  let [, execDeleteAuditMutation] = useMutation(delete_note_audit)

  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onClick() {
    let mutationResponse = await execDeleteMutation({
      note_id: appointment_note.id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    let auditMutationResponse = await execDeleteAuditMutation({
      input: {
        class_type:
          'com.axpm.treatments.core.internal.model.AppointmentAuditLog',
        appointment_audit_log: {
          data: {
            action: 'NoteDeleted',
            appointment_id: event.appointment.id,
          },
        },
      },
    })
    if (auditMutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Note deleted!'))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
