import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'urql'
import mutation from './mutation.graphql.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value }) {
    let mutationResponse = await executeMutation({
      id: value.id,
      status: 'completed',
    })
    if (mutationResponse.error) {
      return notify(
        notifyError(
          `There was a problem updating the request. Please try again.`
        )
      )
    }

    notify(notifySuccess(`The request was updated successfully.`))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
