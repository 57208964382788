import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'charge_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let charge = next.charges[index]
      charge.amount = value

      if (charge.is_included_in_insurance_claim) {
        changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      }

      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
