// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/DefaultPaymentPlans/AddRuleset/DefaultPaymentPlans/Content/DefaultPaymentPlan/Discounts/Discount/Discounts/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_DefaultPaymentPlans_AddRuleset_DefaultPaymentPlans_Content_DefaultPaymentPlan_Discounts_Discount_Discounts($location_id: uuid) {
  vaxiom_discounts(
    where: {
      organization: { _id: { _eq: $location_id } }
      deleted: { _eq: false }
    }
  ) {
    _id
    name
  }
}

`