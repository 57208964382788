// this is an autogenerated file from App/Auth/ResetPasswordConfirm/mutation-mfa.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_ResetPasswordConfirm($ticket: String!, $token: String!, $skip_next: Boolean) {
  vaxiom_users_login_mfa(
    ticket: $ticket
    token: $token
    skip_next: $skip_next
  ) {
    status
    access_token: jwt_token
    refresh_token
    inactivity_timeout
    skip_mfa_token
  }
}

`