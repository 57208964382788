import { useDataChange, useDataValue } from 'Simple/Data.js'
import { age, dateShortIn, capitalize } from 'Data/format.js'

export function format(person, hipaa) {
  /** @type {(string | number)[]} */
  let result = [
    [person.first_name, !hipaa && person.last_name].filter(Boolean).join(' '),
  ]

  if (person.gender) result.push(person.gender[0])

  if (person.birth_date) {
    result.push(age(person.birth_date), dateShortIn(person.birth_date))
  }

  return result.filter(Boolean).join(', ')
}

export function useOnClick(props) {
  let change = useDataChange({
    context: 'global',
    path: 'patient_overlay_patient_id',
    viewPath: props.viewPath,
  })

  let patient_id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.patient._id',
  })

  return function onClick() {
    change(patient_id)
  }
}

/**
 *
 * @param {string} appointment_type_name
 * @returns
 */
export function formatAppointmentTypeName(appointment_type_name) {
  return appointment_type_name.split(' ').map(capitalize).join(' ')
}
