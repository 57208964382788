// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/Content/mutation_payment_receipt_send_email.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment_Content(
  $payment_id: numeric!
  $person_id: numeric!
  $email: String!
) {
  vaxiom_payment_receipt_send_email(
    payment_id: $payment_id
    person_id: $person_id
    email: $email
  ) {
    ok
  }
}

`