// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/Sync/Content/Action/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_Sync_Content_Action($connection_id: uuid!, $location_id: uuid!) {
  apps_sync(
    where: {
      connection_id: { _eq: $connection_id }
      location_id: { _eq: $location_id }
      status: { _in: ["processed", "processing"] }
    }
    order_by: { created_at: desc }
  ) {
    id
    status
  }
}

`