/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  return {
    variables: {
      type: props.method,
      person_id: props.person?._id,
      token_ids: [props.tokenId].filter(Boolean),
    },
    requestPolicy: 'cache-and-network',
  }
}
