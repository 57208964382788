import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let location = locations.find(item => item._id === value)
    change(next => {
      next.location_id = location?._id
      next.note_templates = []
    })
  }
}
