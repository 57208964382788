// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/ImportAction/Content/ImportImagesFromAlignAction/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_ImportAction_Content_ImportImagesFromAlignAction_Content($image_series_id: numeric!) {
  vaxiom_patient_imageseries_by_pk(id: $image_series_id) {
    id
    series_date
    patient_images(
      where: {
        type_key: { _eq: "ortho" }
        slot_key: {
          _in: [
            "facialFrontal"
            "facialFrontalSmiling"
            "facialLateral"
            "occlusalUpper"
            "occlusalLower"
            "buccalRight"
            "buccalCenter"
            "buccalLeft"
          ]
        }
      }
    ) {
      id
      _id
    }
  }
}

`