import addMonths from 'date-fns/addMonths/index.js'
import parseISO from 'date-fns/parseISO/index.js'
import format from 'date-fns/format'

export function getLabel(expires) {
  let expires_at_str = expires
    ? `This token will expire on ${format(
        addMonths(parseISO(new Date().toISOString().split('T')[0]), expires),
        'MMM d, yyyy'
      )}`
    : ''

  return expires_at_str
}
