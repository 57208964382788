// this is an autogenerated file from App/Account/Content/Financial/query-statements-folders-for-patients.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Financial($patient_ids: [numeric!]!) {
  vaxiom_document_tree_nodes(
    where: {
      patient_id: { _in: $patient_ids }
      name: { _eq: "Statements" }
      type: { _eq: "folder" }
    }
  ) {
    id
    patient_id
  }
}

`