// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/ConfigurationTable/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_ConfigurationTable($location_id: uuid!) {
  payments_provider_location_accounts(
    where: { location_id: { _eq: $location_id }, is_default: { _eq: true } }
    order_by: { created_at: desc }
  ) {
    id
    provider
    location {
      id
      feature_flags {
        is_pay_app_enabled
      }
    }
  }
}

`