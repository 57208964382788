// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/UploadVideo/Content/Modal/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_UploadVideo_Content_Modal($objects: [vaxiom_patient_images_insert_input!]!) {
  insert_vaxiom_patient_images(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}

`