import React, { useEffect, useRef } from 'react'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'patient_id',
  })
  let initial_patient_id = useRef(patient_id)

  useEffect(() => {
    if (initial_patient_id.current !== patient_id) {
      props.closePopover?.()
    }
  }, [patient_id])

  return <View {...props} />
}
