import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let app_id = useDataValue({
    context: 'connection_definition',
    path: 'app_id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'connection_definition',
    path: 'id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      connection_name,
      app_id,
    },
    pause: !connection_name || !app_id,
  }
}
