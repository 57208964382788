import { validate as isValidEmail } from 'email-validator'
import isFutureDate from 'date-fns/isFuture'
import isPastDate from 'date-fns/isPast'
import isValidDate from 'date-fns/isValid'
import parseISODate from 'date-fns/parseISO'
import phoneRegex from 'phone-regex'
import { textToNumber } from './format.js'

export function email(value) {
  return isValidEmail(value)
}
// https://stackoverflow.com/a/2385967/1562732
export function name(value) {
  return (
    value &&
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      value.trim()
    )
  )
}

export function nameOrEmpty(value) {
  if (!value) return true
  return name(value)
}

export function trueFalse(value) {
  return [true, false].includes(value)
}

export function birthday(value) {
  return date(value) && isPastDate(parseISODate(value))
}

export function date(value) {
  return isValidDate(parseISODate(value))
}

export function dateFuture(value) {
  return date(value) && isFutureDate(parseISODate(value))
}

export function phoneNumber(value) {
  return phoneRegex({ exact: true }).test(value)
}

export function textInput(value) {
  return text(value) && value.trim().length >= 2
}

export function text(value) {
  return typeof value === 'string'
}

export function number(value) {
  return typeof value === 'number'
}

export function street(value) {
  return textInput(value)
}

export function city(value) {
  return textInput(value)
}

export function state(value) {
  return value && value.length === 2
}

export function zip(value) {
  return /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$|^\d{5}(?:[-\s]\d{4})?$/i.test(
    value
  )
}

export function address({
  addressStreet,
  addressCity,
  addressState,
  addressZip,
}) {
  return (
    street(addressStreet) &&
    city(addressCity) &&
    state(addressState) &&
    zip(addressZip)
  )
}

export function events(value) {
  return value.length > 0
}

export function npi(value) {
  return /^\d{10}$/.test(value)
}

export function sixDigits(value) {
  return /^\d{6}$/.test(value)
}

export function signupCode(value) {
  return sixDigits(value)
}

export function website(value) {
  if (!value) return true
  return value.includes('.')
}

export function isValidUrl(value) {
  try {
    new URL(value)
  } catch (_) {
    return false
  }
  return true
}

export function password(value) {
  return (
    password_eight_chars_or_more(value) &&
    password_one_lowercase_letter(value) &&
    password_one_uppercase_letter(value) &&
    password_one_number(value) &&
    password_one_special_char(value)
  )
}

export function password_eight_chars_or_more(value) {
  return value.length >= 8
}
let LOWERCASE_LETTERS = /[a-z]/
export function password_one_lowercase_letter(value) {
  return LOWERCASE_LETTERS.test(value)
}
let UPPERCASE_LETTERS = /[A-Z]/
export function password_one_uppercase_letter(value) {
  return UPPERCASE_LETTERS.test(value)
}
let NUMBERS = /[0-9]/
export function password_one_number(value) {
  return NUMBERS.test(value)
}
let SPECIAL_CHARACTERS = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/
export function password_one_special_char(value) {
  return SPECIAL_CHARACTERS.test(value)
}

// we have a password in vaxiom/core demo that is 123123123, so...
export function vaxiom_password(value) {
  return textInput(value)
}

let UUID =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export function uuid(value) {
  return value && UUID.test(value)
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function social_security_number(value) {
  return value && SOCIAL_SECURITY_NUMBER.test(value)
}

export function numberZeroOrPositive(value) {
  return Number.isFinite(value) && value >= 0
}

export function numberPositive(value) {
  return Number.isFinite(value) && value > 0
}
export function maybe(value, fn) {
  return (typeof value !== 'number' && !value) || (fn && fn(value))
}

export function threshold(value) {
  return Number.isFinite(value) && value >= 2 && value <= 8
}

let TIME = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9]){0,1}$/
export function time(value) {
  return value && TIME.test(value)
}

export function featureFlags(value) {
  return value && value.every(e => trueFalse(e.value))
}

export function emptyList(value) {
  return Array.isArray(value) && value.length === 0
}

let LETTERS_BLANK_SPACES_REGEX = /[a-zA-Z\s]+$/
/*
 * In Align IDS the patient name (first name or last name) must be not null and the length must be lower than 25 chars
 * */
export function validatePatientNameAlignIDS(name) {
  return name && name.length < 25 && LETTERS_BLANK_SPACES_REGEX.test(name)
}

/*
 * In Align IDS the patient gender must be male or female, unspecified is not supported by Align
 * */
export function validatePatientGenderAlignIDS(gender) {
  return ['male', 'female'].includes(gender?.toLowerCase())
}

export function validateCurrency(value) {
  return textToNumber(value) > 0
}

export function textInputOrNumber(value) {
  return textInput(value) || number(value)
}
