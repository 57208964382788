import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.statements.location_ids',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(value.location_ids)
  }
}
