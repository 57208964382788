// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CreateIteroScanRequest/Content/Content/Step/Account/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CreateIteroScanRequest_Content_Content_Step_Account($connection_id: uuid!, $location_id: uuid!) {
  apps_align_itero_accounts(
    connection_id: $connection_id
    location_id: $location_id
  ) {
    accounts {
      id
      name
      is_preferred
    }
  }
}

`