import { useDataValue, useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let tab = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick(event) {
    event.stopPropagation()

    submit({
      type: 'tabs/remove',
      tab,
    })
  }
}
