/**
 * @description when multiple chairs have same start/end slots for date
 */
export function groupSlots(slots, slot_id) {
  return slots.reduce((result, slot) => {
    let groupId = `group_${slot.date}_${slot.start_min}_${slot.end_min}`
    let group = result.find(v => v.id === groupId)

    let newMember = {
      id: slot.id,
      chair_name: slot.chair_name,
      selected: slot.id === slot_id,
    }

    if (group) {
      group.members.push(newMember)
      if (newMember.selected) group.selected = true
      return result
    }

    let newGroup = {
      id: groupId,
      start_min: slot.start_min,
      end_min: slot.end_min,
      start_time_formatted: slot.start_time_formatted,
      end_time_formatted: slot.end_time_formatted,
      members: [newMember],
      selected: newMember.selected,
    }

    return [...result, newGroup]
  }, [])
}
