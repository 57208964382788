// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Notes/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Notes($patient_id: uuid!) {
  notes: vaxiom_notes(
    order_by: { sys_created: desc }
    where: {
      _and: [
        { patient: { _id: { _eq: $patient_id } } }
        {
          _or: [
            { target_type: { _eq: "Patient.CheckIn" } }
            {
              target_type: {
                _eq: "com.axpm.patients.core.internal.domain.Patient"
              }
            }
          ]
        }
      ]
    }
  ) {
    id
    note
    target_type
    date: sys_created
    creator {
      id
      person {
        id
        first_name
        last_name
      }
    }
    editor {
      id
      person {
        id
        first_name
        last_name
      }
    }
    alert
  }
}

`