import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import { TREATMENT_FLOW_ACCESS_FLAG } from 'Data/constants.js'
import mutation from './mutation.graphql.js'
import { toSentence } from 'Data/format.js'

export function useOnSubmit(props) {
  let changes = useDataValue({
    context: 'ruleset_changes',
    viewPath: props.viewPath,
  })

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit() {
    let validation = validate(changes.update_changes)
    if (validation.isInvalid) {
      notify(notifyError(`Invalid data: ${toSentence(validation.errors)}`))
      return false
    }

    let mutationResponse = await executeMutation({
      ruleset_ids_to_delete: changes.delete_changes,
      message_key: TREATMENT_FLOW_ACCESS_FLAG,
      rulesets_to_update: changes.update_changes.map(item => ({
        where: {
          organization_id: { _eq: item.organization_id },
          message_key: { _eq: item.message_key },
        },
        _set: {
          organization_id:
            item.location_id || item.division_id || item.parent_id,
          message_value: item.message_value.toString(),
        },
      })),
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong when trying to update the modified rulesets.'
        )
      )
      return false
    }

    notify(notifySuccess(`Rulesets updated successfully!`))
  }
}

function validate(rulesets) {
  let errors = []

  // duplicated rulesets
  let organizations = rulesets.map(
    item => item.location_id || item.division_id || item.parent_id
  )
  if (
    organizations.some((item, index) => organizations.indexOf(item) !== index)
  ) {
    errors.push('some rulesets are duplicated for the same organization')
  }

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}
