// this is an autogenerated file from App/Account/Search/Content/Profiles/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Search_Content_Profiles(
  $search_term: String!
  $parent_company_id: uuid!
  $whole_parent_company: Boolean!
) {
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: $whole_parent_company
  ) {
    person: vaxiom_person {
      id
      first_name
      last_name
      greeting
      gender
      birth_date
      profile_pic_url
      public_user {
        id
      }
      phones: contact_method_associations(
        where: {
          contact_method: {
            dtype: { _eq: "phone" }
            phone: { type: { _eq: "CELL" } }
          }
        }
      ) {
        id
        description
        contact_method {
          id
          phone {
            id
            number
          }
        }
      }
      patient {
        id
        _id
        human_readable_id
        legacy_id
      }
      from_person_relationships {
        id
        role
        to_person_record {
          id
          first_name
          last_name
          profile_pic_url
          gender
          birth_date
          patient {
            id
            _id
          }
        }
      }
      employment_contracts(
        where: {
          employment_end_date: { _is_null: true }
          deleted: { _eq: false }
        }
      ) {
        id
      }
      other_professional_relationships {
        id
        employee_type {
          id
          name
        }
        external_office {
          id
          name
          postal_address {
            id
            state
          }
        }
      }
    }
  }
}

`