// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Summary/Content/Content/Reporting/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Summary_Content_Content_Reporting($treatment_id: uuid!) {
  vaxiom_txs(where: { _id: { _eq: $treatment_id } }) {
    id
    appointments_no_shows {
      id
    }
    cancelled_appointments {
      id
    }
    broken_brackets {
      id
      value
    }
    oral_hygiene {
      id
      value
    }
  }
}

`