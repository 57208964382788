// this is an autogenerated file from DesignSystem/CommsThread/Content/EventsTimeline/Content/Event/Types/Status/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_EventsTimeline_Content_Event_Types_Status_Content($id: uuid!) {
  status: comms_events_statuses(
    where: { id: { _eq: $id }, name: { _neq: new } }
  ) {
    id
    name
    user {
      id
      first_name
      last_name
    }
    created_at
  }
}

`