import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

let location = '-'

export default function Logic(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let setSelectedLocations = useDataChange({
    context: 'tab',
    path: 'selected.locations',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!locations.length) return
    location = locations[0].text
    setSelectedLocations(locations.map(({ _id }) => _id))
  }, [locations]) // eslint-disable-line

  return <View viewPath={props.viewPath} location={location} />
}
