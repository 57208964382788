import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataSubmit } from 'Simple/Data.js'

export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let submitOnboarding = useDataSubmit({
    context: 'onboarding',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    if (!window.confirm(`Are you sure?`)) return

    try {
      let objects = value.location_ids.flatMap(organization_id =>
        value.procedures.map(item => ({
          ...item,
          organization_id,
        }))
      )

      let mutationResponse = await executeMutation({ objects })

      console.log({ type: 'onboarding/procedures', mutationResponse })

      if (mutationResponse.error) {
        notify(
          notifyError(
            `Procedures setup failed: ${mutationResponse.error.message}`
          )
        )
      } else {
        await submitOnboarding({
          type: 'PROCEDURES',
          data: {
            source: {
              procedures: objects,
            },
            created: {
              procedures:
                mutationResponse.data.insert_vaxiom_procedures.returning.map(
                  item => item.id
                ),
            },
          },
        })
        notify(notifySuccess(`Procedures setup done`))
      }
    } catch (error) {
      notify(notifyError(`Procedures setup failed: ${error.message}`))
    }
  }
}
