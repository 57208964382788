import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let number = useDataValue({
    viewPath: props.viewPath,
    context: 'new_phone',
    path: 'phone.number',
  })
  return {
    variables: {
      number,
    },
  }
}
