export let TYPE = {
  0: 'MedicalAlertIcon',
  1: 'MedicalAlertIcon',
  2: 'MedicalAlertIcon',
  3: 'MedicalAlertIcon',
  4: 'MedicalAlertIcon',
  5: 'NotesIcon',
}
export function getView(alertType) {
  return TYPE[alertType] || 'No'
}
