import { notifyError, useNotifications } from 'Logic/Notifications.js'
import format from 'date-fns/format'
import useQueryLocations from './useQueryLocations.js'
import createStatementPdf from './create-statement-pdf.js'
import getPdfMake from 'Logic/pdfmake.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnActionViewStatementPdf(props, data) {
  let [, notify] = useNotifications()
  let queryLocations = useQueryLocations(props)

  return async function onActionViewStatementPdf({
    value,
    originalValue,
    args,
    change,
  }) {
    try {
      let [location] = await queryLocations([args.statement.location._id])

      let pdf = createStatementPdf({
        statement: args.statement,
        location,
        pdfMake: await getPdfMake(),
        credit_cards: value.selected.statements.credit_cards,
      })

      pdf.download(
        `statement-${args.statement.patient.human_readable_id}-${format(
          new Date(),
          'MM_dd_yyyy-h_mmaaa'
        )}`
      )
    } catch (error) {
      notify(notifyError('Failed to generate PDF for preview'))
    }
  }
}
