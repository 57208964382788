import { useEffect, useState } from 'react'
import codes from './inputPhoneCodes.json'

export let DEFAULT_PHONE = '+1'

export default function useInputPhone(props) {
  let [valueCode, onChangeValueCode] = useState(() => {
    let maybeCode = codes.find(item => props.value?.startsWith(item.id))
    return maybeCode ? maybeCode.id : DEFAULT_PHONE
  })
  let [valueNumber, onChangeValueNumber] = useState(
    () => props.value?.replace(valueCode, '') || ''
  )

  useEffect(() => {
    let valueNumberOnlyDigits = valueNumber.replace(/\D/g, '')
    if (props.value === null && valueNumberOnlyDigits === '') return

    if (props.onChange) {
      props.onChange(
        valueNumberOnlyDigits === ''
          ? null
          : `${valueCode}${valueNumberOnlyDigits}`
      )
    }
  }, [valueCode, valueNumber]) // eslint-disable-line
  // ignore props.onChange

  return {
    codes,
    onChangeValueCode,
    onChangeValueNumber,
    valueCode,
    valueNumber,
  }
}
