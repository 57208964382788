import { startOfDay } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'

export function shouldShow(state, date, time_zone_id) {
  let isToday =
    typeof date !== 'undefined' &&
    format(
      startOfDay(utcToZonedTime(new Date(), time_zone_id)),
      'yyyy-MM-dd'
    ) === date.split('T')[0]
  return state === 'SCHEDULED' && isToday
}
