import format from 'date-fns/format'
import { getIsoTimeStringDisplay } from 'Data/format.js'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  return (
    <View
      {...props}
      value={
        props.timezone
          ? format(
              utcToZonedTime(props.item?.start || props.item, props.timezone),
              'h:mm aaa'
            )
          : getIsoTimeStringDisplay(props.item?.start || props.item)
      }
      onClick={() => props.onClick(props.item)}
    />
  )
}
