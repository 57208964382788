import React, { useEffect } from 'react'
import { useDataChange } from 'Simple/Data.js'
import { isCoreEmbedMessage } from 'Data/isCoreEmbedMessage.js'

import View from './view.js'

export default function Logic(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'coreParams',
    viewPath: props.viewPath,
  })
  let refresh = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    window.addEventListener('message', listener, false)

    return () => {
      window.removeEventListener('message', listener)
    }

    async function listener(event) {
      // listen for navigation events from core within the same tab only
      // navigation to other sections of the app are handled globally in ../EmbeddableCoreMessageListener/logic.js
      if (
        !isCoreEmbedMessage(event) ||
        event.data.type !== 'navigation' ||
        event.data.section !== 'chairs'
      ) {
        return
      }

      change(event.data.params)
      refresh(Date.now())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore change, refresh

  return <View {...props} />
}
