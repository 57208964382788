import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let connection_resource = useDataValue({
    context: 'connection_resource',
    viewPath: props.viewPath,
  })

  return useMemo(() => connection_resource?.connection, [connection_resource])
}
