import React from 'react'

import { useIsDisabled } from './helpers.js'
import View from './view.js'

export default function Logic(props) {
  let disabled = useIsDisabled(props)

  return <View viewPath={props.viewPath} disabled={disabled} />
}
