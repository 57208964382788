import { useDataValue } from 'Simple/Data'
import { PutObjectCommand } from '@aws-sdk/client-s3'
import {
  useAwsCredentials,
  useAwsS3Client,
  getS3ObjectUrl,
  getAmazonS3URI,
} from 'Data/s3'
import { v4 as uuid } from 'uuid'
import { useSetFlowTo, normalizePath } from 'Simple/Flow'
import { useClient, useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'

import insertMutation from './insert-mutation.graphql.js'
import updateMutation from './update-mutation.graphql.js'
import deleteMutation from './delete-mutation.graphql.js'
import copyMutation from './copy-mutation.graphql.js'
import noteTemplateQuery from './query.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionInsert = useDataOnActionInsert(props)
  let onActionEdit = useDataOnActionEdit(props)
  let onActionUpdate = useDataOnActionUpdate(props)
  let onActionDuplicate = useDataOnActionDuplicate(props)
  let onActionConfirmDelete = useDataOnActionConfirmDelete(props)
  let onActionDelete = useDataOnActionDelete(props)
  let onActionCopy = useDataOnActionCopy(props)
  let onActionCancel = useDataOnActionCancel(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'insert': {
        onActionInsert({ value, originalValue, change })
        break
      }
      case 'edit': {
        onActionEdit({ id: args.id, change })
        break
      }
      case 'update': {
        onActionUpdate({ value, originalValue, change })
        break
      }
      case 'duplicate': {
        onActionDuplicate({ id: args.id, change })
        break
      }
      case 'confirmDelete': {
        onActionConfirmDelete({ id: args.id, name: args.name, change })
        break
      }
      case 'delete': {
        onActionDelete({ id: args.id, originalValue, change })
        break
      }
      case 'copy': {
        onActionCopy({ value, id: args.id, locations: args.locations })
        break
      }
      case 'cancel': {
        onActionCancel({ originalValue, change })
        break
      }
      default:
        break
    }
  }
}

function useDataOnActionInsert(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(insertMutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let getCredentials = useAwsCredentials(props)
  let s3 = useAwsS3Client(props)

  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, originalValue, change }) {
    let file_key = uuid()
    let credentials = await getCredentials()
    let destination_key = getNoteTemplatePath({ parent_company_id, file_key })

    try {
      await (
        await s3()
      ).send(
        new PutObjectCommand({
          Bucket: credentials.storage_bucket_name,
          Key: destination_key,
          Body: value.note_template.content,
          ContentType: 'text/html',
        })
      )
    } catch (error) {
      return notify(
        notifyError(`An error has occurred while uploading new note template`)
      )
    }

    let mutationResponse = await executeMutation({
      object: {
        name: value.note_template.name,
        url: getS3ObjectUrl({
          bucket: credentials.storage_bucket_name,
          key: destination_key,
          region: credentials.region,
        }),
        tags: value.note_template.tags,
        organization_id: value.location_id,
      },
    })
    if (mutationResponse.error) {
      notify(
        notifyError(`An error has occurred while inserting new note template.`)
      )
      return
    }

    notify(
      // Title "Added!"
      notifySuccess(`New template has been added.`)
    )
    change(next => {
      next.note_template = originalValue.note_template
    })
    setFlowTo(normalizePath(props.viewPath, 'Templates'))
  }
}

function useDataOnActionEdit(props) {
  let client = useClient()
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ id, change }) {
    let response = await client.query(noteTemplateQuery, { id }).toPromise()
    if (response.error || !response.data?.notes_templates_by_pk) {
      notify(notifyError('An error has occurred while loading note template'))
      return
    }
    let note_template = response.data.notes_templates_by_pk

    change(next => {
      next.note_template = {
        ...note_template,
        editor_enabled: false,
        original: {
          name: note_template.name,
          tags: note_template.tags,
          content: '', // Loaded when Template view is displayed
        },
      }
    })
    setFlowTo(normalizePath(props.viewPath, 'Template'))
  }
}

function useDataOnActionUpdate(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(updateMutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let s3 = useAwsS3Client(props)

  return async function onAction({ value, originalValue, change }) {
    let { note_template } = value

    // Content changes
    if (note_template.content !== note_template.original.content) {
      try {
        let { bucket, key } = getAmazonS3URI(note_template.url)
        await (
          await s3()
        ).send(
          new PutObjectCommand({
            Bucket: bucket,
            Key: key,
            Body: note_template.content,
            ContentType: 'text/html',
          })
        )
      } catch (error) {
        return notify(
          notifyError(
            `An error has occurred while uploading updated note template`
          )
        )
      }
    }

    // Note template instance changes
    if (hasInstanceChanges(note_template)) {
      let mutationResponse = await executeMutation({
        id: note_template.id,
        object: {
          name: note_template.name,
          tags: note_template.tags,
        },
      })
      if (mutationResponse.error) {
        notify(
          notifyError(`An error has occurred while updating note template.`)
        )
        return
      }
    }

    notify(
      // Title "Saved!"
      notifySuccess(`Template changes have been saved.`)
    )
    change(next => {
      next.note_template = originalValue.note_template
    })
    setFlowTo(normalizePath(props.viewPath, 'Templates'))
  }

  function hasInstanceChanges(value) {
    return (
      value.name !== value.original.name ||
      value.tags.some(item => !value.original.tags.includes(item))
    )
  }
}

function useDataOnActionDuplicate(props) {
  let client = useClient()
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ id, change }) {
    let response = await client.query(noteTemplateQuery, { id }).toPromise()
    if (response.error || !response.data?.notes_templates_by_pk) {
      notify(notifyError('An error has occurred while loading note template'))
      return
    }
    let note_template = response.data.notes_templates_by_pk

    change(next => {
      next.note_template = {
        ...note_template,
        id: null,
        editor_enabled: false,
      }
    })
    setFlowTo(normalizePath(props.viewPath, 'Template'))
  }
}

function useDataOnActionConfirmDelete(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ id, name, change }) {
    change(next => {
      next.note_template.id = id
      next.note_template.name = name
    })
    setFlowTo(
      normalizePath(props.viewPath, 'Templates/DeleteConfirmation/Content')
    )
  }
}

function useDataOnActionDelete(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(deleteMutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ id, originalValue, change }) {
    let mutationResponse = await executeMutation({
      id,
    })
    if (mutationResponse.error) {
      notify(notifyError(`An error has occurred while deleting note template.`))
      return
    }

    notify(
      // Title "Deleted!"
      notifySuccess(`Template has been deleted.`)
    )
    change(next => {
      next.note_template = originalValue.note_template
    })
    setFlowTo(normalizePath(props.viewPath, 'Templates/DeleteConfirmation/No'))
  }
}

function useDataOnActionCopy(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(copyMutation)

  return async function onAction({ value, id, locations }) {
    let mutationResponse = await executeMutation({
      note_template_ids: id ? [id] : value.note_templates,
      target_location_ids: locations,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `An error has occurred while copying ${
            id || value.note_templates.length === 1 ? 'template' : 'templates'
          }.`
        )
      )
      return
    }

    notify(
      // Title "Templates Copied!"
      notifySuccess(
        `${
          id || value.note_templates.length === 1
            ? 'Template was'
            : 'Templates were'
        } copied to ${locations.length} ${
          locations.length > 1 ? 'locations' : 'location'
        }.`
      )
    )
  }
}

function useDataOnActionCancel(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ originalValue, change }) {
    change(next => {
      next.note_template = originalValue.note_template
    })
    setFlowTo(normalizePath(props.viewPath, 'Templates'))
    setFlowTo(normalizePath(props.viewPath, 'Templates/DeleteConfirmation/No'))
  }
}

function getNoteTemplatePath({ parent_company_id, file_key }) {
  return `companies/${parent_company_id}/note-templates/${file_key}`
}
