import React, { useEffect, useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { createRoot } from 'react-dom/client'
import UppyProxyPreactToReact from './UppyProxyPreactToReact.js'
import { useUppy, useUppyPlugin } from 'design-system/FileUploader/react.js'
import PaperClipIcon from 'design-system/Svgs/PaperClipIcon/view.js'
import GraphIcon from 'design-system/Svgs/GraphIcon/view.js'
import DocIcon from 'design-system/Svgs/DocIcon/view.js'

export default function FileUploaderPluginUI(props) {
  let [iconEl, setIconEl] = useState(null)
  let [contentEl, setContentEl] = useState(null)

  let uppy = useUppy(props)
  let uppyPlugin = useUppyPlugin(props)

  useEffect(() => {
    let plugin = uppy.getPlugin(props.name)
    if (plugin) {
      uppy.removePlugin(plugin)
    }

    uppy.use(UppyProxyPreactToReact, {
      id: props.name,
      title: props.name,
      renderIcon: setIconEl,
      renderContent: el => {
        setContentEl(el)

        if (el) {
          props.onMount && props.onMount()
        } else {
          props.onUnmount && props.onUnmount()
        }
      },
    })

    return uppyPlugin(props.name)
  }, [uppy]) // eslint-disable-line
  // ignore uppyPlugin

  useLayoutEffect(() => {
    if (!iconEl) return

    let root = createRoot(iconEl)

    root.render(<Icon name={props.name} />)
  }, [iconEl, props.name])

  return contentEl
    ? createPortal(
        typeof props.children === 'function'
          ? props.children()
          : props.children,
        contentEl
      )
    : null
}

function Icon({ name }) {
  switch (name) {
    case 'Documents':
      return <PaperClipIcon />
    case 'Images':
      return <GraphIcon />
    default:
      return <DocIcon />
  }
}
