import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let location = useDataValue({
    context: 'location',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value) {
      change(next => {
        next.selected.copy_to_locations.push(location._id)
      })
    } else {
      change(next => {
        next.selected.copy_to_locations =
          next.selected.copy_to_locations.filter(id => id !== location._id)
      })
    }
  }
}
