export function getView(layout) {
  if (!layout) return 'No'

  switch (layout) {
    case 'all_images':
      return 'AllImages'
    case 'misc':
      return 'Misc'
    case 'videos':
      return 'Videos'
    case 'itero':
      return 'Itero'
    default:
      return 'Standard'
  }
}
