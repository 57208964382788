import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return useMemo(
    () =>
      data?.[0] || {
        id: null,
        location_id,
        data: {
          default_view: 'timeline',
          compact_view_notes: 'compact',
        },
      },
    [data, location_id]
  )
}
