import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let edited_note = useDataValue({
    viewPath: props.viewPath,
    context: 'edited_note',
  })

  return useMemo(() => {
    if (!data) return data
    // don't show the note if it was just created and it is still edited
    return data.filter(
      note => edited_note.state !== 'create' || note.id !== edited_note.id
    )
  }, [data, edited_note])
}
