// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/MedicalForm/LocationChooser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_MedicalForm_LocationChooser{
  session_user_locations(order_by: { name: asc }) {
    id
    text: name
  }
}

`