// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Tools/AttachmentAction/InApp/Documents/Content/SystemFiles/FilesTree/Documents/Body/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Tools_AttachmentAction_InApp_Documents_Content_SystemFiles_FilesTree_Documents_Body($id: numeric!) {
  vaxiom_document_tree_nodes(
    where: { patient_id: { _eq: $id }, deleted: { _eq: false } }
    order_by: [{ type: desc }, { sys_last_modified: desc_nulls_last }]
  ) {
    id
    parent_id
    name
    size
    type
    sys_last_modified
  }
}

`