// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/PreviewSample/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_PreviewSample($document_id: numeric!) {
  vaxiom_document_tree_nodes_by_pk(id: $document_id) {
    id
    patient {
      organization {
        parent_company {
          id: _id
        }
      }
    }
  }
}

`