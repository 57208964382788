import { MEDICAL_HISTORY_CATEGORY_NAME } from 'Data/constants.js'
import { dateISOToDateAndTime } from 'Data/format.js'

/**
 * This method take the category id and returns the category name to be displayed in the dropdown
 * @param {string} value - category id, possible values all, general, medical_conditions, habits, medications, drugs_allergies_and_sensitivities
 * @return {string}
 * */

export function getTitle(value) {
  return MEDICAL_HISTORY_CATEGORY_NAME[value] ?? 'General'
}

/**
 * This method is used to get when a category question was modified the last time and who modified it
 * @param {array} questions - list of the category questions
 * @return {string} - returns the last person name that modified a category's question and the datetime
 * */
export function getLastModified(questions) {
  let lastAnswer = Array.isArray(questions)
    ? questions.reduce((answer, question) => {
        if (
          (!answer && question.created_at) ||
          answer?.last_modified?.getTime() < question?.created_at?.getTime()
        ) {
          return {
            user: question?.user,
            last_modified: question?.created_at,
          }
        }
        return answer
      }, undefined)
    : undefined

  return lastAnswer?.user && lastAnswer?.last_modified
    ? `Last modified by: ${lastAnswer.user} - ${dateISOToDateAndTime(
        lastAnswer.last_modified.toISOString()
      )}`
    : 'No answers yet'
}
