import { useMemo } from 'react'
import { useDataFormat, useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let current_index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let other_insurance_id = useDataFormat({
    context: 'payment_plan',
    format: payment_plan => {
      let other_insurance = payment_plan.insurances.find(
        (_, index) => current_index !== index
      )
      return other_insurance?.id
    },
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return data
      .map(item => ({
        ...item,
        text: [
          `${item.insurance_subscription.person.first_name} ${item.insurance_subscription.person.last_name}`,
          item.insurance_subscription.insurance_plan.insurance_billing_center
            ?.name ??
            item.insurance_subscription.insurance_plan.insurance_company
              .carrier_name,
          item.insurance_subscription.insurance_plan.number,
        ].join(', '),
      }))
      .filter(item => !other_insurance_id || item.id !== other_insurance_id)
  }, [data, other_insurance_id])
}
