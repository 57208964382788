import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import { toSentence } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, change }) {
    let validation = validate(value)
    if (validation.isInvalid) {
      return notify(
        notifyError(`Invalid data: ${toSentence(validation.errors)}`)
      )
    }

    let res = await executeMutation({
      organization_id:
        value.location_id || value.division_id || parent_company_id,
      steps: value.steps,
    })

    if (res.error) {
      let message = `There was a problem adding the ruleset.`
      let errorCode = res.error.graphQLErrors?.[0]?.extensions?.code
      if (errorCode === 'constraint-violation') {
        message = 'This ruleset already exists!'
      }
      notify(notifyError(`${message}`))
      return false
    } else {
      notify(notifySuccess(`Ruleset added!`))
      change(originalValue)
    }
  }
}

function validate(value) {
  let errors = [
    !value.steps.length && 'no options selected',
    value.steps.length &&
      !value.steps.includes('plan') &&
      'plan step is required',
    value.steps.length &&
      !value.steps.includes('sign') &&
      'sign step is required',
  ].filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}
