import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(obj => {
      let { external_office, employee_type, person } = obj
      let { city, state } = external_office.postal_address
      return {
        id: person.id,
        value: `${person.first_name} ${person.last_name} ${employee_type.name}, ${external_office.name}, ${city}, ${state} `,
      }
    })
  }, [data])
}
