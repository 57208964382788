import React, { useEffect, useState } from 'react'
import addMinutes from 'date-fns/addMinutes'
import isAfter from 'date-fns/isAfter'
import { useDataValue } from 'Simple/Data'
import View from './view.js'
import { timeDifference } from 'Data/format.js'

export default function Logic(props) {
  let appointment_booking = useDataValue({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })
  let [state, setState] = useState(getState)

  useEffect(() => {
    let interval = setInterval(() => setState(getState()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [appointment_booking]) // eslint-disable-line
  // ignore getState

  return (
    <View
      {...props}
      time={state.time}
      isEarly={state.isEarly}
      isOverdue={state.isOverdue}
    />
  )

  function getState() {
    let end_time = addMinutes(
      new Date(`${appointment_booking.start_time}Z`),
      appointment_booking.duration
    )
    let isOverdue = isAfter(new Date(), end_time)
    let isEarly = isAfter(
      new Date(`${appointment_booking.start_time}Z`),
      new Date()
    )
    return { isOverdue, isEarly, time: timeDifference(end_time) }
  }
}
