// this is an autogenerated file from DesignSystem/ImageViewGrid/ImageGrid/ImageSlot/PatientImage/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageViewGrid_ImageGrid_ImageSlot_PatientImage($resource_id: numeric!, $file_key: uuid) {
  resource_object_meta: vaxiom_resource_object_meta(
    resource_id: $resource_id
    resource_type: image
    file_key: $file_key
  ) {
    name
    content_type
    size
    url
    preview_url
  }
}

`