/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'appointmentOverlay/open': {
        change(next => {
          next.appointment_overlay.appointment_id = args.appointment_id
        })
        schedulingReset()
        break
      }
      case 'appointmentOverlay/close': {
        change(next => {
          next.appointment_overlay.appointment_id = null
        })
        schedulingReset()
        break
      }
      case 'scheduling/setSlots': {
        change(next => {
          next.scheduling.slots = args.slots
          next.scheduling.slot_id = null
        })
        break
      }
      case 'scheduling/selectSlot': {
        change(next => {
          next.scheduling.slot_id = args.slot_id
        })
        break
      }
      case 'scheduling/deselectSlot': {
        change(next => {
          next.scheduling.slot_id = null
        })
        break
      }
      case 'scheduling/reset': {
        schedulingReset()
        break
      }
      default: {
        break
      }
    }

    function schedulingReset() {
      change(next => {
        next.scheduling.slots = []
        next.scheduling.slot_id = null
      })
    }
  }
}
