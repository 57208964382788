import View from './view.js'
import React from 'react'

let TysiaAutocompleteAddressChoiceLogic = props => (
  <View
    {...props}
    {...props.item}
    isSelected={props.selected === props.item.place_id}
    onClick={() => {
      props.onClick(props.item.place_id)
    }}
  />
)
export default TysiaAutocompleteAddressChoiceLogic
