import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let images = useDataValue({
    context: 'patient_images',
    viewPath: props.viewPath,
  })
  let image_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_id',
  })
  let setImageId = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'image_id',
  })

  return function onClick() {
    let index = images.map(image => image.id).indexOf(image_id)
    setImageId(images[Math.min(index + 1, images.length - 1)].id)
  }
}

export function isDisabled(images, image_id) {
  return images.map(image => image.id).indexOf(image_id) === images.length - 1
}
