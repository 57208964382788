import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })
  return {
    variables: { person_id },
    pause: !person_id,
  }
}
