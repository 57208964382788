import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'images_request',
  })

  return async function onClick() {
    submit({ type: 'complete' })
  }
}

export function isDisabled(value) {
  return value.some(item => item.status === 'uploading')
}
