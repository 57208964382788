import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let contact_methods = useDataValue({
    context: 'contact_methods',
    viewPath: props.viewPath,
  })
  let setContactMethodId = useDataChange({
    context: 'schedule_via',
    path: 'contact_method_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (contact_methods?.length !== 1) return

    setContactMethodId(contact_methods[0].id)
  }, [contact_methods]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setContactMethodId

  return <View {...props} />
}
