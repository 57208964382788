import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let event = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return () => {
    if (event.selected) {
      submit({
        type: 'scheduling/deselectSlot',
        slot_id: event.id,
      })
    } else {
      submit({
        type: 'scheduling/selectSlot',
        slot_id: event.id,
      })
    }
  }
}
