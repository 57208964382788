// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/AutomationsList/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations_AutomationsList_Content($location_id: uuid!) {
  events_events(
    where: { organization_id: { _eq: $location_id } }
    order_by: { name: asc, source: desc }
  ) {
    id
    name
    data
    type
    status
    updated_at
    automation_type
    organization_id
    source
    actions {
      id
      type
    }
  }
}

`