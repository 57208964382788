import { useFlow, useSetFlowTo, normalizePath } from 'Simple/Flow'

export function useOnClick(props) {
  let flow = useFlow()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    if (flow.has(normalizePath(props.viewPath, '../Appointments/Content'))) {
      setFlowTo(normalizePath(props.viewPath, '../Appointments/No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, '../Appointments/Content'))
    }
  }
}
