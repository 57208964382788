import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let image_series = useDataValue({
    context: 'image_series',
    viewPath: props.viewPath,
  })
  let image_series_id = useDataValue({
    context: 'comparison_action',
    path: 'image_series_id',
    viewPath: props.viewPath,
  })
  let setImageSeriesId = useDataChange({
    context: 'comparison_action',
    path: 'image_series_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!image_series?.length || image_series_id) return
    setImageSeriesId(image_series[0].id)
  }, [image_series_id, image_series, setImageSeriesId])

  return <View {...props} />
}
