// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/DefaultPaymentPlans/AddRuleset/DefaultPaymentPlans/Content/DefaultPaymentPlan/Frequency/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_DefaultPaymentPlans_AddRuleset_DefaultPaymentPlans_Content_DefaultPaymentPlan_Frequency($location_id: uuid!, $division_id: uuid!, $parent_id: uuid!) {
  location_frequencies: treatments_payment_plan_frequencies(
    where: { organization_id: { _eq: $location_id } }
  ) {
    id
    type
    label
  }
  division_frequencies: treatments_payment_plan_frequencies(
    where: { organization_id: { _eq: $division_id } }
  ) {
    id
    type
    label
  }
  parent_frequencies: treatments_payment_plan_frequencies(
    where: { organization_id: { _eq: $parent_id } }
  ) {
    id
    type
    label
  }
}

`