import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutationUpdate from './mutation-update.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnActionSubmit(props) {
  let [, notify] = useNotifications()
  let [, executeMutationUpdate] = useMutation(mutationUpdate)

  return async function onAction({ value, args, originalValue, change }) {
    // TODO: ajv.validate
    if (!value.name || !value.type_id) return true

    let { id, ...object } = value
    let mutationResponse = await executeMutationUpdate({
      id: value.id,
      object,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Tx card field definitions updated!'))
    props.onClose()
  }
}
