import React from 'react'

export default function Button(props) {
  return (
    <button
      style={{
        alignSelf: 'flex-start',
        backgroundColor: '#e67c73',
        color: '#ffffff',
        paddingTop: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 4,
        marginLeft: 2,
        marginTop: 2,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        fontSize: 10,
        cursor: 'pointer',
        ...props.style,
      }}
      className="views-block"
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  )
}
