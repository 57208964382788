import { useDataValue } from 'Simple/Data.js'
import { useClient, useMutation } from 'Data/Api.js'

import query from './query.graphql.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let payment_account_id = useDataValue({
    context: 'patient',
    path: 'person.person_payment_account.id',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company.id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let [, executeMutation] = useMutation(mutation)
  let client = useClient()

  return async function onClick() {
    let response = await client
      .query(query, {
        patient_id,
      })
      .toPromise()
    await executeMutation({
      patient_id,
      amount: Math.ceil(Math.random() * 5_000),
      payment_account_id,
      parent_company_id,
      location_id,
      treatment_id: response.data.vaxiom_txs[0].id,
    })
  }
}
