import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      person: {
        birth_date: new Date().toISOString(),
      },
      referrals: [],
    }
  }, [data])
}
