import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let current_location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_id: current_location_id,
    },
  }
}
