import { patientName } from 'Data/format.js'
import { useDataSubmit } from 'Simple/Data'

// TODO: this has no effect but keeping it here for future reference
// when virtual list is implemented back into the morpher it will be needed
// together with the commented out code in Appointment/helpers.js

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'showPatient': {
        submit({
          type: 'tabs/add',
          tab: {
            id: value.patient_id,
            patient_id: value.patient_id,
            name: patientName(value.patient_person),
            type: 'Patient',
            tab: args.tab,
            viewPath: '/App/Account/Content/Patients',
          },
        })
        return
      }
      default: {
      }
    }
  }
}
