import {
  textInput,
  textInputOrNumber,
  validateCurrency,
  email,
  date,
} from 'Data/validate.js'
import { PAYMENTS_PAYMENT_TYPES } from 'Data/common-constants.js'

export function validate(value) {
  let errors = []

  let {
    payment_account,
    payment_method,
    check,
    receipt,
    amount,
    credit_card_manual,
  } = value

  if (!textInputOrNumber(payment_account.selected_id)) {
    errors.push('Please select account')
  }

  if (!validateCurrency(amount)) {
    errors.push('Please provide amount larger than 0')
  }

  if (!textInputOrNumber(payment_method.selected_id)) {
    errors.push('Please select payment method')
  }

  if (
    payment_method.data.payments_payment_type ===
    PAYMENTS_PAYMENT_TYPES.CheckPayment
  ) {
    if (!textInput(check.number)) {
      errors.push('Please provide valid check number')
    }
  }

  if (
    payment_method.data.payments_payment_type ===
    PAYMENTS_PAYMENT_TYPES.CreditCardManualPayment
  ) {
    if (!textInput(credit_card_manual.transaction_id)) {
      errors.push('Please provide transaction id')
    }

    if (!date(credit_card_manual.effective_date)) {
      errors.push('Please provide effective date')
    }
  }

  if (receipt.checkbox.email === true && !email(receipt.email)) {
    errors.push('Please provide valid receipt email')
  }

  return {
    isInvalid: errors.length > 0,
    errors,
  }
}
