import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let selected_user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.vaxiom_user_id',
  })
  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      selected_user_id: Number(selected_user_id),
    }
  }, [data, selected_user_id])
}
