// this is an autogenerated file from App/Auth/SignIn/mutation-sign-in.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignIn($email: String!, $password: String!, $skip_mfa_token: String) {
  vaxiom_users_login(
    email: $email
    password: $password
    skip_mfa_token: $skip_mfa_token
  ) {
    status
    access_token: jwt_token
    refresh_token
    inactivity_timeout
    mfa {
      type
      ticket
    }
  }
}

`