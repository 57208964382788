import React, { useEffect, useRef } from 'react'
import View from './view.js'
import { useDataValue, useDataSubmit } from 'Simple/Data'

export default function Logic(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'payments_request_payment',
  })
  let submitRef = useRef(submit)
  submitRef.current = submit

  let person_payment_account_id = useDataValue({
    context: 'payments_request_payment',
    path: 'person_payment_account.data.person_payment_account.id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (person_payment_account_id) {
      // trigger query for due amount on payment account
      // which also blocks the whole form while querying
      // it works for pre-select (when entering modal) and also for further selects by user
      submitRef.current({
        type: 'due_amount_submit',
        person_payment_account_id,
      })
    }
  }, [person_payment_account_id])

  return <View {...props} />
}
