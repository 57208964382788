import View from './view.js';
import get from 'lodash/get'
import React from 'react';

let DEFAULT_TEXT = 'Select'
export default function Logic(props) {
  let text = DEFAULT_TEXT
  if (Array.isArray(props.from) && typeof props.isSelected === 'function') {
    let selected = props.from.filter(props.isSelected)
    text =
      selected.length <= 1
        ? get(selected[0], `${props.field}`, props.text || 'Select')
        : `${selected.length} selected`
  }

  return (
    <form
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <View {...props} text={text} placeholder={text === DEFAULT_TEXT} />
    </form>
  );
}