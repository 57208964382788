import { useMemo } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { useDwt } from 'DesignSystem/DWT/useDwt'
import { useUppy } from 'DesignSystem/FileUploader/react.js'
import { downloadBlob } from 'Logic/downloadUrl.js'

export default function useDataTransform(props) {
  let uppy = useUppy(props)
  let {
    sources,
    currentSource,
    isLoading,
    isAcquiring,
    onSourceChange,
    onImagesAcquire,
  } = useDwt({
    containerId: 'dwtControlContainerId',
    convertMultiPageToPdf: false,
    onImagesAcquired,
  })
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setUploading = useDataChange({
    context: 'tab',
    path: 'selected.images.import_from_devices_is_uploading',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return useMemo(() => {
    return {
      sources: sources.map(source => ({ id: source, name: source })),
      currentSource,
      isLoading,
      isAcquiring,
      onSourceChange,
      onImagesAcquire,
    }
  }, [
    currentSource,
    isAcquiring,
    isLoading,
    sources,
    onSourceChange,
    onImagesAcquire,
  ])

  async function onImagesAcquired(images) {
    try {
      setUploading(true)

      uppy.cancelAll()

      for (let image of images) {
        try {
          uppy.addFile(image)
        } catch (err) {
          if (!err.isRestriction) {
            uppy.log(err)
            uppy.info(
              'There was an error when trying to add your files. Please try again.',
              'error',
              3000
            )
          }
        }
      }

      try {
        await uppy.upload()
      } catch (err) {
        // download the images for users to re-upload if needed
        await download(images)

        return notify(
          notifyError(
            'There was a problem uploading the image into our system. Please try again.'
          )
        )
      }

      let attachments = uppy.getFiles()

      let objects = attachments.map(attachment => ({
        patient_id,
        organization_node_id: location_id,
        original_name: attachment.name,
        mime_type: attachment.type,
        file_key: attachment.meta.location.substring(
          attachment.meta.location.lastIndexOf('/') + 1
        ),
      }))

      let mutationCreateDocumentResponse = await executeMutation({ objects })
      if (mutationCreateDocumentResponse.error) {
        // download the images for users to re-upload if needed
        await download(images)

        return notify(
          notifyError(
            'There was a problem storing the image. Please try again.'
          )
        )
      }

      setFlowTo(
        normalizePath(props.viewPath, '../../../UploadedImages/Content')
      )
      setFlowTo(normalizePath(props.viewPath, '../../No'))
    } finally {
      setUploading(false)
    }
  }

  async function download(images) {
    // eslint-disable-next-line
    let results = await Promise.allSettled(
      images.map(image =>
        downloadBlob({ blob: image.data, file_name: image.name })
      )
    )
    if (results.some(result => result?.status !== 'fulfilled')) {
      notify(notifyError('There was a problem downloading the images.'))
    }
  }
}
