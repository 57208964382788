// this is an autogenerated file from DesignSystem/MoveToAnotherTreatment/Events/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_MoveToAnotherTreatment_Events($treatment_id: uuid!) {
  treatments_timeline(args: { _treatment_id: $treatment_id }) {
    id
    type
    created_at
    updated_at
  }
}

`