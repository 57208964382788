import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let primary_location_id = useDataValue({
    context: 'patient',
    path: 'primary_location_id',
    viewPath: props.viewPath,
  })

  let parent_company_uuid = useDataValue({
    context: 'patient',
    path: 'organization._id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      location_id:
        data.patient_image_series.treatment?.organization?.id ||
        primary_location_id,
      parent_company_uuid:
        data.patient_image_series.treatment?.organization?.parent_company
          ?._id || parent_company_uuid,
    }
  }, [data, primary_location_id, parent_company_uuid])
}
