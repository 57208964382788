import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changeLocation = useDataChange({
    context: 'payment_method_selection',
    path: 'location',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    changeLocation(value?.length ? value[0] : null)
  }
}
