export default function useDataConfiguration(props) {
  let organization_ids = Array.isArray(props.organization_ids)
    ? props.organization_ids
    : typeof props.organization_ids === 'number'
    ? [props.organization_ids]
    : null

  return {
    variables: {
      organization_ids,
      name: props.name,
    },
    pause: !props.name || organization_ids === null,
  }
}
