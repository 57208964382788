// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/TreatmentRequestOverride/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_TreatmentRequestOverride($id: uuid!) {
  treatments_requests_by_pk(id: $id) {
    id
    settings
    allowed_steps
    downpayment_payment_methods
    autodraft_payment_methods
    max_discount
    tx_plan {
      id
      tx_plan_template {
        id
        tx_plan_group {
          id
          custom_payment_plan_ruleset {
            id
            min_downpayment
            min_downpayment_type
            max_length
            max_length_type
          }
        }
      }
    }
  }
}

`