// this is an autogenerated file from App/Account/Content/Patients/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content($id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $id } }) {
    id
    _id
    human_readable_id
    legacy_id
    primary_location_id
    person {
      id
      _id
      first_name
      middle_name
      last_name
      greeting
      birth_date
      gender
      school
      organization_id
      profile_pic_url
      person_payment_account {
        id
      }
    }
    organization {
      id
      _id
    }
    primary_location {
      id
      _id
    }
  }
}

`