// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/UploadedImages/Content/Images/Content/TemporaryImage/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_UploadedImages_Content_Images_Content_TemporaryImage($resource_id: numeric!) {
  resource_object_meta: vaxiom_resource_object_meta(
    resource_id: $resource_id
    resource_type: temporary_image
  ) {
    name
    content_type
    size
    url
    preview_url
  }
}

`