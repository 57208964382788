import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionEdit = useDataOnActionEdit(props)
  let onActionDuplicate = useDataOnActionDuplicate(props)
  let onActionDelete = useDataOnActionDelete(props)
  let onActionCopy = useDataOnActionCopy(props)

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'edit': {
        onActionEdit({ value })
        break
      }
      case 'duplicate': {
        onActionDuplicate({ value })
        break
      }
      case 'delete': {
        onActionDelete({ value })
        break
      }
      case 'copy': {
        onActionCopy({ value, locations: args.locations })
        break
      }
      default:
        break
    }
  }
}

function useDataOnActionEdit(props) {
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value }) {
    props.closePopover()
    return submit({ type: 'edit', id: value.id })
  }
}

function useDataOnActionDuplicate(props) {
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value }) {
    props.closePopover()
    return submit({ type: 'duplicate', id: value.id })
  }
}

function useDataOnActionDelete(props) {
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value }) {
    props.closePopover()
    return submit({ type: 'confirmDelete', id: value.id, name: value.name })
  }
}

function useDataOnActionCopy(props) {
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, locations }) {
    props.closePopover()
    return submit({ type: 'copy', id: value.id, locations })
  }
}
