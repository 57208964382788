// this is an autogenerated file from DesignSystem/CaptureImageSeries/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CaptureImageSeries($id: uuid!) {
  images_requests_by_pk(id: $id) {
    id
    status
    patient_image_series {
      id
      name
      treatment {
        id
        organization {
          id
          parent_company {
            id
            _id
          }
        }
      }
      patient_images(order_by: { sys_last_modified: desc }) {
        id
        file_key
        original_file_key
        type_key
        slot_key
        mime_type
      }
    }
  }
}

`