import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.range',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    change({ date_start: null, date_end: null })
  }
}
