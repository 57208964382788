import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'
import mutationRequirementsCheck from './mutation-requirements-check.graphql.js'

export default function useDataOnSubmit(props) {
  let id = useDataValue({
    context: 'event',
    path: 'appointment.booking._id',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, executeMutationRequirementsCheck] = useMutation(
    mutationRequirementsCheck
  )
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ args }) {
    if (!args?.skip_required_fields) {
      let mutationRequirementsCheckResponse =
        await executeMutationRequirementsCheck({
          id,
          params: { id, skip_required_fields: false },
        })
      if (mutationRequirementsCheckResponse.error) {
        notify(
          notifyError('Cannot unseat selected appointment. Please try again.')
        )
        return
      }

      if (
        mutationRequirementsCheckResponse.data
          .create_pre_appointment_bookings_state_update_automations.is_pending
      ) {
        // cannot unseat yet as some requirements need to be checked
        return
      }
    }
    let mutationResponse = await executeMutation({
      id,
      skip_required_fields: args?.skip_required_fields || false,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Cannot unseat selected appointment. Please try again.')
      )
      return
    }

    if (
      mutationResponse.data.vaxiom_appointment_booking_unseat.status ===
      'missing_required_treatment_fields'
    ) {
      setFlowTo(
        normalizePath(props.viewPath, 'MissingRequiredTreatmentFields/Content')
      )
    }
  }
}
