import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnTreatmentFee(props) {
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      let discount = next.discounts[index]
      if (!discount.is_applied_to_payor) {
        // ignore, already applied to treatment fee
        return
      }

      discount.is_applied_to_payor = false
      discount.amount = parseFloat(
        ((next.treatment_fee * discount.percentage) / 100).toFixed(2)
      )

      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}

export function useOnPayorsShare(props) {
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      let discount = next.discounts[index]
      if (discount.is_applied_to_payor) {
        // ignore as already applied to payor's share
        return
      }

      discount.is_applied_to_payor = true
      let primary_payor = next.payors.find(payor => payor.is_primary)
      // assign it to the primary payor by default
      discount.payor_person_id = primary_payor.person_id

      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
