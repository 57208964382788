import { useDataChange, useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    context: 'selected',
    path: 'lead',
    viewPath: props.viewPath,
  })

  let leadsById = useDataValue({
    context: 'marketing_analytics',
    path: 'leadsById',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    if (!next.item?.id) return
    change(leadsById[next.item.id])
  }
}
