import React, { useEffect } from 'react'
import { useDataChange } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let setSelectedImageSeriesId = useDataChange({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    setSelectedImageSeriesId(null)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return <View {...props} />
}
