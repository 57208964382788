// this is an autogenerated file from App/Account/NewPatient/Content/Panes/Check/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_NewPatient_Content_Panes_Check(
  $first_name: String!
  $last_name: String!
  $birth_date: date!
) {
  vaxiom_persons(
    where: {
      first_name: { _eq: $first_name }
      last_name: { _eq: $last_name }
      birth_date: { _eq: $birth_date }
    }
  ) {
    id: _id
    vaxiom_id: id
    first_name
    middle_name
    last_name
    title
    greeting
    gender
    ssn
    school
    birth_date
    patient {
      _id
      human_readable_id
      legacy_id
    }
    contact_method_associations(
      limit: 1
      where: { postal_address: { state: { _is_null: false } } }
    ) {
      postal_address {
        city
        state
      }
    }
  }
}

`