// this is an autogenerated file from App/Account/WaitingRoom/Content/Content/Agenda/Appointments/Content/Appointment/Action/Action/CheckIn/query_check_in_settings.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_WaitingRoom_Content_Content_Agenda_Appointments_Content_Appointment_Action_Action_CheckIn($current_location_id: uuid!) {
  vaxiom_location_access_keys(
    where: {
      organization: { _id: { _eq: $current_location_id } }
      access_name: { _eq: "PATIENT_CHECKIN" }
    }
  ) {
    id
    settings {
      id: location_access_key_id
      max_minutes_late
      max_due_amount
      due_period
      max_due_amount_insurance
      due_period_insurance
    }
  }
}

`