/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    try {
      switch (args.type) {
        case 'upload_captures':
          {
            let ids = await props.onUploadCaptures(
              value.captures.map(capture => capture.url)
            )

            if (ids?.length) {
              change(next => {
                next.captures = []
              })
            }
          }
          return
        default:
          return
      }
    } catch (error) {}
  }
}
