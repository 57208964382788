import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.organization._id',
  })

  return {
    variables: { user_id, location_id },
    pause: !user_id || !location_id,
  }
}
