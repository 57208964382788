import {
  localStorage,
  windowAddEventListenerStorage,
  windowRemoveEventListenerStorage,
} from 'Logic/localStorage'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { v4 as uuid } from 'uuid'
import React, { useEffect } from 'react'
import View from './view.js'

// 4 hours
let DEFAULT_INACTIVITY_TIMEOUT_MS = 4 * 60 * 60 * 1000
let DEBUG =
  process.env.REACT_APP_ENV === 'development' &&
  sessionStorage.getItem('inactivity_timeout_logs')

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let authInactivityTimeout = useDataValue({
    context: 'auth',
    path: 'inactivity_timeout',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (authInactivityTimeout === null) return

    let cancel = false

    let cancelInactivityTracker = makeInactivityTracker({
      timeout:
        Math.min(DEFAULT_INACTIVITY_TIMEOUT_MS, authInactivityTimeout) ||
        DEFAULT_INACTIVITY_TIMEOUT_MS,
      onInactive: ({ isFirstRun }) => {
        // If we wanted to, we could use the isFirstRun flag
        // to send someone to /App/Auth/SignOut
        if (cancel || isFirstRun) return

        setFlowTo(normalizePath(props.viewPath, '../Content'))
      },
    })

    return () => {
      cancel = true
      cancelInactivityTracker()
    }
  }, [props.viewPath, setFlowTo, authInactivityTimeout])

  return <View viewPath={props.viewPath} />
}

// based off:
// - https://medium.com/tinyso/how-to-detect-inactive-user-to-auto-logout-by-using-idle-timeout-in-javascript-react-angular-and-b6279663acf2
// - https://www.npmjs.com/package/idle-timer-manager
// - https://github.com/ovanderzee/inactivity-listener
//
// https://developers.google.com/web/updates/2017/03/background_tabs#budget-based_background_timer_throttling
function makeInactivityTracker({
  onInactive,
  timeout = 10000, // ms
  key = 'inactivity_tracker',
  events = [
    'keydown',
    'keyup', // to be sure
    'mousemove',
    'mouseenter', // to be sure
    'mousedown',
    'mouseup', // to be sure
    'scroll',
    'wheel',
  ],
  eventOptions = { passive: true, capture: true },
  eventTimeout = 3000, // ms
}) {
  if (!onInactive) return

  let id = uuid()
  let isFirstRun = true
  let interval = null
  let updateGuard = null
  let timeoutKey = `${key}_timeout`
  let expiredKey = `${key}_expired`

  start()

  return clear

  function start() {
    DEBUG && console.debug('inactivity-tracker/start', { id })
    setExpiredTime()
    windowAddEventListenerStorage(expiredListener)

    events.forEach(event => {
      let handler = `on${event}`
      if (handler in window) {
        window.addEventListener(event, updateTimeout, eventOptions)
      } else if (handler in document) {
        document.addEventListener(event, updateTimeout, eventOptions)
      }
    })

    interval = setInterval(() => {
      let expiredTime = parseInt(localStorage.getItem(timeoutKey), 10)

      if (expiredTime < Date.now()) {
        console.debug('inactivity-tracker/expired', { expiredTime, id })

        localStorage.setItem(expiredKey, id)

        clear()

        reportInactivity()
      }

      isFirstRun = false
    }, 1000)
  }

  function setExpiredTime() {
    localStorage.setItem(timeoutKey, Date.now() + timeout)

    DEBUG &&
      console.debug('inactivity-tracker/setExpiredTime', {
        expiredTime: localStorage.getItem(timeoutKey),
        id,
      })
  }

  function updateTimeout() {
    if (updateGuard) {
      clearTimeout(updateGuard)
    }

    updateGuard = setTimeout(setExpiredTime, eventTimeout)
  }

  function clear() {
    DEBUG && console.debug('inactivity-tracker/clear', { id })

    if (interval) {
      clearInterval(interval)
    }

    if (updateGuard) {
      clearTimeout(updateGuard)
    }

    windowRemoveEventListenerStorage(expiredListener)
    localStorage.removeItem(expiredKey)

    localStorage.removeItem(timeoutKey)

    events.forEach(event => {
      let handler = `on${event}`
      if (handler in window) {
        window.removeEventListener(event, updateTimeout, eventOptions)
      } else if (handler in document) {
        document.removeEventListener(event, updateTimeout, eventOptions)
      }
    })
  }

  function reportInactivity() {
    onInactive({ isFirstRun })
  }

  function expiredListener(event) {
    if (event.key !== expiredKey || event.newValue === id) return

    DEBUG && console.debug('inactivity/expired', { id, event })

    reportInactivity()
  }
}
