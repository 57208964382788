import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let appointment_id = useDataValue({
    context: 'appointment',
    path: '_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'move_resources',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      if (next.appointment_ids.includes(appointment_id)) {
        next.appointment_ids = next.appointment_ids.filter(
          id => id !== appointment_id
        )
      } else {
        next.appointment_ids.push(appointment_id)
      }
    })
  }
}
