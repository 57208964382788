import { useClient } from 'Data/Api.js'
import { useEffect } from 'react'
import mutation from './mutation.graphql.js'
import Plugin from './plugin.js'
import { v4 as uuid } from 'uuid'

export default function useFileUploaderRemoteFilesUploader({ uppy, prefix }) {
  let client = useClient()

  useEffect(() => {
    if (!uppy) return

    // this one is tricky as Uppy overrides the "this" reference and as a result it will point to an outdated version of those dependencies
    // here we have to re-register the plugin in order for the changes to take effect
    let plugin = uppy.getPlugin(Plugin.pluginName)
    if (plugin) {
      uppy.removePlugin(plugin)
    }

    uppy.use(Plugin, {
      id: Plugin.pluginName,
      uploadFile: async file => {
        let response = await client
          .mutation(mutation, {
            resource_id: file.remote.body.resource_id,
            resource_type: file.remote.body.resource_type,
            destination_key: `${prefix}/${uuid()}.${file.extension}`,
          })
          .toPromise()

        uppy.setFileMeta(file.id, {
          location: response.data.vaxiom_resource_object_copy.url,
        })

        return { location: null }
      },
    })
  }, [uppy, prefix, client])
}
