import { useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation_edit_preferences from './editPreferencesMutation.graphql.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeEditPreferencesMutation] = useMutation(
    mutation_edit_preferences
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })

  let phone = useDataValue({
    viewPath: props.viewPath,
    context: 'phone',
  })

  return function onClick(action) {
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      case 'SET_PREFERRED':
        return setPreferred()
      default:
        return
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Delete/Content'))
  }

  function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Edit/Content'))
  }

  async function setPreferred() {
    let mutationEditPreferencesResponse = await executeEditPreferencesMutation({
      id: phone.id,
      preference: 'CALL',
      person_id: person_id,
    })
    if (mutationEditPreferencesResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
  }
}
