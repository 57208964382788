// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/UploadedImages/Content/Images/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_UploadedImages_Content_Images($patient_id: numeric!) {
  vaxiom_temporary_images(
    where: { patient_id: { _eq: $patient_id } }
    order_by: { sys_last_modified: desc_nulls_last }
  ) {
    id
    file_key
    original_name
    mime_type
  }
}

`