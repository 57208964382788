import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.organization._id',
  })

  return {
    variables: { location_id },
    pause: !location_id,
  }
}
