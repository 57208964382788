import useIsHovered from 'Simple/hooks/useIsHovered.js'
import React from 'react'
import View from './view.js'
import { useDataValue, useDataIsSubmitting } from 'Simple/Data.js'

export default function Logic(props) {
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  let isSubmitting = useDataIsSubmitting({
    context: 'create_diagnosis',
    viewPath: props.viewPath,
  })
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      disabled={
        !(
          ['SEATED', 'UNSEATED', 'CHECKED_OUT'].includes(
            appointment.booking.state
          ) || appointment.diagnosis
        ) || isSubmitting
      }
    />
  )
}
