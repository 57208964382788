import { useDataChange } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onSubmit(args) {
    // reset hack
    change(next => {
      next.selected.comms.auto_uploaded_attachments = []
      next.selected.comms.message = ''
      next.selected.comms.tags = []
    })
  }
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.selected.comms.message_composes[value.thread_id] = value
    })
  }
}

export function useOnThreadIdsHackChange(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.thread_ids_hack',
    viewPath: props.viewPath,
  })

  return function onThreadIdsHackChange(value) {
    change(value)
  }
}
