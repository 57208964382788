import { useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.comms.thread_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    change(null)
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
