import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'provider_accounts',
  })

  let provider = useDataValue({
    viewPath: props.viewPath,
    context: 'provider_account',
    path: 'provider',
  })

  let account_id = useDataValue({
    viewPath: props.viewPath,
    context: 'provider_account',
    path: 'id',
  })
  return function onClick(value) {
    if (value) {
      submit({ type: 'change_default', provider, value, account_id })
    }
  }
}
