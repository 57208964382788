import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let type = useDataValue({
    context: 'treatment',
    path: 'status',
    viewPath: props.viewPath,
  })

  let organization_id = useDataValue({
    context: 'treatment_card',
    path: 'txs.organization.computed_parent_company_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { type, organization_id },
    pause: !type || !organization_id,
  }
}
