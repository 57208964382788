import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useDataChange } from 'Simple/Data'
import { v4 as uuid } from 'uuid'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let changeNewPatient = useDataChange({
    context: 'new_patient',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  function getTextValue(value) {
    if (value.type === 'DropDown') {
      return Array.isArray(value?.referral_list_values)
        ? value.referral_list_values.find(
            template => template.id === value.text_value
          )?.value
        : null
    }

    return value.text_value
  }

  return async function onSubmit({ value, originalValue, args, change }) {
    if (args?.type !== 'cancel' && (value?.text_value || value?.person_id)) {
      changeNewPatient(next => {
        let referral = {
          ...value,
          // eslint-disable-next-line no-undef
          _id: uuid(),
          referral_template_id: value.id,
          text_value: getTextValue(value),
        }
        next.referrals.push(referral)
      })
    }

    change(originalValue)
    setFlowTo(normalizePath(props.viewPath, './Add'))
  }
}
