// this is an autogenerated file from DesignSystem/PaymentProvider/PaymentProviderMethod/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PaymentProvider_PaymentProviderMethod($token_id: uuid!) {
  update_payments_tokens_by_pk(
    pk_columns: { id: $token_id }
    _set: { reusable: false }
  ) {
    id
  }
}

`