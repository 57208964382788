import { useDataValue } from 'Simple/Data.js'
export default function useDataConfiguration(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    path: 'patient_id',
    context: 'tab',
  })

  return {
    variables: {
      type: 'pms_patient',
      resource_id: patient_id,
    },
    requestPolicy: 'network-only',
  }
}
