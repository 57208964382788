import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.document_templates.location_id',
    viewPath: props.viewPath,
  })
  let current_location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (!value.length) return
    if (value.some(item => item._id === location_id)) return

    change(next => {
      next.selected.document_templates.location_id = value.some(
        item => item._id === current_location_id
      )
        ? current_location_id
        : value[0]._id
      next.selected.document_templates.document_template = null
    })
  }
}
