// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/copy-events-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations(
  $event_ids: [uuid!]!
  $target_location_ids: [uuid!]!
) {
  events_events_copy(
    event_ids: $event_ids
    target_location_ids: $target_location_ids
  ) {
    ok
  }
}

`