// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/FeatureAccess/Rulesets/Content/Actions/Content/SaveAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_FeatureAccess_Rulesets_Content_Actions_Content_SaveAction(
  $ruleset_ids_to_delete: [numeric!]!
  $message_key: String!
  $rulesets_to_update: [vaxiom_application_properties_updates!]!
) {
  delete_vaxiom_application_properties(
    where: {
      organization_id: { _in: $ruleset_ids_to_delete }
      message_key: { _eq: $message_key }
    }
  ) {
    affected_rows
    returning {
      organization_id
    }
  }
  update_vaxiom_application_properties_many(updates: $rulesets_to_update) {
    affected_rows
    returning {
      organization_id
    }
  }
}

`