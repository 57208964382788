import { isAppointmentScheduled } from 'Data/format.js'
import isAfter from 'date-fns/isAfter'
import startOfDay from 'date-fns/startOfDay'

export function getEvents(events) {
  // return all the events created today or in the future
  return events.filter(event => {
    if (event.type === 'appointment') {
      return isAppointmentScheduled(event.appointment)
        ? isAfter(
            new Date(`${event.appointment.booking.start_time}Z`),
            startOfDay(new Date())
          )
        : true
    } else {
      return isAfter(new Date(`${event.created_at}Z`), startOfDay(new Date()))
    }
  })
}
