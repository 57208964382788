import {
  TREATMENT_PAYMENT_PLAN_FREQUENCY_TYPES,
  TREATMENT_PAYMENT_PLAN_LENGTH_TYPES,
  TREATMENT_PAYMENT_PLAN_VALUE_TYPES,
} from 'Data/constants'
import { toSentence } from 'Data/format'
import { emptyList, numberZeroOrPositive } from 'Data/validate'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, args, change }) {
    let validation = validate(value)
    if (validation.isInvalid) {
      return notify(
        notifyError(`Invalid data: ${validation.errors.join(', ')}`)
      )
    }

    let res = await executeMutation({
      rulesets: value.default_payment_plans.map((item, index) => ({
        name: item.name,
        description: item.description,
        downpayment: item.downpayment,
        downpayment_type: item.downpayment_type,
        length: item.length,
        length_type: item.length_type,
        frequency: item.frequency,
        order: index,
        organization_id: value.location._id,
        discounts: {
          data: item.discounts,
        },
      })),
    })

    if (res.error) {
      let message = `There was a problem adding the rulesets.`
      let errorCode = res.error.graphQLErrors?.[0]?.extensions?.code
      if (errorCode === 'constraint-violation') {
        message = 'This ruleset already exists!'
      }
      return notify(notifyError(`${message}`))
    }

    notify(notifySuccess(`Ruleset added!`))
    change(next => {
      next.default_payment_plans = originalValue.default_payment_plans
    })
  }
}

function validate(value) {
  if (!value.location) {
    return {
      isInvalid: true,
      errors: ['location is required'],
    }
  }

  if (!value.default_payment_plans?.length) {
    return {
      isInvalid: true,
      errors: ['add a default payment plan'],
    }
  }

  let errors = value.default_payment_plans
    .map((item, index) => {
      let length = parseFloat(item.length)
      let errors = [
        !item.name && 'name is required',
        !TREATMENT_PAYMENT_PLAN_FREQUENCY_TYPES.includes(item.frequency) &&
          'frequency is not valid',
        !TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(item.downpayment_type) &&
          'downpayment type is not valid',
        !numberZeroOrPositive(item.downpayment) && 'downpayment not valid',
        item.downpayment_type === 'percentage' &&
          item.downpayment > 100 &&
          'downpayment of type percentage must be a number between 0 and 100',
        !TREATMENT_PAYMENT_PLAN_LENGTH_TYPES.includes(item.length_type) &&
          'length type is not valid',
        !Number.isInteger(length) && 'length must be an integer',
        item.length_type === 'total' &&
          length < 0 &&
          'length of type total must be a non-negative integer',
        item.downpayment === 100 &&
          item.downpayment_type === 'percentage' &&
          (length !== 0 ||
            item.length_type !== 'total' ||
            item.frequency !== 'None') &&
          'a downpayment of 100% on the payer fee requires a total length of 0 months and frequency None',
        length === 0 &&
          item.length_type === 'total' &&
          (item.downpayment !== 100 ||
            item.downpayment_type !== 'percentage' ||
            item.frequency !== 'None') &&
          'a total length of 0 months requires a downpayment of 100% and frequency None',
        item.frequency === 'None' &&
          (item.downpayment !== 100 ||
            item.downpayment_type !== 'percentage' ||
            length !== 0 ||
            item.length_type !== 'total') &&
          'a frequency None requires a total length of 0 months and a downpayment of 100%',
        ...validateDiscounts(item.discounts),
      ].filter(Boolean)

      if (errors.length === 0) return null
      return `[Plan ${index + 1}] ${toSentence(errors)}`
    })
    .filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}

function validateDiscounts(values) {
  if (emptyList(values)) return []
  if (values.length > 1) return ['only one discount is allowed']

  return values
    .map((item, index) => {
      let errors = [
        !item.discount_id && 'discount name is required',
        !TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(item.type) &&
          'discount type is not valid',
        !numberZeroOrPositive(item.value) && 'discount value not valid',
        item.type === 'percentage' &&
          item.value > 100 &&
          'discount value of type percentage must be a number between 0 and 100',
      ].filter(Boolean)

      if (errors.length === 0) return false
      return `[discount ${index + 1}] ${toSentence(errors)}`
    })
    .filter(Boolean)
}
