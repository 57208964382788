// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Resources/Content/Resources/Content/Resource/VaxiomSysOrganizations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Resources_Content_Resources_Content_Resource_VaxiomSysOrganizations($id: uuid!) {
  vaxiom_sys_organizations(where: { _id: { _eq: $id } }) {
    id
    _id
    name
  }
}

`