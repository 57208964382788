import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import mutation_cash from './mutation_cash.graphql.js'
import mutation_check from './mutation_check.graphql.js'
import mutation_cc from './mutation_cc.graphql.js'
import mutation_ach from './mutation_ach.graphql.js'
import mutation_edit_pp from './mutation_edit_payment_plan.graphql.js'
import {
  notifyError,
  notifyInvalid,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionDownpayment = useDataOnActionDownpayment(props)
  let onActionDownpaymentSkip = useDataOnActionDownpaymentSkip(props)
  let onActionInstallment = useDataOnActionInstallment(props)
  let onActionInstallmentComplete = useDataOnActionInstallmentComplete(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'downpayment': {
        return onActionDownpayment({ value, args, change })
      }
      case 'downpayment_skip': {
        return onActionDownpaymentSkip({ value, args, change })
      }
      case 'downpayment_complete': {
        return onDownpaymentComplete({ value, change })
      }
      case 'installment': {
        return onActionInstallment({ value, args, change })
      }
      case 'installment_complete': {
        return onActionInstallmentComplete({ value, args, change })
      }

      default:
        throw new Error(`Unknown action type ${args.type}`)
    }
  }
}

function useDataOnActionDownpaymentSkip(props) {
  let [, notify] = useNotifications()
  return function onActionDownpaymentSkip({ value, args, change }) {
    onDownpaymentSuccess({ value, change })
    notify(
      notifySuccess(
        'Downpayment will need be collected manually in the Ledger.'
      )
    )
    return
  }
}

function useDataOnActionInstallmentComplete(props) {
  return async function onActionInstallmentComplete({ value, args, change }) {
    change(next => {
      let payer = next.payers.find(p => p.id === next.activeTab.id)
      payer.completed = true
      payer.installment_complete = true

      let nextActive = next.payers.find(p => !p.completed)
      if (!nextActive) {
        next.completed = true
        return
      }

      next.activeTab = {
        ...nextActive,
        view:
          nextActive.data.downpayment > 0
            ? 'DownpaymentSetup'
            : 'AutopaymentSetup',
      }
    })
  }
}

function useDataOnActionDownpayment(props) {
  let onActionCard = useDataOnActionCard(props)
  let onActionCash = useDataOnActionCash(props)
  let onActionCheck = useDataOnActionCheck(props)
  let onActionAch = useDataOnActionAch(props)

  return async function onActionDownpayment({ value, args, change }) {
    switch (value.activeTab.downpayment_metadata.type) {
      case 'card': {
        let error = await onActionCard({ value, args, change })
        if (error) return true
        break
      }
      case 'ach': {
        let error = await onActionAch({ value, args, change })
        if (error) return true
        break
      }
      case 'check': {
        let error = await onActionCheck({ value, args, change })
        if (error) return true
        break
      }
      case 'cash': {
        let error = await onActionCash({ value, args, change })
        if (error) return true
        break
      }
      default:
        throw new Error(
          `Unknown downpayment type ${value.activeTab.downpayment_metadata.type}`
        )
    }
    onDownpaymentSuccess({ value, change })
  }
}

function onDownpaymentSuccess({ value, change }) {
  change(next => {
    let payer = next.payers.find(p => p.id === next.activeTab.id)
    payer.downpayment_complete = true
    next.activeTab.view = 'DownpaymentComplete'
  })
}

function onDownpaymentComplete({ value, change }) {
  change(next => {
    let payer = next.payers.find(p => p.id === next.activeTab.id)
    if (next.activeTab.data.installment) {
      next.activeTab.view = 'AutopaymentSetup'
    } else {
      payer.completed = true
      let nextActive = next.payers.find(p => !p.completed)
      if (nextActive) {
        next.activeTab = nextActive
        next.activeTab.view =
          next.activeTab.data.downpayment > 0
            ? 'DownpaymentSetup'
            : 'AutopaymentSetup'
      } else {
        next.completed = true
      }
    }
  })
}

function useDataOnActionCard(props) {
  let [, notify] = useNotifications()
  let submitPayments = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })

  let [, executeMutationCard] = useMutation(mutation_cc)

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onActionCard({ value, args, change }) {
    let token = await submitPayments({ type: 'tokenize' })
    if (token === true || !token) return true

    let mutationResponse = await executeMutationCard({
      receivable_id: value.activeTab.data.receivable_id,
      amount: value.activeTab.data.downpayment,
      location_id,
      notes: value.notes,
      patient_id: value.activeTab.patient_id,
      payment_account_id: value.activeTab.id,
      token,
    })

    if (mutationResponse.error) {
      if (
        mutationResponse.error?.graphQLErrors?.[0]?.extensions?.code ===
        'payments/payment-failed'
      ) {
        notify(
          notifyError(
            `Payment failed: ${mutationResponse.error.graphQLErrors[0].message}`
          )
        )
      } else {
        notify(
          notifyError(
            `There was an issue taking the payment. Please try again. ${mutationResponse.error.graphQLErrors[0].message}`
          )
        )
      }
      return true
    }

    change(next => {
      let currentPayer = next.payers.find(
        payer => next.activeTab.id === payer.id
      )
      let token_id =
        mutationResponse.data.payments_post_payment_credit_card.payment
          .payment_transaction.token_id

      currentPayer.downpayment_token_id = token_id
      next.activeTab.downpayment_token_id = token_id
    })

    notify(notifySuccess('Downpayment successfull!'))
  }
}

function useDataOnActionAch(props) {
  let [, notify] = useNotifications()
  let submitPayments = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })

  let [, executeMutationAch] = useMutation(mutation_ach)

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onActionAch({ value, args, change }) {
    let token = await submitPayments({ type: 'tokenize' })
    if (token === true || !token) return true

    let mutationResponse = await executeMutationAch({
      receivable_id: value.activeTab.data.receivable_id,
      amount: value.activeTab.data.downpayment,
      location_id,
      notes: value.notes,
      patient_id: value.activeTab.patient_id,
      payment_account_id: value.activeTab.id,
      token,
    })

    if (mutationResponse.error) {
      if (
        mutationResponse.error?.graphQLErrors?.[0]?.extensions?.code ===
        'payments/payment-failed'
      ) {
        notify(
          notifyError(
            `Payment failed: ${mutationResponse.error.graphQLErrors[0].message}`
          )
        )
      } else {
        notify(
          notifyError(
            `There was an issue taking the payment. Please try again. ${mutationResponse.error.graphQLErrors[0].message}`
          )
        )
      }
      return true
    }

    change(next => {
      let currentPayer = next.payers.find(
        payer => next.activeTab.id === payer.id
      )
      let token_id =
        mutationResponse.data.payments_post_payment_bank_account.payment
          .payment_transaction.token_id
      currentPayer.downpayment_token_id = token_id
      next.activeTab.downpayment_token_id = token_id
    })

    notify(notifySuccess('Downpayment successfull!'))
  }
}

function useDataOnActionCash(props) {
  let [, notify] = useNotifications()
  let [, executeMutationCash] = useMutation(mutation_cash)

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onActionCash({ value, args, change }) {
    let mutationResponse = await executeMutationCash({
      receivable_id: value.activeTab.data.receivable_id,
      amount: value.activeTab.data.downpayment,
      location_id,
      notes: value.notes,
      patient_id: value.activeTab.patient_id,
      payment_account_id: value.activeTab.id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please try again later.'))
      return true
    }
    notify(notifySuccess('Downpayment successfull!'))
  }
}

function useDataOnActionCheck(props) {
  let [, notify] = useNotifications()
  let [, executeMutationCheck] = useMutation(mutation_check)

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onActionCheck({ value, args, change }) {
    let mutationResponse = await executeMutationCheck({
      receivable_id: value.activeTab.data.receivable_id,
      amount: value.activeTab.data.downpayment,
      location_id,
      notes: value.notes,
      patient_id: value.activeTab.patient_id,
      payment_account_id: value.activeTab.id,
      payer_name: value.activeTab.downpayment_metadata.check.payer,
      check_number: value.activeTab.downpayment_metadata.check.number,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please try again later.'))
      return true
    }
    notify(notifySuccess('Downpayment successfull!'))
  }
}

function useDataOnActionInstallment(props) {
  let [, notify] = useNotifications()
  let submitPayments = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })
  let [, executeMutationPp] = useMutation(mutation_edit_pp)

  return async function onActionInstallment({ value, args, change }) {
    let token = await submitPayments({ type: 'tokenize' })
    if (token === true || !token) return true

    let mutationResponse = await executeMutationPp({
      payment_plan_id: value.activeTab.data.payment_plan_id,
      token,
    })

    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please try again later.'))
      return true
    }
    if (
      mutationResponse.data.payments_add_payment_plan_autopay.verified ||
      value.installment_metadata.type === 'ach'
    ) {
      notify(notifySuccess('Autopayment setup successful!'))
    } else {
      notify(
        notifyInvalid([
          'Autopayment added. verification failed with: ',
          mutationResponse.data.payments_add_payment_plan_autopay
            .verification_metadata.auth_message,
        ])
      )
    }
    change(next => {
      next.activeTab = { ...value.activeTab, view: 'AutopaymentComplete' }
    })
  }
}
