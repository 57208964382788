import { useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let note_change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected_note',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    note_change({
      note: '',
      target_type: 'Patient.CheckIn',
    })
    setFlowTo(normalizePath(props.viewPath, '../../../NewOrEdit/Content'))
  }
}
