import { useMemo } from 'react'
import { useDataFormat, useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let patient_person_uuid = useDataValue({
    context: 'patient',
    path: 'person._id',
    viewPath: props.viewPath,
  })

  let foundPpa = useDataFormat({
    context: 'patient',
    path: 'person_payment_accounts',
    viewPath: props.viewPath,
    format: value =>
      patient_person_uuid && Array.isArray(value)
        ? value.find(i => i.data.payor._id === patient_person_uuid)
        : null,
  })

  let txs = useDataValue({
    context: 'patient',
    path: 'txs',
    viewPath: props.viewPath,
  })
  let firstTxUuid = txs?.[0]?.id

  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      ...(foundPpa
        ? {
            person_payment_account: {
              data: foundPpa.data,
              selected_id: foundPpa.id,
            },
          }
        : {}),
      ...(firstTxUuid ? { tx_uuid: firstTxUuid } : {}),
    }
  }, [data, foundPpa, firstTxUuid])
}
