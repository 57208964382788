import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let deleted = useDataValue({
    context: 'image_serie',
    path: 'deleted',
    viewPath: props.viewPath,
  })

  return useMemo(
    () =>
      [
        { id: 'EDIT', text: 'Edit image series' },
        !deleted ? { id: 'DELETE', text: 'Delete image series' } : null,
      ].filter(Boolean),
    [deleted]
  )
}
