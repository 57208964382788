// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/DentalMonitoring/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_DentalMonitoring($id: uuid!) {
  dental_monitoring_patient(id: $id) {
    id
    patient {
      _links {
        public_profile
      }
      monitoring {
        status
        resume_date
        config {
          protocol_title
        }
      }
    }
  }
}

`