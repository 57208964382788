import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_id = useDataValue({
    context: 'connection',
    viewPath: props.viewPath,
    path: 'id',
  })

  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return {
    variables: { connection_id, location_id },
    pause: !connection_id || !location_id,
  }
}
