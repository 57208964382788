// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/Notes/Content/Note/Read/Delete/Content/Actions/DeleteAction/deleteNoteMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes_Notes_Content_Note_Read_Delete_Content_Actions_DeleteAction($note_id: numeric!) {
  delete_vaxiom_notes_by_pk(id: $note_id) {
    id
    note
  }
}

`