import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      next.default_payment_plans.push({
        id: null,
        name: 'Best for smallest total',
        description: 'Paid in Full',
        downpayment: 100,
        downpayment_type: 'percentage',
        length: 0,
        length_type: 'total',
        frequency: null,
        discounts: [],
      })
    })
  }
}
