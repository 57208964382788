import { getNumOfInstallments } from './helpers.js'
import { textToNumber } from 'Simple/Data/format.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  return function onChange(value, change) {
    change(next => {
      next.num_of_installments = getNumOfInstallments({
        due_amount: textToNumber(value.invoice_amount),
        installment_amount: textToNumber(value.installment_amount),
      })
    })
  }
}
