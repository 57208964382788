import { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'image_series_id',
    viewPath: props.viewPath,
  })
  let image_series = useDataValue({
    context: 'image_series',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'tab',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!image_series.length || image_series_id) return
    // prevent updating selected treatment if treatment cards displayed for the previous patient
    if (patient_id !== image_series[0].patient._id) return

    change(next => {
      next.image_series_id = image_series[0].id
      next.image_id = null
    })
  }, [image_series, image_series_id]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setImageSeriesId

  return <View {...props} />
}
