import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import {
  getRealizationDatesForAPaymentPlan,
  getInstallmentDates,
  getNumOfInstallments,
  getPlanLengthInMonths,
} from './helpers.js'
import { parseISO } from 'date-fns'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let receivable = useDataValue({
    viewPath: props.viewPath,
    context: 'receivable',
  })

  return useMemo(() => {
    let today = parseISO(
      `${receivable.organization.local_current_date}T00:00:00Z`
    )
    let installment_dates = getInstallmentDates('Monthly')
    let first_installment_dates = getRealizationDatesForAPaymentPlan({
      installment_interval: 'Monthly',
      today,
      installment_dates: [today.getDate()],
    })

    let installment_amount = Math.min(receivable.balance_amount, 100)
    let plan_length = getPlanLengthInMonths({
      due_amount: receivable.balance_amount,
      installment_interval: 'Monthly',
      due_date: first_installment_dates[0].date,
      installment_dates: null,
      installment_amount,
    })
    let num_of_installments = getNumOfInstallments({
      due_amount: receivable.balance_amount,
      installment_amount,
    })

    return {
      invoice_amount: receivable.balance_amount,
      selected_installment_interval: 'Monthly',
      installment_dates,
      selected_installment_date: [today.getDate()],
      first_installment_dates,
      selected_first_installment_date: 0,
      installment_amount,
      plan_length,
      num_of_installments,
      selected_payment_source: null,
      selected_autopay_source: null,
      notes: '',
    }
  }, [receivable.balance_amount, receivable.organization.local_current_date])
}
