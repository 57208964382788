// this is an autogenerated file from DesignSystem/ImageViewGrid/ImageGrid/ImageSlot/LogoImage/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageViewGrid_ImageGrid_ImageSlot_LogoImage($id: numeric!) {
  vaxiom_patient_imageseries_by_pk(id: $id) {
    id
    treatment {
      id
      organization {
        id
        logo {
          id
        }
      }
    }
    patient {
      id
      primary_location {
        id
        logo {
          id
        }
      }
    }
  }
}

`