import { useRef } from 'react'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import debounce from 'lodash/debounce.js'
import { equals } from 'Data/aggregate.js'
import { getAnswer } from 'Data/treatment-fields.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'appointments_treatment_fields',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let previous_answer = useRef(data.answer)
  let [, executeMutation] = useMutation(mutation)

  return debounce(onChange, 500)

  async function onChange(value, change) {
    if (equals(previous_answer.current, value.answer)) {
      return
    }

    let values = getValues(value).map(appointment_field_value => ({
      ...appointment_field_value,
      appointment_id,
      field_definition_id: value.id,
    }))
    let mutationResponse = await executeMutation({
      appointment_id,
      field_definition_id: value.id,
      values,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the appointment fields. Please try again.`
        )
      )
      return
    }

    if (value.tracked && !values.length) {
      // the user either cleared the selection or selected the same value as the one inherited from previous appointments
      // resetting to reflect the tracked value instead of keeping user's selection
      let answer = getAnswer(value.type, value.tracked_values)
      previous_answer.current = answer
      change(next => {
        next.answer = answer
      })
    } else {
      previous_answer.current = value.answer
    }

    submit({ type: 'reFetch' })

    notify(notifySuccess(`Saved!`), { extendExistingNotification: true })
  }
}

function getValues(value) {
  if (!value.answer) return []
  // the value selected is the same as the one inferred from previous appointment
  // remove selection and use inherited value
  if (
    value.tracked &&
    equals(value.answer, getAnswer(value.type, value.tracked_values))
  ) {
    return []
  }

  switch (value.type) {
    case 'choiceCustom':
      return [getValue(value.answer, value.options)]
    case 'multiChoiceCustom':
      return value.answer.map(answer => getValue(answer, value.options))
    case 'text':
      return [{ text_value: value.answer }]
    default:
      return []
  }
}

function getValue(answer, options) {
  return options.some(option => option.id === answer)
    ? { field_option_id: answer }
    : { text_value: answer }
}
