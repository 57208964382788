import React, { useEffect } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let appointments = useDataValue({
    context: 'appointments',
    viewPath: props.viewPath,
  })
  let appointment_id = useDataValue({
    context: 'image_serie_form',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'image_serie_form',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (appointment_id || appointments.length < 2) return

    change(next => {
      next.appointment_id = appointments[1].id // select the first appointment (first option is "No appointment")
      next.date = appointments[0].start_date
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
