import { useDataValue } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import query from './query.graphql.js'
import sample from 'lodash/sample.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    window.postMessage({
      fakeDevelopmentCoreEmbedMessage: true,
      type: 'payments_create_payment_plan_for_existing_invoice',
      params: {
        patient_id,
        receivable_id: prompt('Receivable ID'),
      },
    })
  }
}
