import useIsHovered from 'Simple/hooks/useIsHovered.js'
import React from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'
import { useFloating, autoPlacement, offset } from '@floating-ui/react-dom'

export default function Logic(props) {
  let flow = useFlowValue(props.viewPath)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })

  let { refs, x, y } = useFloating({
    open: flow === 'Content',
    middleware: [
      autoPlacement({ allowedPlacements: ['bottom-start'] }),
      offset({ mainAxis: 10 }),
    ],
  })

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === refs.reference.current ||
      refs.reference.current?.contains(event.target) ||
      flow === 'No'
    )
      return

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View
      {...props}
      setOuterRef={refs.setReference}
      setInnerRef={refs.setFloating}
      left={x}
      top={y}
      onClick={onClick}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      selected={flow === 'Content'}
    />
  )

  function onClick(event) {
    if (flow === 'Content') {
      setFlowTo(normalizePath(props.viewPath, 'No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    }
  }
}
