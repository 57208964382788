import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('insurance_claims')
  }
}
