import React, { useEffect } from 'react'
import { useDataChange, useDataFormat, useDataValue } from 'Simple/Data'
import View from './view.js'

export default function Logic(props) {
  let tabId = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'id',
  })
  let sync = useDataFormat({
    viewPath: props.viewPath,
    context: 'global',
    path: 'tabs',
    format: value => value.find(item => item.id === tabId)?.sync,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  useEffect(() => {
    if (!sync) return

    change(next => {
      next.sync = null
      next.tab = sync.tab
      next.coreParams = sync.coreParams
    })
  }, [sync]) // eslint-disable-line
  // ignore change

  return <View {...props} />
}
