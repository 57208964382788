import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.insurances.push({
        id: crypto.randomUUID(),
        insured_id: null,
        insured: null,
        is_primary: next.insurances.length === 0,
        estimated_reimbursement_amount: 0,
        is_overridden: false,
      })
    })
  }
}
