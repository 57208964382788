import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let image_series_id = useDataValue({
    context: 'image_serie',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setImageSeriesId = useDataChange({
    context: 'comparison_action',
    path: 'image_series_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setImageSeriesId(image_series_id)
  }
}
