// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CreateIteroScanRequest/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CreateIteroScanRequest_Content($parent_company_id: uuid!, $location_id: uuid!) {
  apps_connection_resources(
    where: {
      # TODO: ideally it should also check the app_id
      connection: { name: { _eq: "itero" }, status: { _eq: active } }
      status: { _eq: enabled }
      _or: [
        { resource_id: { _eq: $location_id }, type: { _eq: location } }
        { resource_id: { _eq: $parent_company_id }, type: { _eq: company } }
      ]
    }
  ) {
    id
    connection {
      id
      app_id
      name
    }
  }
  apps_connection_resource_properties(
    where: {
      _or: [
        { resource_id: { _eq: $parent_company_id }, type: { _eq: company } }
        { resource_id: { _eq: $location_id }, type: { _eq: location } }
      ]
      # TODO: ideally it should also check the app_id
      connection: { name: { _eq: "itero" } }
    }
  ) {
    id
    resource_id
    connection_id
    properties
  }
}

`