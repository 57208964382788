// this is an autogenerated file from DesignSystem/CommsThread/Content/EventsTimeline/Content/Event/Types/Sms/Content/Outbound/Info/MessageStatusUpdate/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__DesignSystem_CommsThread_Content_EventsTimeline_Content_Event_Types_Sms_Content_Outbound_Info_MessageStatusUpdate($id: uuid!) {
  comms_events_sms_by_pk(id: $id) {
    id
    twilio_message_status
  }
}

`