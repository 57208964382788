import React, { useEffect, useRef } from 'react'
import View from './view.js'

export default function Logic(props) {
  let innerRef = useRef()

  useEffect(() => {
    onInput({ target: innerRef.current })
  }, [])

  return (
    <View
      {...props}
      value={props.value || ''}
      onInput={onInput}
      onKeyUp={onKeyUp}
      onChange={onChange}
      innerRef={innerRef}
      minHeight={props.height || 100}
    />
  )

  function onChange(event, value) {
    onInput(event)
    if (typeof props.onChange !== 'function') return

    props.onChange(value)
  }

  function onKeyUp(event) {
    if (props.onKeyUp) {
      props.onKeyUp(event)
    } else if (event.key === 'Enter' && typeof props.onSubmit === 'function') {
      props.onSubmit()
    }
  }

  // crappy auto resizer https://github.com/lekoala/vanilla-autogrow/blob/master/autogrow.js
  // I actually change dit a bit and it seems to behave a bit better by setting a mininum height of 12px
  // at the beginning. Leaving the rest of the code just in case because this thing is odd.
  function onInput(event) {
    if (props.height !== 'auto') return

    // reset height
    event.target.style.height = '13px'

    // get computed styles
    // let computed = window.getComputedStyle(event.target)

    // get scroll + border height
    let height =
      // parseInt(computed.getPropertyValue('padding-top'), 10) +
      // parseInt(computed.getPropertyValue('border-top-width'), 10) +
      event.target.scrollHeight // +
    // parseInt(computed.getPropertyValue('padding-bottom'), 10) +
    // parseInt(computed.getPropertyValue('border-bottom-width'), 10)

    // update height
    event.target.style.height = height + 'px'
  }
}
