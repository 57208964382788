// this is an autogenerated file from App/Account/Chairs/Content/Filter/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Chairs_Content_Filter($current_location_id: uuid!) {
  vaxiom_chairs(
    where: {
      resource: {
        organization: {
          _id: { _eq: $current_location_id }
          deleted: { _eq: false }
        }
      }
      # some chairs have empty string code and name, filtering them out for now as it's adding too much clutter on the UI
      code: { _neq: "" }
    }
    order_by: { pos: asc }
  ) {
    id
    _id
    code
  }
}

`