import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(
    () => ({
      ...data,
      patient_id: props.patient_id,
      source_treatment_card_id: props.treatment_card_id,
      appointment_ids: props.appointment_ids || [],
      note_ids: props.note_ids || [],
    }),
    [
      data,
      props.appointment_ids,
      props.note_ids,
      props.patient_id,
      props.treatment_card_id,
    ]
  )
}
