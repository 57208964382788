import { useClient } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import {
  generateSignedUrl,
  useAwsS3Client,
  getS3ObjectUrl,
  useAwsCredentials,
} from 'Data/s3.js'
import query from './query-locations.graphql.js'
import { blobToBase64 } from 'Logic/base64.js'

export default function useQueryLocations(props) {
  let client = useClient()
  let [, notify] = useNotifications()
  let s3 = useAwsS3Client(props)
  let getAwsCredentials = useAwsCredentials(props)

  return async function getLocations(location_ids) {
    let response = await client.query(query, { location_ids }).toPromise()

    if (response.error) {
      notify(
        notifyError(
          `There was a problem getting the locations metadata to generate the PDF. Please try again.`
        )
      )
      return []
    }

    let awsCredentials = await getAwsCredentials()

    return Promise.all(
      response.data.vaxiom_sys_organizations.map(async item => {
        let logo =
          item.logo[0] || item.parent.logo[0] || item.parent.parent.logo[0]
        let url = EMPTY_IMAGE
        if (logo) {
          let s3url = await generateSignedUrl(
            s3,
            getS3ObjectUrl({
              bucket: awsCredentials.legacy_storage_bucket_name,
              key: `logoimg/${logo.file_id}`,
              region: awsCredentials.region,
            })
          )
          // check that file exists, mostly for other envs outside of production
          // but otherwise the PDF generation will fail
          let exists = await fetch(s3url, { method: 'GET' })

          if (exists.ok) {
            try {
              let base64img = await blobToBase64(await exists.blob())

              if (base64img.startsWith('data:')) {
                url = base64img
              }
            } catch {}
          }
        }

        return {
          ...item,
          logo: {
            ...logo,
            url,
          },
        }
      })
    )
  }
}

let EMPTY_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
