import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(
    () => ({
      ...data,
      container_id: props.containerId,
    }),
    [data, props.containerId]
  )
}
