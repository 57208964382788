// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/PaymentProviders/AddAction/Content/Content/Payabli/Token/query_list_paypoints.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_PaymentProviders_AddAction_Content_Content_Payabli_Token($token_id: String!) {
  payments_list_paypoints(token_id: $token_id) {
    id
    name
    parentOrgName
    usedBy
  }
}

`