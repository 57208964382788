// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/NoteTemplates/delete-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_NoteTemplates($id: uuid!) {
  delete_notes_templates_by_pk(id: $id) {
    id
  }
}

`