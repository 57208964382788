// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/AddInvoiceAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_AddInvoiceAction(
  $patient_id: numeric!
  $parent_company_id: numeric!
  $location_id: numeric!
  $treatment_id: numeric!
  $payment_account_id: numeric
  $amount: numeric!
) {
  insert_vaxiom_receivables_one(
    object: {
      rtype: Invoice
      patient_id: $patient_id
      due_date: "NOW()"
      realization_date: "NOW()"
      parent_company_id: $parent_company_id
      organization_id: $location_id
      treatment_id: $treatment_id
      payment_account_id: $payment_account_id
      transactions: {
        data: [
          {
            dtype: MiscFeeCharge
            amount: $amount
            affects_due_now: true
            effective_date: "NOW()"
          }
        ]
      }
    }
  ) {
    id
  }
}

`