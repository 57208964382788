import React, { useEffect, useRef } from 'react'
import View from './view.js'
import { useDataChange } from 'Simple/Data.js'

export default function Logic(props) {
  let change = useDataChange({
    context: 'payment',
    path: 'location_id',
    viewPath: props.viewPath,
  })
  let changeRef = useRef(change)

  useEffect(() => {
    changeRef.current(props.locationId)
  }, [props.locationId])

  useEffect(() => {
    changeRef.current = change
  }, [change])

  return <View {...props} />
}
