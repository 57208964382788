import React, { useRef } from 'react'
import { useDrag } from 'react-dnd'
import { useDataValue } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let ref = useRef()
  let temporary_image = useDataValue({
    context: 'temporary_image',
    viewPath: props.viewPath,
  })

  let [{ isDragging }, drag] = useDrag(() => ({
    type: 'temporary-image',
    item: temporary_image,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(ref)

  return <View innerRef={ref} isDragging={isDragging} {...props} />
}
