// this is an autogenerated file from DesignSystem/CaptureImageSeries/Content/Active/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CaptureImageSeries_Content_Active($parent_company_id: uuid!, $location_id: numeric!) {
  images_settings(where: { organization_id: { _eq: $parent_company_id } }) {
    id
    organization_id
    data
  }
  vaxiom_application_properties(
    where: {
      organization_id: { _eq: $location_id }
      message_key: { _like: "image.auto-flipping.%" }
    }
  ) {
    message_key
    message_value
  }
}

`