import React, { useEffect } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let reset = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'reset_edited_note',
  })
  let setResetEditedNote = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'reset_edited_note',
  })
  let selected_user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.vaxiom_user_id',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'edited_note',
  })

  useEffect(() => {
    if (!reset) return

    change(next => {
      next.id = null
      next.selected_user_id = Number(selected_user_id)
      next.note = ''
      next.state = 'create'
      next.temp_id = Date.now()
    })
    // prevent it from keeping the same value when collapsing and expanding the appointment
    setResetEditedNote(null)
  }, [reset])
  return <View {...props} />
}
