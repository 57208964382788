import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs._id',
  })

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit() {
    let mutationResponse = await executeMutation({
      id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem starting the contract. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess('Started contracts'))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
