import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })

  let eventId = useDataValue({
    context: 'automation',
    path: 'event_id',
    viewPath: props.viewPath,
  })

  let type = useDataValue({
    context: 'type',
    viewPath: props.viewPath,
  })

  return function onChange() {
    if (type.selected || eventId) return
    change(next => {
      next.trigger_type = 'post'
      next.automation_type = type.id
    })
  }
}
