// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CephTracing/ViewTraces/Content/AnalysesTree/Analyses/Content/CalibrationToolModal/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CephTracing_ViewTraces_Content_AnalysesTree_Analyses_Content_CalibrationToolModal_Content($id: uuid!) {
  update_ceph_requests_by_pk(
    pk_columns: { id: $id }
    _set: { status: processing }
  ) {
    id
  }
}

`