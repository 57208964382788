import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let insurance_subscriptions = useDataValue({
    context: 'insurance_subscriptions',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let insurance_subscription = insurance_subscriptions.find(
        item => item.id === value
      )

      let insurance = next.insurances[index]
      insurance.insured_id = value
      insurance.insured = insurance_subscription
      insurance.is_overridden = false

      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
