// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/Primary/Content/Unseat/MissingRequiredTreatmentFields/Content/TreatmentFields/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_Primary_Content_Unseat_MissingRequiredTreatmentFields_Content_TreatmentFields($appointment_id: uuid!) {
  appointments_missing_required_treatment_fields(
    args: { _appointment_id: $appointment_id }
    order_by: { number: asc }
  ) {
    id
    name
  }
}

`