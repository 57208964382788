import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let id = useDataValue({
    context: 'message',
    path: 'params.treatment_request_id',
    viewPath: props.viewPath,
  })
  console.log({ txov: id })
  return {
    variables: { id },
    pause: !id,
  }
}
