import { useEffect, useState } from 'react'

export default function useStableValue(value, wait = 500) {
  let [stable, setStable] = useState(value)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setStable(value)
    }, wait)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, wait])

  return stable
}
