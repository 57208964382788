import React, { useImperativeHandle, useRef } from 'react'
import View from './view.js'
import useMaskedInput from '@local/use-masked-input'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

let MASKS = {
  date: {
    mask: [/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/],
    guide: true,
  },
  phone: {
    mask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  fein: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  twoDigits: {
    mask: [/\d/, /\d/],
    guide: false,
  },
  threeDigits: {
    mask: [/\d/, /\d/, /\d/],
    guide: true,
  },
  fourDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/],
    guide: false,
  },
  fiveDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  sixDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  nineDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  tenDigits: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  currency: {
    mask: createNumberMask({
      allowDecimal: true,
    }),
    guide: false,
  },
  ssn: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    guide: true,
  },
  creditCard: {
    mask: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  zip: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
  },
  signatureRequestCode: {
    mask: [
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/i,
      /[0-9a-fA-F]/,
    ],
    guide: true,
  },
}

export default React.forwardRef(function Logic(
  { mask = 'phone', value = '', onSubmit, ...props },
  ref
) {
  let input = useRef(null)
  if (process.env.REACT_APP_ENV === 'development' && !(mask in MASKS)) {
    throw new Error(
      `${mask} isn't a valid mask. Choose from ${Object.keys(MASKS)}`
    )
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      input.current.focus()
    },
  }))

  let onChange = useMaskedInput({
    input,
    ...MASKS[mask],
    onChange: event => props.onChange(event.target.value),
    value: typeof value === 'string' ? value : '',
  })

  return (
    <View
      {...props}
      innerRef={input}
      onChange={onChange}
      onKeyUp={event => {
        if (event.key === 'Enter' && typeof onSubmit === 'function') {
          onSubmit()
        }
      }}
      onSubmit={onSubmit}
    />
  )
})
