// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/updateOfficeMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content(
  $external_office_id: numeric!
  $office_name: String
  $postal_id: numeric!
  $address_line1: String
  $city: String
  $state: String
  $zip: String!
) {
  update_vaxiom_external_offices_by_pk(
    pk_columns: { id: $external_office_id }
    _set: { name: $office_name }
  ) {
    id
  }
  update_vaxiom_contact_postal_addresses_by_pk(
    pk_columns: { id: $postal_id }
    _set: {
      address_line1: $address_line1
      city: $city
      state: $state
      zip: $zip
    }
  ) {
    id
  }
}

`