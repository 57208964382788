import BasePlugin from '@uppy/core/lib/BasePlugin'
import { blobToBase64 } from 'Logic/base64.js'

export default class EditorFilesUploader extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts)
    this.type = 'uploader'
    this.id = this.opts.id || 'EditorFilesUploader'
    this.title = 'EditorFilesUploader'

    this.handleUpload = this.handleUpload.bind(this)
  }

  async handleUpload(fileIDs) {
    for (let fileId of fileIDs) {
      let imgBase64 = await blobToBase64(this.uppy.getFile(fileId).data)
      let imgTag = `<img src="${imgBase64}" style="width:600px" width="600px"/>`
      this.opts.editor.insertContent(imgTag)
    }
  }

  install() {
    this.uppy.addUploader(this.handleUpload)
  }

  uninstall() {
    this.uppy.removeUploader(this.handleUpload)
  }
}

EditorFilesUploader.pluginName = 'EditorFilesUploader'
