import View from './view.js'

export default function Logic(props) {
  let item = props.item || {}

  return (
    <View
      {...props}
      isSelected={props.isSelected && props.isSelected(item)}
      text={item[props.field]}
      onClick={() => props.onClick(item.id)}
    />
  )
}
