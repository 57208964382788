import React, { useEffect } from 'react'
import { DataProvider, useDataSubmit, useDataValue } from 'Simple/Data'

import View from './view.js'

let value = {
  image_serie_id: null,
  image_id: null,
}

export default function Logic(props) {
  let refetch = useDataValue({
    context: 'tab',
    path: 'selected.images.refetch',
    viewPath: props.viewPath,
  })
  let submitPatientImages = useDataSubmit({
    context: 'patient_images',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refetch) return

    submitPatientImages({ type: 'reFetch' })
  }, [refetch]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitPatientImages

  return (
    <DataProvider context="comparison" value={value} viewPath={props.viewPath}>
      <View {...props} />
    </DataProvider>
  )
}
