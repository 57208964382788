// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/EditLength/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_EditLength_Content(
  $tx_id: uuid!
  $start_date: date!
  $end_date: date!
) {
  update_vaxiom_txs(
    where: { _id: { _eq: $tx_id } }
    _set: {
      start_date: $start_date
      end_date: $end_date
      estimated_end_date: $end_date
    }
  ) {
    affected_rows
  }
}

`