import { useUppy } from '../FileUploader/react.js'
import useFileUploaderPlugin from './hook.js'

export default function FileUploaderPluginS3(props) {
  let uppy = useUppy(props)
  useFileUploaderPlugin({
    uppy,
    editor: props.editor,
  })
  return null
}
