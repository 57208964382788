import { useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let patient_url = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
    path: 'patient_url',
  })
  return function onClick() {
    window.open(patient_url, '_blank')
  }
}
