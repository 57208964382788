import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'frequency',
    path: 'allowed_dates',
    viewPath: props.viewPath,
  })
  let days = useDataValue({
    context: 'days',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change(value ? days.map(day => day.id) : [])
  }
}

export function flatValues(values) {
  return values.map(value => value.id)
}
