import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let notes = useDataValue({
    viewPath: props.viewPath,
    context: 'notes',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'copy_notes',
  })

  useEffect(() => {
    // setting the notes on a data context from the parent view
    // in order to have access to it when copying the notes
    change(next => {
      next.appointment_notes[props.data.appointment.id] = notes
    })
  }, [notes, props.data.appointment.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
