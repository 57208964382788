import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data'
import View from './view.js'

export default function Logic(props) {
  let image_id = useDataValue({
    context: 'gallery',
    path: 'image_id',
    viewPath: props.viewPath,
  })
  let setImageId = useDataChange({
    context: 'gallery',
    path: 'image_id',
    viewPath: props.viewPath,
  })
  let images = useDataValue({
    context: 'patient_images',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (image_id || !images?.length) return

    setImageId(images[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image_id, images])

  useEffect(() => {
    let onKeyDown = event => {
      if (!images.length || !image_id) return

      let index = images.map(image => image.id).indexOf(image_id)
      if (event.key === 'ArrowLeft') {
        setImageId(images[Math.max(index - 1, 0)].id)
      } else if (event.key === 'ArrowRight') {
        setImageId(images[Math.min(index + 1, images.length - 1)].id)
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [image_id, images, setImageId])

  return <View {...props} />
}
