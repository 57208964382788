import { useDataValue } from 'Simple/Data.js'
import { useGetSignedUrl } from 'Data/s3'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let attachment = useDataValue({
    context: 'attachment',
    viewPath: props.viewPath,
  })
  let value = useGetSignedUrl({
    url: attachment.url,
    filename: attachment.name,
    viewPath: props.viewPath,
  })

  return <View viewPath={props.viewPath} value={value} />
}
