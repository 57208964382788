import { generateSignedUrl, useAwsS3Client } from 'Data/s3.js'
import { useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useEffect, useRef } from 'react'

// This is an experiment really...
// I was toying with the idea of moving the logic to a helper
// so it can be reused in a retry button inside.
// The problem I found was that the cleanup logic will run when
// the button in this case Content/PrintAction is unmounted
// the moment Loading is set in onClick the effect will unmount.
// Leaving it here as a concept to explore anyways...
// I considered using a data provider with onSubmit but I
// think the problem will be there anyway, which, tbh, probably
// means we need to see that too...
export function useOnClick(props) {
  let params = useDataValue({
    context: 'message',
    path: 'params',
    viewPath: props.viewPath,
  })
  let s3 = useAwsS3Client({ viewPath: props.viewPath })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let cancel = useRef(false)
  let iframe = useRef(null)

  useEffect(
    () => () => {
      if (props.skipCleanup) return
      cancel.current = true
      cleanupIframe()
    },
    []
  ) // eslint-disable-line

  return async function onClick() {
    if (cancel.current) {
      cancel.current = false
      cleanupIframe()
    }

    setFlowTo(normalizePath(props.viewPath, 'Loading'))
    let pdfMergerJs = import('pdf-merger-js/browser')

    // if we do it within the promise we get them out of order, however,
    // we can fetch the files in parallel no problem and the array will
    // be fine
    let files = await Promise.all(
      params.urls.map(async url => {
        let file = await fetch(await generateSignedUrl(s3, url))
        return await file.blob()
      })
    )
    if (cancel.current) return

    let merger = new (await pdfMergerJs).default()
    if (cancel.current) return
    for (let file of files) {
      await merger.add(file)
    }
    if (cancel.current) return

    iframe.current = document.createElement('iframe')
    iframe.current.style.display = 'none'
    iframe.current.onload = () => {
      // this doesn't seem to work from my tests...
      // it's like the event is never called
      function close() {
        cleanupIframe()
        setFlowTo(normalizePath(props.viewPath, '../No'))
      }

      iframe.current.contentWindow.onbeforeunload = close
      iframe.current.contentWindow.onafterprint = close
      iframe.current.contentWindow.print()
    }
    let blob = await merger.saveAsBlob()
    if (cancel.current) return

    iframe.current.src = URL.createObjectURL(blob)
    document.body.appendChild(iframe.current)

    setFlowTo(normalizePath(props.viewPath, 'Content'))
  }

  function cleanupIframe() {
    if (iframe.current) {
      try {
        document.body.removeChild(iframe.current)
      } catch (error) {}
    }
  }
}
