import { useMemo } from 'react'
import { useDataFormat } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let events = useDataFormat({
    context: 'events',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data || !Array.isArray(events) || !events.length) return data

    let future_separator_idx = events.findIndex(
      event => event.type === 'future_separator'
    )

    let lastPreviousAppointment =
      future_separator_idx > 0
        ? events
            .slice(0, future_separator_idx)
            .findLast(event => event.type === 'appointment')
        : null

    return {
      ...data,
      previous_appointment_id: lastPreviousAppointment?.appointment._id,
    }
  }, [data, events])
}
