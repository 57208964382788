// this is an autogenerated file from App/Auth/Check/mutation-set-current-location.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_Check($current_location_id: uuid!) {
  insert_user_preferences_one(
    object: { current_location_id: $current_location_id, chair_ids: [] }
    on_conflict: {
      constraint: user_preferences_pkey
      update_columns: [current_location_id, chair_ids]
    }
  ) {
    # keep them for the cache
    user_id
    chair_ids
    current_location_id
  }
}

`