import * as Sentry from '@sentry/react'
import React, { useContext, useEffect, useRef } from 'react'
import isObject from 'lodash/isObject.js'
import uuid from 'uuid/v4.js'
import ErrorBoundaryView from 'DesignSystem/ErrorBoundary/index.js'

// uncomment this line to enable Sentry in dev mode
let DISABLE_SENTRY = process.env.REACT_APP_ENV === 'development'

// yes, global
export let sessionId = uuid()

if (!DISABLE_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.REACT_APP_ENV,
    release: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`,
    ignoreErrors: [
      'Could not verify JWT',
      'Failed to fetch dynamically imported module',
      'Importing a module script failed',
      'Invalid token',
      'Invalid username or password',
      'Loading chunk',
      'no subscriptions exist',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'onion',
      'pixie',
      'requestAnimationFrame is not defined',
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver loop limit exceeded',
      `not found in type: 'query_root`,
      `Can't find variable: fetch`,
    ],
    // By Default is 3
    normalizeDepth: 6,
    beforeBreadcrumb(breadcrumb, hint) {
      switch (breadcrumb.category) {
        case 'ui.click': {
          if (hint.event.srcElement?.dataset?.viewPath) {
            breadcrumb.message = hint.event.srcElement?.dataset?.viewPath
            // keeping this here in case we want to log the text at some point
            // it's disabled now because it could potentially log PHI
            // breadcrumb.data = {
            //   text: hint.event.srcElement?.textContent,
            //   viewPath: hint.event.srcElement?.dataset?.viewPath,
            // }
          }
          break
        }
        case 'fetch': {
          try {
            let { body, headers } = hint.input[1]
            let gql = JSON.parse(body)
            let [, rviewPath] = gql.operationName.split('__')
            let [kind] = gql.query.split(' ')
            let viewPath = `/${rviewPath.replace(/_/g, '/')}`

            breadcrumb.data = {
              kind,
              viewPath,
              'request-id': headers['x-request-id'],
              'hasura-role': headers['x-hasura-role'],
            }
          } catch (_) {}
          break
        }
        default: {
        }
      }
      return breadcrumb
    },
  })

  Sentry.configureScope(scope => {
    try {
      scope.setTransactionName()
      scope.setTag('session-id', sessionId)
    } catch (error) {}
  })
}

let Context = React.createContext(false)

export let useErrorBoundary = () => useContext(Context)

export function useOnError(callback) {
  let [error] = useErrorBoundary()
  let didMount = useRef(false)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    }

    if (error) {
      callback()
    }
  }, [error, callback])
}

let filterSensitiveData = makeRedact({
  properties: [
    // from lib
    'apikey',
    'api-key',
    'api_key',
    'pass',
    'password',
    'secret',
    'x-api-key',
  ],
  text: '[...]',
})

export function captureError(error, rawInfo = {}, tags = {}) {
  requestAnimationFrame(() => {
    let info = Object.keys(rawInfo).length > 0 && filterSensitiveData(rawInfo)

    if (DISABLE_SENTRY) {
      console.error({ type: 'captureError', error, info })
      console.trace()
      return
    }

    Sentry.withScope(scope => {
      if (info) {
        info.embedUrl = {
          domain: document.domain,
          origin: document.location.origin,
          // removed it's throwing this issue https://sentry.io/organizations/greyfinch/issues/1161172413/?project=1392628&query=is%3Aunresolved
          // parent: window.parent.location.origin,
        }
        Object.keys(info).forEach(key =>
          scope.setExtra(key, JSON.stringify(info[key]))
        )
      }

      Object.entries(tags).forEach(([key, value]) => {
        scope.setTag(key, value)
      })

      Sentry.captureException(error)
    })
  })
}

export function captureBreadcrumb({
  category,
  message,
  data,
  level = 'debug', // https://github.com/getsentry/sentry-javascript/blob/7.0.0/MIGRATION.md#severity-severitylevel-and-severitylevels
}) {
  if (process.env.REACT_APP_ENV === 'development') {
    console.debug('ErrorBoundary/captureBreadcrumb', {
      category,
      message,
      data,
      level,
    })
  }

  if (DISABLE_SENTRY) return

  if (category === 'auth' && data?.id) {
    Sentry.configureScope(function (scope) {
      try {
        scope.setUser(data)
      } catch (error) {}
    })
  }

  Sentry.addBreadcrumb({
    category,
    message,
    data,
    level,
  })
}

let VISIBLE_PADDING = 3 // characters

// src https://github.com/rcjpisani/redactyl.js/blob/master/src/index.js
function makeRedact({ properties, text }) {
  let regex = new RegExp(`(${properties.join('|')})`, 'i')

  return function redact(json) {
    if (!isObject(json)) {
      throw new TypeError('A valid JSON object must be specified')
    }

    let redacted = JSON.parse(JSON.stringify(json))

    // Object.entries is polyfilled
    // eslint-disable-next-line
    Object.entries(redacted).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.filter(isObject).forEach((item, index) => {
          redacted[key][index] = redact(item)
        })
      } else if (isObject(value)) {
        redacted[key] = redact(value)
      } else if (regex.test(key)) {
        redacted[key] = text
      } else if (typeof value === 'string' && regex.test(value)) {
        // this catches values like https://site.com/?token=1321321
        let match = value.match(regex)[1]
        redacted[key] = `${value.slice(
          0,
          value.indexOf(match) + match.length + VISIBLE_PADDING
        )}${text}`
      }
    })

    return redacted
  }
}
