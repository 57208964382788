import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.person.contact_method_associations.map(association => ({
      id: association.contact_method.email.address,
      text: association.contact_method.email.address,
    }))
  }, [data])
}
