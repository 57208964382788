import { getParentView } from 'Simple/Flow'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import Uppy from '@uppy/core'
import { captureError } from 'Logic/ErrorBoundary.js'
import { DataProvider, useDataValue } from 'Simple/Data.js'

let DEFAULT_CONTEXT = 'uppy'

/** @return {Uppy} */
export function useUppy(props) {
  return useDataValue({
    context: props.context || DEFAULT_CONTEXT,
    viewPath: props.viewPath,
  })
}

export function useUppyPluginContext(props) {
  return useDataValue({
    context: `${props.context || DEFAULT_CONTEXT}_plugins`,
    viewPath: props.viewPath,
  })
}

export function useUppyPlugin(props) {
  let [, setPlugins] = useUppyPluginContext(props)

  return function (name) {
    setPlugins(list => (list.includes(name) ? list : [...list, name]))

    return () => {
      setPlugins(list => list.filter(item => item !== name))
    }
  }
}

export default function FileUploaderProvider(props) {
  let { onChange, reset, restrictions, onBeforeFileAdded, onBeforeUpload } =
    props
  let triggerOnChangeRef = useRef()
  let [plugins, setPlugins] = useState([])

  let uppy = useMemo(
    () =>
      new Uppy({
        locale: {
          strings: {
            myDevice: 'My Computer',
            browseFiles: 'select files',
            dropPasteFiles: 'Drag and drop files here or %{browse} from drive',
            dropPasteImportFiles:
              props.dropPasteImportFilesMessage ||
              "Drag and drop files here, %{browse} from your computer or import from patient's documents:",
          },
        },
      })
        .once('error', error => {
          captureError(error, { type: 'uppy/error' })
        })
        .once('upload-error', (file, error, response) => {
          captureError(error, { type: 'uppy/upload-error', file, response })
        }),
    [props.dropPasteImportFilesMessage]
  )

  useEffect(() => {
    return () => {
      uppy.close({ reason: 'unmount' })
    }
  }, [uppy])

  // TODO: check why we need reset or if would it be enough to rebuild Uppy
  useEffect(() => {
    if (!uppy || !reset) return

    uppy.cancelAll()
  }, [uppy, reset])

  useEffect(() => {
    if (!uppy) return

    if (triggerOnChangeRef.current) {
      uppy.off('file-added', triggerOnChangeRef.current)
      uppy.off('file-removed', triggerOnChangeRef.current)
      uppy.off('upload-success', triggerOnChangeRef.current)
    }
    triggerOnChangeRef.current = () => onChange && onChange(uppy.getFiles())
    uppy.on('file-added', triggerOnChangeRef.current)
    uppy.on('file-removed', triggerOnChangeRef.current)
    uppy.on('upload-success', triggerOnChangeRef.current)
  }, [uppy, onChange])

  useEffect(() => {
    if (!uppy) return

    uppy.setOptions({
      autoProceed: props.autoProceed || false,
      restrictions: {
        minFileSize: restrictions?.minFileSize || null,
        maxFileSize: restrictions?.maxFileSize || null,
        maxTotalFileSize: restrictions?.maxTotalFileSize || null,
        maxNumberOfFiles: restrictions?.maxNumberOfFiles || null,
        minNumberOfFiles: restrictions?.minNumberOfFiles || null,
        allowedFileTypes: restrictions?.allowedFileTypes || null,
      },
      onBeforeFileAdded:
        typeof onBeforeFileAdded === 'function'
          ? (currentFile, files) => {
              let result = onBeforeFileAdded(currentFile, files)
              if (!result) return false

              if (result.notification) {
                let { message, type, duration } = result.notification
                uppy.info(message, type, duration)
              }
              return result.status
            }
          : currentFile => currentFile,
      onBeforeUpload:
        typeof onBeforeUpload === 'function'
          ? files => {
              let result = onBeforeUpload(files)
              if (!result) return false

              if (result.notification) {
                let { message, type, duration } = result.notification
                uppy.info(message, type, duration)
              }
              return result.status
            }
          : files => files,
    })
  }, [uppy, restrictions, onBeforeFileAdded, onBeforeUpload, props.autoProceed])

  // this is needed because the DataProvider context needs to be attached at the app
  // level flow-wise to be consumed properly by the app's children
  let [viewPath] = getParentView(props.viewPath)

  return (
    <DataProvider
      context={props.context || DEFAULT_CONTEXT}
      value={uppy}
      viewPath={viewPath}
    >
      <DataProvider
        context={`${props.context || DEFAULT_CONTEXT}_plugins`}
        value={[plugins, setPlugins]}
        viewPath={viewPath}
      >
        {typeof props.children === 'function'
          ? props.children({ ...props, children: undefined })
          : props.children}
      </DataProvider>
    </DataProvider>
  )
}
