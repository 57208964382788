// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Address/Addresses/Content/Address/Edit/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Address_Addresses_Content_Address_Edit_Content(
  $id: numeric!
  $address_line1: String!
  $city: String!
  $state: String!
  $zip: String!
) {
  update_vaxiom_contact_postal_addresses_by_pk(
    pk_columns: { id: $id }
    _set: {
      address_line1: $address_line1
      city: $city
      state: $state
      zip: $zip
    }
  ) {
    id
  }
}

`