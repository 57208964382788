import { useDataFormat } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_ids = useDataFormat({
    context: 'payment_plan',
    path: 'payors',
    format: payors => payors.map(payor => payor.person_id).filter(Boolean),
    viewPath: props.viewPath,
  })

  return {
    variables: {
      person_ids,
    },
    pause: !person_ids,
  }
}
