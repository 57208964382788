// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/NoteTemplates/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding_NoteTemplates_Location{
  session_user_locations(order_by: { name: asc }) {
    id
    _id
    text: name
  }
}

`