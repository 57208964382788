// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/DentalMonitoring/Empty/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_DentalMonitoring_Empty(
  $patient_id: uuid!
  $doctor_id: uuid!
  $practice_id: String!
) {
  dental_monitoring_patient_send(
    patient_id: $patient_id
    doctor_id: $doctor_id
    practice_id: $practice_id
  ) {
    id
    patient {
      _links {
        public_profile
      }
      monitoring {
        status
        resume_date
        config {
          protocol_title
        }
      }
    }
  }
}

`