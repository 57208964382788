import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let id = useDataValue({
    context: 'dental_monitoring',
    path: 'id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    let response = await executeMutation({
      id,
      quickstart_id: value.quickstart_id,
    })

    if (response.error) {
      notify(notifyError(`Can't start DM treatment`))
    } else {
      notify(notifySuccess(`DM treatment started`))
    }
  }
}
