import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_name = useDataValue({
    context: 'connection_definition',
    path: 'id',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'app',
    path: 'id',
    viewPath: props.viewPath,
  })

  return {
    variables: { app_id, connection_name },
  }
}
