import { useDataValue, useDataChange } from 'Simple/Data.js'
import { numberMoney } from 'Data/format.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import React, { useMemo } from 'react'
import View from './view.js'
import Check from 'DesignSystem/Svgs/Check/view.js'

let columns = [
  {
    Header: 'Location',
    accessor: 'organization.name',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Fee',
    accessor: 'fee',
    Cell: ({ value }) => (value ? numberMoney(value) : null),
  },
  {
    Header: 'Length (weeks)',
    accessor: 'length_in_weeks',
  },
  {
    Header: 'Insurance Code',
    accessor: 'insurance_code.code',
  },
  {
    Header: 'Tx Plan Group',
    accessor: 'tx_plan_group.name',
  },
  {
    Header: 'Category',
    accessor: 'tx_category.name',
  },
  {
    Header: 'Deleted',
    accessor: 'deleted',
    Cell: ({ value }) => (value ? <Check /> : null),
  },
]

export default function Logic(props) {
  let txPlanTemplates = useDataValue({
    context: 'tx_plan_templates',
    viewPath: props.viewPath,
  })

  let selectedChange = useDataChange({
    context: 'selected',
    path: 'tx_plan_template_id',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useMemo(() => {
    return {
      columns,
      data: txPlanTemplates || [],
    }
  }, [txPlanTemplates])

  function onRowClick(row) {
    selectedChange(row.original.id)
    setFlowTo(
      normalizePath(props.viewPath, '../../../../TxPlanTemplate/Content/Edit')
    )
  }

  return (
    <View value={value} onRowClick={onRowClick} viewPath={props.viewPath} />
  )
}
