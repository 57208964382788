import { numberMoney } from 'Data/format.js'
import View from './view.js'
import TysiaButton from 'DesignSystem/TysiaButton/index.js'
import TysiaButtonIcon from 'DesignSystem/TysiaButton/TysiaButtonIcon/index.js'
import TysiaButtonText from 'DesignSystem/TysiaButton/TysiaButtonText/index.js'
import TysiaTag from 'DesignSystem/TysiaTag/index.js'
import TysiaTagText from 'DesignSystem/TysiaTag/TysiaTagText/index.js'
import React, { useMemo } from 'react'
import { useDataSubmit } from 'Simple/Data.js'
import TysiaIconPaid from 'DesignSystem/Svgs/TysiaIconPaid/index.js'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: 'payer_person',
        headerName: 'Payor',
        filter: 'agTextColumnFilter',
        valueGetter: params =>
          `${params.data.payer_person.first_name} ${params.data.payer_person.last_name}`,
      },
      {
        field: 'patient.person',
        headerName: 'Patient',
        filter: 'agTextColumnFilter',
        valueGetter: params =>
          `${params.data.patient.person.first_name} ${params.data.patient.person.last_name}`,
        cellRenderer: params => (
          <PatientLinkCellRenderer
            viewPath={props.viewPath}
            value={params.valueFormatted || params.value}
            patient_id={params.data.patient._id}
          />
        ),
      },
      {
        field: 'location.name',
        headerName: 'Location',
      },
      {
        field: 'due_now_amount',
        headerName: 'Due now amount',
        type: 'numericColumn',
        valueFormatter: params =>
          params.value ? numberMoney(params.value) : '',
      },
      {
        field: 'due_buckets',
        headerName: 'Overdue',
        minWidth: 220,
        cellRenderer: params => (
          <DueBuckets value={params.value} viewPath={props.viewPath} />
        ),
      },
      {
        field: 'pdf',
        headerName: 'PDF',
        cellRenderer: params => (
          <ViewPdfAction statement={params.data} viewPath={props.viewPath} />
        ),
      },
    ],
    [props.viewPath]
  )
  return <View {...props} columnDefs={columnDefs} />
}

function PatientLinkCellRenderer(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <TysiaButton
        viewPath={`${props.viewPath}/TysiaButton`}
        onClick={onClick}
        size="small"
        type="secondary"
        marginTop={0}
        marginRight={4}
        title="Ledger"
        alignSelf="auto"
      >
        {childProps => (
          <TysiaButtonIcon
            {...childProps}
            viewPath={`${props.viewPath}/TysiaButtonIcon`}
          >
            {childProps => (
              <TysiaIconPaid
                {...childProps}
                viewPath={`${props.viewPath}/TysiaIconPaid`}
              />
            )}
          </TysiaButtonIcon>
        )}
      </TysiaButton>
      {props.value}
    </span>
  )

  function onClick() {
    submit({
      type: 'tabs/add',
      focus: false,
      tab: {
        id: props.patient_id,
        type: 'Patient',
        name: props.value,
        patient_id: props.patient_id,
        tab: 'ledger',
        viewPath: '/App/Account/Content/Patients',
      },
    })
  }
}

function ViewPdfAction(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return (
    <TysiaButton
      marginTop={6}
      marginLeft={4}
      viewPath={props.viewPath}
      type="secondary"
      size="small"
      onClick={event => {
        event.stopPropagation()
        submit({ type: 'statements/view', statement: props.statement })
      }}
    >
      {childProps => (
        <TysiaButtonText
          {...childProps}
          text="View PDF"
          viewPath={props.viewPath}
        />
      )}
    </TysiaButton>
  )
}

function DueBuckets(props) {
  return (
    <div className="views-block" style={{ flexDirection: 'row' }}>
      {props.value.map(item => (
        <TysiaTag
          key={item}
          marginLeft={2}
          marginTop={10}
          viewPath={`${props.viewPath}/TysiaTag`}
          type="grey"
          style="outline"
        >
          {childProps => (
            <TysiaTagText
              {...childProps}
              viewPath={`${props.viewPath}/TysiaTagText`}
              text={item}
            />
          )}
        </TysiaTag>
      ))}
    </div>
  )
}
