import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    // showing all the patients the users associated with this thread are allowed to manage
    // this includes the other patients they a person is responsible for
    // or a patient linked to an (external) professional
    return data.users.flatMap(user => {
      let patientRelationships =
        user?.vaxiom_person?.from_person_relationships || []
      let dependants = patientRelationships
        .filter(relationship => relationship?.to_person_record?.patient)
        .map(relationship => relationship.to_person_record)
        .map(person => ({
          id: person.patient.id,
          person_id: person.id,
          name: `${person.first_name} ${person.last_name}`,
        }))
      let professionalRelationships =
        user?.vaxiom_person?.other_professional_relationships || []
      let patients = professionalRelationships
        .filter(
          relationship =>
            relationship?.professional_relationship?.patient?.person
        )
        .map(relationship => relationship.professional_relationship.patient)
        .map(patient => ({
          id: patient.id,
          person_id: patient.person.id,
          name: `${patient.person.first_name} ${patient.person.last_name}`,
        }))
      // add the owner of the thread if they are a patient too
      let patient = user?.vaxiom_person?.patient
        ? {
            id: user?.vaxiom_person?.patient.id,
            person_id: user?.vaxiom_person?.id,
            name: `${user?.vaxiom_person?.first_name} ${user?.vaxiom_person?.last_name}`,
          }
        : null

      return [patient, ...dependants, ...patients].filter(Boolean)
    })
  }, [data])
}
