import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  let selected = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected',
  })

  let changeSelected = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'change_status': {
        if (!selected?.developers?.id || !selected?.developers?.status) {
          return
        }
        let mutationResponse = await executeMutation({
          id: selected.developers.id,
          status: selected.developers.status,
        })
        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        changeSelected(next => {
          next.developers = {
            id: null,
            status: null,
          }
        })
        setFlowTo(normalizePath(props.viewPath, 'Content/ChangeStatus/No'))
        notify(notifySuccess('Status changed!'))

        return
      }
      case 'cancel': {
        changeSelected(next => {
          next.developers = {
            id: null,
            status: null,
          }
        })
        setFlowTo(normalizePath(props.viewPath, 'Content/ChangeStatus/No'))
      }
    }
  }
}
