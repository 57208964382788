// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Comms/mutationInApp.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Comms($person_id: numeric!) {
  thread: comms_threads_in_app_create(person_id: $person_id) {
    id
  }
}

`