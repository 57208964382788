import { useDataValue, useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import add_note from './add_note_mutation.graphql.js'
import update_note from './update_mutation.graphql.js'
import delete_note from './delete_mutation.graphql.js'

export function useOnSave(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })

  let user_id = useDataValue({
    context: 'auth',
    path: 'access_token_data.vaxiom_user_id',
    viewPath: props.viewPath,
  })

  let selected_note = useDataValue({
    viewPath: props.viewPath,
    context: 'selected_note',
  })
  let note_change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected_note',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeUpdateNoteMutation] = useMutation(update_note)
  let [, executeInsertNoteMutation] = useMutation(add_note)

  return async function onClick() {
    if (selected_note.id) {
      let mutationResponse = await executeUpdateNoteMutation({
        note_id: selected_note.id,
        new_note: selected_note.note,
        alert: selected_note.alert,
      })
      if (mutationResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
        return
      } else {
        notify(notifySuccess('Note updated!'))
      }
    } else {
      let mutationResponse = await executeInsertNoteMutation({
        note: selected_note.note,
        target_id: patient_id,
        target_type: selected_note.target_type,
        alert: selected_note.alert,
        user_id,
      })
      if (mutationResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
        return
      } else {
        notify(notifySuccess('Note created!'))
      }
    }
    // TODO: we shouldn't need to reset anything
    note_change({ note: '' })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}

export function useOnDelete(props) {
  let selected_note = useDataValue({
    viewPath: props.viewPath,
    context: 'selected_note',
  })
  let note_change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected_note',
  })

  let [, notify] = useNotifications()
  let [, execDeleteMutation] = useMutation(delete_note)

  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onClick() {
    if (!window.confirm('Are you sure you want to delete this note?')) return

    if (selected_note.id) {
      let mutationResponse = await execDeleteMutation({
        note_id: selected_note.id,
      })
      if (mutationResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
      } else {
        notify(notifySuccess('Note deleted!'))
      }
    }
    note_change({ note: '' })
    setFlowTo(normalizePath(props.viewPath, '../../../../No'))
  }
}

export function useOnBack(props) {
  let note_change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected_note',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    note_change({ note: '' })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
