import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(image_series_type => ({
      id: image_series_type.id,
      text: image_series_type.type.name,
    }))
  }, [data])
}
