import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation from './mutationInApp.graphql.js'

export default function useDataOnActionInApp(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person',
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      person_id: person.id,
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem starting this thread.`))
    }

    submit({
      type: 'tab/openCommsThread',
      thread_id: mutationResponse.data.thread.id,
      is_secure: true,
    })

    // close dropdown
    setFlowTo(normalizePath(props.viewPath, 'Content/No'))
  }
}
