import { useMemo } from 'react'
import { useDataFormat, useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let current_index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let other_payor_person_id = useDataFormat({
    context: 'payment_plan',
    format: payment_plan => {
      let other_payor = payment_plan.payors.find(
        (_, index) => current_index !== index
      )
      return other_payor?.person_id
    },
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let result
    if (data.vaxiom_persons_search?.length) {
      result = data.vaxiom_persons_search
        .map(item => {
          let is_patient = !!item.person.patient
          let is_responsible_party =
            item.person.from_person_relationships?.length > 0
          let is_employee = item.person.employment_contracts?.length > 0
          let is_external_professional =
            item.person.other_professional_relationships?.length > 0

          // TODO: update vaxiom_persons_search to support searching only for specific types of users (patients or responsible parties in this case, not employees)
          if (is_employee || is_external_professional) return null

          return {
            ...item.person,
            type: is_responsible_party
              ? item.person.from_person_relationships[0].type
              : is_patient
              ? 'Patient'
              : 'Responsible',
            role: is_responsible_party
              ? item.person.from_person_relationships[0].role
              : null,
            text: `${item.person.first_name} ${item.person.last_name}`,
          }
        })
        .filter(Boolean)
    } else {
      let patient_person = data.patient_person
      result = [
        {
          ...patient_person,
          type: 'Patient',
          text: `${patient_person.first_name} ${patient_person.last_name}`,
        },
        ...data.vaxiom_relationships.map(item => ({
          ...item.from_person_record,
          role: item.role,
          type: item.type,
          text: `${item.from_person_record.first_name} ${item.from_person_record.last_name}`,
        })),
      ]
    }

    if (
      data.selected_person &&
      result.every(item => item.id !== data.selected_person.id)
    ) {
      let is_patient = !!data.selected_person.patient
      let is_responsible_party =
        data.selected_person.from_person_relationships?.length > 0
      result = [
        {
          ...data.selected_person,
          type: is_responsible_party
            ? data.selected_person.from_person_relationships[0].type
            : is_patient
            ? 'Patient'
            : 'Responsible',
          role: is_responsible_party
            ? data.selected_person.from_person_relationships[0].role
            : null,
          text: `${data.selected_person.first_name} ${data.selected_person.last_name}`,
        },
        ...result,
      ]
    }

    // prevent selecting the other payor
    if (other_payor_person_id) {
      result = result.filter(item => item.id !== other_payor_person_id)
    }

    return result
  }, [data, other_payor_person_id])
}
