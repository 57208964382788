import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let location = locations.find(item => item._id === value)

    change(next => {
      next.selected.location_id = location?._id
      next.selected.location_numeric_id = location?.id
      next.selected.automation_hub_enabled = false
    })
  }
}
