import { DataProvider, useSetFlowToBasedOnData } from 'Simple/Data'
import useFetch from 'Data/useFetch'
import useDataTransform from './useDataTransform.js'
import useDataConfiguration from './useDataConfiguration.js'
import React from 'react'
import View from './view.js'
import useDataOnSubmit from './useDataOnSubmit.js'

/** @type {import('Simple/types.js').Data} */
export default function PatientsData(props) {
  let configuration = useDataConfiguration(props)
  let { data: rdata, error } = useFetch(configuration.variables.url, {
    cache: 'reload',
  })
  let data = useDataTransform(props, rdata)
  let onSubmit = useDataOnSubmit(props)

  useSetFlowToBasedOnData({
    data,
    fetching: !data,
    error,
    pause: configuration.pause,
    viewPath: props.viewPath,
  })

  return (
    <DataProvider
      context="patients"
      value={data}
      viewPath={props.viewPath}
      onSubmit={onSubmit}
    >
      <View {...props} />
    </DataProvider>
  )
}
