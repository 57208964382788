import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let thread_patients = useDataValue({
    context: 'thread_patients',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'patient_documents',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let setSelectedPatientId = useDataChange({
    context: 'patient_documents',
    path: 'patient_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (patient_id || !thread_patients?.length) {
      return
    }
    setSelectedPatientId(thread_patients[0].id)
  }, [thread_patients, patient_id]) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
