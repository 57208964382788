// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Comparison/Content/Content/Sections/Section/ImageTitle/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Comparison_Content_Content_Sections_Section_ImageTitle_Content($id: numeric!) {
  vaxiom_patient_images_by_pk(id: $id) {
    id
    file_key
    type_key
    slot_key
    imageseries {
      id
      name
      series_date
    }
  }
}

`