import View from './view.js'
import React, { useMemo } from 'react'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'id',
      },
      {
        field: 'rtype',
        headerName: 'rtype',
      },
      {
        field: 'status',
        headerName: 'status',
      },
      {
        field: 'human_readable_id',
        headerName: 'human_readable_id',
      },
      {
        field: 'balance_amount',
        headerName: 'balance_amount',
      },
      {
        field: 'due_amount',
        headerName: 'due_amount',
      },
      {
        field: 'due_date',
        headerName: 'due_date',
      },
      {
        field: 'realization_date',
        headerName: 'realization_date',
      },
      {
        field: 'autopay_date',
        headerName: 'autopay_date',
      },
      // {
      //   field: 'payer',
      //   headerName: 'payer',
      //   valueGetter: params => {
      //     let { payer_person } = params.data.payment_account?.person_payment_account || { first_name: 'NA', last_name: 'NA' }
      //     return `${payer_person.first_name} ${payer_person.last_name}`
      //   }
      // },
    ],
    []
  )
  return <View {...props} columnDefs={columnDefs} />
}
