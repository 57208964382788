// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/Content/Content/Launchers/Content/AlignActions/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps_Content_Content_Launchers_Content_AlignActions($patient_id: uuid!) {
  apps_connection_patients(
    where: {
      patient_id: { _eq: $patient_id }
      # TODO: ideally it should also check the app_id
      connection: { name: { _eq: "ids" } }
    }
  ) {
    id
    connection {
      id
      name
      app_id
    }
  }

  apps_connections(where: { name: { _eq: "ids" } }) {
    id
    name
    app_id
  }
}

`