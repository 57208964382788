import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(value) {
    switch (value) {
      case 'EDIT':
        return editImageSeries()
      case 'DELETE':
        return deleteImageSeries()
      default:
        return
    }
  }

  function deleteImageSeries() {
    setFlowTo(normalizePath(props.viewPath, 'Delete/Content'))
  }

  function editImageSeries() {
    setFlowTo(normalizePath(props.viewPath, 'Edit/Content'))
  }
}
