// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content($location_id: uuid!, $data: jsonb!) {
  insert_user_location_preferences_one(
    object: { location_id: $location_id, compact_table_presets: $data }
    on_conflict: {
      update_columns: compact_table_presets
      constraint: user_location_preferences_pkey
    }
  ) {
    user_id
    location_id
    compact_table_presets
  }
}

`