import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let app_id = useDataValue({
    context: 'app',
    path: 'id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'connection_definition',
    path: 'id',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      parent_company_id,
      location_id,
      connection_name,
      app_id,
    },
    pause: !parent_company_id || !location_id || !connection_name || !app_id,
  }
}
