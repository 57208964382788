// this is an autogenerated file from DesignSystem/SendPatientSignatureRequestMessage/mutationInApp.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_SendPatientSignatureRequestMessage($person_id: numeric!) {
  thread: comms_threads_in_app_create(person_id: $person_id) {
    id
  }
}

`