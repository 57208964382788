import { useClient } from 'Data/Api.js'
import queryDocument from './query-document.graphql.js'
import { useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useListItemDataOnSubmit} */
export default function useListItemDataOnSubmit(props, data) {
  let client = useClient()
  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, args, change }) {
    switch (args.type) {
      case 'documentSigned': {
        let {
          data: { vaxiom_document_tree_nodes_by_pk: document },
        } = await client
          .query(
            queryDocument,
            { id: args.document_id },
            { requestPolicy: 'network-only' }
          )
          .toPromise()

        change(next => {
          next.refresh = Date.now()
          next.selected_document_id = null
          next.parent_id = document.parent?.id || null
          next.directories = [next.directories[0], document.parent].filter(
            Boolean
          )
        })

        // crappy way to force it to reload
        setTimeout(() => {
          change(next => {
            next.selected_document_id = document.id
          })
        }, 250)
        break
      }

      default: {
        await submitTab(args)
      }
    }
  }
}
