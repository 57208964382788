// this is an autogenerated file from DesignSystem/CommsThread/Content/EventsTimeline/Content/Event/Types/Internal/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_EventsTimeline_Content_Event_Types_Internal($id: uuid!) {
  comms_events_internal_by_pk(id: $id) {
    id
    message
    is_secure
    created_at
    user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`