import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    // we may need to handle multiple connections with  data?.apps_connections in the future and give a choice.
    return (
      data?.apps_connection_patients?.[0]?.connection ||
      data?.apps_connections?.[0] ||
      data
    )
  }, [data])
}
