import { useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (!value) {
        next.location_id = null
      }

      next.division_id = value
    })
  }
}
