// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/Steps/Rulesets/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_Steps_Rulesets($parent_company_id: uuid!) {
  treatments_allowed_request_steps(
    where: { parent_id: { _eq: $parent_company_id } }
  ) {
    id
    organization_id
    parent_id
    division_id
    location_id
    steps
  }
}

`