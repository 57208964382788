import React, { useEffect } from 'react'
import { useDataChange } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let change = useDataChange({
    context: 'selected',
    path: 'status',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    // the requirements task is already completed by default (no user interaction needed) so that the save button is enabled
    change('completed')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore change

  return <View {...props} />
}
