import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .filter(tx_card => tx_card?.txs?.length > 0)
      .map(tx_card => ({
        ...tx_card,
        txs: tx_card?.txs?.[0],
      }))
  }, [data])
}
