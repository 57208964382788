// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Content/Payors/Payor/Person/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Content_Payors_Payor_Person_Content(
  $person_id: numeric!
  $selected_person_id: numeric!
  $search_term: String!
  $parent_company_id: uuid!
  $with_search: Boolean!
  $with_selected_payor: Boolean!
) {
  vaxiom_relationships(
    where: { to_person: { _eq: $person_id } }
    order_by: { from_person_record: { search_name: asc_nulls_last } }
    limit: 25
  ) @skip(if: $with_search) {
    id
    role
    type
    from_person_record {
      id
      first_name
      last_name
      gender
      birth_date
    }
  }
  patient_person: vaxiom_persons_by_pk(id: $person_id) @skip(if: $with_search) {
    id
    first_name
    last_name
    gender
    birth_date
  }
  selected_person: vaxiom_persons_by_pk(id: $selected_person_id)
    @include(if: $with_selected_payor) {
    id
    first_name
    last_name
    gender
    birth_date
    patient {
      id
      _id
      human_readable_id
      legacy_id
    }
    from_person_relationships(where: { to_person: { _eq: $person_id } }) {
      id
      role
      type
    }
  }
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: true
  ) @include(if: $with_search) {
    person_id
    person: vaxiom_person {
      id
      first_name
      last_name
      greeting
      gender
      birth_date
      patient {
        id
        _id
        human_readable_id
        legacy_id
      }
      from_person_relationships(where: { to_person: { _eq: $person_id } }) {
        id
        role
        type
      }
      employment_contracts(
        where: {
          employment_end_date: { _is_null: true }
          deleted: { _eq: false }
        }
      ) {
        id
      }
      other_professional_relationships {
        id
      }
    }
  }
}

`