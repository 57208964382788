import { useMemo } from 'react'
import { useDataValueOnce } from 'Logic/FlowShortcuts.js'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let flowShortcutsValue = useDataValueOnce({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
  })

  let locationId = useDataValue({
    context: 'global',
    path: 'current_location.id', // ok to be id and not _id
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      location_id: flowShortcutsValue?.locationId || locationId,
    }
  }, [data, flowShortcutsValue, locationId])
}
