import View from './view.js'
import { useEffect, useState } from 'react'
import { useDataIsSubmitting, useDataValue } from 'Simple/Data'

export default function Logic(props) {
  let [isDisabled, setDisable] = useState(true)
  let account_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'account_id',
  })
  let original_account_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'originalValue.account_id',
  })
  let doctor_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'doctor_id',
  })
  let original_doctor_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'originalValue.doctor_id',
  })

  let isSubmitting = useDataIsSubmitting({
    context: 'selected',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    let disabled =
      (account_id === original_account_id &&
        doctor_id === original_doctor_id) ||
      !account_id ||
      !doctor_id ||
      isSubmitting

    setDisable(disabled)
  }, [
    account_id,
    doctor_id,
    original_doctor_id,
    original_account_id,
    isSubmitting,
  ])

  return <View {...props} disabled={isDisabled} />
}
