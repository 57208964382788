import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []
    return data.map(item => {
      let parent_id = null
      let division_id = null
      let location_id = null

      switch (item.organization.level) {
        case 'parent': {
          parent_id = item.organization.id
          break
        }
        case 'div': {
          parent_id = item.organization.parent.id
          division_id = item.organization.id
          break
        }
        case 'location': {
          parent_id = item.organization.parent.parent.id
          division_id = item.organization.parent.id
          location_id = item.organization.id
          break
        }
        default: {
          break
        }
      }

      return {
        ...item,
        parent_id,
        division_id,
        location_id,
        message_value: item.message_value === 'true',
      }
    })
  }, [data])
}
