import { useDataValue } from 'Simple/Data.js'
import { useMutation, useClient } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import { v4 as uuid } from 'uuid'
import { useAwsCredentials, useAwsS3Client } from 'Data/s3'
import { CopyObjectCommand } from '@aws-sdk/client-s3'
import query from './query.graphql.js'

let PATIENT_IMAGE_PREFIX = 'patientimg'
let PATIENT_ORIGINAL_IMAGE_PREFIX = 'orig_patientimg'

export function useOnClick(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let s3 = useAwsS3Client(props)
  let getCredentials = useAwsCredentials(props)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let client = useClient()
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let patient_image = useDataValue({
    context: 'patient_image',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    try {
      let file_key = uuid()
      let destination_key = `${PATIENT_IMAGE_PREFIX}/${file_key}`
      let credentials = await getCredentials()

      await copyObject({
        Bucket: credentials.legacy_storage_bucket_name,
        Key: destination_key,
        CopySource: `${credentials.legacy_storage_bucket_name}/${PATIENT_ORIGINAL_IMAGE_PREFIX}/${patient_image.original_file_key}`,
      })

      let mutationResponse = await executeMutation({
        id: patient_image.id,
        file_key,
      })

      if (mutationResponse.error) {
        throw new Error(mutationResponse.error)
      }

      if (patient_image.slot_key === 'facialFrontalSmiling') {
        try {
          await client
            .query(
              query,
              {
                patient_id,
              },
              {
                requestPolicy: 'network-only',
              }
            )
            .toPromise()
        } catch (e) {}
      }

      notify(notifySuccess(`The image was successfully reset to the original`))
    } catch (err) {
      notify(notifyError(`Couldn't reset the image. Please try again.`))
    } finally {
      setFlowTo(normalizePath(props.viewPath, '../../No'))
    }
  }

  async function copyObject(params) {
    await (await s3()).send(new CopyObjectCommand(params))
  }
}
