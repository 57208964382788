// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/AutomationsList/EnableAutomationHub/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations_AutomationsList_EnableAutomationHub($organization_id: numeric!) {
  insert_vaxiom_application_properties_one(
    object: {
      organization_id: $organization_id
      message_key: "automation.hub.enabled"
      message_value: "true"
    }
  ) {
    id: organization_id
    message_value
  }
}

`