import { PutObjectCommand } from '@aws-sdk/client-s3'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import { getS3ObjectUrl, useAwsCredentials, useAwsS3Client } from 'Data/s3.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications.js'
import { v4 as uuid } from 'uuid'
import { useDataValue } from 'Simple/Data.js'

export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let getAwsCredentials = useAwsCredentials(props)
  let getS3Client = useAwsS3Client(props)
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ args }) {
    try {
      let awsCredentials = await getAwsCredentials()
      let s3 = await getS3Client()

      let key = `companies/${parent_company_id}/onboarding/inputs/${Date.now()}-${uuid()}.json`
      let bucket = awsCredentials.storage_bucket_name

      await s3.send(
        new PutObjectCommand({
          Key: key,
          Bucket: bucket,
          Body: JSON.stringify(args.data),
          ContentType: 'application/json',
        })
      )

      let mutationResponse = await executeMutation({
        type: args.type,
        data_url: getS3ObjectUrl({
          bucket,
          key,
          region: awsCredentials.region,
        }),
      })
      if (mutationResponse.error) {
        notify(notifyError(mutationResponse.error.message))
      } else {
        notify(notifySuccess(`Onboarding input log saved`))
      }
    } catch (error) {
      notify(notifyError(error.message))
    }
  }
}
