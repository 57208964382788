import { DataProvider } from 'Simple/Data.js'
import React from 'react'
import View from './view.js'

let value = {
  patient_id: null,
  parent_id: null,
  selected_image_series: null,
  preview_file: null,
}

export default function Logic(props) {
  return (
    <DataProvider
      context="patient_documents"
      value={value}
      viewPath={props.viewPath}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )
}
