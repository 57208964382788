import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'new_patient',
    viewPath: props.viewPath,
  })
  let value = useDataValue({
    context: 'new_patient_referral',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.referrals = next.referrals.filter(v => v._id != value._id)
    })
  }
}
