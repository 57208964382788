import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  let value = useDataValue({
    context: 'day',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'update_installment_date', value })
  }
}
