import { useDataValue } from 'Simple/Data.js'
import useIsHovered from 'Simple/hooks/useIsHovered.js'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })
  let tabId = useDataValue({
    context: 'tab',
    path: 'id',
    viewPath: props.viewPath,
  })

  let activeTabId = useDataValue({
    context: 'global',
    path: 'tab_id',
    viewPath: props.viewPath,
  })

  return (
    <View
      {...props}
      selected={tabId === activeTabId}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
    />
  )
}
