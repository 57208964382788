import { useDataChange } from 'Simple/Data'
import formatISO from 'date-fns/formatISO'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'image_serie_form',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.treatment_id = value
      next.appointment_id = null
      next.date = formatISO(new Date(), { representation: 'date' })
    })
  }
}
