import { useDataSubmit, useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let patient_option = useDataValue({
    context: 'appointment_overlay',
    path: 'patient_option',
    viewPath: props.viewPath,
  })
  // patient_id with which overlay was opened with
  let patient_id = useDataValue({
    context: 'overlay',
    path: 'patient_id',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (patient_id || patient_option !== 'new') return
    let defaultTemplate = value?.find(item => item.is_default === true)
    if (!defaultTemplate) return

    submit({
      type: 'updateTemplate',
      template_id: defaultTemplate.template_id,
      appointment_type_id: defaultTemplate?.id ?? null,
    })
  }
}
