import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let data = useDataValue({
    context: 'resource',
    path: 'captures',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'resource',
    path: 'captures',
    viewPath: props.viewPath,
  })
  return async function onClick() {
    let [canvas] = document.getElementsByTagName('canvas')
    let video = document.querySelector('video')

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas
      .getContext('2d')
      .drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

    let imageData = canvas.toDataURL()
    change([...data, { id: data.length, url: imageData }])
  }
}
