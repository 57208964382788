import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import formatISO from 'date-fns/formatISO'

import View from './view.js'

export default function Logic(props) {
  let treatments = useDataValue({
    context: 'treatments',
    viewPath: props.viewPath,
  })
  let treatment_id = useDataValue({
    context: 'image_serie_form',
    path: 'treatment_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'image_serie_form',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (treatment_id || treatments.length < 2) return

    change(next => {
      next.treatment_id = treatments[1].id // select the first treatment (first option is "No treatment")
      next.appointment_id = null
      next.date = formatISO(new Date(), { representation: 'date' })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
