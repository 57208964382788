import React from 'react'
import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'manual_action_executions',
    viewPath: props.viewPath,
  })
  let status = useDataValue({
    context: 'selected',
    path: 'status',
    viewPath: props.viewPath,
  })
  return <View isDisabled={isSubmitting || status !== 'completed'} {...props} />
}
