import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !data.length) return data

    return data
      .flatMap(item => {
        switch (item.type) {
          case 'boolean': {
            return [
              {
                id: `${item.id}--true`,
                question_id: item.id,
                question_type: item.type,
                value: true,
                text: `${item.name}: Yes (${item.organization.name})`,
              },
              {
                id: `${item.id}--false`,
                question_id: item.id,
                question_type: item.type,
                value: false,
                text: `${item.name}: No (${item.organization.name})`,
              },
            ]
          }

          case 'choice':
          case 'multichoice': {
            return item.options.map(oitem => ({
              id: `${item.id}--${oitem}`,
              question_id: item.id,
              question_type: item.type,
              value: oitem,
              text: `${item.name}: ${oitem} (${item.organization.name})`,
            }))
          }

          default: {
            return null
          }
        }
      })
      .filter(Boolean)
  }, [data])
}
