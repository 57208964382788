import { toCapitalisedCamelCase } from 'Data/format.js'

export function getView(requirement) {
  if (!requirement) return 'No'

  if (requirement.type === 'internal') {
    return toCapitalisedCamelCase(requirement.id)
  } else {
    return 'ExternalRequirement'
  }
}
