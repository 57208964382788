import React, { useEffect } from 'react'
import View from './view.js'
import { useDataChange } from 'Simple/Data.js'

export default function Logic(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    change(next => {
      next.selected.images.refetch = Date.now() // refetch patient images
    })
  }, []) // eslint-disable-line

  return <View {...props} />
}
