import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifyInvalid,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications'
import mutation from './mutation.graphql.js'
import { getUnixTime } from 'Data/format'
import { dateFuture } from 'Data/validate'

export default function useDataOnSubmit(props, data) {
  let id = useDataValue({
    context: 'dental_monitoring',
    path: 'id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (isInvalid(value, notify)) return true
    if (!window.confirm('Are you sure?')) return

    let response = await executeMutation({
      id,
      resume_date: getUnixTime(value.resume_date),
    })

    if (response.error) {
      notify(notifyError(`Can't resume DM treatment`))
    } else {
      notify(notifySuccess(`Resumed DM treatment`))
    }
  }

  function isInvalid(value, notify) {
    let list = [!dateFuture(value.resume_date) && 'resume date'].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
