// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/TxPlanNotes/Content/Content/update_tx_plan_notes.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_TxPlanNotes_Content_Content($id: numeric!, $notes: String) {
  update_vaxiom_tx_plan_notes_by_pk(
    pk_columns: { id: $id }
    _set: { note: $notes }
  ) {
    id
    note
  }
}

`