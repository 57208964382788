import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })
  let custom_resource_types = useDataValue({
    context: 'custom_resource_types',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.custom_resource_type = value
      if (value) {
        next.app_id = custom_resource_types.find(
          item => item.id === value
        ).app_id
      }
    })
  }
}
