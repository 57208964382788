import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'image',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    return submit({ type: 'reset' })
  }
}
