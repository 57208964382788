import { useDataValue } from 'Simple/Data.js'
import React, { useMemo } from 'react'
import View from './view.js'

// Twilio supports up to 10 attachments, as long as the total size of
// the message body text and all attachments is less than 5 MB.
// To send files that are not jpeg, png or gif images, it is
// recommended using attachments no larger than 600KB as we don't
// know the MMS-carrier of the receiver

let MAX_FILE_SIZE_NOT_IMAGE_MMS = 1024 * 600 // 600KB
let MAX_FILE_SIZE_NOT_IMAGE_MMS_ERROR = `Non-image files must be no larger than ${
  MAX_FILE_SIZE_NOT_IMAGE_MMS / 1024
} KB`
let MAX_NUMBER_OF_FILES_MMS = 10
let MAX_MMS_SIZE = 1024 * 1024 * 5 // 5MB
let ALLOWED_FILE_TYPES_MMS = [
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/png',
  'video/*',
  '.3gp',
]

let MAX_FILE_SIZE_IN_APP = 1024 * 1024 * 50 // 50MB
let ALLOWED_FILE_TYPES_IN_APP = [
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
let restrictions = {
  maxFileSize: MAX_FILE_SIZE_IN_APP,
  allowedFileTypes: ALLOWED_FILE_TYPES_IN_APP,
}

export default function Logic(props) {
  let thread_type = useDataValue({
    context: 'thread',
    path: 'type',
    viewPath: props.viewPath,
  })
  let message = useDataValue({
    context: 'message_compose',
    path: 'message',
    viewPath: props.viewPath,
  })

  let fileUploaderData = useMemo(() => {
    switch (thread_type) {
      case 'sms':
        return getSmsRestrictions(message)
      case 'in_app':
        return getInAppRestrictions()
      default:
        return {}
    }
  }, [message, thread_type])

  return (
    <View
      {...props}
      restrictions={fileUploaderData.restrictions}
      restrictionNote={fileUploaderData.restrictionNote}
      onBeforeFileAdded={fileUploaderData.onBeforeFileAdded}
    />
  )

  function getSmsRestrictions(message) {
    return {
      restrictions: {
        maxNumberOfFiles: MAX_NUMBER_OF_FILES_MMS,
        maxTotalFileSize: MAX_MMS_SIZE - (message?.length || 0),
        allowedFileTypes: ALLOWED_FILE_TYPES_MMS,
      },
      restrictionNote:
        'Keep in mind that sending SMS messages is not secure. To send documents, images, and other potentially sensitive data switch to secure message from below the message input field in the main thread.',
      onBeforeFileAdded: (currentFile, files) => {
        if (
          !/image/i.test(currentFile.type) &&
          currentFile.size > MAX_FILE_SIZE_NOT_IMAGE_MMS
        ) {
          return {
            status: false,
            notification: {
              message: MAX_FILE_SIZE_NOT_IMAGE_MMS_ERROR,
              type: 'error',
            },
          }
        }

        return {
          status: true,
        }
      },
    }
  }

  function getInAppRestrictions() {
    return {
      restrictions,
      restrictionNote: '',
      onBeforeFileAdded: () => ({
        status: true,
      }),
    }
  }
}
