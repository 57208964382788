import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useClient } from 'Data/Api'
import query from './query.graphql.js'
import { truncate } from 'Data/aggregate'

export function useOnClick(props) {
  let request = useDataValue({
    context: 'request',
    viewPath: props.viewPath,
  })

  let value = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let client = useClient()

  return async function onClick() {
    let { data, error } = await client
      .query(query, {
        request_id: request.id,
      })
      .toPromise()

    if (error) {
      return
    }

    if (
      data.ceph_get_edit_analysis_url.edit_analysis_url !==
        value.edit_analysis_url ||
      value.request_id !== request.id
    ) {
      change(next => {
        next.edit_analysis_url =
          data.ceph_get_edit_analysis_url.edit_analysis_url
        next.request_id = request.id
      })
    }

    setFlowTo(
      normalizePath(props.viewPath, '../../CalibrationToolModal/Content')
    )
  }
}

export function truncateTitle(str) {
  return truncate(str)
}
