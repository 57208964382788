// this is an autogenerated file from App/Account/Chairs/Content/Filter/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Chairs_Content_Filter_Content($chair_ids: jsonb!) {
  insert_user_preferences_one(
    object: { chair_ids: $chair_ids }
    on_conflict: {
      constraint: user_preferences_pkey
      update_columns: [chair_ids]
    }
  ) {
    user_id
    current_location_id
    chair_ids
  }
}

`