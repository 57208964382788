import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let sync_id = useDataValue({
    context: 'tab',
    path: 'sync_id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'replace': {
        change(next => {
          let record = next.patients.find(
            p => p.external?.patient?.id === args.external_id
          )
          record.external.type = 'match'
          record.greyfinch = {
            patient: args.selected_patient,
            type: 'match',
          }
          record.selected = true
        })
        sortPatients()
        break
      }
      case 'remove': {
        if (!args.external_id) {
          return
        }
        change(next => {
          let record = next.patients.find(
            p => p.external?.patient?.id === args.external_id
          )
          record.greyfinch = {
            patient: null,
            type: 'noMatch',
          }
          record.external.type = 'noMatch'
          record.selected = false
        })
        sortPatients()
        break
      }
      case 'complete-sync': {
        // get selected patients and do the sync
        let patients = []
        value.patients.forEach(p => {
          if (p.selected) {
            patients.push({
              external_metadata: {
                ids_id: p.external.patient.id,
                id: p.external.patient.jde_id,
              },
              patient_id: p.greyfinch.patient.id,
            })
          }
        })
        if (!patients.length) {
          notify(notifyError('Please select patients to sync.'))
          return
        }
        let mutationResponse = await executeMutation({
          sync_id,
          patients,
        })
        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please try again later.'))
          return
        }
        notify(
          notifySuccess(
            `${mutationResponse.data.apps_complete_sync.patients_synced} patients synced.`
          )
        )
        setFlowTo(normalizePath(props.viewPath, '../../../Apps'))
        break
      }
      default: {
      }
    }
    function sortPatients() {
      change(next => {
        // sort patients out
        let matchedPatients = []
        let partialPatients = []
        let noMatch = []

        next.patients.forEach(p => {
          switch (p.external.type) {
            case 'noMatch':
              noMatch.push({ ...p, id: p.external.patient.id })
              break
            case 'match':
              matchedPatients.push({
                ...p,
                id: p.external.patient.id,
              })
              break
            case 'partial':
              partialPatients.push({
                ...p,
                id: p.external.patient.id,
              })
              break
          }
        })
        next.patients = [
          { external: 'MATCH', greyfinch: '' },
          ...matchedPatients,
          { external: 'PARTIAL MATCH', greyfinch: '' },
          ...partialPatients,
          { external: 'NO MATCH', greyfinch: '' },
          ...noMatch,
        ]
      })
    }
  }
}
