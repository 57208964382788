import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let current_location = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location',
  })
  let chair_ids = useDataValue({
    viewPath: props.viewPath,
    context: 'selected_chair_ids',
  })

  return {
    variables: {
      location_id: current_location._id,
      local_start_date: current_location.local_current_date,
    },
    pause: chair_ids.length === 0,
  }
}
