// @view
import useMousetrap from '../use-mousetrap'

let Shortcut = props => {
  useMousetrap({
    keys: props.keys,
    type: props.type,
    onPress: props.onPress,
    disabled: props.disabled,
  })
  return null
}
export default Shortcut
