import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useMutation } from 'urql'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let onActionActivate = useDataOnActionActivate(props)
  let onActionComplete = useDataOnActionComplete(props)

  return async function onSubmit({ value, args, change }) {
    switch (args?.type) {
      case 'activate': {
        return onActionActivate({ value })
      }
      case 'complete': {
        return onActionComplete({ value })
      }
      default:
        return
    }
  }
}

function useDataOnActionActivate(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onAction({ value }) {
    let mutationResponse = await executeMutation({
      id: value.id,
      status: 'active',
    })
    if (mutationResponse.error) {
      return notify(
        notifyError(`There was a problem marking the request as active.`)
      )
    }
  }
}

function useDataOnActionComplete(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onAction({ value }) {
    let mutationResponse = await executeMutation({
      id: value.id,
      status: 'completed',
    })
    if (mutationResponse.error) {
      return notify(
        notifyError(
          `There was a problem marking the request as completed. Please, try again.`
        )
      )
    }
  }
}
