import { useDataChange } from 'Simple/Data.js'

export function useOnClickYes(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'max_length_type',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('relative')
  }
}

export function useOnClickNo(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'max_length_type',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('total')
  }
}
