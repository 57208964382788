import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  changeDiscountsAppliedToTreatmentFee,
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.treatment_fee = value

      changeDiscountsAppliedToTreatmentFee(next)
      changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
