import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'automation',
    viewPath: props.viewPath,
  })

  let event_id = useDataValue({
    context: 'automation',
    path: 'event_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: event_id ? 'update' : 'create' })
  }
}
