import View from './view.js'
import React from 'react'
import get from 'lodash/get'

let DEFAULT_TEXT = 'Select'
export default function Logic(props) {
  let text = DEFAULT_TEXT
  if (Array.isArray(props.from) && typeof props.isSelected === 'function') {
    let selected = props.from.filter(props.isSelected)
    text =
      selected.length <= 1
        ? get([...selected, null][0], `${props.field}`, props.text || 'Select')
        : `${selected.length} selected`
  }

  return <View {...props} text={text} placeholder={text === DEFAULT_TEXT} />
}
Logic.defaultProps = {
  from: [],
}
