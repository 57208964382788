import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let setSelected = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!locations.length) return
    let location = locations[0]

    setSelected(next => {
      next.location_id = location.id // ok to be id and not _id
      next.location_name = location.text
      next.document_template = null
    })
  }, [locations]) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
