import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let id = useDataValue({
    context: 'connection_resource_properties',
    path: 'id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'save': {
        let properties = {
          account_id: value.account_id,
          doctor_id: value.doctor_id,
        }
        let mutationResponse = await executeMutation({
          id,
          properties,
        })

        if (mutationResponse.error) {
          return notify(notifyError('An error has occurred saving settings'))
        }

        change(next => {
          next.account_id = value.account_id
          next.doctor_id = value.doctor_id
          next.originalValue = properties
        })

        setFlowTo(normalizePath(props.viewPath, '../../../No'))

        return
      }
      default: {
      }
    }
  }
}
