import { useMemo } from 'react'
import { patientName } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let person_payment_accounts = data.payment_accounts.map(i => ({
      id: i.payment_account.id,
      text: patientName(i.payor),
      data: {
        payor: i.payor,
        person_payment_account: i.payment_account.person_payment_account,
      },
    }))
    person_payment_accounts.sort((a, b) => a.text.localeCompare(b.text))

    let txs = data.tx_cards.reduce(
      (result, card) => [
        ...result,
        ...card.txs.map(tx => ({
          id: tx._id,
          text: tx.name,
        })),
      ],
      []
    )
    txs.sort((a, b) => a.text.localeCompare(b.text))

    return {
      ...data,
      person_payment_accounts,
      txs,
    }
  }, [data])
}
