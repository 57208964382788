// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/mutation-update-planned-appointment.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More($updates: [vaxiom_appointments_updates!]!) {
  update_vaxiom_appointments_many(updates: $updates) {
    affected_rows
  }
}

`