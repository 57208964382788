import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
