// Simple standard library of validators

import isPastDate from 'date-fns/isPast'
import isValidDate from 'date-fns/isValid'
import parseISODate from 'date-fns/parseISO'
import { validate as email } from 'email-validator'
import phoneRegex from 'phone-regex'

// text
export function text(value) {
  return typeof value === 'string'
}
export function textEmpty(value) {
  return text(value) && value.trim().length === 0
}
export function textAtLeastOneChar(value) {
  return textAtLeast(value, 1)
}
export function textAtLeastTwoChars(value) {
  return textAtLeast(value, 2)
}
export function textAtLeastThreeChars(value) {
  return textAtLeast(value, 3)
}
export function textAtLeast(value, n) {
  return text(value) && value.trim().length >= n
}

// numbers
export function number(value) {
  return Number.isFinite(value)
}

export function numberZero(value) {
  return number(value) && value === 0
}

export function numberZeroOrPositive(value) {
  return number(value) && value >= 0
}

export function numberPositive(value) {
  return number(value) && value > 0
}

export function numberNegative(value) {
  return number(value) && value < 0
}

// lists
export function list(value) {
  return Array.isArray(value)
}

export function listEmpty(value) {
  return list(value) && value.length === 0
}

export function listOneItem(value) {
  return list(value) && value.length === 1
}

export function listManyItems(value) {
  return list(value) && value.length >= 1
}

// time
export function date(value) {
  return isValidDate(parseISODate(value))
}

export function birthday(value) {
  return date(value) && isPastDate(parseISODate(value))
}

let TIME = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9]){0,1}$/
export function time(value) {
  return value && TIME.test(value)
}

// things
export function phoneNumber(value) {
  return phoneRegex({ exact: true }).test(value)
}

export { email }

// https://stackoverflow.com/a/2385967/1562732
export function name(value) {
  return (
    value &&
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      value.trim()
    )
  )
}

export function url(value) {
  try {
    new URL(value) // eslint-disable-line
    return true
  } catch (_) {
    return false
  }
}

let LOWERCASE_LETTERS = /[a-z]/
let UPPERCASE_LETTERS = /[A-Z]/
let NUMBERS = /[0-9]/
let SPECIAL_CHARACTERS = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/
export function password(value) {
  return (
    value.length >= 8 &&
    LOWERCASE_LETTERS.test(value) &&
    UPPERCASE_LETTERS.test(value) &&
    NUMBERS.test(value) &&
    SPECIAL_CHARACTERS.test(value)
  )
}

let UUID =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export function uuid(value) {
  return value && UUID.test(value)
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function social_security_number(value) {
  return value && SOCIAL_SECURITY_NUMBER.test(value)
}
