// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Unscheduled/More/Content/Delete/Content/DeleteAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Unscheduled_More_Content_Delete_Content_DeleteAction_Content($id: uuid!) {
  vaxiom_appointment_delete(id: $id) {
    id
  }
}

`