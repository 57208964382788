// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-rollback-appointment-state.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions($id: uuid!) {
  vaxiom_appointment_booking_state_rollback(id: $id) {
    id
  }
}

`