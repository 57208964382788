// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/Primary/Content/Unseat/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_Primary_Content_Unseat($id: uuid!, $skip_required_fields: Boolean!) {
  vaxiom_appointment_booking_unseat(
    id: $id
    skip_required_fields: $skip_required_fields
  ) {
    status
  }
}

`