import useIsHovered from 'Simple/hooks/useIsHovered.js'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      onClick={e => {
        if (typeof props.onClick === 'function') {
          props.onClick(e)
        }
        if (typeof props.onChange === 'function') {
          props.onChange(!props.value)
        }
      }}
      selected={props.value}
    />
  )
}
