import { hasHTML, stripHtml } from 'Data/format.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

export function useOnClick(props) {
  let [, notify] = useNotifications()

  return async function onClick(e) {
    e.stopPropagation()

    if (hasHTML(props.value)) {
      // this is supposed to work but it I always got empty text back
      // leaving it for reference just in case
      // let blob = new Blob([props.value], { type: 'text/html' })
      // await navigator.clipboard.write([
      //   new ClipboardItem({ [blob.type]: blob }),
      // ])
      if (typeof window.document.execCommand === 'function') {
        let tempElement = document.createElement('div')
        tempElement.innerHTML = props.value
        tempElement.style.position = 'absolute'
        tempElement.style.zIndex = '-1'
        tempElement.style.opacity = '0'

        // Append the temporary element to the body
        document.body.appendChild(tempElement)

        // Select the content of the temporary element
        let range = document.createRange()
        range.selectNodeContents(tempElement)
        let selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        try {
          let ok = window.document.execCommand('copy')
          notify(
            ok
              ? notifySuccess('Copied to clipboard')
              : notifyError('Failed to copy to clipboard')
          )
        } catch (error) {
          notify(notifyError('Failed to copy to clipboard'))
        }
        // Clean up: remove the temporary element and clear the selection
        document.body.removeChild(tempElement)
        selection.removeAllRanges()
      } else {
        try {
          // eslint-disable-next-line compat/compat
          await navigator.clipboard.writeText(stripHtml(props.value))
          notify(notifySuccess('Copied to clipboard'))
        } catch (error) {
          notify(notifyError('Failed to copy to clipboard'))
        }
      }
    } else {
      try {
        // eslint-disable-next-line compat/compat
        await navigator.clipboard.writeText(props.value)
        notify(notifySuccess('Copied to clipboard'))
      } catch (error) {
        notify(notifyError('Failed to copy to clipboard'))
      }
    }
  }
}
