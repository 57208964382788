import useDataOnActionLogin from './useDataOnActionLogin.js'
import useDataOnActionMfa from './useDataOnActionMfa.js'
import useDataOnActionClockInOut from './useDataOnActionClockInOut.js'

export default function useDataOnSubmit(props, data) {
  let onActionLogin = useDataOnActionLogin(props, data)
  let onActionMfa = useDataOnActionMfa(props, data)
  let onActionClockInOut = useDataOnActionClockInOut(props, data)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'login': {
        return onActionLogin({ value, originalValue, args, change })
      }

      case 'mfa': {
        return onActionMfa({ value, originalValue, args, change })
      }

      case 'clockinout': {
        return onActionClockInOut({ value, originalValue, args, change })
      }

      default: {
        throw new Error(`Unkown action ${args.type}`)
      }
    }
  }
}
