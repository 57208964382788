import { FloatingPortal } from '@floating-ui/react'
import React from 'react'

export default function FloatingPortalReact(props) {
  return (
    <FloatingPortal>
      {typeof props.children === 'function' ? props.children() : props.children}
    </FloatingPortal>
  )
}
