// this is an autogenerated file from App/Account/NewPatient/Content/Panes/Form/ReferralsSection/New/Confirm/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_NewPatient_Content_Panes_Form_ReferralsSection_New_Confirm($current_location_id: uuid!) {
  vaxiom_referral_templates(
    where: {
      _and: [
        { location: { _id: { _eq: $current_location_id } } }
        { deleted: { _eq: false } }
      ]
    }
  ) {
    id
    type
    free_type
    referral_list_values {
      id: pos
      referral_template_id
      pos
      value
    }
  }
}

`