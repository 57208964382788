export default function useDataOnSubmit(props, data, reExecuteQuery) {
  return async function onSubmit({ value, originalValue, change, args }) {
    switch (args?.type) {
      case 'cancel': {
        change(originalValue)
        return
      }
      default: {
        return
      }
    }
  }
}
