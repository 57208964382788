import { useMemo } from 'react'
import { STATES } from 'Data/constants.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return STATES
  }, [data])
}
