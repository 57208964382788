// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/TreatmentPlanTemplates/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_TreatmentPlanTemplates($organization_id: uuid!) {
  vaxiom_tx_plan_groups(
    where: {
      deleted: { _eq: false }
      tx_plan_templates: {
        deleted: { _eq: false }
        organization: { _id: { _eq: $organization_id } }
      }
    }
    order_by: { name: asc_nulls_last }
  ) {
    id
    _id
    name
    tx_plan_templates(
      where: {
        deleted: { _eq: false }
        organization: { _id: { _eq: $organization_id } }
      }
      order_by: { name: asc_nulls_last }
    ) {
      id
      name
      fee
      length_in_weeks
    }
  }
}

`