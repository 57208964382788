// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsEditAutopay/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsEditAutopay(
  $patient_id: numeric!
  $location_id: numeric!
  $receivable_id: numeric!
  $payment_plan_id: numeric!
) {
  patient: vaxiom_patients_by_pk(id: $patient_id) {
    id
    _id
    person {
      id
      _id
      first_name
      last_name
    }
  }
  location: vaxiom_sys_organizations_by_pk(id: $location_id) {
    id
    _id
  }
  receivable: vaxiom_receivables_by_pk(id: $receivable_id) {
    id
    human_readable_id
    treatment {
      id
      name
      tx_plan {
        id
        name
      }
    }
    insured {
      id
      insurance_subscription {
        id
        insurance_plan {
          id
          number
          insurance_company {
            id
            carrier_name
          }
        }
      }
    }
    payment_account {
      id
      person_payment_account {
        id
        payer_person {
          id
          _id
          first_name
          greeting
          last_name
        }
      }
    }
  }
  payment_plan: vaxiom_payment_plans_by_pk(id: $payment_plan_id) {
    id
    payment_plan_type
    autopay_source
  }
}

`