import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let insurances = useDataValue({
    context: 'payment_plan',
    path: 'insurances',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return {
      is_collapsed: insurances.length === 0,
    }
  }, [insurances.length])
}
