import { useDataChange, useDataValue } from 'Simple/Data.js'

import { PAYOR_FILTERS } from './constants.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let payer_account_id = useDataValue({
    context: 'message',
    path: 'params.payer_account_id',
    viewPath: props.viewPath,
  })
  let payment_account_selected_id = useDataValue({
    context: 'payments_post_payment',
    path: 'payment_account.selected_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'payments_post_payment',
  })
  let receivable = useDataValue({
    context: 'post_payment_info',
    path: 'receivable',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (!value) return

    change(next => {
      next.payor_filter = value.some(item => item.data.is_new === false)
        ? PAYOR_FILTERS.EXISTING
        : PAYOR_FILTERS.NEW

      let payment_account_id = receivable
        ? receivable.payment_account_id
        : payer_account_id

      // this is for case of newly created payment account, we want to correctly set it into Existing column
      if (payment_account_selected_id) {
        payment_account_id = payment_account_selected_id
      }

      let payment_account = value.find(
        item => item.data.payment_account_id === payment_account_id
      )

      if (payment_account) {
        // wrapped in string, to keep consistent data type, only used for dropdown
        // this is either the actual payment account id or temporary uuid
        next.payment_account.selected_id = String(payment_account.id)

        next.payment_account.data = payment_account.data
      }
    })
  }
}
