import { useMemo } from 'react'
import { useDataOriginalValue, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets_current = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let rulesets_original = useDataOriginalValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!rulesets_original?.length || !rulesets_current) {
      return data
    }

    let delete_changes = deleteChanges(rulesets_original, rulesets_current)
    let update_changes = updateChanges(rulesets_original, rulesets_current)

    return {
      has_changes: Boolean(delete_changes.length || update_changes.length),
      delete_changes,
      update_changes,
    }
  }, [data, rulesets_original, rulesets_current])
}

function deleteChanges(original, current) {
  return original
    .filter(
      or_item =>
        !current.some(
          cr_item => cr_item.organization_id === or_item.organization_id
        )
    )
    .map(or_item => or_item.organization_id)
}

function updateChanges(original, current) {
  return current.filter(cr_item => {
    let or_item = original.find(
      or_item => or_item.organization_id === cr_item.organization_id
    )

    return !or_item
      ? false
      : or_item.location_id !== cr_item.location_id ||
          or_item.division_id !== cr_item.division_id ||
          or_item.message_value.toString() !== cr_item.message_value.toString()
  })
}
