import { useDataValue, useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let location_id = useDataValue({
    context: 'selected',
    path: 'location_id',
    viewPath: props.viewPath,
  })
  let changeSelection = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === location_id) return

    changeSelection(next => {
      next.location_id = value
    })
  }
}
