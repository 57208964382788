import { useDataChange } from 'Simple/Data.js'
import { getDefaultSecondaryPayor } from 'Data/payment-plan.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      // there should always be at least one payor and it is primary
      // so only a secondary payor could be added
      next.payors.push(getDefaultSecondaryPayor(next))
    })
  }
}
