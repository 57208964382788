import { createBrowserHistory } from 'history'
import { DataProvider, useDataValue, useDataChange } from 'Simple/Data.js'
import { compile, match, parse } from 'path-to-regexp'
import {
  getFlowDefinitionKey,
  getParentView,
  useFlow,
  useSetFlowTo,
} from 'Simple/Flow.js'
import shortcuts from './FlowShortcuts.json'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

let FlowShortcutsHistoryContext = createContext(null)

let history = createBrowserHistory()

let STEPS_INITIAL_VALUE = {
  list: [],
  current: null,
  isError: false,
}

function start(state, steps) {
  if (!steps.length) return state
  let [current, ...list] = steps
  return { ...state, current, list }
}

function next(state) {
  if (!state.list.length) return { ...state, current: null }
  let [current, ...list] = state.list
  return { ...state, list, current }
}

function reducer(state, action) {
  switch (action.type) {
    case 'start':
      return start(state, action.payload)
    case 'next':
      return next(state)
    case 'error':
      return { list: [], current_idx: null, isError: true }
    default:
      return state
  }
}

export function FlowShortcuts(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let flow = useFlow()
  let [steps, dispatch] = useReducer(reducer, STEPS_INITIAL_VALUE)
  let [data, setData] = useState(() => getShortcut(history.location))

  useEffect(() => {
    if (!data?.shortcut?.setFlowTo) return

    setFlowTo(data?.shortcut?.setFlowTo)
  }, [data?.shortcut?.setFlowTo])

  useEffect(() => {
    // It would be good to set a max number of retries, but the flow is noisy so it is not clear what a resonable value it should be
    if (!steps.current || steps.isError) return

    if (steps.current?.viewPath && !flow.has(`${steps.current.viewPath}`)) {
      setFlowTo(steps.current.viewPath)
    } else if (
      !steps.current.content ||
      flow.has(`${steps.current.viewPath}/${steps.current.content}`)
    ) {
      // if content is not set, there is no need to wait for the flow to be set to a particular path, simply mark the current step as completed
      dispatch({ type: 'next' })
    } else if (
      steps.current.error &&
      flow.has(`${steps.current.viewPath}/${steps.current.error}`)
    ) {
      // There was an error setting the flow of the current step,
      // giving up and resetting the state so that it won't retry later if getting into a valid state in the app
      dispatch({ type: 'error' })
    }
  }, [
    flow,
    steps.current?.viewPath,
    steps.current?.content,
    steps.current?.error,
  ])

  return (
    <FlowShortcutsHistoryContext.Provider value={history}>
      <DataProvider
        viewPath={props.viewPath}
        context="set_flow_shortcut"
        value={setFlowShortcut}
      >
        <DataProvider
          viewPath={props.viewPath}
          context="flow_shortcuts"
          value={data.value}
        >
          {props.children}
        </DataProvider>
      </DataProvider>
    </FlowShortcutsHistoryContext.Provider>
  )

  function setFlowShortcut(uri) {
    // The domain is irrelevant we just need it to build the URL object
    let url = new URL(`https://flow.shortcut${uri}`)
    setData(
      getShortcut({
        pathname: url.pathname,
        search: url.search,
      })
    )
  }

  function getShortcut(location) {
    let shortcut = shortcuts
      .filter(item => item.route && !item.fallback)
      .map(item => ({
        ...item,
        match: match(item.route, { decode: decodeURIComponent })(
          location.pathname
        ),
      }))
      .find(item => item.match)

    if (shortcut) {
      let value = {
        redirect: null,
        temporarySession: shortcut.temporarySession,
        ...parseQueryString(location.search),
        ...parseParams(shortcut.match.params),
      }

      if (value.redirectUri) {
        let nextShortcutUri = { pathname: value.redirectUri, search: '' }
        let { shortcut: nextShortcut } = getShortcut(nextShortcutUri)
        if (nextShortcut.temporarySession) {
          value.temporarySession = true
        }

        value.redirect = () => {
          history.push(nextShortcutUri)
          setFlowShortcut(value.redirectUri)
          dispatch({ type: 'start', payload: nextShortcut.steps })
        }
      } else if (shortcut.steps) {
        value.redirect = () =>
          dispatch({ type: 'start', payload: shortcut.steps })
      }

      return { shortcut, value }
    } else {
      return {
        shortcut: shortcuts.find(item => item.fallback) || null,
        value: {},
      }
    }
  }
}

function parseParams(params) {
  if (!params) return {}

  if (params.token) {
    return {
      ...params,
      ...expandToken(params.token),
    }
  }
  if (params.dataToken) {
    return {
      ...params,
      ...expandToken(params.dataToken),
    }
  }

  return params
}

// otherwise swap for https://www.npmjs.com/package/query-string
function parseQueryString(query) {
  return Object.fromEntries([...new URLSearchParams(query)])
}

function expandToken(token) {
  try {
    return JSON.parse(atob(token))
  } catch (error) {
    return {}
  }
}

export function useHistory() {
  return useContext(FlowShortcutsHistoryContext)
}

let shortcutsViewPathToRoutesMap = {}
shortcuts
  .filter(item => item.viewPath && item.route && !item.fallback)
  .forEach(item => {
    if (!(item.viewPath in shortcutsViewPathToRoutesMap)) {
      shortcutsViewPathToRoutesMap[item.viewPath] = []
    }
    shortcutsViewPathToRoutesMap[item.viewPath].push({
      route: compile(item.route, { encode: encodeURIComponent }),
      params: parse(item.route)
        .map(item => item.name)
        .filter(Boolean),
    })
  })

export function getShortcutRouteForViewPath({ viewPath, ...params }) {
  if (!viewPath) return null

  let key = getFlowDefinitionKey(viewPath)
  let paramsKeys = Object.keys(params)
  let route = null

  while (route === null && key) {
    let viewPathRoutes = shortcutsViewPathToRoutesMap[key]
    if (viewPathRoutes) {
      let viewPathRoute = viewPathRoutes.find(item =>
        isContentTheSameAcrossLists(paramsKeys, item.params)
      )
      if (viewPathRoute) {
        route = viewPathRoute.route
      }
    }
    key = getParentView(key)[0]
  }

  try {
    return route ? route(params) : null
  } catch (error) {
    return null
  }
}
function isContentTheSameAcrossLists(a, b) {
  if (a.length !== b.length) return false
  let bSet = new Set(b)
  return a.every(item => bSet.has(item))
}

export function useEnsureUrl(props) {
  let history = useHistory()
  let shortcutRouteForViewPath = getShortcutRouteForViewPath(props)
  useEffect(() => {
    if (shortcutRouteForViewPath === null) return
    if (history.location.pathname.startsWith(shortcutRouteForViewPath)) return

    // TODO swap for push once the back button works
    history.replace(shortcutRouteForViewPath)
  }, [history, shortcutRouteForViewPath])
}

export function useDataValueOnce({
  viewPath,
  path,
  context = 'flow_shortcuts',
}) {
  let _value = useDataValue({ viewPath, context, path })
  let change = useDataChange({ viewPath, context, path })
  let value = useMemo(() => _value, []) // eslint-disable-line

  useEffect(() => {
    change(null)
  }, []) // eslint-disable-line

  return value
}
