// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/RefreshButton/query_medical_history_status.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_RefreshButton($patient_id: uuid!) {
  medical_histories_questions(
    where: {
      answers: { patient_id: { _eq: $patient_id }, alert: { _eq: true } }
      status: { _eq: active }
    }
  ) {
    id
    name
    type
    category
    answers(
      where: { patient_id: { _eq: $patient_id }, alert: { _eq: true } }
      order_by: { created_at: desc_nulls_last }
      limit: 1
    ) {
      id
      alert
      value
    }
  }
}

`