import { useDataChange, useDataOriginalValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let originalRulesets = useDataOriginalValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  let changeRulesets = useDataChange({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  return function onClick() {
    changeRulesets(originalRulesets)
  }
}
