import { useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import mutation from './mutation.graphql.js'

export function useOnDelete(props) {
  let association_id = useDataValue({
    viewPath: props.viewPath,
    context: 'address',
    path: 'id',
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onClick() {
    let mutationResponse = await executeMutation({
      association_id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Address deleted!'))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
