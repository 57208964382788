import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      ...data,
      start_time: props.start,
      end_time: props.end,
      chairs: [props.resourceId],
    }
  }, [data, props.start, props.end, props.resourceId])
}
