// this is an autogenerated file from App/Account/Content/Calendar/New/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Calendar_New_Content(
  $object: calendar_settings_insert_input!
  $columns_to_update: [calendar_settings_update_column!]!
) {
  insert_calendar_settings_one(
    object: $object
    on_conflict: {
      constraint: settings_user_id_location_id_key
      update_columns: $columns_to_update
    }
  ) {
    id
    user_id
    location_id
    hipaa
    slot_interval
    selected_appointment_templates
  }
}

`