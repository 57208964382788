import { useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'payers',
  })
  return function onClick() {
    submit({ type: 'downpayment_complete' })
  }
}
