import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return (
      data.treatment?.organization?.logo?.[0] ||
      data.patient?.primary_location?.logo?.[0]
    )
  }, [data])
}
