import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      has_appointments: data.vaxiom_appointments_aggregate.aggregate.count > 0,
      is_contract_applied: data.vaxiom_txs[0].is_contract_applied,
    }
  }, [data])
}
