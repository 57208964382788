import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let featureFlag = data?.[0]

    if (featureFlag?.message_value !== 'true') return null

    return featureFlag
  }, [data])
}
