import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'address',
  })
  let changeCitySearch = useDataChange({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })
  return function onClick({ street, city, state, zip }) {
    change(next => {
      next.address_line1 = street || ''
      next.city = city || ''
      next.state = state || ''
      next.zip = zip || ''
    })
    changeCitySearch(city || '')
  }
}
