// this is an autogenerated file from DesignSystem/ApiCaller/Request/AlignMergeFields/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ApiCaller_Request_AlignMergeFields($parent_company_id: uuid!) {
  apps_connections(
    where: {
      name: { _in: ["ids", "itero"] }
      status: { _eq: active }
      parent_company_id: { _eq: $parent_company_id }
    }
  ) {
    id
    name
  }
}

`