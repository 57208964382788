import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let connection_id = useDataValue({
    context: 'ids_connection',
    path: 'id',
    viewPath: props.viewPath,
  })
  let refetch = useDataChange({
    context: 'tab',
    path: 'selected.images.refetch',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    try {
      let mutationResponse = await executeMutation({
        connection_id,
        image_series_id,
      })

      if (
        mutationResponse.error ||
        mutationResponse.data?.align_patient_images_import?.status === 'error'
      ) {
        notify(
          notifyError(
            `Import from Align failed. Please reconnect the Invisalign® Doctor Site application from Settings and try again. If the problem persists, contact Support.`
          )
        )
        return
      }

      if (
        mutationResponse.data?.align_patient_images_import?.status ===
        'no_images'
      ) {
        notify(notifySuccess(`There were no images to import from Align.`))
      } else {
        notify(notifySuccess(`Images imported successfully.`))
      }
    } finally {
      refetch(Date.now())
      setFlowTo(normalizePath(props.viewPath, '../No'))
    }
  }
}
