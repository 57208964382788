import { useSetFlowTo } from 'Simple/Flow.js'
import { useRef } from 'react'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let ref = useRef(null)

  return async function onChange(next) {
    localStorage.setItem(
      'sidebar_toggles',
      JSON.stringify(next.sidebar_toggles)
    )

    if (next.next_tab_id !== null) {
      setFlowTo('/App/Account/Content/RedirectToActiveTab')
      ref.current = null
      return
    }

    let tab = next.tabs.find(t => t.id === next.tab_id)
    if (!tab || ref.current === tab.viewPath) return

    ref.current = tab.viewPath
    setFlowTo(tab.viewPath)
  }
}
