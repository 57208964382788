import View from './view.js';
import React from 'react';
import useDropdown from '../useDropdown';

export default function Logic(props) {
  let dropdown = useDropdown(props);

  return <View {...props} {...dropdown} />;
}



