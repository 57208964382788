import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    return data.custom_resource_types.map(option => ({
      ...option,
      text: option.name,
    }))
  }, [data])
}
