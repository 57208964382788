// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Resources/Content/Resources/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Resources_Content_Resources($id: uuid!) {
  comms_threads_by_pk(id: $id) {
    id
    resources
  }
}

`