// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/AutomationsList/EnableAutomationHub/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations_AutomationsList_EnableAutomationHub($organization_id: numeric!) {
  vaxiom_application_properties(
    where: {
      organization_id: { _eq: $organization_id }
      message_key: { _eq: "automation.hub.enabled" }
    }
  ) {
    id: organization_id
    message_value
  }
}

`