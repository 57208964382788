// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/NoteTemplates/update-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_NoteTemplates($id: uuid!, $object: notes_templates_set_input!) {
  update_notes_templates_by_pk(pk_columns: { id: $id }, _set: $object) {
    id
    name
    tags
    organization_id
    updated_at
    updated_by_user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`