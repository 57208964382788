import React, { useEffect } from 'react'
import View from './view.js'
import { normalizePath } from 'Simple/Flow.js'
import { DataProvider } from 'Simple/Data.js'
import makeDebug from 'Simple/debug'
import { useVirtualizer } from '@tanstack/react-virtual'
import pluralize from 'pluralize'

let debug = makeDebug('simple/morph/view')

export default function Logic(props) {
  let list = props?.from || []

  // note if we have aggregate as data source, the item that should give the name to the
  // list data provider context should be ordered after anything else in the aggregate data
  // sources in the view.blocks file as we can only get one props

  /*
  data selected.tab
  data appointment_bookings
  aggregate helpers.js filterByActiveTab

  Not as the below which will give us a context of selected.tab in our implementation
  
  data appointment_bookings
  data selected.tab
  aggregate helpers.js filterByActiveTab
  */

  let dataContextName =
    props.itemDataContextName || defaultItemDataContextName(props?.data)
  let childName = props?.childName || dataContextName

  let viewPath = normalizePath(props.viewPath, '..').slice(0, -1)
  let pinToBottom = props?.pinToBottom || false

  let ref = React.useRef()
  let count = list.length

  let virtualizer = useVirtualizer({
    count,
    getScrollElement: () => ref.current,
    estimateSize: () => props?.sizeEstimate || 75,
    getItemKey: index => list[index]?.id || index,
    overscan: props?.overscan,
  })

  let items = virtualizer.getVirtualItems()

  // set it to a mad number so we know it will hit the bottom
  // using an exact calculated number doesn't work because it's dynamic
  useEffect(() => {
    if (!pinToBottom) return
    window.setTimeout(() => {
      // Scroll to thr last element, using the last index available
      virtualizer.scrollToIndex(count - 1)
    }, 300)
  }, [count, pinToBottom, virtualizer])

  return (
    <View
      viewPath={props.viewPath}
      innerRef={ref}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingTop={props.paddingTop}
      paddingRight={props.paddingRight}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${items?.[0]?.start || 0}px)`,
          }}
        >
          {items.map(item => (
            <ListItem
              {...props}
              key={item.key}
              context={dataContextName}
              item={list[item.index]}
              index={item.index}
              list={list}
              hasItemKey={false}
              viewPath={`${viewPath}/${childName}(${
                list[item.index]?.id || item.index
              })`}
            >
              <div
                key={item.key}
                data-index={item.index}
                ref={virtualizer.measureElement}
                style={{
                  display: 'flex',
                }}
              >
                {typeof props.children === 'function'
                  ? props.children({
                      index: item.index,
                      item: list[item.index],
                      indexReverse: list.length - item.index,
                      isFirst: item.index === 0,
                      isLast: item.index === list.length - 1,
                      viewPath: `${viewPath}/${childName}(${
                        list[item.index]?.id || item.index
                      })`,
                      dsViewPath: `${viewPath}/${childName}(${
                        list[item.index]?.id || item.index
                      })`,
                    })
                  : props.children}
              </div>
            </ListItem>
          ))}
        </div>
      </div>
    </View>
  )
}

function ListItem(props) {
  let valueItem = React.useMemo(
    () => ({
      index: props.index,
      indexReverse: props.list.length - props.index,
      isFirst: props.index === 0,
      isLast: props.index === props.list.length - 1,
    }),
    [props.index, props.list]
  )

  let maybeListActions = {
    ...(typeof props.useListItemDataOnSubmit === 'function' && {
      onSubmit: props.useListItemDataOnSubmit(props),
    }),
    ...(typeof props.useListItemDataOnChange === 'function' && {
      onChange: props.useListItemDataOnChange({
        ...props,
        viewPath: props.parentViewPath,
      }),
    }),
  }

  if (
    process.env.NODE_ENV === 'development' &&
    !props.hasItemKey &&
    !props.item?.id
  ) {
    debug({
      type: 'simple/data',
      warning: `Missing "id" property on item in context "${props.context}". The list's index will be used as a fallback key. Consider setting "itemKey" if the item has a custom or compound key e.g. first_name,last_name.`,
      item: props.item,
      context: props.context,
      viewPath: props.viewPath,
    })
  }
  return (
    <DataProvider
      context={props.context}
      value={props.item}
      viewPath={props.viewPath}
      {...maybeListActions}
    >
      <DataProvider
        context={`${props.context}_item`}
        value={valueItem}
        viewPath={props.viewPath}
      >
        {props.children}
      </DataProvider>
    </DataProvider>
  )
}

function defaultItemDataContextName(data) {
  if (!data) return ''
  return pluralize.singular(data)
}
