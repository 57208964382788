import React, { useEffect, useRef } from 'react'

import View from './view.js'

export default function Logic(props) {
  let elRef = useRef()

  useEffect(() => {
    if (!elRef?.current?.scrollHeight) {
      return
    }
    let height = elRef.current.scrollHeight
    setTimeout(() => {
      props.node.setRowHeight(Math.max(height, 44))
      props.api.onRowHeightChanged()
    }, 0)
  }, [elRef?.current?.scrollHeight]) // eslint-disable-line react-hooks/exhaustive-deps
  return <View {...props} innerRef={elRef} />
}
