import { useDataChange, useDataOriginalValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let originalValue = useDataOriginalValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(originalValue)
  }
}
