import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: '_id',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, createTreatmentMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ args, value, originalValue }) {
    let response = await createTreatmentMutation({
      patient_id,
      location_id,
    })

    if (response.error) {
      return notify(
        notifyError('An unexpected error has occurred creating a new treatment')
      )
    }

    setFlowTo(normalizePath(props.viewPath, '../../No'))

    return notify(
      notifySuccess('A new treatment has been successfully created')
    )
  }
}
