import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let global_current_location = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return {
      ...data,
      location_id: global_current_location,
    }
  }, [data, global_current_location])
}
