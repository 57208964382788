import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      slot_key: props.slot_key || data.slot_key,
      label: props.label || data.label,
      url: props.url || data.url,
    }
  }, [data, props.slot_key, props.label, props.url])
}
