import React, { useEffect } from 'react'
import View from './view.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useDataChange, useDataValue } from 'Simple/Data'
import { textToNumber } from 'Data/format'

export default function Logic(props) {
  let calibrationSize = useDataValue({
    context: 'selected',
    path: 'calibration_size',
    viewPath: props.viewPath,
  })

  let changeCalibrationSize = useDataChange({
    context: 'selected',
    path: 'calibration_size',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    function calibrationToolListener(event) {
      let { id, data = {} } = event?.data || {}
      // Ignore all events where the origin is not wedoceph.com
      if (
        !event.origin.includes('wedoceph.com') &&
        !event.origin.includes('greyfinch.com') &&
        !event.origin.includes('localhost')
      ) {
        return
      }

      let calibrationToolValue = data.calibrationPixelSize
        ? textToNumber(data.calibrationPixelSize)
        : null

      if (
        calibrationToolValue !== null &&
        calibrationSize !== calibrationToolValue
      ) {
        changeCalibrationSize(calibrationToolValue)
      }

      if (id === 'calibrationMessageClose') {
        setFlowTo(normalizePath(props.viewPath, '../No'))
      }
    }
    window.addEventListener('message', calibrationToolListener)

    return function unsubscribe() {
      window.removeEventListener('message', calibrationToolListener)
    }
  }, [])

  return <View {...props} />
}
