import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'default_payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      next.discounts.push({
        discount_id: null,
        value: 10,
        type: 'percentage',
        stack_with_other_discounts: true,
      })
    })
  }
}
