// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsDevelopers/Keys/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsDevelopers_Keys{
  apps_keys(order_by: { created_at: desc }) {
    id
    key
    description
    masked_secret
    status
    expires_at
  }
}

`