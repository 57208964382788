/** @type {import('Simple/types.js').useDataOnChange} */
import { useRef } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import mutation_send_last_event from './mutation_send_last_event.graphql.js'

export default function useDataOnChange(props, data) {
  let thread_id = useDataValue({
    viewPath: props.viewPath,
    context: 'thread',
    path: 'id',
  })

  let latestEventRef = useRef([])

  let parent_company_id = useDataValue({
    context: 'thread',
    path: 'parent_company_id',
    viewPath: props.viewPath,
  })

  let user_id = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data.user_id',
  })

  let [, executeInsertMutation] = useMutation(mutation_send_last_event)

  return async function onChange(value, change) {
    if (!value) return

    let latestEvent =
      value &&
      value
        .filter(i => !['status', 'timeline'].includes(i?.type))
        .slice(-1)?.[0]

    if (
      !latestEvent ||
      latestEvent?.type !== 'internal' ||
      latestEventRef.current?.[thread_id]?.id === latestEvent.id
    )
      return

    if (latestEventRef.current?.[thread_id]?.id !== latestEvent.id) {
      try {
        await executeInsertMutation({
          thread_id,
          user_id,
          parent_company_id,
          event_id: latestEvent.id,
        })
      } catch (err) {}

      latestEventRef.current[thread_id] = latestEvent
    }
  }
}
