import {
  PAYMENT_PLAN_AUTOPAY_SOURCES,
  PAYMENT_PLAN_TYPES,
} from 'Data/common-constants.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let payment_plan = data.payment_plan

    /** @type {{ id: typeof PAYMENT_PLAN_AUTOPAY_SOURCES[number]; text: string }[]} */
    let autopay_sources = [
      // according to axpm, card should be first option
      // card option is only available for Person
      payment_plan.payment_plan_type === PAYMENT_PLAN_TYPES.Person && {
        id: PAYMENT_PLAN_AUTOPAY_SOURCES.CreditCard,
        text: 'Credit Card',
      },
      {
        id: PAYMENT_PLAN_AUTOPAY_SOURCES.BankAccount,
        text: 'Bank Account',
      },
    ].filter(Boolean)

    return {
      patient: data.patient,
      payment_plan,
      receivable: data.receivable,
      location: data.location,
      autopay_sources,
    }
  }, [data])
}
