import { useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (next.xid === value) return

      next.xid = value
      next.location_id = null
    })
  }
}
