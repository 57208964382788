import AwsS3Multipart from '@uppy/aws-s3-multipart'

// Extending AwsS3Multipart to prevent it from attempting to upload the
// remote files through Uppy's Companion server as it will be handled
// with a custom action.
export default class LocalFilesUploader extends AwsS3Multipart {
  upload(fileIDs) {
    let localFileIds = fileIDs.filter(id => !this.uppy.getFile(id).isRemote)
    // only upload local files through this plugin
    return super.upload(localFileIds)
  }
}
LocalFilesUploader.pluginName = 'LocalFilesUploader'
