// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/StartContract/Content/Main/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_StartContract_Content_Main_Content($id: uuid!) {
  vaxiom_treatment_run_realization(id: $id) {
    ok
  }
}

`