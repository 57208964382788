import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let image_series_types = useDataValue({
    context: 'image_series_types',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'image_serie_form',
    viewPath: props.viewPath,
  })

  return function onChange(type_id) {
    change(next => {
      next.type_id = type_id
      next.name =
        image_series_types.find(type => type.id === type_id)?.text || type_id
    })
  }
}
