import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'

export function useOnDelete(props) {
  let video_id = useDataValue({
    context: 'video',
    path: 'id',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onDelete() {
    let mutationResponse = await executeMutation({
      id: video_id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`Couldn't delete the video`))
    }

    change(next => {
      // If I delete the selected video, I clean the selection
      if (next.id === video_id) {
        next.url = null
        next.content_type = null
        next.file_id = null
        next.file_name = null
        next.id = null
      }
    })

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
