import { useNotifications, OK, ERROR } from 'Logic/Notifications.js'
import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let [notifications] = useNotifications()
  return notifications.map(n => (
    <View
      key={n.id}
      isError={n.status === ERROR}
      isOk={n.status === OK}
      message={n.message}
      viewPath={props.viewPath}
    />
  ))
}
