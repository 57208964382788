// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/addOfficeMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content(
  $other_professional_relationship_id: numeric
  $name: String
  $address_line1: String
  $city: String
  $state: String
  $zip: String
) {
  insert_external_office(
    args: {
      other_professional_relationship_id: $other_professional_relationship_id
      address_line1: $address_line1
      name: $name
      city: $city
      state: $state
      zip: $zip
    }
  ) {
    id
  }
}

`