// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/DownloadAction/Content/Content/DownloadAsImage/PrintContent/query_print_image_series.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_DownloadAction_Content_Content_DownloadAsImage_PrintContent(
  $image_series_id: numeric!
  $layout: vaxiom_patient_image_series_layout_type!
) {
  vaxiom_patient_image_series_print(
    image_series_id: $image_series_id
    layout: $layout
  ) {
    url
  }
}

`