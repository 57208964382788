/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  return async function onSubmit({ args }) {
    switch (args.type) {
      case 'reFetch':
        reExecuteQuery({ requestPolicy: 'cache-and-network' })
        return
      default:
        return
    }
  }
}
