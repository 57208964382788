import React, { useRef } from 'react'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'

import View from './view.js'

export default function Logic(props) {
  let triggerRef = useRef()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let contactFlow = useFlowValue(normalizePath(props.viewPath, 'Contact'))

  function hideContact() {
    setFlowTo(normalizePath(props.viewPath, 'Contact/No'))
  }

  function showContact() {
    setFlowTo(normalizePath(props.viewPath, 'Contact/Content'))
  }

  function toggleContact() {
    if (contactFlow === 'Content') {
      hideContact()
    } else {
      showContact()
    }
  }

  return (
    <View
      {...props}
      toggleContact={toggleContact}
      showContact={showContact}
      hideContact={hideContact}
      triggerRef={triggerRef}
    />
  )
}
