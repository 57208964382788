import { useDataChange, useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let image_series_id = useDataValue({
    context: 'comparison_action',
    path: 'image_series_id',
    viewPath: props.viewPath,
  })
  let image_id = useDataValue({
    context: 'comparison_action',
    path: 'image_id',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'comparison_action_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'comparison',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change(next => {
      next.images[index].image_series_id = image_series_id
      next.images[index].image_id = image_id
    })

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
