import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'patients',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let selectedPatient = useDataValue({
    context: 'replace',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({
      type: 'replace',
      selected_patient: selectedPatient.selected_patient,
      external_id: selectedPatient.external_id,
    })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
