import { useOnClick } from './helpers.js'
import View from './view.js'
import React, { useEffect } from 'react'

export default function Logic(props) {
  let onClick = useOnClick(props)

  useEffect(() => {
    onClick()
  }, [])

  return <View {...props} />
}
