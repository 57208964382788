import { patientName } from 'Data/format.js'
import { useDataChange, useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useListItemDataOnSubmit} */
export default function useListItemDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'showPatient': {
        return change(next => {
          next.name = patientName(value.person)
          next.tab = args.tab || 'profile'
        })
      }

      case 'showCalendar': {
        return submit({
          type: 'tabs/add',
          tab: {
            id: 'Calendar',
            name: 'Calendar',
            type: 'Calendar',
            viewPath: '/App/Account/Content/Calendar',
            coreParams: args.coreParams,
          },
        })
      }

      default: {
      }
    }
  }
}
