import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import sortBy from 'lodash/sortBy'
import { useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  // pre-select appointment
  return async function onChange(next) {
    // scheduled first
    let appointments = sortBy(
      flatMap(map(next, 'appointments')).filter(item => item._group !== 'new'),
      item => item._group !== 'scheduled'
    )

    let appointment = appointments?.[0]

    if (appointment) {
      submit({
        type: 'existingAppointment',
        id: appointment.id,
        type_id: appointment.type.id,
        note: appointment.note,
      })
    }
  }
}
