import { useDataChange } from 'Simple/Data'

export function useOnClearProviders(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.providers',
  })

  return function onClick() {
    change([])
  }
}
