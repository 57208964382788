// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/Procedures/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding_Procedures($objects: [vaxiom_procedures_insert_input!]!) {
  insert_vaxiom_procedures(objects: $objects) {
    returning {
      id
      name
    }
  }
}

`