import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let directory_id = useDataValue({
    context: 'patient_documents_directory',
    path: 'id',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'patient_documents_directory_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'patient_documents',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      if (directory_id === next.directories[next.directories.length - 1].id)
        return

      next.parent_id = directory_id
      next.directories = next.directories.slice(0, index + 1)
      next.selected_document_id = null
    })
  }
}
