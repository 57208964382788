// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Signature/Content/Content/CancelAction/Content/SubmitAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Signature_Content_Content_CancelAction_Content_SubmitAction($id: uuid!) {
  update_documents_signature_requests_by_pk(
    pk_columns: { id: $id }
    _set: { status: cancelled }
  ) {
    id
    status
  }
}

`