import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let image_id = useDataValue({
    context: 'patient_image',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setImageId = useDataChange({
    context: 'comparison_action',
    path: 'image_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setImageId(image_id)
  }
}
