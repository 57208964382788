// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Upload/Content/Documents/Content/SystemFiles/FilesTree/ImageSeries/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Upload_Content_Documents_Content_SystemFiles_FilesTree_ImageSeries($patient_id: numeric!) {
  vaxiom_patient_imageseries(
    where: { deleted: { _eq: false }, patient_id: { _eq: $patient_id } }
  ) {
    id
    name
    patient_id
  }
}

`