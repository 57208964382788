// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/ImportAction/Content/CreateIteroScanRequestAction/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_ImportAction_Content_CreateIteroScanRequestAction_Content($image_series_id: numeric!) {
  apps_align_scans(
    where: { patient_image_series: { id: { _eq: $image_series_id } } }
  ) {
    id
  }
}

`