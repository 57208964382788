import { useDataChange, useDataValue } from 'Simple/Data'
import { generateSignedUrl, useAwsS3Client } from 'Data/s3.js'

export function useOnClick(props) {
  let url = useDataValue({
    context: 'image',
    path: 'url',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'image',
    viewPath: props.viewPath,
  })
  let s3 = useAwsS3Client(props)

  return async function onClick() {
    let preview_url = url.startsWith('blob')
      ? url
      : await generateSignedUrl(s3, url)

    change(next => {
      next.editor = null
      next.original_image = null
      next.editor_src = preview_url
    })
  }
}
