// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/NotesCellTooltip/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_NotesCellTooltip($appointment_id: numeric!) {
  vaxiom_notes(
    where: {
      target_id: { _eq: $appointment_id }
      target_type: {
        _eq: "com.axpm.treatments.core.internal.domain.Appointment"
      }
    }
    order_by: { sys_created: asc_nulls_first }
  ) {
    id
    _id
    note
    target_type
    sys_created
    author
    author_user {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`