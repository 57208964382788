// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/Notes/Content/Note/Edit/Users/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes_Notes_Content_Note_Edit_Users($end_date: date!, $current_location_id: uuid!) {
  vaxiom_sys_users(
    where: {
      person: {
        employment_contracts: {
          _and: [
            {
              _or: [
                { employment_end_date: { _is_null: true } }
                { employment_end_date: { _gte: $end_date } }
              ]
            }
            {
              employee_resources: {
                invalid: { _eq: false }
                resource: {
                  organization: { _id: { _eq: $current_location_id } }
                }
              }
            }
          ]
        }
      }
    }
    order_by: { person: { search_name: asc } }
  ) {
    id
    person {
      id
      first_name
      middle_name
      last_name
      search_name
    }
  }
}

`