import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    submit({ type: 'message-sent' })
  }
}
