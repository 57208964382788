// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/Primary/Content/Seat/Confirmation/Content/Chair/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_Primary_Content_Seat_Confirmation_Content_Chair($current_location_id: uuid!) {
  vaxiom_chairs(
    where: {
      resource: {
        organization: { _id: { _eq: $current_location_id } }
        deleted: { _eq: false }
      }
    }
    order_by: { pos: asc }
  ) {
    id
    _id
    text: full_name
    chair_allocations(
      where: {
        ca_date: { _eq: "now()" }
        resource: { deleted: { _eq: false } }
      }
    ) {
      id
      resource {
        id
        resource_type {
          id
          is_assistant
          is_provider
        }
        employee_resources(
          where: {
            invalid: { _eq: false }
            employment_contract: { employment_end_date: { _is_null: true } }
          }
        ) {
          id
          employment_contract {
            id
            person {
              id
              search_name
            }
          }
        }
      }
    }
  }
}

`