// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/PaymentsPostPayment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener_PaymentsPostPayment(
  $patient_id: numeric!
  $patient_uuid: uuid!
  $location_id: numeric!
  $receivable_id: numeric!
  $include_receivable: Boolean!
  $payment_account_id: numeric!
  $include_patient_due_amount: Boolean!
) {
  patient_due_amount: financial_patient_due_amount(
    args: { _patient_id: $patient_uuid }
    where: { payment_account_id: { _eq: $payment_account_id } }
  ) @include(if: $include_patient_due_amount) {
    due_now
  }
  patient: vaxiom_patients_by_pk(id: $patient_id) {
    id
    _id
    person {
      id
      _id
    }
  }
  payment_methods: vaxiom_payment_types(
    where: {
      deleted: { _eq: false }
      payment_types_locations: { organization_id: { _eq: $location_id } }
    }
    order_by: { name: asc }
  ) {
    id
    name
    type
  }
  receivable: vaxiom_receivables_by_pk(id: $receivable_id)
    @include(if: $include_receivable) {
    id
    balance_amount
    payment_account_id
    human_readable_id
  }
}

`