import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let id = useDataValue({
    context: 'option',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'schedule_via',
    path: 'template_type',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(id)
  }
}
