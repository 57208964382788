import { useMemo } from 'react'

import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment.id',
    viewPath: props.viewPath,
  })
  let booking_id = useDataValue({
    context: 'event',
    path: 'appointment.booking.id',
    viewPath: props.viewPath,
  })
  let snapshot_id = useDataValue({
    context: 'event',
    path: 'appointment.tooth_chart_snapshot.id',
    viewPath: props.viewPath,
  })
  return useMemo(
    () => ({
      patient_id,
      appointment_id,
      booking_id,
      snapshot_id,
      tab: 'appointment_tooth_chart',
    }),
    [appointment_id, booking_id, patient_id, snapshot_id]
  )
}
