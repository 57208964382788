import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let event_type = useDataValue({
    context: 'automation',
    path: 'event_type',
    viewPath: props.viewPath,
  })
  let action_index = useDataValue({
    context: 'event_action_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let actions = useDataValue({
    context: 'automation',
    path: 'actions',
    viewPath: props.viewPath,
  })
  let trigger_type = useDataValue({
    context: 'automation',
    path: 'trigger_type',
    viewPath: props.viewPath,
  })
  let automation_type = useDataValue({
    context: 'automation',
    path: 'automation_type',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data) return data

    return data
      .filter(
        ({
          supported_event_types,
          supported_automation_types,
          supported_trigger_type,
        }) =>
          supported_event_types.includes(event_type) &&
          (!supported_automation_types ||
            supported_automation_types.includes(automation_type)) &&
          supported_trigger_type.includes(trigger_type)
      )
      .filter(action_type => {
        // check if status_update is used in a different action
        let status_update_used = actions.some(
          (action, index) =>
            action.type === 'status_update' && index !== action_index
        )

        // status_update can only be added once, filter it out if already added on a different action
        return action_type.id !== 'status_update' || !status_update_used
      })
  }, [data, event_type, automation_type, trigger_type, actions, action_index])
}
