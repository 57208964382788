import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let tx_card_field = useDataValue({
    context: 'tx_card_field',
    viewPath: props.viewPath,
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => tx_card_field, [])
}
