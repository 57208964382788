// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/AssistantAndProvider/Content/Change/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_AssistantAndProvider_Content_Change_Content(
  $id: uuid!
  $provider_id: numeric!
  $assistant_id: numeric!
  $chair_id: numeric!
) {
  vaxiom_appointment_booking_update(
    id: $id
    provider_id: $provider_id
    assistant_id: $assistant_id
    chair_id: $chair_id
  ) {
    id
  }
}

`