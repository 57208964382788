// this is an autogenerated file from DesignSystem/TimelineEvent/FutureSeparator/Actions/AddAppointment/Add/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_FutureSeparator_Actions_AddAppointment_Add_Content(
  $treatment_id: uuid!
  $appointment_template_id: uuid!
  $location_id: uuid!
  $previous_appointment_id: uuid
) {
  vaxiom_appointment_create(
    treatment_id: $treatment_id
    appointment_template_id: $appointment_template_id
    location_id: $location_id
    previous_appointment_id: $previous_appointment_id
  ) {
    id
  }
}

`