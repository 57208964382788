import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })
  let selected = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
  })
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'similar_person',
    path: 'id',
  })

  return function onClick(value) {
    if (value) {
      change(person_id)
    } else {
      change(null)
    }
  }
}
