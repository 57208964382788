// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Tools/AttachmentAction/InApp/Documents/Content/Patients/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Tools_AttachmentAction_InApp_Documents_Content_Patients($id: uuid!) {
  comms_threads_by_pk(id: $id) {
    id
    users {
      id
      vaxiom_person {
        id
        first_name
        last_name
        patient {
          id
        }
        from_person_relationships(
          where: { permitted_to_see_info: { _eq: true } }
        ) {
          id
          to_person_record {
            id
            first_name
            last_name
            patient {
              id
            }
          }
        }
        other_professional_relationships {
          id
          person_id
          professional_relationship {
            id
            patient_id
            patient {
              id
              person {
                id
                first_name
                last_name
              }
            }
          }
        }
      }
    }
  }
}

`