import React, { useMemo } from 'react'
import { PinturaEditor } from '@pqina/react-pintura'
import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultShapePreprocessor,
  createMarkupEditorToolStyles,
  createMarkupEditorToolStyle,
  createDefaultColorOptions,

  // plugins
  setPlugins,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
} from '@pqina/pintura'

import '@pqina/pintura/pintura.css'
import './GalleryImageEditor.css'

setPlugins(plugin_annotate)

let editorDefaults = {
  utils: ['annotate'],
  imageReader: createDefaultImageReader(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...markup_editor_defaults,
  markupEditorToolStyles: createMarkupEditorToolStyles({
    rectangle: createMarkupEditorToolStyle('rectangle', {
      strokeWidth: '1%',
      strokeColor: createDefaultColorOptions().red,
      backgroundColor: createDefaultColorOptions().transparent,
    }),
    ellipse: createMarkupEditorToolStyle('ellipse', {
      strokeWidth: '1%',
      strokeColor: createDefaultColorOptions().red,
      backgroundColor: createDefaultColorOptions().transparent,
    }),
  }),
  locale: {
    ...locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
}

export default function ImageEditor(props) {
  let enableControls = useMemo(() => {
    if (!props.isToolbarHidden) return { enableToolbar: true }

    return {
      enableToolbar: false,
      markupEditorToolbar: [],
      annotateActiveTool: 'view',
    }
  }, [props.isToolbarHidden])

  return (
    <PinturaEditor
      // it doesn't pick up the changes after the editor is mounted so will force recreating it
      key={JSON.stringify(props.isToolbarHidden)}
      {...editorDefaults}
      {...enableControls}
      enableButtonExport={false}
      enableViewTool
      previewUpscale
      src={props.src}
    />
  )
}
