// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/RefreshButton/query_assigned_providers.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_RefreshButton($patient_id: uuid!) {
  patients_assigned_providers(
    args: { _patient_id: $patient_id }
    order_by: { first_name: asc, last_name: asc }
  ) {
    id
    first_name
    last_name
  }
}

`