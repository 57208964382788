import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import { patientName } from 'Data/format.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import query from './query.graphql.js'

export function useOnClick(props) {
  let client = useClient()
  let [, notify] = useNotifications()

  let resources = useDataValue({
    context: 'thread',
    path: 'resources',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    let id = getPatientResourceId(resources)

    // resource is not a patient
    if (!id) return

    // query for the patient details?
    let res = await client
      .query(query, {
        id,
      })
      .toPromise()

    if (res.error) {
      return notify(notifyError(`Couldn't find patient`))
    }

    submit({
      type: 'tabs/add',
      tab: {
        id: id,
        type: 'Patient',
        name: patientName(res.data.patient[0].person),
        tab: 'profile',
        viewPath: '/App/Account/Content/Patients',
        patient_id: id,
      },
    })
  }
}

function getPatientResourceId(resources) {
  return resources
    .map(resource => resource?.table === 'patients' && resource.id)
    .filter(Boolean)?.[0]
}

export function getResourceType(resources) {
  let hasPatient = resources
    .map(resource => {
      if (resource?.table === 'patients') {
        return resource
      }
      return null
    })
    .filter(Boolean)

  if (hasPatient.length > 0) {
    return 'Go to Profile'
  } else {
    return ''
  }
}
