// this is an autogenerated file from App/Account/Content/Financial/mutation-statements.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Financial(
  $documents: [vaxiom_document_tree_nodes_insert_input!]!
  $saveDocuments: Boolean!
  $printNotes: [vaxiom_notes_insert_input!]!
  $savePrintNotes: Boolean!
) {
  insert_vaxiom_document_tree_nodes(objects: $documents)
    @include(if: $saveDocuments) {
    affected_rows
  }

  insert_vaxiom_notes(objects: $printNotes) @include(if: $savePrintNotes) {
    affected_rows
  }
}

`