import { useDataSubmit } from 'Simple/Data'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'note_template',
    viewPath: props.viewPath,
  })

  return function onSubmit(value) {
    return submit({ type: 'copy', locations: value })
  }
}
