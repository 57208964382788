import React, { useEffect } from 'react'
import { useDataSubmit } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    // TODO: I think we can address this in a better way
    // this awful hack somewhat avoids the reset-while-submitting
    // warning because the combination of things that led to the
    // issue are likely gone at this point
    let id = window.requestIdleCallback(
      () => {
        submit({ type: 'tabs/ensureDefault' })
      },
      { timeout: 1_000 }
    )

    return () => window.cancelIdleCallback(id)
  }, []) // eslint-disable-line

  return <View {...props} />
}
