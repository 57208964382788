import { patientName } from 'Data/format.js'
import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  // TODO: revert when virtual list is implemented back into the morpher

  // let submit = useDataSubmit({
  //   context: 'appointment_booking',
  //   viewPath: props.viewPath,
  // })

  // return function onClick() {
  //   submit({
  //     type: 'showPatient',
  //     tab: 'treatment_card',
  //   })
  // }

  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let value = useDataValue({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'tabs/add',
      tab: {
        id: value.patient_id,
        patient_id: value.patient_id,
        name: patientName(value.patient_person),
        type: 'Patient',
        tab: 'treatment_card',
        viewPath: '/App/Account/Content/Patients',
      },
    })
  }
}
