import { useDataChange } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'manual_action_executions',
    viewPath: props.viewPath,
  })

  return function onChange(value, fn) {
    change(inext => {
      let index = inext.findIndex(item => item.id === value.id)
      if (index > -1) {
        let status = isSkipped(value)
          ? 'skipped'
          : isCompleted(value)
          ? 'completed'
          : 'pending'
        inext[index].status = status
        inext[index].data = value.data
        fn(iinext => {
          // update status in the current context
          iinext.status = status
        })
      }
    })
  }
}

function isSkipped(value) {
  return value.status === 'skipped'
}

function isCompleted(value) {
  switch (value.action_type) {
    case 'status_update':
      return isStatusUpdateCompleted(value)
    case 'task_insert':
      return isTaskInsertCompleted(value)
    case 'check_requirements':
      // no user input so it is always valid
      return true
    default:
      return false
  }
}

function isStatusUpdateCompleted(value) {
  return value.data.new_status
}

function isTaskInsertCompleted(value) {
  return value.data.task?.title && value.data.task?.task_basket_id
}
