import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'patient_image',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    return submit({ type: 'delete' })
  }
}
