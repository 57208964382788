import React from 'react'
import View from './view.js'

let MAX_NUMBER_OF_FILES = 20
let MAX_FILE_SIZE_IN_APP = 1024 * 1024 * 10 // 50MB
let ALLOWED_FILE_TYPES_IN_APP = ['image/*', '.heic', '.heif']
let restrictions = {
  maxFileSize: MAX_FILE_SIZE_IN_APP,
  allowedFileTypes: ALLOWED_FILE_TYPES_IN_APP,
  maxNumberOfFiles: MAX_NUMBER_OF_FILES,
}

export default function Logic(props) {
  return <View viewPath={props.viewPath} restrictions={restrictions} />
}
