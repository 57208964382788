import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let ruleset = useDataValue({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_id: ruleset._id,
      division_id: ruleset.division_id,
      parent_id: ruleset.parent_id,
    },
  }
}
