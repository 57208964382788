import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let initalLeads = useDataValue({
    context: 'marketing_analytics',
    path: 'leads',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return { ...data, ordered_rows: initalLeads.map(lead => lead.id) }
  }, [data])
}
