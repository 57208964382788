import { DAYS } from 'Data/constants.js'
import {
  addWeeks,
  startOfMonth,
  isWithinInterval,
  isBefore,
  addDays,
  isAfter,
  isEqual,
  differenceInCalendarMonths,
  differenceInDays,
  getDaysInMonth,
  subDays,
  format,
  addMonths,
  setDate,
} from 'Data/date.js'
import { utcToZonedTime } from 'date-fns-tz'

/**
 * Calculates the total number of installments needed to cover the due amount.
 * It uses the ceiling function to ensure full coverage in case of a non-integer result.
 * @param {{ due_amount: number, installment_amount: number }} input
 * @return {number} The total number of installments required.
 *
 * https://bitbucket.org/vaxiom/axpm/src/71a150787971db886a7deec0f6f5c1b5fb2f3534/sources/core-reporting/core-reporting-application/src/main/java/com/axpm/financial/core/internal/domain/PaymentPlan.java#sources/core-reporting/core-reporting-application/src/main/java/com/axpm/financial/core/internal/domain/PaymentPlan.java-190
 * Also found in: functions/events/reports-executions-insert.js
 */
export function getNumOfInstallments({ due_amount, installment_amount }) {
  if (installment_amount === null || installment_amount === 0) return 0
  return Math.ceil(due_amount / installment_amount)
}

/**
 * Calculates the installment amount, given the number of installments
 * and the total due amount for the entire payment plan
 *
 * @param {{num_of_installments: number, due_amount: number}} input
 */
export function getInstallmentAmount({ num_of_installments, due_amount }) {
  if (num_of_installments <= 0) return 0
  /**
   * The case where num_of_installments = 1 needs to be handled differently
   * This is because if the due_amount has a floating value, then the Math.ceil
   * rounds it off to the nearest integer, which is wrong in that case.
   */ else if (num_of_installments === 1) return due_amount
  return Math.ceil(due_amount / num_of_installments)
}

/**
 * Calculates the total duration of the payment plan in months.
 * It considers the number of installments and the frequency of payments.
 * @param {{ due_amount: number, installment_interval: 'Weekly' | 'EverySecondWeek', due_date: Date, installment_dates: string[], installment_amount: number } | { due_amount: number, installment_interval: 'TwicePerMonth' | 'Monthly' | 'Quarterly' | 'SemiAnnual' | 'Yearly', due_date: Date, installment_dates: number[], installment_amount: number }} input
 *
 * https://bitbucket.org/vaxiom/axpm/src/693796fa8c48c566b729fddae80203a03c8525db/sources/core-reporting/core-reporting-application/src/main/java/com/axpm/financial/core/internal/domain/PaymentPlan.java#lines-148
 */
export function getPlanLengthInMonths({
  due_amount,
  installment_interval,
  due_date,
  installment_dates,
  installment_amount,
}) {
  let num_of_installments = getNumOfInstallments({
    due_amount,
    installment_amount,
  })
  let date = due_date

  switch (installment_interval) {
    case 'Monthly':
      return num_of_installments

    case 'Quarterly':
    case 'SemiAnnual':
    case 'Yearly':
      date = addMonths(
        date,
        num_of_installments *
          (installment_interval === 'Yearly'
            ? 12
            : installment_interval === 'SemiAnnual'
            ? 6
            : 3)
      )
      break

    case 'TwicePerMonth':
      let firstDate = setDate(startOfMonth(date), installment_dates[0])
      let secondDate = setDate(startOfMonth(date), installment_dates[1])

      if (!isAfter(date, firstDate)) {
        due_date = firstDate
      } else if (!isAfter(date, secondDate)) {
        due_date = secondDate
      } else if (isAfter(date, secondDate)) {
        due_date = addMonths(firstDate, 1)
      }

      date = due_date
      for (let i = 0; i < num_of_installments; i++) {
        firstDate = setDate(startOfMonth(date), installment_dates[0])
        secondDate = setDate(startOfMonth(date), installment_dates[1])

        if (isBefore(secondDate, firstDate)) {
          let swap = secondDate
          secondDate = firstDate
          firstDate = swap
        }

        if (isBefore(date, firstDate)) {
          date = firstDate
        } else if (
          isWithinInterval(date, {
            start: firstDate,
            end: subDays(secondDate, 1),
          })
        ) {
          date = secondDate
        } else if (!isBefore(date, secondDate)) {
          date = addMonths(firstDate, 1)
        } else {
          date = addWeeks(date, 2)
        }
      }
      break

    default:
      date = addWeeks(
        date,
        num_of_installments * (installment_interval === 'Weekly' ? 1 : 2)
      )

      break
  }

  if (isEqual(due_date, date)) return 0
  // differenceInMonths returns the number of full months.
  // We need only the number of months.
  // For example, from 10th April, 2024 to 08th May, 2024,
  // it should be 1 month. But with differenceInMonths, it was 0.
  // Hence, differenceInCalendarMonths was used
  return differenceInCalendarMonths(date, due_date)
}

/**
 * Generates a list of possible dates for installment payments based on the selected interval.
 * The dates vary depending on whether payments are weekly, twice per month, etc.
 * @param {string} installment_interval - The frequency of the installment.
 * @return {Array} A list of possible installment dates.
 */
export function getInstallmentDates(installment_interval) {
  if (
    installment_interval === 'Weekly' ||
    installment_interval === 'EverySecondWeek'
  ) {
    return [DAYS]
  } else if (installment_interval === 'TwicePerMonth') {
    return [
      Array.from({ length: 31 }, (_, i) => ({ id: i, text: i + 1 })),
      Array.from({ length: 31 }, (_, i) => ({ id: i, text: i + 1 })),
    ]
  } else {
    return [Array.from({ length: 31 }, (_, i) => ({ id: i, text: i + 1 }))]
  }
}

/**
 * Finds the next occurrence of a specified day of the week from today's date.
 * Useful for weekly payment schedules.
 * @param {{ today: Date, dayOfWeek: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'}} input
 * @return {Date} The next occurrence of the specified day of the week.
 */
function getNextDayOfWeek({ today, dayOfWeek }) {
  let date = addDays(today, 1)
  while (
    format(utcToZonedTime(date, 'UTC'), 'EEEE').toLowerCase() !== dayOfWeek
  ) {
    date = addDays(date, 1)
  }

  return date
}

/**
 * Calculates the next occurrence of a given day in the month.
 * Accounts for the varying lengths of months and rolls over to the next month if necessary.
 * @param {{today: Date, dayOfMonth: number}} input
 * @return {Date} The next occurrence of the specified day.
 */
function getNextDayOfMonth({ today, dayOfMonth }) {
  let currentDay = today.getUTCDate()

  // If today is before the requested day, and the current month has the requested day.
  if (currentDay < dayOfMonth && dayOfMonth <= getDaysInMonth(today)) {
    return setDate(today, dayOfMonth)
  } else if (dayOfMonth > getDaysInMonth(today)) {
    return setDate(today, getDaysInMonth(today))
  }

  // If the requested day is not in this month or has already passed, go to next month.
  let nextMonth = addMonths(today, 1)
  // Adjust for months that don't have the requested day (like February for 30 or 31).
  while (dayOfMonth > getDaysInMonth(nextMonth)) {
    nextMonth = addMonths(nextMonth, 1)
  }

  return setDate(nextMonth, dayOfMonth)
}

/**
 * Determines the first installment date based on the payment frequency and current date.
 * Adjusts for weekly, twice per month, and other payment intervals.
 * @typedef { 'Weekly' | 'EverySecondWeek' | 'TwicePerMonth' | 'Monthly' | 'Quarterly' | 'SemiAnnual' | 'Yearly' } InstallmentInterval
 * @param {{ installment_interval: 'Weekly' | 'EverySecondWeek', today: Date, installment_dates: string[] } | { installment_interval: InstallmentInterval, today: Date, installment_dates: number[] }} input
 * @return {Date} The first installment date.
 */
export function getFirstDay({
  installment_interval,
  today,
  installment_dates,
}) {
  if (
    installment_interval === 'Weekly' ||
    installment_interval === 'EverySecondWeek'
  ) {
    let next_day_of_week = getNextDayOfWeek({
      today,
      // @ts-ignore
      dayOfWeek: installment_dates[0],
    })
    return next_day_of_week
  } else if (installment_interval === 'TwicePerMonth') {
    let date1 = getNextDayOfMonth({ today, dayOfMonth: installment_dates[0] })
    let date2 = getNextDayOfMonth({ today, dayOfMonth: installment_dates[1] })
    return isBefore(date1, date2) ? date1 : date2
    // @ts-ignore
  } else return getNextDayOfMonth({ today, dayOfMonth: installment_dates[0] })
}

/**
 * Creates a list of installment dates for a year starting from the first payment date.
 * The list is tailored to the specific installment interval.
 * @param {{installment_interval: 'Weekly' | 'EverySecondWeek', today: Date, installment_dates: string[]} | { installment_interval: InstallmentInterval, today: Date, installment_dates: number[] }} input
 * @return {{id: number, date: Date, text: string}[]} A list of formatted installment dates for the year.
 *
 * https://bitbucket.org/vaxiom/axpm/src/693796fa8c48c566b729fddae80203a03c8525db/sources/core-reporting/core-reporting-application/src/main/java/com/axpm/modules/patient/businessportal/ui/ledger/transactionssidebar/paymentplan/PaymentPlanInstallmentDateComboBox.java#lines-82
 */
export function getRealizationDatesForAPaymentPlan({
  installment_interval,
  today,
  installment_dates,
}) {
  // @ts-ignore
  let first_date = getFirstDay({
    installment_interval,
    today,
    installment_dates,
  })

  let date = first_date
  let dates = []
  while (differenceInDays(date, first_date) < 365) {
    if (!isBefore(date, first_date)) {
      dates.push(date)
    }

    if (installment_interval === 'TwicePerMonth') {
      let other_date = getSecondPaymentDateInSameMonth({
        date,
        installment_dates,
        today: first_date,
      })
      if (
        other_date &&
        !isBefore(other_date, first_date) &&
        !isEqual(date, other_date)
      ) {
        dates.push(other_date)
      }
    }

    if (
      installment_interval === 'Weekly' ||
      installment_interval === 'EverySecondWeek'
    ) {
      date = addWeeks(date, 1)
    } else {
      let adjustedDate = addMonths(date, 1)
      let monthLength = getDaysInMonth(adjustedDate)
      // @ts-ignore
      date = setDate(adjustedDate, Math.min(installment_dates[0], monthLength))
    }
  }

  dates.sort((a, b) => a.getTime() - b.getTime())
  return dates.map((d, i) => ({
    id: i,
    date: d,
    text: format(
      utcToZonedTime(d, 'UTC'),
      installment_interval === 'Weekly' ||
        installment_interval === 'EverySecondWeek'
        ? 'EEEE, MMMM dd, yyyy'
        : 'MMMM dd, yyyy'
    ),
  }))
}

/**
 * For a 'twice per month' schedule, calculates the date of the second payment in each month.
 * @param {{date: Date, installment_dates: number[], today: Date}} input
 * @return {Date} The date of the other installment in the month.
 */
function getSecondPaymentDateInSameMonth({ date, installment_dates, today }) {
  let num_of_days = getDaysInMonth(date)
  let date1 = setDate(
    date,
    installment_dates[0] > num_of_days ? num_of_days : installment_dates[0]
  )

  let date2 = setDate(
    date,
    installment_dates[1] > num_of_days ? num_of_days : installment_dates[1]
  )

  let next_date = isEqual(date1, date) ? date2 : date1

  // If the next date is not after the current date, then return it
  if (isAfter(next_date, today) && differenceInDays(next_date, today) < 365) {
    return next_date
  }
}

/**
 * Estimates the number of payments that will occur within a specified number of months,
 * based on the frequency of the installment interval.
 * @param {{installment_interval: InstallmentInterval, num_of_months: number}} input
 * @return {number} The estimated number of installments in the given period.
 */
export function howManyInstallmentIn({ installment_interval, num_of_months }) {
  switch (installment_interval) {
    case 'Weekly':
      return num_of_months * 4
    case 'EverySecondWeek':
      return Math.floor((num_of_months * 4) / 2)
    case 'TwicePerMonth':
      return num_of_months * 2
    case 'Quarterly':
      return Math.floor(num_of_months / 3)
    case 'SemiAnnual':
      return Math.floor(num_of_months / 6)
    case 'Yearly':
      return Math.floor(num_of_months / 12)
    default:
      return num_of_months
  }
}
