import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_image = useDataValue({
    context: 'image_card',
    path: 'patient_image',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return {
      ...data,
      ...patient_image,
    }
  }, [data, patient_image])
}
