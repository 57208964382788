import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let status = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.status',
  })

  return useMemo(
    () => ({
      ...data,
      status,
    }),
    [data, status]
  )
}
