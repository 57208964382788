// this is an autogenerated file from App/Account/NewPatient/Content/Panes/Form/ReferralsSection/New/Confirm/Content/Referral/Value/Search/Patient/Patients/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_NewPatient_Content_Panes_Form_ReferralsSection_New_Confirm_Content_Referral_Value_Search_Patient_Patients($parent_company_id: uuid!, $search_term: String!) {
  vaxiom_persons(
    where: {
      _and: [
        { patient: { organization: { _id: { _eq: $parent_company_id } } } }
        { search_name: { _like: $search_term } }
      ]
    }
  ) {
    id
    _id
    first_name
    last_name
    contact_method_associations(
      where: { contact_method: { dtype: { _eq: "postal" } } }
    ) {
      id
      contact_method {
        id
        postal_address {
          id
          city
          state
        }
      }
    }
  }
}

`