import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change('medical-history')
  }
}
