import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let rulesetId = useDataValue({
    context: 'ruleset',
    path: 'id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    // remove Apply-to-All if it is already in other ruleset
    return rulesets.some(
      item => item.id !== rulesetId && item.division_id === null
    )
      ? data
      : [
          {
            _id: null,
            text: 'Apply to all divisions',
          },
          ...data,
        ]
  }, [data, rulesets, rulesetId])
}
