export let TYPE = {
  0: 'MedicalAlertBody',
  1: 'MedicalAlertBody',
  2: 'MedicalAlertBody',
  3: 'MedicalAlertBody',
  4: 'MedicalAlertBody',
  5: 'NotesBody',
}
export function getView(alertType) {
  return TYPE[alertType] || 'No'
}
