// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/CustomPlanRulesets/AddRuleset/TxPlanGroupChooser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_CustomPlanRulesets_AddRuleset_TxPlanGroupChooser($parent_company_id: numeric!) {
  vaxiom_tx_plan_groups(
    where: {
      pc_id: { _eq: $parent_company_id }
      deleted: { _eq: false }
      _not: { custom_payment_plan_ruleset: {} }
    }
    order_by: { name: asc }
  ) {
    _id
    name
  }
}

`