import { useDataValue, useDataChange } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import add_note from './add_note_mutation.graphql.js'

export function useOnSave(props) {
  let tx_id = useDataValue({
    context: 'treatment_card',
    path: 'txs.id',
    viewPath: props.viewPath,
  })
  let user_id = useDataValue({
    context: 'auth',
    path: 'access_token_data.vaxiom_user_id',
    viewPath: props.viewPath,
  })

  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'editor',
    path: 'note',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'editor',
    path: 'note',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeInsertNoteMutation] = useMutation(add_note)

  return async function onClick() {
    let mutationResponse = await executeInsertNoteMutation({
      note,
      target_id: tx_id,
      target_type: 'com.axpm.treatments.core.internal.domain.Treatment',
      user_id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    } else {
      notify(notifySuccess('Note created!'))
    }

    change('')
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
