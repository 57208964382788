import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getPayorForDiscount,
  getUpdatedPayorConfiguration,
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let discount = next.discounts[index]
      discount.percentage = value
      discount.type = 'percentage'

      if (discount.is_applied_to_payor) {
        let payor = getPayorForDiscount(discount, next)
        discount.amount = parseFloat(
          ((payor.share.amount * value) / 100).toFixed(2)
        )

        // update payor's downpayment/installment
        let { downpayment_amount, installment_amount, payment_plan_length } =
          getUpdatedPayorConfiguration(payor, next)
        payor.downpayment_amount = downpayment_amount
        payor.installment_amount = installment_amount
        payor.payment_plan_length = payment_plan_length
      } else {
        discount.amount = parseFloat(
          ((next.treatment_fee * value) / 100).toFixed(2)
        )

        if (discount.is_included_in_insurance_claim) {
          changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
        }

        changePayorsShare(next)
        changePayorsConfiguration(next)
      }
    })
  }
}
