import { useDataIsSubmitting, useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'steps',
    viewPath: props.viewPath,
  })

  let index = useDataValue({
    context: 'selected',
    path: 'index',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'changeStatus',
      value: 'completed',
      index,
    })
  }
}

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'steps',
    viewPath: props.viewPath,
  })

  let value = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return !validate(value) || isSubmitting
}

function validate(value) {
  return value.type === 'automation' ? isAutomationValid(value.event) : true
}

function isAutomationValid({ type, data, action_data }) {
  switch (type) {
    case 'appointment_bookings_state_update': {
      return data.appointment_type_ids.length && data.value_to
    }
    case 'txs_status_update': {
      return data.value_to.length
    }
    case 'custom_resource_insert': {
      return action_data?.task_basket_id
    }
    default: {
      return true
    }
  }
}
