import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let token_id = useDataValue({
    context: 'reusable_method',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.add_new = false
      next.token_id = token_id
      next.valid = true
    })
  }
}
