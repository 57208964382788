// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Ledger/New/PaymentsTransactions/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Ledger_New_PaymentsTransactions($patient_id: uuid!) {
  payments_transactions(
    where: { token: { person: { patient: { _id: { _eq: $patient_id } } } } }
    order_by: { created_at: desc }
  ) {
    id
    amount
    status
    reference_id
    # verified_at
    token {
      last_4: metadata(path: "last_4")
      exp: metadata(path: "exp")
    }
  }
}

`