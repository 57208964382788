import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return function onSubmit() {
    submit({ is_secure: true })
  }
}
