import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.location_frequencies?.length
      ? data.location_frequencies.map(item => ({
          id: item.type,
          text: item.label,
        }))
      : data.division_frequencies?.length
      ? data.division_frequencies.map(item => ({
          id: item.type,
          text: item.label,
        }))
      : data.parent_frequencies?.length
      ? data.parent_frequencies.map(item => ({
          id: item.type,
          text: item.label,
        }))
      : []
  }, [data])
}
