// this is an autogenerated file from App/Account/AutomatedEventNotifications/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_AutomatedEventNotifications($parent_company_id: uuid!) {
  apps_connections(
    where: {
      name: { _in: ["ids", "itero"] }
      app: {
        companies: {
          parent_company_id: { _eq: $parent_company_id }
          status: { _eq: granted }
        }
      }
    }
  ) {
    id
  }
}

`