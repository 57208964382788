// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/MoreActions/Content/Delete/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_MoreActions_Content_Delete_Content($id: numeric!) {
  update_vaxiom_patient_imageseries_by_pk(
    pk_columns: { id: $id }
    _set: { deleted: true }
  ) {
    id
    series_date
    name
    deleted
  }
}

`