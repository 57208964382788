import View from './view.js'
import React, { useEffect, useState } from 'react'
import useStableValue from 'Logic/useStableValue.js'
import useGoogleMapsPlacesAutocomplete from './useGoogleMapsPlacesAutoComplete.js'

export default function Logic(props) {
  let [selected, setSelected] = useState(null)
  // we use a debounced value to query for addresses so we don't hammer the service
  let stableAddress = useStableValue(props.value, 500)

  let autocomplete = useGoogleMapsPlacesAutocomplete({
    address: stableAddress,
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    autocompleteOptions: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  })

  // autocomplete.results stores the previous stableAddress' value when stableAddress === ''
  if (!stableAddress) autocomplete.results = []
  // update the top selected value when the list changes
  useEffect(() => {
    if (!autocomplete.isReady) return
    setSelected(
      autocomplete.results.length > 0 ? autocomplete.results[0].place_id : null
    )
  }, [autocomplete.results, autocomplete.isReady])

  let chooseAddress = async placeId => {
    if (typeof props.onChooseAddress === 'function') {
      // get the actual address because the one before doesn't have all the components
      let place = await autocomplete.getAddressForPlaceId(placeId)
      props.onChooseAddress(mapPlaceToAddress(place), props.id)
    }
  }

  let chooseSelectedAddress = () => selected && chooseAddress(selected)

  // keyboard navigation up and down
  let prevSuggestion = () => {
    let index = autocomplete.results.findIndex(
      item => item.place_id === selected
    )
    let prevIndex = index === 0 ? autocomplete.results.length - 1 : index - 1
    setSelected(autocomplete.results[prevIndex].place_id)
  }

  let nextSuggestion = () => {
    let index = autocomplete.results.findIndex(
      item => item.place_id === selected
    )
    let nextIndex = index === autocomplete.results.length - 1 ? 0 : index + 1
    setSelected(autocomplete.results[nextIndex].place_id)
  }

  // It appears that Chrome now ignores autocomplete="off" unless it is on th
  // <form autocomplete="off"> tag.
  // https://stackoverflow.com/a/16130452/1562732
  return (
    <View viewPath={props.viewPath} innerRef={props.innerRef}>
      <form autoComplete="off" onSubmit={event => event.preventDefault()}>
        <View
          {...props}
          chooseAddress={chooseAddress}
          chooseSelectedAddress={chooseSelectedAddress}
          from={autocomplete.results}
          nextSuggestion={nextSuggestion}
          prevSuggestion={prevSuggestion}
          onSubmit={props.onSubmit}
          selected={selected}
          value={props.value}
        />
      </form>
    </View>
  )
}
Logic.defaultProps = {
  value: '',
  id: '',
}

function findComponentInPlace(place, componentType, field = 'long_name') {
  let component = place.address_components.find(ac =>
    ac.types.includes(componentType)
  )

  if (!component) return ''
  return component[field]
}

function mapPlaceToAddress(place) {
  return {
    street: `${findComponentInPlace(
      place,
      'street_number'
    )} ${findComponentInPlace(place, 'route')}`.trim(),
    city:
      findComponentInPlace(place, 'locality') ||
      findComponentInPlace(place, 'sublocality') ||
      // Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1
      findComponentInPlace(place, 'sublocality_level_1'),
    state: findComponentInPlace(
      place,
      'administrative_area_level_1',
      'short_name'
    ),
    zip: findComponentInPlace(place, 'postal_code'),
  }
}
