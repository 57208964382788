import { useDataValue } from 'Simple/Data.js'
import { format } from 'date-fns-tz'

export default function useDataConfiguration(props) {
  let appointment_type_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_type_id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let start_date = useDataValue({
    context: 'date_select',
    path: 'start_date',
    viewPath: props.viewPath,
  })
  let end_date = useDataValue({
    context: 'date_select',
    path: 'end_date',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_id,
      appointment_type_id,
      start_date: format(start_date, 'yyyy-MM-dd'),
      end_date: format(end_date, 'yyyy-MM-dd'),
    },
    pause: !location_id || !appointment_type_id || !start_date || !end_date,
  }
}
