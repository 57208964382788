export function getView(appointment) {
  if (appointment.unplanned) return 'Unplanned'
  switch (appointment.booking.state) {
    case 'UNSEATED':
    case 'CHECKED_OUT':
      return 'CheckedOut'
    case 'SEATED':
      return 'Seated'
    case 'SCHEDULED':
    case 'CHECKED_IN':
      return 'Scheduled'
    default:
      return 'No'
  }
}
