import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import formatISO from 'date-fns/formatISO'
import { dateShortIn } from 'Data/format.js'

export default function useDataTransform(props, data) {
  let treatment_id = useDataValue({
    context: 'image_serie_form',
    path: 'treatment_id',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!treatment_id) return [] // prevent keeping the previous treatment's appointments
    if (!data?.length) return data

    return [
      {
        id: null,
        text: 'No appointment',
        start_date: formatISO(new Date(), { representation: 'date' }),
      },
      ...data.map(appointment => ({
        id: appointment.id,
        text: getName(appointment),
        start_date: appointment.appointment_bookings[0].local_start_date,
      })),
    ]
  }, [data, treatment_id])
}

function getName(appointment) {
  let date = dateShortIn(appointment.appointment_bookings[0].local_start_date)
  return appointment.type?.display_name
    ? `${date} - ${appointment.type.display_name}`
    : date
}
