// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CephTracing/ViewTraces/Content/AnalysesTree/Analyses/Content/Analysis/AnalysisSectionTitle/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CephTracing_ViewTraces_Content_AnalysesTree_Analyses_Content_Analysis_AnalysisSectionTitle($request_id: uuid!) {
  ceph_get_edit_analysis_url(request_id: $request_id) {
    edit_analysis_url
  }
}

`