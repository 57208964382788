import { useDataValue, useDataChange } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import query from './query.graphql.js'

export function useOnClick(props) {
  let file = useDataValue({
    context: 'patient_image',
    viewPath: props.viewPath,
  })
  let setSelectedFile = useDataChange({
    context: 'patient_documents',
    path: 'preview_file',
    viewPath: props.viewPath,
  })
  let client = useClient()
  let [, notify] = useNotifications()

  return async function onClick() {
    let res = await client
      .query(query, {
        resource_id: file.id,
        resource_type: 'image',
      })
      .toPromise()

    if (res.error) {
      return notify(notifyError(`Couldn't attach the selected patient image`))
    }

    setSelectedFile({
      resource_id: file.id,
      resource_type: 'image',
      name: res.data.resource_object_meta.name,
      type: res.data.resource_object_meta.content_type,
      size: res.data.resource_object_meta.size,
      preview_url: res.data.resource_object_meta.preview_url,
    })
  }
}

let IMAGE_NAME = {
  buccalCenter: 'Buccal center',
  buccalLeft: 'Buccal left',
  buccalRight: 'Buccal right',
  facialFrontal: 'Facial frontal',
  facialFrontalSmiling: 'Facial frontal smiling',
  facialLateral: 'Facial lateral',
  occlusalLower: 'Occlusal lower',
  occlusalUpper: 'Occlusal upper',
  xRayCephalometric: 'X-Ray cephalometric',
  xRayMolarLeft: 'X-Ray molar left',
  xRayMolarPreLeft: 'X-Ray molar pre left',
  xRayMolarPreRight: 'X-Ray molar pre right',
  xRayMolarRight: 'X-Ray molar right',
  xRayPanoramic: 'X-Ray panoramic',
}

export function name(value) {
  return IMAGE_NAME[value] || value || 'Misc'
}
