// this is an autogenerated file from DesignSystem/CommsThreadEventAttachments/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThreadEventAttachments($event_id: uuid!) {
  comms_events_attachments(where: { event_id: { _eq: $event_id } }) {
    id
    event_id
    name
    type
    url
  }
}

`