import { useDataValue } from 'Simple/Data.js'
import { useMutation, useClient } from 'Data/Api.js'
import { useUppy } from 'DesignSystem/FileUploader/react.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import query from './query.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

export function useOnSave(props) {
  let client = useClient()
  let uppy = useUppy(props)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let patient_id = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let patient_image = useDataValue({
    context: 'patient_image',
    viewPath: props.viewPath,
  })

  return async function onSave(file) {
    try {
      setFlowTo(normalizePath(props.viewPath, '../Saving/Content'))

      uppy.cancelAll()
      // generate a new image with a new id to avoid browser caching
      uppy.addFile({
        name: file.name,
        type: file.type,
        size: file.size,
        data: file,
      })
      await uppy.upload()
      let [image] = uppy.getFiles()

      let mutationResponse = await executeMutation({
        id: patient_image.id,
        file_key: image.meta.location.substring(
          image.meta.location.lastIndexOf('/') + 1
        ),
      })

      if (mutationResponse.error) {
        throw new Error(mutationResponse.error)
      }

      if (patient_image.slot_key === 'facialFrontalSmiling') {
        try {
          await client
            .query(
              query,
              {
                patient_id,
              },
              {
                requestPolicy: 'network-only',
              }
            )
            .toPromise()
        } catch (e) {}
      }

      notify(notifySuccess(`The image was saved successfully`))
    } catch (err) {
      notify(notifyError(`Couldn't save the image. Please try again.`))
    } finally {
      setFlowTo(normalizePath(props.viewPath, '../Saving/No'))
    }
  }
}
