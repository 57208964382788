import { useEffect, useState } from 'react'

/**
 * Prevent multiple executions of the same function to be performed in parallel.
 * It could occur even when debouncing, on a slow network when the request takes longer than the debounce period.
 * @param {*} fn function to execute
 * @returns
 */
export function useQueueAsync(fn) {
  let [running, setRunning] = useState(false)
  let [execution, setExecution] = useState()

  useEffect(() => {
    if (!execution || running) return

    async function execute() {
      setRunning(true)

      await fn(...execution.args)

      setExecution(null)
      setRunning(false)
    }

    execute()
  }, [execution, running]) // eslint-disable-line react-hooks/exhaustive-deps

  return function fn(...args) {
    // schedule the next execution with the provided arguments
    setExecution({ args })
  }
}
