import { useClient } from 'Data/Api.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import query from './query.graphql.js'
import React, { useEffect } from 'react'
import View from './view.js'

export default function Logic(props) {
  let number = useDataValue({
    context: 'thread',
    path: 'mobile_numbers_inbound[0].number',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'thread',
    path: 'parent_company_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'message_compose',
    viewPath: props.viewPath,
  })
  let client = useClient()

  useEffect(() => {
    let cancel = false

    async function run() {
      try {
        if (!number) return // prevent querying if no number selected

        let { data } = await client
          .query(
            query,
            {
              number,
              parent_company_id,
            },
            {
              requestPolicy: 'network-only',
            }
          )
          .toPromise()

        if (cancel) return

        submit({
          type: 'threadIdsHack',
          thread_ids: data.comms_events_sms.map(item => item.thread_id),
        })
      } catch (error) {}
    }
    run()
  }, [number, parent_company_id]) // eslint-disable-line
  // ignore change and client

  return <View {...props} />
}
