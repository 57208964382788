// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/UploadedImages/Content/Images/Content/TemporaryImage/Content/DeleteAction/Confirmation/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_UploadedImages_Content_Images_Content_TemporaryImage_Content_DeleteAction_Confirmation_Content($id: numeric!) {
  delete_vaxiom_temporary_images_by_pk(id: $id) {
    id
  }
}

`