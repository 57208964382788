import { useDataChange, useDataValue } from 'Simple/Data.js'
import { changePayorsConfiguration } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'discount_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (!value) return

    change(next => {
      let discount = next.discounts[index]
      discount.payor_person_id = value

      // recalculate discount based on the selected payor's share
      let payor = next.payors.find(item => item.person_id === value)
      discount.amount = parseFloat(
        ((payor.share.amount * discount.percentage) / 100).toFixed(2)
      )

      // recalculate the downpayment and installment amount of all payors as it could have switched from a different payor
      changePayorsConfiguration(next)
    })
  }
}
