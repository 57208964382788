import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let changeSelected = useDataChange({
    context: 'payment_method_selection',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    changeSelected(next => {
      next.value = value ?? 'custom'
      next.originalValue = value ?? 'custom'
    })
  }
}
