// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/PatientSelect/Content/Content/New/ReferralSource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_PatientSelect_Content_Content_New_ReferralSource($location_id: uuid!) {
  vaxiom_referral_templates(
    where: {
      _and: [
        { location: { _id: { _eq: $location_id } } }
        { deleted: { _eq: false } }
      ]
    }
  ) {
    id
    text: free_type
  }
}

`