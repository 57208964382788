import React from 'react'
import { DataProvider } from 'Simple/Data.js'
import { useOnSubmit } from './useOnSubmit'
import View from './view'

export default function Logic(props) {
  let onSubmit = useOnSubmit(props)

  return (
    <DataProvider
      context="directory_name"
      value=""
      onSubmit={onSubmit}
      viewPath={props.viewPath}
    >
      <View {...props} />
    </DataProvider>
  )
}
