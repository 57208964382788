import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'move_resources',
    path: 'patient_id',
  })
  let treatment_card_id = useDataValue({
    viewPath: props.viewPath,
    context: 'move_resources',
    path: 'source_treatment_card_id',
  })

  return {
    variables: { patient_id, treatment_card_id },
    pause: !patient_id || !treatment_card_id,
  }
}
