import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let search_term = useDataValue({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })

  return useMemo(() => {
    if (!data) return data
    if (search_term.length < 3) {
      return []
    }

    return data
  }, [data, search_term])
}
