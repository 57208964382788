// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Images/Misc/Content/PatientImage/Content/Content/Edit/Content/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Images_Misc_Content_PatientImage_Content_Content_Edit_Content_Content($id: numeric!, $file_key: String!) {
  update_vaxiom_patient_images_by_pk(
    pk_columns: { id: $id }
    _set: { file_key: $file_key }
  ) {
    id
  }
}

`