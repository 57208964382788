// this is an autogenerated file from App/Account/NewPatient/Content/newPatientMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_NewPatient_Content($patient: vaxiom_patients_insert_input!) {
  insert_vaxiom_patients_one(object: $patient) {
    _id
    id
    person {
      id
      first_name
      last_name
      greeting
    }
    professional_relationships {
      id
    }
  }
}

`