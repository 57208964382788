// this is an autogenerated file from App/Account/Chairs/Content/Appointments/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Chairs_Content_Appointments(
  $location_id: uuid!
  $local_start_date: date!
) {
  appointments_agenda_chairs_view(
    where: {
      location_id: { _eq: $location_id }
      local_start_date: { _eq: $local_start_date }
    } # the chairs filter happens on the client-side because of performance
  ) {
    id
    _id
    state
    duration
    start_time
    seat_time
    local_start_date
    local_start_time
    check_in_time
    provider_id
    provider_name
    assistant_id
    seated_chair_id
    seated_chair_uuid
    seated_chair_code
    patient_id
    patient_person
    appointment_id
    appointment__id
    appointment_type
    has_notes
    provider_call
  }
}

`