import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let patient = useDataValue({
    context: 'patient',
    viewPath: props.viewPath,
  })
  return {
    variables: {
      patient_id: patient?._id,
      organization_id: patient?.primary_location._id,
    },
    pause: !patient?._id || !patient?.primary_location._id,
  }
}
