import { useDataSubmit } from 'Simple/Data'

export function useOnRowClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'default_provider_location',
  })

  return function onRowClick(row) {
    submit({ value: !row.original.is_active })
  }
}
