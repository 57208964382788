// this is an autogenerated file from DesignSystem/Data/DataVaxiom/DataVaxiomTxCardFieldDefinitions/DataVaxiomTxCardFieldDefinitionsCardWrite/mutation-update.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_Data_DataVaxiom_DataVaxiomTxCardFieldDefinitions_DataVaxiomTxCardFieldDefinitionsCardWrite(
  $id: numeric!
  $object: vaxiom_tx_card_field_definitions_set_input!
) {
  update_vaxiom_tx_card_field_definitions_by_pk(
    pk_columns: { id: $id }
    _set: $object
  ) {
    id
    alert
    hide_for_future
    name
    short_name
    multi_value
    number
    tracked
    type_id
    mandatory
  }
}

`