// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/query-event-by-pk.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations($id: uuid!) {
  events_events_by_pk(id: $id) {
    id
    name
    data
    type
    status
    updated_at
    automation_type
    trigger_type
    source
    organization_id
    actions {
      id
      type
      data
      action_executions(limit: 1) {
        id
      }
    }
  }
}

`