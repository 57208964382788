import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(user => ({
      id: user.person.public_user.id,
      text: `${user.person?.title || ''} ${user.person.first_name} ${
        user.person.last_name
      } (${
        user.person.employment_contracts[0].employee_resources[0]?.resource
          ?.resource_type.name
      })`.trim(),
    }))
  }, [data])
}
