import { useMutation } from 'Data/Api.js'
import { useDataChange } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { login } from 'Data/format.js'
import {
  email as isValidEmail,
  vaxiom_password as isValidVaxiom_password,
  sixDigits as isValidSixDigits,
} from 'Data/validate.js'
import {
  notifyError,
  notifyInvalid,
  useNotifications,
} from 'Logic/Notifications.js'
import mutationMfa from './mutation-mfa.graphql.js'
import mutationSignIn from './mutation-sign-in.graphql.js'

export default function useDataOnSubmit(props) {
  let onActionSignIn = useDataOnActionSignIn(props)
  let onActionMfa = useDataOnActionMfa(props)

  return async function onSubmit({ value, change, args }) {
    switch (args.type) {
      case 'signin': {
        return onActionSignIn({ value, change, args })
      }
      case 'mfa': {
        return onActionMfa({ value, change, args })
      }
      default: {
      }
    }
  }
}

function useDataOnActionSignIn(props) {
  let [, notify] = useNotifications()
  let dataAuthChange = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let [, executeMutation] = useMutation(mutationSignIn)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value, change }) {
    if (isInvalid(value, notify)) return true

    let mutationResponse = await executeMutation(
      {
        email: value.email,
        password: value.password,
        skip_mfa_token: localStorage.getItem('auth_skip_mfa_token') || null,
      },
      {
        fetchOptions: {
          headers: {
            'x-hasura-role': 'public',
          },
        },
      }
    )

    if (mutationResponse.data?.vaxiom_users_login.status === 'mfa_required') {
      change(next => {
        next.mfa = {
          ...mutationResponse.data.vaxiom_users_login.mfa,
          skip_next: false,
        }
      })
      setFlowTo(normalizePath(props.viewPath, 'TwoFactorAuth'))
    } else if (
      mutationResponse.error ||
      mutationResponse.data.vaxiom_users_login.status !== 'ok'
    ) {
      notify(notifyError('Please verify your credentials'))
    } else {
      dataAuthChange(login(mutationResponse.data.vaxiom_users_login))
      setFlowTo(normalizePath(props.viewPath, '../Check'))
    }
  }

  function isInvalid(value, notify) {
    let list = [
      !isValidEmail(value.email) && 'email',
      // TODO replace for other platforms, this is only for vaxiom/core
      !isValidVaxiom_password(value.password) && 'password',
    ].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}

function useDataOnActionMfa(props) {
  let [, notify] = useNotifications()
  let dataAuthChange = useDataChange({
    viewPath: props.viewPath,
    context: 'auth',
  })
  let [, executeMutation] = useMutation(mutationMfa)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value }) {
    if (isInvalid(value, notify)) return true

    let mutationResponse = await executeMutation(
      {
        ticket: value.mfa.ticket,
        token: value.mfa.token,
        skip_next: value.mfa.skip_next,
      },
      {
        fetchOptions: {
          headers: {
            'x-hasura-role': 'public',
          },
        },
      }
    )

    if (
      mutationResponse.error ||
      mutationResponse.data.vaxiom_users_login_mfa.status !== 'ok'
    ) {
      return notify(
        notifyError('Please verify the two-factor authentication token')
      )
    }

    if (value.mfa.skip_next) {
      localStorage.setItem(
        'auth_skip_mfa_token',
        mutationResponse.data.vaxiom_users_login_mfa.skip_mfa_token
      )
    }

    dataAuthChange(login(mutationResponse.data.vaxiom_users_login_mfa))
    setFlowTo(normalizePath(props.viewPath, '../Check'))
  }

  function isInvalid(value, notify) {
    let list = [
      !isValidSixDigits(value.mfa.token) && 'two-factor auth token',
    ].filter(Boolean)

    if (list.length > 0) {
      notify(notifyInvalid(list))
      return true
    }
    return false
  }
}
