// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Resources/Content/Resources/Content/Resource/PublicUsers/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Resources_Content_Resources_Content_Resource_PublicUsers($id: uuid!) {
  users_by_pk(id: $id) {
    id
    vaxiom_person {
      id
      first_name
      last_name
    }
  }
}

`