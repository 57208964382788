import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.locations =
        locations.length === next.locations.length ? [] : locations
    })
  }
}
