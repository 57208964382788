// this is an autogenerated file from DesignSystem/DataVaxiomSysOrganizationsParentCompanyLocationSelect/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomSysOrganizationsParentCompanyLocationSelect_Location($parent_company_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      parent: { parent: { _id: { _eq: $parent_company_id } } }
    }
    order_by: { name: asc }
  ) {
    id
    _id
    text: name
  }
}

`