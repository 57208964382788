// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/Secondary/DiagnosisAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_Secondary_DiagnosisAction_Content($appointment_id: uuid!, $parent_company_id: uuid!) {
  vaxiom_diagnosis_create(
    appointment_id: $appointment_id
    parent_company_id: $parent_company_id
  ) {
    id
    _id
  }
}

`