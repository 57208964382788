// this is an autogenerated file from App/Account/ClockIn/Content/mutation-mfa.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_ClockIn_Content(
  $ticket: String!
  $token: String!
  $skip_next: Boolean
  $xid: String!
) {
  vaxiom_users_login_mfa(
    ticket: $ticket
    token: $token
    skip_next: $skip_next
    xid: $xid
  ) {
    status
    access_token: jwt_token
    refresh_token
    inactivity_timeout
  }
}

`