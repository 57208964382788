import { getIsoTimeStringDisplay } from 'Data/format.js'
import View from './view.js'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import React, { useState, useRef } from 'react'
import useOnClickOutside from '../../useOnClickOutside'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

export default function Logic(props) {
  let [isShowingTimePicker, setIsShowingTimePicker] = useState(false)
  let valueStart = props.value && props.value.start

  let isSelected =
    valueStart &&
    format(parseISO(valueStart), 'yyyy-MM-dd') ===
      format(props.day, 'yyyy-MM-dd')

  let time =
    valueStart &&
    props.slots &&
    props.slots.map(slot => slot.start).filter(start => start === valueStart)
      .length > 0
      ? props.timezone
        ? format(utcToZonedTime(valueStart, props.timezone), 'HH:mm aaa')
        : getIsoTimeStringDisplay(valueStart)
      : null

  let dayRef = useRef()
  useOnClickOutside(
    dayRef,
    () => isShowingTimePicker && setIsShowingTimePicker(false)
  )

  return (
    <View
      {...props}
      day={format(props.day, 'd')}
      dayDate={format(props.day, 'MM/dd/Y')}
      dayRef={dayRef}
      time={time}
      isShowingTimePicker={isShowingTimePicker}
      onClick={() => setIsShowingTimePicker(v => !v)}
      onChange={time => {
        props.onChange(time)
        setIsShowingTimePicker(false)
      }}
      isSelected={isSelected}
      disabled={props.isDisabled}
    />
  )
}
