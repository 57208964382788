import React, { useRef } from 'react'
import View from './view.js'
import useIsHovered from 'Logic/useIsHovered.js'
import { useFloating, flip, offset, useClientPoint } from '@floating-ui/react'

export default function Logic(props) {
  let timerRef = useRef(null)
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
  })

  let { refs, x, y, context } = useFloating({
    open: hovered,
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [
      flip({
        fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
      }),
      offset(4),
    ],
  })

  let { reference } = useClientPoint(context, {
    enabled: !hovered,
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnterTrigger={event => {
        timerRef.current = setTimeout(() => {
          hoverBind.onMouseEnter()
        }, 1000)
      }}
      onMouseMoveTrigger={event => {
        if (reference && typeof reference.onMouseMove !== 'undefined')
          reference.onMouseMove(event)
      }}
      onMouseEnterContent={() => {
        clearTimeout(timerRef.current)
      }}
      onMouseLeave={() => {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          hoverBind.onMouseLeave()
        }, 200)
      }}
      triggerRef={refs.setReference}
      contentRef={refs.setFloating}
      left={x}
      top={y}
    />
  )
}
