import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let image_serie_id = useDataValue({
    context: 'image_serie',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setImageSerieId = useDataChange({
    context: 'images_comparison_action',
    path: 'image_serie_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setImageSerieId(image_serie_id)
  }
}
