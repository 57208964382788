/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'refetch': {
        reExecuteQuery({ requestPolicy: 'network-only' })
        return
      }

      default: {
        return
      }
    }
  }
}
