// this is an autogenerated file from App/Account/Content/Patients/Content/Content/TopBar/Order/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_TopBar_Order($patient_id: uuid!) {
  orders_orders(where: { patient_id: { _eq: $patient_id } }, limit: 1) {
    id
    title
    status
  }
}

`