// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/DentalMonitoring/Empty/Practice/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_DentalMonitoring_Empty_Practice($doctor_id: uuid!) {
  dental_monitoring_practices(doctor_id: $doctor_id) {
    id
    text: name
  }
}

`