import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    setFlowTo(normalizePath(props.viewPath, 'ImageViewer/Content'))
  }
}

export function getImagePreviewSize(slot_key) {
  // show X-Rays in higher quality as those layouts are bigger
  return slot_key && slot_key.startsWith('x_ray') ? 1000 : 500
}
