// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/Add/Content/Note/Note/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes_Add_Content_Note_Note($organization_id: uuid!, $pattern: String!) {
  notes_templates(
    where: {
      organization_id: { _eq: $organization_id }
      name: { _ilike: $pattern }
      tags: { _contains: "appointment" }
    }
    order_by: { name: asc }
  ) {
    id
    value: url
    text: name
  }
}

`