import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let app_connections = useDataValue({
    context: 'app_connections',
    viewPath: props.viewPath,
  })
  let setSelectedAppConnectionId = useDataChange({
    context: 'app_connection_id',
    viewPath: props.viewPath,
  })
  let setActionAppConnection = useDataChange({
    context: 'event_action',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    setSelectedAppConnectionId(value)
    let app_connection = app_connections.find(item => item.id === value)
    setActionAppConnection(next => {
      next.data.app_id = app_connection.app.id
      next.data.connection_name = app_connection.name
    })
  }
}
