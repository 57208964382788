import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let parent_company_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'organization._id',
  })

  return {
    variables: {
      parent_company_id,
    },
  }
}
