import View from './view.js'
import React, { useMemo } from 'react'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'id',
      },
      {
        field: 'payment_id',
        headerName: 'payment_id',
      },
      {
        field: 'applied_type',
        headerName: 'applied_type',
      },
      {
        field: 'transaction.id',
        headerName: 'tx.id',
      },
      {
        field: 'transaction.amount',
        headerName: 'tx.amount',
      },
      {
        field: 'transaction.receivable_id',
        headerName: 'tx.receivable_id',
      },
      {
        field: 'transaction.dtype',
        headerName: 'tx.dtype',
      },
      {
        field: 'transaction.effective_date',
        headerName: 'tx.effective_date',
      },
      {
        field: 'transaction.notes',
        headerName: 'tx.notes',
      },
      // {
      //   field: 'transaction.payment_correction_detail.id',
      //   headerName: 'tx.pcd.id',
      // },
      // {
      //   field: 'transaction.payment_correction_detail.note',
      //   headerName: 'tx.pcd.note',
      // },
      // {
      //   field: 'transaction.payment_correction_detail.correction_type_id',
      //   headerName: 'tx.pcd.correction_type_id',
      // },
    ],
    []
  )
  return <View {...props} columnDefs={columnDefs} />
}
