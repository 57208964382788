import View from './view.js'
import React, { useMemo } from 'react'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'id',
      },
      {
        field: 'autopay_source',
        headerName: 'autopay_source',
      },
      {
        field: 'token_id',
        headerName: 'token_id',
      },
    ],
    []
  )
  return <View {...props} columnDefs={columnDefs} />
}
