import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let setSelectedCampaigns = useDataChange({
    context: 'tab',
    path: 'selected.campaigns',
    viewPath: props.viewPath,
  })

  return async function onClick(value) {
    let selected_campaign_ids = Array.isArray(value) ? value : [value]

    setSelectedCampaigns(selected_campaign_ids)
  }
}
