import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let note_id = useDataValue({
    context: 'note',
    path: '_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'move_resources',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(next => {
      if (next.note_ids.includes(note_id)) {
        next.note_ids = next.note_ids.filter(id => id !== note_id)
      } else {
        next.note_ids.push(note_id)
      }
    })
  }
}
