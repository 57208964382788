import { useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import isToday from 'date-fns/isToday'

export function useOnClick(props) {
  let tooth_chart_snapshot_id = useDataValue({
    context: 'event',
    path: 'appointment.tooth_chart_snapshot._id',
    viewPath: props.viewPath,
  })
  let start_time = useDataValue({
    context: 'event',
    path: 'appointment.appointment.booking.start_time',
    viewPath: props.viewPath,
  })
  let setToothChartSnapshotId = useDataChange({
    context: 'tab',
    path: 'tooth_chart_snapshot_id',
    viewPath: props.viewPath,
  })
  let setOpenToothChartTab = useDataChange({
    context: 'refresh',
    path: 'open_tooth_chart_tab',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    if (tooth_chart_snapshot_id) {
      // select current tooth chart on top bar section
      setToothChartSnapshotId(tooth_chart_snapshot_id)
      setOpenToothChartTab(Date.now())
    }

    if (!tooth_chart_snapshot_id || isToday(new Date(`${start_time}Z`))) {
      setFlowTo(normalizePath(props.viewPath, 'ToothChart/Content'))
    }
  }
}
