// this is an autogenerated file from App/Account/Content/Comms/Filter/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Comms_Filter_Content($data: jsonb) {
  insert_comms_user_preferences_one(
    object: { data: $data }
    on_conflict: { constraint: user_preferences_pkey, update_columns: [data] }
  ) {
    user_id
    location_ids: data(path: "location_ids")
    mobile_numbers_outbound_ids: data(path: "mobile_numbers_outbound_ids")
  }
}

`