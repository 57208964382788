// this is an autogenerated file from App/Account/Content/Financial/query-locations.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Financial($location_ids: [uuid!]!) {
  vaxiom_sys_organizations(where: { _id: { _in: $location_ids } }) {
    id
    _id
    name
    logo {
      id
      file_id
    }
    parent {
      id
      name
      logo {
        id
        file_id
      }
      parent {
        id
        logo {
          id
          file_id
        }
      }
    }
    postal_address {
      id
      address_line1
      address_line2
      city
      state
      zip
    }
  }
}

`