import React, { useEffect, useRef } from 'react'

import View from './view.js'
import { useDataSubmit } from 'Simple/Data.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'import_align_images',
    viewPath: props.viewPath,
  })
  let cancel = useRef(false)

  useEffect(() => {
    if (cancel.current) return

    cancel.current = true
    submit()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
