import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'showPatient',
      tab: 'treatment_card',
    })
  }
}

export function getInitialWithDot(rvalue) {
  return `${rvalue[0]}.`
}
