// Simple standard library of formatters

import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import _formatDate from 'date-fns/format'
import isValidDate from 'date-fns/isValid'
import parseDate from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import _formatNumber from 'number-format.js'
import { conformToMask } from 'text-mask-core'

// generic
export function not(value) {
  return !value
}

// time
export function timeISOIn(value) {
  return formatDate(value, 'iso', 'h:mm a')
}

export function dateShortIn(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd/yyyy')
}
export function dateShortOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'yyyy-MM-dd')
}

export function dateShortInFull(value) {
  return formatDate(value, 'yyyy-MM-dd', 'EEEE, MMMM do, yyyy')
}

export function dateShortInNoYear(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd')
}

export function dateyyyyMMddToMMyyyy(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/yyyy')
}

export function dateISOIn(value) {
  return formatDate(value, 'iso', 'MM/dd/yyyy')
}
export function dateISOOut(value) {
  return formatDate(value, 'MM/dd/yyyy', 'iso')
}

export function dateISOToeeeeMMMMdo(value) {
  return formatDate(value, 'iso', 'eeee MMMM do')
}

export function dateISOToDateAndTime(value) {
  return formatDate(value, 'iso', 'M/dd/y h.mm b').toLowerCase()
}

export function dateISOToTime(value) {
  return formatDate(value, 'iso', 'h:mma').toLowerCase()
}

export function localStartTime(value) {
  return _formatDate(parseISO('1970-01-01T' + value), 'h:mmaaa')
}

export function getTimeDisplay(value) {
  return _formatDate(parseISO(value.split('Z')[0]), 'HH:mm aaa')
}

export function timestampISOIn(value) {
  return formatDate(value, 'iso', 'HH:mm MM/dd/yyyy z')
}

export function age(date_of_birth) {
  return differenceInCalendarYears(new Date(), parseISO(date_of_birth))
}

export function formatDate(rvalue, formatIn, formatOut) {
  let value =
    formatIn === 'iso'
      ? parseISO(rvalue)
      : parseDate(rvalue, formatIn, new Date())
  return isValidDate(value) ? _formatDate(value, formatOut) : rvalue
}

// text
export function textToNumber(value) {
  return typeof value === 'string'
    ? parseFloat(value.replace(/[^0-9.]/g, ''), 10)
    : value
}

export function toSentence(value) {
  return Array.isArray(value)
    ? value.length === 0
      ? 'None'
      : value.join(', ')
    : null
}

export function removeWhitespace(value) {
  return value.replace(/\s/g, '')
}

export function trim(value) {
  return value.trim()
}

export function trimLeft(value) {
  return value.trimLeft()
}

export function trimRight(value) {
  return value.trimRight()
}

// numbers
export function percentage(value) {
  return formatNumber(value, '###.##%')
}

export function numberThousand(value) {
  return formatNumber(value, '#,##0.00')
}

export function numberMoney(value) {
  return `$${numberThousand(value)}`
}

export function formatNumber(value, format) {
  return typeof value === 'number' ? _formatNumber(format, value) : value
}

// lists
export function listLength(list) {
  return Array.isArray(list) ? list.length : 0
}

// things
let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function socialSecurityNumber(input) {
  if (input && SOCIAL_SECURITY_NUMBER.test(input)) return input
  let normalizedValue = input.replace(/[^0-9]/g, '')
  if (normalizedValue.length === 9) {
    let tokens = [
      normalizedValue.slice(0, 3),
      normalizedValue.slice(3, 5),
      normalizedValue.slice(5, 9),
    ]
    return tokens.join('-')
  }
  return input
}

let SOCIAL_SECURITY_NUMBER_TO_HIDE = /^\d{3}-\d{2}/
export function socialSecurityNumberHide(value) {
  return value && value.replace(SOCIAL_SECURITY_NUMBER_TO_HIDE, '•••-••')
}

let PHONE_NUMBER_US_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
let DEFAULT_PHONE_CODE = '+1'
export function phoneNumberUS(rvalue) {
  if (!rvalue || !rvalue.startsWith(DEFAULT_PHONE_CODE)) return rvalue

  return conformToMask(
    rvalue.replace(DEFAULT_PHONE_CODE, ''),
    PHONE_NUMBER_US_MASK
  ).conformedValue
}
