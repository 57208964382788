import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'ruleset',
    path: 'location',
    viewPath: props.viewPath,
  })

  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    change(locations.find(({ _id }) => _id === next))
  }
}
