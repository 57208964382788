import { useMemo } from 'react'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import startOfDay from 'date-fns/startOfDay'
import isPast from 'date-fns/isPast'
import isToday from 'date-fns/isToday'
import { useDataValue } from 'Simple/Data.js'

let ROLLBACK_STATES = {
  CHECKED_IN: 'Cancel check-in',
  SEATED: 'Cancel seating',
  UNSEATED: 'Cancel unseating',
  CHECKED_OUT: 'Cancel check-out',
}

/**
 * @see `com.axpm.modules.patient.businessportal.ui.tx.entry.AppointmentEntryPresenter#fillToolDetails`
 */
export default function useDataTransform(props, data) {
  let treatment_status = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'txs.status',
  })
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    let value = []

    let date = new Date(`${appointment.booking.start_time}Z`)
    if (treatment_status !== 'COMPLETED' && isFuture(date)) {
      value.push({ id: 'RESCHEDULE', text: 'Reschedule' })
    }
    if (isToday(date) || isFuture(date)) {
      value.push({ id: 'CANCEL', text: 'Cancel appointment' })
    }
    if (isToday(date) || isFuture(date)) {
      value.push({
        id: 'CHANGE_APPOINTMENT_TYPE',
        text: 'Change appointment type',
      })
    }
    if (isPast(date) || isToday(date)) {
      if (appointment.tooth_chart_snapshot) {
        value.push({ id: 'SHOW_TOOTH_CHART', text: 'Show tooth chart' })
      }
      if (appointment.has_images) {
        value.push({ id: 'SHOW_IMAGES', text: 'Show images' })
      }
    }
    if (appointment.is_diagnosis_required && appointment.diagnosis) {
      value.push({ id: 'SHOW_DIAGNOSIS', text: 'Show diagnosis' })
    }

    if (isPast(date) || isToday(date)) {
      value.push({ id: 'SHOW_AUDIT_LOG', text: 'Show audit log' })
    }
    value.push({
      id: 'SHOW_APPOINTMENT_HISTORY',
      text: 'Show appointment history',
    })

    if (
      (isPast(date) || isToday(date)) &&
      appointment.booking.state === 'SCHEDULED'
    ) {
      value.push({ id: 'NO_SHOW', text: 'No show' })
    }

    if (appointment.unplanned && appointment.type.is_treatment_starter) {
      value.push({
        id: 'CHANGE_TO_NORMAL_APPOINTMENT',
        text: 'Change to normal appointment',
      })
    }

    value.push({
      id: 'GENERATE_LETTERS',
      text: 'Generate letters',
    })

    if (appointment.type.is_virtual && appointment.booking.video_link) {
      value.push({
        id: 'RESEND_VIRTUAL_LINK',
        text: 'Resend virtual link',
      })
    }
    value.push({
      id: 'MOVE_TO_ANOTHER_TREATMENT',
      text: 'Move to another treatment',
    })
    value.push({
      id: 'GO_TO_APPOINTMENT',
      text: 'Go to appointment',
    })

    if (isToday(date) && appointment.booking.state in ROLLBACK_STATES) {
      // rollback previous state change
      value.push({
        id: 'ROLLBACK_APPOINTMENT_STATE',
        text: ROLLBACK_STATES[appointment.booking.state],
      })
    }

    return value
  }, [appointment, treatment_status])
}

/**
 * @returns true if the appointment's start time is tomorrow or later in the future
 */
function isFuture(date) {
  return isAfter(date, startOfDay(addDays(new Date(), 1)))
}
