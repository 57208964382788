import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'
import { filterByActiveTab } from '../Appointments/Content/helpers.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let appointment_bookings = useDataValue({
    context: 'appointment_bookings',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return data.map(item => ({
      ...item,
      text: `${item.text} (${
        filterByActiveTab(item.id, appointment_bookings).length
      })`,
    }))
  }, [data, appointment_bookings])
}
