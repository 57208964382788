import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let selected = useDataValue({
    context: 'tab',
    path: 'selected.statements.query',
    viewPath: props.viewPath,
  })

  if (!selected) {
    return { variables: { where: {} }, pause: true }
  }

  let where = {
    location_id: { _in: selected.location_ids },
    due_now_amount: { _gte: selected.min_due_now_amount },
  }
  if (selected.due_buckets.length > 0 && selected.due_buckets.length < 5) {
    where._or = selected.due_buckets.map(item => ({
      due_buckets: { _contains: [item] },
    }))
  }

  return {
    variables: { where },
    requestPolicy: 'network-only',
  }
}
