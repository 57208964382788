import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
    path: 'appointment._id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return {
    variables: { appointment_id, location_id },
    pause: !appointment_id,
  }
}
