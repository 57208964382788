import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let location_id = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.location_id',
  })

  return {
    variables: {
      location_id,
    },
    pause: !location_id,
  }
}
