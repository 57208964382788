import { useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

export function useOnClick(props) {
  let resource = useDataValue({
    context: 'resource',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  return async function onClick() {
    try {
      // eslint-disable-next-line compat/compat
      let file = await fetch(resource.preview_url).then(res => res.blob())
      // eslint-disable-next-line compat/compat
      let tempUrl = URL.createObjectURL(file)
      let aTag = document.createElement('a')
      aTag.href = tempUrl
      aTag.download = resource.file_name ?? resource.id
      document.body.appendChild(aTag)
      aTag.click()
      // eslint-disable-next-line compat/compat
      URL.revokeObjectURL(tempUrl)
      aTag.remove()
    } catch (error) {
      return notify(notifyError(`There was a problem downloading the file.`))
    }
  }
}
