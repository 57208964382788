import { useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onChange(value) {
    // FIXME: workaround - for some reason, submit is not executed properly in current stack
    setTimeout(() => {
      if (value) {
        submit({
          type: 'scheduling/setSlots',
          slots: value,
        })
      }
    }, 1)
  }
}
