import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let organization_id = useDataValue({
    context: 'note_templates_tab',
    path: 'location_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { organization_id },
    pause: !organization_id,
  }
}
