import { useDataValue, useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let selected_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })
  let persons = useDataValue({
    viewPath: props.viewPath,
    context: 'similar_persons',
  })

  let submit = useDataSubmit({
    context: 'new_patient',
    viewPath: props.viewPath,
  })
  return async function onClick(value) {
    let person = persons.find(p => p.id === selected_id)

    if (person.patient) {
      submit({
        type: 'redirect',
        person,
      })
    } else {
      submit({
        type: 'add_patient_to_person',
        person,
      })
    }
  }
}
