import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let refreshToothChartSnapshot = useDataChange({
    context: 'refresh',
    path: 'tooth_chart_snapshot',
    viewPath: props.viewPath,
  })
  return function onClick() {
    refreshToothChartSnapshot(Date.now())
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
