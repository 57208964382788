// this is an autogenerated file from DesignSystem/Data/DataVaxiom/DataVaxiomTxCardFieldDefinitions/DataVaxiomTxCardFieldDefinitionsCardWrite/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_Data_DataVaxiom_DataVaxiomTxCardFieldDefinitions_DataVaxiomTxCardFieldDefinitionsCardWrite($id: numeric!) {
  vaxiom_tx_card_field_definitions_by_pk(id: $id) {
    id
    alert
    hide_for_future
    name
    short_name
    multi_value
    number
    tracked
    type_id
    mandatory
  }
}

`