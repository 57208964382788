import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let parent_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data?.length) return data

    return data.map(item => ({
      ...item,
      division_id: item.parent._id,
      parent_id,
    }))
  }, [data, parent_id])
}
