// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Details/Office/Search/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Details_Office_Search_Content($current_location_xid: String!) {
  app_admin_users_access(
    where: { xid: { _eq: $current_location_xid } }
    order_by: { organization_name: asc }
  ) {
    id
    organization_id
  }
}

`