import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!props.organization_id || !props.show_example || !props.slot_key) {
      return null
    }

    return {
      ...data,
      slot_key: props.slot_key,
      label: props.label,
      organization_id: props.organization_id,
    }
  }, [
    data,
    props.slot_key,
    props.organization_id,
    props.label,
    props.show_example,
  ])
}
