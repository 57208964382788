// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Treatment/Content/Actions/Content/Questionnaires/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Treatment_Content_Actions_Content_Questionnaires_Content($tx_card_id: uuid!) {
  vaxiom_questionnaire(where: { tx_card: { _id: { _eq: $tx_card_id } } }) {
    id
    text: questionnaire_name
  }
}

`