// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/Schedule/TxPlan/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_Schedule_TxPlan($id: numeric!) {
  vaxiom_tx_plan_templates(
    where: { deleted: { _eq: false }, id: { _eq: $id } }
    limit: 1
  ) {
    id
    _id
    name
    tx_plan_template_appointments {
      id
      type {
        id
        display_name
        display_color_id
      }
      next_appointment_id
      interval_to_next
      duration
    }
  }
}

`