import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let responsibleParties = (data.person.to_person_relationships || [])
      .map(relationship => relationship?.from_person_record)
      .filter(Boolean)
      .map(person => ({
        id: person.id,
        text: `${person.first_name} ${person.last_name}`,
      }))

    let professionals = (data.professional_relationships || [])
      .flatMap(relationship => {
        switch (relationship.dtype) {
          case 'emploProfessionalRelationship':
            return (
              relationship.employee.provider?.employee_resources?.map(
                resource => ({
                  id: resource.employment_contract.person.id,
                  text: `${resource.employment_contract.person.first_name} ${resource.employment_contract.person.last_name} (Professional)`,
                })
              ) || []
            )
          case 'otherProfessionalRelationship':
            return [
              {
                id: relationship.other.person.id,
                text: `${relationship.other.person.first_name} ${relationship.other.person.last_name} (External professional)`,
              },
            ]
          default:
            return []
        }
      })
      .filter(Boolean)

    // add the currently selected patient too
    let patient = {
      id: data.person.id,
      text: `${data.person.first_name} ${data.person.last_name}`,
    }
    return [patient, ...responsibleParties, ...professionals]
  }, [data])
}
