// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Content/Insurances/Insurance/InsuranceCode/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Content_Insurances_Insurance_InsuranceCode{
  vaxiom_insurance_codes(
    order_by: { code: asc_nulls_last, title: asc_nulls_last }
  ) {
    id
    code
    title
  }
}

`