import { useMutation } from 'Data/Api'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'submit': {
        let mutationResponse = await executeMutation({
          description: value.description,
          expires_at: value.expires_at,
        })
        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }

        change(next => {
          next.created = true
          next.created_access_token =
            mutationResponse.data.auth_api_user_register.key
          next.created_secret =
            mutationResponse.data.auth_api_user_register.secret
        })

        notify(notifySuccess('Token created!'))

        return
      }
      case 'close': {
        change(originalValue)
        setFlowTo(normalizePath(props.viewPath, './No'))
      }
    }
  }
}
