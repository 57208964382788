import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let recipients = useDataValue({
    context: 'recipients',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.send_to_person_vaxiom_id = value
      next.send_to_person_id = recipients.find(item => item.id === value)?._id
      next.contact_method = {
        id: null,
        type: null,
        value: null,
      }
    })
  }
}
