// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/DentalMonitoring/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_DentalMonitoring($organization_id: numeric!) {
  vaxiom_application_properties(
    where: {
      organization_id: { _eq: $organization_id }
      message_key: { _eq: "dental_monitoring.enabled" }
      message_value: { _eq: "true" }
    }
  ) {
    message_key
    message_value
  }
}

`