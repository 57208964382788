import View from './view.js'
import React from 'react'
import { birthday, name, textInput } from 'Data/validate'
import { useDataIsSubmitting, useDataValue } from 'Simple/Data'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'new_patient',
  })
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'new_patient',
    path: 'person',
  })
  let shouldDisable = !(
    name(person.first_name) &&
    name(person.last_name) &&
    textInput(person.gender) &&
    birthday(person.birth_date)
  )

  return <View {...props} disabled={shouldDisable || isSubmitting} />
}
