// this is an autogenerated file from App/ChooseLocation/Locations/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_ChooseLocation_Locations($xid: String!) {
  app_admin_users_access(
    where: { organization_level: { _eq: "location" }, xid: { _eq: $xid } }
    order_by: { organization_name: asc }
  ) {
    id: organization_id
    name: organization_name
    xid
  }
}

`