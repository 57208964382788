import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'patient',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'showPatient',
      tab: 'images',
    })
  }
}
