// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentAutomations/insert-event-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentAutomations($object: events_events_insert_input!) {
  insert_events_events_one(object: $object) {
    id
  }
}

`