import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let apps = useDataValue({
    context: 'apps',
    viewPath: props.viewPath,
  })
  let app_id = useDataValue({
    context: 'tab',
    path: 'selected.app_id',
    viewPath: props.viewPath,
  })
  let connection_name = useDataValue({
    context: 'tab',
    path: 'selected.connection_name',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.parent_company._id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  return useMemo(() => {
    let app = apps.find(item => item.id === app_id)
    if (!data?.length) {
      let connection = app.metadata.connections.find(
        connection => connection.id === connection_name
      )
      return {
        connection_id: null,
        type: connection.level,
        resource_id:
          connection.level === 'company' ? parent_company_id : location_id,
        xid: app.xid,
      }
    }

    return {
      ...data[0],
      connection: {
        ...data[0].connection,
      },
    }
  }, [app_id, apps, connection_name, data, location_id, parent_company_id])
}
