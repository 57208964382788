// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Resources/Content/Resources/Content/Resource/CommsMobileNumbersOutbound/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Resources_Content_Resources_Content_Resource_CommsMobileNumbersOutbound($id: uuid!) {
  comms_mobile_numbers_outbound_by_pk(id: $id) {
    id
    number
    name
  }
}

`