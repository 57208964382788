import View from './view.js'
import React, { useMemo } from 'react'

export default function Logic(props) {
  let columnDefs = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'id',
      },
      {
        field: 'applied_payment.id',
        headerName: 'ap.id',
      },
      {
        field: 'applied_payment.payment_id',
        headerName: 'ap.payment_id',
      },
      {
        field: 'transaction.id',
        headerName: 'tx.id',
      },
      {
        field: 'transaction.amount',
        headerName: 'tx.amount',
      },
      {
        field: 'transaction.receivable_id',
        headerName: 'tx.receivable_id',
      },
      {
        field: 'transaction.dtype',
        headerName: 'tx.dtype',
      },
      {
        field: 'transaction.effective_date',
        headerName: 'tx.effective_date',
      },
      {
        field: 'transaction.notes',
        headerName: 'tx.notes',
      },
    ],
    []
  )
  return <View {...props} columnDefs={columnDefs} />
}
