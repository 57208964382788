// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images($image_series_id: numeric!) {
  vaxiom_patient_images(
    where: { imageseries_id: { _eq: $image_series_id } }
    order_by: { order: asc }
  ) {
    id
    _id
    file_key
    original_file_key
    type_key
    slot_key
    mime_type
    url
    sys_created
    imageseries {
      id
      series_date
    }
  }
}

`