import { useSignedPreviewUrl } from 'Data/s3'
import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let preview_url = useSignedPreviewUrl({
    url: props.url,
    size: props.size,
    viewPath: props.viewPath,
  })
  return (
    <View
      previewUrl={props.url?.startsWith('blob') ? props.url : preview_url}
      {...props}
    />
  )
}
