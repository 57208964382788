// this is an autogenerated file from App/Account/ClockIn/Content/mutation-login.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_ClockIn_Content($email: String!, $password: String!, $xid: String!) {
  vaxiom_users_login(email: $email, password: $password, xid: $xid) {
    status
    access_token: jwt_token
    refresh_token
    inactivity_timeout
    mfa {
      type
      ticket
    }
  }
}

`