import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(item => ({
      id: item.id,
      question: item.question?.question,
      answer: item.answer?.answer,
    }))
  }, [data])
}
