import { useMemo } from 'react'
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import { useDataValue } from 'Simple/Data.js'

let DIFFERENCE_DATE_MAP = {
  weeks: differenceInCalendarWeeks,
  months: differenceInCalendarMonths,
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let treatment = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment',
  })

  return useMemo(() => {
    if (!data && !treatment) return data

    let start_date =
      treatment.start_date || treatment.estimated_start_date
        ? new Date(treatment.start_date || treatment.estimated_start_date)
        : null

    let end_date =
      treatment.end_date || treatment.estimated_end_date
        ? new Date(treatment.end_date || treatment.estimated_end_date)
        : null

    let real_length = getPeriodLength(start_date, end_date, 'weeks')
    let weeks_passed = getPeriodLength(start_date, new Date(), 'weeks')
    let total_months = getPeriodLength(start_date, end_date, 'months')
    let months_passed = getPeriodLength(start_date, new Date(), 'months')
    let planned_length =
      data?.treatment_planned_length.aggregate?.sum?.interval_to_next ?? 0
    let delta_plan = planned_length - real_length
    let total_weeks = Math.max(planned_length, real_length)

    return {
      status: data?.status?.name,
      months_passed,
      total_months,
      planned_length,
      delta_plan,
      weeks_passed,
      total_weeks,
      percentage:
        real_length > 0 ? Math.round((weeks_passed / real_length) * 100) : 0,
    }
  }, [data, treatment])
}

function getPeriodLength(start_date, end_date, timeUnit) {
  if (!start_date || !end_date) return 0

  return DIFFERENCE_DATE_MAP[timeUnit](end_date, start_date)
}
