import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let refresh_treatment_plan_notes = useDataValue({
    context: 'refresh',
    path: 'treatment_plan_notes',
    viewPath: props.viewPath,
  })
  let submitTreatmentPlanNotes = useDataSubmit({
    context: 'tx_plan_notes',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refresh_treatment_plan_notes) return

    submitTreatmentPlanNotes({ type: 'reFetch' })
  }, [refresh_treatment_plan_notes]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitProgress

  return <View {...props} />
}
