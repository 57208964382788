import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let directory = useDataValue({
    context: 'document',
    viewPath: props.viewPath,
  })
  let setParentId = useDataChange({
    context: 'patient_documents',
    path: 'parent_id',
    viewPath: props.viewPath,
  })
  let setDirectories = useDataChange({
    context: 'patient_documents',
    path: 'directories',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setParentId(directory.id)
    setDirectories(value => {
      value.directories.push(directory)
    })
  }
}
