import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(action) {
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      default:
        return
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Delete/Content'))
  }

  function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Edit/Content'))
  }
}
