import { useDataChange, useDataValue } from 'Simple/Data.js'

export function getTreatmentCards(tx_cards) {
  return tx_cards.map(tx_card => ({
    id: tx_card._id,
    text: tx_card.name,
  }))
}

export function useOnChange(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let patient_id = useDataValue({
    context: 'tab',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let tx_cards = useDataValue({
    viewPath: props.viewPath,
    context: 'tx_cards',
  })

  return function onChange(value) {
    let tx_card = tx_cards.find(tx_card => tx_card._id === value)
    // prevent updating selected treatment if treatment cards displayed for the previous patient
    if (patient_id !== tx_card.patient._id) return

    change(next => {
      next.treatment_card_id = tx_card._id
      next.treatment_id = tx_card.txs._id
      next.image_series_id = null
      next.image_id = null
    })
  }
}
