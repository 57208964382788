// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CephTracing/PerformTrace/Content/Content/CalibrateImageAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CephTracing_PerformTrace_Content_Content_CalibrateImageAction($resource_id: uuid!, $token: String!) {
  ceph_upload_image(resource_id: $resource_id, token: $token) {
    calibration_url
    ceph_image_id
    ceph_request_id
  }
}

`