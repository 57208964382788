import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let data = useDataValue({
    context: 'tooth_chart_snapshot',
    path: 'display_data',
    viewPath: props.viewPath,
  })
  return <div dangerouslySetInnerHTML={{ __html: data }} />
}
