import { useMemo } from 'react'
import format from 'date-fns/format'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let auth = useDataValue({
    viewPath: props.viewPath,
    context: 'auth',
    path: 'access_token_data',
  })

  return useMemo(() => {
    let now = new Date()
    now.setSeconds(0)

    return {
      ...data,
      date: format(now, 'yyyy-MM-dd'),
      selected_id: auth.user_id,
      selected_vaxiom_user_id: auth.vaxiom_user_id,
      time: format(now, 'HH:mm:ss'),
    }
  }, [auth, data])
}
