// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/NoteTemplates/insert-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_NoteTemplates($object: notes_templates_insert_input!) {
  insert_notes_templates_one(object: $object) {
    id
    name
    tags
    organization_id
    updated_at
    updated_by_user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`