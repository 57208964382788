let specialCases = ['cbs']

export function snakeCaseToHumanReadable(str = '') {
  // Replace underscores with spaces
  str = str.replace(/_/g, ' ').toLowerCase()

  // Capitalize the first letter of the string
  str = str.replace(/^\w/, match => match.toUpperCase())

  // Convert special cases to upper case
  return str
    .split(' ')
    .map(item =>
      specialCases.includes(item.toLowerCase()) ? item.toUpperCase() : item
    )
    .join(' ')
}
