// Simple standard library of aggregators

export function not(value, valueCompare) {
  return value !== valueCompare
}

export function join(...values) {
  return values.join(' ')
}

export function joinNoSpace(...values) {
  return values.join('')
}

export function any(...values) {
  return values.find(Boolean)
}

export function fallbackIfSame(a, b, fallback) {
  return a === b ? fallback : a
}

export function equals(a, b) {
  return a === b
}

export function equalLength(a, b) {
  return a?.length === b?.length
}
