// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CephTracing/ViewTraces/Content/AnalysesTree/Analyses/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CephTracing_ViewTraces_Content_AnalysesTree_Analyses($image_serie_id: numeric!) {
  ceph_requests(
    where: {
      patient_image: { imageseries_id: { _eq: $image_serie_id } }
      status: { _eq: completed }
    }
    order_by: { created_at: desc_nulls_last }
  ) {
    id
    patient_image_id
    audax_analysis_type_name
    created_at
    updated_at
    files(
      where: {
        type: {
          _nin: [
            "text/plain"
            "application/json"
            "application/acx"
            "image/emf"
          ]
        }
      }
      order_by: [{ updated_at: desc_nulls_last }, { name: asc }]
    ) {
      id
      url
      type
      name
      updated_at
    }
  }
}

`