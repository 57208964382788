// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/PreviewSample/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_PreviewSample(
  $type: vaxiom_export_html_document_to_pdf_content_type!
  $content: String!
  $parent_company_id: uuid!
) {
  vaxiom_export_html_document_to_pdf(
    type: $type
    content: $content
    parent_company_id: $parent_company_id
  ) {
    url
  }
}

`