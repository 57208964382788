import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let ruleset = useDataValue({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let locations = [
      {
        _id: null,
        text: 'Apply to all locations',
      },
      ...data,
    ]

    // remove locations that are already in other rulesets
    return locations.filter(
      location =>
        !rulesets.some(
          item =>
            item.id !== ruleset.id &&
            item.location === location._id &&
            item.division === ruleset.division_id
        )
    )
  }, [data, rulesets, ruleset])
}
