// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/Internal/Tools/LeaveAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content_MessageTools_Internal_Tools_LeaveAction_Content(
  $user_id: uuid!
  $thread_id: uuid!
) {
  comms_thread_delete_user_from_resources(
    args: { user_id: $user_id, thread_id: $thread_id }
  ) {
    id
  }
}

`