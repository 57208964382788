import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(
    () => ({
      ...data,
      sidebar_toggles: getSidebarToggles(data),
    }),
    [data]
  )
}

function getSidebarToggles(data) {
  let value = localStorage.getItem('sidebar_toggles')
  return value ? JSON.parse(value) : data.sidebar_toggles
}
