import { DataProvider, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'
import React from 'react'
import View from './view.js'

let defaultValue = {
  name: '',
  number: null,
  call_forwarding_number: null,
}

export default function Logic(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return (
    <DataProvider
      context="phone_number"
      value={defaultValue}
      onSubmit={onSubmit}
      viewPath={props.viewPath}
    >
      <View viewPath={props.viewPath} />
    </DataProvider>
  )

  async function onSubmit({ value, change }) {
    // you need to make isInvalid
    // if (isInvalid(next.stuff)) return false

    let res = await executeMutation({
      ...value,
      parent_company_id,
    })

    if (res.error) {
      notify(notifyError(`There was a problem adding the phone number`))
      return false
    } else {
      notify(notifySuccess(`Number added!`))
      change(defaultValue)
    }
  }
}
