export function getView(status) {
  switch (status) {
    case 'active':
      return 'Connected'
    case 'expired':
    case 'revoked':
    case 'inactive':
      return 'Disconnected'
    default:
      return 'No'
  }
}
