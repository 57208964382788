// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Tools/AttachmentAction/InApp/Documents/Content/SystemFiles/FilesTree/ImageSeries/Body/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Tools_AttachmentAction_InApp_Documents_Content_SystemFiles_FilesTree_ImageSeries_Body($patient_id: numeric!) {
  vaxiom_patient_imageseries(
    where: { deleted: { _eq: false }, patient_id: { _eq: $patient_id } }
  ) {
    id
    name
    patient_id
  }
}

`