import React, { useEffect } from 'react'

import View from './view.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let refresh_events = useDataValue({
    context: 'refresh',
    path: 'events',
    viewPath: props.viewPath,
  })
  let submitEvents = useDataSubmit({
    context: 'events',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refresh_events) return

    submitEvents({ type: 'reFetch' })
  }, [refresh_events]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitProgress

  return <View {...props} />
}
