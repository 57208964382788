// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/App/Connection/Actions/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps_App_Connection_Actions($app_id: uuid!, $connection_name: String!) {
  apps_connections(
    where: { app_id: { _eq: $app_id }, name: { _eq: $connection_name } }
  ) {
    id
    name
    status
    app_id
  }
}

`