export function getView(layout) {
  return [
    'all_images',
    'misc',
    'videos',
    'x_ray_cephalometric',
    'x_ray_panoramic',
  ].includes(layout)
    ? 'No'
    : 'Content'
}
