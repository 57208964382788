import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let parent_company_id = useDataValue({
    context: 'selected',
    path: 'parent_company_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { parent_company_id },
    pause: !parent_company_id,
  }
}
