import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let address = useDataValue({
    viewPath: props.viewPath,
    context: 'new_email',
    path: 'email.address',
  })
  return {
    variables: {
      address,
    },
  }
}
