import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'appointments',
    viewPath: props.viewPath,
    path: 'tab',
  })

  return () => {
    change('past')
  }
}
