import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api'
import mutation from './mutation.graphql.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'

/** @type {import('Simple/Data').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let location = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      organization_id: location.vaxiom_id,
      value: args.value.toString(),
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess(`${args.value ? 'Enabled' : 'Disabled'} pay app.`))
  }
}
