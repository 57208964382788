import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue, useDataChange } from 'Simple/Data'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'value',
  })
  let change = useDataChange({
    context: 'selected',
    path: 'secure_value',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onClick() {
    let response = await executeMutation({ value })
    if (response.error) {
      notify(
        notifyError(
          `There was a problem getting the secure value. Please try again.`
        )
      )
      return
    }
    change(response.data.encrypt.value)
  }
}
