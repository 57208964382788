// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/Automations/AutomationsTable/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_Automations_AutomationsTable(
  $location_id: uuid!
  $app_id: uuid!
  $connection_name: String!
  $connection_level: connection_levels!
) {
  apps_automations(
    location_id: $location_id
    app_id: $app_id
    connection_name: $connection_name
    connection_level: $connection_level
  ) {
    id
    automations
  }
}

`