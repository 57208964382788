export default function makeDebug(type) {
  if (process.env.NODE_ENV !== 'development') {
    return function noop() {}
  }

  let logQueue = []
  let logTimeout = null

  return function debug(stuff) {
    logQueue.push(stuff)
    // eslint-disable-next-line
    cancelAnimationFrame(logTimeout)
    // eslint-disable-next-line
    logTimeout = requestAnimationFrame(() => {
      if (logQueue.length > 0) {
        console.debug(type, logQueue)
        logQueue = []
      }
    })
  }
}
