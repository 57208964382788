// this is an autogenerated file from DesignSystem/ImageCard/Content/Content/Actions/Edit/EditModal/Content/Editor/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_ImageCard_Content_Content_Actions_Edit_EditModal_Content_Editor($id: numeric!, $file_key: String!) {
  vaxiom_patient_images_update(image_id: $id, file_key: $file_key) {
    id
  }
}

`