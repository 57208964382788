import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'message_compose',
    viewPath: props.viewPath,
  })

  let changeStatus = useDataChange({
    context: 'load_more',
    viewPath: props.viewPath,
  })

  let events = useDataValue({
    viewPath: props.viewPath,
    context: 'events',
  })

  return function onClick() {
    changeStatus(next => {
      next.status = 'loading'
    })

    change(next => {
      let limit = next.limit

      next.oldLimit = limit
      next.oldCount = events.length
      next.limit = limit + 30
      next.pinToBottom = false
    })
  }
}
