import { dateISOIn, dateISOToDateAndTime } from 'Data/format.js'
import { date } from 'Data/validate.js'
import { useMemo } from 'react'

let columns = [
  {
    Header: 'Date',
    accessor: 'created_at',
    Cell: ({ value, row }) => {
      let cellValue = value ? dateISOToDateAndTime(value.toISOString()) : '-'
      if (row.index === 0) {
        return <b>{cellValue}</b>
      }
      return cellValue
    },
  },
  {
    Header: 'User',
    accessor: 'user',
    Cell: ({ value, row }) => {
      if (row.index === 0) {
        return <b>{value}</b>
      }
      return value
    },
  },
  {
    Header: 'Answer',
    accessor: 'answer',
    Cell: ({ value, row }) => {
      let cellValue
      if (typeof value === 'boolean') {
        cellValue = value ? 'Yes' : 'No'
      } else if (date(value)) {
        cellValue = value.toISOString
          ? dateISOIn(value.toISOString())
          : dateISOIn(value)
      } else if (Array.isArray(value)) {
        cellValue = value.join(', ')
      } else {
        cellValue = value?.toString() || ''
      }

      if (row.index === 0) {
        return <b>{cellValue}</b>
      }

      return cellValue
    },
  },
]
export function useTableData(data) {
  return useMemo(() => ({ columns, data }), [data])
}
