// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connections/Main/Content/Actions/Reconnect/Content/Processing/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connections_Main_Content_Actions_Reconnect_Content_Processing($id: uuid!) {
  apps_connections(
    where: { id: { _eq: $id }, status: { _in: [active] } }
    limit: 1
  ) {
    id
    status
    name
    user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`