import { useMemo } from 'react'
import { format } from 'date-fns'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    let appointment = data[0]
    let result = []
    result.push({
      id: appointment._id,
      author: appointment.created_by?.person,
      created_at: appointment.sys_created,
      action: 'created appointment',
    })
    appointment.appointment_bookings.forEach((appointment_booking, index) => {
      for (let state_change of appointment_booking.state_changes) {
        if (state_change.new_state === 'SCHEDULED') {
          result.push({
            id: state_change._id,
            author: getCreatedBy(appointment_booking),
            created_at: state_change.change_time,
            action: `${
              appointment.appointment_bookings.length > 1 && index !== 0
                ? 'rescheduled'
                : 'scheduled'
            } appointment for ${getStartsAt(appointment_booking)}`,
          })
        } else if (
          state_change.new_state === 'OFFICE_CANCELLED' ||
          state_change.new_state === 'PATIENT_CANCELLED'
        ) {
          result.push({
            id: state_change._id,
            author: getModifiedBy(appointment_booking),
            created_at: state_change.change_time,
            action:
              state_change.new_state === 'PATIENT_CANCELLED'
                ? 'cancelled on request from patient'
                : 'cancelled',
          })
        } else if (state_change.new_state === 'NO_SHOW') {
          result.push({
            id: state_change._id,
            author:
              getModifiedBy(appointment_booking) ||
              getCreatedBy(appointment_booking),
            created_at: state_change.change_time,
            action: `patient didn't show for ${getStartsAt(
              appointment_booking
            )}`,
          })
        }
      }
    })

    return result
  }, [data])
}

function getCreatedBy(appointment_booking) {
  // it is possible that it will return the wrong person if last_created_by was set to a wrong value during conversions
  // making sure it's showing it only when created by the patient
  let created_by_patient = appointment_booking.created_by_person?.patient
    ? appointment_booking.created_by_person
    : null
  return created_by_patient || appointment_booking.created_by?.person
}

function getModifiedBy(appointment_booking) {
  // it is possible that it will return the wrong person if last_updated_by was set to a wrong value during conversions
  // making sure it's showing it only when updated by the patient
  let updated_by_patient = appointment_booking.modified_by_person?.patient
    ? appointment_booking.modified_by_person
    : null
  return updated_by_patient || appointment_booking.modified_by?.person
}

function getStartsAt(appointment_booking) {
  return format(
    new Date(
      `${appointment_booking.local_start_date}T${appointment_booking.local_start_time}`
    ),
    'MMM d, yyyy · h:mm aa'
  )
}
