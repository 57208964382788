import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnDelete(props) {
  let event_action_index = useDataValue({
    context: 'event_action_item',
    path: 'index',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'automation',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onDelete() {
    submit({
      type: 'removeAction',
      index: event_action_index,
    })

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
