import { generateSignedUrl, useAwsS3Client } from 'Data/s3.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import View from './view.js'
import React, { useEffect } from 'react'

export default function Logic(props) {
  let params = useDataValue({
    context: 'message',
    path: 'params',
    viewPath: props.viewPath,
  })
  let s3 = useAwsS3Client({ viewPath: props.viewPath })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    let cancel = false

    async function run() {
      let signedUrl = await generateSignedUrl(s3, params.url, {
        download: true,
        filename: params.filename,
      })
      if (cancel) return

      let link = document.createElement('a')
      link.href = signedUrl
      link.download = params.filename
      link.click()

      setFlowTo(normalizePath(props.viewPath, '../No'))
    }
    run()

    return () => {
      cancel = true
    }
  }, [])

  return <View {...props} />
}
