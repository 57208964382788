import { processCapturedImage } from 'Logic/ImageEditor.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let [, notify] = useNotifications()
  let slot_key = useDataValue({
    context: 'image',
    path: 'slot_key',
    viewPath: props.viewPath,
  })
  let settings = useDataValue({
    context: 'settings',
    path: slot_key,
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'image',
    viewPath: props.viewPath,
  })

  return async function onChange(files) {
    if (!Array.isArray(files)) return
    let [src] = files
    if (!src) return

    let result = await processCapturedImage({ src, settings })
    if (result.error) {
      return notify(notifyError(`Couldn't process the image.`))
    }

    change(next => {
      next.original_image = result.dest
      next.editor_src = result.dest
    })
  }
}
