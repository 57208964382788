import { useMemo } from 'react'
import { useDataOriginalValue, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let rulesets_original = useDataOriginalValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })
  let rulesets_current = useDataValue({
    context: 'rulesets',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!rulesets_original?.length || !rulesets_current) {
      return data
    }

    let original_value = mapFrequencies(rulesets_original)
    let current_value = mapFrequencies(rulesets_current)

    let add_changes = addChanges(current_value)
    let delete_changes = deleteChanges(original_value, current_value)
    let update_changes = updateChanges(original_value, current_value)

    return {
      has_changes: Boolean(
        add_changes.length || delete_changes.length || update_changes.length
      ),
      add_changes,
      delete_changes,
      update_changes,
    }
  }, [data, rulesets_original, rulesets_current])
}

function mapFrequencies(values) {
  return values
    .map(value =>
      value.frequencies.map(frequency => ({
        ...frequency,
        organization_id:
          value.location_id || value.division_id || value.parent_id,
      }))
    )
    .flat()
}

function addChanges(current) {
  return current.filter(ruleset => !ruleset.id && ruleset.enabled)
}

function deleteChanges(original, current) {
  let deleted = original
    .map(or => or.id)
    .filter(or => or && !current.some(cr => cr.id === or))
  let disabled = current.filter(cr => cr.id && !cr.enabled).map(cr => cr.id)
  return [...deleted, ...disabled]
}

function updateChanges(original, current) {
  return current.filter(cr_item => {
    if (!cr_item.id || !cr_item.enabled) return false
    let or_item = original.find(or_item => or_item.id === cr_item.id)
    return !or_item
      ? false
      : or_item.organization_id !== cr_item.organization_id ||
          or_item.label !== cr_item.label ||
          allowedDatesChanges(or_item.allowed_dates, cr_item.allowed_dates) ||
          or_item.document_template_id !== cr_item.document_template_id
  })
}

function allowedDatesChanges(original, current) {
  return (
    original?.length !== current?.length ||
    current.some(date => !original.includes(date))
  )
}
