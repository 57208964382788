// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/Content/Content/Launchers/Content/apps-launcher-component-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps_Content_Content_Launchers_Content(
  $id: String!
  $resource_id: uuid!
  $launcher_id: String!
  $resource_type: String
) {
  apps_launcher_component_mount_details(
    id: $id
    resource_type: $resource_type
    resource_id: $resource_id
    launcher_id: $launcher_id
  ) {
    configuration
  }
}

`