import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let item = props.item || {}

  return (
    <View
      {...props}
      isSelected={props.isSelected && props.isSelected(item)}
      text={item[props.field]}
      banner={item.banner}
      onClick={e => {
        e.stopPropagation()
        props.onClick(props.id ? item[props.id] : item.id ?? item._id)
      }}
    />
  )
}
