// this is an autogenerated file from DesignSystem/ImageComparison/Content/Sections/Section/ImageTitle/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageComparison_Content_Sections_Section_ImageTitle_Content($id: numeric!) {
  vaxiom_patient_images_by_pk(id: $id) {
    id
    file_key
    type_key
    slot_key
    imageseries {
      id
      name
      series_date
    }
  }
}

`