// this is an autogenerated file from App/Account/NewPatient/Content/newEmployeeProfessionalRelationshipMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_NewPatient_Content(
  $input: vaxiom_employee_professional_relationships_insert_input!
) {
  insert_vaxiom_employee_professional_relationships_one(object: $input) {
    id
    provider_id
  }
}

`