import React, { useEffect } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data'
import View from './view.js'

export default function Logic(props) {
  let content = useDataValue({
    context: 'content',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    change(next => {
      next.note_template.content = content
      next.note_template.editor_enabled = true
      if (next.id) {
        next.note_template.original.content = content
      }
    })
  }, [])

  return <View {...props} />
}
