import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let patient_videos_enabled = useDataValue({
    viewPath: props.viewPath,
    context: 'app_properties',
    path: 'admin_patient_videos_enabled',
  })
  let itero_enabled = useDataValue({
    viewPath: props.viewPath,
    context: 'app_properties',
    path: 'itero_enabled',
  })
  let image_series = useDataValue({
    viewPath: props.viewPath,
    context: 'image_series',
  })
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let existing_scan = image_series.some(
      item => item.id === image_series_id && item.scans.length
    )

    return data.filter(
      option =>
        !['videos', 'itero'].includes(option.id) ||
        (option.id === 'videos' && !!patient_videos_enabled) ||
        (option.id === 'itero' && (!!itero_enabled || existing_scan))
    )
  }, [
    data,
    patient_videos_enabled,
    itero_enabled,
    image_series,
    image_series_id,
  ])
}
