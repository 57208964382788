// this is an autogenerated file from App/Account/WaitingRoom/Content/Content/Agenda/Appointments/Content/Appointment/Action/Action/CheckIn/Confirmation/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_WaitingRoom_Content_Content_Agenda_Appointments_Content_Appointment_Action_Action_CheckIn_Confirmation_Content($id: uuid!) {
  vaxiom_appointment_booking_check_in(id: $id) {
    id
  }
}

`