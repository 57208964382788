// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Notes/Content/NewOrEdit/Content/Actions/update_mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Notes_Content_NewOrEdit_Content_Actions($note_id: numeric!, $new_note: String!, $alert: Boolean) {
  update_vaxiom_notes_by_pk(
    pk_columns: { id: $note_id }
    _set: { alert: $alert, note: $new_note }
  ) {
    id
    note
  }
}

`