import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let refresh_treatment_card = useDataValue({
    context: 'refresh',
    path: 'treatment_card',
    viewPath: props.viewPath,
  })
  let submitTreatmentCard = useDataSubmit({
    context: 'treatment_card',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refresh_treatment_card) return

    submitTreatmentCard({ type: 'reFetch' })
  }, [refresh_treatment_card]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitTreatmentCard

  return <View {...props} />
}
