// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Content/Discounts/Content/Discount/Payor/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Content_Discounts_Content_Discount_Payor_Content($person_ids: [numeric!]!) {
  vaxiom_persons(
    where: { id: { _in: $person_ids } }
    order_by: { first_name: asc_nulls_last, last_name: asc_nulls_last }
  ) {
    id
    first_name
    last_name
  }
}

`