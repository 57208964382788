import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let setDivision = useDataChange({
    context: 'ruleset',
    path: 'division_id',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    setDivision(value?.length ? value[0].id : null)
  }
}
