/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'addHeader': {
        change(next => {
          if (!Array.isArray(next.req.headers)) {
            next.req.headers = []
          }

          next.req.headers = [...next.req.headers, { key: '', value: '' }]
        })

        break
      }
      case 'removeHeader': {
        change(next => {
          if (next.req.headers.length === 1) {
            next.req.headers = []
          } else {
            next.req.headers = next.req.headers.filter(
              (_, idx) => idx !== args.index
            )
          }
        })
        break
      }
      default: {
        break
      }
    }
  }
}
