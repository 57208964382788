import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    let receivable_id = prompt('Receivable ID (numeric)')
    if (!receivable_id) return
    window.postMessage({
      fakeDevelopmentCoreEmbedMessage: true,
      type: 'payments_post_payment',
      params: {
        patient_id,
        receivable_id: Number(receivable_id),
      },
    })
  }
}
