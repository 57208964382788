import { useMemo } from 'react'

import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })
  let tooth_chart_snapshot = useDataValue({
    context: 'tooth_chart_snapshot',
    viewPath: props.viewPath,
  })
  return useMemo(
    () => ({
      patient_id,
      appointment_id: tooth_chart_snapshot.appointment.id,
      booking_id: tooth_chart_snapshot.appointment.booking?.id,
      snapshot_id: tooth_chart_snapshot.id,
      tab: 'appointment_tooth_chart',
    }),
    [tooth_chart_snapshot, patient_id]
  )
}
