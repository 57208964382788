import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { toSentence } from 'Data/format.js'
import { numberZeroOrPositive } from 'Data/validate.js'

export function useOnSubmit(props) {
  let changes = useDataValue({
    context: 'ruleset_changes',
    viewPath: props.viewPath,
  })
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit() {
    let validation = validate(changes.update_changes, parent_company_id)
    if (validation.isInvalid) {
      notify(notifyError(`Invalid data: ${toSentence(validation.errors)}`))
      return false
    }

    let mutationResponse = await executeMutation({
      rulesets_to_delete: changes.delete_changes,
      rulesets_to_update: changes.update_changes.map(item => ({
        where: { id: { _eq: item.id } },
        _set: {
          organization_id:
            item.location_id || item.division_id || parent_company_id,
          value: item.max_discount,
        },
      })),
    })

    if (mutationResponse.error) {
      return notify(notifyError('Something went wrong. Please, try again.'))
    }

    return notify(notifySuccess(`Rulesets updated successfully!`))
  }
}

function validate(rulesets, parent_company_id) {
  let organizations = rulesets.map(
    item => item.location_id || item.division_id || parent_company_id
  )

  let errors = [
    organizations.some(
      (item, index) => organizations.indexOf(item) !== index
    ) && 'some rulesets are duplicated for the same organization',
    ...validateRulesets(rulesets),
  ].filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}

function validateRulesets(values) {
  return values
    .map((item, index) => {
      let errors = [
        !numberZeroOrPositive(item.max_discount) && 'invalid percentage',
      ].filter(Boolean)

      if (errors.length === 0) return null
      return `[card ${index + 1}] ${toSentence(errors)}`
    })
    .filter(Boolean)
}
