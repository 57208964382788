// this is an autogenerated file from App/Auth/ResetPassword/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_ResetPassword($email: citext!) {
  auth_reset_password_request: vaxiom_users_reset_password_request(email: $email) {
    ok
  }
}

`