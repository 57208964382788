import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

import { validate as validateDefinition } from '../Definitions/helpers.js'
import { validate as validateOptions } from '../Options/helpers.js'
import { validate as validateTypes } from '../Types/helpers.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'data',
    viewPath: props.viewPath,
  })
  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'data',
  })

  return (
    isSubmitting ||
    !validateDefinition(value.tx_card_field_definitions_csv) ||
    !validateOptions(value.tx_card_field_options_csv) ||
    !validateTypes(value.tx_card_field_types_csv)
  )
}
