import { useDataValue, useDataIsSubmitting } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'component',
    viewPath: props.viewPath,
  })

  let active = useDataValue({
    context: 'launcher',
    path: 'active',
    viewPath: props.viewPath,
  })

  return isSubmitting || !active
}

export function isComponent(value) {
  return value === 'component'
}
