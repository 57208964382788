import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let automation_type = useDataValue({
    context: 'automation',
    path: 'automation_type',
    viewPath: props.viewPath,
  })
  let custom_resource_types = useDataValue({
    context: 'custom_resource_types',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return data
      .filter(({ supported_automation_types }) =>
        supported_automation_types.includes(automation_type)
      )
      .filter(
        item =>
          item.id !== 'custom_resource_insert' ||
          custom_resource_types?.length > 0
      )
  }, [data, automation_type, custom_resource_types.length])
}
