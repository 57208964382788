import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return {
    variables: {
      location_id,
    },
  }
}
