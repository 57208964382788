import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      profile_url: props.profile_url,
      initials: props.initials,
    }
  }, [data, props.initials, props.profile_url])
}
