import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import mutation from './mutation.graphql.js'
import { toSentence } from 'Data/format.js'
import {
  TREATMENT_PAYMENT_PLAN_LENGTH_TYPES,
  TREATMENT_PAYMENT_PLAN_VALUE_TYPES,
} from 'Data/constants.js'
import { numberZeroOrPositive } from 'Data/validate.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export default function useDataOnSubmit(props) {
  let onActionOverride = useDataActionOnOverride(props)
  let onActionReset = useDataActionOnReset(props)

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'reset': {
        return onActionReset({ value, args })
      }

      case 'override':
      default: {
        return onActionOverride({ value, args })
      }
    }
  }
}

function useDataActionOnReset(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value, args }) {
    if (!window.confirm('Are you sure?')) return

    let response = await executeMutation({
      id: value.id,
      settings: {},
    })

    if (response.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Payment plan request settings reset'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataActionOnOverride(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onAction({ value, args }) {
    let validation = validate(value)
    if (validation.isInvalid) {
      return notify(
        notifyError(`Invalid data: ${toSentence(validation.errors)}`)
      )
    }

    let response = await executeMutation({
      id: value.id,
      settings: {
        allowed_steps: value.allowed_steps,
        autodraft_payment_methods: value.autodraft_payment_methods,
        downpayment_payment_methods: value.downpayment_payment_methods,
        custom_payment_plan_ruleset: {
          min_downpayment: value.custom_payment_plan_ruleset.min_downpayment,
          min_downpayment_type:
            value.custom_payment_plan_ruleset.min_downpayment_type,
          max_length: value.custom_payment_plan_ruleset.max_length,
          max_length_type: value.custom_payment_plan_ruleset.max_length_type,
        },
        max_discount: value.max_discount,
      },
    })

    if (response.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Payment plan request settings overriden'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function validate(value) {
  let errors = [
    ...validateSteps(value.allowed_steps),
    ...validateAutodraft(value.autodraft_payment_methods),
    ...validateDownpayment(value.downpayment_payment_methods),
    ...validateCustomPaymentPlanRuleset(value.custom_payment_plan_ruleset),
    ...validateMaxDiscount(value.max_discount),
  ].filter(Boolean)

  return {
    isInvalid: Boolean(errors.length),
    errors,
  }
}

function validateSteps(value) {
  return [
    !value?.length > 0 && 'missing steps',
    value.length && !value.includes('plan') && 'plan step is required',
    value.length && !value.includes('sign') && 'sign step is required',
  ]
}

function validateAutodraft(value) {
  return [!value?.length > 0 && 'missing autodraft payment methods']
}

function validateDownpayment(value) {
  return [!value?.length > 0 && 'missing down payment payment methods']
}

function validateCustomPaymentPlanRuleset(value) {
  let max_length = parseFloat(value.max_length)
  return [
    validateMinDownpayment(value.min_downpayment, value.min_downpayment_type),
    validateMaxLength(max_length, value.max_length_type),
    validateCustomPaymentPlanRulesetConsistency({ ...value, max_length }),
  ]
}

function validateMinDownpayment(value, type) {
  return !TREATMENT_PAYMENT_PLAN_VALUE_TYPES.includes(type)
    ? 'minimum downpayment type is required'
    : !numberZeroOrPositive(value)
    ? 'minimum downpayment must be a non-negative number'
    : type === 'percentage' && value > 100
    ? 'minimum downpayment percentage must be a number between 0 and 100'
    : false
}

function validateMaxLength(value, type) {
  return !TREATMENT_PAYMENT_PLAN_LENGTH_TYPES.includes(type)
    ? 'maximum length type is required'
    : !Number.isInteger(value)
    ? 'maximum length must be an integer'
    : type === 'total' && value < 0
    ? 'total maximum length must be a non-negative integer'
    : false
}

function validateCustomPaymentPlanRulesetConsistency(value) {
  if (
    value.min_downpayment === 100 &&
    value.min_downpayment_type === 'percentage' &&
    (value.max_length !== 0 || value.max_length_type !== 'total')
  ) {
    return 'a minimum downpayment of 100% on the payer fee requires a total maximum length of 0 months'
  } else if (
    value.max_length === 0 &&
    value.max_length_type === 'total' &&
    (value.min_downpayment !== 100 ||
      value.min_downpayment_type !== 'percentage')
  ) {
    return 'a total maximum length of 0 months requires a minimum downpayment of 100%'
  }

  return false
}

function validateMaxDiscount(value) {
  return [!numberZeroOrPositive(value) && 'invalid max discount']
}
