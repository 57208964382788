// this is an autogenerated file from DesignSystem/CommsThread/Content/Topbar/Sms/Phone/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_Topbar_Sms_Phone($number: String!, $parent_company_id: uuid!) {
  comms_events_sms(
    where: {
      mobile_number_inbound: {
        number: { _eq: $number }
        parent_company_id: { _eq: $parent_company_id }
      }
    }
  ) {
    id
    thread_id
  }
}

`