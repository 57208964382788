import startOfDay from 'date-fns/startOfDay'
import isAfter from 'date-fns/isAfter'

export function getView(last_event_id, event) {
  return (
    last_event_id === event.id &&
    isAfter(
      new Date(`${event.appointment.booking.start_time}Z`),
      startOfDay(new Date())
    )
  )
}
