// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentPaymentPlans/Configuration/Frequencies/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentPaymentPlans_Configuration_Frequencies_AddRuleset(
  $rulesets: [treatments_payment_plan_frequencies_insert_input!]!
) {
  insert_treatments_payment_plan_frequencies(objects: $rulesets) {
    affected_rows
    returning {
      id
      organization_id
      parent_id
      division_id
      location_id
      type
      label
      allowed_dates
      document_template_id
    }
  }
}

`