// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/App/Connection/Actions/Content/Connecting/Processing/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps_App_Connection_Actions_Content_Connecting_Processing($app_id: uuid!, $connection_name: String!) {
  apps_connections(
    where: {
      app_id: { _eq: $app_id }
      name: { _eq: $connection_name }
      status: { _in: [active] }
    }
    limit: 1
  ) {
    id
    status
    name
    display_name
    user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`