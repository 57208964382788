import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    // transform for TysiaSelectOne
    return data.vaxiom_appointment_templates.map(item => ({
      id: item.appointment_type._id,
      text: item.full_name_computed,
      color_id_computed: item.color_id_computed,
      template_id: item.id,
      is_default:
        item.id === data.vaxiom_default_appointment_templates[0]?.template?.id,
    }))
  }, [data])
}
