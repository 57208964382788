import { getUrls } from './helpers.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)
  let auth = useDataValue({
    context: 'auth',
    path: 'access_token_data',
    viewPath: props.viewPath,
  })
  let current_location = useDataValue({
    context: 'global',
    path: 'current_location',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ args, value, change }) {
    switch (args.type) {
      case 'login':
      case 'refresh': {
        try {
          let vaxiom_user_id = parseInt(auth.vaxiom_user_id, 10)
          let mutationResponse = await executeMutation({
            user_id: vaxiom_user_id,
          })
          if (mutationResponse.error) {
            change({ error: true })
            return
          }

          let urls = getUrls({
            section_type: props.section_type,
            location_id: current_location._id,
            vaxiom_location_id: current_location.id,
            user_id: auth.user_id,
            vaxiom_user_id,
            token: mutationResponse.data.vaxiom_users_generate_auth_token.token,
            params: props.params,
          })

          change({
            error: false,
            url: urls.url_login,
            ...urls,
          })
        } catch (error) {
          change({ error: true })
        }
        break
      }

      case 'logout': {
        // if (value.url_logout) {
        //   logout(value.url_logout)
        // }
        break
      }

      default: {
        break
      }
    }
  }
}

// TODO: try fetch with credentials and cores none
function logout(url) {
  let iframe = document.createElement('iframe')
  iframe.style.height = '0px'
  iframe.style.width = '0px'
  iframe.src = `${url}`
  iframe.addEventListener('load', () => {
    document.body.removeChild(iframe)
  })
  document.body.appendChild(iframe)
}
