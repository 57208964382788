import { useDataValue } from 'Simple/Data.js'
import { uuid } from 'Data/validate.js'

export default function useGetDefaultTab(props, data) {
  let thread_id_raw = useDataValue({
    context: 'flow_shortcuts',
    path: 'thread_id',
    viewPath: props.viewPath,
  })

  let location_ids = useDataValue({
    context: 'global',
    path: 'user.comms_preferences.location_ids',
    viewPath: props.viewPath,
  })

  let mobile_numbers_outbound_ids = useDataValue({
    context: 'global',
    path: 'user.comms_preferences.location_ids',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id) {
    if (maybeTab?.selected) return maybeTab

    let thread_id = uuid(thread_id_raw) ? thread_id_raw : null
    return {
      ...data,
      id,
      type: id,
      name: id,
      viewPath: props.viewPath,
      selected: {
        ...data.selected,
        thread_id: thread_id,
        pinned_thread_id: thread_id,
        location_ids,
        mobile_numbers_outbound_ids,
      },
    }
  }
}
