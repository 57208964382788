import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    return data.map(item => {
      return {
        ...item,
        text:
          item.text ||
          `${item.user.vaxiom_person.first_name} ${item.user.vaxiom_person.last_name}`,
      }
    })
  }, [data])
}
