import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !data.length) return 'custom'

    return data[0].message_value ?? 'custom'
  }, [data])
}
