import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let parent_id = useDataValue({
    context: 'patient_documents',
    path: 'parent_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    return data
      .filter(document => document.parent_id === parent_id)
      .sort((first, second) => first.type - second.type)
  }, [data, parent_id])
}
