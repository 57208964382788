import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(
    () => ({
      ...data,
      message: props.context.error?.message,
      stack: props.context.componentStack,
      resetError: props.context.resetError,
      onClickSignOut: props.onClickSignOut,
      id: props.context.eventId,
    }),
    [data, props.context, props.onClickSignOut]
  )
}
