// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CephTracing/PerformTrace/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CephTracing_PerformTrace_Content($resource_id: uuid!) {
  ceph_get_analysis_types(resource_id: $resource_id) {
    id
    analysis_types {
      id
      category
      text: name
    }
    birth_date
    gender
    patient_name
    token
  }
}

`