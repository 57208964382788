// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/DentalMonitoring/Content/Started/StopAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_DentalMonitoring_Content_Started_StopAction($id: uuid!) {
  dental_monitoring_patient_monitoring_stop(id: $id) {
    id
    patient {
      monitoring {
        status
        config {
          protocol_title
        }
      }
    }
  }
}

`