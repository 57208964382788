// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CephTracing/PerformTrace/Content/Content/AnalyzeAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CephTracing_PerformTrace_Content_Content_AnalyzeAction(
  $token: String!
  $analysis_type_id: String!
  $analysis_type_name: String!
  $birthdate: String!
  $calibration: numeric!
  $image_date: String!
  $image_id: String!
  $patient_id: String!
  $request_id: uuid!
  $sex: String!
) {
  ceph_create_order(
    token: $token
    analysis_type_id: $analysis_type_id
    analysis_type_name: $analysis_type_name
    birthdate: $birthdate
    calibration: $calibration
    image_date: $image_date
    image_id: $image_id
    patient_id: $patient_id
    request_id: $request_id
    sex: $sex
  ) {
    id
  }
}

`