import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'
import { textToNumber } from 'Simple/Data/format.js'
import { getNumOfInstallments } from '../helpers.js'

export default function useDataTransform(props, data) {
  let payment_plan_info = useDataValue({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  let {
    installment_amount: installment_amount_formatted,
    invoice_amount: invoice_amount_formatted,
  } = payment_plan_info

  let { invoice_amount, installment_amount } = useMemo(() => {
    return {
      invoice_amount: textToNumber(invoice_amount_formatted),
      installment_amount: textToNumber(installment_amount_formatted),
    }
  }, [installment_amount_formatted, invoice_amount_formatted])

  return useMemo(() => {
    if (invoice_amount === 0 || installment_amount === 0) return data
    let lhs = '',
      rhs = ''
    let num_of_installments = getNumOfInstallments({
      due_amount: invoice_amount,
      installment_amount,
    })
    let last_installment_amount = parseFloat(
      (num_of_installments > 1
        ? invoice_amount - (num_of_installments - 1) * installment_amount
        : 0
      ).toFixed(2)
    )
    if (
      last_installment_amount > 0 &&
      last_installment_amount < installment_amount
    ) {
      lhs = `${num_of_installments - 1} installment${
        num_of_installments > 2 ? 's' : ''
      } @ $${installment_amount} + 1 installment @ $${last_installment_amount} =`
      rhs = `$${invoice_amount}`
    } else if (last_installment_amount === installment_amount) {
      lhs = `${num_of_installments} installments @ $${installment_amount} =`
      rhs = `$${invoice_amount}`
    } else {
      lhs = `1 installment @ $${installment_amount} =`
      rhs = `$${installment_amount}`
    }

    return { lhs, rhs }
  }, [installment_amount, invoice_amount, data])
}
