import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let patient_images = useDataValue({
    viewPath: props.viewPath,
    context: 'patient_images',
    format: value => {},
  })

  let person = useDataValue({
    context: 'patient',
    path: 'person',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data || !patient_images) return []

    return Array.isArray(patient_images)
      ? patient_images
          ?.filter(item => item.mime_type?.startsWith('video/'))
          .map(({ mime_type, file_key, id, url, sys_created }, index) => ({
            id,
            url,
            file_id: id,
            file_name: `${person.first_name} ${person.last_name} ${index + 1}`,
            content_type: mime_type,
            created_at: sys_created,
          }))
          .reverse()
      : []
  }, [data, patient_images, person])
}
