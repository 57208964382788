import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    change(inext => {
      let location = locations.find(({ _id }) => _id === next)
      inext._id = location._id
      inext.division_id = location.parent?._id
    })
  }
}
