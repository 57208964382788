export function getView(
  connection_resource_status,
  connection_status,
  connection_name
) {
  // only allow setting up automations for iTero and if the connection is active and enabled for the parent compant/location
  return (
    connection_name === 'itero' &&
    connection_resource_status === 'enabled' &&
    connection_status === 'active'
  )
}
