import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let members = useDataValue({
    viewPath: props.viewPath,
    context: 'group',
    path: 'members',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onClick() {
    submit({
      type: 'scheduling/selectSlot',
      slot_id: members[0].id,
    })
  }
}
