import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'edited_note',
  })

  return function onClick() {
    change(next => {
      next.id = null
      next.note = ''
      next.state = 'none'
    })
  }
}
