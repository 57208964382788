import arrayToSentence from 'array-to-sentence'
import React, { useContext, useMemo, useReducer } from 'react'

export let SET = 'SET'
export let UPDATE = 'UPDATE'
export let REMOVE = 'REMOVE'
export let OK = 'OK'
export let ERROR = 'ERROR'

let randomString = () => Math.random().toString(36).substr(7)

let NotificationsContext = React.createContext({})

function reducer(state, action) {
  switch (action.type) {
    case SET: {
      return [
        ...state,
        {
          status: action.status,
          message: action.message,
          id: action.id,
          timeout_id: action.timeout_id,
        },
      ]
    }
    case UPDATE: {
      return state.map(notification =>
        notification.id === action.id
          ? {
              status: action.status,
              message: action.message,
              id: action.id,
              timeout_id: action.timeout_id,
            }
          : notification
      )
    }

    case REMOVE: {
      return state.filter(notification => notification.id !== action.id)
    }

    default: {
      throw new Error(`Action not implemented ${JSON.stringify(action)}`)
    }
  }
}

export function Notifications(props) {
  let [notifications, dispatch] = useReducer(reducer, [
    // comment out if you want test notifications to design it
    // {
    //   id: 1,
    //   message: 'This is a notification',
    //   status: 'OK',
    // },
    // {
    //   id: 2,
    //   message: 'This is a notification error',
    //   status: 'ERROR',
    // },
  ])

  let context = useMemo(() => {
    function notify({ status = OK, message }, options = {}) {
      let { hideAfter, extendExistingNotification } = {
        hideAfter: status === ERROR ? 5000 : 3200,
        extendExistingNotification: false,
        ...options,
      }

      let notification = notifications.find(
        notification =>
          notification.status === status && notification.message === message
      )
      if (extendExistingNotification && notification) {
        clearTimeout(notification.timeout_id)

        let timeout_id = setTimeout(() => {
          dispatch({ status, message, id: notification.id, type: REMOVE })
        }, hideAfter)

        dispatch({
          status,
          message,
          id: notification.id,
          timeout_id,
          type: UPDATE,
        })
      } else {
        let id = Math.random()

        let timeout_id = setTimeout(() => {
          dispatch({ status, message, id, type: REMOVE })
        }, hideAfter)

        dispatch({ status, message, id, timeout_id, type: SET })
      }

      return message
    }

    return [notifications, notify]
  }, [notifications])

  return (
    <NotificationsContext.Provider value={context}>
      {props.children}
    </NotificationsContext.Provider>
  )
}

export let useNotifications = () => useContext(NotificationsContext)

export let notifyInvalid = (fields, list) => ({
  status: ERROR,
  id: randomString(),
  message: createMessage(fields, list),
})

export let notifyError = (message = null) => ({
  status: ERROR,
  id: randomString(),
  message: message || 'Something went wrong. Please, try again.',
})

export let notifySuccess = (message = '') => ({
  message,
  status: OK,
  id: randomString(),
})

function createMessage(fields, list) {
  switch (list) {
    case 'password':
      return 'Make sure your password has minimum 8 characters, one number, one capital letter, one special character !@&'
    default:
      return `Please check: ${arrayToSentence(fields, {
        lastSeparator: ' & ',
      })}`
  }
}
