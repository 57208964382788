import { useDataValue } from 'Simple/Data'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, change }) {
    let rulesets = value.frequencies
      .filter(frequency => frequency.enabled)
      .map(frequency => ({
        organization_id:
          value.location_id || value.division_id || parent_company_id,
        type: frequency.type,
        label: frequency.label,
        document_template_id: frequency.document_template_id,
        allowed_dates: frequency.allowed_dates,
      }))

    let validateResult = validation(rulesets)
    if (validateResult.isInvalid) {
      return notify(
        notifyError(`Invalid ruleset: ${validateResult.errors.join(', ')}`)
      )
    }

    let res = await executeMutation({
      rulesets,
    })

    if (res.error) {
      let message = `There was a problem adding the ruleset.`
      let errorCode = res.error.graphQLErrors?.[0]?.extensions?.code
      if (errorCode === 'constraint-violation') {
        message = 'This ruleset already exists!'
      }
      return notify(notifyError(`${message}`))
    } else {
      notify(notifySuccess(`Ruleset added!`))
      change(originalValue)
    }
  }
}

function validation(value) {
  let errors = [
    !value && 'missing data',
    !value.length && 'at least one frequency is required',
    !value.map(frequency => frequency.type).includes('None') &&
      '"None" frequency is required for full payment',
    value
      .filter(frequency => frequency.type !== 'None')
      .some(frequency => !frequency.allowed_dates.length) &&
      'allowed payment dates are required',
    value.some(frequency => !frequency.document_template_id) &&
      'document template is required',
  ].filter(Boolean)

  return {
    errors,
    isInvalid: errors.length,
  }
}
