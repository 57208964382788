import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'
import { v4 as uuid } from 'uuid'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let thread = useDataValue({
    viewPath: props.viewPath,
    context: 'thread',
  })

  /*
     // TODO: untangle global scope and tab scope from the component
     - global used to sync messages and attachments from the likes of send secure image/document
     - tab used to sync thread message drafts and a pinned thread hack

     - also in:
     CommsThread/Content/useDataOnChange.js
     CommsThread/Content/MessageTools/External/Tools/AttachmentAction/InApp/AutoUploadAttachments/logic.js
     CommsThread/Content/MessageTools/Internal/ParticipantsList/Content/useDataConfiguration.js
     CommsThread/Content/MessageTools/Internal/Tools/AttachmentAction/Internal/AutoUploadAttachments/logic.js
     CommsThread/Content/Topbar/Info/Sms/logic.js
     CommsThread/Content/Topbar/Info/Internal/helpers.js
     CommsThread/Content/MessageTools/Internal/Tools/LeaveAction/Content/helpers.js
  */

  return useMemo(() => {
    if (!data || !thread) return data

    if (props.message_composes?.[thread.id]) {
      return props.message_composes[thread.id]
    }

    let result = {
      ...data,
      thread_id: thread.id,
      allowed_to_send_from: thread.allowed_to_send_from,
      send_from_id: thread.allowed_to_send_from[0]?.id || null,
      message: props.message || '',
      email: null,
      is_secure: typeof props.is_secure === 'boolean' ? props.is_secure : true,
      status: thread.status !== 'new' ? thread.status : 'open',
      is_upload_open: false,
      reset: uuid(), // used to signal that should reset Uppy after message sent
      thread_ids_hack: props.thread_ids_hack,
      pinToBottom: true,
      auto_uploaded_attachments: props.auto_uploaded_attachments || [],
      tags: props.tags || [],
    }

    if (thread.type === 'sms') {
      // get the last event's outbound number
      let from_number = thread.events[0]?.sms.mobile_number_outbound
      // ensure it's in the list of allowed numbers
      if (
        from_number &&
        thread.allowed_to_send_from.some(item => item.id === from_number.id)
      ) {
        result.send_from_id = from_number.id
      }

      result.to_number = thread.mobile_numbers_inbound?.[0].number || null
    }

    return result
  }, [
    data,
    thread,
    props.is_secure,
    props.message_composes,
    props.message,
    props.auto_uploaded_attachments,
    props.thread_ids_hack,
    props.tags,
  ])
}
