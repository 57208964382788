let tableData = {
  columns: [
    {
      Header: 'Method',
      accessor: 'payment_method',
    },
    {
      Header: 'Test data',
      accessor: 'test_data',
    },
    {
      Header: 'CVV',
      accessor: 'cvv',
    },
  ],
  data: [
    { payment_method: 'Visa', test_data: '4124939999999990', cvv: '123' },
    { payment_method: 'MasterCard', test_data: '5472063333333330', cvv: '321' },
    { payment_method: 'Discover', test_data: '6011308744444440', cvv: '456' },
    {
      payment_method: 'AMEX',
      test_data: '370000999999990',
      cvv: '654',
    },
    {
      payment_method: 'ACH account',
      test_data: 'Any eg 102103407',
      cvv: 'N/A',
    },
    {
      payment_method: 'ACH routing',
      test_data: '102103407',
      cvv: 'N/A',
    },
  ],
}

export function getTableData() {
  return tableData
}
