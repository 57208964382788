import React from 'react'
import FileUploaderProvider from '../FileUploaderProvider/react'
import FileUploaderDashboardModal from '../FileUploaderDashboardModal/react'

export default function FileUploader(props) {
  return (
    <FileUploaderProvider {...props}>
      <FileUploaderDashboardModal {...props} />
      {typeof props.children === 'function' ? props.children() : props.children}
    </FileUploaderProvider>
  )
}

// re-export hooks for convenience
export { useUppy, useUppyPlugin } from '../FileUploaderProvider/react'
