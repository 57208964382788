import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let image_serie_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { image_serie_id },
    pause: !image_serie_id,
    requestPolicy: 'cache-and-network',
  }
}
