import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onClick(value) {
    switch (value) {
      case 'TOGGLE_DELETED_IMAGE_SERIES':
        return toggleDeletedImageSeries()
      default:
        return
    }
  }

  function toggleDeletedImageSeries() {
    change(next => {
      next.selected.images.show_deleted_image_series =
        !next.selected.images.show_deleted_image_series
    })
  }
}
