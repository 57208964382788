// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsEdit/Content/Email/Emails/Content/Email/Edit/Content/addPersonContactMethodsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsEdit_Content_Email_Emails_Content_Email_Edit_Content(
  $person_id: numeric!
  $contact_methods: json!
  $contact_method_id: numeric!
) {
  add_person_contact_methods(
    args: { _person_id: $person_id, _contact_methods: $contact_methods }
  ) {
    id
  }
  delete_vaxiom_contact_method_associations(
    where: {
      contact_method_id: { _eq: $contact_method_id }
      person_id: { _eq: $person_id }
    }
  ) {
    affected_rows
  }
}

`