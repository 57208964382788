// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Documents/Navigation/Actions/Upload/Content/Modal/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Documents_Navigation_Actions_Upload_Content_Modal(
  $name: String!
  $patient_id: numeric!
  $parent_id: numeric
  $file_uid: String!
) {
  insert_vaxiom_document_tree_nodes_one(
    object: {
      name: $name
      patient_id: $patient_id
      parent_id: $parent_id
      file_uid: $file_uid
      type: "file"
      created_from: "DOCUMENTS"
    }
  ) {
    id
  }
}

`