import { useEffect, useMemo } from 'react'
import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'
import { dateISOToDateAndTime } from 'Data/format.js'

import Connection from './Connection/index.js'
import Locations from './Locations/index.js'
import Actions from './Actions/index.js'

import View from './view.js'

export default function Logic(props) {
  let connections = useDataValue({
    context: 'connections',
    viewPath: props.viewPath,
  })

  let refetch = useDataValue({
    context: 'tab',
    path: 'selected.refetch_connections',
    viewPath: props.viewPath,
  })
  let setRefetchConnections = useDataChange({
    context: 'tab',
    path: 'selected.refetch_connections',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'connections',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refetch) return

    submit({ type: 'refetch' })
    setRefetchConnections(null)
  }, [refetch]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submit

  let tableData = useMemo(
    () => ({
      columns: [
        {
          Header: 'Connection',
          Cell: ({ row }) => (
            <Connection
              value={row.original}
              viewPath={`${props.viewPath}/Connection(${row.original.id})`}
            />
          ),
        },
        {
          Header: 'Used by',
          Cell: ({ row }) => (
            <Locations
              value={row.original}
              viewPath={`${props.viewPath}/Locations(${row.original.id})`}
            />
          ),
        },
        {
          Header: 'Added by',
          accessor: 'user',
          Cell: ({ row, value }) =>
            value
              ? `${value.vaxiom_person.first_name} ${value.vaxiom_person.last_name}`
              : '-',
        },
        {
          Header: 'Added on',
          accessor: 'created_at',
          Cell: ({ value }) => dateISOToDateAndTime(value),
        },
        {
          // Actions
          Header: '',
          accessor: 'name',
          Cell: ({ row }) => (
            <Actions
              value={row.original}
              viewPath={`${props.viewPath}/Actions(${row.original.id})`}
            />
          ),
        },
      ],
      data: connections ?? [],
    }),
    [connections]
  )

  return <View viewPath={props.viewPath} tableData={tableData} />
}
