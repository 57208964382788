// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/CreateIteroScanRequest/Content/Content/Step/Doctor/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_CreateIteroScanRequest_Content_Content_Step_Doctor($connection_id: uuid!, $account_id: numeric!) {
  apps_align_itero_doctors(
    connection_id: $connection_id
    account_id: $account_id
  ) {
    doctors {
      id
      text: name
    }
  }
}

`