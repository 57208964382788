import React from 'react'
import Lottie from 'lottie-react'
import loadingAnimation from './loadingAnimation.json'

let App = () => (
  <Lottie
    animationData={loadingAnimation}
    loop={true}
    style={{ height: 306 }}
  />
)

export default App
