// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/ImportFromInvisalignPracticeApp/Content/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_ImportFromInvisalignPracticeApp_Content_Content($connection_id: uuid!, $image_series_id: numeric!) {
  align_patient_images_import(
    connection_id: $connection_id
    image_series_id: $image_series_id
  ) {
    status
  }
}

`