import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return {
      event_id: data.id,
      event_name: data.name,
      event_type: data.type,
      event_status: data.status,
      location_id: data.organization_id,
      automation_type: data.automation_type,
      trigger_type: data.trigger_type,
      tx_status: data.type === 'txs_status_update' ? data.data.value_to : null,
      appointment_status:
        data.type === 'appointment_bookings_state_update'
          ? data.data.value_to
          : null,
      appointment_type_ids:
        data.type === 'appointment_bookings_state_update'
          ? data.data.appointment_type_ids
          : null,
      actions: data.actions,
      tx_status_name:
        data.type === 'txs_status_update' ? data.data.status_name : null,
      appointment_type_names:
        data.type === 'appointment_bookings_state_update'
          ? data.data.appointment_type_names
          : null,
      custom_resource_type:
        data.type === 'custom_resource_insert'
          ? data.data.custom_resource_type
          : null,
      app_id: data.type === 'custom_resource_insert' ? data.data.app_id : null,
      source: data.source,
      is_integration: !!data.source && data.source !== 'user',
    }
  }, [data])
}
