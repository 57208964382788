import formatDate from 'date-fns/format'
import subMonths from 'date-fns/subMonths'
import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return {
    variables: {
      end_date: formatDate(subMonths(new Date(), 3), 'yyyy-MM-dd'),
      current_location_id,
    },
    requestPolicy: 'cache-and-network',
  }
}
