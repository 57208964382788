import { useRef } from 'react'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useQueueAsync } from 'Logic/useQueueAsync.js'
import { useDataValue } from 'Simple/Data.js'

import mutation_insert from './mutation-insert.graphql.js'
import mutation_delete from './mutation-delete.graphql.js'

export default function useDataOnChange(props, data) {
  let previous = useRef(data.procedure_ids)
  let appointment_id = useDataValue({
    context: 'event',
    path: 'appointment._id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeInsertMutation] = useMutation(mutation_insert)
  let [, executeDeleteMutation] = useMutation(mutation_delete)

  return useQueueAsync(onChange)

  async function onChange(value, change) {
    let { inserts, deletes } = getDiff(value.procedure_ids, previous.current)
    if (!inserts.length && !deletes.length) return

    let failed_inserts = []
    let failed_deletes = []

    let mutationResponse = await executeDeleteMutation({
      appointment_id,
      procedure_ids: deletes,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem removing the appointment's procedures. Please try again.`
        )
      )

      failed_deletes = deletes
    }

    // eslint-disable-next-line compat/compat
    let results = await Promise.allSettled(
      inserts.map(async procedure_id => {
        let mutationResponse = await executeInsertMutation({
          appointment_id,
          procedure_id,
        })
        if (mutationResponse.error) {
          notify(
            notifyError(
              mutationResponse.error.message?.replace('[GraphQL] ', '') ||
                `There was a problem updating the appointment's procedures. Please try again.`
            )
          )

          throw procedure_id
        }
      })
    )
    failed_inserts = results
      .filter(item => item.status === 'rejected')
      .map(item => item.reason)

    let next_value = [
      ...value.procedure_ids.filter(a => !failed_inserts.includes(a)),
      ...failed_deletes,
    ]
    previous.current = next_value
    if (failed_inserts.length || failed_deletes.length) {
      change(next => {
        next.procedure_ids = next_value
      })
    }

    notify(notifySuccess(`Saved!`), { extendExistingNotification: true })
  }
}

function getDiff(first, second) {
  let inserts = first.filter(a => !second.includes(a))
  let deletes = second.filter(a => !first.includes(a))
  return { inserts, deletes }
}
