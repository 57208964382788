import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataValue, useDataSubmit, useDataChange } from 'Simple/Data'
import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_booking',
    path: '_id',
  })
  let refreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'appointment_booking',
    viewPath: props.viewPath,
  })
  let changeDisabled = useDataChange({
    context: 'appointment_booking',
    path: 'disabled',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (!value.chair_id || !value.provider_id || !value.assistant_id) {
      return true
    }

    let mutationResponse = await executeMutation({
      id,
      provider_id: value.provider_id,
      assistant_id: value.assistant_id,
      chair_id: value.chair_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem seating the patient. Please try again.`
        )
      )
      return
    }

    changeDisabled(true)

    // refresh the core embedded section to update show the updated status of the booking
    refreshEmbeddedSection(Date.now())
    submit({
      type: 'showPatient',
      tab: 'treatment_card',
    })
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
