import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'payment_source',
    path: 'id',
  })

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'new_payment_plan',
    path: 'selected_payment_source',
  })

  return function onChange() {
    change(id)
  }
}
