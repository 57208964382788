import mutation_new_patient from './newPatientMutation.graphql.js'
import mutation_new_employee_professional_relationship from './newEmployeeProfessionalRelationshipMutation.graphql.js'
import query_similar_persons from './similarPersonsQuery.graphql.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { patientName } from 'Data/format.js'
import format from 'date-fns/format'

function prepareReferralsToInsert(refs) {
  let template_referrals = refs
    .filter(ref => ref.text_value)
    .map(ref => ({
      patient_id: ref.patient_id,
      referral_template_id: ref.referral_template_id,
      text_value: ref.text_value,
      _id: ref._id,
    }))

  let person_referrals = refs
    .filter(ref => ref.person_type)
    .map(ref => ({
      patient_id: ref.patient_id,
      person_id: ref.person_id,
      person_type: ref.person_type,
      _id: ref._id,
    }))
  return { person_referrals, template_referrals }
}

export default function useDataOnSubmit(props, data) {
  let client = useClient()
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let global_submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let [, executeMutationNewPatient] = useMutation(mutation_new_patient)
  let [, executeMutationNewEmployeeProfessionalRelationship] = useMutation(
    mutation_new_employee_professional_relationship
  )

  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let parent_company_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.parent_company.id',
  })

  return async function onSubmit({ value: patient, args }) {
    switch (args?.type) {
      case 'add_patient_to_person': {
        let { person_referrals, template_referrals } = prepareReferralsToInsert(
          patient.referrals
        )

        let patient_data = {
          patient: {
            organization_id: parent_company_id,
            primary_location_id: current_location_id,
            person_id: args.person.vaxiom_id,
            patient_locations: {
              data: {
                location_id: current_location_id,
              },
            },
            patient_person_referrals: {
              data: person_referrals,
            },
            patient_template_referrals: {
              data: template_referrals,
            },
          },
        }

        if (patient.provider_id) {
          patient_data.patient.professional_relationships = {
            data: {
              dtype: 'emploProfessionalRelationship',
              past: false,
            },
          }
        }

        let mutationNewPatientRespone = await executeMutationNewPatient(
          patient_data
        )

        if (mutationNewPatientRespone?.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }
        let patient_response =
          mutationNewPatientRespone.data?.insert_vaxiom_patients_one
        let professional_relationship =
          patient_response.professional_relationships?.[0]

        // This has to be a separate mutations because of this bug
        // https://github.com/hasura/graphql-engine/issues/8232

        if (patient.provider_id) {
          await executeMutationNewEmployeeProfessionalRelationship({
            input: {
              provider_id: patient.provider_id,
              id: professional_relationship.id,
            },
          })
        }

        notify(notifySuccess('Patient registered'))

        global_submit({
          type: 'tabs/add',
          tab: {
            id: patient_response._id,
            type: 'Patient',
            name: patientName(patient_response.person),
            tab: 'profile',
            viewPath: '/App/Account/Content/Patients',
            patient_id: patient_response._id,
          },
        })

        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'redirect': {
        global_submit({
          type: 'tabs/add',
          tab: {
            id: args.person.patient._id,
            type: 'Patient',
            name: patientName(args.person),
            tab: 'profile',
            viewPath: '/App/Account/Content/Patients',
            patient_id: args.person.patient._id,
          },
        })
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      default: {
        if (args.check_similar) {
          let { person } = patient
          let res = await client
            .query(
              query_similar_persons,
              {
                first_name: person.first_name.trim(),
                last_name: person.last_name.trim(),
                birth_date: person.birth_date,
              },

              {
                requestPolicy: 'network-only',
              }
            )
            .toPromise()

          if (res.data?.vaxiom_persons_aggregate.aggregate.count > 0) {
            setFlowTo(normalizePath(props.viewPath, './Panes/Check'))
            return
          }
        }
        let { person_referrals, template_referrals } = prepareReferralsToInsert(
          patient.referrals
        )

        let patient_data = {
          patient: {
            organization_id: parent_company_id,
            primary_location_id: current_location_id,
            person: {
              data: {
                ...patient.person,
                ssn:
                  patient.person.ssn?.trim() === '' ? null : patient.person.ssn,
                organization_id: parent_company_id,
              },
            },
            patient_locations: {
              data: {
                location_id: current_location_id,
              },
            },
            patient_person_referrals: {
              data: person_referrals,
            },
            patient_template_referrals: {
              data: template_referrals,
            },
          },
        }

        if (patient.provider_id) {
          patient_data.patient.professional_relationships = {
            data: {
              dtype: 'emploProfessionalRelationship',
              past: false,
            },
          }
        }

        let mutationNewPatientRespone = await executeMutationNewPatient(
          patient_data
        )

        if (mutationNewPatientRespone?.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }
        let patient_response =
          mutationNewPatientRespone.data?.insert_vaxiom_patients_one
        let professional_relationship =
          patient_response.professional_relationships?.[0]

        // This has to be a separate mutations because of this bug
        // https://github.com/hasura/graphql-engine/issues/8232

        if (patient.provider_id) {
          await executeMutationNewEmployeeProfessionalRelationship({
            input: {
              provider_id: patient.provider_id,
              id: professional_relationship.id,
            },
          })
        }

        notify(notifySuccess('Patient registered'))

        global_submit({
          type: 'tabs/add',
          tab: {
            id: patient_response._id,
            type: 'Patient',
            name: patientName(patient_response.person),
            tab: 'profile',
            viewPath: '/App/Account/Content/Patients',
            patient_id: patient_response._id,
          },
        })

        setFlowTo(normalizePath(props.viewPath, '../No'))
      }
    }
  }
}
