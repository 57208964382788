// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/SecureValue/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_SecureValue($value: String!) {
  encrypt(value: $value) {
    value
  }
}

`