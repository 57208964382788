import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let submit = useDataSubmit({
    context: 'appointment_actions',
    viewPath: props.viewPath,
  })

  return async function onSubmit() {
    submit({ type: 'cancelAppointment' })
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
