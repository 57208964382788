// this is an autogenerated file from App/Account/EmbeddableCoreMessageListener/query-patient-info.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_EmbeddableCoreMessageListener($id: numeric!) {
  vaxiom_patients_by_pk(id: $id) {
    id
    _id
    person {
      id
      _id
      first_name
      last_name
    }
  }
}

`