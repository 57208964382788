import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    context: 'launcher',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ change }) {
    let configuration = await submit()
  }
}
