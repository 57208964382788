// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/More/ChangeAppointmentType/Content/AppointmentTypes/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_More_ChangeAppointmentType_Content_AppointmentTypes($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      organization: { _id: { _eq: $location_id } }
      deleted: { _eq: false }
    }
    order_by: { full_name_computed: asc }
  ) {
    id
    appointment_type_id
    text: full_name_computed
  }
}

`