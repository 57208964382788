import { useDataValue } from 'Simple/Data'

let YEAR_RANGE_LENGTH = 3
let CURRENT_YEAR = new Date().getFullYear()

export default function useGetDefaultTab(props, data) {
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company.id',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id) {
    let default_filters = {
      ...data.selected.filters,
      period_year_id: CURRENT_YEAR,
      period_years: [...new Array(YEAR_RANGE_LENGTH)].map((_, i) => {
        let y = CURRENT_YEAR - (YEAR_RANGE_LENGTH - 1) + i
        return { id: y, text: y }
      }),
    }

    if (maybeTab && maybeTab.selected) {
      return {
        ...maybeTab,
        selected: {
          ...maybeTab.selected,
          parent_company_id,
          location_ids: [],
          filters: {
            ...default_filters,
            ...maybeTab.selected.filters,
          },
        },
      }
    }

    return {
      ...data,
      selected: {
        ...data.selected,
        location_ids: [],
        parent_company_id,
        filters: default_filters,
      },
      viewPath: props.viewPath,
    }
  }
}
