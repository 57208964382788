// this is an autogenerated file from App/Account/Content/PatientsList/Filters/MedicalHistoryQuestions/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_PatientsList_Filters_MedicalHistoryQuestions($location_ids: [uuid!]!) {
  medical_histories_questions(
    where: {
      organization_id: { _in: $location_ids }
      type: { _in: [boolean, choice, multichoice] }
      status: { _eq: active }
    }
    order_by: [{ order: asc }]
  ) {
    id
    name
    type
    options
    organization {
      id
      _id
      name
    }
  }
}

`