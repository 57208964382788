// this is an autogenerated file from DesignSystem/CaptureImageSeries/Content/Active/Content/Images/mutation_update_patient_image.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CaptureImageSeries_Content_Active_Content_Images($id: numeric!, $file_key: String!) {
  vaxiom_patient_images_update(image_id: $id, file_key: $file_key) {
    id
    patient_image {
      id
      file_key
      original_file_key
      type_key
      slot_key
      mime_type
    }
  }
}

`