// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/RefreshButton/query_other_relatives.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_RefreshButton($person_id: uuid!) {
  vaxiom_relationships(
    where: {
      to_person_record: { _id: { _eq: $person_id } }
      role: { _neq: "RESPONSIBLE" }
    }
  ) {
    id
    type
    role
    from_person_record {
      id
      first_name
      middle_name
      last_name
    }
  }
}

`