import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(tx_card => ({
      ...tx_card,
      txs: tx_card?.txs?.[0],
    }))[0]
  }, [data])
}
