// this is an autogenerated file from DesignSystem/ImageComparison/Content/Actions/Choose/ChooseImageAction/Content/Content/ImageSeries/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ImageComparison_Content_Actions_Choose_ChooseImageAction_Content_Content_ImageSeries($condition: vaxiom_patient_imageseries_bool_exp!) {
  image_series: vaxiom_patient_imageseries(
    where: $condition
    order_by: { series_date: desc }
  ) {
    id
    series_date
    name
    deleted
  }
}

`