export default function useGetDefaultTab(props, data) {
  return function ensureDefaultTab(maybeTab, id) {
    if (maybeTab?.selected) return maybeTab

    return {
      ...data,
      viewPath: props.viewPath,
    }
  }
}
