import { numberMoney } from 'Data/format.js'
import { getAdjustedTreatmentFeeAfterInsurances } from 'Data/payment-plan.js'

export function getValue(payment_plan) {
  let payors_share_amount = payment_plan.payors.reduce(
    (acc, payor) => acc + payor.share.amount,
    0
  )
  return numberMoney(
    getAdjustedTreatmentFeeAfterInsurances(payment_plan) - payors_share_amount
  )
}
