import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'note',
  })

  return useMemo(
    () => ({
      id: note.id,
      note: note.note,
    }),
    [note]
  )
}
