import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let index = useDataValue({
    context: 'default_payment_plan_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'ruleset',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.default_payment_plans.splice(index, 1)
    })
  }
}
