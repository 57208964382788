// this is an autogenerated file from DesignSystem/TimelineEvent/FutureSeparator/Actions/NewNote/Write/Content/Actions/SaveAction/add_note_mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_FutureSeparator_Actions_NewNote_Write_Content_Actions_SaveAction(
  $note: String!
  $target_id: numeric!
  $target_type: String!
  $user_id: numeric!
) {
  insert_vaxiom_notes_one(
    object: {
      note: $note
      target_id: $target_id
      target_type: $target_type
      author: $user_id
      alert: false
    }
  ) {
    id
    note
  }
}

`