export let STATES = [
  { text: 'Alabama', id: 'AL' },
  { text: 'Alaska', id: 'AK' },
  { text: 'Arizona', id: 'AZ' },
  { text: 'Arkansas', id: 'AR' },
  { text: 'California', id: 'CA' },
  { text: 'Colorado', id: 'CO' },
  { text: 'Connecticut', id: 'CT' },
  { text: 'Delaware', id: 'DE' },
  { text: 'Florida', id: 'FL' },
  { text: 'Georgia', id: 'GA' },
  { text: 'Hawaii', id: 'HI' },
  { text: 'Idaho', id: 'ID' },
  { text: 'Illinois', id: 'IL' },
  { text: 'Indiana', id: 'IN' },
  { text: 'Iowa', id: 'IA' },
  { text: 'Kansas', id: 'KS' },
  { text: 'Kentucky', id: 'KY' },
  { text: 'Louisiana', id: 'LA' },
  { text: 'Maine', id: 'ME' },
  { text: 'Maryland', id: 'MD' },
  { text: 'Massachusetts', id: 'MA' },
  { text: 'Michigan', id: 'MI' },
  { text: 'Minnesota', id: 'MN' },
  { text: 'Mississippi', id: 'MS' },
  { text: 'Missouri', id: 'MO' },
  { text: 'Montana', id: 'MT' },
  { text: 'Nebraska', id: 'NE' },
  { text: 'Nevada', id: 'NV' },
  { text: 'New Hampshire', id: 'NH' },
  { text: 'New Jersey', id: 'NJ' },
  { text: 'New Mexico', id: 'NM' },
  { text: 'New York', id: 'NY' },
  { text: 'North Carolina', id: 'NC' },
  { text: 'North Dakota', id: 'ND' },
  { text: 'Ohio', id: 'OH' },
  { text: 'Oklahoma', id: 'OK' },
  { text: 'Oregon', id: 'OR' },
  { text: 'Pennsylvania', id: 'PA' },
  { text: 'Rhode Island', id: 'RI' },
  { text: 'South Carolina', id: 'SC' },
  { text: 'South Dakota', id: 'SD' },
  { text: 'Tennessee', id: 'TN' },
  { text: 'Texas', id: 'TX' },
  { text: 'Utah', id: 'UT' },
  { text: 'Vermont', id: 'VT' },
  { text: 'Virginia', id: 'VA' },
  { text: 'Washington', id: 'WA' },
  { text: 'West Virginia', id: 'WV' },
  { text: 'Wisconsin', id: 'WI' },
  { text: 'Wyoming', id: 'WY' },
  { text: 'American Samoa', id: 'AS' },
  { text: 'District of Columbia', id: 'DC' },
  { text: 'Guam', id: 'GU' },
  { text: 'Northern Mariana Islands, Commonwealth', id: 'MP' },
  { text: 'Puerto Rico, Commonwealth', id: 'PR' },
  { text: 'the United States Virgin Isnlands', id: 'VI' },
  { text: 'British Columbia', id: 'BC' },
  { text: 'Newfoundland and Labrador', id: 'NL' },
  { text: 'Prince Edward Island', id: 'PE' },
  { text: 'Nova Scotia', id: 'NS' },
  { text: 'New Brunswick', id: 'NB' },
  { text: 'Quebec', id: 'QC' },
  { text: 'Ontario', id: 'ON' },
  { text: 'Manitoba', id: 'MB' },
  { text: 'Saskatchewan', id: 'SK' },
  { text: 'Alberta', id: 'AB' },
  { text: 'Yukon', id: 'YT' },
  { text: 'Northwest Territories', id: 'NT' },
  { text: 'Nunavut', id: 'NU' },
]
