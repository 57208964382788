import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_id = useDataValue({
    viewPath: props.viewPath,
    context: 'connection',
    path: 'id',
  })
  let app_id = useDataValue({
    viewPath: props.viewPath,
    context: 'connection',
    path: 'app_id',
  })
  let connection_name = useDataValue({
    viewPath: props.viewPath,
    context: 'connection',
    path: 'name',
  })
  let type = useDataValue({
    viewPath: props.viewPath,
    context: 'connection_resource',
    path: 'type',
  })
  let resource_id = useDataValue({
    viewPath: props.viewPath,
    context: 'connection_resource',
    path: 'resource_id',
  })
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  return {
    variables: {
      location_id,
      app_id,
      connection_name,
      connection_level: type,
      type,
      resource_id,
      connection_id,
    },
    pause: false,
  }
}
