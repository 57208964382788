import React, { useEffect } from 'react'
import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let refetch = useDataValue({
    context: 'tab',
    path: 'selected.refetch_automations',
    viewPath: props.viewPath,
  })
  let setRefetchAutomations = useDataChange({
    context: 'tab',
    path: 'selected.refetch_automations',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'automations',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refetch) return

    submit({ type: 'refetch' })
    setRefetchAutomations(null)
  }, [refetch]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submitP

  return <View {...props} />
}
