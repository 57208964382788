import { useCallback, useRef } from 'react'
import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useQueueAsync } from 'Logic/useQueueAsync.js'
import debounce from 'lodash/debounce.js'

import updateTxPlanNotes from './update_tx_plan_notes.graphql.js'
import insertTxPlanNotes from './insert_tx_plan_notes.graphql.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutationUpdate] = useMutation(updateTxPlanNotes)
  let [, executeMutationInsert] = useMutation(insertTxPlanNotes)
  let tx_card_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'id',
  })
  let previous = useRef(data)

  return useCallback(debounce(useQueueAsync(onChange), 250), [])

  async function onChange(value, change) {
    if (value.note === previous.current.note) return

    previous.current = value
    let mutationResponse
    if (value.id) {
      mutationResponse = await executeMutationUpdate({
        id: value.id,
        notes: value.note,
      })
    } else {
      mutationResponse = await executeMutationInsert({
        notes: value.note,
        tx_card_id,
      })
      change(next => {
        next.id = mutationResponse.data?.insert_vaxiom_tx_plan_notes_one?.id
      })
    }

    if (mutationResponse.error) {
      notify(notifyError(`Cannot save the notes.`))
    }
  }
}
