// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Gallery/Content/PatientImages/AllImages/PatientImage/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Gallery_Content_PatientImages_AllImages_PatientImage($resource_id: numeric!) {
  vaxiom_resource_object_meta(resource_id: $resource_id, resource_type: image) {
    name
    content_type
    size
    url
    preview_url
  }
}

`