// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/Images/Content/Images/Misc/Content/PatientImage/Content/Content/query_print_image.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_Images_Content_Images_Misc_Content_PatientImage_Content_Content($image_id: numeric!) {
  vaxiom_patient_image_print(image_id: $image_id) {
    url
  }
}

`