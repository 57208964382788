import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'contact_methods',
    viewPath: props.viewPath,
  })

  let number = useDataValue({
    viewPath: props.viewPath,
    context: 'contact_method',
    path: 'number',
  })

  return function onClick() {
    submit({ type: 'sms', number })
  }
}
