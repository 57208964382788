import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let show_deleted_image_series = useDataValue({
    context: 'tab',
    path: 'selected.images.show_deleted_image_series',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => [
      {
        id: 'TOGGLE_DELETED_IMAGE_SERIES',
        text: show_deleted_image_series
          ? 'Hide deleted image series in list'
          : 'Show deleted image series in list',
      },
    ],
    [show_deleted_image_series]
  )
}
