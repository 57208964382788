import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let { person } = useDataValue({
    context: 'new_patient',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      first_name: person.first_name.trim(),
      last_name: person.last_name.trim(),
      birth_date: person.birth_date,
    },
  }
}
