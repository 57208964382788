// @view
import { useEffect } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import { Userpilot } from 'userpilot'
import { useHistory } from 'Logic/FlowShortcuts.js'

// enabled for all envs
// for testing try process.env.REACT_APP_ENV !== 'staging'
let DISABLE_USERPILOT = false

export default function UserPilotView(props) {
  let user = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'user',
  })
  let location = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location',
  })
  let history = useHistory()
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    if (!DISABLE_USERPILOT) {
      Userpilot.initialize(process.env.REACT_APP_USERPILOT_TOKEN)
      // we can't extend their object
      window.userpilotHelper = {
        setFlowTo,
      }
    }
  }, [])

  useEffect(() => {
    !DISABLE_USERPILOT && Userpilot.reload()
  }, [history.location])

  useEffect(() => {
    !DISABLE_USERPILOT &&
      Userpilot.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        user_role: user.role,
        job_title: user.job_title,
        env: process.env.REACT_APP_ENV,
        // we need these because Userpilot's filtering doesn't let us find users in locations easily
        location_id: location._id,
        location_name: location.name,
        // shape required by userPilot
        company: {
          id: location.parent_company.xid,
          name: location.parent_company.name,
          location_id: location._id,
          location_name: location.name,
        },
      })
  }, [user, location])

  return null
}
