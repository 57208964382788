import React from 'react'
import { useDataValue } from 'Simple/Data'
import View from './view.js'

export default function Logic(props) {
  let image = useDataValue({
    context: 'image_slot',
    path: 'definition',
    viewPath: props.viewPath,
  })

  return (
    <View
      gridRowStart={`span ${image.rows}`}
      gridRowEnd={`span ${image.rows}`}
      gridColumnStart={`span ${image.columns}`}
      gridColumnEnd={`span ${image.columns}`}
      {...props}
    />
  )
}
