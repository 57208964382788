import { useDataSubmit } from 'Simple/Data.js'

export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    context: 'preview_document',
    viewPath: props.viewPath,
  })
  return async function onChange(next) {
    if (next?.status !== 'signed') return

    submit({
      type: 'documentSigned',
      document_id: next.resource_id,
    })
  }
}
