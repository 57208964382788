import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { textInput, name, street, city, state, zip } from 'Data/validate.js'
import isEqual from 'lodash/isEqual.js'
import mutation_update_professional from './updateProfessionalMutation.graphql.js'
import mutation_update_office from './updateOfficeMutation.graphql.js'
import mutation_link_office from './linkOfficeMutation.graphql.js'
import mutation_add_office from './addOfficeMutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionNewOffice = useDataOnActionNewOffice(props)
  let onActionEditOffice = useDataOnActionEditOffice(props)
  let onActionLinkOffice = useDataOnActionLinkOffice(props)
  let onActionEditPerson = useDataOnActionEditPerson(props)

  return async function onSubmit({ value, originalValue, args }) {
    switch (args?.type) {
      case 'new_office': {
        return onActionNewOffice({ value, originalValue })
      }
      case 'edit_office': {
        return onActionEditOffice({ value, originalValue })
      }
      case 'link_office': {
        return onActionLinkOffice({ value, args })
      }
      default: {
        return onActionEditPerson({ value, originalValue })
      }
    }
  }
}

function useDataOnActionLinkOffice(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutationLinkOffice] = useMutation(mutation_link_office)
  return async function onAction({ value: edit_professional, args }) {
    let { external_office } = edit_professional.other

    let mutationLinkOfficeResponse = await executeMutationLinkOffice({
      id: edit_professional.other.id,
      office_id: external_office.id,
    })
    if (mutationLinkOfficeResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Office details updated!'))
    props?.onClose()
  }
}
function useDataOnActionEditOffice(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationUpdateOffice] = useMutation(mutation_update_office)
  let [, executeMutationUpdateProfessional] = useMutation(
    mutation_update_professional
  )

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value: edit_professional, originalValue }) {
    let { external_office } = edit_professional.other
    let { postal_address } = external_office
    if (
      !external_office.name ||
      !postal_address.address_line1 ||
      !postal_address.state ||
      !postal_address.zip
    ) {
      return true
    }

    let mutationUpdateOfficeResponse = await executeMutationUpdateOffice({
      external_office_id: external_office.id,
      office_name: external_office.name,
      postal_id: postal_address.id,
      address_line1: postal_address.address_line1,
      city: postal_address.city,
      state: postal_address.state,
      zip: postal_address.zip,
    })
    if (mutationUpdateOfficeResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    if (!isEqual(edit_professional.other.person, originalValue.other.person)) {
      let { person, id, employee_type } = edit_professional.other
      let update_note = typeof person.note?.id !== 'undefined'
      let mutationUpdateProfessionalResponse =
        await executeMutationUpdateProfessional({
          person_id: person.id,
          first_name: person.first_name,
          middle_name: person.middle_name,
          last_name: person.last_name,
          title: person.title,
          greeting: person.greeting,
          other_professional_relationship_id: id,
          employee_type_id: employee_type.id,
          location_id,
          note: person.note?.note,
          note_id: person.note?.id || null,
          update_note,
          create_note: !update_note ? !!person.note?.note : false,
        })
      if (mutationUpdateProfessionalResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }
    }

    notify(notifySuccess('Office details updated!'))
    props?.onClose()
  }
}

function useDataOnActionNewOffice(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddOffice] = useMutation(mutation_add_office)
  let [, executeMutationUpdateProfessional] = useMutation(
    mutation_update_professional
  )
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value: edit_professional, originalValue }) {
    let { external_office, id } = edit_professional.other
    let { postal_address } = external_office
    if (
      !external_office.name ||
      !postal_address.address_line1 ||
      !postal_address.state ||
      !postal_address.zip
    ) {
      return true
    }

    let mutationAddOfficeResponse = await executeMutationAddOffice({
      other_professional_relationship_id: id,
      name: external_office.name,
      address_line1: postal_address.address_line1,
      city: postal_address.city,
      state: postal_address.state,
      zip: postal_address.zip,
    })
    if (mutationAddOfficeResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    if (!isEqual(edit_professional.other.person, originalValue.other.person)) {
      let { person, id, employee_type } = edit_professional.other
      let update_note = typeof person.note?.id !== 'undefined'
      let mutationUpdateProfessionalResponse =
        await executeMutationUpdateProfessional({
          person_id: person.id,
          first_name: person.first_name,
          middle_name: person.middle_name,
          last_name: person.last_name,
          title: person.title,
          greeting: person.greeting,
          other_professional_relationship_id: id,
          employee_type_id: employee_type.id,
          location_id,
          note: person.note?.note,
          note_id: person.note?.id || null,
          update_note,
          create_note: !update_note ? !!person.note?.note : false,
        })
      if (mutationUpdateProfessionalResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }
    }
    notify(notifySuccess('Office details updated!'))
    props?.onClose()
  }
}

function useDataOnActionEditPerson(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationUpdateProfessional] = useMutation(
    mutation_update_professional
  )

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  return async function onAction({ value: edit_professional, originalValue }) {
    let { person, id, employee_type, external_office } = edit_professional.other

    let { postal_address } = external_office
    if (
      !name(person.first_name) ||
      !name(person.last_name) ||
      !textInput(external_office.name) ||
      !street(postal_address.address_line1) ||
      !state(postal_address.state) ||
      !zip(postal_address.zip) ||
      !city(postal_address.city)
    ) {
      return true
    }

    if (external_office !== originalValue.other.external_office) {
      setFlowTo(normalizePath(props.viewPath, './EditOrAdd/Content'))
      return
    }
    let update_note = typeof person.note?.id !== 'undefined'

    let mutationUpdateProfessionalResponse =
      await executeMutationUpdateProfessional({
        person_id: person.id,
        first_name: person.first_name,
        middle_name: person.middle_name,
        last_name: person.last_name,
        title: person.title,
        greeting: person.greeting,
        other_professional_relationship_id: id,
        employee_type_id: employee_type.id,
        location_id,
        note: person.note?.note,
        note_id: person.note?.id || null,
        update_note,
        create_note: !update_note ? !!person.note?.note : false,
      })

    if (mutationUpdateProfessionalResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Person details updated!'))

    props?.onClose()
  }
}
