import { useClient, useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import CreateStatementsPdfsWorker from './create-statements-pdfs-worker.js?worker'
import { chunk } from 'lodash'
import useQueryLocations from './useQueryLocations.js'
import mutation from './mutation-statements.graphql.js'
import { getS3ObjectUrl, useAwsCredentials } from 'Data/s3.js'
import { useDataValue } from 'Simple/Data.js'
import uuid from 'uuid/v4.js'
import MUTATION_S3_SEND_COMMANDS from './mutation-s3-send-commands.graphql.js'
import STATEMENTS_FOLDERS_FOR_PATIENTS from './query-statements-folders-for-patients.graphql.js'

// lazy load this library
let pdfLib = import('pdf-lib')

// TODO: when tab is removed ensure we call URL.revokeObjectURL on all URLs we created
// I don't think we need to worry about blobs because those will be cleared by the GC

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnActionProcessStatements(props, data) {
  let client = useClient()
  let [, notify] = useNotifications()
  let queryLocations = useQueryLocations(props)
  let [, executeMutation] = useMutation(mutation)
  let getAwsCredentials = useAwsCredentials(props)
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })

  return async function onActionProcessStatements({
    value: rvalue,
    originalValue,
    args,
    change,
  }) {
    let [, actionType] = args.type.split('/')

    let value = rvalue.selected.statements
    if (value.merged?.url && value.merged?.filename) {
      if (actionType === 'download') {
        if (!value.merged.downloaded) {
          change(next => {
            next.selected.statements.merged.downloaded = true
          })
        }

        openForDownload({
          url: value.merged.url,
          filename: value.merged.filename,
        })
        notify(notifySuccess(`Statements downloaded`), { hideAfter: 10_000 })
      } else {
        if (!value.merged.printed) {
          change(next => {
            next.selected.statements.merged.printed = true
          })

          let mutationResponsePatientRecords = await executeMutation({
            documents: [],
            saveDocuments: false,
            printNotes: value.merged.printNotes,
            savePrintNotes: true,
          })
          if (mutationResponsePatientRecords.error) {
            return notify(notifyError('Failed to update patient records'))
          }
        }

        openForPrint(value.merged.url)
        notify(notifySuccess(`Statements printed`), { hideAfter: 10_000 })
      }
      return
    }

    let statements = value.selected_rows

    // TODO: use to mock 10000 docks
    // statements = []
    // for (let i = 0; i < 10_000; i++) {
    //   statements.push(value.selected_rows[i % value.selected_rows.length])
    // }

    // chunk documents so the service worker doesn't choke
    let progress = `0%`
    let progressIndex = 0
    let progressTotal =
      statements.length / CHUNK_SIZE_CREATE_PDFS +
      statements.length / CHUNK_SIZE_MERGE_PDFS +
      statements.length / CHUNK_SIZE_UPLOAD_PDFS
    // add some extra progress space to account for the mutations at the end
    // make it either 5% of the total or at least 2 units to make it somewhat relative
    progressTotal += Math.max(Math.ceil(progressTotal * 0.05), 2)

    let pdfs = []
    let cstatements = chunk(statements, CHUNK_SIZE_CREATE_PDFS)
    change(next => {
      next.selected.statements.merged = { lock: true, progress }
    })

    let locations = await queryLocations(value.query.location_ids)

    // start: create pdfs
    // we need to do this linearly to avoid choking the browser
    for (let i = 0; i < cstatements.length; i++) {
      let chunk = cstatements[i]
      pdfs.push(
        await createPdfs({
          pdfs: chunk,
          locations,
          credit_cards: value.credit_cards,
        })
      )

      progressIndex++
      progress = `${Math.floor((progressIndex / progressTotal) * 100)}%`
      // scope progress' value for the change callback
      let scopedProgress = progress
      change(next => {
        next.selected.statements.merged = {
          lock: true,
          progress: scopedProgress,
        }
      })
    }
    pdfs = pdfs.flat()

    let { PDFDocument } = await pdfLib
    let merged = await PDFDocument.create()
    let progressItemIndex = 0
    for (let item of pdfs) {
      let src = await PDFDocument.load(item.buffer)
      let copiedPages = await merged.copyPages(src, src.getPageIndices())
      copiedPages.forEach(page => merged.addPage(page))

      progressItemIndex++
      if (progressItemIndex % CHUNK_SIZE_MERGE_PDFS === 0) {
        progressIndex++
        progress = `${Math.floor((progressIndex / progressTotal) * 100)}%`
        // scope progress' value for the change callback
        let scopedProgress = progress
        change(next => {
          next.selected.statements.merged = { progress: scopedProgress }
        })
      }
    }

    let url = getPdfBlobUrlFromArrayBuffer(await merged.save())
    let filename = `statements-${format(new Date(), 'MM_dd_yyyy-h_mmaaa')}.pdf`
    // end: create pdfs

    // start: upload files
    let awsCredentials = await getAwsCredentials()
    let statementsPdfs = pdfs.map(item => {
      let s3ObjectParams = {
        region: awsCredentials.region,
        bucket: awsCredentials.storage_bucket_name,
        key: `companies/${parent_company_id}/patients/${
          item.statement.patient._id
        }/documents/statements/${uuid()}.pdf`,
      }
      return {
        ...item,
        blob: getPdfBlobFromArrayBuffer(item.buffer),
        url: getS3ObjectUrl(s3ObjectParams),
        s3CommandPayload: {
          Bucket: s3ObjectParams.bucket,
          Key: s3ObjectParams.key,
        },
      }
    })
    let mutationResponseS3SendCommands = await client
      .mutation(MUTATION_S3_SEND_COMMANDS, {
        list: statementsPdfs.map(item => ({
          type: 'PutObjectSigned',
          payload: item.s3CommandPayload,
        })),
      })
      .toPromise()
    if (mutationResponseS3SendCommands.error) {
      return notify(notifyError('Failed to get files to upload'))
    }

    // chunk these to rate limit requests to S3, mostly for the browser...
    // chunked both to make the calculations easier :D
    let statementsPdfsChunks = chunk(statementsPdfs, CHUNK_SIZE_UPLOAD_PDFS)
    let uploadCommandsChunks = chunk(
      mutationResponseS3SendCommands.data.aws_s3_send_commands,
      CHUNK_SIZE_UPLOAD_PDFS
    )
    for (let i = 0; i < uploadCommandsChunks.length; i++) {
      let uploadCommands = uploadCommandsChunks[i]
      for (let j = 0; j < uploadCommands.length; j++) {
        let response = await fetch(uploadCommands[j].response, {
          method: 'PUT',
          body: statementsPdfsChunks[i][j].blob,
        })
        if (!response.ok) {
          // handle error...
          if (mutationResponseS3SendCommands.error) {
            return notify(
              notifyError(
                `Failed to upload file ${statementsPdfsChunks[i][j].filename}`
              )
            )
          }
        }
      }

      progressIndex++
      progress = `${Math.floor((progressIndex / progressTotal) * 100)}%`
      // scope progress' value for the change callback
      let scopedProgress = progress
      change(next => {
        next.selected.statements.merged = {
          lock: true,
          progress: scopedProgress,
        }
      })
    }
    // end: upload files

    // start: store patient documents and notes
    let statementsFoldersForPatients = await client
      .query(STATEMENTS_FOLDERS_FOR_PATIENTS, {
        patient_ids: statementsPdfs.map(item => item.statement.patient.id),
      })
      .toPromise()
    if (statementsFoldersForPatients.error) {
      notify(notifyError('Failed to get folders for patients'))
    }

    let documents = statementsPdfs.map(item => {
      let ret = {
        patient_id: item.statement.patient.id,
        created_from: 'STATEMENTS',
        url: item.url,
        mime_type: 'application/pdf',
        name: item.filename,
        type: 'file',
        size: item.blob.size,
      }

      let folder =
        statementsFoldersForPatients.data.vaxiom_document_tree_nodes.find(
          fitem => fitem.patient_id === item.statement.patient.id
        )
      if (folder) {
        ret.parent_id = folder.id
      } else {
        ret.parent = {
          data: {
            patient_id: item.statement.patient.id,
            created_from: 'STATEMENTS',
            type: 'folder',
            name: 'Statements',
          },
        }
      }

      return ret
    })

    let getNotes = notesType =>
      statementsPdfs.map(item => {
        let [oldestDueReceivable] = [...item.statement.due_receivables].sort(
          (a, b) => {
            let aDueDate = parseISO(a.due_date)
            let bDueDate = parseISO(b.due_date)
            return isEqual(aDueDate, bDueDate)
              ? 0
              : isBefore(aDueDate, bDueDate)
              ? -1
              : 1
          }
        )
        let days = differenceInCalendarDays(
          new Date(),
          parseISO(oldestDueReceivable.due_date)
        )

        return {
          note: `${days} day {link|Statement|${item.url}} ${
            notesType === 'print' ? 'printed.' : 'downloaded.'
          }`,
          target_type: 'Patient.Ledger',
          target_id: item.statement.patient.id,
        }
      })
    // TODO: file uploads should be part of the progress count together with the mutation to save the metadata...
    // we can also likely paralellise the call to merged.save - the file uploads will need to be done in chunks, 100 or so I imagine

    let variables = {
      documents,
      saveDocuments: true,
      printNotes: getNotes(actionType),
      savePrintNotes: true,
    }
    // TODO: if this failed we should delete all the files stored in S3
    let mutationResponsePatientRecords = await executeMutation(variables)
    if (mutationResponsePatientRecords.error) {
      return notify(notifyError('Failed to update patient records'))
    }
    // end: store patient documents and notes

    if (actionType === 'download') {
      notify(notifySuccess(`Statements downloaded`), { hideAfter: 10_000 })
      openForDownload({ url, filename })
    } else {
      notify(notifySuccess(`Statements printed`), { hideAfter: 10_000 })
      openForPrint(url)
    }
    notify(notifySuccess(`Statements saved to patient Documents`), {
      hideAfter: 10_000,
    })

    change(next => {
      next.selected.statements.merged = {
        lock: false,
        url,
        filename,
        downloaded: actionType === 'download',
        printed: actionType === 'print',
        printNotes: actionType === 'print' ? null : getNotes(actionType),
      }
    })
  }
}

async function createPdfs(args) {
  return new Promise((resolve, reject) => {
    let worker = new CreateStatementsPdfsWorker()
    worker.onmessage = res => {
      resolve(res.data)
    }
    worker.postMessage(args)
  })
}

function openForDownload({ url, filename }) {
  let downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = filename

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

function openForPrint(url) {
  let pdfWindow = window.open(url)
  pdfWindow.onload = () => pdfWindow.print()
}

let CHUNK_SIZE_CREATE_PDFS = 100
let CHUNK_SIZE_UPLOAD_PDFS = 100
let CHUNK_SIZE_MERGE_PDFS = 500

function getPdfBlobUrlFromArrayBuffer(buffer) {
  return URL.createObjectURL(getPdfBlobFromArrayBuffer(buffer))
}

function getPdfBlobFromArrayBuffer(buffer) {
  return new Blob([buffer], { type: 'application/pdf' })
}
