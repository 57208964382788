import cubejs from '@cubejs-client/core'
import { CubeProvider, CubeContext } from '@cubejs-client/react'
import { useDataValue } from 'Simple/Data.js'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import AsyncLock from 'async-lock'

export function ApiCube(props) {
  let accessToken = useDataValue({
    context: 'auth',
    path: 'access_token',
    viewPath: props.viewPath,
  })
  let accessTokenRef = useRef(accessToken)
  useEffect(() => {
    accessTokenRef.current = accessToken
  }, [accessToken])

  let cubejsApi = useMemo(
    () =>
      cubejs(async () => accessTokenRef.current, {
        apiUrl: process.env.REACT_APP_API_REPORTS,
      }),
    []
  )

  return <CubeProvider cubejsApi={cubejsApi}>{props.children}</CubeProvider>
}

let lock = new AsyncLock()

export function useQuery({
  query,
  configuration = { skip: false, resetResultSetOnChange: false },
}) {
  let mutexRef = useRef({})
  let [isLoading, setLoading] = useState(false)
  let [resultSet, setResultSet] = useState(null)
  let [progress, setProgress] = useState(null)
  let [error, setError] = useState(null)
  let { cubejsApi } = useContext(CubeContext)

  let cancel = useRef(false)
  useEffect(() => {
    return () => {
      cancel.current = true
    }
  }, [])

  let fetch = useCallback(
    async function fetch() {
      if (cancel.current) return
      if (configuration.skip) return
      console.log('cube/running', query)

      if (configuration.resetResultSetOnChange) {
        setResultSet(null)
      }

      setError(null)
      setLoading(true)

      try {
        let response = await cubejsApi.load(query, {
          mutexObj: mutexRef.current,
          mutexKey: 'query',
          progressCallback: ({ progressResponse }) =>
            setProgress(progressResponse),
        })
        if (cancel.current) return

        setResultSet(response)
        setProgress(null)
      } catch (error) {
        if (cancel.current) return
        setError(error)
        setResultSet(null)
        setProgress(null)
      }

      if (cancel.current) return
      setLoading(false)
    },
    [configuration.skip, configuration.resetResultSetOnChange, cubejsApi, query]
  )

  useEffect(() => {
    async function execute() {
      await lock.acquire('useCubeQuery', fetch)
    }
    execute()
  }, [fetch])

  return {
    isLoading,
    resultSet,
    error,
    progress,
    refetch: fetch,
  }
}
