import { useDataValue } from 'Simple/Data'

export default function useDataConfiguration(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let deleted = useDataValue({
    context: 'tab',
    path: 'selected.images.show_deleted_image_series',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      patient_id,
      deleted: deleted === false ? { _eq: false } : {},
    },
  }
}
