import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'sms',
    path: 'id',
  })

  let status = useDataValue({
    viewPath: props.viewPath,
    context: 'sms',
    path: 'twilio_message_status',
  })

  return {
    variables: {
      id: id,
    },
    pause: !['accepted', 'queued', 'sending', null].includes(status),
  }
}
