import { useMemo } from 'react'
import { useDataValueOnce } from 'Logic/FlowShortcuts.js'

export default function useDataTransform(props, data) {
  let image_id = useDataValueOnce({
    context: 'tab',
    path: 'selected.images.image_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data || !image_id) return data

    return { ...data, image_id }
  }, [data, image_id])
}
