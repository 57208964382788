import { useMemo } from 'react'

import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'id',
  })
  let tx_card_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_card',
    path: 'id',
  })
  return useMemo(() => {
    return {
      patient_id,
      tx_card_id,
      reorder_entries: true,
      hide_top_bar: true,
      hide_side_bar: true,
      tab: 'treatment_card',
    }
  }, [patient_id, tx_card_id])
}
