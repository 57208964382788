import { useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onChange() {
    if (typeof data !== 'undefined' && data.length > 0) {
      submit({ type: 'update' })
    }
  }
}
