// this is an autogenerated file from DesignSystem/MoveToAnotherTreatment/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_MoveToAnotherTreatment(
  $appointment_ids: [uuid!]!
  $note_ids: [uuid!]!
  $source_treatment_card_id: uuid!
  $target_treatment_card_id: uuid!
) {
  treatments_move_resources(
    appointment_ids: $appointment_ids
    note_ids: $note_ids
    source_treatment_card_id: $source_treatment_card_id
    target_treatment_card_id: $target_treatment_card_id
  ) {
    ok
  }
}

`