// this is an autogenerated file from DesignSystem/CommsThread/Content/mutation_send_sms.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CommsThread_Content(
  $from_number_id: uuid!
  $to_number: PhoneNumber!
  $message: String!
  $status: comms_events_statuses_types_enum_action!
  $attachments: [comms_events_sms_and_status_attachment!]
  $tags: jsonb
) {
  comms_events_sms_send_and_events_status_create(
    from_number_id: $from_number_id
    to_number: $to_number
    message: $message
    status: $status
    attachments: $attachments
    tags: $tags
  ) {
    event_sms_id
  }
}

`