import { useDataChange } from 'Simple/Data'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (next.parent_company_id === value) return

      next.parent_company_id = value
      next.location_ids = []
    })
  }
}
