import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'

import mutation_update from './mutation_update.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let onUpdate = useOnUpdate(props)

  return async function onSubmit({ value, args }) {
    switch (args.type) {
      default:
        return onUpdate(value)
    }
  }
}

function useOnUpdate(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation_update)

  return async function onSubmit(value) {
    let mutationResponse = await executeMutation({
      note_id: value.id,
      new_note: value.note,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }

    notify(notifySuccess('Note updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
