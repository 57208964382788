import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(person => ({
      ...person,
      address: person.contact_method_associations?.[0]?.postal_address,
    }))
  }, [data])
}
