// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Actions/Scheduled/Primary/Content/Unseat/mutation-requirements-check.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Actions_Scheduled_Primary_Content_Unseat($id: uuid!, $params: jsonb) {
  create_pre_appointment_bookings_state_update_automations(
    appointment_booking_id: $id
    state: "UNSEATED"
    params: $params
  ) {
    event_execution_ids
    is_pending
  }
}

`