import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import { notifyError, useNotifications } from 'Logic/Notifications'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let audax_ceph_token = useDataValue({
    context: 'ceph_analysis',
    path: 'token',
    viewPath: props.viewPath,
  })
  let audax_ceph_calibration_url = useDataValue({
    context: 'selected',
    path: 'calibration_url',
    viewPath: props.viewPath,
  })

  let resource_id = useDataValue({
    context: 'tab',
    path: 'selected.images.ceph_image_id',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let setDisableActions = useDataChange({
    context: 'selected',
    path: 'disable_actions',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick() {
    setDisableActions(true)
    if (!audax_ceph_calibration_url) {
      let mutationResponse = await executeMutation({
        resource_id,
        token: audax_ceph_token,
      })

      if (mutationResponse.error || !mutationResponse.data?.ceph_upload_image) {
        return notify(notifyError(`Couldn't open the calibration tool`))
      }

      change(next => {
        next.calibration_url =
          mutationResponse.data.ceph_upload_image.calibration_url
        next.ceph_image_id =
          mutationResponse.data.ceph_upload_image.ceph_image_id
        next.ceph_request_id =
          mutationResponse.data.ceph_upload_image.ceph_request_id
      })
    }

    setDisableActions(false)

    setFlowTo(normalizePath(props.viewPath, '../CalibrationTool/Content'))
  }
}
