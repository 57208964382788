import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data'
import { useNotifications, notifyError } from 'Logic/Notifications'

import mutationTriggerSyncPatients from './triggerSyncMutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeTriggerSyncPatientsMutation] = useMutation(
    mutationTriggerSyncPatients
  )
  let [, notify] = useNotifications()

  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'refetch': {
        reExecuteQuery({ requestPolicy: 'network-only' })
        return
      }
      case 'manage': {
        change(next => {
          next.selected.app_id = args.app_id
          next.selected.connection_name = args.connection_name
          next.selected.title = args.title
        })
        setFlowTo(normalizePath(props.viewPath, 'Connection'))

        return
      }
      case 'connections': {
        setFlowTo(normalizePath(props.viewPath, 'Connections'))
        return
      }

      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, 'Apps'))
        return
      }

      case 'back': {
        setFlowTo(normalizePath(props.viewPath, 'Connection'))
        return
      }

      case 'edit_automation': {
        change(next => {
          next.selected.automation_id = args.id
        })
        setFlowTo(normalizePath(props.viewPath, 'Automation'))
        return
      }

      case 'sync': {
        let mutationResponse = await executeTriggerSyncPatientsMutation({
          connection_id: args.connection_id,
          location_id,
        })
        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please try again later.'))
          return
        }
        change(next => {
          next.sync_id =
            mutationResponse.data.apps_sync_patients_process_start.sync_id
        })
        setFlowTo(normalizePath(props.viewPath, 'SyncPatients'))
        break
      }

      case 'open_sync': {
        change(next => {
          next.sync_id = args.sync_id
        })
        setFlowTo(normalizePath(props.viewPath, 'SyncPatients'))
        break
      }

      default: {
        return
      }
    }
  }
}
