import _formatDate from 'date-fns/format'
import isValidDate from 'date-fns/isValid'
import parseDate from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'

export function lineChart(value) {
  return (
    value.length > 0 && [
      value.map(({ completed_date, total, incomplete, created }) => ({
        x: dateShortInNoYear(completed_date.split('T')[0]),
        y: total === 0 ? 0 : incomplete + created,
      })),
      value.map(({ completed_date, total, patient_created }) => ({
        x: dateShortInNoYear(completed_date.split('T')[0]),
        y: total === 0 ? 0 : patient_created,
      })),
    ]
  )
}

export function legend(value) {
  return [
    { name: 'Leads', symbol: { type: 'minus' } },
    { name: 'Patients', symbol: { type: 'minus' } },
  ]
}

function dateShortInNoYear(value) {
  return formatDate(value, 'yyyy-MM-dd', 'MM/dd')
}

function formatDate(rvalue, formatIn, formatOut) {
  let value =
    rvalue instanceof Date
      ? rvalue
      : formatIn === 'iso'
      ? parseISO(rvalue)
      : parseDate(rvalue, formatIn, new Date())
  return isValidDate(value) ? _formatDate(value, formatOut) : rvalue
}
