import { useEffect, useState } from 'react'
import isInIframe from 'Logic/is-in-iframe.js'

let TEN_MINUTES = 1000 * 60 * 10

export default function useDataTransform(props, rdata) {
  let [data, setData] = useState(rdata)

  useEffect(() => {
    if (isInIframe()) return

    let cancel = false

    async function checkVersion() {
      try {
        let response = await fetch(`/version.json?t=${Date.now()}`)
        let versionData = await response.json()
        if (cancel) return

        if (
          versionData?.version !== 'undefined' &&
          versionData?.version !== window?.greyfinch[0]?.version
        ) {
          setData({ should_update: true })
        }
      } catch (_) {}
    }

    checkVersion()
    let interval = setInterval(checkVersion, TEN_MINUTES)

    return () => {
      cancel = true
      clearInterval(interval)
    }
  }, [])

  return data
}
