// this is an autogenerated file from DesignSystem/CommsThread/Content/MessageTools/External/Tools/AttachmentAction/InApp/AutoUploadAttachments/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_MessageTools_External_Tools_AttachmentAction_InApp_AutoUploadAttachments(
  $resource_id: numeric!
  $resource_type: vaxiom_resource_object_meta_resource_type!
) {
  resource_object_meta: vaxiom_resource_object_meta(
    resource_id: $resource_id
    resource_type: $resource_type
  ) {
    name
    content_type
    size
    preview_url
  }
}

`