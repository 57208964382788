// this is an autogenerated file from DesignSystem/CoreEmbeddableSection/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_CoreEmbeddableSection($user_id: numeric!) {
  vaxiom_users_generate_auth_token(vaxiom_user_id: $user_id) {
    token
  }
}

`