import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let selectedLocations = useDataValue({
    context: 'selected',
    path: 'locations',
    viewPath: props.viewPath,
  })

  let showDeleted = useDataValue({
    context: 'selected',
    path: 'show_deleted',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      location_ids:
        selectedLocations && selectedLocations.map(location => location.id),
      deleted_values: showDeleted ? [true, false] : [false],
    },
    pause: !selectedLocations?.length,
  }
}
