import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let thread_users = useDataValue({
    viewPath: props.viewPath,
    context: 'thread',
    path: 'users',
  })

  let current_pc_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.parent_company._id',
  })

  let ids = thread_users.map(i => i.id)

  return {
    variables: {
      current_users: ids,
      organization_id: current_pc_id,
    },
    pause: !Array.isArray(ids),
  }
}
