// this is an autogenerated file from DesignSystem/PreviewDocument/Content/Content/HtmlFile/Content/Content/Content/Actions/Signature/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__DesignSystem_PreviewDocument_Content_Content_HtmlFile_Content_Content_Content_Actions_Signature($id: numeric!) {
  documents_signature_requests(
    where: {
      status: { _neq: cancelled }
      resource_metadata: {
        _contains: { id: $id, table: "document_tree_nodes", schema: "vaxiom" }
      }
    }
  ) {
    id
    status
    created_at
    resource_id: resource_metadata(path: "id")
    allowed_birth_dates
    signatory_person {
      id
      first_name
      last_name
    }
  }
}

`