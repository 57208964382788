// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Documents/query-document.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Documents($id: numeric!) {
  vaxiom_document_tree_nodes_by_pk(id: $id) {
    id
    name
    parent {
      id
      name
      type
    }
  }
}

`