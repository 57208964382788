// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/FeatureFlags/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_FeatureFlags_Content(
  $organization_id: numeric!
  $message_key: String!
  $enabled: Boolean!
) {
  insert_vaxiom_application_properties_one(
    object: {
      organization_id: $organization_id
      message_key: $message_key
      message_value: "true"
    }
  ) @include(if: $enabled) {
    organization_id
    message_key
    message_value
  }
  delete_vaxiom_application_properties(
    where: {
      organization_id: { _eq: $organization_id }
      message_key: { _eq: $message_key }
    }
  ) @skip(if: $enabled) {
    affected_rows
  }
}

`