export function getView(
  appointment_id_from_tab,
  appointment_id_from_dropdown,
  slot_id
) {
  let isEditingAppointment =
    appointment_id_from_tab || appointment_id_from_dropdown

  return isEditingAppointment && slot_id
}
