import { useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
export function useOnChange(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'patient_search',
    path: 'term',
  })

  return function onClick(value) {
    change(value)
    if (value.length < 3) {
      setFlowTo(normalizePath(props.viewPath, '../Patients/No'))
    }
  }
}
