import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  // TODO: copy the approach used in comms and apply here and in
  // other tabs that don't follow the pattern
  // see: apps/admin/src/App/Account/Content/Comms/data.json
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'tab/openCommsThread': {
        change(next => {
          next.thread_id = args.thread_id
          next.is_secure =
            typeof args.is_secure === 'boolean' ? args.is_secure : true
          next.auto_uploaded_attachments = args.auto_uploaded_attachments || []
          next.message = args.message || ''
          next.tags = args.tags || []
        })
        setFlowTo(normalizePath(props.viewPath, 'Comms/Content'))
        break
      }

      case 'message-sent': {
        setFlowTo(normalizePath(props.viewPath, '../../No'))
        break
      }

      default: {
        throw new Error(`Unknown action type ${args.type}`)
      }
    }
  }
}
