import { useDataValue } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications'
import query from './query.graphql.js'
import { downloadUrl } from 'Logic/downloadUrl.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let client = useClient()

  return async function onSubmit({ value }) {
    try {
      setFlowTo(normalizePath(props.viewPath, 'Loading/Content'))

      let response = await client
        .query(query, {
          id: image_series_id,
        })
        .toPromise()
      if (response.error) {
        return notify(
          notifyError(`Couldn't download the selected image series`)
        )
      }

      await downloadUrl({
        url: response.data.vaxiom_image_series_download.url,
        file_name: response.data.vaxiom_image_series_download.file_name,
      })
    } finally {
      setFlowTo(normalizePath(props.viewPath, 'Loading/No'))
      setFlowTo(normalizePath(props.viewPath, '../../../No'))
    }
  }
}
