import { useEnsureUrl } from 'Logic/FlowShortcuts.js'

// a bit of a hack to test the concept
// props with an underscore like thread_id don't work because
// of how the morpher parses the props (or data) it seems, we
// should be able to enable them if needed
export default function EnsureUrl({
  'data-testid': dataTestid,
  'data-view-path': dataViewPath,
  data,
  ...props
}) {
  useEnsureUrl(props)

  return null
}
