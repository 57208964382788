import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { phoneNumber } from 'Data/validate.js'
import query from './query.graphql.js'
import mutation_add_person_contact_methods from './addPersonContactMethodsMutation.graphql.js'
import mutation_update_phone from './updatePhoneMutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionNewPhoneForProfessional =
    useDataOnActionNewPhoneForProfessional(props)
  let onActionEditPhone = useDataOnActionEditPhone(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'new_phone_for_professional': {
        return onActionNewPhoneForProfessional({ value, args })
      }
      case 'form_submit': {
        return onActionFormSubmit({ value, args })
      }
      default: {
        onActionEditPhone({ value })
      }
    }
  }
}

function useDataOnActionFormSubmit(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })

  let client = useClient()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )
  return async function onAction({ value: phone }) {
    if (!phoneNumber(phone.phone.number) || !phone.phone.type) {
      return true
    }
    let { data } = await client
      .query(query, {
        contact_method_id: phone.contact_method_id,
        person_id,
      })
      .toPromise()
    if (
      data?.vaxiom_contact_method_associations_aggregate?.aggregate.count === 0
    ) {
      let mutationAddPersonContactMethods =
        await executeMutationAddPersonContactMethods({
          person_id,
          contact_method_id: phone.contact_method_id,
          contact_methods: [
            {
              dtype: 'phone',
              description: phone.description,
              data: {
                ...phone.phone,
              },
            },
          ],
        })

      if (mutationAddPersonContactMethods.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }
    } else {
      setFlowTo(normalizePath(props.viewPath, './EditOrAdd/Content'))
    }
  }
}

function useDataOnActionEditPhone(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })
  return async function onAction({ value: phone }) {
    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_method_id: phone.contact_method_id,
        contact_methods: [
          {
            dtype: 'phone',
            description: phone.description,
            data: {
              ...phone.phone,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }
    notify(notifySuccess('Phone updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataOnActionNewPhoneForProfessional(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'other.person.id',
  })
  return async function onAction({ value: phone }) {
    // if the phone doesn't exist,
    // and we don't want to add a relation,
    // we just save the new phone and add
    // a new association
    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_method_id: phone.contact_method_id,
        contact_methods: [
          {
            dtype: 'phone',
            description: phone.description,
            data: {
              ...phone.phone,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Phone saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
