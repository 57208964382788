import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'add_provider',
    path: 'provider',
  })
  return function onClick() {
    change('bluefin')
  }
}
