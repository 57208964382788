import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'

export function useOnSubmit(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let parent_id = useDataValue({
    context: 'patient_documents',
    path: 'parent_id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeCreateDirectoryMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (!value) return

    let mutationCreateDirectoryResponse = await executeCreateDirectoryMutation({
      name: value,
      patient_id,
      parent_id,
    })
    if (mutationCreateDirectoryResponse.error) {
      throw mutationCreateDirectoryResponse.error
    }
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
