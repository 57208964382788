import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'requirement',
    path: 'task_basket_id',
  })

  return {
    variables: {
      id,
    },
    pause: !id,
  }
}
