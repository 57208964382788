// this is an autogenerated file from App/Account/Content/Insights/Kpis/Definitions/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Insights_Kpis_Definitions{
  definitions: kpi_definitions {
    id
    title
    query
    overview
    type
  }
}

`