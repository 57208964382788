// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/CreateTreatmentPlan/EditTreatmentAndPaymentPlans/New/Steps/PaymentPlans/Content/PaymentPlan/Content/Summary/Payors/Payor/Person/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_CreateTreatmentPlan_EditTreatmentAndPaymentPlans_New_Steps_PaymentPlans_Content_PaymentPlan_Content_Summary_Payors_Payor_Person($id: numeric!) {
  vaxiom_persons_by_pk(id: $id) {
    id
    first_name
    last_name
  }
}

`