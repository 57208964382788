import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let id = useDataValue({
    context: 'comparison_section',
    path: 'image_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { id },
    pause: !id,
    requestPolicy: 'cache-and-network',
  }
}
