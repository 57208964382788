export function getQuestionType(value_type, is_free_text, multi_value) {
  if (value_type === 'TEXT' && is_free_text) return 'text'
  return multi_value ? 'multiChoiceCustom' : 'choiceCustom'
}

export function getAnswer(type, values) {
  if (!values?.length) return type === 'multiChoiceCustom' ? [] : undefined

  switch (type) {
    case 'text':
      return values[0].text_value
    case 'choiceCustom':
      return values[0].field_option_id || values[0].text_value
    case 'multiChoiceCustom':
      return values.map(value => value.field_option_id || value.text_value)
    default:
      return undefined
  }
}

export function getOptions(type, field_definition, values, tracked_values) {
  let result = field_definition.type.options
  if (!['choiceCustom', 'multiChoiceCustom'].includes(type)) return result

  if (field_definition.tracked && tracked_values?.length) {
    // add tracked field value to the list of options if the field doesn't have value set explicitly
    // and the option inherited through the tracked value is coming from a different treatment field type
    if (!values?.length) {
      // value coming from a different field type - adding it to be reflected on the UI
      result = [
        ...result,
        ...tracked_values
          .filter(tracked_value =>
            result.every(option => option.id !== tracked_value.field_option_id)
          )
          .map(tracked_value => tracked_value.field_option)
          .filter(Boolean),
      ]
    }
    // mark option coming from tracked field
    result = result.map(option => ({
      ...option,
      name: tracked_values.some(
        tracked_value => option.id === tracked_value.field_option_id
      )
        ? `(${option.name})`
        : option.name,
    }))
  }
  if (values?.length) {
    // value coming from a different field type - adding it to be reflected on the UI
    result = [
      ...result,
      ...values
        .filter(value =>
          result.every(option => option.id !== value.field_option_id)
        )
        .map(value => value.field_option)
        .filter(Boolean),
    ]
  }
  return result
}
