import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    context: 'add_connection',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (!value?.length) return

    let { status } = value[0] || {}

    submit({ type: status })
  }
}
