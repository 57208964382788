import React, { useEffect } from 'react'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let open_images_tab = useDataValue({
    context: 'refresh',
    path: 'open_images_tab',
    viewPath: props.viewPath,
  })
  let setOpenImagesTab = useDataChange({
    context: 'refresh',
    path: 'open_images_tab',
    viewPath: props.viewPath,
  })
  let open_tooth_chart_tab = useDataValue({
    context: 'refresh',
    path: 'open_tooth_chart_tab',
    viewPath: props.viewPath,
  })
  let setOpenToothChartTab = useDataChange({
    context: 'refresh',
    path: 'open_tooth_chart_tab',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    if (!open_tooth_chart_tab) return
    // TimelineEvent where the tooth chart is selected is part of the design system so it could be reusable
    // but it cannot set the flow outside of the design system, so updating it though a data context instead
    setFlowTo(normalizePath(props.viewPath, 'ToothChart'))
    setOpenToothChartTab(null)
  }, [open_tooth_chart_tab]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setFlowTo, setOpenToothChartTab

  useEffect(() => {
    if (!open_images_tab) return
    // TimelineEvent where the image series are selected is part of the design system so it could be reusable
    // but it cannot set the flow outside of the design system, so updating it though a data context instead
    setFlowTo(normalizePath(props.viewPath, 'Images'))
    setOpenImagesTab(null)
  }, [open_images_tab]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore setFlowTo, setOpenImagesTab

  return <View {...props} />
}
