import { useCallback, useMemo } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data'
import { employeeName, minutesToTwoDigitsTime } from 'Data/format.js'
import last from 'lodash/last.js'
import { APPOINTMENT_TYPE_COLORS } from 'Data/constants.js'
import { format } from 'date-fns-tz'
import { addMinutes, formatISO, getDay, parseISO } from 'date-fns'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })
  let timeZoneId = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let schedulingSlots = useDataValue({
    context: 'tab',
    path: 'scheduling.slots',
    viewPath: props.viewPath,
  })
  let schedulingSlotId = useDataValue({
    context: 'tab',
    path: 'scheduling.slot_id',
    viewPath: props.viewPath,
  })
  let slotInterval = useDataValue({
    context: 'tab',
    path: 'selected.slot_interval',
    viewPath: props.viewPath,
  })

  let minutesToEventTime = useCallback(
    /**
     * @param {number} minutes
     * @returns {String}
     */
    minutes =>
      // prettier-ignore
      `${format(date, 'yyyy-MM-dd', { timeZone: timeZoneId })}T${minutesToTwoDigitsTime(minutes)}:00`,
    [date, timeZoneId]
  )

  // stage 1 - remote data
  let transformedData = useMemo(() => {
    if (!data?.length) {
      change(next => {
        next.selected.appointment_count = null
        next.selected.patient_count = null
      })
      return data
    }

    let { slotMinTime, slotMaxTime } = calculateSlotMinMaxTime(data)
    let patients = new Set()
    let bookingCount = 0
    let events = []
    let open_dialog_near_click = data.length < 3

    let resources = data.map(resource => {
      let appointment_slots = resource.filtered_appt_slots ?? []
      bookingCount += resource.chair.appointment_bookings.length

      events.push(
        ...resource.chair.schedule_notes.map(note => ({
          id: note._id,
          start: note.start_time,
          end: formatISO(addMinutes(parseISO(note.start_time), note.duration)),
          type: note.is_blocking_time ? 'block' : 'note',
          content: note.content,
          resourceId: resource.chair._id,
          display: note.is_blocking_time ? 'block' : 'background',
        })),
        ...appointment_slots.map(slot => ({
          start: minutesToEventTime(slot.start_min),
          end: minutesToEventTime(
            slot.start_min +
              slot.appointment_type.appointment_templates[0].duration
          ),
          backgroundColor:
            APPOINTMENT_TYPE_COLORS[
              slot.appointment_type.appointment_templates[0].color_id_computed
            ] + '66',
          headerBackgroundColor:
            APPOINTMENT_TYPE_COLORS[
              slot.appointment_type.appointment_templates[0].color_id_computed
            ],
          appointment_type:
            slot.appointment_type.appointment_templates[0].full_name_computed,
          headerColor: '#ffffff',
          type: 'appointment-type',
          resourceId: resource.chair._id,
          display: 'background',
        })),
        ...resource.chair.appointment_bookings.map(booking => {
          patients.add(booking.appointment.patient._id)
          let duration =
            booking.duration > 0
              ? booking.duration
              : Math.max(
                  ...booking.appointment.type.appointment_templates.map(
                    at => at.duration
                  )
                )
          return {
            id: booking._id,
            start: minutesToEventTime(toMinutes(booking.local_start_time)),
            end: minutesToEventTime(
              toMinutes(booking.local_start_time) + duration
            ),
            type: 'appointment',
            resourceId: resource.chair_id,
            appointment: booking.appointment,
            provider: booking.provider,
            assistant: booking.assistant,
            state: booking.state,
            confirmation_status: booking.confirmation_status,
            overlap: false,
            appointment_type_color:
              APPOINTMENT_TYPE_COLORS[
                booking.appointment.type.display_color_id
              ],
            open_dialog_near_click,
            location_id,
            chair_id: resource.chair.id,
          }
        })
      )

      return {
        id: resource.chair._id,
        name: resource.chair.full_name,
        position: resource.chair.pos,
        provider: resource.provider ? employeeName(resource.provider) : '-',
        assistant: resource.assistant ? employeeName(resource.assistant) : '-',
        businessHours: getResourceOfficeHours(resource),
      }
    })

    /**
     *
     * @param {Object} resource
     * @returns {Array}
     */
    function getResourceOfficeHours(resource) {
      if (
        !resource.office_hours?.length ||
        !resource.appointment_slots?.length
      ) {
        return [
          {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
              item => item !== getDay(date)
            ),
            startTime: minutesToTwoDigitsTime(slotMinTime),
            endTime: minutesToTwoDigitsTime(slotMaxTime),
          },
        ]
      }

      // interpolate with odt chair break hours!
      return resource.office_hours
        .filter(item => item.start_min !== item.end_min)
        .map(item => ({
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: minutesToTwoDigitsTime(item.start_min),
          endTime: minutesToTwoDigitsTime(item.end_min),
        }))
    }

    change(next => {
      next.selected.appointment_count = bookingCount
      next.selected.patient_count = patients.size
    })

    return {
      resources,
      events,
      slot_min_time: minutesToTwoDigitsTime(slotMinTime),
      slot_max_time: minutesToTwoDigitsTime(slotMaxTime),
    }
  }, [data, date, minutesToEventTime, location_id])

  // stage 2 - additional runtime events
  return useMemo(() => {
    let additionalEvents = []

    // higher values make slots visually smaller
    let adjustedSlotInterval = {
      5: 5,
      10: 5,
    }

    schedulingSlots.forEach(slot => {
      let start = minutesToEventTime(slot.start_min)
      let previewEnd = minutesToEventTime(
        slot.start_min + adjustedSlotInterval[slotInterval]
      )
      let realEnd = minutesToEventTime(slot.end_min)
      let selected = slot.id === schedulingSlotId

      additionalEvents.push({
        classNames: [selected ? 'candidate-selected' : 'candidate'],
        type: 'candidate',
        display: 'background',
        //
        id: slot.id,
        schedulingSlot: slot,
        start,
        end: selected ? realEnd : previewEnd,
        previewEnd,
        realEnd,
        selected,
        resourceId: slot.chair_id,
        chairName: slot.chair_name,
      })
    })

    return {
      ...transformedData,
      events: [...(transformedData?.events ?? []), ...additionalEvents],
    }
  }, [
    transformedData,
    schedulingSlots,
    schedulingSlotId,
    slotInterval,
    minutesToEventTime,
  ])
}

/**
 * @returns {{
 *   slotMinTime: number
 *   slotMaxTime: number
 * }}
 */
function calculateSlotMinMaxTime(data) {
  let slotMinTime = 24 * 60
  let slotMaxTime = 0
  let earliest, latest

  data.forEach(chair => {
    // Check first and latest appointment booking here

    if (chair.office_hours?.length) {
      earliest = chair.office_hours[0]
      latest = last(chair.office_hours)
      if (earliest.start_min < slotMinTime) slotMinTime = earliest.start_min
      if (latest.end_min > slotMaxTime) slotMaxTime = latest.end_min
    }
  })

  return {
    slotMinTime,
    slotMaxTime,
  }
}

/**
 * @param {string} time
 * @returns
 */
function toMinutes(time) {
  return time
    .split(':')
    .reduce(
      (a, b, currentIndex) =>
        a +
        parseInt(b) * (currentIndex === 0 ? 60 : currentIndex === 1 ? 1 : 0),
      0
    )
}
