import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let connection_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'connection_id',
  })
  let account_id = useDataValue({
    context: 'selected',
    path: 'properties.account_id',
    viewPath: props.viewPath,
  })

  return {
    variables: { connection_id, account_id },
    pause: !connection_id || !account_id,
  }
}
